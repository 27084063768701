import { _ } from "../../../../vendor";
import * as geom from "../../../../core/utilities/geom";
import { CollectionItemElementSelection } from "./CollectionItemElementSelection";
import { defaultDragPositionProps } from "../../../../editor/PresentationEditor/DragElementManager";

export class BigNumberItemSelection extends CollectionItemElementSelection {
    get canDrag() {
        return true;
    }

    get dragPositionProps() {
        const { element } = this.props;

        const getNewProps = element => ({
            ...defaultDragPositionProps,
            dropTargets: this.getDropTargets(element),
            customDropTargets: this.getCustomDropTargets(element),
            drop: async ({ targetElement, customDropTargetId }) => {
                const parentElement = element.parentElement;

                if (targetElement) {
                    if (targetElement.model.row === element.model.row) {
                        const insertAfter = targetElement.itemIndex > element.itemIndex || targetElement.isLastItem;
                        parentElement.deleteItem(element.id);
                        const { id } = parentElement.addItem({ ...element.model, row: targetElement.model.row }, insertAfter ? targetElement.itemIndex + 1 : targetElement.itemIndex);
                        return `${parentElement.uniquePath}/${id}`;
                    }

                    const targetModel = _.cloneDeep(targetElement.model);
                    targetModel.row = element.model.row;

                    const elementModel = _.cloneDeep(element.model);
                    elementModel.row = targetElement.model.row;

                    const elementIdx = parentElement.itemCollection.findIndex(item => item.id === element.id);
                    const targetIdx = parentElement.itemCollection.findIndex(item => item.id === targetElement.id);

                    Object.keys(parentElement.itemCollection[elementIdx]).forEach(key => delete parentElement.itemCollection[elementIdx][key]);
                    Object.assign(parentElement.itemCollection[elementIdx], targetModel);

                    Object.keys(parentElement.itemCollection[targetIdx]).forEach(key => delete parentElement.itemCollection[targetIdx][key]);
                    Object.assign(parentElement.itemCollection[targetIdx], elementModel);

                    return `${parentElement.uniquePath}/${element.id}`;
                }

                if (customDropTargetId === "add_row_below") {
                    element.model.row = element.parentElement.rows + 1;
                    return element.uniquePath;
                }

                if (customDropTargetId === "add_row_above") {
                    element.parentElement.itemElements.filter(el => el !== element).forEach(el => el.model.row += 1);
                    element.model.row = 0;
                    return element.uniquePath;
                }

                if (customDropTargetId?.startsWith("move_to_start_of_row_")) {
                    parentElement.deleteItem(element.id);
                    const row = parseInt(customDropTargetId.split("_")[5]);
                    const { id } = parentElement.addItem({ ...element.model, row }, 0);
                    return `${parentElement.uniquePath}/${id}`;
                }

                if (customDropTargetId?.startsWith("move_to_end_of_row_")) {
                    parentElement.deleteItem(element.id);
                    const row = parseInt(customDropTargetId.split("_")[5]);
                    const { id } = parentElement.addItem({ ...element.model, row });
                    return `${parentElement.uniquePath}/${id}`;
                }
            },
            getNewProps
        });

        return getNewProps(element);
    }

    getCustomDropTargets(element) {
        const bigNumbersElement = element.parentElement;
        if (!bigNumbersElement.isInstanceOf("BigNumbers")) {
            return [];
        }

        const targets = [];

        const bounds = bigNumbersElement.canvasBounds;

        const itemsInRows = bigNumbersElement.getItemsInRows();
        itemsInRows.forEach((rowItems, row) => {
            if (row === element.model.row) {
                return;
            }

            const rowBounds = rowItems.reduce((bounds, item) => bounds ? bounds.union(item.canvasBounds) : item.canvasBounds, null);
            targets.push({
                bounds: new geom.Rect(bounds.left, rowBounds.top, rowBounds.left - bounds.left, rowBounds.height),
                visible: false,
                id: `move_to_start_of_row_${row}`
            });
            targets.push({
                bounds: new geom.Rect(rowBounds.right, rowBounds.top, bounds.width - rowBounds.right + bounds.left, rowBounds.height),
                visible: false,
                id: `move_to_end_of_row_${row}`
            });
        });

        if (bigNumbersElement.rows >= 3) {
            return targets;
        }

        const elementsInRow = bigNumbersElement.itemElements.filter(el => el.model.row === element.model.row && el !== element);
        const canAddRowAbove = element.model.row === 0 && elementsInRow.length === 0 ? false : true;
        const canAddRowBelow = element.model.row === bigNumbersElement.rows - 1 && elementsInRow.length === 0 ? false : true;

        if (canAddRowAbove) {
            targets.push({
                bounds: bounds.deflate({ bottom: bounds.height - 50 }),
                visible: false,
                id: "add_row_above"
            });
        }
        if (canAddRowBelow) {
            targets.push({
                bounds: bounds.deflate({ top: bounds.height - 50 }),
                visible: false,
                id: "add_row_below"
            });
        }

        return targets;
    }
}


import React from "react";

import { AuthoringBaseEditor } from "./AuthoringBaseEditor";
import { BoundsBox } from "../SelectionBox";
import { Slider } from "../../../../../../Components/Slider";
import { ControlBar } from "../../../ElementControlBars/Components/ControlBar";

export class FlexCircleCalloutEditor extends AuthoringBaseEditor {
    constructor(props) {
        super(props);

        this.state = {
            markerSize: props.selection[0].childElement.markerSize,
        };
    }

    componentDidUpdate(prevProps) {
        const { selection } = this.props;
        if (selection[0].childElement.markerSize !== this.state.markerSize) {
            this.setState({ markerSize: selection[0].childElement.markerSize });
        }
    }

    handleDragStart = () => {
        const { selectionLayerController, setShowSelectionBox } = this.props;
        setShowSelectionBox(false);
        selectionLayerController.setFreezeSelectionLayer(true);
    }

    handleSizeChange = async value => {
        const { selection: [element] } = this.props;
        this.setState({ markerSize: value });
        element.refreshModel({ markerSize: value }, { refreshStyles: true });
    }

    handleSizeChangeCommitted = value => {
        const { selection: [element], selectionLayerController, setShowSelectionBox } = this.props;
        setShowSelectionBox(true);
        selectionLayerController.setFreezeSelectionLayer(false);
        element.updateModel({ markerSize: value }, { refreshStyles: true });
    }

    render() {
        const { bounds, selection } = this.props;
        const { markerSize } = this.state;

        if (selection.length !== 1) {
            return null;
        }

        return (
            <BoundsBox bounds={bounds.zeroOffset()}>
                <ControlBar>
                    {/*<AuthoringCalloutPopupMenu {...this.props} />*/}
                    {/*<ColorPicker*/}
                    {/*    slideColor={selection[0].parentElement.getSlideColor()}*/}
                    {/*    showSlideColor={true}*/}
                    {/*    color={markerColor}*/}
                    {/*    onChange={this.handleColorChangeValue}*/}
                    {/*/>*/}
                    {/*<PositionCalloutEditor {...this.props} />*/}
                    <Slider
                        value={markerSize}
                        min={10}
                        max={400}
                        onDragStart={this.handleDragStart}
                        onChange={this.handleSizeChange}
                        onCommit={this.handleSizeChangeCommitted}
                    />
                </ControlBar>
            </BoundsBox>
        );
    }
}

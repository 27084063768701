import { BaseElement } from "../../base/BaseElement";
import { _ } from "../../../../../vendor";
import { ForeColorType } from "../../../../../../common/constants";

export class CalloutElement extends BaseElement {
    get resizeDirections() {
        return {
            left: false,
            right: false,
            top: false,
            bottom: false
        };
    }

    get referencePoint() {
        return null;
    }

    get minTextWidth() {
        return 100;
    }
    get maxTextWidth() {
        return 1280;
    }

    get textWidth() {
        return this.model.textWidth ?? 200;
    }

    setUserWidth(width) {
        this.model.textWidth = Math.clamp(width, this.minTextWidth, this.maxTextWidth);
    }

    get minWidth() {
        return this.options.minWidth ?? 40;
    }

    get minHeight() {
        return this.options.minHeight ?? 20;
    }

    get canChangeTextDirection() {
        return false;
    }

    get limitSize() {
        return true;
    }

    get canAddBlocks() {
        return false;
    }

    get clipboardElement() {
        return this;
    }

    get syncFontSizeWithSiblings() {
        return true;
    }

    get canRollOver() {
        return false;
    }

    get rolloverPadding() {
        return 20; // extra padding to bounds used when detecting if mouse is rolled over this element
    }

    get fitToContents() {
        return {
            width: false,
            height: true
        };
    }

    get connectionShape() {
        return {
            bounds: this.bounds,
            type: this.model.shape
        };
    }

    get canChangeColor() {
        return this.options.canChangeColor ?? true;
    }

    getDefaultConnectorColor() {
        return ForeColorType.SECONDARY;
    }

    get preventSelfPointingConnector() {
        return true;
    }

    get isCallout() {
        return true;
    }

    get _canSelect() {
        return false;
    }

    get connectorsFromNode() {
        const container = this.findClosestOfType("AuthoringCanvas");
        if (container.connectors) {
            return container.connectors.getConnectorsForItem(this.parentElement.id, "source");
        } else {
            return [];
        }
    }

    get connectorsToNode() {
        const container = this.findClosestOfType("AuthoringCanvas");
        if (container.connectors) {
            return container.connectors.getConnectorsForItem(this.parentElement.id, "target");
        } else {
            return [];
        }
    }

    get childNodes() {
        const container = this.findClosestOfType("AuthoringCanvas");
        return _.map(this.connectorsFromNode, c => container.getChild(c.model.target));
    }

    get parentNodes() {
        const container = this.findClosestOfType("AuthoringCanvas");
        return _.map(this.connectorsToNode, c => container.getChild(c.model.source));
    }
}

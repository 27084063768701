import React, { Component } from "react";

import { WithLabel } from "../../../../../Components/WithLabel";
import { PropertyPanelHeader } from "../../../../../editor/ElementEditor/PropertyPanelHeader";
import { SlideBackgroundColorPicker } from "../../../../../editor/PresentationEditor/Components/SlideBackgroundColorPicker";
import { PropertyPanelContainer, PropertySection } from "../../../../../EditorComponents/PropertyPanel";

export class SelectedElementPropertyPanel extends Component {
    render() {
        const { selectedElements, primaryElement } = this.props;

        // get either the selected element or fallback on the primary element
        let element = selectedElements[0] ?? primaryElement;

        if (element?.isDeleted) {
            element = primaryElement;
        }

        // walk an element up to the root to find one that has a property panel defined
        while (!element.getElementPropertyPanel() && (element.parentElement != null && element.parentElement != element.getCanvasElement())) {
            element = element.parentElement;
        }

        // make sure the element being rendered has calculatedProps (this is a workaround for a bug where changing the grid while a cell was selected would crash)
        if (!element.calculatedProps) {
            element = primaryElement;
        }

        let ElementPropertyPanel;
        // if a child of a CollectionItem is selected, use the ElementPropertyPanel of the parent CollectionItem
        if (element.findClosestOfType("CollectionItemElement")) {
            ElementPropertyPanel = element.findClosestOfType("CollectionItemElement").getElementPropertyPanel();
        } else {
            ElementPropertyPanel = element.getElementPropertyPanel();
        }

        if (!ElementPropertyPanel) {
            ElementPropertyPanel = element.getRootElement().getElementPropertyPanel();
        }

        return (
            <PropertyPanelContainer fullHeight data-element-path={element.uniquePath} data-property-panel-name={ElementPropertyPanel?.name}>
                {element === primaryElement && (
                    <>
                        <PropertyPanelHeader>{element.canvas.slideTemplate.constructor.title} PROPERTIES</PropertyPanelHeader>

                        {!element.preventSlideBackgroundColor && (
                            <PropertySection>
                                <WithLabel label="Slide Background" left>
                                    <SlideBackgroundColorPicker {...this.props} />
                                </WithLabel>
                            </PropertySection>
                        )}
                    </>
                )}
                {ElementPropertyPanel && <ElementPropertyPanel {...this.props} element={element} />}
            </PropertyPanelContainer>
        );
    }
}

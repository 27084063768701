import React, { Component } from "react";

import { WithLabel } from "../../../../Components/WithLabel";

import { PropertyPanelContainer, PropertySection } from "../../../../EditorComponents/PropertyPanel";
import { Slider } from "../../../../Components/Slider";
import { DecorationStyle, ShapeType } from "../../../../../common/constants";
import { CollectionColorPicker } from "../EditorComponents/ColorPickers/CollectionColorPicker";
import { ItemColorPicker } from "../EditorComponents/ColorPickers/ItemColorPicker";
import { ControlBar } from "../ElementControlBars/Components/ControlBar";
import { MediaPopup } from "../EditorComponents/MediaPopup";
import { ImageFramePopupMenu } from "../EditorComponents/ImageFrameMenu";

export class PhotoTextListPropertyPanel extends Component {
    render() {
        const { element } = this.props;

        const frameType = element.getChildValues("frameType");

        let hasShapeFrame = element.itemElements.some(el => el.media?.hasShapeFrame);

        return (
            <PropertyPanelContainer>
                <PropertySection>
                    <WithLabel label="Item Colors">
                        <CollectionColorPicker element={element} showDecorationStyles />
                    </WithLabel>
                </PropertySection>
                <PropertySection>
                    <WithLabel label="Item Frames">
                        <ImageFramePopupMenu frameType={frameType}
                            showPreview
                            allowedCategories={["shape", "device"]}
                            onChange={frame => {
                                for (let item of element.itemElements) {
                                    item.model.frameType = frame;
                                    if (frame == ShapeType.NONE) {
                                        item.media?.content?.assetElement?.resetMediaTransform && item.media.content.assetElement.resetMediaTransform();
                                    }
                                }
                                element.canvas.updateCanvasModel(false);
                            }}
                        />
                    </WithLabel>
                </PropertySection>
                <PropertySection>
                    <WithLabel label="Media Height" left>
                        <Slider value={element.model.mediaHeight}
                            fillWidth
                            min={100}
                            max={element.calculatedProps.size.height - element.calculatedProps.maxTextHeight}
                            step={1}
                            onChange={value => element.refreshModel({ mediaHeight: value })}
                            onCommit={() => element.saveModel()}
                        />
                    </WithLabel>
                </PropertySection>

            </PropertyPanelContainer>
        );
    }
}

export class PhotoTextListItemControlBar extends Component {
    render() {
        const { element } = this.props;
        return (
            <ControlBar>
                <MediaPopup element={element.media.content} />
                <ImageFramePopupMenu element={element}
                    allowedCategories={["shape", "device"]}
                    onChange={frameType => element.updateModel({ frameType }, { refreshStyles: true })}
                    onChangeDecorationStyle={decorationStyle => element.updateModel({ decorationStyle }, { refreshStyles: true })}
                />
                <ItemColorPicker element={element} showDecorationStyles />
            </ControlBar>
        );
    }
}

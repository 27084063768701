import React, { Component } from "react";
import { ColorPicker } from "./ColorPicker";

export class ItemColorPicker extends Component {
    render() {
        let { element, size = 20, allowedDecorationStyles, ignoreMediaElement = false, colorProperty = "color", showDefault = true, showBackgroundColors = true, showNone, autoColorOverride, allowColorOnColor, showDecorationStyles, position } = this.props;

        const canvas = element.canvas;

        // note there are some legacy places where color is set to "auto" instead of null
        const elementColor = element.model[colorProperty] == "auto" ? null : element.model[colorProperty];
        const collectionColor = autoColorOverride ?? element.findClosestOfType("CollectionElement")?.collectionColor;

        let backgroundColor = element.parentElement.getBackgroundColor();
        if (!ignoreMediaElement && element.mediaElement != null) {
            backgroundColor = element.mediaElement.getBackgroundColor() ?? backgroundColor;
            if (showDecorationStyles) {
                // don't show DecorationStyles for device frames and FrameType.None
                showDecorationStyles = element.mediaElement.hasShapeFrame;
            }
        }

        return (
            <ColorPicker
                allowedDecorationStyles={allowedDecorationStyles}
                value={elementColor}
                allowColorOnColor={allowColorOnColor}
                backgroundColor={backgroundColor}
                defaultColor={showDefault ? collectionColor : null} size={size}
                canvas={canvas}
                showBackgroundColors={showBackgroundColors}
                showNone={showNone} showDecorationStyles={showDecorationStyles}
                position={position}
                onChange={color => {
                    if (this.props.onChange) {
                        this.props.onChange(color);
                    } else {
                        element.updateModel({ [colorProperty]: color });
                    }
                }}
                onChangeDecorationStyle={decorationStyle => {
                    element.updateModel({ decorationStyle });
                }}
            />
        );
    }
}


import * as geom from "js/core/utilities/geom";
import { TextElement } from "../../base/Text/TextElement";
import { SVGRectElement } from "../../base/SVGElement";
import { BaseElement } from "../../base/BaseElement";

export class TimelineSpanSliceItem extends BaseElement {
    get cornerRadius() {
        return this.styles.cornerRadius;
    }

    get canRollover() {
        return false;
    }

    _build() {
        this.shape = this.addElement("shape", () => SVGRectElement);
        this.label = this.addElement("label", () => TextElement, {
            scaleTextToFit: true,
            autoWidth: false,
            autoHeight: false,
            minTextScale: 1,
            syncFontSizeWithSiblings: true,
            backgroundElement: this.shape
        });
    }

    _calcProps(props) {
        const { size } = props;

        const shapeProps = this.shape.calcProps(size);
        shapeProps.bounds = new geom.Rect(0, 0, size);
        shapeProps.cornerRadius = this.cornerRadius;

        const labelProps = this.label.calcProps(size);
        labelProps.bounds = new geom.Rect(0, size.height / 2 - labelProps.size.height / 2, labelProps.size);

        return { size };
    }
}

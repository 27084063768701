import React, { Component } from "react";

import { IconButton } from "../../../../Components/IconButton";
import { ImageOptionList } from "../../../../Components/ImageOptionList";
import { Popup, PopupContainer, PopupContent } from "../../../../Components/Popup";
import { TextPositionPicker } from "../../../../Components/PositionPicker";
import { WithLabel } from "../../../../Components/WithLabel";
import { ControlBar } from "../ElementControlBars/Components/ControlBar";
import { ItemColorPicker } from "../EditorComponents/ColorPickers/ItemColorPicker";
import AuthoringElementHeader from "../EditorComponents/AuthoringElementHeader";
import { PropertySection } from "../../../../EditorComponents/PropertyPanel";
import { getContentItemImageOptions } from "../EditorComponents/getContentItemImageOptions";

export class ContentItemPropertyPanel extends Component {
    render() {
        const { element } = this.props;

        return (
            <AuthoringElementHeader element={element}>
                <PropertySection>
                    <WithLabel label="Style" top left>
                        <ImageOptionList value={element.model.nodeType}
                            cols={3}
                            onChange={value => element.updateModel({ nodeType: value })}
                        >
                            {getContentItemImageOptions(element.parentElement.getAllowedNodeTypes(element))}
                        </ImageOptionList>
                    </WithLabel>
                </PropertySection>
                {element.canChangeTextDirection && (
                    <PropertySection>
                        <WithLabel label="Text Position">
                            <TextPositionPicker value={element.model.textDirection}
                                onChange={textDirection => {
                                    element.updateModel({ textDirection });
                                }} />
                        </WithLabel>
                    </PropertySection>
                )}
                <PropertySection>
                    <WithLabel label="Layering">
                        <IconButton round icon="flip_to_front" label="Bring to Front" />
                        <IconButton round icon="flip_to_back" label="Send to Back" />
                    </WithLabel>
                </PropertySection>
            </AuthoringElementHeader>
        );
    }
}

export class ContentItemControlBar extends Component {
    render() {
        const { element } = this.props;

        const allowedNodeTypes = element.parentElement.getAllowedNodeTypes(element);

        return (
            <ControlBar>
                {allowedNodeTypes.length > 1 &&
                    <Popup icon="apps">
                        <PopupContent>
                            {closePopup => (
                                <PopupContainer>
                                    <ImageOptionList value={element.model.nodeType}
                                        cols={4} size={100}
                                        onChange={value => {
                                            element.updateModel({ nodeType: value });
                                            closePopup();
                                        }}
                                    >
                                        {getContentItemImageOptions(allowedNodeTypes)}
                                    </ImageOptionList>
                                </PopupContainer>
                            )}
                        </PopupContent>
                    </Popup>
                }
                <ItemColorPicker element={element} showDecorationStyles={element.allowDecorationStyles ?? true} />
                {this.props.children}
            </ControlBar>
        );
    }
}

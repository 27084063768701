import React from "react";

import WidgetButton, { WidgetButtonPosition } from "../../../../../Components/WidgetButton";

function DeleteElementButton({ onClick, position = WidgetButtonPosition.OUTER, left = undefined, top = undefined, outlined = true }) {
    if (left === undefined || top === undefined) {
        switch (position) {
            case WidgetButtonPosition.CORNER:
                left = "100%";
                top = 0;
                break;
            case WidgetButtonPosition.INNER:
                left = "calc(100% - 14px)";
                top = 14;
                break;
            case WidgetButtonPosition.OUTER:
            default:
                left = "calc(100% + 14px)";
                top = 7;
                break;
        }
    }

    return (
        <WidgetButton
            className="delete-button"
            onClick={onClick}
            icon="close"
            hoverColor="orangered"
            outlined={outlined}
            left={left}
            top={top}
        />
    );
}

export default DeleteElementButton;

import { AiGeneratedSlideType } from "common/aiConstants";
import { BlockStructureType } from "common/constants";
import { getStaticUrl } from "js/config";
import { getLogger, LogGroup } from "js/core/logger";
import AiGenerationService, { InsufficientCreditsError, TooManyRequestsError } from "js/core/services/aiGeneration";
import { detectTextContent } from "js/core/services/sharedModelManager";
import { buildSlideModel, findElementsWithImages, generateImagesForElement } from "js/core/services/slideModelBuilder";
import * as geom from "js/core/utilities/geom";
import { sanitizeHtmlText } from "js/core/utilities/htmlTextHelpers";
import { SVGGroup } from "js/core/utilities/svgHelpers";
import { trackActivity } from "js/core/utilities/utilities";
import { ShowDialog, ShowErrorDialog } from "js/react/components/Dialogs/BaseDialog";
import { ShowInsufficientCreditsDialog, ShowTooManyRequestsDialog } from "js/react/components/Dialogs/CredtsErrorDialog";
import { LoadingBotDialog } from "js/react/components/Dialogs/LoadingBotDialog";
import React from "react";

import { SlidePlaceholderPropertyPanel } from "../../Editor/ElementPropertyPanels/SlidePlaceholderUI";
import { BaseElement } from "../base/BaseElement";
import { SVGElement } from "../base/SVGElement";
import { TextElement } from "../base/Text/TextElement";

const logger = getLogger(LogGroup.ELEMENTS);

class SlidePlaceholderBackground extends SVGElement {
    get backgroundImageUrl() {
        return getStaticUrl("/images/placeholderbg.svg");
    }

    _load() {
        return new Promise(resolve => {
            const image = new Image();
            image.onload = () => {
                this.imageSize = new geom.Size(image.naturalWidth, image.naturalHeight);
                resolve();
            };
            image.src = this.backgroundImageUrl;
        });
    }

    renderSVG(props, styles) {
        return (<SVGGroup ref={this.ref} key={this.id}>
            <image
                x={-50}
                y={-50}
                href={this.backgroundImageUrl}
                onLoad={this.getImageOnLoadPromiseResolver(this.backgroundImageUrl)}
            />
        </SVGGroup>);
    }
}

class SlidePlaceholder extends BaseElement {
    get name() {
        return "Slide Placeholder";
    }

    getElementPropertyPanel() {
        return SlidePlaceholderPropertyPanel;
    }

    get preventSlideBackgroundColor() {
        return true;
    }

    getCanvasMargins() {
        return {
            left: 0,
            top: 0,
            right: 0,
            bottom: 0
        };
    }

    getBackgroundColor() {
        return this.palette.getColor("white");
    }

    _build() {
        this.canvas.$el.addClass("slide_placeholder_canvas");
        this.canvas.$el.parent().css("box-shadow", "none");

        this.background = this.addElement("background", () => SlidePlaceholderBackground, {
            preventExport: true
        });

        this.text = this.addElement("text", () => TextElement, {
            blockStructure: BlockStructureType.SINGLE_BLOCK,
            placeholder: "Add a note about this slide",
            scaleTextToFit: true,
            allowStyling: false,
            allowNewLines: true
        });
    }

    _calcProps(props, options) {
        const { size, children } = props;

        const backgroundProps = this.background.calcProps(size);
        backgroundProps.bounds = new geom.Rect(0, 0, size);

        const textProps = this.text.calcProps(size);
        textProps.bounds = new geom.Rect(0, 0, size);

        return { size };
    }

    _exportToSharedModel() {
        return this.text._exportToSharedModel();
    }

    _importFromSharedModel(model) {
        const textContent = detectTextContent(model);
        if (!textContent?.length) return;

        const textContentMerged = textContent.reduce((acc, { mainText, secondaryTexts }) => ({
            mainText: acc.mainText ? acc.mainText : mainText,
            secondaryTexts: [...acc.secondaryTexts, ...(acc.mainText ? [mainText] : []), ...secondaryTexts]
        }), { mainText: null, secondaryTexts: [] });

        return this.text._importFromSharedModel({ ...model, textContent: [textContentMerged] });
    }

    async finishWithAi() {
        try {
            const prompt = sanitizeHtmlText(this.text.blocks.map(block => block.textContent).join("\n"));
            if (!prompt) {
                ShowErrorDialog({
                    title: "No Slide Content", message: "Please add some text to use as a prompt to generate the new slide from."
                });
                return;
            }

            const loadingDialog = ShowDialog(LoadingBotDialog, {
                labels: [
                    "Gathering data...",
                    "Plotting narrative...",
                    "Curating supporting visuals...",
                ]
            });

            const generateSlideResponse = await AiGenerationService.generateSlide({
                prompt,
                allowedSlideTypes: Object.values(AiGeneratedSlideType)
            });

            const slideModel = await buildSlideModel(generateSlideResponse);

            const imagesToGenerate = findElementsWithImages(slideModel);
            if (imagesToGenerate.length > 0) {
                await Promise.all(imagesToGenerate.map(image => generateImagesForElement(image)));
            }

            this.canvas.model.elements = slideModel.states[0];
            this.canvas.model.layout = slideModel.layout;
            this.canvas.model.template_id = slideModel.template_id;
            await this.canvas.updateCanvasModel(false);

            loadingDialog.close();

            // add analytics event
            trackActivity("Slide", "Generate", null, null, {
                presentationId: this.canvas.model.presentation_id,
                prompt,
                aiResponse: JSON.stringify(generateSlideResponse),
                source: "SlidePlaceholder"
            });
        } catch (err) {
            logger.error(err, "[SlidePlaceholder] finishWithAi");

            if (err instanceof InsufficientCreditsError) {
                ShowInsufficientCreditsDialog();
            } else if (err instanceof TooManyRequestsError) {
                ShowTooManyRequestsDialog();
            } else {
                ShowErrorDialog({ title: "Error", message: "Error generating slide from TBD slide content" });
            }
        }
    }
}

export const elements = {
    SlidePlaceholder,
};

import React, { Component } from "react";
import { MenuItem } from "@material-ui/core";

import { PropertyPanelContainer, PropertySection } from "../../../../EditorComponents/PropertyPanel";
import { ImageOption, ImageOptionList } from "../../../../Components/ImageOptionList";
import { StaticImage } from "../../../../Components/StaticImage";
import { ResizeDirection } from "../../../../../common/constants";
import { WithLabel } from "../../../../Components/WithLabel";
import { Popup, PopupContent, PopupContainer, PopupPreview } from "../../../../Components/Popup";
import { Icon } from "../../../../Components/Icon";
import { Button } from "../../../../Components/Button";

import { CollectionColorPicker } from "../EditorComponents/ColorPickers/CollectionColorPicker";
import { ControlBar } from "../ElementControlBars/Components/ControlBar";
import { CollectionElementControlBar } from "../ElementControlBars/CollectionElementControlBar";
import { ItemColorPicker } from "../EditorComponents/ColorPickers/ItemColorPicker";
import { CollectionItemElementSelection } from "../ElementSelections/CollectionItemElementSelection";
import { defaultDragPositionProps, defaultDragResizeProps } from "../../../../editor/PresentationEditor/DragElementManager";
import { TextElementSelection } from "../ElementSelections/TextElementSelection";

export class VennDiagramPropertyPanel extends Component {
    handleSetPresetLayout = type => {
        const { element } = this.props;
        const items = element.itemCollection;

        const createOrTrimItems = count => {
            if (items.length > count) {
                while (items.length > count) {
                    element.deleteItem(items.pop().id);
                }
            } else {
                while (items.length < count) {
                    element.addItem();
                }
            }
        };

        switch (type) {
            case "2up":
                createOrTrimItems(2);
                break;
            case "3up":
                createOrTrimItems(3);
                break;
            case "4up":
                createOrTrimItems(4);
                break;
        }

        element.presetLayout();
        element.canvas.refreshCanvas().then(() => {
            element.beautify();
            element.canvas.updateCanvasModel();
        });
    }

    render() {
        const { element } = this.props;

        return (
            <PropertyPanelContainer>
                <PropertySection>
                    <WithLabel label="Bubble Colors">
                        <CollectionColorPicker element={element} />
                    </WithLabel>
                </PropertySection>
                <PropertySection label="Presets">
                    <ImageOptionList cols={3} size={70} onChange={this.handleSetPresetLayout}>
                        <ImageOption label="2 circles" value="2up">
                            <StaticImage src="/images/ui/venn/2venn.svg" />
                        </ImageOption>
                        <ImageOption label="3 circles" value="3up">
                            <StaticImage src="/images/ui/venn/3venn.svg" />
                        </ImageOption>
                        <ImageOption label="4 circles" value="4up">
                            <StaticImage src="/images/ui/venn/4venn.svg" />
                        </ImageOption>
                    </ImageOptionList>
                </PropertySection>
            </PropertyPanelContainer>
        );
    }
}

export class VennDiagramControlBar extends CollectionElementControlBar {
    get addItemLabel() {
        return "Add Bubble";
    }
}

export function VennDiagramLabelControlBar({ element, selectionLayerController }) {
    const userPositionedLabel = element.parentElement.model.userPositionedLabel;

    if (!userPositionedLabel) {
        return null;
    }

    const handleAutoPositionLabel = async () => {
        await selectionLayerController.setSelectedElements([]);

        const containerElement = element.parentElement;
        containerElement.model.userPositionedLabel = false;
        containerElement.model.labelCenterX = containerElement.model.labelCenterY = null;
        containerElement.parentElement.beautify();
        await containerElement.canvas.updateCanvasModel(true);

        await selectionLayerController.setSelectedElements([element]);
    };

    return (<ControlBar>
        <Button blue onClick={handleAutoPositionLabel}>
            <Icon>auto_fix_high</Icon>
            Auto position label
        </Button>
    </ControlBar>);
}

export class VennDiagramItemControlBar extends Component {
    render() {
        const { element } = this.props;

        return (
            <ControlBar>
                <ItemColorPicker element={element} />
                <Popup icon="settings">
                    <PopupContent>
                        {closePopup => (
                            <>
                                <MenuItem onClick={() => {
                                    element.parentElement.itemCollection.remove(element.model);
                                    element.parentElement.itemCollection.push(element.model);
                                    element.parentElement.saveModel();
                                    closePopup();
                                }}><Icon>flip_to_front</Icon>Bring to Front</MenuItem>
                                <MenuItem onClick={() => {
                                    element.parentElement.itemCollection.remove(element.model);
                                    element.parentElement.itemCollection.insert(element.model, 0);
                                    element.parentElement.saveModel();
                                    closePopup();
                                }}><Icon>flip_to_back</Icon>Send to Back</MenuItem>
                            </>
                        )}
                    </PopupContent>
                </Popup>
            </ControlBar>
        );
    }
}

export class VennDiagramItemSelection extends CollectionItemElementSelection {
    get canDrag() {
        return true;
    }

    get canResize() {
        return true;
    }

    get dragPositionProps() {
        const { element } = this.props;

        return {
            ...defaultDragPositionProps,
            dragOpacity: 0,
            onDragEnd: async ({ dragOffset }) => {
                element.model.x += dragOffset.x;
                element.model.y += dragOffset.y;
            }
        };
    }

    get dragResizeProps() {
        const { element } = this.props;

        let initialRadius;
        return {
            ...defaultDragResizeProps,
            resizeDirections: [ResizeDirection.RIGHT],
            onDragStart: async () => {
                initialRadius = element.model.radius;
            },
            onDrag: async ({ dragOffset }) => {
                element.refreshModel({
                    radius: Math.clamp(initialRadius + dragOffset.x / 2, 75, 400)
                });
            },
            onDragEnd: async () => {
                element.parentElement.beautify();
            }
        };
    }
}

export class VennDiagramLabelSelection extends TextElementSelection {
    get showSelectionBorder() {
        return true;
    }

    get canDrag() {
        return true;
    }

    get dragPositionProps() {
        const { element } = this.props;

        let dragStartProps;
        return {
            ...defaultDragPositionProps,
            dragOpacity: 0,
            onDragStart: async () => {
                const containerElement = element.parentElement;

                dragStartProps = { x: containerElement.model.labelCenterX ?? 0, y: containerElement.model.labelCenterY ?? 0 };
            },
            onDragEnd: async ({ dragOffset }) => {
                const containerElement = element.parentElement;

                containerElement.model.labelCenterX = dragStartProps.x + dragOffset.x;
                containerElement.model.labelCenterY = dragStartProps.y + dragOffset.y;
                containerElement.model.userPositionedLabel = true;

                await element.canvas.refreshCanvas();
            }
        };
    }
}

import React, { Component } from "react";
import styled from "styled-components";
import * as XLSX from "xlsx";
import firebase from "firebase/compat/app";

import Api from "js/core/api";
import { app } from "js/namespaces";
import getLogger, { LogGroup } from "js/core/logger";
import { getStaticUrl } from "js/config";
import * as googleAuth from "js/core/oauth/googleAuth";
import * as gDrive from "js/core/utilities/gDrive";
import { FileType } from "js/core/utilities/fileSourcesAndTypes";
import { trackActivity } from "js/core/utilities/utilities";
import { TextButton } from "js/react/components/UiComponents";
import { GoogleButton } from "js/react/views/Auth/AuthUi";
import Spinner from "js/react/components/Spinner";
import { ShowMessageDialog, ShowConfirmationDialog, ShowErrorDialog } from "js/react/components/Dialogs/BaseDialog";
import { Gap10, Gap30 } from "js/react/components/Gap";
import { spreadsheetDataToXlsxWorkbook, SpreadsheetData } from "js/core/utilities/xlsx";

const logger = getLogger(LogGroup.DATA_SOURCE);

const Container = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #A9A9A9;
    padding: 30px 50px;
    margin-bottom: 30px;
    & .label-text {
        font-size: 18px;
        font-weight: 600;
    }
    & .description-text {
        text-align: center; 
        color: #666666;
        font-size: 16px;
        font-weight: 400;
    }
    & .disconnect-button-text {
        color: #11A9E2;
        font-size: 12px;
        font-weight: 400;
        text-decoration-line: underline;
        text-transform: uppercase;
    }
`;

interface PickerCallbackData {
    action: "picked" | "cancel" | "loaded",
    docs?: [{
        id: string,
        name: string,
        lastEditedUtc: number,
        url: string,
        iconUrl: string,
        embedUrl: string,
    }]
}

interface Props {
    auth: any,
    firebaseUser: any,
    handleAddedData: Function,
    handleDisconnect: Function,
}

interface State {
    userHasPasswordProvider: boolean,
    userHasGoogleLinked: boolean,
    userHasGoogleOfflineAccess: boolean,
    userHasGDriveSyncEnabled: boolean,
    userGoogleAccessToken: string,
    userGoogleLinkFetching: boolean,
    spreadsheetDataFetching: boolean,
    spreadsheetData: SpreadsheetData,
    disconnecting: boolean,
}

export default class GoogleSheetsPane extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            userHasPasswordProvider: !!props.firebaseUser.providerData.find(p => p.providerId === "password"),
            userHasGoogleLinked: !!props.firebaseUser.providerData.find(p => p.providerId === "google.com"),
            userHasGoogleOfflineAccess: false,
            userHasGDriveSyncEnabled: app.user.get("isGDriveEnabled"),
            userGoogleAccessToken: "",
            userGoogleLinkFetching: false,
            spreadsheetDataFetching: false,
            spreadsheetData: null,
            disconnecting: false
        };
    }

    componentDidMount() {
        this.fetchGoogleAuthTokens();
    }

    setSpreadsheetDataFetching(spreadsheetDataFetching: boolean, spreadsheetData?: SpreadsheetData) {
        this.setState({ spreadsheetDataFetching, spreadsheetData });
    }

    fetchSpreadsheetData = async (spreadsheetId: string) => {
        this.setSpreadsheetDataFetching(true);

        let spreadsheetData: SpreadsheetData = null;
        try {
            spreadsheetData = await gDrive.getSpreadsheetData(spreadsheetId);
            this.setSpreadsheetDataFetching(false, spreadsheetData);

            const workbookData: XLSX.WorkBook = spreadsheetDataToXlsxWorkbook(spreadsheetData);
            this.props.handleAddedData(workbookData, spreadsheetData);
        } catch (err) {
            logger.error(err, "[GoogleSheetsPane] gDrive.getSpreadsheetData() failed", { spreadsheetId });
            this.setSpreadsheetDataFetching(false);

            ShowErrorDialog({
                error: "Sorry, we could not process your request",
                message: err.message
            });
        }
    }

    fetchGoogleAuthTokens = async () => {
        this.setState({ userGoogleLinkFetching: true });

        try {
            const { hasTokens, accessToken } = await Api.getGoogleTokensForScope.get({ scope: gDrive.SCOPES.join(" ") });
            this.setState({ userGoogleLinkFetching: false, userHasGoogleOfflineAccess: hasTokens, userGoogleAccessToken: accessToken });
        } catch (err) {
            logger.error(err, "[GoogleSheetsPane] Api.getGoogleTokensForScope.get() failed");
            this.setState({ userGoogleLinkFetching: false });
        }
    }

    handleGoogleAuth = async () => {
        const { firebaseUser } = this.props;
        const { userHasGoogleLinked, userHasGoogleOfflineAccess, spreadsheetDataFetching } = this.state;
        if (spreadsheetDataFetching) return;

        try {
            if (!userHasGoogleLinked || (userHasGoogleLinked && !userHasGoogleOfflineAccess)) {
                trackActivity("GDrive", "DataLinkGrantAccess", null, null, {}, { audit: true, skipAmplitude: false });

                const tokens = await googleAuth.grantOfflineAccessWithDialogIfNeeded(gDrive.SCOPES.join(" "));

                if (!userHasGoogleLinked) {
                    const credential = firebase.auth.GoogleAuthProvider.credential(tokens.idToken);
                    await firebaseUser.linkWithCredential(credential);
                }

                this.setState({ userHasGoogleLinked: true, userHasGoogleOfflineAccess: true, userGoogleAccessToken: tokens.accessToken });
            }

            this.openPickerUI();
        } catch (err) {
            if (err instanceof googleAuth.GoogleAuthFlowWasInterruptedError) {
                // User cancelled, ignore
                return;
            }

            if (err.message === "Wrong user") {
                ShowMessageDialog({
                    title: "Error",
                    message: <span>You must have selected a wrong account, your Google Drive account must match with the Google account you linked with Beautiful.ai</span>
                });
            }

            logger.error(err, "[GoogleSheetsPane] handleGoogleAuth() failed");
        }
    }

    handleDisconnect = async () => {
        if (this.state.disconnecting) return;

        const { auth, firebaseUser } = this.props;
        const { userHasGDriveSyncEnabled, userHasPasswordProvider } = this.state;

        ShowConfirmationDialog({
            title: "Disconnect Google Account?",
            message: (
                <span>
                    {userHasGDriveSyncEnabled && "Your presentation library will no longer sync with Google Drive."}
                    {userHasGDriveSyncEnabled && <br />}
                    Any linked data will no longer automatically update.
                    <br /><br />
                    You can link to this account again at any time.
                </span>
            ),
            buttonOptions: { acceptButtonColor: "red" },
            okButtonLabel: "Disconnect",
            acceptCallback: async () => {
                this.setState({ disconnecting: true });

                try {
                    if (userHasGDriveSyncEnabled) {
                        await gDrive.disable();
                        this.setState({ userHasGDriveSyncEnabled: false });
                    }

                    logger.info("Unlinking Google account from Firebase");
                    await auth.unlinkProvider(firebaseUser, "google.com");

                    logger.info("Deleting credentials from server");
                    await Api.unlinkGoogleAuth.delete();

                    if (!userHasPasswordProvider) {
                        logger.info("No password provider, sending password reset email");
                        await auth.sendPasswordResetEmail(firebaseUser.email);
                    }

                    this.setState({
                        disconnecting: false,
                        userHasGoogleLinked: false,
                        userHasGoogleOfflineAccess: false,
                        userGoogleAccessToken: ""
                    });

                    await this.props.handleDisconnect();
                } catch (err) {
                    logger.error(err, "[GoogleSheetsPane] handleDisconnect() failed");
                    this.setState({ disconnecting: false });

                    ShowErrorDialog({
                        error: "Sorry, we could not process your request",
                        message: err.message
                    });
                }
            }
        });
    };

    openPickerUI = async () => {
        const { userGoogleAccessToken } = this.state;

        const picker = await gDrive.preparePickerUI(userGoogleAccessToken, this.pickerCallback, [FileType.Spreadsheet]);
        picker.setVisible(true);

        const pickerElems = document.querySelectorAll(".picker");
        pickerElems.forEach(e => {
            // @ts-ignore
            e.style.zIndex = 20000;
        });
    }

    pickerCallback = async (data: PickerCallbackData) => {
        switch (data.action) {
            case "picked":
                await this.fetchSpreadsheetData(data.docs[0].id);
                break;
            case "cancel":
            case "loaded":
            default:
                // maybe record as analytics event?
                break;
        }
    }

    render() {
        const { userHasGoogleLinked, userHasGoogleOfflineAccess, userGoogleLinkFetching, spreadsheetDataFetching, disconnecting } = this.state;

        return (
            <Container>
                <img src={getStaticUrl("/images/data-linking/google-sheets.svg")} width={50} height={50} />
                <Gap30 />
                <span className="label-text">Import from or link data to Google Sheets.</span>
                <Gap10 />
                {!userGoogleLinkFetching && (<>
                    <span className="description-text">Linked data will update automatically whenever your source file is changed.</span>
                    <Gap30 />
                    <GoogleButton /** @ts-ignore */
                        fullWidth={false}
                        label={!spreadsheetDataFetching && ((userHasGoogleLinked && userHasGoogleOfflineAccess) ? "Select File from Google" : "Connect to Google")}

                        onClick={this.handleGoogleAuth}>
                        {spreadsheetDataFetching && <Spinner />}
                    </GoogleButton>
                    {userHasGoogleLinked && userHasGoogleOfflineAccess && (
                        <>
                            <Gap10 />
                            <TextButton /** @ts-ignore */
                                onClick={this.handleDisconnect}
                                color="secondary"
                                disabled={spreadsheetDataFetching}
                            >
                                <div className="disconnect-button-text">
                                    {disconnecting && <Spinner />}
                                    {!disconnecting && "Disconnect Account"}
                                </div>
                            </TextButton>
                        </>
                    )}
                </>)}
            </Container>
        );
    }
}

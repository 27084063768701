import * as geom from "js/core/utilities/geom";
import { _ } from "js/vendor";
import { HorizontalAlignType, VerticalAlignType, AuthoringBlockType, TextStyleType, DecorationStyle } from "common/constants";
import { detectTextContent } from "js/core/services/sharedModelManager";

import { CollectionElement, CollectionItemElement } from "../base/CollectionElement";
import { TextElement } from "../base/Text/TextElement";
import { BaseElement } from "../base/BaseElement";
import { Icon } from "../base/MediaElements/IconElement";
import { FindBestFit } from "../layouts/FindBestFit";
import { layoutHelper } from "../layouts/LayoutHelper";
import { BigNumberItemControlBar, BigNumbersControlBar, BigNumberPropertyPanel } from "../../Editor/ElementPropertyPanels/BigNumberUI";
import { BigNumberItemSelection } from "../../Editor/ElementSelections/BigNumberItemSelection";
import { BigNumberChangeInValueIconDefaultOverlay } from "../../Editor/ElementDefaultOverlays/BigNumbersDefaultOverlay";

export class BigNumbers extends CollectionElement {
    static get schema() {
        return {
            horizontalAlign: HorizontalAlignType.CENTER,
            collectionColor: "background_light"
        };
    }

    getElementPropertyPanel() {
        return BigNumberPropertyPanel;
    }

    getElementControlBar() {
        return BigNumbersControlBar;
    }

    get name() {
        return "Big Numbers";
    }

    static get maximumItems() {
        return 8;
    }

    getChildItemType(itemModel) {
        return BigNumberItem;
    }

    get maxItemCount() {
        return BigNumbers.maximumItems;
    }

    get minItemWidth() {
        return 200;
    }

    get rows() {
        return new Set(this.itemElements.map(item => item.model.row)).size;
    }

    get defaultItemData() {
        return {
            ...BigNumberItem.schema,
            row: Math.max(0, this.rows - 1),
            showLabel: _.last(this.itemCollection)?.showLabel ?? true,
            showChangeInValue: _.last(this.itemCollection)?.showChangeInValue ?? true,
            showDescription: _.last(this.itemCollection)?.showDescription ?? false
        };
    }

    getItemsInRows() {
        let itemsInRows = [];
        for (const item of this.itemElements) {
            const rowIdx = item.model.row ?? 0;
            if (!itemsInRows[rowIdx]) {
                itemsInRows[rowIdx] = [];
            }
            itemsInRows[rowIdx].push(item);
        }

        // Remove empty rows
        itemsInRows = itemsInRows.filter(items => items);

        // Reset row indexes in model
        itemsInRows.forEach((rowItems, rowIdx) => rowItems.forEach(item => item.model.row = rowIdx));

        return itemsInRows;
    }

    _build() {
        this.buildItems();
    }

    _calcProps(props, options) {
        const { size } = props;

        const itemsInRows = this.getItemsInRows();

        const vGap = this.styles.vGap;
        const hGap = (this.styles.hGap ?? 10);

        const rowsCount = itemsInRows.length;
        const maxRowHeight = (size.height - (rowsCount - 1) * vGap) / rowsCount;

        let y = 0;
        for (const rowItems of itemsInRows) {
            // For each row, find best fit scale
            const { rowHeight, stylesScale, isFit } = FindBestFit({
                min: 0.4,
                max: 1,
                preCheckMax: true,
                doFinalLayout: true,
                layout: stylesScale => {
                    let x = 0;
                    let rowHeight = 0;
                    let isTextFit = true;
                    for (const item of rowItems) {
                        const itemProps = item.calcProps(new geom.Size(size.width, maxRowHeight), { stylesScale });
                        isTextFit = isTextFit && itemProps.isTextFit;
                        itemProps.bounds = new geom.Rect(x, y, itemProps.size);

                        x += itemProps.size.width + hGap;
                        rowHeight = Math.max(rowHeight, itemProps.size.height);
                    }

                    return {
                        rowHeight,
                        stylesScale,
                        isFit: (x - hGap) <= size.width && rowHeight <= maxRowHeight && isTextFit
                    };
                }
            });

            props.isFit = isFit;

            if (this.model.layout === "fill") {
                // Recalc with fill width and rowheight
                const totalWidthOfItems = layoutHelper.getTotalBoundsOfItems(rowItems).width;
                if (totalWidthOfItems < size.width) {
                    const gapWidth = hGap * (rowItems.length - 1);
                    const scale = (size.width - gapWidth) / (totalWidthOfItems - gapWidth);
                    let x = 0;
                    for (const item of rowItems) {
                        const itemProps = item.calcProps(new geom.Size(Math.floor(item.calculatedProps.size.width * scale), maxRowHeight), {
                            forceWidth: true,
                            stylesScale
                        });
                        itemProps.bounds = new geom.Rect(x, y, itemProps.size);
                        x += itemProps.size.width + hGap;
                    }
                }
            }

            // Recalc with the correct row height
            let x = 0;
            for (const item of rowItems) {
                if (item.bounds.height != rowHeight) {
                    const itemProps = item.calcProps(new geom.Size(item.bounds.width, rowHeight), {
                        forceWidth: true,
                        forceHeight: true,
                        stylesScale
                    });
                    itemProps.bounds = new geom.Rect(x, y, itemProps.size);
                }
                x += item.calculatedProps.size.width + hGap;
            }

            layoutHelper.alignItemsInContainer(rowItems, size, HorizontalAlignType.CENTER, null);
            y += rowHeight + vGap;
        }

        layoutHelper.alignItemsInContainer(this.itemElements, size, null, VerticalAlignType.MIDDLE);

        return { size };
    }

    _exportToSharedModel() {
        const textContent = this.itemElements.map(item => {
            const label = item.label ? item.label._exportToSharedModel().textContent[0] : {};
            const description = item.description ? item.description._exportToSharedModel().textContent[0] : {};
            const value = item.value._exportToSharedModel().textContent[0];

            return {
                mainText: label.mainText ? label.mainText : description.mainText ? description.mainText : value.mainText,
                secondaryTexts: [...(label.mainText && description.mainText ? [description.mainText] : []), value.mainText]
            };
        });

        return { textContent, collectionColor: this.collectionColor };
    }

    _importFromSharedModel(model) {
        const textContent = detectTextContent(model);
        if (!textContent?.length) {
            return;
        }

        const items = textContent.map(({ mainText, secondaryTexts }, i) => {
            const label = {
                blocks: [
                    {
                        html: mainText.text,
                        textStyle: TextStyleType.TITLE,
                        type: AuthoringBlockType.TEXT,
                    }
                ]
            };

            const description = secondaryTexts.length > 1 ? {
                blocks: [
                    {
                        html: secondaryTexts[0].text,
                        textStyle: TextStyleType.BODY,
                        type: AuthoringBlockType.TEXT,
                    }
                ]
            } : {};

            const value = secondaryTexts.length ? {
                blocks: [
                    {
                        html: secondaryTexts[secondaryTexts.length - 1].text,
                        textStyle: TextStyleType.HEADING,
                        type: AuthoringBlockType.TEXT,
                    }
                ]
            } : {};

            return {
                row: i % 3,
                showLabel: true, showChangeInValue: false, showDescription: secondaryTexts.length > 1,
                label, text: description, value
            };
        });

        items.splice(this.maxItemCount);
        return { items, collectionColor: model.collectionColor };
    }

    _migrate_10_02() {
        if (this.canvas.getSlideColor() == "neutral" || this.canvas.getSlideColor() == "primary") {
            if (this.canvas.getBackgroundColor().name == "background_accent") {
                this.model.collectionColor = "background_light";
            } else {
                this.model.collectionColor = "background_accent";
            }
        } else {
            this.model.collectionColor = this.canvas.getSlideColor();
        }
    }
}

class BigNumberItem extends CollectionItemElement {
    static get schema() {
        return {
            showLabel: true,
            showChangeInValue: true,
            showDescription: false,
            label: {
                text: "My Statistic"
            },
            value: {
                text: "$50,000"
            },
            changeLabel: {
                text: "100%"
            },
            positive: true,
            decorationStyle: DecorationStyle.FILLED
        };
    }

    getElementControlBar() {
        return BigNumberItemControlBar;
    }

    getElementSelection() {
        return BigNumberItemSelection;
    }

    get selectionPadding() {
        return 0;
    }

    get showLabel() {
        return this.model.showLabel;
    }

    get showChangeInValue() {
        return this.model.showChangeInValue;
    }

    get showDescription() {
        return this.model.showDescription;
    }

    get minItemWidth() {
        return this.parentElement.minItemWidth;
    }

    _build() {
        if (this.showLabel) {
            this.label = this.addElement("label", () => BigNumberLabel, {
                autoWidth: true,
                autoHeight: true,
                singleLine: true,
                scaleTextToFit: false,
                syncFontSizeWithSiblings: true,
                backgroundElement: this.decoration
            });
        }

        this.value = this.addElement("value", () => BigNumberValue, {
            autoWidth: true,
            autoHeight: true,
            singleLine: true,
            scaleTextToFit: false,
            syncFontSizeWithSiblings: true,
            backgroundElement: this.decoration
        });

        if (this.showChangeInValue) {
            this.changeInValue = this.addElement("changeInValue", () => BigNumberChangeInValue);
        }

        if (this.showDescription) {
            this.description = this.addElement("text", () => BigNumberDescription, {
                autoWidth: true,
                autoHeight: true,
                scaleTextToFit: false,
                syncFontSizeWithSiblings: true,
                backgroundElement: this.decoration
            });
        }
    }

    _loadStyles(styles) {

    }

    _calcProps(props, options) {
        const { size } = props;

        let y = 0;

        let isTextFit = true;

        let labelProps;
        if (this.showLabel) {
            labelProps = this.label.calcProps(size, { stylesScale: options.stylesScale });
            labelProps.layer = 1;
            isTextFit = isTextFit && labelProps.isTextFit;
            labelProps.bounds = new geom.Rect(0, 0, labelProps.size);
            y += labelProps.size.height;
        }

        let changeInValueProps;
        if (this.showChangeInValue) {
            changeInValueProps = this.changeInValue.calcProps(size, { stylesScale: options.stylesScale });
        }

        const valueProps = this.value.calcProps(new geom.Size(size.width - (changeInValueProps?.size.width ?? 0), size.height), { stylesScale: options.stylesScale });
        isTextFit = isTextFit && valueProps.isTextFit;
        valueProps.bounds = new geom.Rect(0, y, valueProps.size);
        y += valueProps.size.height;

        if (this.showChangeInValue) {
            // Shift change in value to the right
            changeInValueProps.bounds = new geom.Rect(valueProps.bounds.right, valueProps.bounds.centerV - changeInValueProps.size.height / 2, changeInValueProps.size);
        }

        let totalWidth = valueProps.size.width + (changeInValueProps?.size.width ?? 0);

        if (this.showLabel) {
            totalWidth = Math.max(totalWidth, labelProps.size.width);
        }

        let descriptionProps;
        if (this.showDescription) {
            descriptionProps = this.description.calcProps(new geom.Size(size.width, size.height), { stylesScale: options.stylesScale });
            descriptionProps.layer = 1;
            isTextFit = isTextFit && descriptionProps.isTextFit;
            descriptionProps.bounds = new geom.Rect(0, y, descriptionProps.size);
            y += descriptionProps.size.height;

            totalWidth = Math.max(totalWidth, descriptionProps.size.width);
        }

        const calculatedWidth = options.forceWidth ? size.width : Math.max(totalWidth, this.minItemWidth);
        const calculatedHeight = options.forceHeight ? size.height : y;
        return { size: new geom.Size(calculatedWidth, calculatedHeight), isTextFit };
    }

    _applyColors() {
        this.colorSet.backgroundColor = this.palette.getColor(this.model.color ?? this.parentElement.collectionColor, this.getBackgroundColor());
    }
}

class BigNumberLabel extends TextElement {

}

class BigNumberValue extends TextElement {

}

class BigNumberDescription extends TextElement {

}

class BigNumberChangeInValue extends BaseElement {
    get iconId() {
        return this.model.icon ?? "arrow-up";
    }

    _build() {
        this.icon = this.addElement("icon", () => BigNumberChangeInValueIcon, {
            canRollover: true
        });
        this.label = this.addElement("changeLabel", () => BigNumberChangeInValueLabel, {
            autoWidth: true,
            placeholder: "Type value",
            syncFontSizeWithSiblings: true,
            backgroundElement: this.parentElement.decoration
        });
    }

    _calcProps(props, options) {
        const { size } = props;

        const height = 50;

        const iconProps = this.icon.calcProps(new geom.Size(height, height));
        iconProps.bounds = new geom.Rect(0, 0, iconProps.size);

        const labelProps = this.label.calcProps(new geom.Size(size.width - iconProps.size.width, height));
        labelProps.bounds = new geom.Rect(iconProps.size.width, 0, labelProps.size);

        return { size: new geom.Size(iconProps.bounds.width + labelProps.bounds.width, height) };
    }
}

class BigNumberChangeInValueLabel extends TextElement {

}

class BigNumberChangeInValueIcon extends Icon {
    get iconId() {
        if (this.model.positive) {
            return "arrow-up";
        } else {
            return "arrow-down";
        }
    }

    get _doubleClickToSelect() {
        return false;
    }

    get showDefaultOverlay() {
        return true;
    }

    getElementRollover() {
        return null;
    }

    getElementDefaultOverlay() {
        return BigNumberChangeInValueIconDefaultOverlay;
    }

    _applyColors() {
        if (this.model.positive) {
            this.styles.iconColor = this.palette.getColor("positive", this.getBackgroundColor());
        } else {
            this.styles.iconColor = this.palette.getColor("negative", this.getBackgroundColor());
        }
    }
}

export const elements = {
    BigNumbers,
    BigNumberItem
};


import React from "react";
import styled from "styled-components";

import { Popover } from "@material-ui/core";

import { getStaticUrl } from "../../../../../config";
import { SeriesTypeLabels } from "../../../../../../common/constants";
import { NestedMenuItem } from "../../../../../react/components/NestedMenuItem";
import * as geom from "../../../../../core/utilities/geom";

import * as helpers from "./helpers";
import { MenuItem } from "../../../../../Components/Menu";
import { Icon } from "../../../../../Components/Icon";
import { ColorPicker } from "../../../../../Components/ColorPicker";
import { PopupContent } from "../../../../../Components/Popup";
import { themeColors } from "../../../../../react/sharedStyles";

const WidgetButtonContainer = styled.div.attrs(({ position, visible = false, disabled = false }) => ({
    style: {
        left: position.x,
        top: position.y,
        opacity: visible ? 1 : undefined,
        pointerEvents: disabled ? "none" : "auto"
    }
}))`
    position: absolute;
    width: 22px;
    height: 22px;
    transform: translate(-50%, -50%);

    cursor: pointer;
    background: ${themeColors.ui_blue};
    color: #fff;
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    opacity: 0;

    &:hover {
        opacity: 1;
    }

    .bai-icon {
        color: white !important;
        font-size: 16px;
    }
`;

const StrokeColorMenuItem = styled(MenuItem)`
    &&& {
        pointer-events: none;

        > div {
            pointer-events: auto;
            margin-left: 6px;
        }

        &:hover {
            background: none;
        }
    }
`;

export class SeriesWidgets extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            popoverPosition: null,
            selectedPoint: null,
            rolloverPoint: null,
            widgetsDisabled: false
        };

        this.isDraggingPoint = false;
        this.dragStartProps = null;

        this.animationFrameHandledAt = null;

        this.pointWidgetRefs = [];
    }

    get plotBoxBounds() {
        const { element, canvas } = this.props;

        const { x, y, width, height } = element.chart.plotBox;
        return new geom.Rect(x, y, width, height).spaceScale(canvas.getScale());
    }

    get plotBoxScreenBounds() {
        const { element, canvas } = this.props;

        const { x, y, width, height } = element.chart.plotBox;
        const chartScreenBounds = element.getScreenBounds();
        return new geom.Rect(x, y, width, height).spaceScale(canvas.getScale()).offset(chartScreenBounds.position);
    }

    getPointPosition(point) {
        const { element, canvas, series } = this.props;

        const { width, height } = element.chart.plotBox;
        const plotBoxBounds = this.plotBoxBounds;

        if (series.type === "bar") {
            return new geom.Point(
                width - point.plotY,
                height - point.barX - point.pointWidth / 2
            )
                .scale(canvas.getScale())
                .offset(plotBoxBounds.position);
        }

        let x;
        if (series.type === "column") {
            x = point.barX + point.pointWidth / 2;
        } else {
            x = point.plotX;
        }

        return new geom.Point(x, point.plotY)
            .scale(canvas.getScale())
            .offset(plotBoxBounds.position);
    }

    handleAddDataHilite = async () => {
        const { element, selectionLayerController } = this.props;
        const { selectedPoint } = this.state;

        const annotations = helpers.getAnnotations(element);

        const annotation = annotations.addItem(helpers.getDataHiliteAnnotationModel(element.uniquePath, helpers.getSeriesId(selectedPoint), selectedPoint.index));

        this.handleClosePopup();

        await element.updateModel();

        const addedAnnotation = annotations.getItemElementById(annotation.id);
        await selectionLayerController.setSelectedElements([addedAnnotation]);
    }

    handleRemoveDataHilite = async () => {
        const { element } = this.props;
        const { selectedPoint } = this.state;

        const annotations = helpers.getAnnotations(element);

        const annotationsElementsLinkedToPoint = helpers.getAnnotationsElementsLinkedToPoint(element, selectedPoint);
        const existingDataHilite = annotationsElementsLinkedToPoint.find(element => element.model.annotationType === "DataHilite");
        if (!existingDataHilite) {
            return;
        }

        annotations.deleteItem(existingDataHilite.id);

        this.handleClosePopup();

        await element.updateModel();
    }

    handleAddDataNote = async () => {
        const { element, selectionLayerController } = this.props;
        const { selectedPoint } = this.state;

        const annotations = helpers.getAnnotations(element);

        const { annotationModel, connectorModel } = helpers.getDataNoteAnnotationAndConnectorModels(element.uniquePath, helpers.getSeriesId(selectedPoint), selectedPoint.index);
        const annotation = annotations.addItem(annotationModel);
        annotations.connectors.addItem(connectorModel);

        this.handleClosePopup();

        await element.updateModel();

        const addedAnnotation = annotations.getItemElementById(annotation.id);
        await selectionLayerController.setSelectedElements([addedAnnotation]);
    }

    handleRemoveDataNote = async () => {
        const { element } = this.props;
        const { selectedPoint } = this.state;

        const annotations = helpers.getAnnotations(element);

        const annotationsElementsLinkedToPoint = helpers.getAnnotationsElementsLinkedToPoint(element, selectedPoint);
        const existingDataNote = annotationsElementsLinkedToPoint.find(element => element.model.annotationType === "DataNote");
        if (!existingDataNote) {
            return;
        }

        annotations.deleteItem(existingDataNote.id);

        this.handleClosePopup();

        await element.updateModel();
    }

    handleAddChangeInValue = async () => {
        const { element } = this.props;
        const { selectedPoint } = this.state;

        const annotations = helpers.getAnnotations(element);

        const series = selectedPoint.series;
        const endPoint = series.points[(selectedPoint.index + 1) % series.points.length];

        const connectorModel = annotations.connectors.addItem(
            helpers.getShowChangeInValueConnectorModel(element.uniquePath, helpers.getSeriesId(selectedPoint), selectedPoint.index, endPoint.index)
        );

        this.handleClosePopup();

        await element.updateModel();

        this.setState({ widgetsDisabled: true });

        helpers.handleDragChangeInValueAnchor({
            plotBoxScreenBounds: this.plotBoxScreenBounds,
            point: selectedPoint,
            annotations,
            connectorModel,
            chartElement: element,
            isStart: false,
            onDragEnd: () => {
                this.setState({ widgetsDisabled: false });
            }
        });
    }

    handleSetLineStyle = async type => {
        const { element, series } = this.props;
        const { selectedPoint } = this.state;

        element.setLineStyle(selectedPoint.x, series, type);

        this.handleClosePopup();

        await element.updateModel();
    }

    handlePointMouseDown = (point, event) => {
        const { element } = this.props;

        this.dragStartProps = {
            yAxisModelSettings: element.chartModel.yAxis
                ? {
                    min: element.chartModel.yAxis.min,
                    max: element.chartModel.yAxis.max,
                    tickInterval: element.chartModel.yAxis.tickInterval
                }
                : null,
            yAxis2ModelSettings: element.chartModel.yAxis2
                ? {
                    min: element.chartModel.yAxis2.min,
                    max: element.chartModel.yAxis2.max,
                    tickInterval: element.chartModel.yAxis2.tickInterval
                }
                : null,
            chartScreenBounds: element.getScreenBounds(),
            plotBox: point.series.getPlotBox(),
            boundsTop: element.getScreenBounds().top + point.series.getPlotBox().translateY
        };

        this.setState({ selectedPoint: point }, () => {
            window.addEventListener("mousemove", this.handleMouseMove);
            window.addEventListener("mouseup", this.handleMouseUp);
        });
    }

    handleMouseMove = event => {
        const { element, canvas } = this.props;
        const { selectedPoint } = this.state;

        const canDragPoint = !selectedPoint.isSum && !element.parentElement.hasDataSourceLink();

        if (!this.isDraggingPoint && canDragPoint) {
            this.isDraggingPoint = true;

            // Set the yAxis and yAsix2 to fixed min/max range while dragging so they don't auto-adjust
            if (element.chartModel.yAxis) {
                const yAxis = element.chart.yAxis[0];
                Object.assign(element.chartModel.yAxis, {
                    min: yAxis.min,
                    max: yAxis.max,
                    tickInterval: yAxis.tickInterval
                });
            }
            if (element.chartModel.yAxis1) {
                const yAxis2 = element.chart.yAxis[1];
                Object.assign(element.chartModel.yAxis2, {
                    min: yAxis2.min,
                    max: yAxis2.max,
                    tickInterval: yAxis2.tickInterval
                });
            }
        }

        window.requestAnimationFrame(timestamp => {
            if (this.animationFrameHandledAt === timestamp) {
                return;
            }
            this.animationFrameHandledAt = timestamp;

            if (!this.isDraggingPoint) {
                return;
            }

            const {
                chartScreenBounds,
                boundsTop
            } = this.dragStartProps;

            const series = selectedPoint.series;

            if (element.chartModel.series[series.index].stacking === "percent") {
                // Ignore resizing the first series since "percent" requires
                //   resizing two series in order for things to look right
                if (series.index > 0) {
                    // Determine how much to resize by
                    const mouseY = (event.pageY - boundsTop) / canvas.getScale();

                    let minVal = 0;
                    let maxVal = 0;
                    let totalVal = 0;
                    for (let seriesIndex = 0; seriesIndex < element.chartModel.series.length; ++seriesIndex) {
                        const value = element.chartModel.series[seriesIndex].data[selectedPoint.index].y;
                        seriesIndex < series.index - 1 && (minVal += value);
                        seriesIndex < series.index + 1 && (maxVal += value);
                        totalVal += value;
                    }
                    const interp = mouseY / series.yAxis.height;

                    // Clamp the value between minVal and maxVal
                    const targetVal = Math.min(Math.max(interp * totalVal, minVal), maxVal);

                    const upperVal = targetVal - minVal;
                    const lowerVal = maxVal - targetVal;

                    element.chartModel.series[series.index - 1].data[selectedPoint.index].y = upperVal;
                    element.chartModel.series[series.index].data[selectedPoint.index].y = lowerVal;
                }
            } else {
                // Determine how much to resize by
                let mousePoint;
                const dragAxis = (series.type === "bar") ? "x" : "y";
                if (dragAxis == "y") {
                    mousePoint = (event.pageY - chartScreenBounds.top) / canvas.getScale();
                } else {
                    mousePoint = (event.pageX - chartScreenBounds.left) / canvas.getScale();
                }

                let newVal = series.yAxis.toValue(mousePoint);

                // Check for graphs that have stacked data and offset
                // any changes by the values beneath the target
                if ("stackKey" in selectedPoint.series) {
                    let below;
                    let index = series.index;
                    do {
                        below = element.chartModel.series[++index];
                        if (below) {
                            newVal -= below.data[selectedPoint.index].y;
                        }
                    }
                    while (below);
                }

                if (series.yAxis.dataMax > 30) {
                    newVal = Math.round(newVal);
                } else {
                    newVal = parseFloat(newVal.toFixed(2));
                }

                element.chartModel.series[series.index].data[selectedPoint.index].y = newVal;
            }

            element.refreshElement(false);
        });
    }

    handleMouseUp = async () => {
        const { element } = this.props;
        const { selectedPoint } = this.state;

        window.removeEventListener("mousemove", this.handleMouseMove);
        window.removeEventListener("mouseup", this.handleMouseUp);

        if (!this.isDraggingPoint) {
            this.dragStartProps = null;
            const popoverPosition = geom.Rect.FromBoundingClientRect(this.pointWidgetRefs[selectedPoint.index].current.getBoundingClientRect()).center;
            this.setState({ popoverPosition });
            return;
        }

        const {
            yAxisModelSettings,
            yAxis2ModelSettings
        } = this.dragStartProps;

        // Restore the min/max settings for the axises
        if (yAxisModelSettings) {
            Object.assign(element.chartModel.yAxis, yAxisModelSettings);
        }
        if (yAxis2ModelSettings) {
            Object.assign(element.chartModel.yAxis2, yAxis2ModelSettings);
        }

        this.isDraggingPoint = false;
        this.dragStartProps = null;

        await element.updateModel();

        this.setState({ selectedPoint: null });
    }

    handleClosePopup = () => {
        this.setState({
            popoverPosition: null,
            selectedPoint: null
        });
    }

    render() {
        const { series, element, selectedElements } = this.props;
        const { selectedPoint, popoverPosition, rolloverPoint, widgetsDisabled } = this.state;

        const seriesModel = element.series.findById(series.userOptions.id);
        if (!seriesModel) {
            return null;
        }

        const seriesColorProps = element.getSeriesProps(seriesModel);

        this.pointWidgetRefs = this.pointWidgetRefs.slice(0, series.points.length);

        series.points.forEach(point => {
            if (!this.pointWidgetRefs[point.index]) {
                this.pointWidgetRefs[point.index] = React.createRef();
            }
        });

        const pointsWithWidgets = series.points.filter(point =>
            !selectedElements
                .filter(element => element.isInstanceOf("ConnectorItem"))
                .some(element =>
                    (element.targetSnapOptions?.seriesId === series.userOptions.id && element.targetSnapOptions?.pointIndex === point.index) ||
                    (element.sourceSnapOptions?.seriesId === series.userOptions.id && element.sourceSnapOptions?.pointIndex === point.index)
                )
        );

        let PopoverContent = null;
        if (popoverPosition && selectedPoint) {
            const annotationsElementsLinkedToPoint = helpers.getAnnotationsElementsLinkedToPoint(element, selectedPoint);
            const existingDataHilite = annotationsElementsLinkedToPoint.find(element => element.model.annotationType === "DataHilite");
            const existingDataNote = annotationsElementsLinkedToPoint.find(element => element.model.annotationType === "DataNote");

            PopoverContent = (
                <>
                    {series.userOptions.type != "waterfall" && !existingDataHilite && <MenuItem onClick={this.handleAddDataHilite}>
                        <Icon>star</Icon>
                        Highlight Point
                    </MenuItem>}
                    {series.userOptions.type != "waterfall" && existingDataHilite && <MenuItem onClick={this.handleRemoveDataHilite}>
                        <Icon>star</Icon>
                        Remove Highlight Point
                    </MenuItem>}
                    {!existingDataNote && <MenuItem onClick={this.handleAddDataNote}>
                        <Icon>try</Icon>
                        Add Note
                    </MenuItem>}
                    {existingDataNote && <MenuItem onClick={this.handleRemoveDataNote}>
                        <Icon>try</Icon>
                        Remove Note
                    </MenuItem>}
                    <MenuItem divider onClick={this.handleAddChangeInValue}>
                        <Icon>north_east</Icon>
                        Show Change/Growth/CAGR
                    </MenuItem>
                    {series.userOptions.type != "waterfall" && (
                        <NestedMenuItem label={`${SeriesTypeLabels[series.type]} Style`}>
                            <MenuItem onClick={() => this.handleSetLineStyle("default")}>
                                <img src={getStaticUrl("/images/ui/connectors/line-style-solid.svg")} />
                                Default
                            </MenuItem>
                            <MenuItem onClick={() => this.handleSetLineStyle("projection")}>
                                <img src={getStaticUrl("/images/ui/connectors/line-style-dashed.svg")} />
                                Projection
                            </MenuItem>
                            {(series.type === "area" || series.type === "areaspline") && <MenuItem onClick={() => this.handleSetLineStyle("emphasized")}>
                                <Icon>star_purple500</Icon>
                                Emphasized
                            </MenuItem>}
                        </NestedMenuItem>
                    )}
                    {(series.userOptions.type == "column" || series.userOptions.type == "bar") && (
                        <StrokeColorMenuItem disableRipple>
                            Column Color
                            <ColorPicker
                                value={seriesModel.data[selectedPoint.index].color || seriesColorProps.color || seriesColorProps.lineColor}
                                onChange={color => {
                                    seriesModel.data[selectedPoint.index].color = color;
                                    element.saveModel();
                                    this.handleClosePopup();
                                }}
                                showChartColors
                                showColorPicker
                            />
                        </StrokeColorMenuItem>
                    )}
                </>
            );
        }

        return (
            <>
                {pointsWithWidgets.map(point => (
                    <WidgetButtonContainer
                        key={point.index}
                        ref={this.pointWidgetRefs[point.index]}
                        position={this.getPointPosition(point)}
                        onMouseDown={event => this.handlePointMouseDown(point, event)}
                        onMouseEnter={() => this.setState({ rolloverPoint: point })}
                        onMouseLeave={() => this.setState({ rolloverPoint: null })}
                        visible={!widgetsDisabled && rolloverPoint === point}
                        disabled={widgetsDisabled}
                    >
                        <Icon>gps_fixed</Icon>
                    </WidgetButtonContainer>
                ))}

                {PopoverContent &&
                    <Popover
                        open
                        anchorReference="anchorPosition"
                        anchorPosition={{ left: popoverPosition.x, top: popoverPosition.y }}
                        onClose={this.handleClosePopup}
                        disableEnforceFocus
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center"
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "center"
                        }}
                    >
                        <PopupContent>
                            {PopoverContent}
                        </PopupContent>
                    </Popover>
                }
            </>
        );
    }
}

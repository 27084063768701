import * as geom from "js/core/utilities/geom";
import { TextElement } from "../../base/Text/TextElement";
import { SVGPolygonElement } from "../../base/SVGElement";
import { BaseElement } from "../../base/BaseElement";
import { Shape } from "../../../../../core/utilities/shapes";

export class TimelineSpanChevronItem extends BaseElement {
    get chevronOffset() {
        return this.options.chevronOffset;
    }

    get canRollover() {
        return false;
    }

    _build() {
        this.shape = this.addElement("shape", () => SVGPolygonElement);
        this.label = this.addElement("label", () => TextElement, {
            scaleTextToFit: true,
            autoWidth: false,
            autoHeight: false,
            minTextScale: 1,
            syncFontSizeWithSiblings: true,
            backgroundElement: this.shape
        });
    }

    _calcProps(props) {
        const { size } = props;
        const { showEndMarker } = this.options;

        const shapeBounds = new geom.Rect(0, 0, size.width, size.height).inflate({
            left: this.chevronOffset / 2,
            right: this.chevronOffset / 2
        });
        if (this.parentElement.isLast && !showEndMarker) {
            shapeBounds.width += this.chevronOffset / 2;
        }
        const shapeProps = this.shape.calcProps(size);
        shapeProps.path = Shape.drawChevron(shapeBounds, this.chevronOffset, this.parentElement.itemIndex === 0).toPolygonData();

        const labelProps = this.label.calcProps(new geom.Size(size.width - this.chevronOffset * 2, size.height));
        labelProps.bounds = new geom.Rect(this.chevronOffset, 0, labelProps.size);

        return { size };
    }
}

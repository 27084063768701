import { _ } from "../../../../vendor";
import BadFitDialog from "../../../../react/components/Dialogs/BadFitDialog";
import { ShowDialog, ShowErrorDialog } from "../../../../react/components/Dialogs/BaseDialog";
import { defaultDragPositionProps } from "../../../../editor/PresentationEditor/DragElementManager";

import { BaseElementSelection } from "./BaseElementSelection";
import { Key } from "../../../../core/utilities/keys";

export class CollectionItemElementSelection extends BaseElementSelection {
    get canDelete() {
        const { element } = this.props;
        return element.parentElement.isInstanceOf("CollectionElement") && element.parentElement.itemCollection.length > element.parentElement.minItemCount;
    }

    get canDrag() {
        const { element } = this.props;
        return this.getDropTargets(element).length > 0 || this.getCustomDropTargets(element).length > 0;
    }

    get swapOnDragDrop() {
        return false;
    }

    get dragPositionProps() {
        const { element } = this.props;

        if (this.swapOnDragDrop) {
            let startIndex = null;
            let swappedElementIndex = null;
            const getNewProps = element => {
                return {
                    ...defaultDragPositionProps,
                    dropTargets: this.getDropTargets(element),
                    customDropTargets: this.getCustomDropTargets(element),
                    onDragStart: async () => {
                        if (startIndex === element.itemIndex) {
                            swappedElementIndex = null;
                        }
                        if (startIndex === null) {
                            startIndex = element.itemIndex;
                        }
                    },
                    drop: async ({ targetElement, customDropTargetId }) => {
                        const collectionElement = element.parentElement;

                        if (customDropTargetId) {
                            const targetCollectionElement = element.canvas.getElementByUniquePath(customDropTargetId);
                            collectionElement.deleteItem(element.id);
                            const { id } = targetCollectionElement.addItem(element.model, 0);
                            return `${targetCollectionElement.uniquePath}/${id}`;
                        }

                        if (swappedElementIndex !== null) {
                            const swappedElementModel = collectionElement.itemCollection[startIndex];
                            const elementModel = collectionElement.itemCollection[swappedElementIndex];
                            collectionElement.itemCollection[swappedElementIndex] = swappedElementModel;
                            collectionElement.itemCollection[startIndex] = elementModel;
                        }

                        if (targetElement.itemIndex === startIndex || targetElement.itemIndex === swappedElementIndex) {
                            return element.uniquePath;
                        }

                        swappedElementIndex = targetElement.itemIndex;

                        const elementModel = collectionElement.itemCollection[startIndex];
                        const targetElementModel = collectionElement.itemCollection[swappedElementIndex];

                        collectionElement.itemCollection[startIndex] = targetElementModel;
                        collectionElement.itemCollection[swappedElementIndex] = elementModel;

                        return element.uniquePath;
                    },
                    getNewProps
                };
            };

            return getNewProps(element);
        }

        const getNewProps = element => ({
            ...defaultDragPositionProps,
            dropTargets: this.getDropTargets(element),
            customDropTargets: this.getCustomDropTargets(element),
            drop: async ({ targetElement, customDropTargetId }) => {
                if (customDropTargetId) {
                    const targetCollectionElement = element.canvas.getElementByUniquePath(customDropTargetId);
                    element.parentElement.deleteItem(element.id);
                    const { id } = targetCollectionElement.addItem(element.model, 0);
                    return `${targetCollectionElement.uniquePath}/${id}`;
                }

                const insertAfter = (targetElement.parentElement === element.parentElement && targetElement.itemIndex > element.itemIndex) || targetElement.isLastItem;
                element.parentElement.deleteItem(element.id);
                const { id } = targetElement.parentElement.addItem(element.model, insertAfter ? targetElement.itemIndex + 1 : targetElement.itemIndex);
                return `${targetElement.parentElement.uniquePath}/${id}`;
            },
            getNewProps
        });

        return getNewProps(element);
    }

    getDropTargets(element) {
        if (element.parentElement.isInstanceOf("CollectionElement")) {
            const collectionElements = element.canvas.getElementsByType(element.parentElement.type);
            return _.flatten(collectionElements.map(collectionElement => collectionElement.itemElements))
                .filter(el => el !== element)
                .filter(el => el.isVisible);
        }

        return [];
    }

    getCustomDropTargets(element) {
        if (element.parentElement.isInstanceOf("CollectionElement")) {
            return element.canvas
                .getElementsByType(element.parentElement.type)
                .filter(el => el !== element.parentElement)
                .filter(el => el.itemCollection.length === 0)
                .filter(el => el.isVisible)
                .map(collectionElement => ({
                    bounds: collectionElement.canvasBounds,
                    overlapThreshold: 0.5,
                    visible: false,
                    id: collectionElement.uniquePath
                }));
        }

        return [];
    }

    handleDeleteElement = () => {
        const { element, selectedElements, selectionLayerController } = this.props;

        if (element.parentElement.minItemCount >= element.parentElement.itemCollection.length) {
            ShowErrorDialog({
                title: "Sorry, we aren't able to delete the item",
                message: element.parentElement.minItemCount > 1 ? `This collection must have at least ${element.parentElement.minItemCount} items.` : "You can't delete the last item in this collection.",
            });
            return;
        }

        const currentSelection = [...selectedElements];

        element.parentElement.deleteItem(element.id);
        element.canvas.markStylesAsDirty();
        element.canvas.updateCanvasModel()
            .catch(() => {
                selectionLayerController.setSelectedElements(currentSelection);

                ShowDialog(BadFitDialog, {
                    title: "Sorry, we aren't able to delete the item",
                });
            });
    }

    handleKeyboardShortcut = async event => {
        if ([Key.DELETE, Key.BACKSPACE].includes(event.which)) {
            this.handleDeleteElement();
        }
    }
}

import { _ } from "js/vendor";
import { DecorationStyle, PaletteColorType } from "common/constants";
import { StackDiagramPropertyPanel } from "../../Editor/ElementPropertyPanels/StackDiagramUI";
import { GridLayoutContainer, LayoutEngineType } from "./LayoutContainers/GridLayoutContainer";
import { StackDiagramLayouts } from "./LayoutContainers/GridLayoutEngines/LayoutDefs";
import { InfographicElementTypes } from "./Dashboard/InfographicManager";

class StackDiagram extends GridLayoutContainer {
    static get schema() {
        return {
            showGutter: true,
        };
    }

    getElementPropertyPanel() {
        return StackDiagramPropertyPanel;
    }

    getElementSelection() {
        return null;
    }

    getElementControlBar() {
        return null;
    }

    get defaultItemData() {
        return {
            componentType: "TextAndImage",
            childElement: {},
        };
    }

    get defaultCellColor() {
        return PaletteColorType.BACKGROUND_ACCENT;
    }

    getDefaultLayout() {
        return _.cloneDeep(StackDiagramLayouts[0]);
    }

    get layoutEngineType() {
        return this.model.layoutEngineType ?? LayoutEngineType.STACK_DIAGRAM;
    }

    get decorationStyle() {
        return DecorationStyle.FILLED;
    }

    get disableAllAnimationsByDefault() {
        return true;
    }

    get animationElementName() {
        return "Stack diagram";
    }

    get animateChildren() {
        return false;
    }

    _getAnimations() {
        return [{
            name: "Fade in",
            prepare: () => this.animationState.fadeInProgress = 0,
            onBeforeAnimationFrame: progress => {
                this.animationState.fadeInProgress = progress;
            }
        }];
    }

    _migrate_10_02() {
        this.model.gridLayout = {
            cols: this.model.cols,
            rows: this.model.rows,
            cells: this.model.items.map(cell => {
                return {
                    x: cell.x,
                    y: cell.y,
                    width: cell.width,
                    height: cell.height,
                };
            })
        };

        this.model.items = this.model.items.map(cell => {
            if (cell.color == "transparent") {
                return {
                    componentType: "GridPlaceholder",
                    childElement: {}
                };
            } else {
                return {
                    componentType: "TextAndImage",
                    cellColor: (cell.color && cell.color != "auto") ? cell.color : this.canvas.getSlideColor(),
                    childElement: {
                        backdropPadding: 10,
                        text: cell.text,
                    },
                };
            }
        });

        delete this.model.cols;
        delete this.model.rows;
    }
}

export const elements = {
    StackDiagram,
};

import React, { Component } from "react";
import { Button } from "../../../../Components/Button";
import { Icon } from "../../../../Components/Icon";

export class EmphasizeToggle extends Component {
    render() {
        const { element, showLabel = false } = this.props;

        const hilited = !!element.model.hilited;
        return (
            <Button blue onClick={() => element.updateModel({ hilited: !hilited }, { refreshStyles: true })}>
                <Icon fill={hilited} style={{ position: "relative", top: -1 }}>star</Icon>
                {showLabel ? hilited ? "De-Emphasize" : "Emphasize" : null}
            </Button>
        );
    }
}


import React, { Component } from "react";
import styled from "styled-components";

import {
    Checkbox,
    Divider,
    FormControlLabel,
    IconButton,
    Popover
} from "@material-ui/core";
import { ToggleButton } from "@material-ui/lab";

import { RewriteTextTask } from "../../../../../../../../common/aiConstants";
import {
    AssetType,
    AuthoringBlockType,
    BlockStyleType,
    HorizontalAlignType,
    ListStyleType,
    TextStyleType,
    VerticalAlignType
} from "../../../../../../../../common/constants";
import { FeatureType } from "../../../../../../../../common/features";
import { builtInFonts } from "../../../../../../../../common/fontConstants";
import AppController from "../../../../../../../core/AppController";
import getLogger, { LogGroup } from "../../../../../../../core/logger/index";
import { ds } from "../../../../../../../core/models/dataService";
import { fontManager } from "../../../../../../../core/services/fonts";
import * as browser from "../../../../../../../core/utilities/browser";
import { sanitizeHtml } from "../../../../../../../core/utilities/dompurify";
import { openPricingPage } from "../../../../../../../core/utilities/externalLinks";
import { generateText, GenTextApplyAction } from "../../../../../../../core/utilities/generateText";
import * as geom from "../../../../../../../core/utilities/geom";
import {
    getSelection,
    getSelectionState,
    removeFormattingFromHtmlText,
    runForNodeAndAllChildNodes,
    sanitizeHtmlText,
    setSelection
} from "../../../../../../../core/utilities/htmlTextHelpers";
import { delayUntil } from "../../../../../../../core/utilities/promiseHelper";
import { trackActivity } from "../../../../../../../core/utilities/utilities";
import { GenerateTextDialog } from "../../../../../../../editor/dialogs/GenerateTextDialog";
import { app } from "../../../../../../../namespaces";
import DesignerBotIcon from "../../../../../../../react/components/DesignerBotIcon";
import { ShowDialog, ShowErrorDialog } from "../../../../../../../react/components/Dialogs/BaseDialog";
import ProgressDialog from "../../../../../../../react/components/Dialogs/ProgressDialog";
import { Gap10, Gap5 } from "../../../../../../../react/components/Gap";
import InfoToolTip from "../../../../../../../react/components/InfoToolTip";
import { LabeledContainer, ToggleButtonContainer } from "../../../../../../../react/components/LabeledContainer";
import { FlexBox, GridBox } from "../../../../../../../react/components/LayoutGrid";
import { NestedMenuItem } from "../../../../../../../react/components/NestedMenuItem";
import { PopupMenu, PopupMenuPaddedContainer } from "../../../../../../../react/components/PopupMenu";
import ProBadge from "../../../../../../../react/components/ProBadge";
import { BlueButton, BlueOutlinedButton } from "../../../../../../../react/components/UiComponents";
import { themeColors } from "../../../../../../../react/sharedStyles";
import { UpgradePlanDialogType } from "../../../../../../../react/views/MarketingDialogs/UpgradePlanDialog";
import { $, _, tinycolor } from "../../../../../../../vendor";

import { AuthoringCanvasControlBar, ControlBarGroup } from "../../AuthoringCanvasControlBar";
import { SmallLabel } from "../../../../../../../Components/legacy-components/AuthoringEditorComponents/Controls";
import { InputSlider } from "../../../../../../../Components/legacy-components/AuthoringEditorComponents/InputSlider";
import { NumberStepper } from "../../../../../../../Components/legacy-components/AuthoringEditorComponents/NumberStepper";

import { Popup, PopupContent } from "js/Components/Popup";
import { Icon } from "../../../../../../../Components/Icon";
import { ColorPicker } from "../../../../EditorComponents/ColorPickers/ColorPicker";
import { getBlockPopupMenuItems } from "../AuthoringBlockEditor";
import { IconListDecorationMenu } from "../../../../../../../Components/legacy-components/AuthoringEditorComponents/IconListDecorationMenu";
import { addLink } from "./AddLink";
import { Slider } from "../../../../../../../Components/Slider";
import { MenuItem } from "../../../../../../../Components/Menu";

const logger = getLogger(LogGroup.FONTS);

const FontSelect = styled.button`
    border: solid 1px #ccc;
    padding: 5px 12px;
    display: flex;
    align-items: center;
    background: white;
    font-family: "Source Sans Pro", sans-serif;

    label {
        font-size: 13px;
        text-transform: none;
    }

    .drop-down-arrow {
        color: #999;
    }
`;

const TextScalePopupMenu = styled.div`
    padding: 15px;

    label {
        text-transform: uppercase;
        font-weight: 600;
        font-size: 12px;
    }
`;

const FontMenuItem = styled(MenuItem)`
    &&& {
        display: flex;

        img {
            height: 20px;
            pointer-events: none;
        }

        span {
            color: rgb(51, 51, 51);
        }
    }
`;

const FontMenuItemWithDivider = styled(MenuItem)`
    &&& {
        display: flex;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        text-transform: uppercase;
        font-size: 11px;

        span {
            color: rgb(51, 51, 51);
        }
    }
`;

const UpgradeMessage = styled.div`
    font-size: 12px;
    line-height: 15px;
    color: #676767;
    margin-top: 10px;
    margin-bottom: 10px;
`;

class InnerTextFormatWidgetBar extends Component {
    state = {
        builtInFonts: [],
        customFonts: [],
        disableSelectionStateUpdate: false,
        recentlyUsedRewritePrompts: _.clone(app.user.getLibrarySettings().recentlyUsedRewritePrompts) || [],
        isGeneratingText: false,
    };

    isUnmounted = false;
    updateSelectionStylesOnSelectionChange = true;
    handleChangeFontScalePromiseChain = Promise.resolve();
    updateSelectedBlocksModelsPromiseChain = Promise.resolve();

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const { isMultiSelectMode, selectedBlocks } = this.props;

        // Loading custom fonts
        this.customFontsLoadPromise = ds.assets.getAssetsByType(AssetType.FONT, AppController.workspaceId)
            .then(assets => Promise.all(assets.map(asset => fontManager.loadFont(asset.id))))
            .then(customFonts => new Promise(resolve => {
                if (this.isUnmounted) {
                    return;
                }

                // Filtering out fonts that failed to load (fallback fonts)
                this.setState({ customFonts: customFonts.filter(font => font.isCustomFont) }, resolve);
            }))
            .catch(err => logger.error(err, "TextFormatWidgetBar failed to load custom fonts"));

        // Loading built in fonts
        this.builtInFontsLoadPromise = Promise.all(Object.keys(builtInFonts).map(fontId => fontManager.loadFont(fontId)))
            .then(builtInFonts => new Promise(resolve => {
                if (this.isUnmounted) {
                    return;
                }

                this.setState({ builtInFonts }, resolve);
            }))
            .catch(err => logger.error(err, "TextFormatWidgetBar failed to load built in fonts"));

        // Subscribing for selection changes
        if (!isMultiSelectMode) {
            this.instanceId = _.uniqueId();
            $(document).on("selectionchange.textEditor" + this.instanceId, () => {
                if (this.updateSelectionStylesOnSelectionChange) {
                    this.updateSelectionStyles();
                }
            });

            const blockElements = selectedBlocks.filter(block => block.type === AuthoringBlockType.TEXT).map(block => block.ref.current).filter(Boolean);
            const canManipulateSelection = !event || !blockElements.some(element => element === event.target || element.contains(event.target));
            // If not handling mouse event on a block, then allow manipulating selection
            this.updateSelectionStyles(canManipulateSelection);
        } else {
            // Set initial selection styles for multiselect
            this.updateSelectionStyles();
        }
    }

    componentWillUnmount() {
        const { isMultiSelectMode } = this.props;

        if (!isMultiSelectMode) {
            $(document).off(".textEditor" + this.instanceId);
        }

        this.isUnmounted = true;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.selectedBlocks, this.props.selectedBlocks)) {
            this.updateSelectionStyles();
        }

        const { fontFamily } = this.state;
        if (prevState.fontFamily !== fontFamily) {
            // Updating current font styles
            this.setState({ currentFontStyles: null });
            this.loadCurrentFontStyles()
                .catch(err => logger.error(err, "TextFormatWidgetBar loadCurrentFontStyles() failed", { fontFamily }));

            if (fontFamily && fontFamily !== "mixed") {
                // Making sure we have the current font loaded
                Promise.all([this.builtInFontsLoadPromise, this.customFontsLoadPromise])
                    .then(() => {
                        const { customFonts, builtInFonts } = this.state;
                        if (!builtInFonts.some(font => font.id === fontFamily) && !customFonts.some(font => font.id === fontFamily)) {
                            return fontManager.loadFont(fontFamily)
                                .then(loadedFont => {
                                    if (this.isUnmounted) {
                                        return;
                                    }

                                    // Making sure we don't have a duplicate font record in case this flow was running simultaneously for the same font
                                    this.setState(prevState => ({ customFonts: [...prevState.customFonts.filter(font => font.id !== loadedFont.id), loadedFont] }));
                                });
                        }
                    })
                    .catch(err => logger.error(err, "TextFormatWidgetBar fontManager.loadFont() failed", { fontFamily }));
            }
        }
    }

    async loadCurrentFontStyles() {
        const { fontFamily } = this.state;

        if (fontFamily !== "mixed") {
            const font = await fontManager.loadFont(fontFamily);
            this.setState({ currentFontStyles: font.styles });
        }
    }

    createTempNode(html) {
        const $dummy = $.div("").attr("contenteditable", true).css({
            position: "absolute",
            top: 0,
            left: 0,
            userSelect: "text",
            MozUserSelect: "text"
        });
        $dummy.html(html);
        $("body").append($dummy);
        return $dummy;
    }

    updateSelectionStyles(canManipulateSelection = true) {
        const { containers, selectedBlocks, isMultiSelectMode } = this.props;
        const { disableSelectionStateUpdate } = this.state;

        if (disableSelectionStateUpdate) return;

        let palette = containers[0].canvas.getTheme().palette;

        const selection = getSelection();

        if (isMultiSelectMode || !selection) {
            // Get the styles from the aggregate of all the selected blocks
            const blocksProps = [];
            for (const block of selectedBlocks.filter(block => block.type === AuthoringBlockType.TEXT)) {
                const node = block.ref.current;
                const nodeStyles = window.getComputedStyle(node);
                const textStyles = block.props.textStyles;

                const blockProps = {};
                blockProps.textStyle = block.props.textStyle;
                blockProps.fontFamily = textStyles.fontFamily;
                blockProps.fontWeight = textStyles.fontWeight ?? parseInt(nodeStyles.getPropertyValue("font-weight"));
                blockProps.fontSize = textStyles.fontSize;
                blockProps.fontScale = textStyles.fontScale ?? 1;
                blockProps.fontColor = block.model.fontColor ?? "auto";
                blockProps.letterSpacing = parseFloat(textStyles.letterSpacing ?? 0);
                blockProps.lineHeight = textStyles.lineHeight;
                blockProps.textAlign = textStyles.textAlign;
                blockProps.hyphenation = block.model.hyphenation ?? false;
                blockProps.fontKerning = block.model.fontKerning ?? true;
                blockProps.ligatures = block.model.ligatures ?? true;
                blockProps.evenBreak = textStyles.evenBreak ?? false;
                blockProps.isEmphasized = block.model.emphasized ?? false;
                blockProps.spaceAbove = block.model.spaceAbove ?? 0;
                blockProps.listStyle = block.model.listStyle;
                blockProps.columns = block.model.columns ?? 1;
                // blockProps.bulletColor = block.model.bulletColor;
                blockProps.bulletColor = containers[0].listDecorations?.[block.model.id]?.colorSet.decorationColor?.toRgbString();
                blockProps.allowFancyNumberedDecorations = textStyles.allowFancyNumberedDecorations;
                blockProps.listDecorationStyle = block.model.listDecorationStyle;
                blockProps.useThemedListDecoration = block.model.useThemedListDecoration ?? true;

                blocksProps.push(blockProps);

                if (canManipulateSelection) {
                    const $temp = this.createTempNode(block.model.html);
                    window.getSelection().selectAllChildren($temp[0]);
                    blockProps.isBold = document.queryCommandState("bold");
                    blockProps.isItalic = document.queryCommandState("italic");
                    blockProps.isUnderline = document.queryCommandState("underline");
                    blockProps.isSubscript = document.queryCommandState("subscript");
                    blockProps.isSuperscript = document.queryCommandState("superscript");
                    blockProps.isStrikeThrough = document.queryCommandState("strikeThrough");
                    $temp.remove();
                } else {
                    blockProps.isBold = false;
                    blockProps.isItalic = false;
                    blockProps.isUnderline = false;
                    blockProps.isSubscript = false;
                    blockProps.isSuperscript = false;
                    blockProps.isStrikeThrough = false;
                }

                // blockProps.fontSize = Math.round(blockProps.fontSize / block.scale);
                blockProps.fontSize = blockProps.fontSize / block.scale;
            }

            const mergedBlockProps = this.getMergedProperties(blocksProps);
            const containerProps = this.getMergedProperties(_.map(containers, container => _.pick(container.model, "textAlign", "verticalAlign", "syncFontSizeWithSiblings")));

            this.setState({ ...mergedBlockProps, ...containerProps });
        } else {
            // Get the styles from the selection range
            const selectedBlock = selectedBlocks[0];
            const range = selection.getRangeAt(0);

            let selectedNode = range.commonAncestorContainer;
            while (selectedNode.nodeName === "#text") {
                selectedNode = selectedNode.parentElement;
            }

            let isLink = selectedBlock.model.linkToSlide;
            let isEmphasized = !!selectedBlock.model.emphasized;

            let currentNode = selectedNode;
            while (currentNode) {
                if (currentNode.tagName === "A") {
                    isLink = true;
                }
                if (currentNode.className?.includes("emphasized")) {
                    isEmphasized = true;
                }
                if (isEmphasized && isLink) {
                    break;
                }
                if (currentNode === selectedBlock.ref.current) {
                    break;
                }
                currentNode = currentNode.parentNode;
            }

            const nodeStyles = window.getComputedStyle(selectedNode);
            const textStyles = selectedBlock.props.textStyles;

            let fontFamily, fontWeight, fontSize, fontColor;
            if (selection.isCollapsed) {
                fontFamily = textStyles.fontFamily;
                fontWeight = parseInt(nodeStyles.getPropertyValue("font-weight"));
                fontSize = textStyles.fontSize;
                fontColor = nodeStyles.getPropertyValue("color");
            } else {
                fontFamily = nodeStyles.getPropertyValue("font-family");
                fontWeight = parseInt(nodeStyles.getPropertyValue("font-weight"));
                fontSize = parseFloat(nodeStyles.getPropertyValue("font-size").replace("px", ""));
                fontColor = document.queryCommandValue("foreColor");
            }

            currentNode = selectedNode;
            while (currentNode) {
                if (currentNode === selectedBlock.ref.current) {
                    // "auto" is legacy, in case the model was migrated but the value remained as "auto"
                    if (selectedBlock.model.fontColor && selectedBlock.model.fontColor !== "auto") {
                        fontColor = selectedBlock.model.fontColor;
                    } else {
                        fontColor = textStyles.fontColor;
                    }
                    break;
                }
                if (currentNode.style && currentNode.style.getPropertyValue("color")) {
                    break;
                }
                if (currentNode.hasAttribute && currentNode.hasAttribute("color")) {
                    break;
                }
                if (currentNode.classList && [...currentNode.classList.values()].some(c => c.startsWith("color-"))) {
                    break;
                }

                currentNode = currentNode.parentNode;
            }

            fontSize = fontSize / selectedBlock.scale;

            this.setState({
                selectionBounds: range.getBoundingClientRect(),
                textStyle: selectedBlock.props.textStyle,
                isBold: document.queryCommandState("bold"),
                isItalic: document.queryCommandState("italic"),
                isUnderline: document.queryCommandState("underline"),
                isSubscript: document.queryCommandState("subscript"),
                isSuperscript: document.queryCommandState("superscript"),
                isStrikeThrough: document.queryCommandState("strikeThrough"),
                fontColor,
                isLink: isLink,
                isEmphasized,
                fontFamily,
                fontWeight,
                fontSize,
                fontScale: textStyles.fontScale ?? 1,
                letterSpacing: parseFloat(textStyles.letterSpacing ?? 0),
                lineHeight: textStyles.lineHeight,
                textAlign: selectedBlock.model.textAlign ?? textStyles.textAlign,
                verticalAlign: selectedBlock.props.element.verticalAlign,
                syncFontSizeWithSiblings: selectedBlock.props.element.textModel.syncFontSizeWithSiblings ?? selectedBlock.props.element.options.syncFontSizeWithSiblingsDefaultValue ?? true,
                hyphenation: selectedBlock.model.hyphenation ?? false,
                fontKerning: selectedBlock.model.fontKerning ?? true,
                ligatures: selectedBlock.model.ligatures ?? true,
                evenBreak: textStyles.evenBreak ?? false,
                spaceAbove: selectedBlock.model.spaceAbove ?? 0,
                listStyle: selectedBlock.model.listStyle,
                columns: selectedBlock.model.columns ?? 1,
                bulletColor: selectedBlock.model.bulletColor ?? containers[0].listDecorations?.[selectedBlock.model.id]?.colorSet.decorationColor?.toRgbString(),
                indent: selectedBlock.model.indent,
                allowFancyNumberedDecorations: textStyles.allowFancyNumberedDecorations,
                listDecorationStyle: selectedBlock.model.listDecorationStyle,
                useThemedListDecoration: selectedBlock.model.useThemedListDecoration ?? true
            });
        }
    }

    getMergedProperties(collection) {
        const props = {};
        for (const item of collection) {
            for (const [key, value] of Object.entries(item)) {
                if (props.hasOwnProperty(key) && props[key] != value) {
                    switch (key) {
                        case "fontSize":
                            props[key] = Math.max(props[key] || 0, value);
                            break;
                        default:
                            props[key] = "mixed";
                    }
                } else {
                    props[key] = value;
                }
            }
        }

        return props;
    }

    handleFormat = format => {
        const { isMultiSelectMode, refreshCanvasAndSaveChanges, selectedBlocks, setSelection, runPostUpdate } = this.props;

        if (!isMultiSelectMode) {
            const selectedBlock = selectedBlocks[0];

            const selection = window.getSelection();
            if (selection.type === "Caret") {
                // Selecting current word
                selection.modify("extend", "forward", "word");
                selection.collapseToEnd();
                selection.modify("extend", "backward", "word");
            } else if (selection.type === "None") {
                // Selecting all text
                selection.selectAllChildren(selectedBlock.ref.current);
            }

            // Saving current state to be able to restore it after the update
            const selectionStateBeforeUpdate = getSelectionState(selectedBlock.ref.current);

            // Formatting
            document.execCommand(format);

            runPostUpdate(() => {
                const selectionStateAfterUpdate = getSelectionState(selectedBlock.ref.current);
                if (selectionStateAfterUpdate.start !== selectionStateBeforeUpdate.start || selectionStateAfterUpdate.end !== selectionStateBeforeUpdate.end) {
                    // Restoring the selection if it has changed which may happen in some cases,
                    // refer to BA-8789
                    setSelection(selectionStateBeforeUpdate);
                }
            });
        } else {
            const { selectedBlocks } = this.props;
            for (const block of selectedBlocks.filter(block => block.type === AuthoringBlockType.TEXT)) {
                const $temp = this.createTempNode(block.model.html);
                window.getSelection().selectAllChildren($temp[0]);
                document.execCommand(format);
                block.model.html = $temp.html();
                $temp.remove();
            }

            this.updateSelectionStyles();
            refreshCanvasAndSaveChanges();
        }
    }

    handleSelectColor = colorKey => {
        this.updateFontStyle({ fontColor: colorKey });
    }

    handleSelectBulletColor = colorKey => {
        const { selectedBlocks, refreshCanvasAndSaveChanges } = this.props;

        for (const block of selectedBlocks) {
            block.model.bulletColor = colorKey == "slide" ? null : colorKey;
        }

        this.updateSelectionStyles();
        refreshCanvasAndSaveChanges();
    }

    handleSetTextAlign = textAlign => {
        const { containers, isMultiSelectMode, editorConfig = {}, refreshCanvasAndSaveChanges } = this.props;

        if (editorConfig.syncTextAlignAcrossBlocks) {
            for (const container of containers) {
                container.model.textAlign = textAlign;
                for (const blockModel of container.textModel.blocks) {
                    blockModel.textAlign = undefined;
                }
            }
            refreshCanvasAndSaveChanges()
                .then(() => this.updateSelectionStyles());
            return;
        }

        if (isMultiSelectMode) {
            // Update containers' models as well
            for (const container of containers) {
                container.model.textAlign = textAlign;
            }
        }

        this.updateSelectedBlocksModels({ textAlign });
    }

    handleSetVerticalAlign = align => {
        this.updateContainerModels({ verticalAlign: align });
    }

    handleShowFontMenu = event => {
        event.preventDefault();
        event.stopPropagation();

        this.setState({
            fontMenuAnchorEl: event.currentTarget,
        });
    }

    handleCloseFontMenu = event => new Promise(resolve => {
        const { isMultiSelectMode, setSelection, selectedBlocks } = this.props;

        event.preventDefault();
        event.stopPropagation();

        if (!isMultiSelectMode && browser.isSafari) {
            const selectionState = getSelectionState(selectedBlocks[0].ref.current);
            this.setState({
                fontMenuAnchorEl: null
            }, () => {
                _.defer(() => {
                    setSelection(selectionState);
                    resolve();
                });
            });
        } else {
            this.setState({
                fontMenuAnchorEl: null
            }, resolve);
        }
    });

    handleSelectFont = (fontId, weight) => {
        if (fontId === "__theme") {
            this.updateFontStyle({ fontFamily: "__theme", fontWeight: "__theme" });
        } else {
            this.updateFontStyle({ fontFamily: fontId, fontWeight: weight });
        }
    }

    handleChangeLink = () => {
        const { selectedBlocks, refreshCanvasAndSaveChanges, updateHtml, isMultiSelectMode } = this.props;
        if (isMultiSelectMode) {
            throw new Error("handleChangeLink() should not be called in multi select mode");
        }
        this.updateSelectionStylesOnSelectionChange = false;
        addLink({
            block: selectedBlocks[0],
            refreshCanvasAndSaveChanges,
            updateHtml,
            onClose: () => {
                this.updateSelectionStylesOnSelectionChange = true;
            }
        });
    };

    handleSetBlockType = blockType => {
        const { selectedBlocks, refreshCanvasAndSaveChanges } = this.props;

        for (const block of selectedBlocks) {
            if (Object.values(TextStyleType).includes(blockType)) {
                block.model.textStyle = blockType;
                block.model.type = AuthoringBlockType.TEXT;
            } else {
                block.model.type = blockType;
            }

            if (block.model.listStyle) {
                continue; // don't reset styles when switching list types
            }
            block.model.indent = 0;
            block.model.fontFamily = undefined;
            block.model.fontSize = undefined;
            block.model.letterSpacing = undefined;
            block.model.lineHeight = undefined;
            // Clean up formatting and only preserve line breaks
            block.model.html = removeFormattingFromHtmlText(block.model.html || "");
        }

        this.updateSelectionStyles();
        refreshCanvasAndSaveChanges();

        this.setState({ textStyle: blockType });
    }

    handleToggleListStyle = (listStyle, options = {}) => {
        const { selectedBlocks, refreshCanvasAndSaveChanges } = this.props;

        for (const block of selectedBlocks) {
            if (listStyle == null) {
                // convert to title or body block - note this arguably should be reading from styles to determine which textStyle to
                // set when converting from bullleted to non instead of assuming TITLE and BODY like i did here
                if (block.indent > 0) {
                    block.model.textStyle = TextStyleType.BODY;
                } else {
                    block.model.textStyle = TextStyleType.TITLE;
                }
                block.model.listStyle = undefined;
                block.model.listDecorationStyle = undefined;
                block.model.listIconId = undefined;
            } else {
                block.model.textStyle = TextStyleType.BULLET_LIST;
                block.model.listStyle = listStyle;

                if (listStyle == ListStyleType.ICON) {
                    block.model.listIconId = options.icon;
                } else {
                    block.model.listDecorationStyle = options.style ?? block.model.listDecorationStyle;
                    block.model.useThemedListDecoration = options.useThemedListDecoration ?? block.model.useThemedListDecoration;
                }
            }
        }

        const indents = selectedBlocks.reduce((indents, block) => indents.add(block.indent), new Set());
        const elements = selectedBlocks.reduce((elements, block) => elements.add(block.element), new Set());
        if (indents.size === 1 && elements.size === 1) {
            // All selected blocks have the same indent and belong to one element so find
            // adjacent similar blocks (with list style) and update their list style too

            const element = selectedBlocks[0].element;
            const indent = selectedBlocks[0].indent;

            const allBlocks = element.blockContainerRef.current.blocks;
            const blocksBefore = allBlocks.slice(0, selectedBlocks[0].index);
            const blocksAfter = allBlocks.slice(selectedBlocks[selectedBlocks.length - 1].index + 1);

            const blocksToUpdate = [];
            const shouldBreak = block => !block.model.listStyle || block.indent < indent;
            for (let i = blocksBefore.length - 1; i >= 0; i--) {
                const blockBefore = blocksBefore[i];
                if (shouldBreak(blockBefore)) {
                    break;
                }
                if (blockBefore.indent === indent) {
                    blocksToUpdate.push(blockBefore);
                }
            }
            for (let i = 0; i < blocksAfter.length; i++) {
                const blockAfter = blocksAfter[i];
                if (shouldBreak(blockAfter)) {
                    break;
                }
                if (blockAfter.indent === indent) {
                    blocksToUpdate.push(blockAfter);
                }
            }
            for (const block of blocksToUpdate) {
                block.model.listStyle = listStyle;
                if (listStyle != ListStyleType.ICON) {
                    block.model.listDecorationStyle = options.style ?? block.model.listDecorationStyle;
                    block.model.useThemedListDecoration = options.useThemedListDecoration ?? block.model.useThemedListDecoration;
                }
            }

            element.onAdjacentBlocksListStyleChanged(indent, listStyle, options);
        }

        this.updateSelectionStyles();
        refreshCanvasAndSaveChanges();
    }

    handleChangeIndent = delta => {
        const { selectedBlocks, refreshCanvasAndSaveChanges } = this.props;

        for (const block of selectedBlocks) {
            if (block.element.textModel.blocks.indexOf(block.model) > 0) {
                block.model.indent = Math.clamp((block.model.indent ?? 0) + delta, 0, 5);
            }
        }
        this.updateSelectionStyles();
        refreshCanvasAndSaveChanges();
    }

    updateFontStyle = async ({ fontSize, fontScale, fontFamily, fontWeight, fontColor, emphasized }, saveChanges = true) => {
        const { isLink } = this.state;
        const canvas = this.props.containers[0].canvas;

        if (fontFamily && fontFamily !== "__theme") {
            try {
                // Preloading the selected font for smoother transition
                const font = await fontManager.loadFont(fontFamily);
                await Promise.all(font.styles.map(style => style.loadCssFont()));
            } catch (err) {
                logger.error(err, "TextFormatWidgetBar updateFontStyle() fontManager.loadFont() failed", { fontFamily });
            }
        }

        if (fontWeight || fontColor) {
            // Updating weight and color force-disable emphasized
            emphasized = false;
        }

        const writeUpdatesToModel = () => {
            const modelUpdates = {
                fontSize,
                fontScale,
                fontFamily: fontFamily === "__theme" ? null : fontFamily,
                fontWeight: fontWeight === "__theme" ? null : fontWeight,
                fontColor: fontColor === "auto" ? null : fontColor
            };

            if (emphasized != null) {
                modelUpdates.emphasized = emphasized;
            }

            this.updateSelectedBlocksModels(modelUpdates, true, saveChanges);
        };

        const {
            isMultiSelectMode,
            selectedBlocks,
            stopHandlingChanges,
            startHandlingChanges,
            updateHtml,
            runPostUpdate,
            setSelection,
        } = this.props;

        const cleanupElementStyles = (element, topParent, respectFontSize) => {
            let hasChanges = false;

            if (emphasized != null && element !== topParent) {
                // Cleanup all parent element with emphasized styles
                let parentNode = element.parentNode;
                while (parentNode && parentNode !== topParent) {
                    if (parentNode.classList && parentNode.classList.contains("emphasized")) {
                        parentNode.classList.remove("emphasized");
                        hasChanges = true;
                    }
                    parentNode = parentNode.parentNode;
                }
            }

            runForNodeAndAllChildNodes(element, node => {
                if (node === topParent) {
                    return;
                }

                if (node.style) {
                    if (fontSize) {
                        if (respectFontSize) {
                            const elementFontSize = element.style.getPropertyValue("font-size");
                            if (elementFontSize && elementFontSize.endsWith("em")) {
                                const sizeInEm = parseFloat(elementFontSize.replace("em", ""));
                                const actualSize = sizeInEm * selectedBlock.props.element.getTextStylesForBlock(selectedBlock.id).fontSize;
                                const newSizeInEm = actualSize / fontSize;
                                element.style.setProperty("font-size", `${newSizeInEm}em`);
                            }
                        } else {
                            node.style.removeProperty("font-size");
                        }
                        hasChanges = true;
                    }
                    if (fontColor || emphasized != null) {
                        node.style.removeProperty("color");
                        if (emphasized != null) {
                            node.removeAttribute("class");
                        } else {
                            if (node.classList) {
                                [...node.classList.values()].filter(className => className.startsWith("color-")).forEach(className => {
                                    node.classList.remove(className);
                                });
                            }
                        }
                        hasChanges = true;
                    }
                    if (fontFamily) {
                        node.style.removeProperty("font-family");
                        hasChanges = true;
                    }
                    if (fontWeight || emphasized != null) {
                        node.style.removeProperty("font-weight");
                        hasChanges = true;
                    }
                }

                if (node.removeAttribute) {
                    if (fontColor) {
                        node.removeAttribute("color");
                        hasChanges = true;
                    }
                }

                if (node.classList) {
                    if (emphasized != null) {
                        node.classList.remove("emphasized");
                        hasChanges = true;
                    }
                    if (fontColor) {
                        [...node.classList.values()].filter(className => className.startsWith("color-")).forEach(className => {
                            node.classList.remove(className);
                            hasChanges = true;
                        });
                    }
                }
            });
            return hasChanges;
        };

        const removeEmptyFontElements = node => {
            node.querySelectorAll("font").forEach(font => {
                if (!font.getAttributeNames().some(attr => !!font.getAttribute(attr))) {
                    font.replaceWith(...font.childNodes);
                }
            });
        };

        if (isMultiSelectMode) {
            // We aren't editing text so update all the blocks at the model level
            for (const block of selectedBlocks) {
                const hasChanges = cleanupElementStyles(block.ref.current, block.ref.current, false);
                if (hasChanges) {
                    block.model.html = sanitizeHtml(block.ref.current.innerHTML);
                }
            }

            writeUpdatesToModel();
            return;
        }

        this.updateSelectionStylesOnSelectionChange = false;
        const setPostUpdateTasks = () => {
            runPostUpdate(() => {
                const postUpdateCallback = () => {
                    setSelection(selectionState);
                    _.defer(() => {
                        this.updateSelectionStylesOnSelectionChange = true;
                        this.updateSelectionStyles();
                    });
                };

                if (canvas.layouter.isGenerating) {
                    canvas.layouter.runPostRender(postUpdateCallback);
                } else {
                    postUpdateCallback();
                }
            });
        };

        // we are editing text so there can only be a single selectedBlock
        const selectedBlock = selectedBlocks[0];
        const contentEditableElement = selectedBlock.ref.current;

        if (this.savedSelectionState) {
            setSelection(this.savedSelectionState);
            this.savedSelectionState = null;
        }

        const selectionState = getSelectionState(contentEditableElement);

        const selection = getSelection();

        const allowAdvancedTextStyling = selectedBlock.props.allowAdvancedTextStyling;

        let updateSelectedText = selection && selection.anchorNode !== contentEditableElement && !selection.isCollapsed;
        if ((!allowAdvancedTextStyling && fontSize) || fontScale) {
            // when no advancedTextStyling, updating the font size should be applied to the entire text regardless of selection
            updateSelectedText = false;
        }

        if (updateSelectedText) {
            // update selected text only

            if (!fontSize && allowAdvancedTextStyling) {
                const range = selection.getRangeAt(0);
                let styleOwnerElement = range.commonAncestorContainer;
                while (styleOwnerElement.nodeName === "#text") {
                    styleOwnerElement = styleOwnerElement.parentElement;
                }
                // We have to restore font size after running the command from below
                fontSize = parseFloat(window.getComputedStyle(styleOwnerElement).getPropertyValue("font-size").replace("px", ""));
            }

            stopHandlingChanges();

            // Wrap the selection in a font tag with `size="7"`
            document.execCommand("fontSize", false, "7");

            startHandlingChanges();

            const updatedElements = [];
            contentEditableElement.querySelectorAll("font").forEach(fontElement => {
                if (fontElement.getAttribute("size") === "7") {
                    updatedElements.push(fontElement);
                }
            });

            if (updatedElements.length === 1 && updatedElements[0].outerHTML === contentEditableElement.innerHTML) {
                const updatedElement = updatedElements[0];
                updatedElement.removeAttribute("size");
                cleanupElementStyles(updatedElement, contentEditableElement, false);
                setPostUpdateTasks();
                updateHtml(sanitizeHtml(updatedElement.innerHTML));
                writeUpdatesToModel();
            } else if (updatedElements.length > 0) {
                let removeModelFontColor = false;
                if (updatedElements.length === 1 && fontColor === "auto" && selectedBlocks[0].model.fontColor) {
                    let hasMultipleChildren = false;
                    let stop = false;
                    runForNodeAndAllChildNodes(contentEditableElement, node => {
                        if (stop) {
                            return;
                        }
                        if (node === updatedElements[0]) {
                            stop = true;
                            return;
                        }
                        if (node.childNodes.length > 1) {
                            hasMultipleChildren = true;
                            stop = true;
                        }
                    });
                    if (!hasMultipleChildren) {
                        removeModelFontColor = true;
                    }
                }

                updatedElements.forEach(updatedElement => {
                    updatedElement.removeAttribute("size");
                    cleanupElementStyles(updatedElement, contentEditableElement, false);

                    if (fontSize) {
                        updatedElement.style.setProperty("font-size", `${fontSize / selectedBlock.props.textStyles.fontSize}em`);
                    }
                    if (fontFamily) {
                        if (fontFamily === "__theme") {
                            updatedElement.style.removeProperty("font-family");
                        } else {
                            updatedElement.style.setProperty("font-family", fontFamily);
                        }
                    }
                    if (fontWeight) {
                        if (fontWeight === "__theme") {
                            const themeWeight = selectedBlock.props.textStyles.fontWeight;
                            updatedElement.style.setProperty("font-weight", themeWeight);
                        } else {
                            updatedElement.style.setProperty("font-weight", fontWeight);
                        }
                    }
                    if (fontColor) {
                        updatedElement.style.removeProperty("color");

                        if (fontColor === "auto") {
                            if (isLink) updatedElement.classList.add("auto");
                            else updatedElement.classList.add("color-auto");
                        } else {
                            if (!tinycolor(fontColor).isValid()) {
                                // Theme color
                                updatedElement.classList.add(`color-${fontColor}`);
                            } else {
                                updatedElement.style.setProperty("color", fontColor, "important");
                            }
                        }
                    }
                    if (emphasized) {
                        updatedElement.classList.add("emphasized");
                    }
                });

                removeEmptyFontElements(contentEditableElement);

                setPostUpdateTasks();
                if (emphasized != null || removeModelFontColor) {
                    this.updateSelectedBlocksModels({
                        emphasized: emphasized != null ? false : undefined,
                        fontColor: removeModelFontColor ? null : undefined
                    }, true, false, true);
                }
                updateHtml(sanitizeHtml(contentEditableElement.innerHTML), true);
            } else {
                cleanupElementStyles(contentEditableElement, contentEditableElement, false);
                setPostUpdateTasks();
                updateHtml(sanitizeHtml(contentEditableElement.innerHTML));
                writeUpdatesToModel();
            }
        } else {
            // update the styles for the entire text
            const shouldUpdateState = cleanupElementStyles(contentEditableElement, contentEditableElement, true);
            if (shouldUpdateState) {
                setPostUpdateTasks();
                updateHtml(sanitizeHtml(contentEditableElement.innerHTML));
            }

            writeUpdatesToModel();
        }
    }

    handleResetStyles = () => {
        const { containers, selectedBlocks, refreshCanvasAndSaveChanges } = this.props;

        for (const container of containers) {
            // container.model.textAlign = undefined;
            // container.model.verticalAlign = undefined;
            container.model.fontSize = undefined;
        }

        for (const block of selectedBlocks) {
            block.model.fontFamily = undefined;
            block.model.fontSize = undefined;
            block.model.fontColor = undefined;
            block.model.letterSpacing = undefined;
            block.model.lineHeight = undefined;
            block.model.textAlign = undefined;
            block.model.verticalAlign = undefined;
            block.model.fontScale = undefined;
            block.model.bulletColor = undefined;
            block.model.spaceAbove = undefined;

            // Clean up formatting and only preserve line breaks
            block.model.html = removeFormattingFromHtmlText(block.model.html || "");
        }

        for (const textElement of containers) {
            if (textElement.options.syncFontSizeWithSiblings) {
                const siblings = textElement.getSiblings();
                for (const item of siblings) {
                    const blockModel = item.model[textElement.bindTo]?.blocks?.find(b => b.textStyle == selectedBlocks[0].props.textStyle);
                    if (blockModel && blockModel.syncFontSizeWithSiblings !== null) {
                        blockModel.fontSize = undefined;
                        blockModel.fontScale = undefined;
                    }
                }
            }
        }

        this.updateSelectionStyles();
        refreshCanvasAndSaveChanges();
    }

    handleChangeColumns = cols => {
        const { selectedBlocks, refreshCanvasAndSaveChanges } = this.props;

        for (const block of selectedBlocks) {
            block.model.columns = cols;
        }
        this.updateSelectionStyles();
        refreshCanvasAndSaveChanges();
    }

    handleChangeBlockStyle = (style, color) => {
        const { selectedBlocks, refreshCanvasAndSaveChanges } = this.props;

        for (const block of selectedBlocks) {
            block.model.blockStyle = style;
            block.model.blockColor = color;
        }
        this.updateSelectionStyles();
        refreshCanvasAndSaveChanges(true);
    }

    handleChangeSyncFontScale = value => {
        const { containers, refreshCanvasAndSaveChanges } = this.props;

        for (const textElement of containers) {
            textElement.textModel.syncFontSizeWithSiblings = value;
            if (value) {
                for (let siblingTextElement of textElement.getSiblings().filter(el => el !== textElement)) {
                    if (siblingTextElement.textModel.hasOwnProperty("blockFontScales")) {
                        textElement.textModel.blockFontScales = _.cloneDeep(siblingTextElement.textModel.blockFontScales);
                        break;
                    }
                }
            }
        }
        this.updateSelectionStyles();
        refreshCanvasAndSaveChanges();
    }

    handleChangeFontScale = (scale, saveChanges) => {
        return new Promise((resolve, reject) => {
            this.handleChangeFontScalePromiseChain = this.handleChangeFontScalePromiseChain
                .then(async () => {
                    const { selectedBlocks, refreshCanvasAndSaveChanges, refreshCanvas, refreshElement, containers } = this.props;

                    let needsCanvasRefresh = false;
                    for (const textElement of containers) {
                        let siblings;

                        if (textElement.syncFontSizeWithSiblings) {
                            siblings = textElement.getSiblings();
                            needsCanvasRefresh = true; // we need to refresh the entire canvas - not just the element - so all the siblings update their size
                        } else {
                            siblings = [textElement];
                        }

                        let blockFontScales;

                        for (let siblingTextElement of siblings) {
                            if (siblingTextElement.textModel.hasOwnProperty("blockFontScales")) {
                                blockFontScales = siblingTextElement.textModel.blockFontScales;
                                break;
                            }
                        }

                        if (!blockFontScales) {
                            blockFontScales = {};
                        }

                        for (const selectedBlock of selectedBlocks) {
                            if (selectedBlock.model.textStyle == TextStyleType.BULLET_LIST) {
                                blockFontScales[TextStyleType.BULLET_LIST] = blockFontScales[TextStyleType.BULLET_LIST] || [];
                                blockFontScales[TextStyleType.BULLET_LIST][selectedBlock.model.indent > 0 ? 1 : 0] = scale;
                            } else {
                                blockFontScales[selectedBlock.model.textStyle] = scale;
                            }
                        }

                        for (let siblingTextElement of siblings) {
                            siblingTextElement.textModel.blockFontScales = _.clone(blockFontScales);
                        }
                    }

                    if (saveChanges) {
                        await refreshCanvasAndSaveChanges();
                    } else if (needsCanvasRefresh) {
                        await refreshCanvas();
                    } else {
                        await refreshElement();
                    }

                    this.updateSelectionStyles();
                })
                .then(resolve)
                .catch(reject);
        });
    }

    handleChangeFormat = async model => {
        const { selectedBlocks, refreshCanvasAndSaveChanges } = this.props;

        for (const block of selectedBlocks) {
            Object.assign(block.model, model);
        }
        await refreshCanvasAndSaveChanges();
        this.updateSelectionStyles();
    }

    updateSelectedBlocksModels = (updates, ignoreUndefined = false, saveChanges = true, skipRefresh = false) => {
        return new Promise((resolve, reject) => {
            this.updateSelectedBlocksModelsPromiseChain = this.updateSelectedBlocksModelsPromiseChain
                .then(async () => {
                    const { selectedBlocks, refreshCanvasAndSaveChanges, refreshElement, containers } = this.props;

                    for (const selectedBlock of selectedBlocks) {
                        Object.entries(updates).forEach(([key, value]) => {
                            if (value === undefined && ignoreUndefined) {
                                return;
                            }

                            if (key === "lineHeight" && value) {
                                // Convert value to the backwards-compatible "H" derived value
                                selectedBlock.model.lineHeight = value * selectedBlock.props.textStyles.fontSize / selectedBlock.props.fontHeight;
                                delete selectedBlock.model.trueLineHeight;
                            } else {
                                selectedBlock.model[key] = value;
                            }
                        });
                    }

                    if (updates.hasOwnProperty("fontSize")) {
                        for (const textElement of containers) {
                            if (textElement.options.syncFontSizeWithSiblings && selectedBlocks[0].model.syncFontSizeWithSiblings !== false) {
                                // sync other blocks of same textStyle in this container/element
                                for (let blockModel of textElement.textModel.blocks.filter(b => b.textStyle == selectedBlocks[0].props.textStyle && b !== selectedBlocks[0])) {
                                    if (updates.hasOwnProperty("fontSize")) {
                                        blockModel.fontSize = updates.fontSize;
                                    }
                                }

                                // sync siblings collection item elements to this container/element
                                const siblings = textElement.getSiblings();
                                for (const item of siblings) {
                                    // Adding fallback if any of the optional properties are missing
                                    const blockModels = item.model[textElement.bindTo]?.blocks?.filter(b => b.textStyle == selectedBlocks[0].model.textStyle && b.syncFontSizeWithSiblings !== false) ?? [];
                                    for (let blockModel of blockModels) {
                                        if (updates.hasOwnProperty("fontSize")) {
                                            blockModel.fontSize = updates.fontSize;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    if (skipRefresh) {
                        return;
                    }

                    if (saveChanges) {
                        await refreshCanvasAndSaveChanges();
                    } else {
                        await refreshElement();
                    }

                    this.updateSelectionStyles();
                })
                .then(resolve)
                .catch(reject);
        });
    }

    updateContainerModels = (updates, ignoreUndefined = false, saveChanges = true) => {
        const { containers, refreshCanvasAndSaveChanges, refreshElement } = this.props;

        for (const container of containers) {
            Object.entries(updates).forEach(([key, value]) => {
                if (value === undefined && ignoreUndefined) {
                    return;
                }
                container.model[key] = value;
            });
        }

        this.updateSelectionStyles();

        if (saveChanges) {
            refreshCanvasAndSaveChanges();
        } else {
            refreshElement();
        }
    }

    startDragSpacingSlider = () => {
        $(".MuiPaper-root > div > :not(#spacing-menu-item)").opacity(0);
        $("#spacing-menu-item").css({ boxShadow: $(".MuiPaper-root").css("boxShadow"), background: "white", border: "solid 10px white" });
        $(".MuiPaper-root").css({ background: "none", boxShadow: "none" });
    }

    stopDragSpacingSlider = () => {
        $(".MuiPaper-root").css({ background: "white", boxShadow: $("#spacing-menu-item").css("boxShadow") });
        $(".MuiPaper-root > div > :not(#spacing-menu-item)").opacity(1);
        $("#spacing-menu-item").css({ boxShadow: "none", background: "none", border: "none" });
    }

    //region render functions

    saveSelection = elem => {
        this.storedSelection = {
            state: getSelectionState(elem),
            elem,
        };
    }

    restoreSelection = () => {
        if (this.storedSelection) {
            setSelection(this.storedSelection.state, this.storedSelection.elem);
            this.storedSelection = null;
        }
    }

    handleCustomGenerateText = ({
        task,
        prompt = "",
        isCustomPrompt = false,
        isRecentlyUsed = false,
        shorten = false,
        lengthen = false,
    }) => {
        const {
            containers
        } = this.props;

        const { selectedBlocks } = this.props;
        const selectedBlock = selectedBlocks[0];

        const contentEditableElement = selectedBlock.ref.current;
        const textboxText = contentEditableElement.innerText.trim();
        let { textStyle } = selectedBlock.model;
        if (!textStyle || textStyle[0] === "_") {
            textStyle = TextStyleType.LABEL;
        }

        const selection = getSelection();
        const selectedText = selection?.toString();

        const allText = containers[0].canvas.slide.getSlideText();

        // Save the selection so we can restore it when the dialog is closed
        this.saveSelection(contentEditableElement);

        ShowDialog(GenerateTextDialog, {
            task,
            selectedText,
            textboxText,
            allText,
            textStyle,
            initialPrompt: prompt,
            isCustomPrompt,
            isRecentlyUsed,
            shorten,
            lengthen,
            onClose: () => {
                this.restoreSelection();
            },
            onApply: this.applyGenerateTextResult,
        });
    }

    handleQuickGenerateText = async ({
        task,
        prompt,
        isCustomPrompt = false,
        isRecentlyUsed = false,
        shorten = false,
        lengthen = false,
    }) => {
        const {
            containers
        } = this.props;

        const { selectedBlocks } = this.props;
        const selectedBlock = selectedBlocks[0];

        const contentEditableElement = selectedBlock.ref.current;
        const textboxText = contentEditableElement.innerText.trim();
        let { textStyle } = selectedBlock.model;
        if (!textStyle || textStyle[0] === "_") {
            textStyle = TextStyleType.LABEL;
        }

        const selection = getSelection();
        const selectedText = selection?.toString();

        const allText = containers[0].canvas.slide.getSlideText();

        // Save the selection so we can restore it when the generation completes
        this.saveSelection(contentEditableElement);

        const dialogProgress = ShowDialog(ProgressDialog, {
            title: "Generating text...",
        });

        const handleError = error => {
            logger.error(error, "Error while performing quick generate");
            dialogProgress.props.closeDialog();
            ShowErrorDialog({
                title: (
                    <>Unfortunately, the text generation failed.<br />Please try again.</>
                ),
            });
        };

        await generateText({
            task,
            prompt,
            selectedText,
            textboxText,
            allText,
            textStyle,
            variationCount: 1,
            isCustomPrompt,
            isRecentlyUsed,
            shorten,
            lengthen,
            onReportState: ({
                results,
                isGenerating,
                error,
            }) => {
                if (error) {
                    handleError(error);
                } else if (
                    !isGenerating &&
                    results.length
                ) {
                    const result = results[0];
                    if (!result?.text?.length) {
                        handleError("Result text is empty.");
                    }

                    this.restoreSelection();
                    this.applyGenerateTextResult(result, true);
                    dialogProgress.props.closeDialog();
                }
                this.setState({ isGeneratingText: isGenerating });
            },
        });
    }

    truncatePrompt(prompt) {
        let result = prompt;
        if (result.length > 100) {
            const chars = result.split("");
            chars.splice(97, chars.length - 97);
            result = chars.join("");
            result += "...";
        }
        return result;
    }

    recordRecentlRewritePrompt(prompt, isCustomPrompt) {
        const sessionPrompts = _.clone(this.state.recentlyUsedRewritePrompts);

        // Remove the prompt if it was previously present in the list
        const index = sessionPrompts.findIndex(item => item.prompt === prompt);
        if (index > -1) {
            sessionPrompts.splice(index, 1);
        }

        const item = {
            prompt,
            isCustomPrompt,
        };

        // Add the prompt to the beginning
        sessionPrompts.unshift(item);

        const persistedPrompts = _.clone(sessionPrompts);
        // Delete any prompts after the 5th item
        persistedPrompts.splice(
            Math.min(5, persistedPrompts.length),
            Math.max(0, persistedPrompts.length - 5)
        );

        // Save the new lists
        app.user.update({
            librarySettings: { recentlyUsedRewritePrompts: persistedPrompts }
        });
        this.setState({
            recentlyUsedRewritePrompts: sessionPrompts,
        });
    }

    applyGenerateTextResult = async (result, skipRecord = false) => {
        // Wait until the selection has been restored
        await delayUntil(() => !this.storedSelection);

        let {
            text,
            applyAction,
            trackProps,
        } = result;

        const {
            selectedBlocks,
            updateHtml,
        } = this.props;

        const selectedBlock = selectedBlocks[0];

        const contentEditableElement = selectedBlock.ref.current;

        const textboxText = contentEditableElement.innerText;
        const baseText = text;

        const selection = getSelection();
        let selectedText = selection?.toString();

        // If the selection is empty, select all
        if (
            selection.collapsed ||
            (
                applyAction === GenTextApplyAction.REPLACE &&
                !selectedText
            )
        ) {
            selection.selectAllChildren(contentEditableElement);
            selectedText = selection?.toString();
        }

        const words = selectedText.split(/\s/).filter(x => !!x);

        // Maintain casing
        if (
            text.length &&
            (
                applyAction === GenTextApplyAction.APPEND ||
                trackProps.selectedText[0] === trackProps.selectedText[0]?.toUpperCase()
            )
        ) {
            const chars = text.split("");
            chars[0] = chars[0].toUpperCase();
            text = chars.join("");
        }

        if (
            applyAction === GenTextApplyAction.APPEND &&
            !!textboxText.length
        ) {
            // Add a space at the beginning
            const regexEndsWithWhitespace = /\s+$/g;
            if (!regexEndsWithWhitespace.test(textboxText)) {
                text = " " + text.trim();
            }
            // If we don't have punctuation or whitespace at
            //   the end of the current text, add a period
            const regexPunctOrWhitespace = /[^\w]+$/g;
            if (!regexPunctOrWhitespace.test(textboxText)) {
                text = "." + text;
            }
        }

        if (applyAction === GenTextApplyAction.APPEND) {
            const range = document.createRange();
            range.selectNodeContents(contentEditableElement);
            selection.removeAllRanges();
            selection.addRange(range);
            selection.collapseToEnd();
        }

        const range = selection.getRangeAt(0);
        range.deleteContents();
        range.insertNode(document.createTextNode(sanitizeHtmlText(text)));

        // Submit the full block text to be saved now that we're done changing it.
        updateHtml(sanitizeHtml(contentEditableElement.innerHTML));

        // Record rewrite prompts as recently used
        if (
            trackProps.task === RewriteTextTask.REWRITE &&
            trackProps.prompt &&
            !skipRecord
        ) {
            this.recordRecentlRewritePrompt(trackProps.prompt, trackProps.isCustomPrompt);
        }

        delete trackProps.results;
        trackProps.result = baseText;

        trackActivity("TextRewrite", "Apply", null, null, trackProps);
    }

    renderAIDropdown() {
        let {
            recentlyUsedRewritePrompts,
            isGeneratingText,
        } = this.state;
        const {
            selectedBlocks,
        } = this.props;
        const designerBotDisabled = app.user.features.isFeatureEnabled(FeatureType.PROHIBIT_GENERATIVE_AI, AppController.workspaceId);
        const designerBotAccessible = app.user.features.isFeatureEnabled(FeatureType.DESIGNER_BOT, AppController.workspaceId);

        if (
            selectedBlocks.length == 1 &&
            !designerBotDisabled
        ) {
            const selectedBlock = selectedBlocks[0];
            const contentEditableElement = selectedBlock.ref.current;
            const textboxText = contentEditableElement?.innerText.trim();

            return (
                <ControlBarGroup color={themeColors.aiColor} style={{ padding: 0 }}>
                    <PopupMenu
                        component={<DesignerBotIcon />}
                        childrenAreMenuItems
                        disableOverflow
                    >
                        <MenuItem
                            disabled={isGeneratingText || !designerBotAccessible}
                            divider
                            onClick={() => this.handleCustomGenerateText({
                                task: RewriteTextTask.GENERATE,
                            })}
                        >
                            <Icon>playlist_add</Icon>
                            <span>Generate new...</span>
                        </MenuItem>
                        <MenuItem
                            disabled={isGeneratingText || !designerBotAccessible || !textboxText}
                            onClick={() => this.handleQuickGenerateText({
                                task: RewriteTextTask.REWRITE,
                                prompt: "",
                            })}
                        >
                            <Icon>autorenew</Icon>
                            <span>Quick rewrite</span>
                        </MenuItem>

                        <MenuItem
                            disabled={isGeneratingText || !designerBotAccessible || !textboxText}
                            divider={!recentlyUsedRewritePrompts.length}
                            onClick={() => this.handleCustomGenerateText({
                                task: RewriteTextTask.REWRITE,
                            })}
                        >
                            <Icon>edit_note</Icon>
                            <span>Rewrite with prompt...</span>
                        </MenuItem>
                        {
                            !!recentlyUsedRewritePrompts.length &&
                            <NestedMenuItem
                                disabled={isGeneratingText || !designerBotAccessible || !textboxText}
                                divider
                                icon={<Icon style={{ marginRight: 5 }}>recent_actors</Icon>}
                                label="Recent prompts"
                            >
                                {
                                    recentlyUsedRewritePrompts.map((item, index) => {
                                        // Handle old prompts which were simply strings,
                                        //   or new which are objects
                                        let prompt;
                                        let isCustomPrompt;
                                        if (item?.prompt) {
                                            prompt = item.prompt;
                                            isCustomPrompt = item.isCustomPrompt;
                                        } else {
                                            prompt = item;
                                            isCustomPrompt = true;
                                        }

                                        return (
                                            <MenuItem
                                                key={`recent-prompt-${index}`}
                                                divider={index === recentlyUsedRewritePrompts.length - 1}
                                                disabled={isGeneratingText || !designerBotAccessible || !textboxText}
                                                onClick={() => this.handleCustomGenerateText({
                                                    task: RewriteTextTask.REWRITE,
                                                    prompt,
                                                    isCustomPrompt,
                                                    isRecentlyUsed: true,
                                                })}
                                                style={{
                                                    maxWidth: 200,
                                                    textWrap: "pretty",
                                                    lineHeight: 1.3,
                                                }}
                                            >{this.truncatePrompt(prompt)}</MenuItem>
                                        );
                                    })
                                }
                            </NestedMenuItem>
                        }
                        <MenuItem
                            disabled={isGeneratingText || !designerBotAccessible || !textboxText}
                            onClick={() => this.handleCustomGenerateText({
                                task: RewriteTextTask.REWRITE,
                                prompt: "",
                                shorten: true,
                            })}
                        >
                            <Icon>short_text</Icon>
                            <span>Shorten...</span>
                        </MenuItem>
                        <MenuItem
                            disabled={isGeneratingText || !designerBotAccessible || !textboxText}
                            divider
                            onClick={() => this.handleCustomGenerateText({
                                task: RewriteTextTask.REWRITE,
                                prompt: "",
                                lengthen: true,
                            })}
                        >
                            <Icon>format_align_justify</Icon>
                            <span>Lengthen...</span>
                        </MenuItem>
                        <MenuItem
                            disabled={isGeneratingText || !designerBotAccessible || !textboxText}
                            divider
                            onClick={() => this.handleQuickGenerateText({
                                task: RewriteTextTask.SPELLCHECK,
                            })}
                        >
                            <Icon>auto_fix_high</Icon>
                            <span>Fix spelling and grammar</span>
                        </MenuItem>
                        {
                            !designerBotAccessible &&
                            <div style={{ padding: 10 }}>
                                <Divider style={{ marginBottom: 10 }} />
                                <FlexBox left>
                                    <ProBadge
                                        upgradeType={UpgradePlanDialogType.UPGRADE_PLAN}
                                        show={!designerBotAccessible}
                                        analytics={{ cta: "TextBot", ...ds.selection?.presentation?.getAnalytics() }}
                                        workspaceId={AppController.workspaceId}
                                        style={{ marginLeft: 0 }}
                                    />
                                </FlexBox>
                                <UpgradeMessage>
                                    Let DesignerBot rework your
                                    <br />
                                    copy and inspire a more
                                    <br />
                                    impactful message, faster.
                                </UpgradeMessage>
                                <BlueButton
                                    fullWidth
                                    style={{ marginBottom: 0 }}
                                    onClick={() => openPricingPage(app.user.analyticsPersonalPlan, { cta: "TextBot", ...ds.selection?.presentation?.getAnalytics() })}
                                >Get Started</BlueButton>
                            </div>
                        }
                    </PopupMenu>
                </ControlBarGroup>
            );
        } else {
            return null;
        }
    }

    renderTextStyleMenu() {
        const { selectedBlocks, editorConfig, refreshCanvasAndSaveChanges } = this.props;

        if (editorConfig.allowedBlockTypes.length) {
            return (
                <ControlBarGroup color="#14516e">
                    <BlockTypePopupMenu
                        selectedBlocks={selectedBlocks}
                        allowedBlockTypes={editorConfig.allowedBlockTypes}
                        onChange={refreshCanvasAndSaveChanges}
                    />
                </ControlBarGroup>
            );
        } else {
            return null;
        }
    }

    renderCharStyles() {
        const {
            isBold,
            isItalic,
            isEmphasized
        } = this.state;
        const {
            containers,
            selectedBlocks
        } = this.props;

        const allowEmphasized = !containers.some(container => !container.allowEmphasized);
        const hasBackgroundColor = containers.some(container => container.getBackgroundColor().isColor);
        const canSetEmphasizeColor =
            !isEmphasized &&
            !hasBackgroundColor &&
            selectedBlocks.every(block => block.model.textStyle.equalsAnyOf("_use_styles_", TextStyleType.HEADING, TextStyleType.HEADLINE, TextStyleType.TITLE, TextStyleType.BIG_TEXT, TextStyleType.BULLET_LIST, TextStyleType.NUMBERED_LIST));

        return (
            <>
                {allowEmphasized &&
                    <FlexBox width={30} center middle>
                        {!canSetEmphasizeColor && (
                            <ToggleButton
                                value="emphasize"
                                selected={!!isEmphasized}
                                onChange={() => {
                                    this.updateFontStyle({ emphasized: !isEmphasized });
                                    // Remove accent color so we fall back to the element's accent color
                                    this.updateSelectedBlocksModels({ accentColor: null });
                                }}
                            >
                                <Icon fill={isEmphasized}>star</Icon>
                            </ToggleButton>
                        )}
                        {canSetEmphasizeColor && (
                            <ColorPicker
                                canvas={containers[0].canvas}
                                showPrimary
                                onChange={accentColor => {
                                    this.updateFontStyle({ emphasized: !isEmphasized });
                                    this.updateSelectedBlocksModels({ accentColor });
                                }}
                                customPopup={<Icon>star</Icon>}
                            />
                        )}
                    </FlexBox>
                }
                <ToggleButton
                    value="bold"
                    selected={!!isBold}
                    onChange={() => this.handleFormat("BOLD", !isBold)}
                    style={{ position: "relative", top: 1, opacity: isBold ? 1 : 0.5 }}
                >
                    <Icon>format_bold</Icon>
                </ToggleButton>
                <ToggleButton
                    value="italic"
                    selected={!!isItalic}
                    onChange={() => this.handleFormat("ITALIC", !isItalic)}
                    style={{ position: "relative", top: 1, opacity: isItalic ? 1 : 0.5 }}
                >
                    <Icon>format_italic</Icon>
                </ToggleButton>
            </>
        );
    }

    renderLink() {
        const { isMultiSelectMode } = this.props;
        const { isLink } = this.state;

        return (
            <ToggleButton
                value="link"
                selected={!!isLink}
                disabled={isMultiSelectMode}
                onChange={this.handleChangeLink}
                style={{ opacity: isLink ? 1 : 0.5 }}
            >
                <Icon>insert_link</Icon>
            </ToggleButton>
        );
    }

    renderColorPicker() {
        let { fontColor } = this.state;
        const { editorConfig, containers, selectedBlocks } = this.props;

        if (fontColor == "decoration") {
            fontColor = selectedBlocks[0].props?.textStyles?.color;
        }

        return (
            <ColorPicker
                size={20}
                canvas={containers[0].canvas}
                value={fontColor}
                showPrimary showSecondary showWhite showBlack
                onChange={this.handleSelectColor}
                allowColorOnColor
                showColorPicker={editorConfig.showFullSpectrumColorPicker}
                position="above"
            />
        );
    }

    renderFontSize() {
        const { fontSize } = this.state;

        return (
            <NumberStepper
                width={50}
                value={parseInt(fontSize ?? 0)}
                minValue={6}
                maxValue={500}
                onChange={fontSize => this.updateFontStyle({ fontSize })}
                menuItems={[15, 20, 25, 30, 35, 40, 50, 60, 80, 100]}
            />
        );
    }

    renderFontScale() {
        const fontScale = this.state.fontScale ?? 1;

        return (
            <PopupMenu
                showArrow
                position="above"
                useFixedPosition
                label={fontScale == "mixed" ? "mixed" : (Math.round(fontScale * 100) + "%")}
                onShow={() => {
                    // in AuthoringBlockEditor we set app.isEditingText to false on blur, but we want to keep it true,
                    // so we defer the change until after the blur has been triggered
                    _.defer(() => {
                        app.isEditingText = true;
                    });
                }}
                onClose={() => app.isEditingText = false}
            >
                <TextScalePopupMenu>
                    <InputSlider
                        id="text-size-slider"
                        style={{ width: 120 }}
                        value={fontScale}
                        onChange={fontScale => this.handleChangeFontScale(fontScale, false)}
                        onChangeCommitted={fontScale => this.handleChangeFontScale(fontScale, true)}
                        sliderMin={0.25}
                        sliderMax={2}
                        inputMin={0.1}
                        inputMax={2}
                        step={0.05}
                        inputFormat="percentage"
                    />
                </TextScalePopupMenu>
            </PopupMenu>
        );
    }

    renderListStyles() {
        const { containers, editorConfig, selectedBlocks } = this.props;
        const { listStyle, bulletColor, indent, allowFancyNumberedDecorations, listDecorationStyle, useThemedListDecoration } = this.state;

        const canvas = containers[0].canvas;

        let allowBulletColor = listStyle?.equalsAnyOf(ListStyleType.BULLET, ListStyleType.NUMBERED, ListStyleType.ICON);

        let bulletSlideColor = "theme";
        if (indent > 0) {
            bulletSlideColor = "primary";
        }

        return (
            <>
                <PopupMenu
                    icon="format_list_bulleted"
                    showArrow={false}
                    position="above"
                    style={{ padding: 6 }}
                >
                    <PopupMenuPaddedContainer>
                        <ToggleButtonContainer>
                            <ToggleButton
                                value={ListStyleType.TEXT}
                                selected={listStyle == ListStyleType.TEXT}
                                onChange={() => this.handleToggleListStyle(ListStyleType.TEXT)}>
                                <Icon>subject</Icon>
                            </ToggleButton>
                            <ToggleButton
                                value={ListStyleType.BULLET}
                                selected={listStyle == ListStyleType.BULLET}
                                onChange={() => this.handleToggleListStyle(ListStyleType.BULLET)}>
                                <Icon>format_list_bulleted</Icon>
                            </ToggleButton>
                            <ToggleButton
                                value={ListStyleType.NUMBERED}
                                selected={listStyle == ListStyleType.NUMBERED}
                                onChange={() => this.handleToggleListStyle(ListStyleType.NUMBERED, { style: "numbers" })}>
                                <Icon>format_list_numbered</Icon>
                                <PopupMenu showArrow
                                    style={{ paddingLeft: 0, paddingRight: 5 }}>
                                    <PopupMenuPaddedContainer>
                                        <ToggleButtonContainer>
                                            <ToggleButton
                                                value="numbers"
                                                selected={listDecorationStyle == "numbers"}
                                                onChange={() => this.handleToggleListStyle(ListStyleType.NUMBERED, { style: "numbers" })}
                                            ><Icon>123</Icon>
                                            </ToggleButton>
                                            <ToggleButton
                                                value="letters"
                                                selected={listDecorationStyle == "letters"}
                                                onChange={() => this.handleToggleListStyle(ListStyleType.NUMBERED, { style: "letters" })}
                                            ><Icon>abc</Icon>
                                            </ToggleButton>
                                        </ToggleButtonContainer>
                                        {allowFancyNumberedDecorations &&
                                            <FormControlLabel
                                                label="Use Theme Decoration"
                                                control={<Checkbox checked={useThemedListDecoration}
                                                    onChange={event => this.handleToggleListStyle(ListStyleType.NUMBERED, { useThemedListDecoration: event.target.checked })}
                                                />}
                                            />
                                        }
                                    </PopupMenuPaddedContainer>
                                </PopupMenu>
                            </ToggleButton>
                            <ToggleButton
                                value={ListStyleType.CHECKBOX}
                                selected={listStyle == ListStyleType.CHECKBOX}
                                onChange={() => this.handleToggleListStyle(ListStyleType.CHECKBOX)}>
                                <Icon>checklist</Icon>
                            </ToggleButton>
                            <ToggleButton
                                value={ListStyleType.ICON}
                                selected={listStyle == ListStyleType.ICON}
                            >
                                <PopupMenu icon="star" showArrow style={{ paddingLeft: 0, paddingRight: 5 }}>
                                    <IconListDecorationMenu
                                        onChange={icon => this.handleToggleListStyle(ListStyleType.ICON, { icon })} />
                                </PopupMenu>
                            </ToggleButton>
                            {allowBulletColor && <Gap10 />}
                            {allowBulletColor &&
                                <ColorPicker
                                    canvas={canvas}
                                    value={bulletColor ?? bulletSlideColor}
                                    backgroundColor="black"
                                    showPrimary showSecondary
                                    showColorPicker={editorConfig.showFullSpectrumColorPicker}
                                    onChange={this.handleSelectBulletColor}
                                    position="above"
                                />
                            }
                        </ToggleButtonContainer>
                    </PopupMenuPaddedContainer>
                </PopupMenu>
                {selectedBlocks[0].props.textStyle === TextStyleType.BULLET_LIST && <IconButton onClick={() => this.handleChangeIndent(1)}>
                    <Icon>format_indent_increase</Icon>
                </IconButton>}
                {selectedBlocks[0].props.textStyle === TextStyleType.BULLET_LIST && <IconButton onClick={() => this.handleChangeIndent(-1)}>
                    <Icon>format_indent_decrease</Icon>
                </IconButton>}
            </>
        );
    }

    renderTextAlign() {
        const { textAlign = "left" } = this.state;

        return (
            <PopupMenu
                icon={`format_align_${textAlign == "mixed" ? "left" : textAlign}`}
                showArrow={false}
                position="above"
                style={{ padding: 0 }}
            >
                <PopupMenuPaddedContainer>
                    <LabeledContainer label="Text Align">
                        <ToggleButtonContainer>
                            <ToggleButton
                                value="left"
                                selected={textAlign == HorizontalAlignType.LEFT}
                                onChange={() => this.handleSetTextAlign(HorizontalAlignType.LEFT)}
                            >
                                <Icon>format_align_left</Icon>
                            </ToggleButton>
                            <ToggleButton
                                value="center"
                                selected={textAlign == HorizontalAlignType.CENTER}
                                onChange={() => this.handleSetTextAlign(HorizontalAlignType.CENTER)}
                            >
                                <Icon>format_align_center</Icon>
                            </ToggleButton>
                            <ToggleButton
                                value="right"
                                selected={textAlign == HorizontalAlignType.RIGHT}
                                onChange={() => this.handleSetTextAlign(HorizontalAlignType.RIGHT)}
                            >
                                <Icon>format_align_right</Icon>
                            </ToggleButton>
                        </ToggleButtonContainer>
                    </LabeledContainer>
                </PopupMenuPaddedContainer>
            </PopupMenu>
        );
    }

    renderVerticalAlign() {
        const { verticalAlign } = this.state;

        return (
            <PopupMenu
                icon={`vertical_align_${verticalAlign == "mixed" ? "top" : (verticalAlign == VerticalAlignType.MIDDLE ? "center" : verticalAlign)}`}
                showArrow={false}
                position="above"
                style={{ padding: 0 }}
            >
                <PopupMenuPaddedContainer>
                    <LabeledContainer label="Vertical Align">
                        <ToggleButtonContainer>
                            <ToggleButton
                                value="top"
                                selected={verticalAlign == VerticalAlignType.TOP}
                                onChange={() => this.handleSetVerticalAlign(VerticalAlignType.TOP)}
                            >
                                <Icon>vertical_align_top</Icon>
                            </ToggleButton>
                            <ToggleButton
                                value="middle"
                                selected={verticalAlign == VerticalAlignType.MIDDLE}
                                onChange={() => this.handleSetVerticalAlign(VerticalAlignType.MIDDLE)}
                            >
                                <Icon>vertical_align_center</Icon>
                            </ToggleButton>
                            <ToggleButton
                                value="bottom"
                                selected={verticalAlign == VerticalAlignType.BOTTOM}
                                onChange={() => this.handleSetVerticalAlign(VerticalAlignType.BOTTOM)}
                            >
                                <Icon>vertical_align_bottom</Icon>
                            </ToggleButton>
                        </ToggleButtonContainer>
                    </LabeledContainer>

                </PopupMenuPaddedContainer>
            </PopupMenu>
        );
    }

    renderSimpleCharacterStylesMenu() {
        const {
            isSubscript,
            isSuperscript,
            isUnderline,
            isStrikeThrough
        } = this.state;

        return (
            <PopupMenu
                id="text-format"
                icon="text_format"
                showArrow={true}
                position="above"
                popupWidth={900}
                arrowAdjust={-5}
                style={{ marginTop: 1, paddingLeft: 0 }}
            >
                <PopupMenuPaddedContainer>
                    <GridBox rows gap={15}>
                        <ToggleButtonContainer>
                            <ToggleButton
                                value="strikethrough"
                                selected={isStrikeThrough}
                                onChange={() => this.handleFormat("STRIKETHROUGH", !isStrikeThrough)}
                            >
                                <Icon>format_strikethrough</Icon>
                            </ToggleButton>
                            <ToggleButton
                                value="underline"
                                selected={isUnderline}
                                onChange={() => this.handleFormat("UNDERLINE", !isUnderline)}
                            >
                                <Icon>format_underlined</Icon>
                            </ToggleButton>
                            <ToggleButton
                                value="subscript"
                                selected={isSubscript}
                                onChange={() => this.handleFormat("SUBSCRIPT", !isSubscript)}
                            >
                                <Icon>subscript</Icon>
                            </ToggleButton>
                            <ToggleButton
                                value="superscript"
                                selected={isSuperscript}
                                onChange={() => this.handleFormat("SUPERSCRIPT", !isSuperscript)}
                            >
                                <Icon>superscript</Icon>
                            </ToggleButton>
                        </ToggleButtonContainer>
                    </GridBox>
                </PopupMenuPaddedContainer>
            </PopupMenu>
        );
    }

    renderAdvancedCharacterStylesMenu() {
        const {
            isMultiSelectMode,
        } = this.props;
        const {
            letterSpacing,
            lineHeight,
            isLink,
            isSubscript,
            isSuperscript,
            isUnderline,
            isStrikeThrough,
            fontFamily,
            fontWeight,
            builtInFonts,
            customFonts,
            currentFontStyles,
            fontMenuAnchorEl
        } = this.state;

        const fontWeights = [];
        if (currentFontStyles) {
            currentFontStyles.forEach(style => {
                if (!fontWeights.includes(parseInt(style.weight))) {
                    fontWeights.push(parseInt(style.weight));
                }
            });
        }
        if (fontWeight && !fontWeights.includes(fontWeight)) {
            fontWeights.push(fontWeight);
        }

        const isSelectedFontLoading = fontFamily !== "mixed" && !builtInFonts.some(font => font.id === fontFamily) && !customFonts.some(font => font.id === fontFamily);

        let currentFontLabel = customFonts.find(font => font.id == fontFamily)?.getStyle(fontWeight, false)?.label;
        if (!currentFontLabel) {
            currentFontLabel = builtInFonts.find(font => font.id == fontFamily)?.getStyle(fontWeight, false)?.label;
        }
        if (!currentFontLabel && isSelectedFontLoading) {
            currentFontLabel = "Loading...";
        }
        if (fontFamily == "mixed") {
            currentFontLabel = "Mixed";
        }

        return (
            <PopupMenu
                id="text-format"
                icon="font_download"
                showArrow={true}
                position="above"
                popupWidth={900}
            >
                <PopupMenuPaddedContainer>
                    <GridBox columns gap={40}>
                        <GridBox rows gap={15}>
                            <LabeledContainer icon="font_download" label="Font">
                                <FontSelect onClick={this.handleShowFontMenu}>
                                    <label>{currentFontLabel}</label>
                                    <Icon className="drop-down-arrow">arrow_drop_down</Icon>
                                </FontSelect>
                                <Popover
                                    open={!!fontMenuAnchorEl}
                                    anchorEl={fontMenuAnchorEl}
                                    onClose={this.handleCloseFontMenu}
                                >
                                    <GridBox rows gap={5}>
                                        {isSelectedFontLoading && <FontMenuItem disabled={true}
                                            value={fontFamily}><span>Loading...</span></FontMenuItem>}
                                        <>
                                            {customFonts.length > 0 &&
                                                <FontMenuItemWithDivider disabled={true} value={"__custom"}><span>Uploaded fonts</span></FontMenuItemWithDivider>}

                                            {fontMenuAnchorEl && customFonts.sort(font => font.label).map(font => (
                                                <NestedMenuItem
                                                    key={font.id}
                                                    contents={<img src={font.imageData} height={24} />}>
                                                    {font.styles.filter(fontStyle => !fontStyle.italic).map((fontStyle, index) => (
                                                        <FontMenuItem
                                                            key={index}
                                                            onMouseDown={event => {
                                                                this.handleCloseFontMenu(event)
                                                                    .then(() => this.handleSelectFont(font.id, fontStyle.weight));
                                                            }}
                                                        >
                                                            <img
                                                                height={24}
                                                                src={fontStyle.imageData}
                                                            />
                                                        </FontMenuItem>
                                                    ))}
                                                </NestedMenuItem>
                                            ))}

                                            {builtInFonts.length > 0 &&
                                                <FontMenuItemWithDivider disabled={true} value={"__custom"}><span>Built-in fonts</span></FontMenuItemWithDivider>}

                                            {fontMenuAnchorEl && builtInFonts.sort(font => font.label).map(font => (
                                                <NestedMenuItem
                                                    key={font.id}
                                                    contents={<img src={font.imageData} height={24} />}>
                                                    {font.styles.filter(fontStyle => !fontStyle.italic).map((fontStyle, index) => (
                                                        <FontMenuItem
                                                            key={index}
                                                            onMouseDown={event => {
                                                                this.handleCloseFontMenu(event)
                                                                    .then(() => this.handleSelectFont(font.id, fontStyle.weight));
                                                            }}
                                                        >
                                                            <img
                                                                height={24}
                                                                src={fontStyle.imageData}
                                                            />
                                                        </FontMenuItem>
                                                    ))}
                                                </NestedMenuItem>
                                            ))}
                                        </>
                                    </GridBox>
                                </Popover>
                            </LabeledContainer>
                            <LabeledContainer icon="text_format" label="Character Styles">
                                <div>
                                    <ToggleButton
                                        value="link"
                                        selected={!!isLink}
                                        disabled={isMultiSelectMode}
                                        onChange={this.handleChangeLink}
                                    >
                                        <Icon>insert_link</Icon>
                                    </ToggleButton>
                                    <ToggleButton
                                        value="strikethrough"
                                        selected={isStrikeThrough}
                                        onChange={() => this.handleFormat("STRIKETHROUGH", !isStrikeThrough)}
                                    >
                                        <Icon>format_strikethrough</Icon>
                                    </ToggleButton>
                                    <ToggleButton
                                        value="underline"
                                        selected={isUnderline}
                                        onChange={() => this.handleFormat("UNDERLINE", !isUnderline)}
                                    >
                                        <Icon>format_underlined</Icon>
                                    </ToggleButton>
                                    <ToggleButton
                                        value="subscript"
                                        selected={isSubscript}
                                        onChange={() => this.handleFormat("SUBSCRIPT", !isSubscript)}
                                    >
                                        <Icon>subscript</Icon>
                                    </ToggleButton>
                                    <ToggleButton
                                        value="superscript"
                                        selected={isSuperscript}
                                        onChange={() => this.handleFormat("SUPERSCRIPT", !isSuperscript)}
                                    >
                                        <Icon>superscript</Icon>
                                    </ToggleButton>
                                </div>
                            </LabeledContainer>
                        </GridBox>

                        <GridBox rows gap={15}>
                            <LabeledContainer icon="text_rotation_none" label="Letter Spacing">
                                <InputSlider
                                    value={letterSpacing}
                                    onChange={letterSpacing => this.updateSelectedBlocksModels({ letterSpacing }, false, false)}
                                    onChangeCommitted={letterSpacing => this.updateSelectedBlocksModels({ letterSpacing })}
                                    sliderMin={-2}
                                    sliderMax={5}
                                    inputMin={-10}
                                    inputMax={100}
                                    step={0.1}
                                />
                            </LabeledContainer>
                            <LabeledContainer icon="format_line_spacing" label="Line Height">
                                <InputSlider
                                    value={lineHeight}
                                    onChange={lineHeight => this.updateSelectedBlocksModels({ lineHeight }, false, false)}
                                    onChangeCommitted={lineHeight => this.updateSelectedBlocksModels({ lineHeight })}
                                    sliderMin={0.75}
                                    sliderMax={2}
                                    inputMin={0}
                                    inputMax={10}
                                    step={0.05}
                                />
                            </LabeledContainer>
                        </GridBox>
                    </GridBox>
                </PopupMenuPaddedContainer>
            </PopupMenu>
        );
    }

    renderSettingsMenu = () => {
        const { editorConfig = {}, selectedBlocks } = this.props;
        const { syncFontSizeWithSiblings, ligatures, hyphenation, fontKerning, evenBreak, spaceAbove, columns } = this.state;

        const bulletsAreSelected = selectedBlocks.every(block => block.props.textStyle == TextStyleType.BULLET_LIST);

        return (
            <PopupMenu
                icon="more_vert"
                showArrow={false}
                style={{ paddingRight: 5 }}
            >
                <PopupMenuPaddedContainer style={{ gridGap: 10 }}>
                    <BlueOutlinedButton onClick={this.handleResetStyles}>Reset Styles</BlueOutlinedButton>
                    <Gap10 />
                    {/*{editorConfig.showColumns &&*/}
                    {/*    <LabeledContainer label="Columns">*/}
                    {/*        <Select*/}
                    {/*            value={columns}*/}
                    {/*            onChange={event => this.handleChangeFormat({ columns: event.target.value })}*/}
                    {/*            variant="outlined"*/}
                    {/*        >*/}
                    {/*            <MenuItem value={1}>1</MenuItem>*/}
                    {/*            <MenuItem value={2}>2</MenuItem>*/}
                    {/*            <MenuItem value={3}>3</MenuItem>*/}
                    {/*        </Select>*/}
                    {/*    </LabeledContainer>*/}
                    {/*}*/}
                    {editorConfig.showMargins &&
                        <>
                            <LabeledContainer id="spacing-menu-item" label="Spacing Above">
                                {/*<InputSlider*/}
                                {/*    value={spaceAbove}*/}
                                {/*    onChange={spaceAbove => this.updateSelectedBlocksModels({ spaceAbove }, false, false)}*/}
                                {/*    onChangeCommitted={spaceAbove => this.updateSelectedBlocksModels({ spaceAbove })}*/}
                                {/*    sliderMin={-20}*/}
                                {/*    sliderMax={100}*/}
                                {/*    inputMin={-100}*/}
                                {/*    inputMax={300}*/}
                                {/*    step={1}*/}
                                {/*    onStartDrag={this.startDragSpacingSlider}*/}
                                {/*    onEndDrag={this.stopDragSpacingSlider}*/}
                                {/*    disabled={selectedBlocks.length == 1 && selectedBlocks[0].index == 0}*/}
                                {/*/>*/}
                                <Slider value={spaceAbove} min={-20} max={100} step={1}
                                    onChange={spaceAbove => this.updateSelectedBlocksModels({ spaceAbove }, false, false)}
                                    onCommit={spaceAbove => this.updateSelectedBlocksModels({ spaceAbove })}
                                    disabled={selectedBlocks.length == 1 && selectedBlocks[0].index == 0}
                                    showInput
                                />
                            </LabeledContainer>
                            <Gap5 />
                            <Divider />
                            <Gap5 />
                        </>
                    }

                    <SmallLabel>Advanced Settings</SmallLabel>
                    <FlexBox vertical left top style={{ paddingLeft: 10 }}>
                        {editorConfig.showEvenBreak && !bulletsAreSelected &&
                            <FlexBox left middle>
                                <FormControlLabel
                                    label="Balance Ragged Lines"
                                    control={<Checkbox checked={evenBreak}
                                        onChange={event => this.handleChangeFormat({ evenBreak: event.target.checked })}
                                    />}
                                    style={{ marginRight: 5 }}
                                />
                                <InfoToolTip
                                    title="Format text to visually minimize uneven lines and prevent orphaned words on the last line. Note: This option only effects multiple lines of text."
                                />
                            </FlexBox>
                        }
                        {editorConfig.showMatchSizes &&
                            <FlexBox left middle>
                                <FormControlLabel
                                    label="Match Font Scale With Other Items"
                                    control={<Checkbox checked={syncFontSizeWithSiblings}
                                        onChange={event => this.handleChangeSyncFontScale(event.target.checked)}
                                    />}
                                    style={{ marginRight: 5 }}
                                />
                                <InfoToolTip
                                    title="Checking this option will match the font scale of this text with the other items on this slide."
                                />
                            </FlexBox>
                        }
                        {editorConfig.showBlockStyles &&
                            <NestedMenuItem label="Block Style">
                                <MenuItem
                                    onClick={() => this.handleChangeBlockStyle(BlockStyleType.NONE)}>None</MenuItem>
                                <MenuItem
                                    onClick={() => this.handleChangeBlockStyle(BlockStyleType.INSET)}>Inset</MenuItem>
                                <MenuItem
                                >Color <ColorPicker
                                        onChange={color => this.handleChangeBlockStyle(BlockStyleType.COLOR, color)} /></MenuItem>
                            </NestedMenuItem>
                        }
                        <FlexBox left middle>
                            <FormControlLabel
                                label="Use Kerning"
                                control={<Checkbox checked={fontKerning}
                                    onChange={event => this.handleChangeFormat({ fontKerning: event.target.checked })}
                                />}
                                style={{ marginRight: 5 }}
                            />
                            <InfoToolTip
                                title="Kerning defines how letters are spaced. In well-kerned fonts, this feature makes character spacing more uniform and pleasant to read than it would otherwise be."
                            />
                        </FlexBox>
                        <FlexBox left middle>
                            <FormControlLabel
                                label="Use Hyphenation"
                                control={<Checkbox checked={hyphenation}
                                    onChange={event => this.handleChangeFormat({ hyphenation: event.target.checked })}
                                />}
                                style={{ marginRight: 5 }}
                            />
                            <InfoToolTip
                                title="Break overflowing words using hyphenation instead of wrapping to a new line."
                            />
                        </FlexBox>
                        <FlexBox left middle>
                            <FormControlLabel
                                label="Show Ligatures"
                                control={<Checkbox checked={ligatures}
                                    onChange={event => this.handleChangeFormat({ ligatures: event.target.checked })}
                                />}
                                style={{ marginRight: 5 }}
                            />
                            <InfoToolTip
                                title="Use ligatures to decorate or join certain characters. Only applicable to fonts with ligature support."
                            />
                        </FlexBox>
                    </FlexBox>
                </PopupMenuPaddedContainer>
            </PopupMenu>
        );
    }

    // endregion
    render() {
        const { editorConfig = {}, selectedBlocks, offset = 30, controlBarRef, marginLeft = 0 } = this.props;

        const showListStyles = editorConfig.showListStyles && selectedBlocks.every(b => [TextStyleType.BULLET_LIST, TextStyleType.TITLE, TextStyleType.BODY].includes(b.props.textStyle));

        const bulletsAreSelected = selectedBlocks.every(block => block.props.textStyle == TextStyleType.BULLET_LIST);

        return (
            <AuthoringCanvasControlBar
                id="text-format-bar"
                position="above"
                offset={offset}
                gap={3}
                ref={controlBarRef}
                marginLeft={marginLeft}
            >
                {this.renderTextStyleMenu()}
                <ControlBarGroup>
                    {this.renderCharStyles()}
                    {this.renderLink()}
                    {!editorConfig.showAdvancedStylesMenu && this.renderSimpleCharacterStylesMenu()}
                    {editorConfig.showAdvancedStylesMenu && this.renderAdvancedCharacterStylesMenu()}
                </ControlBarGroup>
                {showListStyles &&
                    <ControlBarGroup>
                        {showListStyles && this.renderListStyles()}
                    </ControlBarGroup>
                }
                <ControlBarGroup>
                    <Gap10 />
                    {this.renderColorPicker()}
                    <Gap10 />
                    {editorConfig.showFontSize && this.renderFontSize()}
                    {!editorConfig.showFontSize && this.renderFontScale()}
                    {/*<Gap10 />*/}
                    {editorConfig.showTextAlign && !bulletsAreSelected && this.renderTextAlign()}
                    {editorConfig.showVerticalAlign && <Gap10 />}
                    {editorConfig.showVerticalAlign && this.renderVerticalAlign()}
                    {this.renderSettingsMenu()}
                </ControlBarGroup>

                {this.renderAIDropdown()}
            </AuthoringCanvasControlBar>
        );
    }
}

export const TextFormatWidgetBar = React.forwardRef(function TextFormatWidgetBar(props, ref) {
    const { containers } = props;
    const canvasController = containers[0].canvasController;

    const controlBarRef = React.useRef();
    const canvasScreenBoundsRef = React.useRef();

    const [marginLeft, setMarginLeft] = React.useState(0);

    React.useEffect(() => {
        canvasScreenBoundsRef.current = canvasController.canvasScreenBounds;
    }, []);

    React.useEffect(() => {
        if (controlBarRef.current && canvasScreenBoundsRef.current) {
            if (marginLeft === 0) {
                const canvasBounds = canvasScreenBoundsRef.current;
                const controlBarBounds = geom.Rect.FromBoundingClientRect(controlBarRef.current.gridRef.current.getBoundingClientRect());
                const controlBarBoundsFit = controlBarBounds.fitInRect(canvasBounds.deflate(5));
                if (controlBarBoundsFit.left !== controlBarBounds.left) {
                    const offset = controlBarBoundsFit.left - controlBarBounds.left;
                    if (marginLeft !== offset) {
                        setMarginLeft(offset);
                    }
                }
            }
            return;
        }

        if (marginLeft !== 0) {
            setMarginLeft(0);
        }
    }, [controlBarRef.current, canvasScreenBoundsRef.current]);

    return <InnerTextFormatWidgetBar {...props} ref={ref} controlBarRef={controlBarRef} marginLeft={marginLeft} />;
});

export function BlockTypePopupMenu({ selectedBlocks, allowedBlockTypes, onChange }) {
    let blockModels = selectedBlocks.map(block => block.model);

    let handleSetBlockType = (data, closePopup) => {
        let blockType = data.type;
        for (const block of blockModels) {
            if (Object.values(TextStyleType).includes(blockType)) {
                block.textStyle = blockType;
                block.type = AuthoringBlockType.TEXT;
            } else {
                block.type = blockType;
            }

            if (block.textStyle == TextStyleType.BULLET_LIST) {
                block.listStyle = selectedBlocks[0].props.element.defaultBlockListStyle ?? ListStyleType.BULLET;
            } else {
                block.indent = 0;
                block.fontFamily = undefined;
                block.fontSize = undefined;
                block.letterSpacing = undefined;
                block.lineHeight = undefined;
                block.listStyle = undefined;
                // Clean up formatting and only preserve line breaks
                block.html = removeFormattingFromHtmlText(block.html ?? "", ["a"], { "a": ["href", "style", "class", "id"] });
            }
        }

        onChange && onChange();
        closePopup();
    };

    let types = _.uniq(blockModels.map(block => (blockModels[0].type == AuthoringBlockType.TEXT) ? block.textStyle : block.type));

    return (
        <FlexBox>
            <Gap10 />
            <Popup label={types.length > 1 ? "(Mixed)" : types[0]} showArrow>
                <PopupContent>
                    {closePopup => getBlockPopupMenuItems(allowedBlockTypes, type => handleSetBlockType(type, closePopup))}
                </PopupContent>
            </Popup>
        </FlexBox>
    );
}

import { ResizeDirection, TrayType } from "../../../../../../common/constants";
import { defaultDragResizeProps, DragHandleStyle } from "../../../../../editor/PresentationEditor/DragElementManager";
import { BaseElementSelection } from "../../ElementSelections/BaseElementSelection";
import { GridLayoutContainerControlBar, GridLayoutPropertyPanel } from "./GridLayoutUI";
import React, { Component } from "react";
import { PropertyPanelContainer, PropertySection } from "../../../../../EditorComponents/PropertyPanel";
import { ImagePopup } from "../../../../../Components/ImagePopup";
import PresentationEditorController from "../../../../../editor/PresentationEditor/PresentationEditorController";
import { ImageOption } from "../../../../../Components/ImageOptionList";
import { LayoutEngineType } from "../../../elements/elements/LayoutContainers/GridLayoutContainer";
import { WithLabel } from "../../../../../Components/WithLabel";

export class SideBarPropertyPanel extends Component {
    render() {
        const { element, canvas } = this.props;
        const template = canvas.slideTemplate;

        const isInlineTray = canvas.model.layout.trayLayout == TrayType.LEFT_INLINE || canvas.model.layout.trayLayout == TrayType.RIGHT_INLINE;

        const availableLayouts = [LayoutEngineType.SIDEBAR];
        if (isInlineTray) {
            availableLayouts.push(LayoutEngineType.PRESERVE_ASPECT);
        }

        return (
            <PropertyPanelContainer>
                <PropertySection>
                    <WithLabel label="Side Bar Position">
                        <ImagePopup cols={3} size={100} previewSize={50} border={false}
                            value={canvas.model.layout.trayLayout}
                            onChange={async value => {
                                if (canvas.model.layout.trayLayout == value) {
                                    canvas.model.layout.trayLayout = TrayType.NONE;
                                    canvas.updateCanvasModelWithErrorDialog(true);
                                } else {
                                    let hasTray = canvas.model.layout.trayLayout != TrayType.NONE;
                                    if (!hasTray) {
                                        // reset the tray model
                                        canvas.model.elements.tray = null;
                                    }
                                    canvas.model.layout.trayLayout = value;
                                    if (value == TrayType.LEFT_TRAY || value == TrayType.RIGHT_TRAY) {
                                        element.setLayoutEngineType(LayoutEngineType.SIDEBAR);
                                    }
                                    await canvas.updateCanvasModelWithErrorDialog(false);
                                    if (!hasTray) {
                                        PresentationEditorController.selectionLayerController.setSelectedElements([canvas.layouter.elements.tray.itemElements[0]]);
                                    } else {
                                        // this.setGridThumbnails();
                                    }
                                }
                            }}>
                            <ImageOption label="No Sidebar" value={null} url="/images/ui/trays/header_none.svg" />
                            {template.availableTrayLayouts.contains(TrayType.LEFT_TRAY) && (
                                <ImageOption label="Left Fullbleed" value={TrayType.LEFT_TRAY} url="/images/ui/trays/left_tray_header.png" />
                            )}
                            {template.availableTrayLayouts.contains(TrayType.RIGHT_TRAY) && (
                                <ImageOption label="Right Fullbleed" value={TrayType.RIGHT_TRAY} url="/images/ui/trays/right_tray_header.png" />
                            )}
                            {(template.availableTrayLayouts.contains(TrayType.LEFT_TRAY) && template.availableTrayLayouts.contains(TrayType.RIGHT_TRAY)) && (
                                <ImageOption label="" value={"placeholder"} />
                            )}
                            {template.availableTrayLayouts.contains(TrayType.LEFT_INLINE) && (
                                <ImageOption label="Left Inline" value={TrayType.LEFT_INLINE} url="/images/ui/trays/left_inline_header.png" />
                            )}
                            {template.availableTrayLayouts.contains(TrayType.RIGHT_INLINE) && (
                                <ImageOption label="Right Inline" value={TrayType.RIGHT_INLINE} url="/images/ui/trays/right_inline_header.png" />
                            )}
                        </ImagePopup>
                    </WithLabel>
                </PropertySection>
                <GridLayoutPropertyPanel {...this.props}
                    element={element}
                    availableLayoutEngines={availableLayouts}
                    popupIconSize={50} layoutCols={6} layoutSize={50}
                    allowFullBleed={false} allowGutter={!isInlineTray || element.itemCount > 1}
                />
            </PropertyPanelContainer>
        );
    }
}

export class SideBarSelection extends BaseElementSelection {
    get canResize() {
        return true;
    }

    get canDelete() {
        return false;
    }

    handleDeleteElement = () => {
        const { element } = this.props;
        element.canvas.model.layout.trayLayout = TrayType.NONE;
        element.canvas.updateCanvasModel(true);
    }

    get dragResizeProps() {
        const { element } = this.props;

        const maxSize = element.maxTrayWidth;
        const minSize = element.minTrayWidth;
        let initialWidth;
        return {
            ...defaultDragResizeProps,
            handleProps: {
                style: DragHandleStyle.GRABBER,
                offset: {
                    // x: [TrayType.LEFT_TRAY, TrayType.LEFT_INLINE].includes(element.trayLayout) ? 20 : -20,
                    y: 0
                }
            },
            resizeDirections: [TrayType.LEFT_TRAY, TrayType.LEFT_INLINE].includes(element.trayLayout) ? [ResizeDirection.RIGHT] : [ResizeDirection.LEFT],
            onDragStart: async () => {
                initialWidth = element.trayWidth;
            },
            onDrag: async ({ dragOffset, resizeDirection }) => {
                if (resizeDirection === ResizeDirection.LEFT) {
                    element.model.trayWidth = Math.clamp(initialWidth - dragOffset.x, minSize, maxSize);
                } else {
                    element.model.trayWidth = Math.clamp(initialWidth + dragOffset.x, minSize, maxSize);
                }

                await element.canvas.refreshCanvas();
            }
        };
    }
}

export class SideBarControlBar extends GridLayoutContainerControlBar {
    get addItemLabel() {
        return "Add Cell to Tray";
    }

    get suppressPrimaryControlBar() {
        return true;
    }

    onAfterAddItem = () => {
        const { element } = this.props;
        // select a new layout for the new number of items
        element.setGridLayout(element.layoutEngine.getDefaultLayout(element.itemCount));
    }
}


import React, { Component } from "react";
import { ds } from "../../../core/models/dataService";
import styled from "styled-components";
import { app } from "../../../namespaces";
import { FlexBox } from "../../../react/components/LayoutGrid";
import { PopupMenuPaddedContainer } from "../../../react/components/PopupMenu";
import { BlueButton } from "../../../react/components/UiComponents";
import { ShowDialog, ShowWarningDialog } from "../../../react/components/Dialogs/BaseDialog";
import { AddAssetsContainer } from "../../../react/views/AddAssets/index";
import { AssetType } from "../../../../common/constants";
import getLogger from "../../../core/logger/index";
import { ShowAssetDialog } from "../../../react/views/AddAssets/ShowAssetDialog";

const logger = getLogger();

const DEFAULT_ICONS = ["thumbs-up", "thumbs-down", "star", "star-half", "star-none", "full", "quarters-three", "contrast", "quarter", "none"];

const IconPreviewContainer = styled.div`
  width: 50px;
  height: 50px;

  &:hover {
    background: #f1f1f1;
  }
`;

class IconPreview extends Component {
    state = {
        iconPath: null
    }

    async componentDidMount() {
        const { iconId } = this.props;

        ds.assets.getAssetById("icon-" + app.currentTheme.get("iconStyle") + "-" + iconId, "icon")
            .then(icon => {
                const url = icon.get("original");
                return fetch(url).then(res => res.text()).then(data => {
                    const r = / d="(.*?)"/m;
                    const pathString = r.exec(data)[1];
                    this.setState({ iconPath: pathString });
                });
            }).catch(err => {
                logger.error(err, "[IconPreview] failed to load icon", { iconId });
            });
    }

    render() {
        const { onClick } = this.props;
        const { iconPath } = this.state;

        return (
            <IconPreviewContainer onClick={onClick}>
                {iconPath &&
                    <svg width="100%" height="100%" viewBox="0 0 128 128">
                        <path d={iconPath} fill="black" />
                    </svg>
                }
            </IconPreviewContainer>
        );
    }
}

export class IconListDecorationMenu extends Component {
    handleChooseIcon = () => {
        const { onChange } = this.props;

        ShowAssetDialog(AssetType.ICON, {
            supportedAssetTypes: [AssetType.ICON],
            hideVideos: true,
            hidePhotos: true,
            hideLogos: true,
            hideAISearch: true
        }).then(asset => {
            onChange(asset.content_value);
        });
    }

    render() {
        const { onChange } = this.props;

        return (
            <PopupMenuPaddedContainer>
                <FlexBox gap={10} width={250} wrap>
                    {DEFAULT_ICONS.map(icon =>
                        <IconPreview key={icon} iconId={icon} onClick={() => onChange(icon)} />
                    )}
                </FlexBox>
                <BlueButton onClick={this.handleChooseIcon}>Choose Icon...</BlueButton>

            </PopupMenuPaddedContainer>
        );
    }
}

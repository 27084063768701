import { MenuItem } from "@material-ui/core";
import moment from "moment/moment";
import React, { Component } from "react";
import styled from "styled-components";

import { ACCEPTED_DATE_FORMATS, AssetType, CellChangeStyle, FormatType, HorizontalAlignType } from "../../../../../common/constants";
import { Dropdown } from "../../../../Components/Dropdown";
import { Icon } from "../../../../Components/Icon";
import { ImageOption, ImageOptionList } from "../../../../Components/ImageOptionList";
import { Popup, PopupContent } from "../../../../Components/Popup";
import { ToggleButton, ToggleButtons } from "../../../../Components/ToggleButtons";
import { ToggleSwitch } from "../../../../Components/ToggleSwitch";
import { WithLabel } from "../../../../Components/WithLabel";
import AppController from "../../../../core/AppController";
import { ds } from "../../../../core/models/dataService";
import { formatter } from "../../../../core/utilities/formatter";
import { PropertyPanelContainer, PropertySection, PropertySectionHeader } from "../../../../EditorComponents/PropertyPanel";
import { ShowDialog } from "../../../../react/components/Dialogs/BaseDialog";
import { FlexBox } from "../../../../react/components/LayoutGrid";
import { AddAssetsContainer } from "../../../../react/views/AddAssets/index";
import { _ } from "../../../../vendor";

const IconContainer = styled.div`
    width: 50px;
    height: 50px;
    cursor: pointer;

    > svg {
        width: 100%;
        height: 100%;
    }
`;

export class FormattingDropdown extends Component {
    render() {
        let { format, formatOptions, onChangeFormat, onChangeFormatOptions, allowedFormats } = this.props;

        format ||= FormatType.NUMBER;
        formatOptions ||= formatter.getDefaultFormatOptions();

        return (
            <Popup contained label={format.toTitleCase()} showArrow>
                <PopupContent>
                    <FormattingOptions format={format}
                        formatOptions={formatOptions}
                        onChangeFormat={onChangeFormat}
                        onChangeFormatOptions={onChangeFormatOptions}
                        allowedFormats={allowedFormats}
                    />
                </PopupContent>
            </Popup>
        );
    }
}

export class FormattingOptions extends Component {
    constructor(props) {
        super(props);

        this.state = {
            iconsData: {},
            iconIds: [
                "check-yes",
                "x",
                "thumbs-up",
                "thumbs-down",
                "star",
                "star-half",
                "star-none",
                "full",
                "quarters-three",
                "contrast",
                "quarter",
                "none",
                "arrow-up",
                "arrow-down"
            ]
        };
    }

    componentDidMount() {
        const { allowedFormats = Object.values(FormatType) } = this.props;
        if (allowedFormats.includes(FormatType.ICON)) {
            this.fetchIcons();
        }
    }

    async loadIcon(iconId) {
        const { theme } = this.props;

        const icon = await ds.assets.getAssetById("icon-" + theme.get("iconStyle") + "-" + iconId, "icon");
        const url = icon.get("original");
        const svgData = await fetch(url).then(res => res.text());
        return svgData;
    }

    async fetchIcons() {
        const { iconIds } = this.state;

        await Promise.all(iconIds.map(async iconId => {
            const svgData = await this.loadIcon(iconId);
            this.setState(state => ({
                iconsData: {
                    ...state.iconsData,
                    [iconId]: svgData
                }
            }));
        }));
    }

    handleChangeFormatOptions(formatOptions) {
        const { onChangeFormatOptions, formatOptions: currentFormatOptions } = this.props;
        onChangeFormatOptions(Object.assign({}, currentFormatOptions, formatOptions));
    }

    handleChangeContentValue(contentType, contentValue) {
        const { onChangeContentValue } = this.props;
        onChangeContentValue(contentType, contentValue);
    }

    handleChooseAsset = () => {
        ShowDialog(AddAssetsContainer, {
            assetType: AssetType.ICON,
            workspaceId: AppController.workspaceId,
            callback: ({ content_type, content_value }) => this.handleChangeContentValue(content_type, content_value)
        });
    }

    render() {
        const {
            format,
            formatOptions,
            onChangeFormat,
            allowedFormats = Object.values(FormatType)
        } = this.props;
        const {
            iconsData,
            iconIds
        } = this.state;

        const DecimalOptions = (
            <WithLabel label="Decimals" flex toolTip={
                <>
                    <p>Set the number of decimals that will be display in a number. The numeric value will be rounded to the set number of decimals.</p>
                    <p>Using Auto will display up to two decimal places automatically if the value is not a whole number.</p>
                    <p>Other options will display the set number of decimal places regardless of whether the value is a whole number or not.</p>
                </>
            }>
                <Dropdown value={formatOptions.decimal ?? "auto"}
                    onChange={value => this.handleChangeFormatOptions({ decimal: value })}>
                    <MenuItem value="auto">Auto</MenuItem>
                    <MenuItem value={0}>0</MenuItem>
                    <MenuItem value={1}>0.0</MenuItem>
                    <MenuItem value={2}>0.00</MenuItem>
                    <MenuItem value={3}>0.000</MenuItem>
                    <MenuItem value={4}>0.0000</MenuItem>
                </Dropdown>
            </WithLabel>
        );

        const getTextAlignElement = () => {
            return (
                <PropertySection>
                    <WithLabel label="Text Align" flex>
                        <ToggleButtons
                            value={formatOptions.textAlign}
                            onChange={value => this.handleChangeFormatOptions({ textAlign: value })}
                        >
                            <ToggleButton value={HorizontalAlignType.LEFT}>
                                <Icon>format_align_left</Icon>
                            </ToggleButton>
                            <ToggleButton value={HorizontalAlignType.CENTER}>
                                <Icon>format_align_center</Icon>
                            </ToggleButton>

                            <ToggleButton value={HorizontalAlignType.RIGHT}>
                                <Icon>format_align_right</Icon>
                            </ToggleButton>
                        </ToggleButtons>
                    </WithLabel>
                </PropertySection>
            );
        };

        const getFormatPanel = format => {
            if (format === FormatType.NUMBER) {
                return (
                    <>
                        <PropertySection>
                            {DecimalOptions}
                            <WithLabel label="Thousands Separator" toolTip="Set whether to show the thousand separator when displaying numbers over 999.">
                                <ToggleSwitch
                                    value={formatOptions.separator}
                                    onChange={value => this.handleChangeFormatOptions({ separator: value })}
                                />
                            </WithLabel>
                        </PropertySection>
                        <PropertySection>
                            <WithLabel label="Large Numbers" toolTip="Sets how to display large numbers over 999.">
                                <Dropdown value={formatOptions.abbreviated ?? false}
                                    onChange={value => this.handleChangeFormatOptions({ abbreviated: value })}>
                                    <MenuItem value={false}>{formatOptions.separator ? "100,000" : "100000"}</MenuItem>
                                    <MenuItem value="lower">100k</MenuItem>
                                    <MenuItem value="upper">100K</MenuItem>
                                </Dropdown>
                            </WithLabel>
                            <WithLabel label="Scale Values" toolTip="Sets whether to treat the value as an abbreviated larger value, ie. display 5 as 5,000 or 5,000,000.">
                                <Dropdown value={formatOptions.scale ?? "ones"}
                                    onChange={value => this.handleChangeFormatOptions({ scale: value })}>
                                    <MenuItem value="ones">None</MenuItem>
                                    <MenuItem value="thousands">Thousand (k)</MenuItem>
                                    <MenuItem value="millions">Million (M)</MenuItem>
                                    <MenuItem value="billions">Billion (B)</MenuItem>
                                    <MenuItem value="trillions">Trillion (T)</MenuItem>
                                </Dropdown>
                            </WithLabel>
                        </PropertySection>
                    </>
                );
            }

            if (format === FormatType.PERCENT) {
                return (
                    <PropertySection>
                        {DecimalOptions}
                    </PropertySection>
                );
            }

            if (format === FormatType.CURRENCY) {
                return (
                    <>
                        <PropertySection>
                            <WithLabel label="Currency Symbol" flex>
                                <Dropdown value={formatOptions.currency || "$"}
                                    onChange={value => this.handleChangeFormatOptions({ currency: value })}>
                                    <MenuItem value="$">$</MenuItem>
                                    <MenuItem value="€">Euro (€)</MenuItem>
                                    <MenuItem value="£">British Pound (£)</MenuItem>
                                    <MenuItem value="¥">Yen / Yuan (¥)</MenuItem>
                                    <MenuItem value="₹">Indian Rupee (₹)</MenuItem>
                                    <MenuItem value="₽">Russian Ruble (₽)</MenuItem>
                                    <MenuItem value="kr">Krone (kr)</MenuItem>
                                    <MenuItem value="MX$">Mexican Peso (MX$)</MenuItem>
                                    <MenuItem value="R$">Brazilian Real (R$)</MenuItem>
                                    <MenuItem value="₩">South Korean Won (₩)</MenuItem>
                                    <MenuItem value="₪">Israeli New Shekel (₪)</MenuItem>
                                    <MenuItem value="₫">Vietnamese Dong (₫)</MenuItem>
                                </Dropdown>
                            </WithLabel>
                            {DecimalOptions}
                            <WithLabel label="Thousands Separator" flex>
                                <ToggleSwitch value={formatOptions.separator}
                                    onChange={value => this.handleChangeFormatOptions({ separator: value })} />
                            </WithLabel>
                        </PropertySection>
                        <PropertySection>
                            <WithLabel label="Large Numbers" flex
                                toolTip="Choose whether to abbreviate large numbers over 1,000.">
                                <Dropdown value={formatOptions.abbreviated ?? false}
                                    onChange={value => this.handleChangeFormatOptions({ abbreviated: value })}>
                                    <MenuItem value={false}>{formatOptions.separator ? "100,000" : "100000"}</MenuItem>
                                    <MenuItem value="lower">100k</MenuItem>
                                    <MenuItem value="upper">100K</MenuItem>
                                </Dropdown>
                            </WithLabel>
                            <WithLabel label="Scale Values" toolTip="Sets whether to treat the value as an abbreviated larger value, ie. display 5 as 5,000 or 5,000,000.">
                                <Dropdown value={formatOptions.scale ?? "ones"}
                                    onChange={value => this.handleChangeFormatOptions({ scale: value })}>
                                    <MenuItem value="ones">None</MenuItem>
                                    <MenuItem value="thousands">Thousand (k)</MenuItem>
                                    <MenuItem value="millions">Million (M)</MenuItem>
                                    <MenuItem value="billions">Billion (B)</MenuItem>
                                    <MenuItem value="trillions">Trillion (T)</MenuItem>
                                </Dropdown>
                            </WithLabel>
                        </PropertySection>
                        <PropertySection>
                            <WithLabel label="Accounting Style" flex>
                                <ToggleSwitch value={!!formatOptions.accountingStyle}
                                    onChange={value => this.handleChangeFormatOptions({
                                        accountingStyle: value,
                                        changeStyle: CellChangeStyle.NONE
                                    })} />
                            </WithLabel>
                        </PropertySection>
                        {!formatOptions.accountingStyle && <PropertySection>
                            <WithLabel label="Change In Value" flex>
                                <Dropdown value={formatOptions.changeStyle || "Mixed"}
                                    onChange={value => this.handleChangeFormatOptions({ changeStyle: value })}>
                                    <MenuItem value={CellChangeStyle.NONE}>None</MenuItem>
                                    <MenuItem value={CellChangeStyle.PLUS_MINUS}>+/-</MenuItem>
                                    <MenuItem value={CellChangeStyle.ARROWS}>Up/down arrows</MenuItem>
                                </Dropdown>
                            </WithLabel>
                        </PropertySection>}
                        {formatOptions.accountingStyle && <PropertySection>
                            <WithLabel label="Color Code Change Values" flex>
                                <ToggleSwitch value={formatOptions.changeColor ?? true}
                                    onChange={value => this.handleChangeFormatOptions({ changeColor: value })} />
                            </WithLabel>
                        </PropertySection>}
                    </>
                );
            }

            if (format === FormatType.ICON) {
                return (
                    <PropertySection>
                        <FlexBox>
                            {_.chunk(iconIds, iconIds.length / 2)[0].map(iconId => (
                                <IconContainer
                                    key={iconId}
                                    dangerouslySetInnerHTML={{ __html: iconsData[iconId] ?? "" }}
                                    onClick={() => this.handleChangeContentValue(AssetType.ICON, iconId)}
                                >
                                </IconContainer>
                            ))}
                        </FlexBox>
                        <FlexBox>
                            {_.chunk(iconIds, iconIds.length / 2)[1].map(iconId => (
                                <IconContainer
                                    key={iconId}
                                    dangerouslySetInnerHTML={{ __html: iconsData[iconId] ?? "" }}
                                    onClick={() => this.handleChangeContentValue(AssetType.ICON, iconId)}
                                >
                                </IconContainer>
                            ))}
                        </FlexBox>
                        <ToggleButton onClick={this.handleChooseAsset}>
                            Choose Icon, Logo, or Image...
                        </ToggleButton>
                    </PropertySection>
                );
            }

            if (format === FormatType.NONE) {
                return (
                    <PropertySection>
                    </PropertySection>
                );
            }

            if (format === FormatType.DATE) {
                return (
                    <PropertySection>
                        <WithLabel label="Date Format">
                            <Dropdown value={formatOptions.dateFormat}
                                onChange={value => this.handleChangeFormatOptions({ dateFormat: value })}>
                                {ACCEPTED_DATE_FORMATS.map(dateFormat => <MenuItem value={dateFormat} key={dateFormat}>{moment().format(dateFormat)}</MenuItem>)}
                            </Dropdown>
                        </WithLabel>
                    </PropertySection>
                );
            }
        };

        return (
            <PropertyPanelContainer style={{ minWidth: 270 }}>
                <PropertySection>
                    <PropertySectionHeader label="Format" />
                    <ImageOptionList value={format} onChange={onChangeFormat} gap={20} size={30}>
                        {allowedFormats.map(format => <ImageOption value={format} key={format} label={format} icon={getFormatIcon(format)} />)}
                    </ImageOptionList>
                </PropertySection>
                {getFormatPanel(format)}
                {
                    (format !== FormatType.NONE && format !== FormatType.ICON) && getTextAlignElement()
                }
            </PropertyPanelContainer>
        );
    }
}

function getFormatIcon(format) {
    switch (format) {
        case FormatType.TEXT:
            return "text_fields";
        case FormatType.NUMBER:
            return "pin";
        case FormatType.PERCENT:
            return "percent";
        case FormatType.CURRENCY:
            return "attach_money";
        case FormatType.ICON:
            return "star";
        case FormatType.NONE:
            return "block";
        case FormatType.DATE:
            return "event";
    }
}

import React from "react";
import styled from "styled-components";
import { v4 as uuid } from "uuid";

import { reactMount, reactUnmount } from "js/react/renderReactRoot";
import { _, $ } from "js/vendor";
import * as geom from "js/core/utilities/geom";
import {
    DocumentType,
    ElementTextBlockPositionType,
    TrayElementType,
    TrayType
} from "common/constants";
import perf from "js/core/utilities/perf";
import { delay } from "js/core/utilities/promiseHelper";
import { ELEMENT_TRANSITION_DURATION } from "js/core/utilities/svgHelpers";
import getLogger, { LogGroup } from "js/core/logger";
import { getStaticUrl } from "js/config";

import { CanvasLayouter } from "./baseCanvasLayouter";
import { CanvasElement } from "../elements/base/CanvasElement";

const logger = getLogger(LogGroup.CANVAS_LAYOUTER);

const ErrorStateContainer = styled.div`
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(248, 250, 252);
    flex-flow: column;
    gap: 40px;
`;

const ErrorStateErrorMessageContainer = styled.div`
    display: flex;
    flex-flow: column;
    gap: 0;
    max-width: 60%;
    align-items: center;
    justify-content: center;

    >span:first-child {
        font-size: 20px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 40px;
    }

    >span {
        font-size: 12px;
        font-weight: 400;
        width: 100%;
        text-align: left;
    }
`;

function ErrorState({ err, showErrorMessage }) {
    return (<ErrorStateContainer>
        <img src={getStaticUrl("/images/bai-bot/bai-bot-sad.png")} />
        {showErrorMessage && <ErrorStateErrorMessageContainer>
            <span>UH OH, we had a problem rendering this slide, try reverting the slide to one of the earlier revisions or contact us at support@beautiful.ai</span>
            {err.stack.split("\n").map((line, index) => <span key={index}>{line}</span>)}
        </ErrorStateErrorMessageContainer>}
    </ErrorStateContainer>);
}

export class LayoutNotFitError extends Error {
    constructor(message) {
        super(message);
        this.name = "LayoutNotFitError";
    }
}

class SlideCanvasLayouter extends CanvasLayouter {
    constructor(canvas, template) {
        super(canvas, template);

        this.generationPromiseChain = Promise.resolve();
        this.postRenderCallbacks = [];
        this.postLoadCallbacks = [];

        this.options = {
            editable: true
        };

        this.removedElements = [];
        this.cleanupRemovedElementsTimeout = null;
        this.afterTransitionTimeout = null;

        // Setting up a flag so we always have it available in case we need to change it
        this.lastLayoutFit = null;

        this.isInErrorState = false;

        this.renderKey = uuid();
    }

    get primary() {
        return this.canvasElement.elements.primary;
    }

    get annotations() {
        return this.canvasElement.elements.annotations;
    }

    setLastLayoutFit(isFit = false) {
        this.lastLayoutFit = isFit;
    }

    get callouts() {
        return this.canvasElement.elements.callouts;
    }

    clear() {
        reactUnmount(this.canvas.el);
    }

    /**
     * WARNING: supposed to be called only from within a method that is being called as a
     * part of the generate() loop, e.g. _calcProps, renderChildren(), _build, etc.
     * Callback has to be sync, if you need to run an async code in it, please take care
     * of promise rejections yourself.
     */
    runPostRender(callback) {
        this.postRenderCallbacks.push(callback);
    }

    /**
     * WARNING: supposed to be called only from within a method that is being called as a
     * part of the generate() loop, e.g. _calcProps, renderChildren(), _build, etc.
     * Callback has to be sync, if you need to run an async code in it, please take care
     * of promise rejections yourself.
     */
    runPostLoad(callback) {
        this.postLoadCallbacks.push(callback);
    }

    async reportState(stateUpdates) {
        await this.canvas.canvasController?.reportCanvasState({
            ...stateUpdates,
            renderKey: this.renderKey
        });
    }

    _generate = this._withErrorStateAsync(async (
        model,
        {
            transition = false,
            forceRender = false,
            skipRender = false
        },
        canvasSize
    ) => {
        this.isGenerating = true;
        await this.reportState({ isGenerating: true });

        try {
            this.model = model;

            perf.start("generate");

            // generate a unique ID for this generation pass so we can determine elements that might have been removed on a future pass
            const generationKey = _.uniqueId();

            if (!this.canvasElement) {
                this.canvasElement = new CanvasElement({ id: "root", canvas: this.canvas });
                this.canvasElement.template = this.template;
            }

            perf.start("build");
            this.canvasElement.build(model, generationKey);
            perf.stop("build");

            perf.start("load");
            await this.canvasElement.load();
            // Cleaning up this.postLoadCallbacks before calling the callbacks to
            // avoid endless loops in cases when a callback invokes rendering
            const postLoadCallbacks = this.postLoadCallbacks;
            this.postLoadCallbacks = [];
            postLoadCallbacks.forEach(callback => callback());
            perf.stop("load");

            perf.start("calcProps");
            let canvasProps = this.canvasElement.calcProps(canvasSize);
            canvasProps.bounds = new geom.Rect(0, 0, canvasSize);

            this.isPostCalcProps = true;
            this.canvasElement.postCalcProps();
            this.isPostCalcProps = false;

            // support old elements ref
            this.elements = this.canvasElement.elements;
            perf.stop("calcProps");

            if (skipRender) {
                perf.stop("generate");
                return;
            }

            const layoutFit = this.doesLayoutFit(this.canvasElement);

            // dont get stuck in a layoutNotFit state - allow the user to move from bad state to another bad state
            if (!layoutFit && this.lastLayoutFit === false) {
                forceRender = true;
            }
            this.lastLayoutFit = layoutFit;

            if (layoutFit || forceRender) {
                if (!layoutFit) {
                    logger.warn("Layout does not fit but is being forced to render", { slideId: this.canvas.dataModel.id });
                }

                if (transition) {
                    await this.reportState({ isTransitioning: true });
                }

                this.canvasElement.applyColors();

                perf.start("render");
                this.render(transition, {}, false);
                perf.stop("render");

                await this.reportState({ isGenerating: false, isRendered: true });

                // Cleaning up this.postRenderCallbacks before calling the callbacks to
                // avoid endless loops in cases when a callback invokes rendering
                const postRenderCallbacks = this.postRenderCallbacks;
                this.postRenderCallbacks = [];
                postRenderCallbacks.forEach(callback => callback());
            } else {
                throw new LayoutNotFitError("Layout doesn't fit");
            }

            if (transition) {
                await delay(ELEMENT_TRANSITION_DURATION);
                await this.reportState({ isTransitioning: false });
            }

            perf.stop("generate");
        } catch (err) {
            if (err instanceof LayoutNotFitError) {
                logger.warn(err, "layout not fit", { slideId: this.canvas.dataModel.id, _changeId: this.canvas.dataModel.attributes._changeId });
            } else {
                logger.error(err, "failed to generate slide", { slideId: this.canvas.dataModel.id, _changeId: this.canvas.dataModel.attributes._changeId });
            }
            const stateUpdates = { isGenerating: false };
            if (transition) {
                stateUpdates.isTransitioning = false;
            }
            await this.reportState(stateUpdates);
            throw err;
        } finally {
            this.isGenerating = false;
        }
    });

    async generate(
        model,
        {
            transition = false,
            forceRender = false,
            skipRender = false
        },
        canvasSize
    ) {
        return new Promise((resolve, reject) => {
            // Chaining generation promises to ensure there's only one running at a time
            this.generationPromiseChain = this.generationPromiseChain
                .then(() => this._generate(
                    model,
                    {
                        transition,
                        forceRender,
                        skipRender
                    },
                    canvasSize
                ))
                .then(resolve)
                .catch(reject);
        });
    }

    doesLayoutFit(element) {
        let isFit = element.calculatedProps?.isFit !== false;
        for (let child of Object.values(element.elements)) {
            if (child.isDeleted) {
                continue;
            }

            if (!this.doesLayoutFit(child)) {
                isFit = false;
            }
        }

        return isFit;
    }

    async tryLayout(model) {
        let canvasElement = new CanvasElement({ id: "root", canvas: this.canvas, options: { isTryingLayout: true } });
        canvasElement.template = this.template;

        canvasElement.build(model, 0);

        await canvasElement.load();
        let canvasProps = canvasElement.calcProps(new geom.Size(this.canvas.CANVAS_WIDTH, this.canvas.CANVAS_HEIGHT));

        return canvasProps.isFit;
    }

    reportRemovedElement(element) {
        this.removedElements.push(element);
    }

    refreshRender = this._withErrorState((transition, renderProps) => {
        this.render(transition, renderProps);
        const postRenderCallbacks = this.postRenderCallbacks;
        this.postRenderCallbacks = [];
        postRenderCallbacks.forEach(callback => callback());
    });

    render(transition, renderProps, reportState = true) {
        clearTimeout(this.afterTransitionTimeout);

        // render the React DOM from the element tree starting with the canvasElement
        const renderedCanvasElement = this.canvasElement.renderElement(transition, renderProps);

        reactMount(renderedCanvasElement, this.canvas.el);

        this.renderKey = uuid();

        if (reportState) {
            this.reportState({ isRendered: true, isTransitioning: !!transition });
        }

        // If there are removed elements and transition then set up a timer to
        // refresh render in order to delete the removed elements from dom after transition
        // If there's no transition then the removed elements were not rendered
        if (transition) {
            const shouldRefresh = this.removedElements.length > 0;
            this.afterTransitionTimeout = setTimeout(
                () => {
                    if (shouldRefresh) {
                        this.refreshRender(false, renderProps, false);
                    }
                    if (reportState) {
                        this.reportState({ isTransitioning: false });
                    }
                },
                ELEMENT_TRANSITION_DURATION
            );
        }
        this.removedElements = [];

        this.isLayedOut = true;

        this.canvas.onRendered();
    }

    renderErrorState(err) {
        clearTimeout(this.afterTransitionTimeout);

        const showErrorMessage = this.canvas.errorStateOptions.showErrorMessage;
        reactMount(<ErrorState err={err} showErrorMessage={showErrorMessage} />, this.canvas.el);
    }

    recalcElement(element) {
        element.recalcProps();

        this.isPostCalcProps = true;
        element.postCalcProps();
        this.isPostCalcProps = false;
    }

    renderElement = this._withErrorState((element, transition, requireFit = false) => {
        perf.start("recalcProps");
        element.recalcProps();

        this.isPostCalcProps = true;
        element.postCalcProps();
        this.isPostCalcProps = false;

        const isFit = this.doesLayoutFit(element);
        if (!isFit && requireFit) {
            throw new LayoutNotFitError("Layout not fit");
        }
        perf.stop("recalcProps");

        this.canvasElement.applyColors();

        perf.start("rerender");
        this.refreshRender(transition);
        perf.stop("rerender");
    });

    renderElements = this._withErrorState((elements, transition) => {
        elements.forEach(element => {
            element.recalcProps();
        });

        this.isPostCalcProps = true;
        elements.forEach(element => {
            element.postCalcProps();
        });
        this.isPostCalcProps = false;

        this.canvasElement.applyColors();

        this.refreshRender(transition);
    });

    _withErrorState(callToWrap) {
        return (...args) => {
            try {
                const response = callToWrap(...args);
                this.isInErrorState = false;
                this.reportState({ isInErrorState: false });
                return response;
            } catch (err) {
                if (!(err instanceof LayoutNotFitError)) {
                    this.isInErrorState = true;
                    this.reportState({ isInErrorState: true });
                    this.renderErrorState(err);
                }
                throw err;
            }
        };
    }

    _withErrorStateAsync(callToWrap) {
        return async (...args) => {
            try {
                const response = await callToWrap(...args);
                await this.reportState({ isInErrorState: false });
                this.isInErrorState = false;
                return response;
            } catch (err) {
                if (!(err instanceof LayoutNotFitError)) {
                    this.isInErrorState = true;
                    await this.reportState({ isInErrorState: true });
                    this.renderErrorState(err);
                }
                throw err;
            }
        };
    }

    getElementLayoutOptions(type) {
        let layoutOptions = [];
        let layoutProps = this.getLayoutProps();

        let availableTrayLayouts = this.template.availableTrayLayouts;

        if (type === TrayElementType.TEXT) {
            layoutOptions.push({
                type: "text-header",
                label: "Slide Header",
                enabled: this.template.allowHeader,
                selected: layoutProps.showHeader === true,
                props: {
                    showHeader: true
                }
            });
        }
        layoutOptions.push({
            type: "tray-left",
            label: "Left Tray",
            enabled: availableTrayLayouts.contains(TrayType.LEFT_TRAY) && (layoutProps.trayLayout === TrayType.NONE || layoutProps.trayElementType === type),
            selected: layoutProps.trayLayout === TrayType.LEFT_TRAY,
            props: {
                trayLayout: TrayType.LEFT_TRAY
            }
        });
        layoutOptions.push({
            type: "tray-right",
            label: "Right Tray",
            enabled: availableTrayLayouts.contains(TrayType.RIGHT_TRAY) && (layoutProps.trayLayout === TrayType.NONE || layoutProps.trayElementType === type),
            selected: layoutProps.trayLayout === TrayType.RIGHT_TRAY,
            props: {
                trayLayout: TrayType.RIGHT_TRAY
            }
        });
        layoutOptions.push({
            type: "tray-top",
            label: "Top Tray",
            enabled: availableTrayLayouts.contains(TrayType.TOP_TRAY) && (layoutProps.trayLayout === TrayType.NONE || layoutProps.trayElementType === type),
            selected: layoutProps.trayLayout === TrayType.TOP_TRAY,
            props: {
                trayLayout: TrayType.TOP_TRAY
            }
        });
        layoutOptions.push({
            type: "tray-bottom",
            label: "Bottom Tray",
            enabled: availableTrayLayouts.contains(TrayType.BOTTOM_TRAY) && (layoutProps.trayLayout === TrayType.NONE || layoutProps.trayElementType === type),
            selected: layoutProps.trayLayout === TrayType.BOTTOM_TRAY,
            props: {
                trayLayout: TrayType.BOTTOM_TRAY
            }
        });
        layoutOptions.push({
            type: "background",
            label: "Background",
            enabled: availableTrayLayouts.contains(TrayType.BACKGROUND) && (layoutProps.trayLayout === TrayType.NONE || layoutProps.trayElementType === type),
            selected: layoutProps.trayLayout === TrayType.BACKGROUND,
            props: {
                trayLayout: TrayType.BACKGROUND
            }
        });
        layoutOptions.push({
            type: "inline-left",
            label: "Left Inline",
            enabled: availableTrayLayouts.contains(TrayType.LEFT_INLINE) && (layoutProps.trayLayout === TrayType.NONE || layoutProps.trayElementType === type),
            selected: layoutProps.trayLayout === TrayType.LEFT_INLINE,
            props: {
                trayLayout: TrayType.LEFT_INLINE
            }
        });
        layoutOptions.push({
            type: "inline-right",
            label: "Right Inline",
            enabled: availableTrayLayouts.contains(TrayType.RIGHT_INLINE) && (layoutProps.trayLayout === TrayType.NONE || layoutProps.trayElementType === type),
            selected: layoutProps.trayLayout === TrayType.RIGHT_INLINE,
            props: {
                trayLayout: TrayType.RIGHT_INLINE
            }
        });
        if (type === TrayElementType.TEXT) {
            layoutOptions.push({
                type: "text-tray-bottom",
                label: "Bottom Tray",
                enabled: this.template.allowElementTextTray,
                selected: layoutProps.elementTextBlockPosition === ElementTextBlockPositionType.TRAY,
                props: {
                    elementTextBlockPosition: ElementTextBlockPositionType.TRAY
                }
            });
            layoutOptions.push({
                type: "text-inline-bottom",
                label: "Bottom Text",
                enabled: this.template.allowElementTextInline,
                selected: layoutProps.elementTextBlockPosition === ElementTextBlockPositionType.INLINE,
                props: {
                    elementTextBlockPosition: ElementTextBlockPositionType.INLINE
                }
            });
            layoutOptions.push({
                type: "text-attribution",
                label: "Footnote/Attribution",
                enabled: this.template.allowElementAttribution,
                selected: layoutProps.showElementAttribution === true,
                props: {
                    showElementAttribution: true
                }
            });
        }

        return layoutOptions;
    }
}

export { SlideCanvasLayouter };


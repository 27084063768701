import React, { Component, Fragment } from "reactn";
import styled from "styled-components";

import { Close as CloseIcon } from "@material-ui/icons";

import * as geom from "../../../../../core/utilities/geom";
import { AnchorType } from "../../../../../core/utilities/geom";
import { getStaticUrl } from "../../../../../config";
import { stopPropagation } from "../../../../../core/utilities/stopPropagation";

import { BoundsBox, SelectionBox } from "./SelectionBox";
import DeleteElementButton from "../Components/DeleteElementButton";
import { DragType } from "./AuthoringLayer";
import { WidgetButtonPosition } from "../../../../../Components/WidgetButton";

export const ResizeHandle = styled.div.attrs(({ cursor }) => ({
    style: {
        cursor
    }
}))`
    position: absolute;
    border: solid 1px #6c6c6c;
    border-radius: 2px;
    background: white;
    width: 8px;
    height: 8px;
    pointer-events: auto;
`;

export const RotateHandle = styled.div`
    position: absolute;
    width: 10px;
    height: 10px;
    pointer-events: auto;
    cursor: url(${getStaticUrl("/images/cursors/rotate.svg")}) 5 5, auto
`;

export class SelectionUI extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showContextMenu: props.showContextMenu ?? false,
            contextMenuPosition: new geom.Point(0, 0),
        };
    }

    handleResizeHandleMouseDown = (event, anchor) => {
        // We don't want this mouseDown to be also
        // handled by the parent
        stopPropagation()(event);

        if (event.button == 0) {
            const { onResizeStarted } = this.props;
            onResizeStarted(event, anchor);
        }
    }

    handleRotateHandleMouseDown = (event, anchor) => {
        // We don't want this mouseDown to be also
        // handled by the parent
        stopPropagation()(event);

        if (event.button == 0) {
            this.props.onRotateStarted(event, anchor);
        }
    }

    handleSelectionBoxContextMenu = event => {
        event.preventDefault();
        stopPropagation()(event);

        const contextMenuPosition = new geom.Point(event.pageX, event.pageY);
        this.setState(({ showContextMenu }) => ({
            showContextMenu: !showContextMenu,
            contextMenuPosition
        }));
    }

    render() {
        const {
            bounds,
            showSelectionBox,
            isLocked,
            resizeDirections,
            selection,
            canvasScale,
            removeSelectedElements,
            dragType
        } = this.props;

        const scaledBounds = bounds.multiply(canvasScale);

        let rotate = 0;
        if (selection.length == 1 && selection[0].model.rotation > 0) {
            rotate = selection[0].model.rotation;
        }

        const authoringCanvas = selection[0].authoringCanvas;

        return (
            <SelectionBox
                canvasScale={canvasScale}
                className="authoring-selection-box"
                bounds={bounds}
                showBorder={false}
                onContextMenu={this.handleSelectionBoxContextMenu}
                style={{ pointerEvents: "none" }}
            >
                {this.props.children}

                {showSelectionBox &&
                    <BoundsBox
                        bounds={scaledBounds.zeroOffset()}
                        showOutline={showSelectionBox}
                        style={{ pointerEvents: "none" }}
                        rotate={rotate}
                    >
                        {/*<Fragment>*/}
                        {/*    <RotateHandle*/}
                        {/*        style={{ left: -15, top: -15 }}*/}
                        {/*        onMouseDown={event => this.handleRotateHandleMouseDown(event, AnchorType.TOP_LEFT)}*/}
                        {/*    />*/}
                        {/*    <RotateHandle*/}
                        {/*        style={{ left: scaledBounds.width + 5, top: -15 }}*/}
                        {/*        onMouseDown={event => this.handleRotateHandleMouseDown(event, AnchorType.TOP_RIGHT)}*/}
                        {/*    />*/}
                        {/*    <RotateHandle*/}
                        {/*        style={{ left: scaledBounds.width + 5, top: scaledBounds.height + 5 }}*/}
                        {/*        onMouseDown={event => this.handleRotateHandleMouseDown(event, AnchorType.BOTTOM_RIGHT)}*/}
                        {/*    />*/}
                        {/*    <RotateHandle*/}
                        {/*        style={{ left: -15, top: scaledBounds.height + 5 }}*/}
                        {/*        onMouseDown={event => this.handleRotateHandleMouseDown(event, AnchorType.BOTTOM_LEFT)}*/}
                        {/*    />*/}
                        {/*</Fragment>*/}
                        {selection[0].childElement?.isCallout && (dragType == null) && (
                            <DeleteElementButton position={WidgetButtonPosition.CORNER} onClick={e => {
                                e.stopPropagation();
                                removeSelectedElements();
                            }} />
                        )}

                        {!isLocked && (dragType == null || dragType == DragType.RESIZE) && resizeDirections.left && resizeDirections.right && resizeDirections.top && resizeDirections.bottom &&
                            <Fragment>
                                <ResizeHandle
                                    style={{ left: -4, top: -4 }}
                                    cursor="nwse-resize"
                                    onMouseDown={event => this.handleResizeHandleMouseDown(event, AnchorType.TOP_LEFT)}
                                />
                                <ResizeHandle
                                    style={{ left: scaledBounds.width - 4, top: -4 }}
                                    cursor="nesw-resize"
                                    onMouseDown={event => this.handleResizeHandleMouseDown(event, AnchorType.TOP_RIGHT)}
                                />
                                <ResizeHandle
                                    style={{ left: scaledBounds.width - 4, top: scaledBounds.height - 4 }}
                                    cursor="nwse-resize"
                                    onMouseDown={event => this.handleResizeHandleMouseDown(event, AnchorType.BOTTOM_RIGHT)}
                                />
                                <ResizeHandle
                                    style={{ left: -4, top: scaledBounds.height - 4 }}
                                    cursor="nesw-resize"
                                    onMouseDown={event => this.handleResizeHandleMouseDown(event, AnchorType.BOTTOM_LEFT)}
                                />
                            </Fragment>
                        }
                        {!isLocked && (dragType == null || dragType == DragType.RESIZE) && resizeDirections.left &&
                        <ResizeHandle
                            style={{ left: -4, top: scaledBounds.height / 2 - 4 }}
                            cursor="ew-resize"
                            onMouseDown={event => this.handleResizeHandleMouseDown(event, AnchorType.LEFT)}
                        />
                        }
                        {!isLocked && (dragType == null || dragType == DragType.RESIZE) && resizeDirections.right &&
                        <ResizeHandle
                            style={{ left: scaledBounds.width - 4, top: scaledBounds.height / 2 - 4 }}
                            cursor="ew-resize"
                            onMouseDown={event => this.handleResizeHandleMouseDown(event, AnchorType.RIGHT)}
                        />
                        }
                        {!isLocked && (dragType == null || dragType == DragType.RESIZE) && resizeDirections.top &&
                        <ResizeHandle
                            style={{ left: scaledBounds.width / 2 - 4, top: -4 }}
                            cursor="ns-resize"
                            onMouseDown={event => this.handleResizeHandleMouseDown(event, AnchorType.TOP)}
                        />
                        }
                        {!isLocked && (dragType == null || dragType == DragType.RESIZE) && resizeDirections.bottom &&
                        <ResizeHandle
                            style={{ left: scaledBounds.width / 2 - 4, top: scaledBounds.height - 4 }}
                            cursor="ns-resize"
                            onMouseDown={event => this.handleResizeHandleMouseDown(event, AnchorType.BOTTOM)}
                        />
                        }
                    </BoundsBox>
                }

            </SelectionBox>
        );
    }
}

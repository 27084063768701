import React, { Component } from "react";

import { Dropdown } from "../../../../../Components/Dropdown";
import { Icon } from "../../../../../Components/Icon";
import { IconButton } from "../../../../../Components/IconButton";
import { MenuItem } from "../../../../../Components/Menu";
import { Popup, PopupContent } from "../../../../../Components/Popup";
import { ToolTip } from "../../../../../Components/ToolTip";
import { WithLabel } from "../../../../../Components/WithLabel";
import { PropertyPanelHeader } from "../../../../../editor/ElementEditor/PropertyPanelHeader";
import { SlideBackgroundColorPicker } from "../../../../../editor/PresentationEditor/Components/SlideBackgroundColorPicker";
import { NoFitWarning } from "../../../../../editor/PresentationEditor/SelectionLayer";
import { HorizontalPropertyList, PropertyPanelContainer, PropertySection } from "../../../../../EditorComponents/PropertyPanel";
import { FlexSpacer } from "../../../../../react/components/Gap";
import { InfographicElementTypes } from "../../../elements/elements/Dashboard/InfographicManager";
import { LayoutEngineType } from "../../../elements/elements/LayoutContainers/GridLayoutContainer";
import { DefaultOverlayContainer } from "../../ElementDefaultOverlays/Components/DefaultOverlayContainer";
import { CollectionItemElementSelection } from "../../ElementSelections/CollectionItemElementSelection";
import { ColorPicker } from "../../EditorComponents/ColorPickers/ColorPicker";
import { tinycolor } from "js/vendor";

export class LayoutContainerItemPropertyPanel extends Component {
    renderHeaderControls(props) {
        return null;
    }

    renderSwitcher() {
        return null;
    }

    getConfigureLabel() {
        return "Configure Grid";
    }

    render() {
        const { element } = this.props;
        const canvas = element.canvas;

        let containerElement = element.findClosestOfType("GridLayoutContainer") ?? element.findClosestOfType("CollectionElement");
        let cellElement, containerItemElement;

        if (element.isInstanceOf("LayoutContainerItem")) {
            containerItemElement = element;
            cellElement = element.childElement;
        } else {
            containerItemElement = element.parentElement;
            cellElement = element;
        }

        const layoutEngineType = containerElement.layoutEngineType;
        const isSingleCellLayout = layoutEngineType === LayoutEngineType.SINGLE_CELL;

        // const itemName = cellElement.name + " " + (cellElement.itemIndex + 1);
        const itemName = "CELL " + (cellElement.itemIndex + 1);

        const ContainerPropertyPanel = containerElement.getElementPropertyPanel();
        const ItemPropertyPanel = cellElement.getElementPropertyPanel();

        const isPlaceholder = element.type == "LayoutPlaceholder";

        let imageBackgroundColor;
        if (cellElement.content?.hasSolidBackground) {
            imageBackgroundColor = tinycolor(cellElement.content.asset.get("imageBackgroundColor")).toRgbString();
        }
        let hasImageWithAlpha = cellElement.content?.hasAlpha;

        const showTitle = containerItemElement.model.showTitle ?? false;
        const allowBackgroundColor = showTitle || (element.allowBackgroundColor ?? true);

        const isPrimaryElement = containerElement === canvas.getPrimaryElement();

        const allowCellColor = containerItemElement.componentType != InfographicElementTypes.BLANK;
        const allowTitleCaption = !isPlaceholder && containerItemElement.componentType != InfographicElementTypes.BLANK;

        return (
            <PropertyPanelContainer>
                {!isSingleCellLayout &&
                    <PropertyPanelHeader>
                        {containerElement.name}
                        <FlexSpacer />
                        <Popup label="Edit Grid" icon="grid_view" showArrow>
                            <PopupContent>
                                <ContainerPropertyPanel {...this.props} isPopupMenu element={containerElement} />
                            </PopupContent>
                        </Popup>
                    </PropertyPanelHeader>
                }
                {isPrimaryElement &&
                    <PropertySection>
                        <WithLabel label="Slide Background">
                            <SlideBackgroundColorPicker {...this.props} />
                        </WithLabel>
                    </PropertySection>
                }
                {ItemPropertyPanel && (
                    <>
                        {!isSingleCellLayout &&
                            <PropertyPanelHeader isChildElement>
                                {itemName}
                                <FlexSpacer />
                                {this.renderSwitcher()}
                            </PropertyPanelHeader>
                        }
                        {(allowCellColor || allowTitleCaption) && (
                            <PropertySection>
                                <HorizontalPropertyList>
                                    {allowCellColor &&
                                        <WithLabel label="Color" leftr tight>
                                            {allowBackgroundColor &&
                                                <ColorPicker value={containerItemElement.model.cellColor} canvas={canvas}
                                                    allowColorOnColor showNone showBackgroundColors showDefault
                                                    showWhite={hasImageWithAlpha}
                                                    defaultColor={containerElement.model.cellColor}
                                                    imageBackgroundColor={imageBackgroundColor}
                                                    onChange={color => {
                                                        if (color == "match-image") {
                                                            color = tinycolor(cellElement.content.asset.get("imageBackgroundColor")).toRgbString();
                                                        }
                                                        containerItemElement.updateModel({ cellColor: color });
                                                    }}
                                                />
                                            }
                                            {!allowBackgroundColor &&
                                                <IconButton icon="photo" disabled tooltip="Background color is not available when cell contains a full-bleed image." />
                                            }
                                        </WithLabel>
                                    }
                                    {allowTitleCaption &&
                                        <WithLabel label="Title/Caption" left tight>
                                            <Dropdown value={showTitle}
                                                onChange={value => containerItemElement.updateModel({ showTitle: value })}>
                                                <MenuItem value={false}>None</MenuItem>
                                                <MenuItem value="above">Title</MenuItem>
                                                <MenuItem value="below">Caption</MenuItem>
                                            </Dropdown>
                                        </WithLabel>
                                    }
                                </HorizontalPropertyList>
                            </PropertySection>
                        )}
                        <ItemPropertyPanel {...this.props} element={cellElement} />
                    </>
                )}
            </PropertyPanelContainer>
        );
    }
}

export class LayoutContainerItemSelection extends CollectionItemElementSelection {
    getDropTargets(element) {
        if (element.parentElement.isInstanceOf("CollectionElement")) {
            return element.parentElement.itemElements.filter(el => el !== element);
        }

        return [];
    }
}

export class LayoutContainerItemNoFitDefaultOverlay extends Component {
    render() {
        const { element } = this.props;

        return (
            <NoFitWarning>
                <div className="backdrop" />
                <ToolTip
                    title={`We can't fit a ${element.childElement.name} element in a cell this small. Please resize the cell or switch to another type of element.`}
                >
                    <Icon white fill>warning</Icon>
                </ToolTip>
            </NoFitWarning>
        );
    }
}

export class LayoutContainerItemSelectionOverlay extends Component {
    render() {
        return (
            <DefaultOverlayContainer
                background={false}
                outline={true}
            />
        );
    }
}

import React, { Component } from "react";
import { PropertyPanelContainer, PropertySection } from "../../../../EditorComponents/PropertyPanel";
import { FormattingDropdown, FormattingOptions } from "../EditorComponents/FormattingOptions";
import { WithLabel } from "../../../../Components/WithLabel";
import { NumericStepper } from "../../../../Components/NumericStepper";
import { Dropdown } from "../../../../Components/Dropdown";
import { MenuItem } from "@material-ui/core";
import { ToggleSwitch } from "../../../../Components/ToggleSwitch";
import { FormatType } from "../../../../../common/constants";
import { CollectionColorPicker } from "../EditorComponents/ColorPickers/CollectionColorPicker";
import { IconPicker, IconPickerPopup } from "../../../../Components/IconPicker";
import { ControlBar } from "../ElementControlBars/Components/ControlBar";
import { ItemColorPicker } from "../EditorComponents/ColorPickers/ItemColorPicker";
import { Slider } from "../../../../Components/Slider";
import { Gap10, Gap20, Gap5 } from "../../../../react/components/Gap";
import { IconButton } from "../../../../Components/IconButton";
import { ToolTip } from "../../../../Components/ToolTip";
import { EmphasizeToggle } from "../EditorComponents/EmphasizeToggle";
import { themeColors } from "../../../../react/sharedStyles";

function CompareItemPropertyPanel({ element }) {
    return (
        <>
            <PropertySection>
                <WithLabel label="Item Colors">
                    <CollectionColorPicker element={element} />
                </WithLabel>
            </PropertySection>
            <PropertySection>
                <WithLabel label="Show Values">
                    <ToggleSwitch value={element.format != FormatType.NONE}
                        onChange={value => element.updateModel({ format: value ? FormatType.PERCENT : FormatType.NONE })}
                    />
                </WithLabel>
                {element.format != FormatType.NONE &&
                    <WithLabel label="Value Format">
                        <FormattingDropdown
                            format={element.format}
                            formatOptions={element.formatOptions}
                            onChangeFormat={format => element.updateModel({ format })}
                            onChangeFormatOptions={formatOptions => element.updateModel({ formatOptions })}
                            allowedFormats={[FormatType.PERCENT, FormatType.NUMBER, FormatType.CURRENCY]}
                        />
                    </WithLabel>
                }
            </PropertySection>
        </>
    );
}

function CompareItemAxisRange({ element }) {
    if (element.format == FormatType.NONE) {
        return null;
    }

    return (
        <PropertySection>
            <WithLabel label="Range"
                toolTip={
                    <>
                        <p>Sets the min and max for the values in the chart.</p>
                        <p>You can edit these values if you want more control of the relative size of each item or if you want to increase the range that the chart represents without changing values.</p>
                        <p>Clicking <span style={{ borderRadius: "50%", background: themeColors.ui_blue, color: "white", fontSize: 16, marginLeft: 4, marginRight: 4, textAlign: "center", verticalAlign: "middle" }} className="material-symbols-outlined">arrow_range</span> will set the min to 0
                            and the max to the highest value in the chart.</p>
                    </>
                }>
                <WithLabel label="Min" tight below center small>
                    <NumericStepper value={element.minValue}
                        onChange={minValue => element.updateModel({ minValue })}
                        min={0} max={9999999999}
                    />
                </WithLabel>
                <WithLabel label="Max" tight below center small>
                    <NumericStepper value={element.maxValue}
                        onChange={maxValue => element.updateModel({ maxValue })}
                        min={1} max={9999999999}
                    />
                </WithLabel>
                <Gap5 />
                <WithLabel label="AutoFit" tight below center small>
                    <ToolTip title="AutoFit Min/Max">
                        <IconButton icon="arrow_range" onClick={() => element.autoFitRange()} />
                    </ToolTip>
                </WithLabel>
            </WithLabel>
        </PropertySection>
    );
}

export class CompareRadialBarPropertyPanel extends Component {
    render() {
        const { element } = this.props;

        return (
            <PropertyPanelContainer>
                <CompareItemPropertyPanel element={element} />
                <CompareItemAxisRange element={element} />
            </PropertyPanelContainer>
        );
    }
}

export class CompareHorizontalBarPropertyPanel extends Component {
    render() {
        const { element } = this.props;

        return (
            <PropertyPanelContainer>
                <CompareItemPropertyPanel element={element} />
                <CompareItemAxisRange element={element} />
            </PropertyPanelContainer>
        );
    }
}

export class CompareVerticalBarPropertyPanel extends Component {
    render() {
        const { element } = this.props;

        return (
            <PropertyPanelContainer>
                <CompareItemPropertyPanel element={element} />
                <CompareItemAxisRange element={element} />
            </PropertyPanelContainer>
        );
    }
}

export class CompareBubblePropertyPanel extends Component {
    render() {
        const { element } = this.props;

        return (
            <PropertyPanelContainer>
                <CompareItemPropertyPanel element={element} />
                <PropertySection>
                    <WithLabel label="Align Circles By">
                        <Dropdown value={element.circleAlign}
                            onChange={value => element.updateModel({ circleAlign: value })}
                        >
                            <MenuItem value="top">Top</MenuItem>
                            <MenuItem value="middle">Middle</MenuItem>
                            <MenuItem value="bottom">Bottom</MenuItem>
                        </Dropdown>
                    </WithLabel>
                    <WithLabel label="Unlock Size and Value"
                        toolTip="Controls whether the size of the chart item is controlled directly by it's value. Turning on this setting can be useful if you wish to have more control over the relative sizes of the items in the chart - especially when the range of values is large and results in some very small sizes.">
                        <ToggleSwitch value={!element.useValueForSize}
                            onChange={value => element.updateModel({ useValueForSize: !value })}
                        />
                    </WithLabel>
                </PropertySection>
                {element.useValueForSize &&
                    <CompareItemAxisRange element={element} />
                }
            </PropertyPanelContainer>
        );
    }
}

const defaultIcons = ["figure-woman", "figure-man", "torso-female", "torso-male", "baby", "pregnant", "stroller", "people", "laborer", "business-man", "business-woman", "female", "male", "star", "full"];

export class CompareVerticalPictographPropertyPanel extends Component {
    render() {
        const { element } = this.props;

        return (
            <PropertyPanelContainer>
                <PropertySection>
                    <WithLabel above left label="Icon" fillWidth>
                        <IconPicker icons={defaultIcons}
                            canChooseIcon
                            onChange={iconId => {
                                for (let child of element.itemElements) {
                                    child.model.iconId = iconId;
                                }
                                element.saveModel(false);
                            }}
                        />
                    </WithLabel>
                </PropertySection>
                <CompareItemPropertyPanel element={element} />
                {element.format != FormatType.NONE &&
                    <PropertySection>
                        <WithLabel label="Value Position">
                            <Dropdown value={element.model.valuePosition ?? "top"}
                                onChange={value => element.updateModel({ valuePosition: value })}
                            >
                                <MenuItem value="top">Top</MenuItem>
                                <MenuItem value="bottom">Bottom</MenuItem>
                            </Dropdown>
                        </WithLabel>
                    </PropertySection>
                }
                <CompareItemAxisRange element={element} />

            </PropertyPanelContainer>
        );
    }
}

export class CompareItemControlBar extends Component {
    render() {
        const { element } = this.props;
        const collection = element.parentElement;

        return (
            <ControlBar>
                {element.type.equalsAnyOf("CompareBubbleItem", "CompareHorizontalBarItem", "CompareVerticalBarItem") &&
                    <EmphasizeToggle element={element} />
                }
                <ItemColorPicker element={element} />
                <Slider value={element.model.value}
                    onChange={value => element.refreshModel({ value })}
                    onCommit={() => element.saveModel()}
                    min={collection.minValue} max={collection.maxValue}
                />
            </ControlBar>
        );
    }
}

export class ComparePictographItemControlBar extends Component {
    render() {
        const { element } = this.props;
        const collection = element.parentElement;
        return (
            <ControlBar>
                <ItemColorPicker element={element} />
                <Slider value={element.model.value}
                    onChange={value => element.updateModel({ value })}
                    onCommit={() => element.saveModel()}
                    min={collection.minValue} max={collection.maxValue}
                />
                <IconPickerPopup value={element.model.iconId}
                    width={430}
                    icons={defaultIcons}
                    canChooseIcon
                    onChange={iconId => element.updateModel({ iconId })}
                />
            </ControlBar>
        );
    }
}


import React from "react";
import styled from "styled-components";

import * as geom from "../../../../core/utilities/geom";
import BadFitDialog from "../../../../react/components/Dialogs/BadFitDialog";
import { ShowDialog } from "../../../../react/components/Dialogs/BaseDialog";
import setCursor from "../../../../core/utilities/cursor";
import { Shape } from "../../../../core/utilities/shapes";

import ElementSelectionBox from "./Components/ElementSelectionBox";
import DeleteElementButton from "./Components/DeleteElementButton";
import { SVGPathSelection } from "./BaseElementSelection";

const DragHandle = styled.div.attrs(({ left, top }) => ({
    style: {
        left: `${left}px`,
        top: `${top}px`
    }
}))`
    position: absolute;
    border: solid 1px #6c6c6c;
    border-radius: 2px;
    background: white;
    width: 8px;
    height: 8px;
    transform: translate(-50%, -50%);
    pointer-events: auto;
    cursor: move;
`;

export class RadialBarItemSelection extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isDragging: false
        };

        this.isHandlingDragEvent = false;
    }

    componentWillUnmount() {
        this.handleEndDrag(false);
    }

    handleDeleteElement = () => {
        const { element, selectedElements, selectionLayerController } = this.props;

        const currentSelection = [...selectedElements];

        element.parentElement.deleteItem(element.id);
        element.canvas.updateCanvasModel(true)
            .catch(() => {
                selectionLayerController.setSelectedElements(currentSelection);

                ShowDialog(BadFitDialog, {
                    title: "Sorry, we aren't able to delete the item",
                });
            });
    }

    handleStartDrag = () => {
        this.setState({ isDragging: true });

        setCursor("-webkit-grabbing");

        window.addEventListener("mouseup", this.handleEndDrag);
        window.addEventListener("mousemove", this.handleDrag);
    }

    handleDrag = event => {
        if (this.isHandlingDragEvent) {
            return;
        }

        const { element, canvasController } = this.props;

        const radialBarChartElement = element.parentElement;

        const mousePoint = new geom.Point(event.pageX, event.pageY).minus(canvasController.canvasScreenBounds.position);
        const centerPoint = radialBarChartElement.canvasBounds.spaceScale(element.canvas.getScale()).getPoint("center");

        let angle;
        if (radialBarChartElement.startPosition === "top") {
            angle = (centerPoint.angleToPoint(mousePoint) + 90) % 360;
        } else {
            angle = (centerPoint.angleToPoint(mousePoint) - 90) % 360;
        }

        let percent = angle / 270;
        if (radialBarChartElement.startPosition === "top") {
            if (angle > 270 && angle < 340) {
                percent = 1;
            } else if (angle >= 340 && angle < 360) {
                percent = 0;
            }
        } else {
            if (angle < -20) {
                percent = 1;
            } else if (angle < 0) {
                percent = 0;
            }
        }

        element.model.value = Math.clamp(Math.round(percent * radialBarChartElement.maxValue), 0, radialBarChartElement.maxValue);

        element.canvas.refreshCanvas()
            .then(() => {
                this.isHandlingDragEvent = false;
            });
    }

    handleEndDrag = (save = true) => {
        const { element } = this.props;

        window.removeEventListener("mouseup", this.handleEndDrag);
        window.removeEventListener("mousemove", this.handleDrag);

        setCursor("default");

        element.canvas.saveCanvasModel();

        this.setState({ isDragging: false });
    }

    render() {
        const { element } = this.props;

        const canDelete = element.parentElement.deleteItem && element.canDelete && element.parentElement.itemCollection.length > element.parentElement.minItemCount;

        const canvasScale = element.canvas.getScale();

        const center = new geom.Point(element.radius, element.radius).multiply(canvasScale);
        const selectionPath = Shape.drawArc2((element.radius + element.barWidth / 2 + 5) * canvasScale, (element.radius - element.barWidth / 2 - 5) * canvasScale, element.startAngle, element.endAngle, center);

        const deleteButtonPosition = element.parentElement.startPosition === "top"
            ? new geom.Point(element.radius - element.label.bounds.width - 10, -10).multiply(canvasScale)
            : new geom.Point(element.radius + element.label.bounds.width, element.radius * 2 - element.label.bounds.height - 5).multiply(canvasScale);

        const arcEndPoint = geom.Point.PointFromAngle(element.radius * canvasScale, element.endAngle, new geom.Point(element.radius, element.radius).multiply(canvasScale));

        return (
            <ElementSelectionBox>
                <SVGPathSelection>
                    <path d={selectionPath.toPathData()} />
                </SVGPathSelection>
                {canDelete && <DeleteElementButton onClick={this.handleDeleteElement} left={deleteButtonPosition.x} top={deleteButtonPosition.y} />}
                <DragHandle left={arcEndPoint.x} top={arcEndPoint.y} onMouseDown={this.handleStartDrag} />
            </ElementSelectionBox>
        );
    }
}

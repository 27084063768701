import React, { Component } from "react";

import { WithLabel } from "../../../../Components/WithLabel";

import { PropertyPanelContainer, PropertySection } from "../../../../EditorComponents/PropertyPanel";
import { CollectionColorPicker } from "../EditorComponents/ColorPickers/CollectionColorPicker";
import { DecorationStylePicker } from "../EditorComponents/ColorPickers/DecorationStylePicker";
import { ControlBar } from "../ElementControlBars/Components/ControlBar";
import { MediaPopup } from "../EditorComponents/MediaPopup";
import { ToggleButton, ToggleButtons } from "../../../../Components/ToggleButtons";

export class TeamPropertyPanel extends Component {
    render() {
        const { element } = this.props;

        return (
            <PropertyPanelContainer>
                <PropertySection>
                    <WithLabel label="Item Colors">
                        <CollectionColorPicker element={element} />
                    </WithLabel>
                    {/*<WithLabel label="Icon Scale">*/}
                    {/*    <Slider value={element.itemScale}*/}
                    {/*        onChange={value => element.refreshModel({ itemScale: value })}*/}
                    {/*        onCommit={() => element.saveModel()}*/}
                    {/*        min={0.5} max={3} step={0.01}*/}
                    {/*        marks={[{ value: 1 }]}*/}
                    {/*        showInput percent*/}
                    {/*    />*/}
                    {/*</WithLabel>*/}
                </PropertySection>
                {/*<PropertySection>*/}
                {/*    <WithLabel left above label="Orientation">*/}
                {/*        <ToggleButtons*/}
                {/*            value={element.model.orientation ?? "auto"}*/}
                {/*            onChange={orientation => element.updateModel({ orientation })}*/}
                {/*        >*/}
                {/*            <ToggleButton value="auto">Auto</ToggleButton>*/}
                {/*            <ToggleButton value="vertical">Vertical</ToggleButton>*/}
                {/*            <ToggleButton value="horizontal">Horizontal</ToggleButton>*/}
                {/*        </ToggleButtons>*/}
                {/*    </WithLabel>*/}
                {/*</PropertySection>*/}
            </PropertyPanelContainer>
        );
    }
}

export class TeamItemControlBar extends Component {
    render() {
        const { element } = this.props;

        return (
            <ControlBar>
                <MediaPopup element={element.content} />
            </ControlBar>
        );
    }
}

import { MenuItem } from "@material-ui/core";
import React, { Component } from "react";

import { ControlBarPosition } from "../../../../../common/constants";
import { Button } from "../../../../Components/Button";
import { Dropdown } from "../../../../Components/Dropdown";
import { IconButton } from "../../../../Components/IconButton";
import { ToggleSwitch } from "../../../../Components/ToggleSwitch";
import { WithLabel } from "../../../../Components/WithLabel";
import { PropertySection } from "../../../../EditorComponents/PropertyPanel";
import { ShowInputDialog } from "../../../../react/components/Dialogs/BaseDialog";
import AuthoringElementHeader from "../EditorComponents/AuthoringElementHeader";
import { ColorPicker } from "../EditorComponents/ColorPickers/ColorPicker";
import { ControlBar } from "../ElementControlBars/Components/ControlBar";

export class StreetMapPropertyPanel extends Component {
    state = {
        address: this.props.element.map.address
    }

    render() {
        const { element } = this.props;

        return (
            <AuthoringElementHeader element={element}>
                <PropertySection>
                    <WithLabel label="Address" above left>
                        <div style={{ fontSize: 20 }}>{element.map.address}</div>
                    </WithLabel>
                    <Button onClick={async () => {
                        const address = await ShowInputDialog({ title: "Enter Address", value: element.map.address, placeholder: "Enter address here..." });
                        if (address) {
                            element.model.map_address.blocks[0].html = address;
                            element.canvas.updateCanvasModel(false);
                        }
                    }}>Set Address...</Button>
                </PropertySection>
                <PropertySection>
                    <WithLabel label="Map Style">
                        <Dropdown value={element.model.mapStyle ?? "default"}
                            onChange={value => element.updateModel({ mapStyle: value })}
                        >
                            <MenuItem value="default">Default</MenuItem>
                            <MenuItem value="light">Light</MenuItem>
                            <MenuItem value="dark">Dark</MenuItem>
                            <MenuItem value="retro">Retro</MenuItem>
                            <MenuItem value="satellite">Satellite</MenuItem>
                            <MenuItem value="hybrid">Hybrid</MenuItem>
                            <MenuItem value="terrain">Terrain</MenuItem>
                        </Dropdown>
                    </WithLabel>
                    <WithLabel label="Show Location Marker">
                        <ToggleSwitch value={element.model.showMarker}
                            onChange={value => element.updateModel({ showMarker: value })}
                        />
                    </WithLabel>
                    {element.model.showMarker && element.map && (
                        <WithLabel label="Marker Color">
                            <ColorPicker value={element.map.markerColor} canvas={element.canvas}
                                onChange={value => element.updateModel({ markerColor: value })}
                                allowColorOnColor showBackgroundColors
                            />
                        </WithLabel>
                    )}
                </PropertySection>
                <PropertySection>
                    <WithLabel label="Zoom" gap={5}>
                        <IconButton round size={25} icon="zoom_in" onClick={() => element.updateModel({ zoom: (element.model.zoom || 12) + 1 })} />
                        <IconButton round size={25} icon="zoom_out" onClick={() => element.updateModel({ zoom: (element.model.zoom || 12) - 1 })} />
                    </WithLabel>
                </PropertySection>
            </AuthoringElementHeader>
        );
    }
}

export class StreetMapControlBar extends Component {
    render() {
        const { element } = this.props;

        return (
            <ControlBar position={ControlBarPosition.INNER_BOTTOM}>
                <IconButton icon="zoom_in" onClick={() => element.updateModel({ zoom: (element.model.zoom || 12) + 1 })} />
                <IconButton icon="zoom_out" onClick={() => element.updateModel({ zoom: (element.model.zoom || 12) - 1 })} />
            </ControlBar>
        );
    }
}

import { _ } from "js/vendor";
import * as geom from "js/core/utilities/geom";
import { detectTextContent } from "js/core/services/sharedModelManager";
import { BlockStructureType, TextStyleType, AuthoringBlockType, ShapeType, DecorationStyle } from "common/constants";

import { CollectionElement, CollectionItemElement } from "../base/CollectionElement";
import { TextElement } from "../base/Text/TextElement";
import { SwotDiagramItemControlBar, SwotDiagramItemSelection, SwotDiagramPropertyPanel } from "../../Editor/ElementPropertyPanels/SwotDiagramUI";

const swotChars = ["S", "W", "O", "T"];

class SwotDiagram extends CollectionElement {
    getElementPropertyPanel() {
        return SwotDiagramPropertyPanel;
    }

    getElementControlBar() {
        return null;
    }

    getChildItemType() {
        return SwotDiagramItem;
    }

    get maxItemCount() {
        return 4;
    }

    get minItemCount() {
        return 4;
    }

    _calcProps(props, options) {
        let { size } = props;

        let layouter = this.getLayouter(props, this.itemElements, size).calcTextBoxGridLayout();

        return { size: layouter.size };
    }

    deleteItem() {
        // noop
    }

    _exportToSharedModel() {
        const textContent = this.itemElements.reduce((textContent, itemElement) => ([
            ...textContent, ...itemElement.text._exportToSharedModel().textContent
        ]), []);

        return { textContent, collectionColor: this.collectionColor };
    }

    _importFromSharedModel(model) {
        const textContent = detectTextContent(model);
        if (!textContent?.length) return this.canvas.slideTemplate.defaultData.primary;

        const elModel = _.cloneDeep(this.canvas.slideTemplate.defaultData.primary);
        elModel.collectionColor = model.collectionColor;
        elModel.items.forEach((item, i) => {
            if (textContent[i]) {
                item.text.blocks = [
                    {
                        html: textContent[i].mainText.text,
                        textStyle: TextStyleType.TITLE,
                        type: AuthoringBlockType.TEXT,
                    },
                    ...textContent[i].secondaryTexts.map(({ text }) => ({
                        html: text,
                        textStyle: TextStyleType.BODY,
                        type: AuthoringBlockType.TEXT,
                    }))
                ];
            } else {
                item.text.blocks.forEach(block => block.html = "");
            }
        });

        return elModel;
    }
}

class SwotDiagramItem extends CollectionItemElement {
    getElementControlBar() {
        return SwotDiagramItemControlBar;
    }

    getElementSelection() {
        return SwotDiagramItemSelection;
    }

    get selectionPadding() {
        return 0;
    }

    get _canSelect() {
        return true;
    }

    _build() {
        if (!this.model.char) {
            this.model.char = {
                text: swotChars[this.itemIndex]
            };
        }

        this.char = this.addElement("char", () => SWOTCharElement, {
            blockStructure: BlockStructureType.SINGLE_BLOCK,
            syncFontSizeWithSiblings: true,
        });

        this.text = this.addElement("text", () => TextElement, {
            blockStructure: BlockStructureType.TITLE_AND_BODY,
            defaultBlockTypes: [TextStyleType.TITLE, TextStyleType.BODY],
            allowAlignment: false,
            scaleTextToFit: true,
            syncFontSizeWithSiblings: true,
            backgroundElement: this.decoration
        });
    }

    _calcProps(props, options) {
        let { size } = props;

        const layoutBounds = new geom.Rect(0, 0, size);
        let remainingTextBounds = layoutBounds;
        let bodyBounds;
        let textLeft = 0;

        let charOffset = 0;

        switch (this.canvas.getTheme().get("styleShape")) {
            case ShapeType.RECT:
                charOffset = -1;
                break;
            case ShapeType.CIRCLE:
                charOffset = 2;
                break;
            case ShapeType.ROUNDED_RECT:
                charOffset = 5;
                break;
            case ShapeType.OCTAGON:
                charOffset = 0;
                break;
        }

        let charSize = new geom.Size(0.4 * size.height, 0.4 * size.height);
        let charProps = this.char.calcProps(new geom.Size(charSize.width - charOffset, charSize.height - charOffset));
        let charLeft = charOffset,
            charTop = charOffset;

        if (this.itemIndex % 2 === 0) {
            charLeft = size.width - charProps.size.width - charOffset;
        } else {
            textLeft = charProps.size.width;
        }
        if (this.itemIndex < 2) {
            charTop = size.height - charProps.size.height - charOffset;
        }
        charProps.bounds = new geom.Rect(charLeft, charTop, charProps.size);
        remainingTextBounds = layoutBounds.largestRemainingRect(new geom.Rect(charLeft, charTop, charSize));

        let textProps = this.text.calcProps(remainingTextBounds.size);
        textProps.bounds = new geom.Rect(textLeft, 0, textProps.size);

        return { size };
    }

    _applyColors() {
        this.colorSet.backgroundColor = this.decoration.getBackgroundColor();
    }

    get animationElementName() {
        return swotChars[this.itemIndex];
    }
}

class SWOTCharElement extends TextElement {
    get decorationStyle() {
        return DecorationStyle.FILLED;
    }

    _applyColors() {
        if (this.shapeStyle != "none") {
            if (this.parentElement.decorationStyle == DecorationStyle.OUTLINED) {
                this.decoration.colorSet.decorationColor = this.getDecorationColor();
            } else if (this.parentElement.decoration.getBackgroundColor().name === "white") {
                this.decoration.colorSet.decorationColor = this.palette.getColor("primary", this.parentElement.decoration.getBackgroundColor());
            } else {
                this.decoration.colorSet.decorationColor = this.palette.getColor("white");
            }

            this.colorSet.backgroundColor = this.decoration.colorSet.decorationColor;
        } else {
            this.colorSet.backgroundColor = this.parentElement.decoration.getBackgroundColor();
        }

        super._applyColors();
    }
}

export { SwotDiagramItem };

export const elements = {
    SwotDiagram,
};

import React, { Component } from "react";
import { MenuItem } from "@material-ui/core";

import { Popup, PopupContent } from "../../../../Components/Popup";
import { Icon } from "../../../../Components/Icon";
import { ShowAssetDialog } from "../../../../react/views/AddAssets/ShowAssetDialog";
import PresentationEditorController from "../../../../editor/PresentationEditor/PresentationEditorController";

export class MediaPopup extends Component {
    handleReplaceAsset = async closePopup => {
        const { element } = this.props;

        let assetModel = await ShowAssetDialog(element.assetType);
        if (assetModel) {
            element.updateModel(assetModel, { refreshStyles: true });
        }
        PresentationEditorController.selectionLayerController.setSelectedElements([element.assetElement]);
        closePopup();
    }

    handleAdjustAsset = closePopup => {
        const { element } = this.props;
        PresentationEditorController.selectionLayerController.setSelectedElements([element.assetElement]);
        closePopup();
    }

    handleRemoveAsset = closePopup => {
        const { element } = this.props;

        element.deleteAsset();
        element.saveModel();
        closePopup();
    }

    render() {
        const { element } = this.props;
        if (!element) {
            return null;
        }

        const { assetLabel = element.assetTypeLabel, canAdjust = !!element.assetElement } = this.props;

        return (
            <Popup icon="photo_camera">
                <PopupContent>
                    {closePopup => (
                        <>
                            {!element.hasValidAsset && (
                                <MenuItem onClick={() => this.handleReplaceAsset(closePopup)}><Icon>add_a_photo</Icon>Add Media...</MenuItem>
                            )}
                            {element.hasValidAsset && (
                                <>
                                    <MenuItem divider onClick={() => this.handleReplaceAsset(closePopup)}><Icon>add_a_photo</Icon>Replace {assetLabel}...</MenuItem>
                                    {canAdjust && <MenuItem divider onClick={() => this.handleAdjustAsset(closePopup)}><Icon>crop</Icon>Adjust {assetLabel}...</MenuItem>}
                                    <MenuItem onClick={() => this.handleRemoveAsset(closePopup)}><Icon>delete</Icon>Remove {assetLabel}</MenuItem>
                                </>
                            )}
                        </>
                    )}
                </PopupContent>
            </Popup>
        );
    }
}

import React, { Component } from "react";

import { MenuItem } from "@material-ui/core";
import { FormatType } from "../../../../../common/constants";
import { Dropdown } from "../../../../Components/Dropdown";
import { NumericStepper } from "../../../../Components/NumericStepper";
import { Slider } from "../../../../Components/Slider";
import { ToggleSwitch } from "../../../../Components/ToggleSwitch";
import { WithLabel } from "../../../../Components/WithLabel";
import { PropertyPanelContainer, PropertySection } from "../../../../EditorComponents/PropertyPanel";
import { CollectionColorPicker } from "../EditorComponents/ColorPickers/CollectionColorPicker";
import { ItemColorPicker } from "../EditorComponents/ColorPickers/ItemColorPicker";
import { FormattingDropdown } from "../EditorComponents/FormattingOptions";
import { ControlBar } from "../ElementControlBars/Components/ControlBar";

export class RadialBarChartPropertyPanel extends Component {
    render() {
        const { element } = this.props;

        return (
            <PropertyPanelContainer element={element}>
                <PropertySection>
                    <WithLabel label="Bar Color">
                        <CollectionColorPicker element={element} showBackgroundAccent={false} />
                    </WithLabel>
                </PropertySection>
                <PropertySection>
                    <WithLabel label="Show Bar Value" flex>
                        <ToggleSwitch value={element.model.format != FormatType.NONE}
                            onChange={value => element.updateModel({ format: value ? FormatType.PERCENT : FormatType.NONE })} />
                    </WithLabel>
                    <WithLabel label="Show Grid Lines" flex>
                        <ToggleSwitch value={element.model.showGrid}
                            onChange={value => element.updateModel({ showGrid: value })} />
                    </WithLabel>
                    <WithLabel label="Track Style" flex>
                        <Dropdown value={element.model.trackStyle}
                            onChange={value => element.updateModel({ trackStyle: value }, { refreshStyles: true })}>
                            <MenuItem value="none">None</MenuItem>
                            <MenuItem value="bar">Bar</MenuItem>
                            <MenuItem value="line">Line</MenuItem>
                        </Dropdown>
                    </WithLabel>
                    <WithLabel label="Label Position" flex>
                        <Dropdown value={element.model.startPosition}
                            onChange={value => element.updateModel({ startPosition: value })}>
                            <MenuItem value="top">Top</MenuItem>
                            <MenuItem value="bottom">Bottom</MenuItem>
                        </Dropdown>
                    </WithLabel>

                </PropertySection>

                {element.format != FormatType.NONE &&
                    <PropertySection>
                        <WithLabel label="Value Format"
                            toolTip="Configure how values in the chart are formatted when displayed."
                        >
                            <FormattingDropdown
                                format={element.format}
                                formatOptions={element.formatOptions}
                                onChangeFormat={format => element.updateModel({ format })}
                                onChangeFormatOptions={formatOptions => element.updateModel({ formatOptions })}
                                allowedFormats={[FormatType.PERCENT, FormatType.NUMBER, FormatType.CURRENCY]}
                            />
                        </WithLabel>

                        <WithLabel label="Max Value" flex
                            toolTip="Defines the maximum value for all radial bars in the chart. ">
                            <NumericStepper value={element.maxValue}
                                min={1}
                                onChange={value => element.updateModel({ maxValue: value })} />
                        </WithLabel>
                    </PropertySection>
                }
            </PropertyPanelContainer>
        );
    }
}

export class RadialBarChartItemControlBar extends Component {
    render() {
        const { element } = this.props;

        return (
            <ControlBar>
                <Slider min={0} max={element.parentElement.maxValue}
                    value={element.model.value}
                    onChange={value => element.refreshModel({ value })}
                    onCommit={() => element.saveModel()}
                />
                <ItemColorPicker element={element} showBackgroundAccent={false} />
            </ControlBar>
        );
    }
}

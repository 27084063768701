import * as geom from "js/core/utilities/geom";

export class SingleCellGridLayout {
    get layouts() {
        return [];
    }

    getName() {
        return "Single Cell";
    }

    getContainerSelection() {
        return null;
    }

    getCellSelection() {
        return null;
    }

    calcCellProps(container, props) {
        const { size, outerGutter = 0, innerGutter = 0 } = props;

        const cells = container.itemElements;
        if (cells.length === 0) {
            return { size };
        }

        const cell = cells[0];
        const left = outerGutter + innerGutter,
            top = outerGutter + innerGutter,
            width = size.width - outerGutter - innerGutter,
            height = size.height - outerGutter - innerGutter;

        const cellProps = cell.calcProps(new geom.Size(width, height));
        cellProps.bounds = new geom.Rect(left, top, width, height);

        return { size };
    }

    renderThumbnails() {
        return [];
    }
}

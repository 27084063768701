import React, { Component } from "react";

import { TextStyleType } from "../../../../../common/constants";
import { Button } from "../../../../Components/Button";
import { Slider } from "../../../../Components/Slider";
import { ToggleSwitch } from "../../../../Components/ToggleSwitch";
import { WithLabel } from "../../../../Components/WithLabel";
import { getValueOrMixed } from "../../../../core/utilities/utilities";
import { PropertyPanelContainer, PropertySection, PropertySectionHeader } from "../../../../EditorComponents/PropertyPanel";

export class TextBlockPropertyPanel extends Component {
    render() {
        const { element } = this.props;

        let title = "All Blocks Selected";
        let selectedBlocks = element.blocks;

        let textContainer = element.uiRefs.selectionRef?.current?.textEditorContainerRef?.current;
        if (textContainer && textContainer.selectedBlocks.length) {
            selectedBlocks = textContainer.selectedBlocks;
            title = selectedBlocks.length == 1 ? "1 Block Selected" : `${selectedBlocks.length} Blocks Selected`;
        }

        let blockModels = selectedBlocks.map(block => block.model);

        let spaceAbove = getValueOrMixed(blockModels, "spaceAbove");
        let evenBreak = getValueOrMixed(blockModels, "evenBreak");
        let fontKerning = getValueOrMixed(blockModels, "fontKerning");
        let hyphenation = getValueOrMixed(blockModels, "hyphenation");
        let ligatures = getValueOrMixed(blockModels, "ligatures");

        return (
            <PropertyPanelContainer>
                <PropertySection>
                    <PropertySectionHeader label={title}>
                        <Button small>Select All Blocks</Button>
                    </PropertySectionHeader>
                    <WithLabel label="Block Spacing">
                        <Slider value={spaceAbove} min={-20} max={100} step={1}
                            onChange={spaceAbove => {
                                selectedBlocks.forEach(block => block.model.spaceAbove = spaceAbove);
                                element.refreshModel();
                            }}
                            onCommit={spaceAbove => {
                                selectedBlocks.forEach(block => block.model.spaceAbove = spaceAbove);
                                element.saveModel();
                            }}
                            showInput
                        />
                    </WithLabel>
                    <WithLabel label="Match Font Scale">
                        <ToggleSwitch />
                    </WithLabel>
                    <WithLabel label="Balance Ragged Lines">
                        <ToggleSwitch value={evenBreak}
                            onChange={value => {
                                selectedBlocks.forEach(block => block.model.evenBreak = value);
                                element.saveModel();
                            }}
                            disabled={!blockModels.some(block => block.textStyle.equalsAnyOf(TextStyleType.HEADLINE, TextStyleType.HEADING, TextStyleType.TITLE))}
                        />
                    </WithLabel>
                </PropertySection>
                <PropertySection>
                    <PropertySectionHeader label="Advanced Settings"/>
                    <WithLabel label="Use Kerning">
                        <ToggleSwitch value={fontKerning}
                            onChange={value => {
                                selectedBlocks.forEach(block => block.model.fontKerning = value);
                                element.saveModel();
                            }}
                        />
                    </WithLabel>
                    <WithLabel label="Use Hyphenation">
                        <ToggleSwitch value={hyphenation}
                            onChange={value => {
                                selectedBlocks.forEach(block => block.model.hyphenation = value);
                                element.saveModel();
                            }}
                        />
                    </WithLabel>
                    <WithLabel label="Show Ligatures">
                        <ToggleSwitch value={ligatures}
                            onChange={value => {
                                selectedBlocks.forEach(block => block.model.ligatures = value);
                                element.saveModel();
                            }}
                        />
                    </WithLabel>
                </PropertySection>
                <PropertySection>
                    <Button>Reset Selected Block Styles</Button>
                </PropertySection>
            </PropertyPanelContainer>
        );
    }
}


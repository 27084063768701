import React, { Component } from "react";
import styled from "styled-components";

import * as geom from "../../../../core/utilities/geom";
import { AnchorType } from "../../../../core/utilities/geom";
import { Key } from "../../../../core/utilities/keys";
import { defaultDragPositionProps } from "../../../../editor/PresentationEditor/DragElementManager";

import ElementSelectionBox from "../ElementSelections/Components/ElementSelectionBox";
import { ConnectorPropertyPanel } from "../EditorComponents/ConnectorPropertyPanel";
import AuthoringElementHeader from "../EditorComponents/AuthoringElementHeader";
import { CalloutType, PositionType } from "../../../../../common/constants";
import { _ } from "../../../../vendor";

const PathHilite = styled.svg`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;

    > path {
        stroke: #11a9e2;
        fill: none;
        stroke-width: 3px;
    }
`;

export class ConnectorItemPropertyPanel extends Component {
    render() {
        const { element, canvas } = this.props;

        return (
            <AuthoringElementHeader element={element}>
                <ConnectorPropertyPanel
                    canvas={canvas}
                    element={element}
                    canChangeConnectorType
                    canAddLabel
                    canDelete={element.canDelete}
                />
            </AuthoringElementHeader>
        );
    }
}

const ConnectorEndDragHandle = styled.div.attrs(({ position }) => ({
    style: {
        left: position.x,
        top: position.y
    }
}))`
    position: absolute;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: white;
    border: 1px solid #ccc;
    cursor: pointer;
    pointer-events: all;
    z-index: 99999;
`;

const ConnectorAdjDragHandle = styled.div.attrs(({ position, direction }) => ({
    style: {
        left: position.x,
        top: position.y,
        cursor: direction === "H" ? "ew-resize" : "ns-resize"
    }
}))`
    position: absolute;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: white;
    border: 1px solid #ccc;
    pointer-events: all;
    z-index: 99999;
`;

const SquareAnchorPoint = styled.div.attrs(({ position, active }) => ({
    style: {
        left: position.x,
        top: position.y,
        backgroundColor: active ? "#11a9e2" : "white",
    }
}))`
    width: 12px;
    height: 12px;
    transform: translate(-50%, -50%);
    position: absolute;
    border: 2px solid #11a9e2;
    background-color: white;
    z-index: 999999;
`;

const CircleAnchorPoint = styled(SquareAnchorPoint)`
    border-radius: 50%;
`;

const DragStartContainer = styled.div.attrs(({ bounds }) => ({
    style: {
        left: bounds.left,
        top: bounds.top,
        width: bounds.width,
        height: bounds.height
    }
}))`
    position: absolute;
    cursor: move;
    pointer-events: auto;
`;

export function getAnchorPointsForUI(element, canvasBounds) {
    const canvas = element.canvas;

    const anchorPoints = [];

    if (element.options.allowConnection === false) {
        return anchorPoints;
    }

    const targetBounds = element.anchorBounds;

    const parentCanvasBounds = element.parentElement.canvasBounds;
    let targetRenderBounds = targetBounds
        .offset(parentCanvasBounds.left, parentCanvasBounds.top)
        .multiply(canvas.getScale())
        .offset(canvasBounds.left, canvasBounds.top);

    if (element.model.calloutType == CalloutType.BULLET_TEXT) {
        targetRenderBounds = targetRenderBounds.inflate(15);
    }

    const sensitiveSize = 20;

    if (element.availableAnchorPoints.includes(AnchorType.TOP)) {
        anchorPoints.push({
            anchor: AnchorType.TOP,
            position: new geom.Point(targetRenderBounds.left + targetRenderBounds.width / 2, targetRenderBounds.top),
            sensitiveBounds: new geom.Rect(targetBounds.left + targetBounds.width / 2 - sensitiveSize / 2, targetBounds.top - sensitiveSize / 2, sensitiveSize, sensitiveSize)
        });
    }

    if (element.availableAnchorPoints.includes(AnchorType.BOTTOM)) {
        anchorPoints.push({
            anchor: AnchorType.BOTTOM,
            position: new geom.Point(targetRenderBounds.left + targetRenderBounds.width / 2, targetRenderBounds.bottom),
            sensitiveBounds: new geom.Rect(targetBounds.left + targetBounds.width / 2 - sensitiveSize / 2, targetBounds.bottom - sensitiveSize / 2, sensitiveSize, sensitiveSize)
        });
    }

    if (element.availableAnchorPoints.includes(AnchorType.LEFT)) {
        anchorPoints.push({
            anchor: AnchorType.LEFT,
            position: new geom.Point(targetRenderBounds.left, targetRenderBounds.top + targetRenderBounds.height / 2),
            sensitiveBounds: new geom.Rect(targetBounds.left - sensitiveSize / 2, targetBounds.top + targetBounds.height / 2 - sensitiveSize / 2, sensitiveSize, sensitiveSize)
        });
    }

    if (element.availableAnchorPoints.includes(AnchorType.RIGHT)) {
        anchorPoints.push({
            anchor: AnchorType.RIGHT,
            position: new geom.Point(targetRenderBounds.right, targetRenderBounds.top + targetRenderBounds.height / 2),
            sensitiveBounds: new geom.Rect(targetBounds.right - sensitiveSize / 2, targetBounds.top + targetBounds.height / 2 - sensitiveSize / 2, sensitiveSize, sensitiveSize)
        });
    }

    if (element.availableAnchorPoints.includes(AnchorType.FREE)) {
        anchorPoints.push({
            anchor: AnchorType.FREE,
            position: new geom.Point(targetRenderBounds.left + targetRenderBounds.width / 2, targetRenderBounds.top + targetRenderBounds.height / 2),
            sensitiveBounds: new geom.Rect(targetBounds.left + targetBounds.width / 2 - sensitiveSize / 2, targetBounds.top + targetBounds.height / 2 - sensitiveSize / 2, sensitiveSize, sensitiveSize)
        });
    }

    anchorPoints.forEach(anchorPoint => {
        anchorPoint.elementId = element.id;
    });

    return anchorPoints;
}

export class ConnectorItemSelection extends Component {
    constructor(props) {
        super(props);

        this.state = {
            anchorPoints: [],
            isDragging: false
        };
    }

    get authoringCanvas() {
        const { element } = this.props;
        return element.getParentOfType("AuthoringCanvas");
    }

    get authoringLayer() {
        return this.authoringCanvas?.authoringLayer;
    }

    setStateAsync = state => new Promise(resolve => this.setState(state, resolve));

    handleKeyboardShortcut = async event => {
        const { element, canvas } = this.props;

        if ([Key.DELETE, Key.BACKSPACE].includes(event.which)) {
            const container = element.parentElement;
            container.deleteItem(element.id);
            await canvas.updateCanvasModel(false);
        }
    }

    handleDrag = () => {
        const { element, selectionLayerController, canvasBounds } = this.props;

        if (!element.canDragConnectors) return;

        const authoringCanvas = this.authoringCanvas;
        const authoringLayer = this.authoringLayer;
        this.setState({ isDragging: true });

        let sourcePoint, targetPoint;

        const dragPositionProps = {
            ...defaultDragPositionProps,
            dragOpacity: 1,
            hideFauxElement: true,
            onDragStart: async () => {
                sourcePoint = new geom.Point(element.model.sourcePoint.x, element.model.sourcePoint.y);
                targetPoint = new geom.Point(element.model.targetPoint.x, element.model.targetPoint.y);
            },
            onDrag: async ({ dragOffset, event }) => {
                element.model.sourcePoint.x = sourcePoint.x + dragOffset.x;
                element.model.sourcePoint.y = sourcePoint.y + dragOffset.y;
                element.model.targetPoint.x = targetPoint.x + dragOffset.x;
                element.model.targetPoint.y = targetPoint.y + dragOffset.y;

                const points = [
                    element.model.sourcePoint,
                    element.model.sourcePoint
                ];

                const anchorPoints = [];
                const overElement = authoringCanvas.itemElements.find(el => el !== element && el.bounds.inflate(20).contains(points));
                let activeAnchorPoint;
                if (overElement) {
                    anchorPoints.push(...getAnchorPointsForUI(overElement, canvasBounds));
                }

                await this.setStateAsync({ anchorPoints });

                let anchor = null;
                let target = null;

                if (activeAnchorPoint) {
                    await authoringLayer.removeSnapLines();

                    anchor = activeAnchorPoint.anchor;
                    target = activeAnchorPoint.elementId;
                } else if (!authoringCanvas.snapToGrid && authoringCanvas.showSnapLines) {
                    const snapLines = await authoringLayer.renderSnapLinesForExternalElement(new geom.Rect(
                        Math.min(element.model.sourcePoint.x, element.model.targetPoint.x),
                        Math.min(element.model.sourcePoint.y, element.model.targetPoint.y),
                        Math.abs(element.model.sourcePoint.x - element.model.targetPoint.x),
                        Math.abs(element.model.sourcePoint.y - element.model.targetPoint.y)
                    ));

                    const xOffset = snapLines.find(item => item.direction === "vertical")?.offset ?? 0;
                    const yOffset = snapLines.find(item => item.direction === "horizontal")?.offset ?? 0;

                    element.model.sourcePoint.x += xOffset;
                    element.model.sourcePoint.y += yOffset;
                    element.model.targetPoint.x += xOffset;
                    element.model.targetPoint.x += yOffset;
                }

                element.refreshElement();
            },
            onDragEnd: async () => {
                this.setState({ isDragging: false });
                await authoringLayer.removeSnapLines();
                await this.setStateAsync({ anchorPoints: [] });
            }
        };

        selectionLayerController.registerElementDrag([element], dragPositionProps);
    }

    handleConnectorEndDragStart = (isEnd, event = null) => {
        const { element, selectionLayerController, canvasBounds } = this.props;

        let initialX, initialY;

        const authoringCanvas = this.authoringCanvas;
        const authoringLayer = this.authoringLayer;
        this.setState({ isDragging: true });

        const dragPositionProps = {
            ...defaultDragPositionProps,
            dragOpacity: 1,
            hideFauxElement: true,
            onDragStart: async () => {
                const pathPoint = isEnd ? element.calculatedProps.path.points[element.calculatedProps.path.points.length - 1] : element.calculatedProps.path.points[0];
                initialX = pathPoint.x;
                initialY = pathPoint.y;
            },
            onDrag: async ({ dragOffset }) => {
                const currentPoint = new geom.Point(initialX + dragOffset.x, initialY + dragOffset.y);

                if (isEnd && !element.model.targetPoint) {
                    element.model.targetPoint = {};
                } else if (!isEnd && !element.model.sourcePoint) {
                    element.model.sourcePoint = {};
                }
                const pointModel = isEnd ? element.model.targetPoint : element.model.sourcePoint;
                pointModel.x = currentPoint.x;
                pointModel.y = currentPoint.y;

                if (isEnd && element.options.snapEndPoint) {
                    let snapPoint = element.options.snapEndPoint(currentPoint);
                    pointModel.x = snapPoint.x;
                    pointModel.y = snapPoint.y;
                }

                const anchorPoints = [];
                let activeAnchorPoint;

                if (element.options.canConnectToOtherNodes) {
                    const overElement = authoringCanvas.itemElements.find(el => el !== element && el.bounds.inflate(20).contains(currentPoint));
                    if (overElement) {
                        anchorPoints.push(...getAnchorPointsForUI(overElement, canvasBounds));

                        activeAnchorPoint = _.minBy(anchorPoints, anchorPoint => anchorPoint.sensitiveBounds.getPoint(PositionType.CENTER).distance(currentPoint));
                        activeAnchorPoint.active = true;
                    }
                }

                await this.setStateAsync({ anchorPoints });

                let anchor = null;
                let target = null;

                if (activeAnchorPoint) {
                    await authoringLayer.removeSnapLines();

                    anchor = activeAnchorPoint.anchor;
                    target = activeAnchorPoint.elementId;
                } else if (!authoringCanvas.snapToGrid && authoringCanvas.showSnapLines) {
                    const snapLines = await authoringLayer.renderSnapLinesForExternalElement(new geom.Rect(pointModel.x, pointModel.y, 1, 1));

                    const xOffset = snapLines.find(item => item.direction === "vertical")?.offset ?? 0;
                    const yOffset = snapLines.find(item => item.direction === "horizontal")?.offset ?? 0;

                    pointModel.x += xOffset;
                    pointModel.y += yOffset;
                }

                if (element.restrictConnectorToBounds) {
                    const registrationPointBounds = new geom.Rect(pointModel.x, pointModel.y, 0, 0)
                        .fitInRect(new geom.Rect(0, 0, element.parentElement.calculatedProps.size.width, element.parentElement.calculatedProps.size.height));
                    pointModel.x = registrationPointBounds.x;
                    pointModel.y = registrationPointBounds.y;
                }

                if (isEnd) {
                    element.model.endAnchor = anchor;
                    element.model.target = target;
                } else {
                    element.model.startAnchor = anchor;
                    element.model.source = target;
                }

                // reset adjustments when reconnecting
                element.model.adjustments = {};

                element.refreshElement();
            },
            onDragEnd: async ({ dragOffset }) => {
                await authoringLayer.removeSnapLines();
                await this.setStateAsync({ anchorPoints: [] });

                this.setState({ isDragging: false });
            }
        };

        selectionLayerController.registerElementDrag([element], dragPositionProps, event);
    }

    handleConnectorAdjDragStart = async pointIndex => {
        const { element, selectionLayerController } = this.props;

        const { adjustmentId, adjustmentDirection, x, y } = element.calculatedProps.path.points[pointIndex];
        this.setState({ isDragging: true });

        const dragPositionProps = {
            ...defaultDragPositionProps,
            dragOpacity: 1,
            hideFauxElement: true,
            dragAxes: adjustmentDirection === "H" ? ["x"] : ["y"],
            onDrag: async ({ dragOffset }) => {
                if (adjustmentDirection === "H") {
                    element.model.adjustments[adjustmentId] = Math.clamp(x + dragOffset.x, 0, element.bounds.width);
                } else {
                    element.model.adjustments[adjustmentId] = Math.clamp(y + dragOffset.y, 0, element.bounds.height);
                }
                element.refreshElement();
            },
            onDragEnd: async ({ dragOffset }) => {
                this.setState({ isDragging: false });
            }
        };

        selectionLayerController.registerElementDrag([element], dragPositionProps);
    }

    getDraggingBounds(startPoint, endPoint) {
        const left = Math.min(startPoint.x, endPoint.x);
        const top = Math.min(startPoint.y, endPoint.y);
        const right = Math.max(startPoint.x, endPoint.x);
        const bottom = Math.max(startPoint.y, endPoint.y);

        const width = right - left;
        const height = bottom - top;

        return new geom.Rect(left, top, width, height).inflate(20);
    }

    render() {
        const { canvas, element } = this.props;
        const { anchorPoints, isDragging } = this.state;

        const path = element.calculatedProps.path.clone();
        path.scale(canvas.getScale());

        const startPoint = path.points[0];
        const endPoint = path.points[path.points.length - 1];

        const startPointEditable = !element.parentElement.startPointsAreLocked || !element.model.source;
        const endPointEditable = !element.parentElement.endPointsAreLocked || !element.model.target;

        return (
            <ElementSelectionBox>
                {element.canDragConnectors && <DragStartContainer bounds={this.getDraggingBounds(startPoint, endPoint)} onMouseDown={this.handleDrag} />}
                <PathHilite>
                    <path d={path.toPathData()} />
                </PathHilite>

                {startPointEditable && !isDragging && <ConnectorEndDragHandle
                    position={startPoint}
                    onMouseDown={() => this.handleConnectorEndDragStart(false)}
                />}
                {endPointEditable && !isDragging && <ConnectorEndDragHandle
                    position={endPoint}
                    onMouseDown={() => this.handleConnectorEndDragStart(true)}
                />}

                {!isDragging && path.points.map((pt, index) => pt.adjustmentId &&
                    <ConnectorAdjDragHandle
                        key={index}
                        position={path.getSegment(index).midpoint}
                        direction={pt.adjustmentDirection}
                        onMouseDown={() => this.handleConnectorAdjDragStart(index)}
                    />
                )}

                {anchorPoints
                    .filter(({ anchor }) => anchor === AnchorType.FREE)
                    .map(({ position, active }, index) => (
                        <SquareAnchorPoint key={index} position={position} active={active} />
                    ))}
                {anchorPoints
                    .filter(({ anchor }) => anchor !== AnchorType.FREE)
                    .map(({ position, active }, index) => (
                        <CircleAnchorPoint key={index} position={position} active={active} />
                    ))}
            </ElementSelectionBox>
        );
    }
}

import React, { Component } from "react";
import styled from "styled-components";

import { Icon, Menu, MenuItem } from "@material-ui/core";

import FlipToFront from "mdi-material-ui/FlipToFront";
import FlipToBack from "mdi-material-ui/FlipToBack";
import AlignHorizontalLeft from "mdi-material-ui/AlignHorizontalLeft";
import AlignHorizontalCenter from "mdi-material-ui/AlignHorizontalCenter";
import AlignHorizontalRight from "mdi-material-ui/AlignHorizontalRight";
import AlignVerticalTop from "mdi-material-ui/AlignVerticalTop";
import AlignVerticalCenter from "mdi-material-ui/AlignVerticalCenter";
import AlignVerticalBottom from "mdi-material-ui/AlignVerticalBottom";
import DistributeHorizontalCenter from "mdi-material-ui/DistributeHorizontalCenter";
import DistributeVerticalCenter from "mdi-material-ui/DistributeVerticalCenter";
import Group from "mdi-material-ui/Group";
import Ungroup from "mdi-material-ui/Ungroup";
import ContentCut from "mdi-material-ui/ContentCut";
import ContentCopy from "mdi-material-ui/ContentCopy";
import Eyedropper from "mdi-material-ui/Eyedropper";
import EyedropperPlus from "mdi-material-ui/EyedropperPlus";

import { NestedMenuItem } from "../../../../../react/components/NestedMenuItem";
import * as browser from "../../../../../core/utilities/browser";
import { getAuthoringContainerElementIconAndName } from "../../ElementPropertyPanels/AuthoringUI";

const PREFERRED_ICON_SIZE = 20;

const MenuDivider = styled.hr`
    border: 1px solid #eeeeee;
`;

const ImagePreview = styled.div`
    width: ${props => props.width ?? `${PREFERRED_ICON_SIZE}px`};
    height: ${PREFERRED_ICON_SIZE}px;
    background-image: url(${props => props.src});
    background-size: cover;
    background-position: 50% 50%;
`;

const MenuItemPair = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;

    .menu-item--icon {
        width: 25px;
        display: flex;
        justify-content: center;
        text-align: center;

        // some icons have margins on them
        // so this ensures they don't nudge
        > * {
            margin: 0 !important;
        }
    }
`;

export class SelectionContextMenu extends Component {
    handleContextMenuAction = action => {
        this.props.onContextMenuAction(action);
    }

    handleSelectElement = (event, element) => {
        event.preventDefault();
        event.stopPropagation();

        this.props.setContentMenuItemSelected(true);
        this.props.onSelectElement(element);
    }

    render() {
        const {
            open,
            contextMenuPosition,
            canAlignAndDistribute,
            canCopyStyles,
            canGroup,
            canUngroup,
            isLocked,
            onClose,
            elementsUnderMouse = [],
            allowCopyPasteStyles = true,
        } = this.props;

        // This is a callout context menu, so we don't want to show whenever we are in a smart slide
        const bringToElements = [
            {
                onMouseDown: () => this.handleContextMenuAction("bringToFront"),
                icon: (<FlipToFront />),
                label: "Bring to Front"
            }, {
                onMouseDown: () => this.handleContextMenuAction("sendToBack"),
                icon: (<FlipToBack />),
                label: "Send to Back"
            }
        ];

        return (
            <Menu
                open={open}
                anchorReference="anchorPosition"
                anchorPosition={{ left: contextMenuPosition.x, top: contextMenuPosition.y }}
                onClose={onClose}
            >
                {bringToElements.map((item, index) => (
                    <MenuItem key={index} onMouseDown={item.onMouseDown}>
                        {item.icon}
                        {item.label}
                    </MenuItem>
                ))}
                {canAlignAndDistribute && <MenuDivider />}
                {canAlignAndDistribute && <NestedMenuItem label="Align">
                    <MenuItem onMouseDown={() => this.handleContextMenuAction("align-left")}>
                        <AlignHorizontalLeft />
                        Left
                    </MenuItem>
                    <MenuItem onMouseDown={() => this.handleContextMenuAction("align-center")}>
                        <AlignHorizontalCenter />
                        Center
                    </MenuItem>
                    <MenuItem onMouseDown={() => this.handleContextMenuAction("align-right")} divider>
                        <AlignHorizontalRight />
                        Right
                    </MenuItem>
                    <MenuItem onMouseDown={() => this.handleContextMenuAction("align-top")}>
                        <AlignVerticalTop />
                        Top
                    </MenuItem>
                    <MenuItem onMouseDown={() => this.handleContextMenuAction("align-middle")}>
                        <AlignVerticalCenter />
                        Middle
                    </MenuItem>
                    <MenuItem onMouseDown={() => this.handleContextMenuAction("align-bottom")} divider>
                        <AlignVerticalBottom />
                        Bottom
                    </MenuItem>
                </NestedMenuItem>}
                {canAlignAndDistribute && <NestedMenuItem label="Distribute">
                    <MenuItem onMouseDown={() => this.handleContextMenuAction("distribute-horizontal")}>
                        <DistributeHorizontalCenter />
                        Horizontally
                    </MenuItem>
                    <MenuItem onMouseDown={() => this.handleContextMenuAction("distribute-vertical")}>
                        <DistributeVerticalCenter />
                        Vertically
                    </MenuItem>
                </NestedMenuItem>}
                <MenuDivider />
                {canGroup && <MenuItem onMouseDown={() => this.handleContextMenuAction("group")}>
                    <Group />
                    Group
                </MenuItem>}
                {!canGroup && canUngroup && <MenuItem onMouseDown={() => this.handleContextMenuAction("ungroup")}>
                    <Ungroup />
                    Ungroup
                </MenuItem>}
                {isLocked && <MenuItem onMouseDown={() => this.handleContextMenuAction("unlock")}>
                    <Icon>lock_open</Icon>
                    Unlock
                </MenuItem>}
                {!isLocked && <MenuItem onMouseDown={() => this.handleContextMenuAction("lock")}>
                    <Icon>lock</Icon>
                    Lock
                </MenuItem>}
                <MenuDivider />
                {!browser.isFirefox &&
                    <NestedMenuItem label="Copy/Paste">
                        <MenuItem onMouseDown={() => this.handleContextMenuAction("cut")}>
                            <ContentCut />
                            Cut
                        </MenuItem>
                        <MenuItem onMouseDown={() => this.handleContextMenuAction("copy")}>
                            <ContentCopy />
                            Copy
                        </MenuItem>
                        {allowCopyPasteStyles && <>
                            <MenuDivider />
                            <MenuItem onMouseDown={() => this.handleContextMenuAction("copyStyles")} disabled={!canCopyStyles}>
                                <Eyedropper />
                                Copy Styles
                            </MenuItem>
                            <MenuItem onMouseDown={() => this.handleContextMenuAction("pasteStyles")}>
                                <EyedropperPlus />
                                Paste Styles
                            </MenuItem>
                        </>}
                    </NestedMenuItem>
                }
                {!browser.isFirefox && <MenuDivider />}
                <MenuItem onMouseDown={() => this.handleContextMenuAction("delete")}>
                    <Icon>delete_outline</Icon>
                    Delete
                </MenuItem>
                {elementsUnderMouse.length > 1 && <MenuDivider />}
                {elementsUnderMouse.length > 1 &&
                    <NestedMenuItem label="Select Layer">
                        {elementsUnderMouse.map((element, i) => {
                            const { IconJSX, name } = getAuthoringContainerElementIconAndName(element);

                            return (
                                <MenuItem onMouseDown={event => this.handleSelectElement(event, element)} key={`item_${i}`}>
                                    <MenuItemPair>
                                        <div className="menu-item--icon">{IconJSX}</div>
                                        <div className="menu-item--label">{name}</div>
                                    </MenuItemPair>
                                </MenuItem>
                            );
                        })}
                    </NestedMenuItem>
                }
            </Menu>
        );
    }
}

import React, { Component } from "react";

import { WithLabel } from "../../../../Components/WithLabel";
import { ToggleSwitch } from "../../../../Components/ToggleSwitch";
import { IconButton, RoundIconButton } from "../../../../Components/IconButton";
import { FlexBox } from "../../../../react/components/LayoutGrid";
import PresentationEditorController from "../../../../editor/PresentationEditor/PresentationEditorController";
import { DatePicker } from "../../../../Components/DatePicker";
import { StaticImage } from "../../../../Components/StaticImage";
import { ImageOption, ImageOptionList } from "../../../../Components/ImageOptionList";
import { Icon } from "../../../../Components/Icon";

import { PropertyPanelContainer, PropertySection } from "../../../../EditorComponents/PropertyPanel";
import { ControlBar } from "../ElementControlBars/Components/ControlBar";
import { BaseElementSelection } from "../ElementSelections/BaseElementSelection";
import { TextElementSelection } from "../ElementSelections/TextElementSelection";
import { ColorPicker } from "../EditorComponents/ColorPickers/ColorPicker";
import { CollectionColorPicker } from "../EditorComponents/ColorPickers/CollectionColorPicker";

export class CalendarPropertyPanel extends Component {
    render() {
        const { element } = this.props;

        const calendarElement = element.calendar;
        const isMonthCalendar = calendarElement.isInstanceOf("MonthCalendar");
        const isWeekCalendar = calendarElement.isInstanceOf("WeekCalendar");

        return (
            <PropertyPanelContainer>
                <PropertySection>
                    <WithLabel label="Calendar Type" left>
                        <ImageOptionList cols={2} size={40}
                            value={element.model.calendarType}
                            onChange={value => element.switchCalendarType(value)}
                        >
                            <ImageOption value="month" label="Month">
                                <Icon>grid_view</Icon>
                            </ImageOption>
                            <ImageOption value="week" label="Week">
                                <Icon>calendar_view_week</Icon>
                            </ImageOption>
                        </ImageOptionList>
                    </WithLabel>
                </PropertySection>
                <PropertySection>
                    {isMonthCalendar && (
                        <WithLabel label="Calendar Month">
                            <DatePicker value={new Date(element.model.date)}
                                onChange={value => element.updateModel({ date: value.toDateString() })}
                                showMonthYearPicker
                                dateFormat="MMM yyyy"
                            />
                        </WithLabel>
                    )}
                    {isWeekCalendar && (
                        <WithLabel label="Calendar Week">
                            <DatePicker value={new Date(element.model.date)}
                                onChange={value => element.updateModel({ date: value.toDateString() })}
                                dateFormat="MM/dd/yyyy"
                            />
                        </WithLabel>
                    )}
                </PropertySection>
                <PropertySection>
                    <WithLabel label="Show Calendar Title">
                        <ToggleSwitch value={element.model.showTitle} onChange={value => element.updateModel({ showTitle: value })} />
                    </WithLabel>
                    <WithLabel label="Show Weekends">
                        <ToggleSwitch value={element.model.showWeekends} onChange={value => element.updateModel({ showWeekends: value })} />
                    </WithLabel>
                </PropertySection>
                {isWeekCalendar && (
                    <>
                        <PropertySection>
                            <WithLabel label="Task Colors">
                                <CollectionColorPicker element={element.calendar.board}
                                    showDecorationStyles
                                    onChange={color => {
                                        if (element.calendar.board?.itemElements) {
                                            for (let column of element.calendar.board.itemElements) {
                                                column.taskList.model.collectionColor = color;
                                                for (let task of column.taskList.itemElements) {
                                                    task.model.color = null;
                                                }
                                            }
                                        }
                                        element.calendar.board?.updateModel({ collectionColor: color });
                                    }}
                                    onChangeDecorationStyle={decorationStyle => {
                                        if (element.calendar.board?.itemElements) {
                                            for (let column of element.calendar.board.itemElements) {
                                                column.taskList.model.decorationStyle = decorationStyle;
                                                for (let item of column.taskList.itemElements) {
                                                    item.model.decorationStyle = null;
                                                }
                                            }
                                        }
                                        element.calendar.board?.updateModel({ decorationStyle });
                                    }}

                                />
                            </WithLabel>
                        </PropertySection>
                        <PropertySection>
                            <WithLabel label="Style" above left>
                                <ImageOptionList cols={3}
                                    value={calendarElement.model.style || "box"}
                                    onChange={value => calendarElement.updateModel({ style: value })}
                                >
                                    <ImageOption value="no-frame" label="None">
                                        <StaticImage src="/images/ui/vertical-tasks-style-none.svg" />
                                    </ImageOption>
                                    <ImageOption value="divider" label="Divider">
                                        <StaticImage src="/images/ui/vertical-tasks-style-divider.svg" />
                                    </ImageOption>
                                    <ImageOption value="box" label="Box">
                                        <StaticImage src="/images/ui/vertical-tasks-style-box.svg" />
                                    </ImageOption>
                                </ImageOptionList>
                            </WithLabel>
                        </PropertySection>
                        <PropertySection>
                            <WithLabel label="Layout" above left>
                                <ImageOptionList cols={3}
                                    value={calendarElement.model.taskLayout || "stack"}
                                    onChange={value => calendarElement.updateModel({ taskLayout: value }, { transition: true })}
                                >
                                    <ImageOption value="stack" label="Stack">
                                        <StaticImage src="/images/ui/vertical-tasks-layout-stack.svg" />
                                    </ImageOption>
                                    <ImageOption value="fill" label="Fill">
                                        <StaticImage src="/images/ui/vertical-tasks-layout-fill.svg" />
                                    </ImageOption>
                                    <ImageOption value="fit" label="Fit">
                                        <StaticImage src="/images/ui/vertical-tasks-layout-fit.svg" />
                                    </ImageOption>
                                </ImageOptionList>
                            </WithLabel>
                        </PropertySection>
                    </>
                )}
            </PropertyPanelContainer>
        );
    }
}

export class CalendarCellControlBar extends Component {
    render() {
        const { element } = this.props;

        return (
            <ControlBar>
                <WithLabel label="Color" gap={0}>
                    <ColorPicker value={element.model.cellColor} canvas={element.canvas}
                        allowColorOnColor defaultColor="#ffffff" showPositiveNegative showBackgroundColors
                        onChange={value => element.updateModel({ cellColor: value })} />
                </WithLabel>
            </ControlBar>
        );
    }
}

export class CalendarCellSelection extends BaseElementSelection {
    renderCustomChildren() {
        const { element } = this.props;

        if (!element.showTask) {
            return (
                <FlexBox center middle fill>
                    <RoundIconButton small icon="text_increase" onClick={() => {
                        element.showTaskPlaceholder = true;
                        element.updateModel({ task: { blocks: [{ type: "text", html: "" }] } });
                    }
                    } />
                </FlexBox>
            );
        }
    }
}

export class CalendarTaskTextSelection extends TextElementSelection {
    get canDelete() {
        return true;
    }

    handleDeleteElement = async () => {
        const { element } = this.props;
        PresentationEditorController.selectionLayerController.setSelectedElements([element.parentElement]);
        element.showTaskPlaceholder = false;
        await element.updateModel({ task: null });
    }
}

export class WeekCalenderVerticalTaskSelection extends BaseElementSelection {

}

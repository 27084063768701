import React, { Component } from "react";

import { app } from "../../../../../../../namespaces";

import CheckBox from "../../../../../../../react/components/CheckBox";
import { PopupMenu, PopupMenuPaddedContainer } from "../../../../../../../react/components/PopupMenu";
import { themeColors } from "../../../../../../../react/sharedStyles";
import { $ } from "../../../../../../../vendor";

import { AuthoringCanvasControlBar, ControlBarGroup } from "../../AuthoringCanvasControlBar";
import { BoundsBox, ReverseScaleBox } from "../../SelectionBox";
import { BlockTypePopupMenu } from "./TextFormatBar";

export class ElementBlockEditor extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isEditing: false
        };
    }

    componentDidMount() {
        const { block } = this.props;

        this.setState({ isEditing: true });

        block.setState({ isEditing: true });
    }

    componentWillUnmount() {
        this.setState({ isEditing: false });
    }

    handleEdit = () => {
        this.setState({ isEditing: true });
    }

    handleChangeElementType = type => {
        const { element, block } = this.props;

        block.model.elementModel = {
            elementType: type
        };

        element.canvas.updateCanvasModel();
    }

    handleChangeBackgroundColor = color => {
        const { element, block } = this.props;

        block.model.backgroundColor = color;

        element.canvas.updateCanvasModel();
    }

    handleChangeBlockStyle = (style, color) => {
        const { element, block } = this.props;

        block.model.blockStyle = style;
        block.model.blockColor = color;

        element.canvas.updateCanvasModel();
    }

    handleResizeHandleMouseDown = event => {
        const { block, refreshCanvasAndSaveChanges, refreshElement } = this.props;

        event.stopPropagation();

        const top = block.element.DOMNode.getBoundingClientRect().y;

        app.isDragging = true;

        $(document).on("mousemove.resize", event => {
            event.stopPropagation();
            const height = (event.pageY - top) / app.currentCanvas.getScale();
            block.model.blockHeight = Math.clamp(height, 50, 1000);
            refreshElement();
        });

        $(document).on("mouseup.resize", event => {
            app.isDragging = false;
            event.stopPropagation();
            $(document).off(".resize");
            refreshCanvasAndSaveChanges();
        });
    }

    handleToggleFullBleed = value => {
        const { block, refreshCanvasAndSaveChanges } = this.props;
        block.model.fullBleed = value;
        refreshCanvasAndSaveChanges();
    }

    render() {
        const { bounds, block, editorConfig, refreshCanvasAndSaveChanges, element } = this.props;
        const { isEditing } = this.state;

        return (
            <BoundsBox bounds={bounds} className="element-block-editor-container">
                <AuthoringCanvasControlBar position="above" offset={10}>
                    <ControlBarGroup color={themeColors.darkBlue}>
                        <BlockTypePopupMenu
                            selectedBlocks={[block]}
                            allowedBlockTypes={editorConfig.allowedBlockTypes}
                            onChange={refreshCanvasAndSaveChanges}
                        />
                    </ControlBarGroup>
                    <ControlBarGroup>
                        <PopupMenu icon="settings">
                            <PopupMenuPaddedContainer>
                                <CheckBox label="Full Bleed" checked={block.model.fullBleed ?? false} onChange={this.handleToggleFullBleed} />
                            </PopupMenuPaddedContainer>
                        </PopupMenu>
                    </ControlBarGroup>
                </AuthoringCanvasControlBar>
                {/* inner div reverse scales contenteditable so that selection layer coordinate-space matches canvas */}
                <ReverseScaleBox
                    bounds={bounds}
                    canvasScale={element.canvas.getScale()}
                    style={{ pointerEvents: isEditing ? "none" : "auto" }}
                >
                </ReverseScaleBox>
            </BoundsBox>
        );
    }
}

import React, { Component } from "react";
import { Button } from "../../../../Components/Button";
import { Popup, PopupContent, PopupPreview } from "../../../../Components/Popup";
import { Icon } from "../../../../Components/Icon";

export class AddItemButton extends Component {
    render() {
        const { label = "Add Item", icon = "add_circle", showDropdown, dropDownMenu, minItems = 1, maxItems = 8, disabled, onClick } = this.props;

        if (showDropdown) {
            return (
                <Popup label={label} icon={icon} showArrow>
                    <PopupContent>
                        {this.props.children}
                    </PopupContent>
                </Popup>
            );
        } else {
            return (
                <Button blue onClick={onClick}>
                    <Icon>{icon}</Icon>
                    {label}
                </Button>
            );
        }
    }
}


import { $ } from "js/vendor";
import { ShowDialog } from "js/react/components/Dialogs/BaseDialog";
import { AddLinkDialog } from "./AddLinkDialog";
import { sanitizeHtml } from "js/core/utilities/dompurify";

export const addLink = ({ block, refreshCanvasAndSaveChanges, updateHtml, onClose = ()=>{} })=>{
    const selection = window.getSelection();
    if (selection.type === "Caret") {
        // Selecting current word
        selection.modify("extend", "forward", "word");
        selection.collapseToEnd();
        selection.modify("extend", "backward", "word");
    } else if (selection.type === "None") {
        // Selecting all text
        selection.selectAllChildren(block.ref.current);
    }

    const range = selection.getRangeAt(0);

    // If a link has a color it is wrapped in a <font> tag so we want to get the href from the <a> tag
    // leaving the other option as a fallback
    const link = $(range.startContainer)?.closest("a")?.attr("href") ??
        range.startContainer.parentNode.getAttribute("href");

    const isBaiLink = link?.startsWith("bai://") ?? false;

    ShowDialog(AddLinkDialog, {
        value: isBaiLink ? "" : link,
        selectedSlide: isBaiLink ? link.replace("bai://", "") : block.model.linkToSlide,
        selectedBlocks: [block],
        linkToSlideAsLink: block.element.linkToSlideAsLink ?? true,
        onRemoveLink: () => {
            selection.removeAllRanges();
            selection.addRange(range);
            document.execCommand("unlink");

            block.model.linkToSlide = undefined;
            refreshCanvasAndSaveChanges();
        },
        onSetLink: value => {
            selection.removeAllRanges();
            selection.addRange(range);

            // Create a new anchor (a) element
            const link = document.createElement("a");
            link.href = value;

            if (range.startContainer.parentElement.tagName === "FONT") {
                // If the start container is a font tag, we need to wrap the link in a font tag
                const font = document.createElement("font");
                font.setAttribute("class", range.startContainer.parentElement.className);
                font.textContent = range.toString();

                range.startContainer.parentElement.remove();

                link.appendChild(font);
            } else {
                // Set the selected text as the link's text content
                link.textContent = range.toString();
            }

            // Insert the link at the current selection
            range.deleteContents();
            range.insertNode(link);

            block.model.linkToSlide = undefined;
            updateHtml(sanitizeHtml(block.ref.current.innerHTML));
            refreshCanvasAndSaveChanges();
        },
        onSetSlide: slideId => {
            selection.removeAllRanges();
            selection.addRange(range);
            document.execCommand("unlink");

            block.model.linkToSlide = slideId;
            refreshCanvasAndSaveChanges();
        },
        onClose: onClose
    });
};

import React, { Component } from "react";
import styled from "styled-components";
import { PropertySection } from "../../../../../EditorComponents/PropertyPanel";
import { FlexBox } from "../../../../../react/components/LayoutGrid";
import { WithLabel } from "../../../../../Components/WithLabel";

const DecorationStyleChit = styled.div`
    width: 20px;
    height: 20px;
    cursor: pointer;
`;

const OutlinedDecoration = styled(DecorationStyleChit)`
    border: solid 2px ${props => props.color.setAlpha(1).toRgbString()};
    background: none;
`;

const MutedDecoration = styled(DecorationStyleChit)`
    background: ${props => props.color.setAlpha(0.3).toRgbString()};
`;

const FilledDecoration = styled(DecorationStyleChit)`
    background: ${props => props.color.setAlpha(1).toRgbString()};
`;

const FillAndStrokeDecoration = styled(DecorationStyleChit)`
    background: ${props => props.color.setAlpha(0.3).toRgbString()};
    border: solid 2px ${props => props.color.setAlpha(1).toRgbString()};
`;

const DecorationButton = styled.div`
    cursor: pointer;
`;

export class DecorationStyles extends Component {
    render() {
        const { color, canvas, onChange, allowedDecorationStyles = ["outlined", "muted", "filled", "fillAndStroke"] } = this.props;
        if (color == "none") {
            return null;
        }

        const theme = canvas.getTheme();
        let clr = theme.palette.getColor(color ?? "white");

        const decorationStyles = {
            "outlined": {
                onClick: () => onChange("outlined"),
                label: "Outlined",
                icon: <OutlinedDecoration color={clr} />
            },
            "muted": {
                onClick: () => onChange("muted"),
                label: "Muted",
                icon: <MutedDecoration color={clr} />
            },
            "filled": {
                onClick: () => onChange("filled"),
                label: "Filled",
                icon: <FilledDecoration color={clr} />
            },
            "fillAndStroke": {
                onClick: () => onChange("fillAndStroke"),
                label: "Fill & Stroke",
                icon: <FillAndStrokeDecoration color={clr} />
            }
        };

        return (
            <PropertySection>
                <FlexBox gap={20} left>
                    {allowedDecorationStyles.map(style => {
                        const { onClick, label, icon } = decorationStyles[style];
                        return (
                            <DecorationButton key={style} onClick={onClick}>
                                <WithLabel label={label} right small tight>
                                    {icon}
                                </WithLabel>
                            </DecorationButton>
                        );
                    })}
                </FlexBox>
            </PropertySection>

        );
    }
}


const BaseSlideTemplate = require("./BaseSlideTemplate");
const {
    obsoleteTemplates,
    devTemplates,
    classicTemplates,
    variations,
    templates,
    chartUtils,
    tableUtils
} = require("./templatesData");

function getTemplate(instanceProps, staticProps) {
    class SlideTemplate extends BaseSlideTemplate {
        constructor() {
            super();
            Object.assign(this, instanceProps);
        }
    }
    Object.assign(SlideTemplate, staticProps);
    return SlideTemplate;
}

function getTemplates(isDevelopment) {
    const slideTemplates = {};

    templates.forEach(({ getInstanceProps, getStaticProps }) => {
        const instanceProps = getInstanceProps();
        const staticProps = getStaticProps();
        slideTemplates[staticProps.id] = getTemplate(instanceProps, { ...staticProps, isSmart: true });
    });

    devTemplates.forEach(({ getInstanceProps, getStaticProps }) => {
        const instanceProps = getInstanceProps();
        const staticProps = getStaticProps();

        if (isDevelopment) {
            slideTemplates[staticProps.id] = getTemplate(instanceProps, { ...staticProps, isSmart: true, isDev: true });
        } else if (staticProps.isComingSoon) {
            slideTemplates[staticProps.id] = getTemplate(instanceProps, staticProps);
        }
    });

    obsoleteTemplates.forEach(({ getInstanceProps, getStaticProps }) => {
        const instanceProps = getInstanceProps();
        const staticProps = getStaticProps();
        slideTemplates[staticProps.id] = getTemplate(instanceProps, { ...staticProps, isSmart: true });
    });

    return slideTemplates;
}

module.exports = { templates, getTemplates, variations, chartUtils, classicTemplates, tableUtils };

import React, { Component } from "react";
import { createPortal } from "react-dom";
import { Button } from "js/Components/Button";
import { Icon } from "js/Components/Icon";
import { ContentItemSelection } from "../ElementSelections/ContentItemSelection";
import { ControlBar } from "../ElementControlBars/Components/ControlBar";
import { VideoRecorder } from "../../elements/VideoOverlay/VideoOverlayRecorder";

export class VideoOverlaySelection extends ContentItemSelection {
    get canResize() {
        return false;
    }

    get canDelete() {
        return true;
    }

    get canDrag() {
        return true;
    }

    get dragPositionProps() {
        const { element, canvas } = this.props;
        let dragStartProps;

        return {
            ...super.dragPositionProps,
            onDragStart: async () => {
                const parentSize = element.parentElement.calculatedProps.size.clone();
                dragStartProps = {
                    x: element.model.x,
                    y: element.model.y,
                    parentSize,
                    elementSize: element.bounds
                };
            },
            onDrag: async ({ dragOffset }) => {
                const { x, y, parentSize, elementSize } = dragStartProps;

                // Calculate the maximum allowed position considering element size
                const maxX = 1 - (elementSize.width / parentSize.width);
                const maxY = 1 - (elementSize.height / parentSize.height);

                const horizontalShift = dragOffset.x / parentSize.width;
                const verticalShift = dragOffset.y / parentSize.height;

                // Clamp the position between 0 and max values
                element.model.x = Math.clamp(x + horizontalShift, 0, maxX);
                element.model.y = Math.clamp(y + verticalShift, 0, maxY);
                element.model.hasBeenDragged = true;

                if (element.model.snapOptions) {
                    delete element.model.snapOptions;
                }

                if (element.parentElement.canRefreshElement) {
                    element.parentElement.refreshElement();
                } else {
                    await canvas.refreshCanvas();
                }
            }
        };
    }

    handleDeleteElement = async () => {
        const { element } = this.props;

        element.canvas.getCanvasElement().model.elements.videoOverlay = null;
        await element.canvas.getCanvasElement().saveModel();
    };
}

export class VideoOverlayControlBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showRecorder: false
        };

        this.recorderContainerElement = null;
    }

    componentDidMount() {
        this.recorderContainerElement = document.createElement("div");
        document.body.appendChild(this.recorderContainerElement);
    }

    componentWillUnmount() {
        this.recorderContainerElement.remove();
    }

    handleOpenRecorder = () => {
        this.setState({ showRecorder: true });
    }

    handleCloseRecorder = () => {
        this.setState({ showRecorder: false });
    }

    render() {
        const { showRecorder } = this.state;

        return (<>
            <ControlBar>
                {!showRecorder ? <Button blue small onClick={this.handleOpenRecorder}>
                    <Icon>video_camera_front</Icon>
                    Add Recording
                </Button>
                    : createPortal(
                        <VideoRecorder
                            {...this.props}
                            onClose={this.handleCloseRecorder}
                        />,
                        this.recorderContainerElement
                    )}
            </ControlBar>

        </>);
    }
}

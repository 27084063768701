import React from "react";
import styled from "styled-components";
import { v4 as uuid } from "uuid";

import {
    AuthoringBlockType,
    HorizontalAlignType,
    TextStyleType
} from "../../../../../../../common/constants";
import { $ } from "../../../../../../vendor";

import { BoundsBox } from "../SelectionBox";
import { AuthoringBaseEditor } from "./AuthoringBaseEditor";
import { ConvertScreenToSelectionLayer } from "./AuthoringHelpers";

const AdjustmentContainer = styled.div.attrs(({ bounds }) => ({
    style: {
        ...bounds.toObject()
    }
}))`
  position: absolute;
  pointer-events: none;
  z-index: 100;
`;

const AdjustmentHandle = styled.div.attrs(({ point }) => ({
    style: {
        left: point.x - 4,
        top: point.y - 4
    }
}))`
  position: absolute;
  background: gold;
  border: solid 1px #333;
  width: 8px;
  height: 8px;
  cursor: pointer;
  pointer-events: auto;
  &:hover {
    background: goldenrod;
  } 
`;

export class AuthoringShapeEditor extends AuthoringBaseEditor {
    constructor(props) {
        super(props);

        this.controlBarRef = React.createRef();
        this.blockEditorRef = React.createRef();

        this.state = {
            fill: null,
            stroke: null,
            strokeWidth: null,
            strokeStyle: null,
            opacity: null,
            cornerRadius: null,
            adjustmentHandles: props.selection[0].childElement.getAdjustmentHandles() ?? []
        };
    }

    setSelectionState = () => {
        super.setSelectionState();

        this.setState({
            fill: this.getElementValue("fill"),
            stroke: this.getElementValue("stroke"),
            strokeWidth: this.getElementValue("strokeWidth"),
            strokeStyle: this.getElementValue("strokeStyle"),
            cornerRadius: this.getElementValue("cornerRadius"),
            textAlign: this.getElementValue("textAlign"),
            verticalAlign: this.getElementValue("verticalAlign"),
            textInset: this.getElementValue("textInset"),
            fitToText: this.getElementValue("fitToText"),
            direction: this.getElementValue("direction")
        });
    }

    handleAdjustmentHandleMouseDown = (event, handle) => {
        const element = this.props.selection[0];

        $(document).on("mousemove.drag", event => {
            event.stopPropagation();

            const offset = ConvertScreenToSelectionLayer(event.pageX, event.pageY).multiply(1 / element.canvas.getScale());
            handle.setPosition(Math.round(offset.x - element.model.x), Math.round(offset.y - element.model.y));

            element.refreshElement(false);
            this.forceUpdate();
        });

        $(document).on("mouseup.drag", () => {
            $(document).off(".drag");
            element.canvas.saveCanvasModel();
        });
    }

    async createInitialTextBlock() {
        const { selection, selectionLayerController } = this.props;

        const element = selection[0];

        const blockId = uuid();
        element.model.text.blocks.push({
            id: blockId,
            type: AuthoringBlockType.TEXT,
            textStyle: TextStyleType.TITLE,
            textAlign: HorizontalAlignType.CENTER,
            html: ""
        });

        element.canvas.refreshCanvas().then(() => {
            selectionLayerController.selectTextElementBlock(element.childElement.text, blockId);
        });
    }

    render() {
        const { bounds, selection, containerElement } = this.props;

        const isLocked = selection.some(element => element.isLocked);
        const canReshape = selection.length === 1 && selection[0].canReshape;

        let adjustmentHandles = [];
        if (selection.length === 1) {
            adjustmentHandles = selection[0].childElement.getAdjustmentHandles() || [];
        }

        return (
            <BoundsBox bounds={bounds.zeroOffset()}>
                {!isLocked && canReshape &&
                    <AdjustmentContainer bounds={bounds.zeroOffset()}>
                        {adjustmentHandles.map((adj, index) => (
                            <AdjustmentHandle key={index}
                                className="adj-handle"
                                point={adj.getPosition().multiply(containerElement.canvas.getScale())}
                                onMouseDown={event => this.handleAdjustmentHandleMouseDown(event, adj)}
                            />
                        ))}
                    </AdjustmentContainer>
                }
            </BoundsBox>
        );
    }
}

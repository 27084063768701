import React, { Component } from "react";
import { ImagePopup } from "../../../../../Components/ImagePopup";
import { ImageOption } from "../../../../../Components/ImageOptionList";
import { StaticImage } from "../../../../../Components/StaticImage";

export class DecorationStylePicker extends Component {
    render() {
        const { element, propertyName = "decorationStyle" } = this.props;

        let value;
        let onChange;
        if (element.length) {
            value = element[0].model[propertyName];
            onChange = value => element.forEach(e => e.updateModel({ [propertyName]: value }, { refreshStyles: true }));
        } else {
            value = element.model[propertyName];
            onChange = value => element.updateModel({ [propertyName]: value }, { refreshStyles: true });
        }

        return (
            <ImagePopup value={value}
                previewSize={30} size={50}
                onChange={onChange}
            >
                <ImageOption value="outlined" label="Outline">
                    <StaticImage src="/images/theme_editor/Shape-Outline.svg" style={{ background: "#ddd" }} />
                </ImageOption>
                <ImageOption value="muted" label="Muted">
                    <StaticImage src="/images/theme_editor/Shape-Muted.svg" style={{ background: "#ddd" }} />
                </ImageOption>
                <ImageOption value="filled" label="Filled">
                    <StaticImage src="/images/theme_editor/Shape-Filled.svg" style={{ background: "#ddd" }} />
                </ImageOption>
                <ImageOption value="fillAndStroke" label="Fill & Stroke">
                    <StaticImage src="/images/theme_editor/Shape-Fill-Stroke.svg" style={{ background: "#ddd" }} />
                </ImageOption>
            </ImagePopup>
        );
    }
}

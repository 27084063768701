import { v4 as uuid } from "uuid";

import { _ } from "js/vendor";
import { AuthoringBlockType, PaletteColorType, TextStyleType } from "common/constants";

export const InfographicElementTypes = {
    TEXT_AND_IMAGE: "TextAndImage",
    RADIAL_BAR: "RadialBar",
    CHART: "Chart",
    PIE_CHART: "PieChart",
    TABLE: "Table",
    STATISTIC: "Statistic",
    RADIAL_BAR_CHART: "RadialBarChart",
    CANVAS: "Canvas"
};

export const InfographicElements = [
    {
        id: "chart",
        label: "Chart",
        icon: "/images/infographics/dashboard-chart-line.svg",
        getDefaultModel: (element, series) => {
            let componentType = InfographicElementTypes.CHART;
            let chartModel = null;
            switch (series) {
                case "pie":
                    chartModel = element.canvas.chartUtils.getDefaultPieChartModel(false);
                    componentType = InfographicElementTypes.PIE_CHART;
                    break;
                case "donut":
                    chartModel = element.canvas.chartUtils.getDefaultPieChartModel(true);
                    componentType = InfographicElementTypes.PIE_CHART;
                    break;
                case "waterfall":
                    chartModel = element.canvas.chartUtils.getDefaultWaterfallChartModel();
                    break;
                default:
                    chartModel = element.canvas.chartUtils.getDefaultChartModel(series ?? "line");
                    break;
            }

            const newModel = {
                componentType,
                childElement: {
                    ..._.cloneDeep(element.model.childElement ?? {}),
                    annotations: null,
                    ...chartModel
                }
            };

            if (element.childElement?.isInstanceOf("PieChart") && newModel.childElement.chartData) {
                newModel.childElement.chartData.xAxis.categories = [];
                newModel.childElement.chartData.series[0].data = [];
                for (const { label, value } of element.childElement.model.data) {
                    const text = label.blocks
                        .map(block => block.html)
                        .join(" ");
                    newModel.childElement.chartData.xAxis.categories.push(text);
                    newModel.childElement.chartData.series[0].data.push({ y: value, pt: true });
                }
            }

            return newModel;
        },
        forceClearLayouter: true
    },
    {
        id: "statistic",
        label: "Statistic",
        icon: "/images/infographics/dashboard-stat.svg",
        getDefaultModel: element => {
            const newModel = {
                componentType: InfographicElementTypes.STATISTIC,
                cellColor: element.model.cellColor ?? PaletteColorType.BACKGROUND_ACCENT,
                showTitle: "above",
                chartTitle: {
                    textAlign: "left",
                    chartTitle: {
                        blocks: [{
                            id: uuid(),
                            html: "My Statistic",
                            type: AuthoringBlockType.TEXT,
                            textStyle: "_use_styles_"
                        }]
                    }
                },
                childElement: {
                    showChangeInValue: true,
                    showNote: true,
                    horizontalAlign: "left",
                    label: {
                        text: "Statistic"
                    },
                    value: {
                        text: "$50,000"
                    },
                    changeLabel: {
                        text: "100%"
                    },
                    note: {
                        text: "vs last month"
                    },
                    positive: true
                }
            };

            return newModel;
        }
    },
    {
        id: "radial_bar",
        label: "Radial",
        icon: "/images/infographics/dashboard-radial.svg",
        getDefaultModel: element => ({
            componentType: InfographicElementTypes.RADIAL_BAR,
            cellColor: element.model.cellColor ?? PaletteColorType.BACKGROUND_ACCENT,
            showTitle: false,
            childElement: {}
        })
    },
    {
        id: "text",
        label: "Text",
        icon: "/images/infographics/dashboard-text.svg",
        getDefaultModel: element => {
            const elementModel = _.cloneDeep(element.model);

            const newModel = {
                ...elementModel,
                componentType: InfographicElementTypes.TEXT_AND_IMAGE,
                cellColor: element.model.cellColor ?? PaletteColorType.BACKGROUND_ACCENT,
                showTitle: false
            };

            if (!newModel.childElement?.text?.blocks?.length) {
                newModel.childElement = {
                    ...(newModel.childElement ?? {}),
                    text: {
                        blocks: [{
                            id: uuid(),
                            html: "",
                            type: AuthoringBlockType.TEXT,
                            textStyle: TextStyleType.HEADING
                        }]
                    }
                };
            }

            return newModel;
        },
    },
    {
        id: "image",
        label: "Image",
        icon: "/images/infographics/dashboard-image.svg",
        getDefaultModel: element => ({
            componentType: InfographicElementTypes.TEXT_AND_IMAGE,
            cellColor: element.model.cellColor ?? PaletteColorType.BACKGROUND_ACCENT,
            showTitle: false,
            childElement: {
                ..._.cloneDeep(element.model.childElement),
                text: null
            }
        })
    },
    {
        id: "table",
        label: "Table",
        icon: "/images/infographics/dashboard-table.svg",
        getDefaultModel: element => ({
            componentType: InfographicElementTypes.TABLE,
            showTitle: false,
            childElement: {}
        })
    },
    {
        id: "radial_bar_chart",
        label: "Radial Bar Chart",
        icon: "/images/infographics/dashboard-radial-bar-chart.svg",
        getDefaultModel: element => ({
            componentType: InfographicElementTypes.RADIAL_BAR_CHART,
            cellColor: element.model.cellColor ?? PaletteColorType.BACKGROUND_ACCENT,
            showTitle: false,
            childElement: {
                items: [{
                    value: 100,
                }, {
                    value: 75,
                }, {
                    value: 50,
                }]
            }
        })
    },
    {
        id: "canvas",
        label: "Canvas",
        icon: "/images/infographics/dashboard-canvas.svg",
        getDefaultModel: element => ({
            componentType: InfographicElementTypes.CANVAS,
            cellColor: element.model.cellColor,
            showTitle: false,
            childElement: {}
        })
    }
];

import React from "react";
import styled from "styled-components";
import InfoToolTip from "js/react/components/InfoToolTip";
import { Gap10, Gap5 } from "js/react/components/Gap";
import { Icon } from "@material-ui/core";
import { ToolTipBadge } from "./ToolTip";

const SectionLabelContainer = styled.label`
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #333;
  font-weight: 600;
  text-transform: uppercase;
    
    .popupmenu-icon {
        font-size: 16px;
    }
`;

export function SectionLabel({ children, tooltip }) {
    return (
        <SectionLabelContainer>
            {children}
            {tooltip &&
                <>
                    <Gap10/>
                    <ToolTipBadge>{tooltip}</ToolTipBadge>
                </>
            }
        </SectionLabelContainer>
    );
}

const WarningLabelContainer = styled.div`
    display: flex;
    align-items: center;
    font-size: 14px;
    color: orangered;
    font-weight: 600;
`;

export function WarningLabel({ children, showIcon = true, tooltip }) {
    return (
        <WarningLabelContainer>
            {showIcon && <Icon>warning</Icon>}
            {showIcon && <Gap10/>}
            {children}
            {tooltip &&
                <>
                    <Gap10/>
                    <InfoToolTip title={tooltip} />
                </>
            }
        </WarningLabelContainer>
    );
}


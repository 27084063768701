import React, { Component } from "react";
import { PropertyPanelContainer, PropertySection } from "../../../../EditorComponents/PropertyPanel";
import { WithLabel } from "../../../../Components/WithLabel";
import { ToggleSwitch } from "../../../../Components/ToggleSwitch";
import { Dropdown } from "../../../../Components/Dropdown";
import { MenuItem } from "../../../../Components/Menu";
import { DatePicker } from "../../../../Components/DatePicker";
import moment from "moment/moment";
import { CollectionColorPicker } from "../EditorComponents/ColorPickers/CollectionColorPicker";

export class CountdownPropertyPanel extends Component {
    shouldComponentUpdate(newProps) {
        // comporing timestamp to check if the element is changed,
        // otherwise it will keep updating because the element is always a new object
        return newProps.element.timestamp !== this.props.element.timestamp;
    }
    render() {
        const { element } = this.props;

        return (
            <PropertyPanelContainer>
                <PropertySection>
                    <WithLabel label="Color">
                        <CollectionColorPicker element={element} />
                        {/*<ColorPicker value={element.model.color} canvas={element.canvas} showPrimary*/}
                        {/*    onChange={color => element.updateModel({ color }, { refreshStyles: true })}*/}
                        {/*/>*/}
                    </WithLabel>
                </PropertySection>
                <PropertySection>
                    <WithLabel label="Count">
                        <Dropdown value={element.model.direction || "down"}
                            onChange={value => {
                                element.updateModel({ direction: value });
                                element.resetCountdownDisplay();
                            }}
                        >
                            <MenuItem value="down">Down To Target</MenuItem>
                            <MenuItem value="up">Up From Target</MenuItem>
                        </Dropdown>
                    </WithLabel>
                    <WithLabel label="Target">
                        <DatePicker
                            value={new Date(element.timestamp)}
                            onChange={value => {
                                const date = moment(value).format("MM/DD/YYYY");
                                const time = moment(value).format("hh:mm A");

                                element.updateModel({ date, time });
                            }}
                            showTimeSelect
                            dateFormat="MMM d yyyy h:mm:ss aa"
                            onCalendarOpen={() => {
                                element.stopAnimation();
                            }}
                            onCalendarClose={() => {
                                element.resetCountdownDisplay();
                            }}
                        />
                    </WithLabel>
                </PropertySection>
                <PropertySection>
                    <WithLabel label="Show Seconds">
                        <ToggleSwitch value={element.model.showSeconds}
                            onChange={value => element.updateModel({ showSeconds: value })}
                        />
                    </WithLabel>
                </PropertySection>
            </PropertyPanelContainer>
        );
    }
}


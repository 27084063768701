import React, { Component } from "react";
import { PropertyPanelContainer, PropertySection } from "../../../../EditorComponents/PropertyPanel";
import { WithLabel } from "../../../../Components/WithLabel";
import { ToggleSwitch } from "../../../../Components/ToggleSwitch";
import { CollectionElementControlBar } from "../ElementControlBars/CollectionElementControlBar";
import { ControlBar } from "../ElementControlBars/Components/ControlBar";
import { MediaPopup } from "../EditorComponents/MediaPopup";

export class LogoGridPropertyPanel extends Component {
    render() {
        const { element } = this.props;

        return (
            <PropertyPanelContainer>
                <PropertySection>
                    <WithLabel label="Show Frame">
                        <ToggleSwitch value={element.model.showFrame}
                            onChange={value => element.updateModel({ showFrame: value })} />
                    </WithLabel>
                </PropertySection>
            </PropertyPanelContainer>
        );
    }
}

export class LogoGridItemControlBar extends Component {
    render() {
        const { element } = this.props;
        return (
            <ControlBar>
                <MediaPopup element={element.logo} />
            </ControlBar>
        );
    }
}

export class LogoGridControlBar extends CollectionElementControlBar {
    get addItemLabel() {
        return "Add Logo";
    }
}


import React, { Component } from "react";

import { PropertyPanelContainer, PropertySection, PropertySectionHeader } from "../../../EditorComponents/PropertyPanel";
import { WithLabel } from "../../../Components/WithLabel";
import { AssetType, BackgroundStyleType, ForeColorType, PaletteColorType } from "../../../../common/constants";
import { Popup, PopupContent, PopupPreview } from "../../../Components/Popup";
import { FlexBox, GridBox } from "../../../react/components/LayoutGrid";
import { Gap10 } from "../../../react/components/Gap";
import { ColorChit, ThemeColorChit } from "../../../Components/ColorChit";
import Logos from "../../../core/models/logos";
import { getLogoPath } from "../../../../common/assetUtils";
import { ImageLoader } from "../../../Components/ImageLoader";
import { MenuItem } from "@material-ui/core";
import { ShowAssetDialog } from "../../../react/views/AddAssets/ShowAssetDialog";
import { Box } from "../../../Components/Box";
import { SectionLabel } from "../../../Components/Labels";
import { PropertyPanelButton } from "../../../EditorComponents/PropertyPanelButton";
import { Gradient } from "js/editor/ThemeEditor/components/Gradient";
import { ShowDialog, ShowWarningDialog } from "../../../react/components/Dialogs/BaseDialog";
import { Icon } from "../../../Components/Icon";
import { IconButton } from "../../../Components/IconButton";
import { FilterDialog } from "../../../react/components/Dialogs/MediaDialog";

const colorsIcon = (<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
    <g fill="none" fillRule="evenodd" transform="rotate(45 12.597 22.99)">
        <circle cx="6.68" cy="6.68" r="6.68" fill="#3E8CE5" />
        <circle cx="6.68" cy="24.188" r="6.68" fill="#FFCD45" />
        <circle cx="24.833" cy="6.68" r="6.68" fill="#38DBB9" />
        <circle cx="24.833" cy="24.188" r="6.68" fill="#E46183" />
    </g>
</svg>);

export class SlideBackgroundColorPicker extends Component {
    render() {
        const { currentCanvasController } = this.props;

        const canvas = currentCanvasController.canvas;
        const theme = canvas.getTheme();

        if (!theme.palette) {
            return null;
        }

        const backgroundLight = theme.palette.getColor(PaletteColorType.BACKGROUND_LIGHT);
        const backgroundDark = theme.palette.getColor(PaletteColorType.BACKGROUND_DARK);
        const backgroundAccent = theme.palette.getColor(PaletteColorType.BACKGROUND_ACCENT);

        const themeColors = theme.palette.getSlideColors();

        const backgroundColor = canvas.model.layout.backgroundColor ?? canvas.layouter.elements.background.canvasBackgroundColorType;

        const themeBackgroundImages = canvas.getTheme().get("backgroundImages") ?? [];
        const backgroundGradients = canvas.getTheme().get("backgroundGradients") ?? [];

        const allowBackgroundImage = canvas.slideTemplate.allowBackgroundImage;
        // const hasBackgroundImage = canvas.layouter.elements.background.canvasBackgroundStyle == BackgroundStyleType.IMAGE;

        const bgImageColorStyle = canvas.model.layout.customBackgroundImage?.colorStyle ?? "light";

        const showFilterButton = (canvas.layouter.canvasElement.background.background.content?.assetType == AssetType.IMAGE);

        return (
            <FlexBox>
                <Popup>
                    <PopupPreview>
                        <ThemeColorChit color={backgroundColor} theme={theme} size={25} />
                    </PopupPreview>
                    <PopupContent>
                        {closePopup => (
                            <PropertyPanelContainer>
                                <PropertySection>
                                    <FlexBox left gap={5} middle>
                                        <SectionLabel tooltip={
                                            <>
                                                <p>The slide background can be set to a solid color or an image background if defined in your theme. Additionally, you can upload or select an image to use as a background for this slide.</p>
                                                <p>NOTE: Depending on your background, certain other color options for foreground elements (like shapes and text) may be limited.</p>
                                            </>
                                        }
                                        >Slide Background</SectionLabel>
                                    </FlexBox>
                                    <FlexBox gap={15} left>
                                        <WithLabel small below center label="Light">
                                            <ColorChit color={backgroundLight} size={35}
                                                onClick={() => {
                                                    if (canvas.model.layout.backgroundStyle == BackgroundStyleType.COLOR && canvas.model.layout.slideColor == ForeColorType.NEUTRAL) {
                                                        canvas.model.layout.slideColor = PaletteColorType.THEME;
                                                    }
                                                    canvas.model.layout.backgroundStyle = BackgroundStyleType.LIGHT;
                                                    canvas.model.layout.backgroundColor = PaletteColorType.BACKGROUND_LIGHT;
                                                    canvas.model.layout.customBackgroundImage = undefined;
                                                    canvas.markStylesAsDirty();
                                                    canvas.updateCanvasModel();
                                                    closePopup();
                                                }} />
                                        </WithLabel>
                                        <WithLabel small below center label="Dark">
                                            <ColorChit color={backgroundDark} size={35}
                                                onClick={() => {
                                                    if (canvas.model.layout.backgroundStyle == BackgroundStyleType.COLOR && canvas.model.layout.slideColor == ForeColorType.NEUTRAL) {
                                                        canvas.model.layout.slideColor = PaletteColorType.THEME;
                                                    }
                                                    canvas.model.layout.backgroundStyle = BackgroundStyleType.DARK;
                                                    canvas.model.layout.backgroundColor = PaletteColorType.BACKGROUND_DARK;
                                                    canvas.model.layout.customBackgroundImage = undefined;
                                                    canvas.markStylesAsDirty();
                                                    canvas.updateCanvasModel();
                                                    closePopup();
                                                }} />
                                        </WithLabel>
                                        <WithLabel small below center label="Accent">
                                            <ColorChit color={backgroundAccent} size={35}
                                                onClick={() => {
                                                    if (canvas.model.layout.backgroundStyle == BackgroundStyleType.COLOR && canvas.model.layout.slideColor == ForeColorType.NEUTRAL) {
                                                        canvas.model.layout.slideColor = PaletteColorType.THEME;
                                                    }
                                                    canvas.model.layout.backgroundStyle = BackgroundStyleType.ACCENT;
                                                    canvas.model.layout.backgroundColor = PaletteColorType.BACKGROUND_ACCENT;
                                                    canvas.model.layout.customBackgroundImage = undefined;
                                                    canvas.markStylesAsDirty();
                                                    canvas.updateCanvasModel();
                                                    closePopup();
                                                }} />
                                        </WithLabel>

                                        <Gap10 />
                                        <WithLabel small below left label="Theme Colors">
                                            <GridBox columns={6} gap={5}>
                                                {Object.keys(themeColors).map(color => (<ColorChit key={color} color={themeColors[color]}
                                                    size={35}
                                                    onClick={() => {
                                                        canvas.model.layout.backgroundStyle = BackgroundStyleType.COLOR;
                                                        canvas.model.layout.backgroundColor = color;
                                                        canvas.model.layout.customBackgroundImage = undefined;
                                                        canvas.model.layout.slideColor = ForeColorType.NEUTRAL;
                                                        canvas.markStylesAsDirty();
                                                        canvas.updateCanvasModel();
                                                        closePopup();
                                                    }} />))}
                                            </GridBox>
                                        </WithLabel>

                                    </FlexBox>
                                </PropertySection>
                                {(backgroundGradients.length > 0 || (allowBackgroundImage && themeBackgroundImages.length > 0)) && (
                                    <PropertySection>
                                        {/*<PropertySectionHeader label="Gradients" />*/}
                                        <GridBox left middle gap={10} template="repeat(5, 100px)">
                                            {backgroundGradients.map((backgroundGradient, index) => {
                                                const handleBoxClick = () => {
                                                    const { layout } = canvas.model;
                                                    layout.backgroundStyle = BackgroundStyleType.GRADIENT;
                                                    layout.backgroundColor = backgroundGradient.id;
                                                    layout.customBackgroundImage = undefined;
                                                    canvas.markStylesAsDirty();
                                                    canvas.updateCanvasModel();
                                                    closePopup();
                                                };

                                                return (
                                                    <Box
                                                        key={index}
                                                        width={100}
                                                        height={56}
                                                        style={{ position: "relative" }}
                                                        onClick={handleBoxClick}
                                                    >
                                                        <Gradient gradient={backgroundGradient} />
                                                    </Box>
                                                );
                                            })}
                                            {themeBackgroundImages.map(backgroundImage => (
                                                <Box width={100} height={56} style={{ position: "relative" }}>
                                                    <ImageLoader key={backgroundImage.asset}
                                                        url={() => Logos.getSignedUrlAndLoad(getLogoPath(backgroundImage.asset))}
                                                        style={{ width: "100%", height: "100%", objectFit: "cover" }}
                                                        onClick={() => {
                                                            canvas.model.layout.backgroundStyle = BackgroundStyleType.IMAGE;
                                                            canvas.model.layout.backgroundColor = backgroundImage.id;
                                                            canvas.model.layout.customBackgroundImage = undefined;
                                                            canvas.markStylesAsDirty();
                                                            canvas.updateCanvasModel();
                                                            closePopup();
                                                        }}
                                                    />
                                                </Box>))}
                                        </GridBox>
                                    </PropertySection>
                                )}
                                {allowBackgroundImage &&
                                    <PropertyPanelButton title="Import Background Image..." icon="add_a_photo"
                                        onClick={async () => {
                                            let model = await ShowAssetDialog();

                                            if (model.content_type != AssetType.IMAGE && model.content_type != AssetType.STOCK_VIDEO && model.content_type != AssetType.VIDEO) {
                                                ShowWarningDialog({ title: "Invalid Asset", message: "Please select an image or video asset." });
                                                return;
                                            }

                                            canvas.model.layout.backgroundStyle = BackgroundStyleType.IMAGE;
                                            canvas.model.layout.backgroundColor = "custom-asset";
                                            canvas.model.layout.customBackgroundImage = {
                                                ...model,
                                                colorStyle: "light",
                                                isCustomAsset: true
                                            };
                                            await canvas.updateCanvasModel();

                                            closePopup();
                                        }
                                        } />
                                }
                            </PropertyPanelContainer>
                        )}
                    </PopupContent>
                </Popup>
                {allowBackgroundImage && canvas.model.layout.backgroundColor == "custom-asset" &&
                    <>
                        <Gap10 />
                        <Popup onChange={value => {
                            canvas.model.layout.customBackgroundImage.colorStyle = value;
                            canvas.updateCanvasModel(true);
                        }}>
                            <PopupPreview>
                                <ColorChit color={bgImageColorStyle == "light" ? "white" : bgImageColorStyle == "dark" ? "black" : "#11a9e2"} size={20} />
                            </PopupPreview>
                            <PopupContent>
                                {closePopup => (
                                    <>
                                        <MenuItem value={"light"} onClick={() => {
                                            canvas.model.layout.customBackgroundImage.colorStyle = "light";
                                            canvas.updateCanvasModel();
                                            closePopup();
                                        }}>
                                            <ColorChit color="white" size={20} /><Gap10 />Treat Image as Light</MenuItem>

                                        <MenuItem value={"dark"} onClick={() => {
                                            canvas.model.layout.customBackgroundImage.colorStyle = "dark";
                                            canvas.updateCanvasModel();
                                            closePopup();
                                        }}><ColorChit color="black" size={20} /><Gap10 />Treat Image as Dark</MenuItem>
                                        <MenuItem value={"color"} onClick={() => {
                                            canvas.model.layout.customBackgroundImage.colorStyle = "color";
                                            canvas.updateCanvasModel();
                                            closePopup();
                                        }}><ColorChit color="#11a9e2" size={20} /><Gap10 />Treat Image as Color</MenuItem>
                                    </>
                                )}
                            </PopupContent>
                        </Popup>
                        <Gap10/>
                        {showFilterButton &&
                            <IconButton onClick={() => {
                                ShowDialog(FilterDialog, { element: canvas.getCanvasElement().background.background.content.assetElement });
                            }}>
                                <Icon color="#555">instant_mix</Icon>
                            </IconButton>}
                    </>
                }
            </FlexBox>
        );
    }
}

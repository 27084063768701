import anime from "animejs";
import React from "react";

import { CalloutType } from "../../../../../common/constants";
import * as geom from "../../../../core/utilities/geom";
import { Path, Shape } from "../../../../core/utilities/shapes";
import { SVGGroup } from "../../../../core/utilities/svgHelpers";
import { Timeline2ControlBar, Timeline2PropertyPanel } from "../../Editor/ElementPropertyPanels/Timeline2UI";
import { BaseElement } from "../base/BaseElement";
import { CalloutsCanvas } from "./Callouts/CalloutsCanvas";

export class Timeline2 extends BaseElement {
    static get schema() {
        return {
            calloutType: CalloutType.BULLET_TEXT,
            milestones: {
                elements: [],
            }
        };
    }

    getElementPropertyPanel() {
        return Timeline2PropertyPanel;
    }

    getElementControlBar() {
        return Timeline2ControlBar;
    }

    get pathType() {
        return this.model.pathType ?? "journey";
    }

    get pathStyle() {
        return this.model.pathStyle ?? "solid";
    }

    get pathSize() {
        return this.model.pathSize ?? "medium";
    }

    get pathColor() {
        return this.model.pathColor ?? "primary";
    }

    get progress() {
        return this.model.progress ?? 0;
    }

    get progressColor() {
        return this.model.progressColor ?? "theme";
    }

    getAnchorPoint(connector, anchor, sourcePoint) {
        if (!this.flattenedPath) {
            return new geom.Point(0, 0);
        }

        // find the closest point on the path to the source point
        let closestPoint = this.flattenedPath.reduce((acc, point) => {
            let distance = sourcePoint.distance(point);
            if (distance < acc.distance) {
                return { point, distance, position: this.flattenedPath.indexOf(point) };
            }
            return acc;
        }, { point: this.flattenedPath[0], distance: sourcePoint.distance(this.flattenedPath[0]), position: 0 });

        let pt = closestPoint.point;

        // store the position of the closest point on the path as a percentage of the total path length
        pt.position = closestPoint.position / this.flattenedPath.length;

        return pt;
    }

    _build() {
        this.milestones = this.addElement("milestones", () => TimelineMilestones, {
            model: this.model.milestones,
        });
    }

    async setPathType(type) {
        this.model.pathType = type;
        this.flattenedPath = null;
        await this.saveModel();

        this.milestones.resetMilestones();
    }

    getPath(type, size) {
        let path = new Path();

        switch (type) {
            case "straight":
                path.moveTo(0, size.height / 2);
                path.lineTo(size.width, size.height / 2);
                break;
            case "zigzag":
                path.moveTo(0, size.height / 2);
                // path.lineTo(size.width / 3, size.height / 2);
                path.lineTo(size.width / 3 + 50, size.height / 2 - 200);
                // path.lineTo(size.width / 3 * 2, size.height / 2 - 50);
                path.lineTo(size.width / 3 * 2 + 50, size.height / 2 + 200);
                path.lineTo(size.width, size.height / 2);
                break;
            case "boomerang":
                path.moveTo(100, size.height / 2 - 125);
                path.lineTo(size.width - 200, size.height / 2 - 125);
                path.arc(100, 100, 0, 0, 1, size.width - 200, size.height / 2 + 125);
                path.lineTo(100, size.height / 2 + 125);
                break;
            case "circle": // full circle that fits with size height
                path.moveTo(size.width / 2 - 200, size.height / 2);
                path.arc(100, 100, 0, 0, 1, size.width / 2 + 200, size.height / 2);
                path.arc(100, 100, 0, 0, 1, size.width / 2 - 200, size.height / 2);
                break;
            case "ellipse":
                path.moveTo(100, size.height / 2);
                path.arc(100, 30, 0, 0, 1, size.width - 100, size.height / 2);
                path.arc(100, 30, 0, 0, 1, 100, size.height / 2);
                break;
            case "wave":
                path.moveTo(0, size.height / 2);
                path.arc(100, 100, 0, 0, 1, size.width / 3, size.height / 2);
                path.arc(100, 100, 0, 0, 0, size.width / 3 * 2, size.height / 2);
                path.arc(100, 100, 0, 0, 1, size.width, size.height / 2);
                break;
            case "box":
                path.moveTo(0, 100);
                path.lineTo(size.width / 3, 100);
                path.lineTo(size.width / 3, size.height - 100);
                path.lineTo(size.width / 3 * 2, size.height - 100);
                path.lineTo(size.width / 3 * 2, 100);
                path.lineTo(size.width, 100);
                break;

            case "journey":
            default:
                path.moveTo(0, 341);
                path.lineTo(189.667, 341);
                path.curveTo(261.793, 341, 321.566, 285.079, 326.364, 213.113);
                path.lineTo(327.716, 192.825);
                path.curveTo(333.412, 107.389, 404.374, 41, 490, 41);
                path.curveTo(575.626, 41, 646.588, 107.389, 652.284, 192.825);
                path.lineTo(653.659, 213.445);
                path.curveTo(658.444, 285.224, 718.062, 341, 790, 341);
                path.curveTo(861.938, 341, 921.556, 285.224, 926.341, 213.445);
                path.lineTo(927.694, 193.157);
                path.curveTo(933.402, 107.534, 1004.52, 41, 1090.33, 41);

                break;
        }
        return path;
    }

    _calcProps(props, options) {
        const { size } = props;

        let path = this.getPath(this.pathType, size);

        let milestoneProps = this.milestones.calcProps(size);
        milestoneProps.bounds = new geom.Rect(0, 0, size.width, size.height);

        if (this.hasStoredPropChanged("pathType", this.pathType)) {
            this.flattenedPath = null;
        }

        return { size, path };
    }

    _applyColors() {

    }

    renderChildren(transition) {
        let children = super.renderChildren(transition);
        const props = this.calculatedProps;

        this.pathRef = React.createRef();

        let pathColor = this.palette.getColor(this.model.pathColor ?? "primary", this.getBackgroundColor());

        let strokeWidth;
        switch (this.pathSize) {
            case "thin":
                strokeWidth = 5;
                break;
            case "thick":
                strokeWidth = 50;
                break;
            case "medium":
            default:
                strokeWidth = 30;
                break;
        }

        let path;
        switch (this.pathStyle) {
            case "solid":
                path = <path ref={this.pathRef} d={props.path.toPathData()} fill="none" stroke={pathColor.toRgbString()} strokeOpacity={0.15} strokeWidth={strokeWidth} strokeLinecap="round" />;
                break;
            case "dashed":
                path = <path ref={this.pathRef} d={props.path.toPathData()} fill="none" stroke="#eee" strokeWidth={strokeWidth} strokeDasharray="30,15" />;
                break;
            case "dotted":
                path = <path ref={this.pathRef} d={props.path.toPathData()} fill="none" stroke={pathColor.toRgbString()} strokeWidth={2} strokeDasharray="2,5" />;
                break;
            case "road":
                path = (
                    <>
                        <path d={props.path.toPathData()} fill="none" stroke="#eee" strokeWidth={strokeWidth} />
                        <path ref={this.pathRef} d={props.path.toPathData()} fill="none" stroke="#aaa" strokeWidth={2} strokeDasharray="10,5" />
                    </>
                );
                break;
        }

        let progressPath;
        if (this.pathLength) {
            let progressPathLength = (this.animationState.progress ?? 1) * this.progress;
            let progressColor = this.palette.getColor(this.progressColor, pathColor, { allowColorOnColor: true });
            progressPath = (
                <path d={props.path.toPathData()} fill="none" stroke={progressColor.toRgbString()}
                    strokeWidth={15} strokeLinecap="round"
                    strokeDasharray={this.pathLength}
                    strokeDashoffset={this.pathLength * (1 - progressPathLength)}
                />
            );
        }

        children.insert(
            <SVGGroup ref={this.ref} key={this.id}>
                {path}
                {progressPath}
            </SVGGroup>
            , 0);

        if (!this.flattenedPath) {
            this.canvas.layouter.runPostRender(() => this.flattenPath());
        }

        return children;
    }

    flattenPath() {
        // create an array of segments from the flattened path
        this.flattenedPath = [];
        let path = this.pathRef.current;
        let pathLength = path.getTotalLength();
        this.pathLength = pathLength;
        let segments = 1000;
        let step = pathLength / segments;
        for (let i = 0; i < segments; i++) {
            let point = path.getPointAtLength(i * step);
            this.flattenedPath.push(new geom.Point(point.x, point.y));
        }
        this.canvas.refreshCanvas();
    }

    get animateChildren() {
        return false;
    }

    _getAnimations() {
        let animations = [{
            name: "Grow in",
            element: this,
            prepare: () => {
                this.animationState.progress = 0;
            },
            onBeforeAnimationFrame: progress => {
                this.animationState.progress = progress;
                return this;
            }
        }];

        for (let milestone of this.milestones.itemElements) {
            animations.push({
                name: "Fade in",
                element: milestone,
                prepare: () => {
                    let markerNode = milestone.childElement.marker.DOMNode;
                    let textNode = milestone.childElement.text.DOMNode;

                    let connector = this.milestones.connectors.itemElements.find(c => c.model.source == milestone.id);

                    let connectorNode = connector.pathRef.current.pathRef.current;
                    let connectorMarkerNode = connector.endDecorationRef.current.pathRef.current;

                    let anim = anime.timeline({
                        easing: "easeOutQuad",
                        autoplay: false,
                        complete: () => {
                            connectorNode.removeAttribute("stroke-dasharray");
                        }
                    });

                    anim.add({
                        targets: connectorMarkerNode,
                        scale: [0, 1],
                        duration: 200
                    }, 0);

                    var pathLength = connectorNode.getTotalLength();
                    connectorNode.setAttribute("stroke-dasharray", pathLength);
                    anim.add({
                        targets: connectorNode,
                        strokeDashoffset: [-pathLength, 0],
                        easing: "easeOutQuad",
                        duration: 400
                    }, 100);

                    anim.add({
                        targets: markerNode,
                        scale: [0, 1],
                        duration: 400
                    }, 500);

                    anim.add({
                        targets: textNode,
                        opacity: [0, 1],
                        duration: 600
                    }, 600);

                    milestone.animationState.anim = anim;
                    milestone.animationState.hasPlayed = false;
                },
                onBeforeAnimationFrame: progress => {
                    if (progress > (milestone.model.position - .05) && !milestone.animationState.hasPlayed) {
                        // milestone.animationState.fadeInProgress = progress;

                        milestone.animationState.anim.play();
                        milestone.animationState.hasPlayed = true;
                    }
                },
            });
        }

        return animations;
    }
}

class TimelineMilestones extends CalloutsCanvas {
    getChildOptions(model) {
        return {
            ...super.getChildOptions(model),
            onDrag: () => {
                this.handleMilestoneDrag(model);
            },
            onDragEnd: () => {
                this.handleMilestoneDragEnd(model);
            }
        };
    }

    get showSnapLines() {
        return false;
    }

    get restrictElementsToBounds() {
        return false;
    }

    snapEndPoint = point => {
        let endPoint = new geom.Point(point.x, point.y);
        return this.parentElement.getAnchorPoint(null, null, endPoint);
    }

    drawEndDecoration = pt => {
        return {
            trimAmount: 0,
            path: Shape.drawCircle(10, pt).toPathData()
        };
    }

    getConnectorOptions = model => {
        return {
            snapEndPoint: this.snapEndPoint,
            endDecoration: this.drawEndDecoration
        };
    }

    // callback when a milestone is dragged so that we can "unstick" the connector if it's dragged too far away
    handleMilestoneDrag = milestone => {
        let pt = new geom.Point(milestone.x, milestone.y);
        let connector = this.connectors.itemElements.find(c => c.model.source == milestone.id);
        if (connector && connector.model.targetPoint) {
            if (pt.distance(connector.model.targetPoint) > 200) {
                connector.model.targetPoint = null;
                // pointing the connector to the timeline will flip it back to connecting to closest point on the path
                connector.model.target = this.parentElement.uniquePath;
            }
        }
    }

    handleMilestoneDragEnd = milestone => {
        let connector = this.connectors.itemElements.find(c => c.model.source == milestone.id);
        let closestPoint = this.parentElement.getAnchorPoint(null, null, connector.calculatedProps.end);

        milestone.position = closestPoint.position;

        // reorder milestones based on their position
        this.model.elements = this.model.elements.sort((a, b) => a.position - b.position);
    }

    resetMilestones = () => {
        for (let milestone of this.itemElements) {
            let closestPoint = this.parentElement.getAnchorPoint(null, null, new geom.Point(milestone.model.x, milestone.model.y));
            // store the position of the point on the path as a percentage of the total path length
            milestone.model.position = closestPoint.position;

            // pointing the connector to the timeline will flip it back to connecting to closest point on the path
            let connector = this.connectors.itemElements.find(c => c.model.source == milestone.id);
            connector.model.targetPoint = null;
            connector.model.target = this.parentElement.uniquePath;
        }

        // reorder milestones based on their position
        this.model.elements = this.model.elements.sort((a, b) => a.position - b.position);

        this.saveModel();
    }
}

export const elements = {
    Timeline2
};

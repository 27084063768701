import React, { Component } from "react";

import { Button } from "js/Components/Button";
import { NumericStepper } from "js/Components/NumericStepper";
import { ToggleSwitch } from "js/Components/ToggleSwitch";
import { WithLabel } from "js/Components/WithLabel";
import { PropertyPanelContainer, PropertySection } from "js/EditorComponents/PropertyPanel";
import { ShowDialog } from "js/react/components/Dialogs/BaseDialog";
import ImportMediaDialog from "js/react/views/ImportMedia/ImportMediaDialog";
import { Dropdown } from "../../../../Components/Dropdown";
import { Icon } from "../../../../Components/Icon";
import { RoundIconButton } from "../../../../Components/IconButton";
import { MenuItem } from "../../../../Components/Menu";
import { ShowAssetDialog } from "../../../../react/views/AddAssets/ShowAssetDialog";
import { PictureElementControlBar } from "../ElementControlBars/PictureElementControlBar";
import { DefaultOverlayContainer } from "../ElementDefaultOverlays/Components/DefaultOverlayContainer";

function importVideo(element) {
    ShowDialog(ImportMediaDialog, {
        fileTypes: ["video"],
        onSuccess: asset => {
            element.updateModel({
                videoId: asset.hostedVideoUrl,
                videoAssetId: asset.id,
                muted: false,
                startTime: 0
            });
        }
    });
}

export class VideoPropertyPanel extends Component {
    render() {
        const { element } = this.props;

        return (
            <PropertyPanelContainer>
                <PropertySection>
                    <Button blue solid onClick={() => importVideo(element)}><Icon>video_call</Icon>Import Video...</Button>
                </PropertySection>
                <PropertySection>
                    <WithLabel label="Autoplay">
                        <ToggleSwitch value={element.model.autoPlay}
                            onChange={value => element.updateModel({ autoPlay: value })}
                        />
                    </WithLabel>
                    <WithLabel label="Start Time">
                        <WithLabel small right label="Seconds" gap={5}>
                            <NumericStepper value={element.model.startTime}
                                onChange={value => element.updateModel({ startTime: value })}
                            />
                        </WithLabel>
                    </WithLabel>
                    <WithLabel label="Loop">
                        <ToggleSwitch value={element.model.loop}
                            onChange={value => element.updateModel({ loop: value })}
                        />
                    </WithLabel>
                    <WithLabel label="Play Audio">
                        <ToggleSwitch value={!element.model.muted}
                            onChange={value => element.updateModel({ muted: !value })}
                        />
                    </WithLabel>
                    <WithLabel label="Playback Speed">
                        <Dropdown
                            value={element.playbackRate}
                            onChange={value => element.updateModel({ playbackRate: value })}
                        >
                            <MenuItem value={0.25}>0.25x</MenuItem>
                            <MenuItem value={0.5}>0.5x</MenuItem>
                            <MenuItem value={1}>1x</MenuItem>
                            <MenuItem value={1.5}>1.5x</MenuItem>
                            <MenuItem value={2}>2x</MenuItem>
                        </Dropdown>
                    </WithLabel>
                </PropertySection>
                <PropertySection>
                    <WithLabel label="Full Bleed">
                        <ToggleSwitch value={element.model.fullBleed}
                            onChange={value => element.updateModel({ fullBleed: value })}
                        />
                    </WithLabel>
                </PropertySection>
            </PropertyPanelContainer>
        );
    }
}

export class VideoElementControlBar extends PictureElementControlBar {
    render() {
        return null;
    }
}

export class VideoElementDefaultOverlay extends Component {
    handleAddMedia = async () => {
        const { element } = this.props;
        let asset = await ShowAssetDialog(element.defaultAssetType);
        element.updateModel(asset, { refreshStyles: true });
    }

    render() {
        const { element } = this.props;

        return (
            <DefaultOverlayContainer>
                <RoundIconButton icon="video_call" onClick={() => {
                    importVideo(element);
                }} />
            </DefaultOverlayContainer>
        );
    }
}

/*
const VideoSelection = ElementSelection.extend({
    renderControls: function() {
        this.addControl({
            type: controls.BUTTON,
            icon: "ondemand_video",
            label: "Set Video...",
            callback: () => {
                this.showImportMediaDialog();
            }
        });

        if (this.element.showDefaultOverlay) {
            this.$el.append(controls.createButton(this, {
                icon: "ondemand_video",
                label: "Import Video...",
                className: "large_icon light",
                callback: () => {
                    this.showImportMediaDialog();
                }
            }));
        }
    },
    showImportMediaDialog: function() {
        ShowDialog(ImportMediaDialog, {
            fileTypes: ["video"],
            onSuccess: asset => {
                const {
                    hostedVideoUrl,
                    id,
                } = asset;
                return this.setVideo(hostedVideoUrl, id);
            }
        });
    },
    setVideo: async function(hostedVideoUrl, videoAssetId) {
        this.element.model.videoId = hostedVideoUrl;
        this.element.model.videoAssetId = videoAssetId;
        this.element.model.muted = false;
        this.element.model.startTime = 0;
        await this.element.canvas.updateCanvasModel(false);
    }
});

const VideoOptionsMenu = ElementOptionsMenu.extend({
    renderControls: function() {
        this.addControl({
            type: controls.TOGGLE,
            label: "Fullbleed",
            property: "fullBleed"
        });

        this.addControl({
            type: controls.TOGGLE,
            label: "Mute Audio",
            property: "muted"
        });

        this.addControl({
            type: controls.TOGGLE,
            label: "Autoplay",
            property: "autoPlay"
        });

        this.addControl({
            type: controls.TOGGLE,
            label: "Loop",
            property: "loop"
        });

        this.addControl({
            type: controls.NUMERIC,
            label: "Start Time",
            property: "startTime",
            triggerEvent: "blur",
            min: 0,
            max: 9999
        });

        if (this.element.videoType === "upload") {
            this.addControl({
                type: controls.POPUP_BUTTON,
                property: "playbackRate",
                label: "Playback Speed",
                items: [
                    { label: "0.25", value: "0.25" },
                    { label: "0.5", value: "0.5" },
                    { label: "0.75", value: "0.75" },
                    { label: "Normal", value: "1" },
                    { label: "1.25", value: "1.25" },
                    { label: "1.5", value: "1.5" },
                    { label: "1.75", value: "1.75" },
                    { label: "2", value: "2" },
                ]
            });
        }
    }
});
 */

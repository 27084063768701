import React, { Component } from "react";
import styled from "styled-components";
import { MenuItem, Select } from "@material-ui/core";

import { FlexBox } from "../../../../../../react/components/LayoutGrid";
import { Gap10, Gap20, Gap5 } from "../../../../../../react/components/Gap";
import { ColorPicker } from "../../../../../../react/components/ColorPicker";

import { AuthoringCanvasControlBar } from "../AuthoringCanvasControlBar";
import { SelectInput } from "../../../../../../Components/legacy-components/AuthoringEditorComponents/SelectInput";

export class AuthoringSvgEditor extends Component {
    state = {
        fill: null,
        stroke: null
    }

    componentDidMount() {
        this.setSelectionState();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { selection } = this.props;

        if (prevProps.selection !== selection) {
            this.setSelectionState();
        }
    }

    setSelectionState = () => {
        this.setState({
            fill: this.getModelValue("fill"),
            stroke: this.getModelValue("stroke"),
            strokeWidth: this.getModelValue("strokeWidth"),
            strokeStyle: this.getModelValue("strokeStyle"),
            canChangeStyles: this.getModelValue("stroke")
        });
    }

    getModelValue = property => {
        const { selection } = this.props;

        const uniqueModelValues = [...new Set(selection.map(container => container.element.model[property]))];

        if (uniqueModelValues.some(value => value == undefined)) {
            return null;
        }

        if (uniqueModelValues.length === 1) {
            return uniqueModelValues[0];
        } else {
            return "mixed";
        }
    }

    setModelValue = (property, value) => {
        const { selection } = this.props;

        for (const container of selection) {
            container.element.model[property] = value;
        }

        selection[0].canvas.refreshCanvas({ suppressRefreshCanvasEvent: true })
            .then(() => {
                this.setState({ [property]: value });
            });
    }

    render() {
        const { selection, selectionBounds, ungroupSelection } = this.props;
        const { fill, stroke, strokeWidth, strokeStyle } = this.state;

        if (selection.length > 1) return null;

        return (
            <AuthoringCanvasControlBar selectionBounds={selectionBounds} >
                <ColorPicker
                    label="Fill"
                    color={fill}
                    showNone
                    onChange={color => this.setModelValue("fill", color)}
                />
                <ColorPicker
                    label="Border"
                    color={stroke}
                    showNone
                    onChange={color => this.setModelValue("stroke", color)}
                >
                    <FlexBox left middle style={{ marginBottom: 20 }}>
                        <label>Border</label>
                        <Gap10 />
                        <SelectInput
                            value={strokeWidth}
                            onChange={value => this.setModelValue("strokeWidth", value)}
                        >
                            <MenuItem value={0}>0px</MenuItem>
                            <MenuItem value={1}>1px</MenuItem>
                            <MenuItem value={2}>2px</MenuItem>
                            <MenuItem value={3}>3px</MenuItem>
                            <MenuItem value={5}>5px</MenuItem>
                            <MenuItem value={10}>10px</MenuItem>
                        </SelectInput>
                        <Gap5 />
                        px
                        <Gap20 />
                        <Select
                            value={strokeStyle}
                            onChange={event => this.setModelValue("strokeStyle", event.target.value)}
                            disableUnderline
                            variant="outlined"
                        >
                            <MenuItem value="solid">Solid</MenuItem>
                            <MenuItem value="dashed">Dashed</MenuItem>
                            <MenuItem value="dotted">Dotted</MenuItem>
                        </Select>
                    </FlexBox>
                </ColorPicker>
            </AuthoringCanvasControlBar>
        );
    }
}

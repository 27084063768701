import React, { Component } from "react";
import { PropertyPanelContainer, PropertySection } from "../../../../EditorComponents/PropertyPanel";
import { WithLabel } from "../../../../Components/WithLabel";
import { ToggleSwitch } from "../../../../Components/ToggleSwitch";
import { NumericStepper } from "../../../../Components/NumericStepper";
import { Dropdown } from "../../../../Components/Dropdown";
import { MenuItem } from "../../../../Components/Menu";
import { ColorPicker } from "../EditorComponents/ColorPickers/ColorPicker";

export class TimerPropertyPanel extends Component {
    state = {
        showAsSeconds: true,
        showEmphasizeAsSeconds: true
    };

    componentDidMount() {
        const { element } = this.props;
        if (element.model.duration % 60 === 0) {
            this.setState({ showAsSeconds: false });
        }
        if (element.model.emphasize % 60 === 0) {
            this.setState({ showEmphasizeAsSeconds: false });
        }
    }

    render() {
        const { element } = this.props;
        const { showAsSeconds, showEmphasizeAsSeconds } = this.state;

        return (
            <PropertyPanelContainer>
                <PropertySection>
                    <WithLabel label="Timer Color">
                        <ColorPicker value={element.model.color} canvas={element.canvas}
                            onChange={color => element.updateModel({ color }, { refreshStyles: true })}
                            showAuto showBackgroundAccent showPositiveNegative
                        />
                    </WithLabel>
                </PropertySection>
                <PropertySection>
                    <WithLabel label="Timer duration">
                        <NumericStepper value={element.model.duration / (showAsSeconds ? 1 : 60)}
                            onChange={value => element.updateModel({ duration: value * (showAsSeconds ? 1 : 60) })}
                            min={0.1}
                            max={3600}
                            step={1}
                        />
                        <Dropdown value={showAsSeconds ? "seconds" : "minutes"}
                            onChange={value => {
                                this.setState({ showAsSeconds: value === "seconds" });
                            }}>
                            <MenuItem value="minutes">Minutes</MenuItem>
                            <MenuItem value="seconds">Seconds</MenuItem>
                        </Dropdown>
                    </WithLabel>
                    <WithLabel label="Emphasize when">
                        <NumericStepper value={element.model.emphasize / (showEmphasizeAsSeconds ? 1 : 60)}
                            onChange={value => element.updateModel({ emphasize: value * (showEmphasizeAsSeconds ? 1 : 60) })}
                            min={0}
                            max={3600}
                            step={1}
                        />
                        <Dropdown value={showEmphasizeAsSeconds ? "seconds" : "minutes"}
                            onChange={value => {
                                this.setState({ showEmphasizeAsSeconds: value === "seconds" });
                            }}>
                            <MenuItem value="minutes">Minutes</MenuItem>
                            <MenuItem value="seconds">Seconds</MenuItem>
                        </Dropdown>
                    </WithLabel>
                </PropertySection>
                <PropertySection>
                    <WithLabel label="Advance slide when complete">
                        <ToggleSwitch value={element.model.autoAdvanceSlide}
                            onChange={value => element.updateModel({ autoAdvanceSlide: value })}
                        />
                    </WithLabel>
                </PropertySection>
            </PropertyPanelContainer>
        );
    }
}


import React, { Component } from "react";

import { _ } from "js/vendor";
import { BeautifulIcon } from "../../../../Components/IconPicker";
import { ImageOption, ImageOptionList } from "../../../../Components/ImageOptionList";
import { PopupContainer } from "../../../../Components/Popup";
import { WithLabel } from "../../../../Components/WithLabel";
import { ShowWarningDialog } from "../../../../react/components/Dialogs/BaseDialog";

import { PropertyPanelContainer, PropertySection } from "../../../../EditorComponents/PropertyPanel";
import { AddItemButton } from "../EditorComponents/AddItemButton";
import { CollectionColorPicker } from "../EditorComponents/ColorPickers/CollectionColorPicker";
import { ControlBar, ControlBarGroup } from "../ElementControlBars/Components/ControlBar";
import { CollectionItemElementSelection } from "../ElementSelections/CollectionItemElementSelection";
import { TextElementSelection, TextEditorContainer } from "../ElementSelections/TextElementSelection";
import { MediaPropertyPanel } from "./MediaUI";
import { StreetMapPropertyPanel } from "./StreetMapUI";

export class ContactInfoAddressTextSelection extends TextElementSelection {
    get TextEditorContainer() {
        return ContactInfoAddressTextEditorContainer;
    }
}

class ContactInfoAddressTextEditorContainer extends TextEditorContainer {
    refreshElement = () => {
        const { element } = this.props;
        this.lockSlideForCollaborators();
        return element.canvas.refreshCanvas();
    }
}

export class ContactInfoPropertyPanel extends Component {
    render() {
        const { element } = this.props;

        const type = element.model.layout.split("_")[1];

        return (
            <PropertyPanelContainer>
                <PropertySection>
                    <WithLabel label="Street Map" above left>
                        <ImageOptionList cols={3} value={type} onChange={type => element.updateModel({ layout: type })}>
                            <ImageOption value="left_map" url="/images/ui/trays/contact_map_left.png" />
                            <ImageOption value="right_map" url="/images/ui/trays/contact_map_right.png" />
                        </ImageOptionList>
                    </WithLabel>

                    <WithLabel label="Media" above left>
                        <ImageOptionList cols={3} value={type} onChange={type => element.updateModel({ layout: type })}>
                            <ImageOption value="left_picture" url="/images/ui/trays/contact_image_left.png" />
                            <ImageOption value="right_picture" url="/images/ui/trays/contact_image_right.png" />
                            <ImageOption value="top_picture" url="/images/ui/trays/contact_image_top.png" />
                        </ImageOptionList>
                    </WithLabel>
                </PropertySection>
                <PropertySection>
                    <WithLabel label="Icon Colors">
                        <CollectionColorPicker element={element.list} />
                    </WithLabel>
                </PropertySection>
                {type == "map" && element.map && (
                    <StreetMapPropertyPanel element={element.map} />
                )}
                {type == "picture" && element.picture && (
                    <MediaPropertyPanel element={element.picture} />
                )}

            </PropertyPanelContainer>
        );
    }
}

export class ContactInfoControlBar extends Component {
    handleAddItem = (type, socialType) => {
        const { element, selectionLayerController } = this.props;

        // We can have only one address block
        if (type === "address" && _.find(element.list.elements, item => item.model.type == "address")) {
            ShowWarningDialog({
                title: "Can't add another address",
                message: "You can only have one address block",
            });
            return;
        }

        const item = element.list.addItem({ type: type, social_type: socialType });
        element.canvas.updateCanvasModel(false).then(() => {
            selectionLayerController.setSelectedElements([element.list.getChild(item.id).text]);
        }).catch(err => {
            ShowWarningDialog({
                title: "Unable to add item",
                message: err.message,
            });
        });
    }

    render() {
        return (
            <ControlBar>
                <ControlBarGroup>
                    <AddItemButton label="Add Contact Info"
                        showDropdown
                    >
                        <PopupContainer>
                            <ImageOptionList gap={15} size={40} cols={6} onChange={type => this.handleAddItem(type)}>
                                <ImageOption value="text" label="Text">
                                    <BeautifulIcon>a-z</BeautifulIcon>
                                </ImageOption>
                                <ImageOption value="address" label="Address">
                                    <BeautifulIcon>location-home</BeautifulIcon>
                                </ImageOption>
                                <ImageOption value="phone" label="Phone">
                                    <BeautifulIcon>phone-call</BeautifulIcon>
                                </ImageOption>
                                <ImageOption value="website" label="Website">
                                    <BeautifulIcon>internet</BeautifulIcon>
                                </ImageOption>
                                <ImageOption value="email" label="Email">
                                    <BeautifulIcon>symbol-at</BeautifulIcon>
                                </ImageOption>
                                <ImageOption value="fax" label="Fax">
                                    <BeautifulIcon>printer</BeautifulIcon>
                                </ImageOption>
                            </ImageOptionList>
                        </PopupContainer>
                    </AddItemButton>
                </ControlBarGroup>
                <ControlBarGroup>
                    <AddItemButton label="Add Social Link" icon="share" showDropdown>
                        <PopupContainer>
                            <ImageOptionList gap={15} size={40} cols={6} onChange={type => this.handleAddItem("social", type)}>
                                <ImageOption value="facebook" label="Facebook">
                                    <BeautifulIcon>facebook</BeautifulIcon>
                                </ImageOption>
                                <ImageOption value="twitter" label="Twitter">
                                    <BeautifulIcon>twitter</BeautifulIcon>
                                </ImageOption>
                                <ImageOption value="linkedin" label="LinkedIn">
                                    <BeautifulIcon>linkedin</BeautifulIcon>
                                </ImageOption>
                                <ImageOption value="skype" label="Skype">
                                    <BeautifulIcon>skype</BeautifulIcon>
                                </ImageOption>
                                <ImageOption value="instagram" label="Instagram">
                                    <BeautifulIcon>instagram</BeautifulIcon>
                                </ImageOption>
                                <ImageOption value="tumblr" label="Tumblr">
                                    <BeautifulIcon>tumblr</BeautifulIcon>
                                </ImageOption>
                                <ImageOption value="pinterest" label="Pinterest">
                                    <BeautifulIcon>pinterest</BeautifulIcon>
                                </ImageOption>
                                <ImageOption value="whatsapp" label="WhatsApp">
                                    <BeautifulIcon>whatsapp</BeautifulIcon>
                                </ImageOption>
                                <ImageOption value="youtube" label="Youtube">
                                    <BeautifulIcon>youtube</BeautifulIcon>
                                </ImageOption>
                                <ImageOption value="tiktok" label="Tiktok">
                                    <BeautifulIcon>tiktok</BeautifulIcon>
                                </ImageOption>
                            </ImageOptionList>
                        </PopupContainer>
                    </AddItemButton>
                </ControlBarGroup>
            </ControlBar>
        );
    }
}

export class ContactInfoHeaderSelection extends CollectionItemElementSelection {
    get canDelete() {
        return false;
    }
}

import React, { Component } from "react";
import styled from "styled-components";

import { $, _ } from "../../../../vendor";
import { BlockStructureType, TextFocusType } from "../../../../../common/constants";
import { Key } from "../../../../core/utilities/keys";
import { app } from "../../../../namespaces";
import { getSelectionState } from "../../../../core/utilities/htmlTextHelpers";
import { TabKeyController } from "../../../../editor/PresentationEditor/TabKeyController";

import { AuthoringBlockEditor } from "./Authoring/Editors/AuthoringBlockEditor";
import { BaseElementSelection } from "./BaseElementSelection";

const Container = styled.div`
    position: relative;
    pointer-events: none;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
`;

export class TextElementSelection extends BaseElementSelection {
    constructor(props) {
        super(props);

        this.textEditorContainerRef = React.createRef();
        this.textEditorRef = React.createRef();
    }

    get showSelectionBorder() {
        return false;
    }

    get TextEditorContainer() {
        return TextEditorContainer;
    }

    handleTabKey = (event, block) => {
        if (!event.shiftKey && !block.props.isLastBlock) {
            // focus next block in text
            this.focusBlock(
                this.textEditorRef.current.blockContainer.blocks[block.props.index + 1].props.id,
                TextFocusType.ALL
            );
        } else if (event.shiftKey && !block.props.isFirstBlock) {
            // focus prev block in text
            this.focusBlock(
                this.textEditorRef.current.blockContainer.blocks[block.props.index - 1].props.id,
                TextFocusType.ALL
            );
        } else {
            TabKeyController.handleTabKey(event);
        }
    }

    handleKeyDownInBlock = (event, block) => {
        if (event.which === Key.TAB) {
            this.handleTabKey(event, block);
            return true;
        }

        return false;
    }

    focusBlock = async (blockId, focusType = TextFocusType.START) => {
        const block = this.textEditorRef.current?.blockContainer.blockRefs[blockId]?.current;
        if (block) {
            await this.textEditorRef.current.focusBlock(block, focusType);
        }
    }

    renderCustomChildren() {
        const { element, selectionLayerController, canvasController } = this.props;

        return (
            <this.TextEditorContainer
                ref={this.textEditorContainerRef}
                element={element}
                textEditorRef={this.textEditorRef}
                allowMultipleBlocks={false}
                onKeyDownInBlock={this.handleKeyDownInBlock}
                onReady={() => { }}
                canvasController={canvasController}
                selectionLayerController={selectionLayerController}
            />
        );
    }
}

export class TextEditorContainer extends Component {
    constructor(props) {
        super(props);

        this.lockSlideForCollaborators = _.throttle(() => props.element.canvas.lockSlideForCollaborators(10), 5000);

        this.focusedBlock = null;
        this.selectedBlocks = [];
    }

    get isOnAuthoringCanvas() {
        const { element } = this.props;
        return !!element.getParentOfType("AuthoringCanvas");
    }

    componentDidMount() {
        this.lockSlideForCollaborators();
    }

    componentWillUnmount() {
        const { element } = this.props;

        this.lockSlideForCollaborators.cancel();

        if (element.canvas.isLockedForCollaborators()) {
            element.canvas.unlockSlideForCollaborators();
        }
    }

    refreshAndSaveCanvas = async () => {
        const { element } = this.props;
        this.lockSlideForCollaborators();

        await element.canvas.refreshCanvasAutoRevert({ suppressRefreshCanvasEvent: false });

        this.saveChanges();
    }

    refreshCanvas = async () => {
        const { element } = this.props;
        this.lockSlideForCollaborators();

        await element.canvas.refreshCanvas({ suppressRefreshCanvasEvent: false });
    }

    refreshElement = async () => {
        const { element } = this.props;

        this.lockSlideForCollaborators();

        if (element.options?.autoWidth || element.options?.syncFontSizeWithSiblings) {
            if (element.options?.autoWidth) {
                // If auto width then it's cheaper to always do full refresh
                await element.canvas.refreshCanvas();
            } else if (element.parentTextScaleManagingElement && element.parentTextScaleManagingElement.canRefreshElement) {
                // Need to sync font sizes but can refresh managing parent element
                element.parentTextScaleManagingElement.refreshElement();
            } else {
                // Need to sync font sizes but *cannot* refresh managing parent element
                await element.canvas.refreshCanvas();
            }
        } else {
            const currentSize = element.calculatedProps.size;
            const currentShowTextIsClippedWarning = element.options.showTextIsClippedWarning;
            // Make sure we don't show text is clipped warning
            element.options.showTextIsClippedWarning = false;
            element.refreshElement();

            // Restore original value
            element.options.showTextIsClippedWarning = currentShowTextIsClippedWarning;
            if (
                !currentSize.equals(element.calculatedProps.size) ||
                !element.calculatedProps.isTextFit ||
                (element.syncFontSizeWithSiblings && element.hasStoredPropChanged("textScale", element.calculatedProps.textScale))
            ) {
                // Something significant changed -> need full refresh
                await element.canvas.refreshCanvas();
            }
        }
    }

    getUndoState = () => {
        const { element } = this.props;
        return {
            selectedElementUniquePath: element.uniquePath,
            focusedBlockId: this.focusedBlock?.model.id,
            focusedBlockSelectionState: this.focusedBlock?.ref.current ? getSelectionState(this.focusedBlock?.ref.current) : null,
            selectedBlockIds: this.selectedBlocks.map(({ model: { id } }) => id)
        };
    }

    saveChanges = (save = true) => {
        const { element } = this.props;

        const undoState = this.getUndoState();

        if (!this.undoState) {
            this.undoState = undoState;
        }

        const undoOldState = { ...this.undoState };
        const undoNewState = { ...undoState };

        this.undoState = undoState;

        return element.canvas.saveCanvasModel({ undoOldState, undoNewState, save, forceUndo: true });
    }

    onSelectedBlocksChanged = (focusedBlock, selectedBlocks) => {
        this.focusedBlock = focusedBlock;
        this.selectedBlocks = selectedBlocks;

        if (!app.undoManager.isUndoing) {
            this.saveChanges(false);
        }
    }

    render() {
        const {
            element,
            textEditorRef,
            onKeyDownInBlock,
            onReady,
            canvasController,
            selectionLayerController
        } = this.props;

        if (!element.canEditBlocks) {
            // Blocks editing not allowed, nothing to render
            return null;
        }

        const bounds = element.selectionBounds.multiply(element.canvas.getScale());

        const editorConfig = {
            allowStyling: element.options.allowStyling ?? true,
            allowedBlockTypes: element.allowedBlockTypes,
            allowedBlockStyles: [],
            canAddBlocks: element.options.canAddBlocks ?? false, // whether to show the inline add block widget
            canReorderBlocks: true,
            showBlockTypesInMenu: false,
            showBlockStylesInMenu: false,
            showEvenBreak: element.options.allowEvenBreak ?? true,
            showMatchSizes: element.options.syncFontSizeWithSiblings ?? false,
            showTextAlign: element.allowAlignment, // whether to show text alignment in textformat bar
            showVerticalAlign: element.allowVerticalAlignment ?? false,
            showColumns: element.options.allowColumns ?? false,
            showMargins: true,
            showBlockStyles: false,
            showListStyles: element.allowListStyling ?? true,
            syncTextAlignAcrossBlocks: element.options.syncTextAlignAcrossBlocks ?? true,
            showRolloverBlock: element.options.showRolloverBlock ?? true,
            showFocusedBlock: element.options.showFocusedBlock ?? true,
            showAdvancedStylesMenu: element.options.showAdvancedStylesMenu === true,
            canDeleteLastBlock: element.options.canDeleteLastBlock,
            positionWidgetBarWithTextBlock: element.options.positionWidgetBarWithTextBlock,
            showFontSize: element.options.showFontSize ?? false, // whether to use font size or font scaling
            createSubBulletOnEnter: element.options.createSubBulletOnEnter ?? false,
            showFullSpectrumColorPicker: element.isOnAuthoringCanvas,
            textFormatBarOffset: element.options.textFormatBarOffset
        };

        if (element.getRootElement().type == "DocumentElement") {
            editorConfig.showColumns = true;
            editorConfig.showBlockStyles = true;
            editorConfig.showRolloverBlock = false;
            editorConfig.positionWidgetBarWithTextBlock = true;
        }

        switch (element.blockStructure) {
            case BlockStructureType.SINGLE_BLOCK:
                editorConfig.allowNewLines = element.options.allowNewLines ?? false;
                break;
            case BlockStructureType.FREEFORM:
                editorConfig.showBlockTypesInMenu = true;
                editorConfig.showBlockStylesInMenu = true;
                break;
        }

        return (
            <Container className="authoring-block-editor-container">
                <AuthoringBlockEditor
                    refreshCanvasAndSaveChanges={this.refreshAndSaveCanvas}
                    saveChanges={this.saveChanges}
                    refreshCanvas={this.refreshCanvas}
                    refreshElement={this.refreshElement}
                    bounds={bounds}
                    editorConfig={editorConfig}
                    ref={textEditorRef}
                    element={element}
                    scale={element.calculatedProps.textScale}
                    blockStructure={element.blockStructure}
                    onKeyDownInBlock={onKeyDownInBlock}
                    onSelectedBlocksChanged={this.onSelectedBlocksChanged}
                    onReady={onReady}
                    canvasController={canvasController}
                    selectionLayerController={selectionLayerController}
                    lockSlideForCollaborators={this.lockSlideForCollaborators}
                />
            </Container>
        );
    }
}

export class HideTextElementControlBar extends Component {
    get suppressPrimaryControlBar() {
        return true;
    }

    render() {
        // return null and suppress primary control bar so that no control bar is shown
        return null;
    }
}

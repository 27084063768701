import React, { useEffect, useRef } from "react";

import { numeral } from "../../../../vendor";
import * as geom from "../../../../core/utilities/geom";
import { ShowDialog } from "../../../../react/components/Dialogs/BaseDialog";
import EditTextDialog from "../../../../react/components/Dialogs/EditTextDialog";

export function ValueLabelSelection({ element, selectionLayerController }) {
    useEffect(() => {
        const selectionBounds = element.selectionBounds;
        const screenBounds = element.getScreenBounds().offset(0, -selectionBounds.height / 2);
        const targetPt = new geom.Point(screenBounds.centerH, screenBounds.centerV);

        const valueOriginal = element.parentElement.model.value;
        const valueScaled = element.parentElement.valueScaled;

        ShowDialog(EditTextDialog, {
            targetPt,
            target: element,
            minWidth: 150,
            value: valueScaled ?? valueOriginal,
            onClose: () => {
                selectionLayerController.setSelectedElements([element.parentElement]);
            },
            callback: value => {
                if (!numeral.validateEx(value)) {
                    return;
                }

                value = numeral(value).value();
                if (valueScaled !== undefined && valueOriginal !== valueScaled) {
                    value = value * (valueOriginal / valueScaled);
                }

                if (Math.abs(value) > 999999999999) {
                    value = value > 0 ? 999999999999 : -9999999999999;
                }

                element.parentElement.model.value = value;
                element.parentElement.canvas.updateCanvasModel(false).then(() => {
                    selectionLayerController.setSelectedElements([element.parentElement]);
                });
            },
        });
    }, []);

    return null;
}

import React, { Component } from "react";
import { PropertyPanelContainer, PropertySection } from "../../../../EditorComponents/PropertyPanel";
import { WithLabel } from "../../../../Components/WithLabel";
import { CollectionColorPicker } from "../EditorComponents/ColorPickers/CollectionColorPicker";
import { ImageOption, ImageOptionList } from "../../../../Components/ImageOptionList";
import { StaticImage } from "../../../../Components/StaticImage";
import { ControlBar } from "../ElementControlBars/Components/ControlBar";
import { ItemColorPicker } from "../EditorComponents/ColorPickers/ItemColorPicker";
import { GridLayoutPropertyPanel } from "./GridContainer/GridLayoutUI";
import { LayoutEngineType } from "../../elements/elements/LayoutContainers/GridLayoutContainer";

export class StackDiagramPropertyPanel extends Component {
    render() {
        const { element } = this.props;

        return (
            <PropertyPanelContainer>
                <GridLayoutPropertyPanel {...this.props}
                    availableLayoutEngines={[LayoutEngineType.STACK_DIAGRAM, LayoutEngineType.CUSTOM]}
                    layoutCols={2} allowFullBleed={false}
                />
            </PropertyPanelContainer>
        );
    }
}

export class StackDiagramItemControlBar extends Component {
    render() {
        const { element, canvas } = this.props;

        if (element.isHidden || canvas.getBackgroundColor().isColor) {
            return null;
        }

        return (
            <ControlBar left="50%" top="calc(100% + 30px)">
                <ItemColorPicker element={element} />
            </ControlBar>
        );
    }
}

function getVariations() {
    return [
        {
            label: "Hierarchy",
            template: "stackDiagram",
            icon: "stack_hierarchy",
            model: {
                primary: {
                    items: [{
                        x: 0, y: 0, width: 12, height: 4
                    }, {
                        x: 0, y: 4, width: 6, height: 4
                    }, {
                        x: 6, y: 4, width: 6, height: 4
                    }, {
                        x: 0, y: 8, width: 4, height: 4
                    }, {
                        x: 4, y: 8, width: 4, height: 4
                    }, {
                        x: 8, y: 8, width: 4, height: 4
                    }]
                }
            }
        },
        {
            label: "Architecture",
            template: "stackDiagram",
            icon: "stack_architecture",
            model: {
                primary: {
                    items: [{
                        x: 0, y: 0, width: 12, height: 3
                    }, {
                        x: 0, y: 3, width: 3, height: 6
                    }, {
                        x: 3, y: 3, width: 3, height: 6
                    }, {
                        x: 6, y: 3, width: 3, height: 6
                    }, {
                        x: 9, y: 3, width: 3, height: 6
                    }, {
                        x: 0, y: 9, width: 12, height: 3
                    }]
                }
            }
        },
        {
            label: "Side By Side",
            template: "stackDiagram",
            icon: "stack_sidebyside",
            model: {
                primary: {
                    items: [{
                        x: 0, y: 0, height: 6, width: 3
                    }, {
                        x: 0, y: 6, height: 3, width: 6
                    }, {
                        x: 3, y: 0, width: 3, height: 6,
                    }, {
                        x: 6, y: 0, width: 3, height: 6,
                    }, {
                        x: 0, y: 9, width: 6, height: 3,
                    }, {
                        x: 6, y: 6, width: 6, height: 3,
                    }, {
                        x: 9, y: 0, width: 3, height: 6,
                    }, {
                        x: 6, y: 9, width: 6, height: 3,
                    }]
                }
            }
        },
        {
            label: "Four Square",
            template: "stackDiagram",
            icon: "stack_foursquare",
            model: {
                primary: {
                    items: [{
                        x: 0, y: 0, width: 6, height: 6
                    }, {
                        x: 0, y: 6, width: 6, height: 6
                    }, {
                        x: 6, y: 0, width: 6, height: 6
                    }, {
                        x: 6, y: 6, width: 6, height: 6
                    }]
                }
            }
        },
    ];
}

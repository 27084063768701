import React, { Component } from "react";
import { v4 as uuid } from "uuid";

import { WithLabel } from "../../../../Components/WithLabel";
import { ImagePopup } from "../../../../Components/ImagePopup";
import { AuthoringElementType, CalloutType, ResizeDirection } from "../../../../../common/constants";
import { Button } from "../../../../Components/Button";
import { ToggleSwitch } from "../../../../Components/ToggleSwitch";
import { ImageOption, ImageOptionList } from "../../../../Components/ImageOptionList";
import { StaticImage } from "../../../../Components/StaticImage";
import { ShowDialog, ShowDialogAsync } from "../../../../react/components/Dialogs/BaseDialog";
import BadFitDialog from "../../../../react/components/Dialogs/BadFitDialog";
import { Icon } from "../../../../Components/Icon";
import { defaultDragResizeProps } from "../../../../editor/PresentationEditor/DragElementManager";

import { getContentItemImageOptions } from "../EditorComponents/getContentItemImageOptions";
import { PropertyPanelContainer, PropertySection } from "../../../../EditorComponents/PropertyPanel";
import { ControlBar, ControlBarGroup } from "../ElementControlBars/Components/ControlBar";
import { ItemColorPicker } from "../EditorComponents/ColorPickers/ItemColorPicker";
import { CollectionColorPicker } from "../EditorComponents/ColorPickers/CollectionColorPicker";
import { CollectionItemElementSelection } from "../ElementSelections/CollectionItemElementSelection";
import { MenuItem } from "../../../../Components/Menu";
import { Dropdown } from "../../../../Components/Dropdown";
import * as geom from "../../../../core/utilities/geom";
import { Slider } from "../../../../Components/Slider";
import { ColorPicker } from "../EditorComponents/ColorPickers/ColorPicker";

export class Timeline2PropertyPanel extends Component {
    render() {
        const { element } = this.props;

        let calloutType = element.model.milestones.elements[0]?.calloutType ?? CalloutType.TEXT;

        return (
            <PropertyPanelContainer>
                <PropertySection>
                    <WithLabel label="Path Type">
                        <Dropdown value={element.pathType}
                            onChange={async value => {
                                element.setPathType(value);
                            }}
                        >
                            <MenuItem value="straight">Straight</MenuItem>
                            <MenuItem value="journey">Journey</MenuItem>
                            <MenuItem value="boomerang">Boomerang</MenuItem>
                            <MenuItem value="zigzag">ZigZag</MenuItem>
                            <MenuItem value="circle">Circle</MenuItem>
                            <MenuItem value="ellipse">Ellipse</MenuItem>
                            <MenuItem value="wave">Wave</MenuItem>
                            <MenuItem value="box">Box</MenuItem>
                        </Dropdown>
                    </WithLabel>
                    <WithLabel label="Path Style">
                        <Dropdown value={element.pathStyle}
                            onChange={value => element.updateModel({ pathStyle: value })}
                        >
                            <MenuItem value="solid">Solid</MenuItem>
                            <MenuItem value="dashed">Dashed</MenuItem>
                            <MenuItem value="dotted">Dotted</MenuItem>
                            <MenuItem value="road">Road</MenuItem>
                        </Dropdown>
                    </WithLabel>
                    <WithLabel label="Path Size">
                        <Dropdown value={element.pathSize}
                            onChange={value => element.updateModel({ pathSize: value })}
                        >
                            <MenuItem value="thin">Thin</MenuItem>
                            <MenuItem value="medium">Medium</MenuItem>
                            <MenuItem value="thick">Thick</MenuItem>
                        </Dropdown>
                    </WithLabel>
                    <WithLabel label="Path Color">
                        <ColorPicker value={element.pathColor} canvas={element.canvas}
                            onChange={value => element.updateModel({ pathColor: value })}
                            showPrimary showSecondary
                        />
                    </WithLabel>
                </PropertySection>
                <PropertySection>
                    <WithLabel label="Milestone Style">
                        <ImagePopup value={calloutType}
                            onChange={value => {
                                element.model.milestones.elements.forEach(itemModel => itemModel.calloutType = value);
                                element.updateModel({ calloutType: value });
                            }}
                            size={70}
                        >
                            {getContentItemImageOptions([CalloutType.TEXT, CalloutType.BULLET_TEXT, CalloutType.NUMBERED_TEXT, CalloutType.LETTERED_TEXT, CalloutType.CONTENT_AND_TEXT])}
                        </ImagePopup>
                    </WithLabel>
                    <WithLabel label="Milestone Color">
                        <CollectionColorPicker element={element.milestones} />
                    </WithLabel>

                    {calloutType === CalloutType.CONTENT_AND_TEXT &&
                        <WithLabel label="Frame Style">
                            <ImagePopup value={element.model.milestones.elements[0].frameType}
                                onChange={value => {
                                    element.model.milestones.elements.forEach(itemModel => itemModel.frameType = value);
                                    element.canvas.updateCanvasModel();
                                }}
                                size={100} previewSize={50}
                            >
                                <ImageOption value="none" label="None">
                                    <StaticImage src="/images/frames/thumbnails/frame_none.png" />
                                </ImageOption>
                                <ImageOption value="rect" label="Square">
                                    <StaticImage src="/images/frames/thumbnails/Square.svg" />
                                </ImageOption>
                                <ImageOption value="circle" label="Circle">
                                    <StaticImage src="/images/frames/thumbnails/Circle.svg" />
                                </ImageOption>
                                <ImageOption value="rounded_rect" label="Rounded">
                                    <StaticImage src="/images/frames/thumbnails/Rounded.svg" />
                                </ImageOption>
                                <ImageOption value="octagon" label="Octagon">
                                    <StaticImage src="/images/frames/thumbnails/Octagon.svg" />
                                </ImageOption>

                            </ImagePopup>
                        </WithLabel>
                    }

                </PropertySection>
                <PropertySection>
                    <WithLabel label="Show Start Marker">
                        <ToggleSwitch value={element.model.showStartMarker}
                            onChange={value => element.updateModel({ showStartMarker: value })}
                        />
                    </WithLabel>
                    <WithLabel label="Show End Marker">
                        <ToggleSwitch value={element.model.showEndMarker}
                            onChange={value => element.updateModel({ showEndMarker: value })}
                        />
                    </WithLabel>
                </PropertySection>
                <PropertySection>
                    <WithLabel label="Progress">
                        <Slider value={element.model.progress}
                            onChange={value => element.updateModel({ progress: value })}
                            min={0} max={1} step={0.01}
                            percent showInput
                        />
                    </WithLabel>
                    <WithLabel label="Progress Color">
                        <ColorPicker value={element.progressColor} canvas={element.canvas}
                            showPrimary showSecondary
                            onChange={value => element.updateModel({ progressColor: value })}
                        />
                    </WithLabel>
                </PropertySection>
                <PropertySection>
                    <Button small onClick={async () => {
                        await element.distributeMilestones();
                        await element.canvas.updateCanvasModel(true);
                    }}><Icon>stacks</Icon>Auto Arrange</Button>
                </PropertySection>
            </PropertyPanelContainer>
        );
    }
}

export class Timeline2ControlBar extends Component {
    onAddNewItem = async () => {
        const { element, selectionLayerController, selectedElements, position } = this.props;

        const currentSelection = [...selectedElements];
        selectionLayerController.setSelectedElements([]);

        try {
            const milestone = element.milestones.addItem({
                type: AuthoringElementType.CALLOUT,
                calloutType: element.model.calloutType,
                x: 0.5 * element.milestones.bounds.width,
                y: 0.5 * element.milestones.bounds.height,
                width: 200,
            });

            element.milestones.connectors.addItem({
                id: `from-${milestone.id}`,
                source: milestone.id,
                target: `${element.uniquePath}`,
                // targetPoint: {x: 0.5, y: 0.5 },
                endPointIsLocked: false,
                canChangeConnectorType: false,
                startAnchor: milestone.calloutType === CalloutType.TEXT ? geom.AnchorType.LEFT : geom.AnchorType.FREE
            });

            await element.canvas.updateCanvasModel();

            selectionLayerController.setSelectedElements([element.milestones.getItemElementById(milestone.id)]);
        } catch (err) {
            selectionLayerController.setSelectedElements(currentSelection);

            ShowDialog(BadFitDialog, {
                title: "Sorry, we aren't able to fit another item to this layout",
            });
        }
    }

    onAddSpan = async () => {
        const { element } = this.props;

        if (element.spanStyle === "line") {
            element.model.spanStyle = "chevron";
        }
        element.model.spans.push({
            id: uuid()
        });
        // We're removing the decoration from the start/end of the timeline when adding a span
        // we want to be sure the color is set to the default color
        // of the slide and not the background it had before
        element.markStylesAsDirty();
        return element.canvas.updateCanvasModel().catch(err => {
            ShowDialogAsync(BadFitDialog, {
                title: "Sorry, we aren't able to fit another task on this chart",
            });
        });
    }

    render() {
        const { element, position } = this.props;

        const canAddNewItem = true; //element.addItem && (element.maxItemCount ? (element.itemCount < element.maxItemCount) : true);

        return (
            <ControlBar position={position}>
                <ControlBarGroup>
                    <Button onClick={this.onAddNewItem} disabled={!canAddNewItem}>
                        <Icon>add_circle</Icon>
                        Add Milestone
                    </Button>
                </ControlBarGroup>
                {element.spanStyle !== "line" && (
                    <ControlBarGroup>
                        <Button onClick={this.onAddSpan} disabled={!canAddNewItem}>
                            <Icon>add_circle</Icon>
                            Add Span
                        </Button>
                    </ControlBarGroup>
                )}
            </ControlBar>
        );
    }
}

// export class TimelineSpanControlBar extends Component {
//     render() {
//         const { element } = this.props;
//
//         return (
//             <ControlBar>
//                 <ItemColorPicker element={element} />
//             </ControlBar>
//         );
//     }
// }
//
// export class TimelineSpanSelection extends CollectionItemElementSelection {
//     get canResize() {
//         return true;
//     }
//
//
//     get dragResizeProps() {
//         const { element } = this.props;
//
//         const initialWidths = [];
//         let totalSliceWidth = null;
//         let availableWidth = null;
//
//         return {
//             ...defaultDragResizeProps,
//             resizeDirections: [element.isFirstItem ? null : ResizeDirection.LEFT, element.isLastItem ? null : ResizeDirection.RIGHT].filter(Boolean),
//             onDragStart: async () => {
//                 element.parentElement.itemElements.forEach(element => {
//                     initialWidths.push(element.model.width);
//                 });
//                 totalSliceWidth = element.parentElement.totalSpansWidth;
//                 availableWidth = element.parentElement.availableWidth;
//             },
//             onDrag: async ({ dragOffset, resizeDirection }) => {
//                 const elementIndex = element.itemIndex;
//
//                 const minItemWidth = element.styles.minWidth / availableWidth * totalSliceWidth;
//
//                 let offset = dragOffset.x / availableWidth * totalSliceWidth;
//
//                 if (resizeDirection === ResizeDirection.LEFT) {
//                     const maxOffset = Math.max(initialWidths[elementIndex] - minItemWidth, 0);
//                     const minOffset = -Math.max(initialWidths[elementIndex - 1] - minItemWidth, 0);
//                     offset = Math.clamp(offset, minOffset, maxOffset);
//
//                     const newWidth = initialWidths[elementIndex] - offset;
//                     const newNeighborWidth = initialWidths[elementIndex - 1] + offset;
//
//                     element.model.width = newWidth;
//                     element.parentElement.itemElements[elementIndex - 1].model.width = newNeighborWidth;
//                 } else {
//                     const maxOffset = Math.max(initialWidths[elementIndex + 1] - minItemWidth, 0);
//                     const minOffset = -Math.max(initialWidths[elementIndex] - minItemWidth, 0);
//                     offset = Math.clamp(offset, minOffset, maxOffset);
//
//                     const newWidth = initialWidths[elementIndex] + offset;
//                     const newNeighborWidth = initialWidths[elementIndex + 1] - offset;
//
//                     element.model.width = newWidth;
//                     element.parentElement.itemElements[elementIndex + 1].model.width = newNeighborWidth;
//                 }
//
//                 element.parentElement.refreshElement();
//             }
//         };
//     }
// }
//

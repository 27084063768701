import { CollectionItemElementSelection } from "./CollectionItemElementSelection";
import { defaultDragPositionProps } from "../../../../editor/PresentationEditor/DragElementManager";

export class GanttChartMilestoneSelection extends CollectionItemElementSelection {
    get canDrag() {
        return true;
    }

    get dragPositionProps() {
        const { element, canvas } = this.props;

        let dragStartProps;
        return {
            ...defaultDragPositionProps,
            dragOpacity: 0,
            dropTargets: [],
            dragAxes: ["x"],
            drop: () => {
            },
            onDragStart: async () => {
                dragStartProps = {
                    date: element.date.clone(),
                    startDate: element.parentElement.startDate.clone(),
                    endDate: element.parentElement.endDate.clone(),
                    dayWidth: element.parentElement.calculatedProps.dayWidth
                };
            },
            onDrag: async ({ dragOffset }) => {
                const { date, startDate, endDate, dayWidth } = dragStartProps;

                let newDate = date.clone().add(dragOffset.x / dayWidth, "days");
                if (newDate.isBefore(startDate)) {
                    newDate = startDate.clone();
                } else if (newDate.isAfter(endDate)) {
                    newDate = endDate.clone();
                }

                element.model.date = newDate.toDate().getTime();

                element.parentElement.refreshElement();
            }
        };
    }
}


import React from "reactn";
import { _ } from "js/vendor";
import Logos from "js/core/models/logos";
import * as geom from "js/core/utilities/geom";
import { loadImage } from "js/core/utilities/promiseHelper";

import { ELEMENT_IDS } from "common/constants";
import { getLogoPath } from "common/assetUtils";
import { isRenderer } from "js/config";

import { BaseElement } from "../base/BaseElement";
import { TextElement } from "../base/Text/TextElement";
import { BaseElementSelection } from "../../Editor/ElementSelections/BaseElementSelection";
import { FooterPropertyPanel } from "../../Editor/ElementPropertyPanels/FooterUI";

export class Footer extends BaseElement {
    get _canSelect() {
        return true;
    }

    getElementPropertyPanel() {
        return FooterPropertyPanel;
    }

    getElementSelection() {
        return BaseElementSelection;
    }

    get theme() {
        return this.canvas.getTheme();
    }

    get showMessage() {
        if (this.model.showMessageOverride !== undefined) {
            return this.model.showMessageOverride;
        } else {
            return this.theme.get("showMessage");
        }
    }

    get showPageNum() {
        if (this.model.showPageNumOverride !== undefined) {
            return this.model.showPageNumOverride;
        } else {
            return this.theme.get("showPageNum");
        }
    }

    get showLogo() {
        if (this.logoType === false) return false;
        if (this.model.showLogoOverride !== undefined) {
            return this.model.showLogoOverride;
        } else {
            return this.theme.get("showLogo");
        }
    }

    get logoPosition() {
        if (this.model.logoPositionOverride) {
            return this.model.logoPositionOverride;
        } else {
            return this.theme.get("logoPosition") || "left";
        }
    }

    get logoScale() {
        return this.theme.get("logoScale") || 1;
    }

    get logoOffset() {
        return this.theme.get("logoOffset") || 0;
    }

    get hasDefaultLogo() {
        return !_.isEmpty(this.theme.get("logo"));
    }

    get hasAltLogo() {
        return !_.isEmpty(this.theme.get("logo_dark"));
    }

    get logoType() {
        if (!this.hasDefaultLogo && !this.hasAltLogo) {
            return false;
        }

        if (this.hasDefaultLogo && !this.hasAltLogo) {
            return "light";
        }
        if (this.hasAltLogo && !this.hasDefaultLogo) {
            return "dark";
        }

        if (this.model.logoTypeOverride) {
            return this.model.logoTypeOverride;
        } else {
            let backgroundColor = this.getBackgroundColor();
            if ((backgroundColor == "backgroundImage" || backgroundColor.isDark()) && this.hasAltLogo) {
                return "dark";
            } else {
                return "light";
            }
        }
    }

    get overrideStyle() {
        return this.model.overrideStyle || "auto";
    }

    get fitToContents() {
        return {
            width: false,
            height: true
        };
    }

    get resizeDirections() {
        return {
            left: true, right: true,
            top: false, bottom: false
        };
    }

    get needsFullSizeToCalcFit() {
        return {
            width: false,
            height: true
        };
    }

    get fitToContentsAnchor() {
        return { height: geom.AnchorType.BOTTOM };
    }

    calcSlideNum() {
        return (
            isRenderer
                ? (this.canvas.getSlideIndex() + 1)
                : this.canvas.slide.presentation?.isSlideSkipped(this.canvas.slide)
                    ? "X"
                    : (this.canvas.getSlideIndex() + 1)
        );
    }

    _build() {
        let theme = this.canvas.getTheme();

        if (this.showMessage) {
            this.message = this.addElement("message", () => FooterMessage, {
                model: {
                    message: theme.get("footerMessage")
                },
                spellcheck: false,
                canRollover: false,
                canEdit: false
            });
        }

        if (this.showPageNum) {
            this.slideNum = this.addElement(ELEMENT_IDS.SLIDE_NUM, () => TextElement, {
                model: {
                    slideNum: this.calcSlideNum(),
                },
                autoWidth: true,
                canRollover: false,
                canEdit: false,
                isTabbable: false
            });
        }
    }

    getPath() {
        let theme = this.canvas.getTheme();
        let url;

        if (this.logoType == "dark") {
            url = theme.get("logo_dark");
        } else {
            url = theme.get("logo");
        }

        return getLogoPath(url);
    }

    refreshElement(transition) {
        this.canvas.refreshElement(this, transition);
    }

    get canRefreshElement() {
        return true;
    }

    async _load() {
        if (this.showLogo) {
            // determine whether to show light or dark logo (if available) based on background color
            let path = this.getPath();

            if (!this.lastLogoPath || this.lastLogoPath !== path) {
                this.lastLogoPath = path;

                this.url = await Logos.getSignedUrlAndLoad(path);

                let image = await loadImage(this.url);
                this.nativeLogoSize = new geom.Size(image.naturalWidth, image.naturalHeight);
            }
        }
    }

    _calcProps(props, options) {
        let { size } = props;

        if (this.showLogo && this.nativeLogoSize) {
            let hasLogoFrame = this.canvas.getBackgroundColor().name != "background_light" && !this.hasAltLogo;

            let scale;
            if (this.nativeLogoSize.aspectRatio < 1.5) {
                scale = size.height / this.nativeLogoSize.height;
            } else {
                scale = (size.height - this.styles.logo.paddingBottom) / this.nativeLogoSize.height;
                if (this.nativeLogoSize.width * scale > 100) {
                    scale = 100 / this.nativeLogoSize.width;
                }
            }

            scale *= this.logoScale;

            let logoWidth = this.nativeLogoSize.width * scale;
            let logoHeight = this.nativeLogoSize.height * scale;

            let logoY = size.height / 2 - logoHeight / 2;

            if (logoY + logoHeight > size.height - this.styles.logo.paddingBottom) {
                logoY = size.height - logoHeight - this.styles.logo.paddingBottom;
            }

            if (this.logoPosition == "right") {
                props.logoBounds = new geom.Rect(
                    size.width - logoWidth - this.styles.logo.paddingLeft,
                    logoY,
                    logoWidth,
                    logoHeight
                ).offset(-this.logoOffset, -this.logoOffset);

                if (hasLogoFrame) {
                    props.logoFrameBounds = props.logoBounds.inflate({
                        left: size.width - props.logoBounds.right,
                        right: size.width - props.logoBounds.right,
                        top: size.height - props.logoBounds.bottom,
                        bottom: size.height - props.logoBounds.bottom
                    });
                }

                if (this.canvas.showBrandingWatermark()) {
                    props.logoBounds.left -= 140;
                    if (hasLogoFrame) {
                        props.logoFrameBounds.left -= 140;
                    }
                }
            } else {
                props.logoBounds = new geom.Rect(
                    this.styles.logo.paddingLeft,
                    logoY,
                    logoWidth,
                    logoHeight
                ).offset(this.logoOffset, -this.logoOffset);

                if (hasLogoFrame) {
                    props.logoFrameBounds = props.logoBounds.inflate({
                        left: props.logoBounds.left,
                        right: props.logoBounds.left,
                        top: size.height - props.logoBounds.bottom,
                        bottom: size.height - props.logoBounds.bottom
                    });
                }
            }
        }

        if (this.showPageNum) {
            // Refresh the page number in case it's changed.
            this.slideNum.model.slideNum.blocks[0].html = this.calcSlideNum();

            let slideNumProps = this.slideNum.calcProps(new geom.Size(100, size.height));

            if (this.logoPosition == "right") {
                slideNumProps.bounds = new geom.Rect(0, size.height / 2 - slideNumProps.size.height / 2, slideNumProps.size);
            } else {
                slideNumProps.bounds = new geom.Rect(size.width - slideNumProps.size.width, size.height / 2 - slideNumProps.size.height / 2, slideNumProps.size);

                if (this.canvas.showBrandingWatermark()) {
                    slideNumProps.bounds.left -= 140;
                }
            }
        }

        if (this.showMessage) {
            let messageProps = this.message.calcProps(new geom.Size(size.width - 300, size.height));
            messageProps.bounds = new geom.Rect(150, 0, size.width - 300, size.height);
        }

        return { size };
    }

    renderChildren(transition) {
        let props = this.calculatedProps;
        let renderChildren = super.renderChildren(transition);

        if (props.logoFrameBounds) {
            let frameStyle = {
                position: "absolute",
                background: "white",
                ...props.logoFrameBounds.toObject()
            };
            renderChildren.push(<div key="logo-frame" style={frameStyle}></div>);
        }

        if (props.logoBounds) {
            let logoStyle = {
                position: "absolute",
                ...props.logoBounds.toObject()
            };
            renderChildren.push(<img key="logo" style={logoStyle} src={this.url} />);
        }

        return renderChildren;
    }

    _getBackgroundColor(forElement) {
        switch (this.overrideStyle) {
            case "auto":
                return this.canvas.layouter.canvasElement.elements.primary.getBackgroundColor(this);
            case "light":
                return this.canvas.getTheme().palette.getColor("primary_dark");
            case "dark":
            default:
                return this.canvas.getTheme().palette.getColor("primary_light");
        }
    }

    get disableAnimationsByDefault() {
        return true;
    }
}

class FooterMessage extends TextElement {
    get _canSelect() {
        return false;
    }

    get _canRollover() {
        return false;
    }
}

export const elements = {
    Footer
};

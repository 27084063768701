import * as geom from "js/core/utilities/geom";
import { _ } from "js/vendor";

import { AuthoringBlockType, BlockStructureType, TextStyleType } from "common/constants";
import { detectCompareContent } from "js/core/services/sharedModelManager";
import { SliceChartItemControlBar, SliceChartItemSelection, SliceChartPropertyPanel } from "../../Editor/ElementPropertyPanels/SliceChartUI";
import { BaseElement } from "../base/BaseElement";
import { CollectionElement, CollectionItemElement } from "../base/CollectionElement";
import { SVGRectElement } from "../base/SVGElement";
import { TextElement } from "../base/Text/TextElement";

class SliceChart extends CollectionElement {
    static get schema() {
        return {
            showSecondaryText: true
        };
    }

    getElementPropertyPanel() {
        return SliceChartPropertyPanel;
    }

    getChildItemType() {
        return SliceChartItem;
    }

    get defaultItemData() {
        return {
            width: 1
        };
    }

    get maxItemCount() {
        return 8;
    }

    get canRefreshElement() {
        return true;
    }

    refreshElement(transition) {
        this.canvas.refreshElement(this, transition);
    }

    _calcProps(props, options) {
        let { size, children } = props;
        this.availableWidth = size.width - (this.itemElements.length - 1) * this.styles.hGap;
        this.totalSliceWidth = _.sumBy(this.itemElements, item => item.model.width);

        let descriptionHeight = 0;
        if (this.model.showSecondaryText) {
            descriptionHeight = _.max(this.itemElements.map(el => el.calcDescriptionProps(new geom.Size(this.getSliceWidth(el.model.width), size.height)).size.height));
        }

        let maxHeight = 0;
        for (let item of this.itemElements) {
            let itemWidth = this.getSliceWidth(item.model.width);
            let itemProps = item.calcProps(new geom.Size(itemWidth, size.height), { descriptionHeight });
            // itemProps.bounds = new geom.Rect(0, 0, itemProps.size);
            maxHeight = Math.max(maxHeight, itemProps.size.height);
        }

        // for (let item of this.itemElements) {
        //     let itemWidth = this.getSliceWidth(item.model.width);
        //     let itemProps = item.calcProps(new geom.Size(itemWidth, size.height));
        //     maxHeight = Math.max(maxHeight, itemProps.size.height);
        // }
        let offsetY = size.height / 2 - maxHeight / 2;
        let offsetX = 0;
        for (let item of this.itemElements) {
            item.calculatedProps.bounds = new geom.Rect(offsetX, offsetY, item.calculatedProps.size);
            offsetX += item.calculatedProps.bounds.width + this.styles.hGap;
        }

        props.isFit = this.itemCount <= this.styles.maxItemCount;

        return { size };
    }

    getSliceWidth(gridWidth) {
        return gridWidth / this.totalSliceWidth * this.availableWidth;
    }

    _exportToSharedModel() {
        const values = this.itemElements.map(item => item.model.width);

        const textContent = this.itemElements.map(item => {
            const labelContent = item.shapeContainer.label._exportToSharedModel().textContent[0];
            const descriptionContent = item.description?._exportToSharedModel().textContent[0];
            return {
                mainText: labelContent.mainText,
                secondaryTexts: [
                    ...labelContent.secondaryTexts,
                    ...(descriptionContent ? [descriptionContent.mainText, ...descriptionContent.secondaryTexts] : [])
                ]
            };
        });

        const compareContent = this.itemElements.map((item, i) => ({
            value: values[i], text: textContent[i],
            format: "number", emphasized: !!item.model.hilited
        }));

        return { textContent, compareContent, collectionColor: this.collectionColor };
    }

    _importFromSharedModel(model) {
        const compareContent = detectCompareContent(model);
        if (!compareContent?.length) return;

        const items = compareContent.map(({ text, value, emphasized }) => {
            const mainText = text.mainText;
            const secondaryTexts = text.secondaryTexts || [];
            return {
                hilited: !!emphasized,
                width: Math.max(30, value ?? Math.round(Math.random() * (80 - 40) + 40)),
                label: {
                    blocks: [
                        {
                            html: mainText.text,
                            textStyle: TextStyleType.TITLE,
                            type: AuthoringBlockType.TEXT,
                        },
                        ...secondaryTexts.slice(0, secondaryTexts.length - 1).map(({ text }) => ({
                            html: text,
                            textStyle: TextStyleType.BODY,
                            type: AuthoringBlockType.TEXT,
                        }))
                    ]
                },
                ...(secondaryTexts.length > 1 ? {
                    description: {
                        blocks: secondaryTexts.slice(-1).map(({ text }) => ({
                            html: text,
                            textStyle: TextStyleType.BODY,
                            type: AuthoringBlockType.TEXT,
                        }))
                    }
                } : {}),
            };
        });

        items.splice(this.maxItemCount);
        return { items, showSecondaryText: items.some(item => !!item.description), collectionColor: model.collectionColor };
    }
}

class SliceChartItem extends CollectionItemElement {
    static get schema() {
        return {
            width: 1
        };
    }

    getElementSelection() {
        return SliceChartItemSelection;
    }

    getElementControlBar() {
        return SliceChartItemControlBar;
    }

    get selectionPadding() {
        return { left: 0, right: 0, top: 0, bottom: 20 };
    }

    _build() {
        this.shapeContainer = this.addElement("shapeContainer", () => SliceChartShape);

        if (this.parentElement.model.showSecondaryText) {
            this.description = this.addElement("description", () => TextElement, {
                blockStructure: BlockStructureType.TITLE_AND_BODY,
                defaultBlockTextStyle: TextStyleType.BODY,
                autoHeight: true,
                syncFontSizeWithSiblings: true
            });
        }
    }

    get sliceHeight() {
        return this.parentElement.model.sliceHeight ?? this.styles.sliceHeight;
    }

    _calcProps(props, options) {
        let { size } = props;

        const availableShapeHeight = size.height - options.descriptionHeight;

        const shapeWidth = this.isAnimating ? size.width * this.animationState.value : size.width;
        const shapeProps = this.shapeContainer.calcProps(new geom.Size(shapeWidth, this.sliceHeight * availableShapeHeight));
        shapeProps.bounds = new geom.Rect(0, 0, shapeProps.size);
        let calculatedSize = new geom.Size(size.width, shapeProps.size.height);

        if (this.parentElement.model.showSecondaryText) {
            const descriptionProps = this.description.calcProps(new geom.Size(size.width, size.height - shapeProps.size.height), options);
            descriptionProps.bounds = new geom.Rect(0, this.sliceHeight * availableShapeHeight, size.width, descriptionProps.size.height);

            calculatedSize.height += descriptionProps.size.height;
        }

        return { size: calculatedSize };
    }

    calcDescriptionProps(size) {
        return this.description.calcProps(size);
    }

    get animationElementName() {
        return `Slice #${this.itemIndex + 1}`;
    }

    _getAnimations() {
        return [{
            name: "Grow in",
            prepare: () => {
                this.shapeContainer.label.animationState.fadeInProgress = 0;
                if (this.parentElement.model.showSecondaryText) {
                    this.description.animationState.fadeInProgress = 0;
                }
                this.animationState.value = 0;
                this.parentElement.animationTextScale = this.parentElement.minTextScale;
            },
            onBeforeAnimationFrame: progress => {
                this.shapeContainer.label.animationState.fadeInProgress = Math.clamp((progress - 0.7) / 0.3, 0, 1);
                if (this.parentElement.model.showSecondaryText) {
                    this.description.animationState.fadeInProgress = Math.clamp((progress - 0.7) / 0.3, 0, 1);
                }
                this.animationState.value = progress;
                return this;
            }
        }];
    }

    _migrate_10() {
        this.model.description = this.model.body;
        delete this.model.body;
    }
}

class SliceChartShape extends BaseElement {
    get _canRollover() {
        return false;
    }

    _build() {
        this.shape = this.addElement("shape", () => SVGRectElement);
        this.label = this.addElement("label", () => TextElement, {
            blockStructure: BlockStructureType.TITLE_AND_BODY,
            autoHeight: true,
            scaleTextToFit: this.isAnimating ? false : true,
            syncFontSizeWithSiblings: true,
            backgroundElement: this.shape
        });
    }

    _calcProps(props, options) {
        const { size } = props;

        const shapeProps = this.shape.calcProps(size);
        shapeProps.bounds = new geom.Rect(0, 0, size);

        const labelProps = this.label.calcProps(size);
        labelProps.bounds = new geom.Rect(0, size.height / 2 - labelProps.size.height / 2, size.width, labelProps.size.height);

        return { size };
    }
}

export const elements = {
    SliceChart
};

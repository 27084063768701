import React, { Component } from "react";
import styled from "styled-components";
import { themeColors } from "../../../../react/sharedStyles";
import { FlexSpacer } from "../../../../react/components/Gap";

import { PropertySection } from "../../../../EditorComponents/PropertyPanel";
import { PropertyPanelHeader } from "../../../../editor/ElementEditor/PropertyPanelHeader";

export const ElementName = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    padding: 12px 15px;
    background: #444;

    label {
        text-transform: uppercase;
        color: #ddd;
        //color: white;
        letter-spacing: 0.5px;
        font-size: 14px;
        font-weight: 700;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 150px;
    }

    div {
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 10px;
        font-weight: 500;
    }

    .bai-icon {
        color: ${themeColors.ui_blue};
        font-size: 20px;
    }

`;

class AuthoringElementHeader extends Component {
    render() {
        const { element, name, control } = this.props;
        return (
            <>
                <PropertyPanelHeader>
                    {name ?? element?.name}
                    <FlexSpacer />
                    {control}
                </PropertyPanelHeader>
                {this.props.children}
            </>
        );
    }
}

export default AuthoringElementHeader;

import React from "react";

import { AssetType, DecorationStyle, ShapeType } from "common/constants";
import { findElementsWithImages } from "js/core/services/slideModelBuilder";
import { ClipboardType } from "js/core/utilities/clipboard";
import * as geom from "js/core/utilities/geom";
import { getClipPath } from "js/core/utilities/svgHelpers";
import Spinner from "js/react/components/Spinner";
import { _ } from "js/vendor";

import {
    ContentElementDefaultOverlay,
    ContentElementSelection
} from "../../Editor/ElementPropertyPanels/MediaUI";
import { DeviceFrames } from "../DeviceFrames";
import { BaseElement } from "./BaseElement";
import { Icon } from "./MediaElements/IconElement";
import { Logo, Picture } from "./MediaElements/PictureElement";
import { VideoElement } from "./MediaElements/VideoElement";

class ContentElement extends BaseElement {
    getElementSelection() {
        return this.options.elementSelection ?? ContentElementSelection;
    }

    get allowElementSnap() {
        return true;
    }

    get _canSelect() {
        return true;
    }

    get _canRollover() {
        return false;
    }

    get bindTo() {
        return this.options.bindTo || "content_value";
    }

    get contentType() {
        return this.model["content_type"];
    }

    get contentValue() {
        return this.model[this.bindTo];
    }

    get canDropImage() {
        return !this.options.disableDropImage;
    }

    setDisableDropImage(disableDropImage) {
        this.options.disableDropImage = disableDropImage;
    }

    get clipboardElement() {
        return this.assetElement;
    }

    getClipboardData() {
        return { [ClipboardType.ASSET]: this.model };
    }

    get canPasteImage() {
        return true;
    }

    get asset() {
        if (this.assetElement) {
            return this.assetElement.asset;
        }
    }

    get defaultAssetType() {
        return this.options.defaultAssetType || this.contentType || AssetType.IMAGE;
    }

    get hasAsset() {
        return this.asset != null;
    }

    get showInlineAddMediaButton() {
        return true;
    }

    getContentSize() {
        switch (this.assetElement.type) {
            case "Picture":
                if (this.asset && this.asset.getImageType() == AssetType.LOGO) {
                    return new geom.Size(200, 200);
                } else {
                    return this.assetElement.mediaSize;
                }
            case "VideoElement":
                return this.assetElement.mediaSize;
            case AssetType.ICON:
            case AssetType.LOGO:
            default:
                return new geom.Size(200, 200);
        }
    }

    get aspectRatio() {
        return "fill";
    }

    get constrainAspectRatio() {
        return this.asset.constrainAspectRatio || "fit";
    }

    get hasValidAsset() {
        if (!this.contentType || this.contentType.equalsAnyOf(AssetType.IMAGE, AssetType.ICON, AssetType.LOGO)) {
            if (this.assetElement && this.assetElement instanceof Picture && this.assetElement.hasMedia == false) {
                return false;
            }
            return !_.isEmpty(this.contentValue);
        } else {
            return true;
        }
    }

    get selectionPadding() {
        return 0;
    }

    get isBackgroundVisible() {
        if (this.contentType == AssetType.ICON) {
            return true;
        }
        if (this.assetElement.hasAlpha) {
            return true;
        }
        if (this.options.allowImageScaling && this.assetElement.isScaledBelowFill && !this.assetElement.hasSolidBackground) {
            return true;
        }
        return false;
    }

    get hasAlpha() {
        return this.hasAsset && (this.contentType === AssetType.ICON || this.assetElement.hasAlpha);
    }

    get hasSolidBackground() {
        return this.hasAsset && this.assetElement.hasSolidBackground && !this.hasAlpha;
    }

    get assetType() {
        return this.contentType || this.options.defaultAssetType || AssetType.IMAGE;
    }

    get assetTypeLabel() {
        switch (this.assetType) {
            case AssetType.ICON:
                return "Icon";
            case AssetType.IMAGE:
                return "Image";
            case AssetType.LOGO:
                return "Logo";
            case AssetType.VIDEO:
                return "Video";
            case AssetType.STOCK_VIDEO:
                return "Stock Video";
        }
    }

    get assetIconScale() {
        return null; // use style iconScale setting unless overridden
    }

    get isVideo() {
        return this.contentType === AssetType.VIDEO || this.contentType === AssetType.STOCK_VIDEO;
    }

    get _showDefaultOverlay() {
        return !this.hasValidAsset;
    }

    getElementDefaultOverlay() {
        if (!this.disabled) {
            return ContentElementDefaultOverlay;
        }
    }

    setDisabled(disabled) {
        this.disabled = disabled;
        if (this.isVideo) {
            this.assetElement.disabled = disabled;
        }
    }

    deleteAsset() {
        delete this.model.content_value;
        delete this.model.content_type;
        delete this.model.aoiBottom;
        delete this.model.aoiLeft;
        delete this.model.aoiRight;
        delete this.model.aoiTop;
        delete this.model.assetProps;
        delete this.model.assetName;
        delete this.model.assetWidth;
        delete this.model.assetHeight;
        delete this.model.filter;
        delete this.model.filterBlur;
        delete this.model.filterContrast;
        delete this.model.scale;
        delete this.model.mediaOpacity;
    }

    get frameType() {
        if (this.model.frameType == "theme") {
            return this.canvas.getTheme().get("styleShape");
        } else {
            return this.model.frameType;
        }
    }

    get decorationStyle() {
        if (this.model.decorationStyle &&
            this.model.frameType &&
            DeviceFrames.findById(this.frameType)?.category === "Shape") {
            return this.model.decorationStyle;
        } else {
            return this.parentElement.decorationStyle;
        }
    }

    _loadStyles(styles) {
        // if (this.options.overflow) {
        // styles.overflow = "hidden"; // this is necessary to clip the content when adjusting scale and from ken burns animations
        // }
    }

    _build() {
        switch (this.assetType) {
            case AssetType.ICON:
                this.assetElement = this.addElement("asset", () => Icon, {
                    iconScale: this.assetIconScale,
                    allowBackdrop: this.options.allowBackdrop,
                    canDoubleClickToEdit: this.isOnAuthoringCanvas ? false : this.canEdit && (this.options.canDoubleClickToEdit != false),
                    forceIconColor: this.options.forceIconColor,
                    hasShapeFrame: this.options.hasShapeFrame,
                    backgroundElement: this.options.backgroundElement
                });
                break;
            case AssetType.IMAGE:
                this.assetElement = this.addElement("asset", () => Picture, {
                    bindTo: this.bindTo,
                    allowScaleBelowFill: this.options.allowScaleBelowFill,
                    allowImageScaling: this.options.allowImageScaling ?? true,
                    allowBackdrop: this.options.allowBackdrop,
                    canDoubleClickToEdit: this.canEdit && (this.options.canDoubleClickToEdit != false)
                });
                break;
            case AssetType.LOGO:
                this.assetElement = this.addElement("asset", () => Logo, {
                    bindTo: this.bindTo,
                    defaultScale: this.options.defaultLogoScale || 0.66,
                    allowScaleBelowFill: this.options.allowScaleBelowFill,
                    allowImageScaling: this.options.allowImageScaling ?? true,
                    allowBackdrop: this.options.allowBackdrop,
                    canDoubleClickToEdit: this.canEdit && (this.options.canDoubleClickToEdit != false)
                });
                break;
            case AssetType.VIDEO:
            case AssetType.STOCK_VIDEO:
                const {
                    assetProps: {
                        controls,
                        loop,
                        autoPlay,
                        duration,
                        start,
                        end,
                        speed,
                        muted,
                    },
                    content_value,
                } = this.model;
                this.assetElement = this.addElement("asset", () => VideoElement, {
                    bindTo: this.bindTo,
                    content_value,
                    controls,
                    loop,
                    autoPlay,
                    duration,
                    start,
                    end,
                    speed,
                    muted,
                    allowScaleBelowFill: this.options.allowScaleBelowFill,
                    allowImageScaling: this.options.allowImageScaling,
                    canSelect: this.options.canSelect
                });
                break;
        }
    }

    hitCheck(pt) {
        if (this.styles.decoration && this.styles.decoration.shape === ShapeType.CIRCLE) {
            let r = Math.min(this.calculatedSize.width, this.calculatedSize.height) / 2;
            return pt.minus(r, r).magnitude() <= r;
        } else {
            let w = this.calculatedSize.width;
            let h = this.calculatedSize.height;
            return pt.x <= w && pt.x >= 0 && pt.y <= h && pt.y >= 0;
        }
    }

    _calcProps(props, options = {}) {
        const { size } = props;

        if (this.assetType === AssetType.ICON && this.hasValidAsset && this.decoration) {
            this.assetElement.options.backgroundElement = this.decoration;
        }

        const fitAsset = this.assetType === AssetType.ICON ? true : options.fitAsset;

        let assetSize = size.deflate(options.framePadding ?? 0);

        if (this.assetType === AssetType.ICON && options.forceIconSize) {
            assetSize = options.forceIconSize;
        }

        const assetProps = this.assetElement.calcProps(assetSize, { ...options, fitAsset });

        assetProps.bounds = new geom.Rect(0, 0, assetProps.size).centerInRect(new geom.Rect(0, 0, size));

        if (this.decoration) {
            assetProps.clipPath = getClipPath(this.decoration.styles.shape, new geom.Rect(0, 0, size), this.decoration.styles.cornerRadius, this.isVideo, this.canvas.getScale());
        }

        return { size };
    }

    renderChildren(transition) {
        const children = super.renderChildren(transition);

        const isLoadingImages = findElementsWithImages(this.model).length > 0;
        if (isLoadingImages) {
            children.push(<Spinner key="spinner" />);
        }

        return children;
    }

    _applyColors() {
        // this.colorSet.decorationColor = this.palette.getColor(this.model.color ?? this.model.frameColor, this.getBackgroundColor(), { allowColorOnColor: this.allowColorOnColor });

        switch (this.assetType) {
            case AssetType.ICON:
                if (this.decorationStyle == DecorationStyle.FILLED && this.frameType != "none" && this.palette.isColor(this.getBackgroundColor())) {
                    // the icon is on a shape decoration
                    this.assetElement.colorSet.iconColor = this.palette.getColor("primary", this.getDecorationColor());
                } else {
                    // the icon is on the background color
                    this.assetElement.colorSet.iconColor = this.palette.getColor(this.model.iconColor ?? this.model.color ?? this.findClosestOfType("CollectionElement")?.collectionColor, this.getBackgroundColor(), { itemIndex: this.itemIndex, allowColorOnColor: this.options.allowColorOnColor });
                }
                break;
            case AssetType.IMAGE:
                this.colorSet.backgroundColor = { name: "background-image", isDark: () => true, getAlpha: () => 1 };
                break;
            case AssetType.LOGO:
                break;
        }
    }

    _exportToSharedModel() {
        if (!this.hasValidAsset) return { assets: [] };

        const assets = [{
            type: this.model.content_type,
            value: this.model.content_value,
            name: this.model.assetName,
            props: this.model.assetProps,
            configProps: _.omit(this.model, ["content_type", "content_value", "assetName", "assetProps", "frameType", "text"])
        }];

        return { assets };
    }

    _importFromSharedModel(model) {
        const { assets } = model;
        if (!assets?.length) return;

        return {
            content_type: assets[0].type,
            content_value: assets[0].value,
            assetName: assets[0].name,
            assetProps: assets[0].props,
            ...(assets[0].configProps ?? {})
        };
    }
}

class AutoSizeContentElement extends ContentElement {
    get _canSelect() {
        return true;
    }

    get _canRollover() {
        return true;
    }

    get defaultOverlayType() {
        return "ContentElementDefaultOverlay";
    }

    get fitWidth() {
        return Boolean(this.options.fitWidth);
    }

    get fitHeight() {
        return Boolean(this.options.fitHeight);
    }

    get showBackdrop() {
        return this.options.showBackdrop ?? this.model.showBackdrop ?? this.options.defaultShowBackdrop ?? false;
    }

    _loadStyles(styles) {
        styles.paddingLeft = styles.paddingRight = 16;
        styles.paddingTop = styles.paddingBottom = 10;
    }

    _calcProps(props, options) {
        let { size } = props;

        if (this.showBackdrop) {
            this.createDecoration({
                type: "frame",
                shape: "rect",
                fillColor: "white"
            });
        }

        // fit the logo into the availableSize
        let assetSize;

        if (this.assetElement.mediaSize && this.hasValidAsset) {
            assetSize = this.assetElement.mediaSize;
        } else {
            assetSize = size.square();
        }

        let defaultScale;
        if (this.fitWidth) {
            defaultScale = size.width / assetSize.width;
        } else if (this.fitHeight) {
            defaultScale = size.height / assetSize.height;
        } else {
            defaultScale = Math.min(size.width / assetSize.width, size.height / assetSize.height);
        }

        assetSize = assetSize.scale(defaultScale);

        let assetProps = this.assetElement.calcProps(assetSize, { ...options, fitAsset: true });
        assetProps.bounds = new geom.Rect(0, 0, assetSize);

        return { size: assetSize };
    }

    _applyColors() {
        if (this.showBackdrop) {
            this.decoration.colorSet.fillColor = this.palette.getColor("white");
        }
    }
}

export { AutoSizeContentElement, ContentElement };


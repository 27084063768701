import anime from "animejs";

import {
    AssetType,
    AuthoringBlockType,
    BlockStructureType,
    ContentBlockType,
    HorizontalAlignType,
    ListStyleType,
    PositionType,
    TextStyleType,
    VerticalAlignType
} from "common/constants";
import * as geom from "js/core/utilities/geom";
import { _ } from "js/vendor";

import { delay } from "../../../../../core/utilities/promiseHelper";
import { TextFrameBoxSelection } from "../../../Editor/ElementPropertyPanels/TextFrameBoxUI";
import { playActionTextAnimation } from "../../elements/ActionText/animations/ActionTextAnimations";
import { BaseElement } from "../BaseElement";
import { GetBlockModelsFromMigratedHtml, TextElement } from "./TextElement";

export class TextFrame extends BaseElement {
    get _canSelect() {
        return false;
    }

    refreshElement(transition) {
        this.canvas.refreshElement(this, transition);
    }

    get canRefreshElement() {
        return this.options.canRefreshElement ?? true;
    }

    get isOverImage() {
        if (this.options.backgroundElement?.isInstanceOf("ContentElement") && this.options.backgroundElement.model.content_type == AssetType.ICON) {
            return false;
        }
        return this.options.backgroundElement?.isInstanceOf("ContentElement") ?? false;
    }

    get text() {
        return this.textFrameBox.text;
    }

    get canDelete() {
        return this.options.canDelete ?? true;
    }

    get fillFrame() {
        return this.options.fillFrame ?? false;
    }

    get canDragPosition() {
        return this.options.canDragPosition ?? true;
    }

    get canDragResize() {
        return this.options.canDragResize ?? true;
    }

    get hasUserPosition() {
        return this.canDragPosition && this.userPositionX != null && this.userPositionY != null;
    }

    get hasBackdrop() {
        return !!this.model.backdropColor && this.isOverImage;
    }

    get backdropStyle() {
        return this.model.backdropStyle;
    }

    get userWidth() {
        return this.model.userWidth;
    }

    get userPositionX() {
        return this.model.userPositionX;
    }

    get userPositionY() {
        return this.model.userPositionY;
    }

    get textPosition() {
        if (this.hasUserPosition) {
            return PositionType.USER;
        } else if (this.options.textPosition) {
            return this.options.textPosition;
        } else {
            return this.model.textPosition ?? PositionType.CENTER;
        }
    }

    _build() {
        this.textFrameBox = this.addElement("textFrameBox", () => TextFrameBox, {
            ...this.options.textOptions,
            backgroundElement: this.hasBackdrop ? null : this.options.backgroundElement, // pass the background element to the textFrameBox if it has no backdrop
        });
    }

    _calcProps(props, options) {
        let { size } = props;

        let innerPadding = 0;
        if (!this.hasUserPosition) {
            // determine the inner padding of the textFrame when using textPosition to position the box
            // this can be different depending on whether the TextFrameBox has a backdrop or now
            if (this.hasBackdrop) {
                innerPadding = this.styles.innerPaddingWithBackdrop ?? 20;
            } else {
                innerPadding = this.styles.innerPaddingWithNoBackdrop ?? 30;
            }
            if (size.height < 50 || size.width < 205) {
                innerPadding = 15;
            } else if (size.height < 120) {
                innerPadding = 20;
            }
        }

        // if the user has defined a width, we will use that to fit the text within - otherwise we will use autoWidth to calc the textWidth
        let autoWidth = !this.userWidth;

        // this the special caption style when setting to top/bottom and will fill the width of the frame
        if ([PositionType.TOP, PositionType.BOTTOM].includes(this.textPosition) && this.textFrameBox.hasBackdrop) {
            innerPadding = 0;
            autoWidth = false;
        }

        let innerBounds = new geom.Rect(0, 0, size).deflate(innerPadding);
        let availableTextBoxSize = innerBounds.size;

        if (this.userWidth) {
            if (this.userWidth == "auto") {
                // special case to handle migration from v10
                this.model.userWidth = availableTextBoxSize.width / 2;
            }
            availableTextBoxSize.width = Math.min(availableTextBoxSize.width, this.userWidth);
        }

        let textFrameBoxProps = this.textFrameBox.calcProps(availableTextBoxSize, {
            forceTextScale: options.forceTextScale,
            textAlign: options.textAlign,
            autoWidth
        });

        if (this.hasUserPosition) {
            // user positioned, limiting to the available bounds
            const userPositionX = Math.clamp(this.userPositionX * size.width, innerBounds.left, innerBounds.width - textFrameBoxProps.size.width) / size.width;
            const userPositionY = Math.clamp(this.userPositionY * size.height, innerBounds.top, innerBounds.height - textFrameBoxProps.size.height) / size.height;
            if (this.userPositionX !== userPositionX || this.userPositionY !== userPositionY) {
                this.model.userPositionX = userPositionX;
                this.model.userPositionY = userPositionY;
            }
            textFrameBoxProps.bounds = new geom.Rect(userPositionX * size.width, userPositionY * size.height, textFrameBoxProps.size);
        } else {
            // auto positioned using textPosition
            textFrameBoxProps.bounds = new geom.Rect(0, 0, textFrameBoxProps.size).positionInContainer(innerBounds, this.textPosition);

            // adjust to cover anti-aliased edge
            if (this.textPosition == PositionType.BOTTOM) {
                textFrameBoxProps.bounds.top += 1;
            } else if (this.textPosition == PositionType.TOP) {
                textFrameBoxProps.bounds.top -= 1;
            }
        }

        return { size, scale: textFrameBoxProps.scale, innerBounds };
    }

    _exportToSharedModel() {
        return this.textFrameBox.text._exportToSharedModel();
    }

    _importFromSharedModel(model) {
        return this.textFrameBox.text._importFromSharedModel(model);
    }

    _migrate_10_02() {
        let userWidth = this.model.userWidth;
        if (!userWidth && this.model.textPosition && (this.model.textPosition.contains("left") || this.model.textPosition.contains("right"))) {
            this.model.userWidth = "auto"; // we need to migrate the text userWidth to 50% for left/right text
        }

        let setBlocksFontColor = fontColor => {
            if (!this.model.text?.blocks?.length) {
                return;
            }

            for (let block of this.model.text.blocks) {
                if (
                    !block.fontColor ||
                    // Legacy value
                    block.fontColor === "auto"
                ) {
                    block.fontColor = fontColor;
                }
            }
        };

        if (this.isOverImage) {
            switch (this.model.textStyle) {
                case "white_box":
                    this.model.backdropColor = "white";
                    break;
                case "transparent_light_box":
                    this.model.backdropColor = "white";
                    this.model.backdropOpacity = 0.5;
                    break;
                case "transparent_dark_box":
                    this.model.backdropColor = "background_dark";
                    this.model.backdropOpacity = 0.3;
                    setBlocksFontColor("white");
                    break;
                case "white_text_with_shadow":
                    this.model.shadow = {
                        x: 2,
                        y: 4,
                        blur: 6,
                        color: "rgba(0,0,0,.25)"
                    };
                    setBlocksFontColor("white");
                    break;
                case "dark_text":
                    this.model.backdropColor = null;
                    setBlocksFontColor("black");
                    break;
                case "white_text":
                default:
                    this.model.backdropColor = null;
                    setBlocksFontColor("white");
                    break;
            }
            delete this.model.textStyle;
        }
    }
}

export class TextFrameBox extends BaseElement {
    static get schema() {
        return {
            backdropPadding: 30,
            verticalAlign: VerticalAlignType.MIDDLE
        };
    }

    get name() {
        return "Text Box";
    }

    getElementSelection() {
        return this.options.elementSelection ?? TextFrameBoxSelection;
    }

    get _canSelect() {
        return true;
    }

    get passThroughSelection() {
        return false;
    }

    get selectionPadding() {
        return this.hasBackdrop ? 0 : 20;
    }

    get isOverImage() {
        return this.parentElement.isOverImage;
    }

    get canDragPosition() {
        return this.parentElement.canDragPosition;
    }

    get hasBackdrop() {
        return this.parentElement.hasBackdrop;
    }

    onBlockRemoved() {
        this.options.onBlockRemoved?.();
    }

    _build() {
        this.text = this.addElement("text", () => TextElement, {
            blockStructure: BlockStructureType.FREEFORM,
            autoHeight: true,
            scaleTextToFit: true,
            minTextScale: 0.01,
            canAddBlocks: true,
            canReorderBlocks: true,
            canDeleteLastBlock: this.options.canDeleteLastBlock ?? false,
            requireParentSelection: this.parentElement.canDragPosition,
            passThroughSelection: false,
            allowAlignment: true,
            syncFontSizeWithSiblings: this.options.syncFontSizeWithSiblings ?? false,
            allowedBlockTypes: this.options.allowedBlockTypes ?? [
                TextStyleType.HEADLINE,
                TextStyleType.HEADING,
                TextStyleType.TITLE,
                TextStyleType.BODY,
                TextStyleType.BULLET_LIST,
                TextStyleType.CAPTION,
                TextStyleType.LABEL,
                AuthoringBlockType.MEDIA,
                AuthoringBlockType.DIVIDER,
                AuthoringBlockType.CODE,
                AuthoringBlockType.EQUATION,
            ],
            defaultBlockTextStyle: TextStyleType.HEADING,
            createSubBulletOnEnter: true,
            blockDefaults: {
                [TextStyleType.HEADLINE]: {
                    evenBreak: true
                },
                [TextStyleType.HEADING]: {
                    evenBreak: true
                },
                ...this.options.blockDefaults
            },
            ...this.options,
            elementSelection: undefined
        });
    }

    getHTMLFilter() {
        if (this.parentElement.isOverImage) {
            if (this.model.shadow) {
                switch (this.model.shadow) {
                    case "drop":
                        return "drop-shadow(3px 5px 6px rgba(0,0,0,.4)";
                    case "soft":
                        return "drop-shadow(0px 0px 10px rgba(0,0,0,.4)";
                    case "block":
                        return "drop-shadow(10px 10px 0px rgba(0,0,0,.5";
                    default:
                        if (this.model.shadow.hasOwnProperty("color") && this.model.shadow.color != "rgba(0, 0, 0, 0)") {
                            let shadowColor = this.canvas.getTheme().palette.getColor(this.model.shadow.color).setAlpha(this.model.shadow.opacity).toRgbString();
                            return `drop-shadow(${this.model.shadow.x}px ${this.model.shadow.y}px ${this.model.shadow.blur}px ${shadowColor})`;
                        }
                }
            }
            if (this.textStyle == "white_text_with_shadow") {
                return "drop-shadow(2px 4px 6px rgba(0,0,0,.25))";
            }
        }
    }

    _calcProps(props, options) {
        let { size } = props;

        let padding = 0;

        if (this.hasBackdrop) {
            // when autoWidth, set the style padding to account for backdrop padding
            padding = this.model.backdropPadding ?? 30;

            if (this.canvas.layouter.isGenerating && !this.text.decoration) {
                let decorationProps = {
                    type: "frame",
                    shape: "rect",
                    fillColor: this.model.backdropColor,
                    fillOpacity: this.model.backdropOpacity,
                    cornerRadius: this.model.backdropCornerRadius ?? 0,
                };

                if (this.model.backdropColor == "frosted") {
                    decorationProps.fillColor = "rgba(0,0,0,0)";
                    decorationProps.fillOpacity = null;
                    decorationProps.backdropFilter = "saturate(100%) blur(15px)";
                }

                this.text.createDecoration(decorationProps);
            }
        }

        this.text.loadedStyles.paddingLeft = this.text.loadedStyles.paddingRight = this.text.loadedStyles.paddingTop = this.text.loadedStyles.paddingBottom = padding;

        let textProps = this.text.calcProps(size, {
            ...options,
            autoWidth: options.autoWidth,
        });

        let calculatedSize;

        textProps.bounds = new geom.Rect(0, 0, textProps.size);
        calculatedSize = textProps.size;

        return {
            size: calculatedSize,
            scale: textProps.textScale
        };
    }

    _applyColors() {
        if (this.hasBackdrop) {
            if (this.model.backdropColor == "frosted") {
                this.text.decoration.colorSet = {
                    fillColor: this.palette.getColor("black").setAlpha(0),
                };
            } else {
                this.text.decoration.colorSet = {
                    fillColor: this.palette.getColor(this.model.backdropColor).setAlpha(this.model.backdropOpacity),
                };
            }
            this.colorSet.backgroundColor = this.text.decoration.colorSet.fillColor.clone().setAlpha(1);
        }
    }

    static migrateToV10(element) {
        if (element.model.textPosition && !element.model.textAlign) {
            if (element.model.textPosition.contains("left")) {
                element.model.textAlign = HorizontalAlignType.LEFT;
            } else if (element.model.textPosition.contains("right")) {
                element.model.textAlign = HorizontalAlignType.RIGHT;
            } else {
                element.model.textAlign = HorizontalAlignType.CENTER;
            }
        }

        if (element.model.blocks) {
            const blocks = element.model.blocks;
            delete element.model.blocks;

            let blockFontScales = {};

            const migratedBlocks = [];
            for (const block of blocks) {
                if (block.type === ContentBlockType.BULLET_POINT) {
                    let fontScale = 1;
                    for (let [key, value] of Object.entries(element.getRootElement().model.userFontScale ?? {})) {
                        if (key.contains("ContentBlockFrame/ContentBlockCollection/ContentBlockItem/ContentBlockBulletPoint/TextGroup/TextGroupTitle")) {
                            fontScale = value;
                        }
                    }
                    fontScale *= 1.2; // adjust for root style differences

                    blockFontScales[TextStyleType.BULLET_LIST] = [fontScale];

                    migratedBlocks.push(...GetBlockModelsFromMigratedHtml(block.title, element, TextStyleType.TITLE)
                        .map(migratedBlock => ({
                            ...migratedBlock,
                            listIconId: block.icon,
                            textStyle: TextStyleType.BULLET_LIST,
                            listStyle: element.model.listStyle ?? "bullets",
                            indent: 0
                        }))
                    );

                    if (block.body) {
                        const bodyBlocks = GetBlockModelsFromMigratedHtml(block.body, element, TextStyleType.BODY, ["bullet_list", "numbered_list"].includes(block.text_format));
                        if (bodyBlocks.filter(block => block.html).length > 0) {
                            let listStyle;
                            switch (block.text_format) {
                                case "bullet_list":
                                    listStyle = ListStyleType.BULLET;
                                    break;
                                case "numbered_list":
                                    listStyle = ListStyleType.NUMBERED;
                                    break;
                                default:
                                    listStyle = ListStyleType.TEXT;
                            }

                            let fontScale = 1;
                            for (let [key, value] of Object.entries(element.getRootElement().model.userFontScale ?? {})) {
                                if (key.contains("ContentBlockFrame/ContentBlockCollection/ContentBlockItem/ContentBlockBulletPoint/TextGroup/TextGroupBody")) {
                                    fontScale = value;
                                }
                            }
                            fontScale *= 1.2; // adjust for root style differences
                            blockFontScales[TextStyleType.BULLET_LIST][1] = fontScale;

                            migratedBlocks.push(...bodyBlocks
                                .map(block => ({
                                    ...block,
                                    textStyle: TextStyleType.BULLET_LIST,
                                    listStyle,
                                    indent: 1,
                                }))
                            );
                        }
                    }

                    continue;
                }

                const blocksHtml = GetBlockModelsFromMigratedHtml(block.content, element).map(({ html }) => html);
                blocksHtml.forEach(html => {
                    const migratedBlock = _.cloneDeep(block);

                    migratedBlock.html = html;
                    switch (migratedBlock.type) {
                        case "icon":
                            migratedBlock.type = AuthoringBlockType.MEDIA;
                            migratedBlock.elementModel = {
                                elementType: "MediaBlock",
                                ..._.omit(block, ["id", "type"])
                            };
                            // delete everything else
                            Object.keys(block).forEach(key => {
                                if (!key.equalsAnyOf("id", "type", "blockHeight", "elementModel")) {
                                    delete block[key];
                                }
                            });

                            migratedBlock.autoWidth = true;

                            if (migratedBlock.elementModel.contentSize && migratedBlock.elementModel.content_type !== AssetType.ICON) {
                                element.canvas.layouter.runPostLoad(() => {
                                    const mediaBlockElement = element.text.blockElements[migratedBlock.id];
                                    if (!mediaBlockElement) {
                                        return;
                                    }

                                    const mediaElement = mediaBlockElement.media;
                                    const asset = mediaElement.content?.asset;
                                    if (!asset) {
                                        return;
                                    }

                                    if (["unframedRect", "none"].includes(mediaElement.frameType) && mediaElement.assetType !== AssetType.ICON) {
                                        migratedBlock.blockHeight = (migratedBlock.elementModel.contentSize - 20) / asset.get("w") * asset.get("h");
                                    } else {
                                        migratedBlock.blockHeight = migratedBlock.elementModel.contentSize;
                                    }
                                    delete migratedBlock.elementModel.contentSize;
                                });
                            } else {
                                migratedBlock.blockHeight = migratedBlock.elementModel.contentSize;
                                delete migratedBlock.elementModel.contentSize;
                            }

                            break;
                        case ContentBlockType.DIVIDER:
                            migratedBlock.type = AuthoringBlockType.DIVIDER;
                            migratedBlock.dividerWidth = "short";
                            break;
                        default:
                            migratedBlock.textStyle = migratedBlock.type;
                            migratedBlock.type = AuthoringBlockType.TEXT;
                            migratedBlock.hyphenation = false;
                            migratedBlock.ligatures = true;
                    }
                    delete migratedBlock.content;
                    migratedBlocks.push(migratedBlock);
                });
            }

            element.model.text = {
                blocks: migratedBlocks,
                blockFontScales
            };
        }
    }

    _migrate_10() {
        return TextFrameBox.migrateToV10(this);
    }

    async previewAnimation() {
        this.canvas.selectionLayerController?.setSelectedElements([]);

        if (this.animation) {
            this.animation.timeline.pause();
            this.refreshRender(true, { regenerateDOM: true });
            this.canvas.refreshCanvas();
            this.animation = null;
        } else {
            return new Promise(resolve => {
                this.playAnimationIn(() => {
                    this.refreshRender(true, { regenerateDOM: true });
                    this.canvas.refreshCanvas();

                    this.animation = null;
                    resolve();
                });
            });
        }
    }

    stopPreviewAnimation() {

    }

    async _beforeShowElement() {
        this.prepareForAnimation();
        await delay(300);
        this.playAnimationIn();
        this.canvas.registerOutroAnimation(this);
    }

    async playAnimationOut() {
        if (this.model.playOutroAnimation) {
            return new Promise(resolve => {
                let timeline = anime.timeline({
                    easing: "easeInQuad"
                });

                timeline.add({
                    targets: this.text.DOMNode,
                    scale: 3,
                    opacity: 0,
                    duration: 600
                });

                timeline.finished.then(() => {
                    resolve();
                });
                timeline.play();
            });
        }
    }

    prepareForAnimation() {
    }

    playAnimationIn(onComplete) {
        this.animation = playActionTextAnimation(this, this.model.animationStyle, onComplete);
    }
}


import React, { Component } from "react";

import { _ } from "js/vendor";

import { RoundIconButton } from "../../../../../../Components/IconButton";
import { DefaultOverlayContainer } from "../../../ElementDefaultOverlays/Components/DefaultOverlayContainer";
import { PropertyPanelContainer } from "../../../../../../EditorComponents/PropertyPanel";
import { addMediaToLayoutContainerItem, addTextToLayoutContainerItem, setCellToTextAndImage } from "./TextAndImageUI";
import { Popup, PopupContent, PopupPreview } from "../../../../../../Components/Popup";
import { FlexBox } from "../../../../../../react/components/LayoutGrid";
import { AddTextPopupMenu, TextIdeas } from "./AddTextPopupMenu";
import ImageVideoDrop from "../../../../../../Components/ImageVideoDrop";
import { mergeMediaElementModelDefaults } from "common/assetUtils";
import { PropertyPanelButton } from "../../../../../../EditorComponents/PropertyPanelButton";
import { getElementModelUpdatesForAsset } from "common/assetUtils";

export class GridPlaceholderPropertyPanel extends Component {
    render() {
        const { element } = this.props;

        const itemContainer = element.parentElement;

        return (
            <PropertyPanelContainer>
                <PropertyPanelButton icon="add_a_photo"
                    title="Add Media to Cell..."
                    description="Add an image, icon or video to fill the cell."
                    onClick={async () => {
                        addMediaToLayoutContainerItem({ element: itemContainer });
                    }}
                />
                <Popup>
                    <PopupPreview>
                        <PropertyPanelButton icon="text_increase"
                            title="Add Text to Cell"
                            description="Add text to the cell."
                        />
                    </PopupPreview>
                    <PopupContent>
                        {closePopup =>
                            <AddTextPopupMenu element={itemContainer} closePopup={closePopup} />
                        }
                    </PopupContent>
                </Popup>

            </PropertyPanelContainer>
        );
    }
}

export class GridPlaceholderDefaultOverlay extends Component {
    handleAddMedia = async () => {
        const { element } = this.props;
        addMediaToLayoutContainerItem({ element: element.parentElement });
    }

    getElementModelUpdatesForAsset = async asset => {
        const { element, canvas } = this.props;
        const parent = element.parentElement;

        const updates = getElementModelUpdatesForAsset(asset);

        setCellToTextAndImage(parent);
        mergeMediaElementModelDefaults(parent.model.childElement, updates);
        await canvas.updateCanvasModel(false);
        canvas.selectionLayerController.setSelectedElements([parent]);
    }

    render() {
        const { element, selectionLayerController, containerBounds } = this.props;

        let gridLayoutContainer = element.findClosestOfType("GridLayoutContainer");

        let buttonSize = 40 * Math.min(1, Math.min(containerBounds.width / 150, containerBounds.height / 80));

        const hasCellColor = element.parentElement.model.cellColor != null;
        return (
            <ImageVideoDrop callback={this.getElementModelUpdatesForAsset}>
                <DefaultOverlayContainer background={!hasCellColor}>
                    <FlexBox gap={10}>
                        {!selectionLayerController.isDraggingBlock && <>
                            <RoundIconButton icon="add_a_photo" size={buttonSize}
                                onClick={() => {
                                    addMediaToLayoutContainerItem({ element: (element.parentElement) });
                                }} />
                            {(element.parentElement.allowText && gridLayoutContainer.getAvailableTextIdeas().length > 1) && (
                                <Popup>
                                    <PopupPreview>
                                        <RoundIconButton icon="text_increase" size={buttonSize} />
                                    </PopupPreview>
                                    <PopupContent>
                                        <AddTextPopupMenu element={element.parentElement} />
                                    </PopupContent>
                                </Popup>
                            )}
                            {(element.parentElement.allowText && gridLayoutContainer.getAvailableTextIdeas().length == 1) && (
                                <RoundIconButton icon="text_increase" size={buttonSize}
                                    onClick={() => {
                                        let idea = TextIdeas.findById(gridLayoutContainer.getAvailableTextIdeas()[0]);
                                        addTextToLayoutContainerItem({
                                            element: (element.parentElement),
                                            ...idea.text,
                                            autoSelect: false
                                        });
                                    }}
                                />
                            )}
                        </>}
                    </FlexBox>
                </DefaultOverlayContainer>
            </ImageVideoDrop>
        );
    }
}

import { v4 as uuid } from "uuid";
import { _ } from "js/vendor";
import { ConnectorType, NodeType, ShapeType, AuthoringBlockType, TextStyleType } from "common/constants";
import getLogger, { LogGroup } from "js/core/logger";
import { detectGraphData } from "js/core/services/sharedModelManager";

import { CollectionElement } from "../../base/CollectionElement";
import ConnectorGroup from "../connectors/ConnectorGroup";
import { getContentItemFromType } from "../../base/ContentItem";
import {
    IconCircleItemPropertyPanel,
    IconCirclePropertyPanel,
    IconCircleItemSelection
} from "../../../Editor/ElementPropertyPanels/IconCircleUI";

const logger = getLogger(LogGroup.ELEMENTS);

class IconCircle extends CollectionElement {
    static get schema() {
        return {
            items: [{}, {}],
        };
    }

    getElementPropertyPanel() {
        return IconCirclePropertyPanel;
    }

    get name() {
        return "Cycle";
    }

    get connectorDefaultModel() {
        return {
            startDecoration: "none",
            // Migrating from the old model
            endDecoration: this.model.show_arrows !== false ? "arrow" : "none",
            lineStyle: "solid",
            lineWeight: 3,
            canChangeConnectorType: false
        };
    }

    get connectorType() {
        return this.model.shape_is_polygon === true ? ConnectorType.STRAIGHT : ConnectorType.ARC;
    }

    getChildItemType(model) {
        return getContentItemFromType(model.nodeType || NodeType.BULLET_TEXT);
    }

    getChildOptions(model) {
        return {
            canChangeTextDirection: false,
            frameType: ShapeType.CIRCLE,
            syncFontSizeWithSiblings: true,
            name: "Cycle Item",
            elementSelection: IconCircleItemSelection,
            extendLastItemDropBounds: false,
            connectionShape: "HubAndSpokeCircle"
        };
    }

    getAllowedNodeTypes(node) {
        return [];
    }

    get defaultItemData() {
        return {
            nodeType: _.last(this.itemCollection).nodeType
        };
    }

    get minItemCount() {
        return 2;
    }

    get maxItemCount() {
        return 8;
    }

    addItem(props, index) {
        const item = super.addItem(props, index);

        // Index can be undefined, in this case the item is pushed to the end of the items array
        let actualIndex = typeof index === "number" ? index : this.itemElements.length - 1;

        // if we pass an index we need to calculate the previous and next indexes, otherwise we need to get the last element
        const indexBefore = actualIndex === 0 || !index ? this.itemElements.length - 1 : (actualIndex - 1) % this.itemElements.length;
        const indexAfter = (actualIndex + 1) % this.itemElements.length;

        const itemIdBefore = this.itemCollection[indexBefore].id;
        const itemIdAfter = this.itemCollection[indexAfter].id;

        // Removing the connector between the previous element and the next element
        const oldConnector = this.model.connectors.items.find(connector => connector.source === itemIdBefore || connector.target === itemIdAfter);
        this.model.connectors.items.remove(oldConnector);

        // Adding a connector from the previous element to the new element
        this.model.connectors.items.push({
            ...oldConnector,
            id: null,
            source: itemIdBefore,
            target: item.id
        });

        // Adding a connector from the new element to the next element
        this.model.connectors.items.push({
            ...oldConnector,
            id: null,
            source: item.id,
            target: itemIdAfter
        });

        return item;
    }

    deleteItem(itemId) {
        // We'll use this connector to copy it's properties to the new connectors
        const oldConnector = this.model.connectors.items.find(connector => connector.target === itemId);

        // Removing the connectors from and to the deleted item
        _.remove(this.model.connectors.items, c => c.source === itemId || c.target === itemId);

        // Building a list of elements w/o the item
        const filteredElements = this.itemElements
            .filter(x => x.id !== itemId);
        if (filteredElements.length > 1) {
            // Building a list of connectors we need
            const connectorsNeeded = filteredElements
                .map((element, idx) => ({
                    source: element.id,
                    target: filteredElements[(idx + 1) % filteredElements.length].id
                }));
            // Finding ones that missing and recreating them
            connectorsNeeded
                .filter(({ source, target }) => !this.model.connectors.items.some(connector =>
                    connector.source === source && connector.target === target
                ))
                .forEach(({ source, target }) => {
                    this.model.connectors.items.push({
                        ...oldConnector,
                        id: null,
                        source,
                        target
                    });
                });
        }

        super.deleteItem(itemId);
    }

    _build() {
        // Migration from HubAndSpoke variation
        const hubItem = this.itemCollection.find(item => item.id === "hub");
        if (hubItem) {
            delete hubItem.userSize;

            let otherItemsType;
            for (const item of this.itemCollection.filter(item => item.id !== "hub")) {
                if (!otherItemsType) {
                    otherItemsType = item.nodeType;
                } else if (otherItemsType !== item.nodeType) {
                    break;
                }
            }
            if (otherItemsType) {
                hubItem.nodeType = otherItemsType;
            }
        }

        super._build();

        if (!this.model.connectors) {
            this.model.connectors = {
                items: []
            };
        } else if (this.model.connectors.items &&
            this.model.connectors.items.some(connector =>
                !this.itemElements.some(item => item.id === connector.source) ||
                !this.itemElements.some(item => item.id === connector.target)
            )
        ) {
            // Rebuild connectors if there are connectors pointing to non existing elements
            // because of a wrong migration
            logger.warn("[IconCircle] malformed connectors model, rebuilding...", { slideId: this.canvas.dataModel?.id });
            this.model.connectors = {
                items: []
            };
        }

        if (!this.model.connectors.items || this.model.connectors.items.length === 0) {
            this.itemElements.forEach((element, idx) => {
                const source = element.id;
                const target = this.itemElements[(idx + 1) % this.itemElements.length].id;

                if (source !== target) {
                    this.model.connectors.items.push({
                        ...this.connectorDefaultModel,
                        connectorType: this.connectorType,
                        source,
                        target
                    });
                }
            });
        } else {
            // Assigning the default model props and connector type to the existing connectors
            for (let i = 0; i < this.model.connectors.items.length; i++) {
                this.model.connectors.items[i] = {
                    ...this.connectorDefaultModel,
                    ...this.model.connectors.items[i],
                    connectorType: this.connectorType
                };
            }
        }

        this.connectors = this.addElement("connectors", () => ConnectorGroup, {
            model: this.model.connectors,
            containerElement: this,
            startPointsAreLocked: true,
            endPointsAreLocked: true,
            canDeleteConnectors: false
        });
        this.connectors.layer = -1;
    }

    _calcProps(props, options) {
        let { size } = props;

        this.styles.itemWidth = 300;

        const layouter = this.getLayouter(props, this.itemElements, size)
            .calcCircleLayout({
                clockwise: this.model.clockwise,
                shape_is_polygon: this.model.shape_is_polygon,
                ellipse_size: this.model.ellipse_size,
                topTextAbove: true,
                bottomTextBelow: true
            });

        let connectorProps = this.connectors.calcProps(size, { shape: layouter.shape });
        connectorProps.opacity = 0.5;

        props.isFit = layouter.isFit;

        return { size };
    }

    getAnimations() {
        const animations = [];
        this.itemElements.forEach(node => {
            animations.push(...node.getAnimations());

            this.connectors.itemElements
                .filter(({ model }) => model.source === node.id)
                .forEach(connector => {
                    animations.push(...connector.getAnimations());
                });
        });
        return animations;
    }

    _exportToSharedModel() {
        const assets = this.itemElements.map(itemElement => itemElement._exportToSharedModel().assets[0]);
        const textContent = this.itemElements.map(itemElement => itemElement._exportToSharedModel().textContent[0]);

        const graphData = [{
            nodes: this.itemElements.map(itemElement => itemElement._exportToSharedModel().graphData[0].nodes[0]),
            connectors: this.connectors._exportToSharedModel().graphData[0].connectors.map(c => ({ ...c, type: ConnectorType.STRAIGHT }))
        }];

        return { graphData, textContent, assets, collectionColor: this.collectionColor };
    }

    _importFromSharedModel(model) {
        const graphData = detectGraphData(model) || {};
        if (!graphData || !graphData.nodes?.length) return;

        const items = (graphData.isHubAndSpoke ? graphData.nodes.slice(1) : graphData.nodes).filter(node => node.textContent || node.asset).map(node => ({
            id: node.id,
            nodeType: NodeType.CONTENT_AND_TEXT,
            ...(node.asset ? {
                id: uuid(),
                content_type: node.asset.type,
                content_value: node.asset.value,
                assetName: node.asset.name,
                assetProps: node.asset.props,
            } : {}),
            ...(node.textContent ? {
                text: {
                    blocks: [
                        {
                            html: node.textContent.mainText.text,
                            textStyle: node.textContent.mainText.textStyle || TextStyleType.TITLE,
                            type: AuthoringBlockType.TEXT,
                        },
                        ...node.textContent.secondaryTexts.map(({ text, textStyle }) => ({
                            html: text,
                            textStyle: textStyle || TextStyleType.BODY,
                            type: AuthoringBlockType.TEXT,
                        }))
                    ]
                }
            } : {}),
        }));

        const connectors = {
            items: items.map((item, i) => ({
                id: `connector-${i}`,
                connectorType: ConnectorType.STRAIGHT,
                source: item.id,
                target: (items[i < items.length - 1 ? i + 1 : 0].id),
            }))
        };

        return { items, connectors, collectionColor: model.collectionColor, ...(model.props || {}) };
    }

    _migrate_10() {
        if (this.model.useLegacyNodes) {
            this.model.items.forEach(item => {
                item.nodeType = item.nodeType || NodeType.CONTENT_AND_TEXT;
            });
        }
    }
}

export const elements = {
    IconCircle
};

import React from "react";
import { BaseElement } from "../../base/BaseElement";
import { ROICalculationControlBar, ROICalculationsControlBar, ROICalculatorPropertyPanel, ROIInputControlBar, ROIInputsControlBar } from "./ROICalculatorUI";
import { FormatType, HorizontalAlignType, VerticalAlignType } from "../../../../../../common/constants";
import styled from "styled-components";
import { Slider } from "../../../../../Components/Slider";
import { formatter } from "../../../../../core/utilities/formatter";
import { CollectionElement, CollectionItemElement } from "../../base/CollectionElement";
import * as geom from "../../../../../core/utilities/geom";
import { TextElement } from "../../base/Text/TextElement";
import { AbsoluteBox } from "../../../../../react/components/LayoutGrid";
import { layoutHelper } from "../../layouts/LayoutHelper";

class ROICalculator extends BaseElement {
    static get schema() {
        return {
            inputs: [{
                name: "users",
                label: "Number of users",
                min: 1,
                max: 1000,
                step: 1,
                format: FormatType.NUMBER,
                value: 1
            }, {
                name: "cost",
                label: "Cost per user",
                min: 1,
                max: 1000,
                step: 1,
                format: FormatType.CURRENCY,
                value: 100
            }],
            calculations: [{
                name: "totalCost",
                label: "Total cost",
                formula: "{users * cost}",
            }]
        };
    }

    getElementPropertyPanel() {
        return ROICalculatorPropertyPanel;
    }

    setInputValue(input, value) {
        this.values[input.name] = value;
        this.canvas.refreshCanvas();
    }

    _build() {
        if (!this.values) {
            this.values = {};
            for (let input of this.model.inputs) {
                this.values[input.name] = input.value;
            }
        }

        this.inputs = this.addElement("inputs", () => ROIInputs);
        this.calculations = this.addElement("calculations", () => ROICalculations);
    }

    _calcProps(props, options) {
        const { size } = props;

        const COLWIDTH = size.width / 3;
        const GAP = 60;

        let inputsProps = this.inputs.calcProps(new geom.Size(COLWIDTH, size.height));
        inputsProps.bounds = new geom.Rect(0, 0, inputsProps.size);

        let calculationsProps = this.calculations.calcProps(new geom.Size(size.width - COLWIDTH - GAP, size.height));
        calculationsProps.bounds = new geom.Rect(COLWIDTH + GAP, 0, calculationsProps.size);

        return { size };
    }

    _applyColors() {
        this.colorSet.decorationColor = this.palette.getColor("theme", this.getBackgroundColor());
        this.colorSet.textColor = this.palette.getColor("primary", this.getBackgroundColor());
    }

    // renderChildren() {
    //     const { inputs, calculations } = this.model;
    //     return (
    //         <Calculator style={this.styles}
    //                     decorationColor={this.colorSet.decorationColor.toRgbString()}
    //                     textColor={this.colorSet.textColor.toRgbString()}
    //                     inputs={inputs} calculations={calculations}
    //         />
    //     );
    // }
}

const StyledSlider = styled(Slider)`

    width: 100%;

    .MuiSlider-thumb {
        width: 20px;
        height: 20px;
        color: ${props => props.color}
    }

    .MuiSlider-track, .MuiSlider-rail {
        height: 10px;
        background: ${props => props.color};
    }

    .MuiSlider-valueLabel {
        span {
            span {
                color: white;
                font-weight: bold;
            }
        }
    }

    .MuiSlider-root {
        padding: 7px 0;
    }
`;

class ROIInputs extends CollectionElement {
    get collectionPropertyName() {
        return "inputs";
    }

    getChildItemType() {
        return ROIInput;
    }

    get maxItemCount() {
        return 5;
    }

    getElementControlBar() {
        return ROIInputsControlBar;
    }

    _calcProps(props, options) {
        const { size } = props;

        let y = 0;
        for (let input of this.itemElements) {
            let inputProps = input.calcProps(size);
            inputProps.bounds = new geom.Rect(0, y, size.width, inputProps.size.height);
            y += inputProps.size.height + this.styles.vGap;
        }

        layoutHelper.alignItemsInContainer(this.itemElements, size, HorizontalAlignType.LEFT, VerticalAlignType.MIDDLE);

        return { size };
    }
}

class ROIInput extends CollectionItemElement {
    getElementControlBar() {
        return ROIInputControlBar;
    }

    _build() {
        let values = this.getRootElement().values;

        let formatOptions = this.model.formatOptions ?? formatter.getDefaultFormatOptions();
        if (this.model.format === FormatType.PERCENT) {
            formatOptions.divideBy100 = true;
        }

        this.label = this.addElement("label", () => TextElement, {
            html: `${this.model.label}: <span>${formatter.formatValue(values[this.model.name], this.model.format, formatOptions)}</span>`,
            autoHeight: true
        });
    }

    _calcProps(props, options) {
        const { size } = props;

        const SLIDER_HEIGHT = 30;

        let labelProps = this.label.calcProps(size);
        labelProps.bounds = new geom.Rect(0, 0, size.width, labelProps.size.height);

        return { size: new geom.Size(size.width, labelProps.bounds.height + SLIDER_HEIGHT) };
    }

    renderChildren() {
        let children = super.renderChildren();

        let values = this.getRootElement().values;

        let labelStyle = {
            fontFamily: this.styles.label.fontId,
            fontSize: this.styles.label.fontSize,
            fontWeight: this.styles.label.fontWeight,
            letterSpacing: this.styles.label.letterSpacing,
            textTransform: this.styles.label.textTransform,
            color: "black"
        };

        let decorationColor = this.getDecorationColor().toRgbString();

        let input = this.model;

        children.push(
            <AbsoluteBox top={this.label.offsetBounds.bottom} width="100%" height={30} style={{ pointerEvents: "auto" }}>
                {/*<label style={labelStyle}>{input.label} : <span style={{color: decorationColor}}>{formatter.formatValue(values[input.name], input.format, input.formatOptions)}</span></label>*/}
                {/*<label style={labelStyle}>Foo : <span style={{color: decorationColor}}>{formatter.formatValue(values[input.name], input.format, input.formatOptions)}</span></label>*/}
                {/*<Gap10/>*/}
                <StyledSlider value={values[input.name]}
                    color={decorationColor}
                    min={input.min}
                    max={input.max}
                    step={input.step}
                    onChange={value => this.getRootElement().setInputValue(input, value)} />
            </AbsoluteBox>
        );

        return children;
    }
}

class ROICalculations extends CollectionElement {
    get collectionPropertyName() {
        return "calculations";
    }

    getChildItemType() {
        return ROICalculation;
    }

    get maxItemCount() {
        return 4;
    }

    getElementControlBar() {
        return ROICalculationsControlBar;
    }

    _calcProps(props, options) {
        const { size } = props;

        let y = 0;
        for (let calculation of this.itemElements) {
            let calculationProps = calculation.calcProps(size);
            calculationProps.bounds = new geom.Rect(0, y, size.width, calculationProps.size.height);
            y += calculationProps.size.height + this.styles.vGap;
        }

        layoutHelper.alignItemsInContainer(this.itemElements, size, HorizontalAlignType.LEFT, VerticalAlignType.MIDDLE);

        return { size };
    }
}

class ROICalculation extends CollectionItemElement {
    getElementControlBar() {
        return ROICalculationControlBar;
    }

    parseFormula() {
        // the formula is a string that contains an equation like "{cost + value} per month"
        // use regex to find the equation and replace it with the calculated value
        let formula = this.model.formula;
        let matches = formula.match(/{(.*?)}/g);
        let values = this.getRootElement().values;

        let formatOptions = this.model.formatOptions ?? formatter.getDefaultFormatOptions();
        if (this.model.format === FormatType.PERCENT) {
            formatOptions.divideBy100 = true;
        }

        if (matches) {
            for (let match of matches) {
                let value = eval(match.replace(/([a-zA-Z]+)/g, match => {
                    return parseFloat(values[match]);
                }));
                value = formatter.formatValue(value, this.model.format, formatOptions);
                formula = formula.replace(match, value);
            }
        }

        // for (let value in values) {
        //     formula = formula.replace(new RegExp(`{${value}}`, "g"), formatter.formatValue(values[value], this.model.format, formatOptions));
        // }
        // console.log(formula);
        // let calculatedValue = formatter.formatValue(eval(formula), this.model.format, formatOptions);

        return formula;
    }

    _build() {
        this.label = this.addElement("label", () => TextElement, {
            html: `${this.model.label}`,
            autoHeight: true
        });
        this.value = this.addElement("value", () => TextElement, {
            html: this.parseFormula(),
            autoHeight: true
        });
    }

    _calcProps(props, options) {
        const { size } = props;

        let labelProps = this.label.calcProps(size);
        labelProps.bounds = new geom.Rect(0, 0, size.width, labelProps.size.height);

        let valueProps = this.value.calcProps(size);
        valueProps.bounds = new geom.Rect(0, labelProps.size.height, size.width, valueProps.size.height);

        return { size: new geom.Size(size.width, labelProps.size.height + valueProps.size.height) };
    }
}

export const elements = {
    ROICalculator
};


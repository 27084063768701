import React, { Component } from "react";
import { BaseElementSelection } from "../../ElementSelections/BaseElementSelection";
import { defaultDragResizeProps, DragHandleStyle } from "../../../../../editor/PresentationEditor/DragElementManager";
import { ElementTextBlockPositionType, ResizeDirection } from "../../../../../../common/constants";
import { PropertyPanelContainer, PropertySection } from "../../../../../EditorComponents/PropertyPanel";
import { GridLayoutPropertyPanel } from "./GridLayoutUI";
import { BottomTrayLayouts } from "../../../elements/elements/LayoutContainers/BottomTray";
import { PropertyPanelHeader } from "../../../../../editor/ElementEditor/PropertyPanelHeader";
import { NumericStepper } from "../../../../../Components/NumericStepper";
import { WithLabel } from "../../../../../Components/WithLabel";

export class BottomTrayPropertyPanel extends Component {
    render() {
        const { element, canvas } = this.props;

        return (
            <PropertyPanelContainer>
                <PropertyPanelHeader>Bottom Tray</PropertyPanelHeader>
                {element && (
                    <GridLayoutPropertyPanel {...this.props} element={element}
                        showLayoutEngines={false} allowFullBleed={false}
                        layouts={BottomTrayLayouts} width={84} height={20} layoutCols={5}
                    />
                )}
                <PropertySection>
                    <WithLabel label="Tray Height">
                        <NumericStepper value={element.model.trayHeight}
                            onChange={value => {
                                element.model.trayHeight = value ? Math.round(value) : null;
                                canvas.updateCanvasModel();
                            }}
                            min={50} max={300} step={1} useAutoWhenBlank
                        />
                    </WithLabel>
                </PropertySection>
            </PropertyPanelContainer>
        );
    }
}

export class BottomTraySelection extends BaseElementSelection {
    get canResize() {
        return true;
    }

    get canDelete() {
        return false;
    }

    handleDeleteElement = () => {
        const { element } = this.props;
        element.canvas.model.layout.elementTextBlockPosition = ElementTextBlockPositionType.NONE;
        element.canvas.updateCanvasModel(true);
    }

    get dragResizeProps() {
        const { element } = this.props;

        const maxSize = element.maxTrayHeight;
        const minSize = element.minTrayHeight;
        let initialHeight;
        return {
            ...defaultDragResizeProps,
            resizeDirections: [ResizeDirection.TOP],
            handleProps: {
                style: DragHandleStyle.GRABBER,
                offset: {
                    x: 0,
                    y: 0
                }
            },
            onDragStart: async () => {
                // use the calculated height instead of trayHeight because it may be autosizing
                initialHeight = element.calculatedProps.bounds.height;
            },
            onDrag: async ({ dragOffset }) => {
                let lastTrayHeight = element.model.trayHeight;
                element.model.trayHeight = Math.round(Math.clamp(initialHeight - dragOffset.y, minSize, maxSize));
                try {
                    await element.canvas.refreshCanvas();
                } catch (error) {
                    element.model.trayHeight = lastTrayHeight;
                    await element.canvas.refreshCanvas();
                }
            }
        };
    }
}

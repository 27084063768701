import React, { Component } from "react";
import styled from "styled-components";

import { themeColors } from "../../../../../react/sharedStyles";
import { ControlBarPosition } from "../../../../../../common/constants";
import { Button } from "../../../../../Components/Button";

const ControlBarContainer = styled.div.attrs(({ left, top, zIndex }) => ({
    style: {
        left: typeof left === "number" ? `${left}px` : left,
        top: typeof top === "number" ? `${top}px` : top,
        zIndex
    }
}))`
    position: absolute;
    transform: translateX(-50%) translateY(-50%) translate3d(0, 0, 0);
    height: 34px;
    display: flex;
    gap: 10px;
    z-index: 2; // layer above default overlay and selection box
`;

export const ControlBarGroup = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    color: #fff;
    background: ${props => props.color ?? themeColors.ui_blue};
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;

    pointer-events: auto;

    border-radius: 3px;
    filter: drop-shadow(0px 14px 14px rgba(0, 0, 0, 0.2));

    text-wrap: nowrap;

    & > div, .bai-button {
        position: relative;
        height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:after {
            content: "";
            position: absolute;
            top: 0px;
            right: -5px;
            right: 0px;
            height: 100%;
            width: 1px;
            background: white;
            opacity: 0.3;
        }
    }
    
    .bai-button {
        border: none !important;
        background: ${themeColors.ui_blue};
    }

    .withlabel {
        padding-left: 15px;
        .label-container {
            label {
                color: white;
            }
        }
    }

    & > div, & > button {
        color: white !important;
    }

    .bai-slider {
        padding: 0px 14px;
        .MuiSlider-root {
            color: #f1f1f1;
            width: 120px;
        }
    }
    
    .bai-popup {
        padding: 0px 14px;
        &.labeled {
            background: none;
            border: none;
        }
    }
    
    .bai-icon-button {
        padding: 0px 10px;
        &:hover {
            background: rgba(0,0,0,0.1);
        }
    }

    .MuiIcon-root, .bai-icon {
        font-size: 20px !important;
        color: white !important;
    }
    
    .MuiButton-root {
        height: auto !important;
        padding: 6px 12px;
        .MuiButton-label {
            letter-spacing: 0.4px;
            font-family: "Source Sans Pro", sans-serif;
            font-size: 12px;
            font-weight: 600;
            column-gap: 6px;
        }
    }

    & > div:last-child:after, .bai-button:last-child:after {
        display: none;
    }
`;

export class ControlBar extends Component {
    render() {
        let {
            position = ControlBarPosition.CANVAS,
            left = undefined,
            top = undefined,
            zIndex = 9999
        } = this.props;

        if (left === undefined || top === undefined) {
            switch (position) {
                case ControlBarPosition.INNER_BOTTOM:
                    left = "50%";
                    top = "calc(100% - 30px)";
                    break;
                case ControlBarPosition.DOCKED:
                    left = "50%";
                    top = "calc(100% + 15px)";
                    break;
                case ControlBarPosition.CANVAS:
                    left = "50%";
                    top = "calc(100% + 30px)";
                    break;
                case ControlBarPosition.ABOVE:
                    left = "50%";
                    top = "-40px";
                    break;
                case ControlBarPosition.BELOW_CELL:
                    left = "50%";
                    top = "calc(100% + 55px)";
                    break;
                case ControlBarPosition.BELOW:
                default:
                    left = "50%";
                    top = "calc(100% + 25px)";
                    break;
            }
        }

        let Contents;
        if (React.Children.toArray(this.props.children)[0]?.type?.displayName === "ControlBar__ControlBarGroup") {
            Contents = this.props.children;
        } else {
            Contents = <ControlBarGroup>{this.props.children}</ControlBarGroup>;
        }

        return (
            <ControlBarContainer left={left} top={top} zIndex={zIndex}>
                {Contents}
            </ControlBarContainer>
        );
    }
}

export class ControlBarButton extends Component {
    render() {
        return (
            <Button blue {...this.props}>{this.props.children}</Button>
        );
    }
}

import { AssetType, AuthoringBlockType, BlockStructureType, DecorationStyle, TextStyleType } from "common/constants";
import { detectTasks } from "js/core/services/sharedModelManager";
import { getValueOrDefault } from "js/core/utilities/extensions";
import * as geom from "js/core/utilities/geom";
import { _ } from "js/vendor";
import { v4 as uuid } from "uuid";

import {
    VerticalTaskItemControlBar,
    VerticalTaskItemSelection,
    VerticalTaskListColumnControlBar,
    VerticalTaskListColumnSelection,
    VerticalTaskListControlBar,
    VerticalTaskListPropertyPanel
} from "../../Editor/ElementPropertyPanels/VerticalTaskListUI";
import { CollectionElement, CollectionItemElement } from "../base/CollectionElement";
import { Icon } from "../base/MediaElements/IconElement";
import { TextElement } from "../base/Text/TextElement";

export class VerticalTaskLists extends CollectionElement {
    static get schema() {
        return {
            showTitles: true,
            style: "box",
            taskLayout: "stack",
        };
    }

    get name() {
        return "Kanban Board";
    }

    getElementPropertyPanel() {
        return VerticalTaskListPropertyPanel;
    }

    getElementControlBar() {
        return VerticalTaskListControlBar;
    }

    getChildItemType() {
        return VerticalTaskListsColumn;
    }

    get maxItemCount() {
        return 7;
    }

    get isVertical() {
        return this.model.orientation == "vertical";
    }

    get lockColumns() {
        return getValueOrDefault(this.options.lockColumns, false);
    }

    get showTitles() {
        return this.model.showTitles;
    }

    get taskLayout() {
        return this.model.taskLayout;
    }

    get userStyle() {
        let style = getValueOrDefault(this.model.style, "box");
        if (style == "divider" && this.itemElements.length == 1) {
            style = "none";
        }
        return style;
    }

    _loadStyles(styles) {
        // load userStyle
        styles.applyStyles(styles.styles[this.userStyle]);
    }

    _calcProps(props, options) {
        let { size } = props;

        let maxHeaderHeight = 0;
        if (this.showTitles) {
            const MAX_ROW_HEADER_HEIGHT = 200;
            // calculate the optimal header height from all the columns
            for (let row of this.itemElements) {
                let headerProps = row.columnHeader.calcProps(new geom.Size((size.width - this.styles.hGap * (this.itemCollection.length - 1)) / this.itemCollection.length, MAX_ROW_HEADER_HEIGHT), {
                    autoHeight: true,
                    styles: this.styles.VerticalTaskListsColumn.label
                });
                maxHeaderHeight = Math.max(maxHeaderHeight, headerProps.size.height);
            }
        }

        let layouter = this.getLayouter(props, this.itemElements, size);
        layouter.calcHorizontalLayout({
            itemOptions: {
                headerHeight: maxHeaderHeight
            }
        });

        return { size };
    }

    _applyColors() {
        // suppress this collection element from calculating colorSets
    }

    _exportToSharedModel() {
        const colContents = this.itemElements.map(item => ({
            category: item.columnHeader?._exportToSharedModel().textContent[0],
            tasks: item.taskList.itemElements.map(task => task._exportToSharedModel()),
        }));

        return {
            assets: colContents.reduce((assets, item) => ([
                ...assets, ...item.tasks.reduce((assets, task) => ([
                    ...assets, ...(task.assets || [])
                ]), [])
            ]), []),
            textContent: colContents.reduce((texts, item) => ([
                ...texts, ...item.tasks.reduce((texts, task) => ([
                    ...texts, ...task.textContent
                ]), [])
            ]), []),
            tasks: colContents.reduce((tasks, item) => ([
                ...tasks, ...item.tasks.map(task => ({
                    text: task.textContent[0],
                    category: item.category,
                    ...(task.assets?.length ? { asset: task.assets[0] } : {}),
                }))
            ]), []),
            collectionColor: this.collectionColor
        };
    }

    _importFromSharedModel(model) {
        const tasks = detectTasks(model);
        if (!tasks?.length) {
            return;
        }

        const categories = _.uniq(tasks.map(task => task.category?.mainText.text));
        const tasksByCategory = _.groupBy(tasks, task => task.category?.mainText.text);

        return {
            items: categories.map(category => ({
                id: uuid(),
                label: {
                    blocks: [{
                        html: category,
                        textStyle: TextStyleType.TITLE,
                        type: AuthoringBlockType.TEXT,
                    }]
                },
                items: tasksByCategory[category].map(task => ({
                    id: uuid(),
                    text: {
                        blocks: [
                            {
                                html: task.text.mainText.text,
                                textStyle: TextStyleType.TITLE,
                                type: AuthoringBlockType.TEXT,
                            },
                            ...task.text.secondaryTexts.map(({ text }) => ({
                                html: text,
                                textStyle: TextStyleType.BODY,
                                type: AuthoringBlockType.TEXT,
                            }))
                        ]
                    },
                    ...(task.asset ? { icon: task.asset.value } : {})
                }))
            })),
            collectionColor: model.collectionColor
        };
    }
}

export class VerticalTaskListsColumn extends CollectionItemElement {
    getElementSelection() {
        return this.options.elementSelection ?? VerticalTaskListColumnSelection;
    }

    getElementControlBar() {
        return VerticalTaskListColumnControlBar;
    }

    get selectionPadding() {
        return 0;
    }

    get canDelete() {
        return !this.parentElement.lockColumns;
    }

    get showTitle() {
        return this.parentElement.showTitles;
    }

    _build() {
        if (this.showTitle) {
            this.columnHeader = this.addElement("label", () => ColumnHeaderTextElement, {
                placeholder: "Type title",
                canRollover: this.canDelete,
                canEdit: this.canDelete,
                canSelect: this.canDelete,
                isTabbable: this.canDelete,
                syncFontSizeWithSiblings: true,
                scaleTextToFit: true
            });
        }
        this.taskList = this.addElement("taskList", () => VerticalTaskList, {
            orientation: "vertical"
        });
    }

    _calcProps(props, options) {
        let { size } = props;
        let y = 0;

        if (this.decoration && this.parentElement.model.style == "divider" && this.itemIndex == this.itemCount - 1) {
            this.decoration.updateStyles({ type: "none" });
        }

        if (this.showTitle) {
            let headerProps = this.columnHeader.calcProps(new geom.Size(size.width, options.headerHeight), options);
            headerProps.bounds = new geom.Rect(0, 0, headerProps.size);
            y += headerProps.size.height;
        }

        let taskListProps = this.taskList.calcProps(new geom.Size(size.width, size.height - y));
        taskListProps.bounds = new geom.Rect(0, y, taskListProps.size);

        return { size };
    }

    _applyColors() {
        if (this.parentElement.model.style == "divider") {
            if (this.decoration) {
                this.decoration.colorSet = {
                    strokeColor: this.palette.getColor("secondary", this.getBackgroundColor())
                };
            }
        } else if (this.parentElement.model.style == "box") {
            this.decoration.colorSet = {
                fillColor: this.palette.getColor("white"),
                strokeColor: this.palette.getColor("primary", this.getBackgroundColor()),
            };
            this.colorSet.backgroundColor = this.palette.getColor("white");
        }
    }
}

class ColumnHeaderTextElement extends TextElement {
}

export class VerticalTaskList extends CollectionElement {
    getElementControlBar() {
        return false;
    }

    getChildItemType() {
        return VerticalTaskElement;
    }

    get _canSelect() {
        return false;
    }

    get _canRollover() {
        return false;
    }

    get minItemCount() {
        return 0;
    }

    _calcProps(props, options) {
        let { size } = props;

        let rootElement = this.getParentOfType("VerticalTaskLists");

        let fixedItemHeight;

        switch (rootElement.taskLayout) {
            case "stack":
                // leave null so items try to use their calculated width
                break;
            case "fill":
                fixedItemHeight = (size.height - (this.itemCount - 1) * this.styles.vGap) / this.itemCount;
                break;
            case "fit":
                let maxItemsInColumn = _.maxBy(rootElement.itemCollection, c => c.items.length).items.length;
                fixedItemHeight = (size.height - (maxItemsInColumn - 1) * this.styles.vGap) / maxItemsInColumn;
                break;
        }

        let layouter = this.getLayouter(props, this.itemElements, size);
        layouter.distributeVertically({
            gap: this.styles.vGap,
            itemOptions: {
                fixedItemHeight
            },
            reserveMinHeights: true
        });
        props.isFit = layouter.isFit && this.itemElements.filter(item => !item.text.calculatedProps.isTextFit).length == 0;

        return { size };
    }
}

class VerticalTaskElement extends CollectionItemElement {
    getElementControlBar() {
        return VerticalTaskItemControlBar;
    }

    getElementSelection() {
        return VerticalTaskItemSelection;
    }

    get selectionPadding() {
        return 0;
    }

    get _canSelect() {
        return true;
    }

    get requireParentSelection() {
        return true;
    }

    get passThroughSelection() {
        return false;
    }

    get minHeight() {
        return this.text.minHeight;
    }

    get hasIcon() {
        return this.model.icon && this.model.icon != "none";
    }

    _loadStyles(styles) {
        if (this.hasIcon) {
            styles.text.title.paddingRight = 40;
        }
    }

    _exportToSharedModel() {
        return {
            textContent: this.text._exportToSharedModel().textContent,
            assets: this.hasIcon ? [{ type: AssetType.ICON, value: this.model.icon }] : []
        };
    }

    _build() {
        if (this.hasIcon) {
            this.icon = this.addElement("icon", () => Icon, {
                icon: this.model.icon,
                backgroundElement: this.decoration,
                canSelect: false
            });
        }

        this.text = this.addElement("text", () => TextElement, {
            blockStructure: BlockStructureType.TITLE_AND_BODY,
            autoHeight: true,
            syncFontSizeWithSiblings: true,
            // We need this in order to fit long words that won't break
            scaleTextToFit: true,
            getSiblings: () => {
                return this.getRootElement().findChildElements("TextElement").filter(text => text.id === "text");
            },
            backgroundElement: this.decoration
        });
    }

    _calcProps(props, options) {
        let { size } = props;

        if (options.fixedItemHeight) {
            size.height = options.fixedItemHeight - this.styles.paddingTop - this.styles.paddingBottom;
        }

        let textProps = this.text.calcProps(size);
        textProps.bounds = new geom.Rect(0, 0, textProps.size);

        if (this.hasIcon) {
            let iconProps = this.icon.calcProps(new geom.Size(30, 30));
            iconProps.bounds = new geom.Rect(size.width - iconProps.size.width, Math.min(0, textProps.size.height / 2 - iconProps.size.height / 2), iconProps.size);
        }

        if (!options.fixedItemHeight) {
            size.height = textProps.size.height;
        }

        return { size };
    }

    _applyColors() {
        if (this.hasIcon) {
            this.icon.colorSet.iconColor = this.palette.getColor("primary", this.decoration.getBackgroundColor());
        }
    }

    _migrate_10_02() {
        this.model.decorationStyle = DecorationStyle.FILLED;
        if (this.model.color == "auto") {
            this.model.color = null;
        }
    }
}

export const elements = {
    VerticalTaskLists
};

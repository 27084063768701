import React, { Component } from "react";
import { Button, Icon, MenuItem } from "@material-ui/core";
import { DialogTitle, DialogActions } from "@material-ui/core";
import { ToggleButton } from "@material-ui/lab";
import FormatAlignCenter from "mdi-material-ui/FormatAlignCenter";
import FormatAlignLeft from "mdi-material-ui/FormatAlignLeft";
import FormatAlignRight from "mdi-material-ui/FormatAlignRight";

import { HorizontalAlignType } from "../../../../../../../../common/constants";
import getLogger, { LogGroup } from "../../../../../../../core/logger/index";
import { FlexSpacer, Gap20 } from "../../../../../../../react/components/Gap";
import { BeautifulDialog, DialogContent, ShowDialog } from "../../../../../../../react/components/Dialogs/BaseDialog";
import { FlexBox } from "../../../../../../../react/components/LayoutGrid";
import Loadable from "../../../../../../../react/components/Loadable";
import { PopupMenu, PopupMenuPaddedContainer } from "../../../../../../../react/components/PopupMenu";
import { LabeledContainer } from "../../../../../../../react/components/LabeledContainer";
import { ColorPicker } from "../../../../../../../react/components/ColorPicker";
import { sanitizeSvg } from "../../../../../../../core/utilities/dompurify";

import { AuthoringCanvasControlBar, ControlBarGroup } from "../../AuthoringCanvasControlBar";
import { BoundsBox, ReverseScaleBox } from "../../SelectionBox";
import { BlockTypePopupMenu } from "./TextFormatBar";
import { themeColors } from "../../../../../../../react/sharedStyles";

const logger = getLogger(LogGroup.ELEMENTS);

let mathJaxLoadPromise;

function getMathJax() {
    if (!mathJaxLoadPromise) {
        window.MathJax = {
            svg: {
                scale: 1,
                minScale: .5,
                mtextInheritFont: false,
                merrorInheritFont: true,
                mathmlSpacing: false,
                skipAttributes: {},
                exFactor: .5,
                displayAlign: "center",
                displayIndent: "0",
                fontCache: "none",
                localID: null,
                internalSpeechTitles: true,
                titleID: 0
            }
        };

        mathJaxLoadPromise = new Promise((resolve, reject) => {
            // Create script element and set its attributes
            const script = document.createElement("script");
            script.type = "text/javascript";
            script.async = true;
            script.src = "https://cdn.jsdelivr.net/npm/mathjax@3.0.0/es5/tex-svg.js";

            // Append the script to the DOM
            const el = document.getElementsByTagName("script")[0];
            el.parentNode.insertBefore(script, el);

            // Resolve the promise once the script is loaded
            script.addEventListener("load", () => {
                resolve(window.MathJax);
            });

            // Catch any errors while loading the script
            script.addEventListener("error", () => {
                reject(new Error("Failed to load MathJax"));
            });
        });
    }

    return mathJaxLoadPromise;
}

export class EquationBlockEditor extends Component {
    constructor(props) {
        super(props);

        this.state = {
            equation: props.block.equation,
            fontSize: props.block.fontSize,
            textAlign: props.block.textAlign,
            backgroundColor: props.block.backgroundColor
        };
    }

    handleSetFontSize = fontSize => {
        const { block, refreshCanvasAndSaveChanges } = this.props;

        block.model.fontSize = fontSize;
        this.setState({ fontSize });
        refreshCanvasAndSaveChanges();
    }

    handleSetTextAlign = textAlign => {
        const { block, refreshCanvasAndSaveChanges } = this.props;

        block.model.textAlign = textAlign;
        this.setState({ textAlign });
        refreshCanvasAndSaveChanges();
    }

    handleEditEquation = () => {
        const { block, refreshCanvasAndSaveChanges } = this.props;
        const { equation } = this.state;

        ShowDialog(EditEquationDialog, {
            equation: equation,
            callback: (equation, svgHTML) => {
                block.model.equation = equation;
                block.model.svgHTML = svgHTML;
                this.setState({ equation });
                refreshCanvasAndSaveChanges();
            }
        });
    }

    handleSelectColor = backgroundColor => {
        const { block, refreshCanvasAndSaveChanges } = this.props;

        block.model.backgroundColor = backgroundColor;
        this.setState({ backgroundColor });
        refreshCanvasAndSaveChanges();
    }

    render() {
        const { bounds, refreshCanvasAndSaveChanges, block, editorConfig, element } = this.props;
        const { textAlign, backgroundColor } = this.state;

        const fontSizes = {
            25: "Small",
            50: "Medium",
            75: "Large"
        };

        return (
            <BoundsBox bounds={bounds}>
                {/* inner div reverse scales contenteditable so that selection layer coordinate-space matches canvas */}
                <ReverseScaleBox
                    bounds={bounds}
                    canvasScale={element.canvas.getScale()}
                    style={{ pointerEvents: "auto" }}
                    onDoubleClick={this.handleEditEquation}
                >
                </ReverseScaleBox>

                <AuthoringCanvasControlBar position="above" offset={10}>
                    <ControlBarGroup color={themeColors.darkBlue}>
                        <BlockTypePopupMenu
                            selectedBlocks={[block]}
                            allowedBlockTypes={editorConfig.allowedBlockTypes}
                            onChange={refreshCanvasAndSaveChanges}
                        />
                    </ControlBarGroup>

                    <ControlBarGroup>
                        <Button onClick={this.handleEditEquation}><Icon>calculate</Icon>Edit Equation...</Button>
                        <ColorPicker
                            color={backgroundColor}
                            showBackgroundColors
                            onChange={this.handleSelectColor}
                            position="above"
                        />
                        <PopupMenu
                            icon="format_size"
                            showArrow
                            style={{ height: "29px" }}
                            onChange={this.handleSetFontSize}
                            childrenAreMenuItems
                        >
                            {Object.entries(fontSizes)
                                .map(([size, label]) => (
                                    <MenuItem
                                        key={size}
                                        value={size}
                                    >
                                        {label}
                                    </MenuItem>
                                ))}
                        </PopupMenu>
                        <PopupMenu
                            icon={`format_align_${textAlign == "mixed" ? "left" : textAlign}`}
                            showArrow={false}
                            position="above"
                        >
                            <PopupMenuPaddedContainer>
                                <LabeledContainer label="Text Align">
                                    <ToggleButton
                                        value="left"
                                        selected={textAlign == HorizontalAlignType.LEFT}
                                        onChange={() => this.handleSetTextAlign(HorizontalAlignType.LEFT)}
                                    >
                                        <FormatAlignLeft />
                                    </ToggleButton>
                                    <ToggleButton
                                        value="center"
                                        selected={textAlign == HorizontalAlignType.CENTER}
                                        onChange={() => this.handleSetTextAlign(HorizontalAlignType.CENTER)}
                                    >
                                        <FormatAlignCenter />
                                    </ToggleButton>
                                    <ToggleButton
                                        value="right"
                                        selected={textAlign == HorizontalAlignType.RIGHT}
                                        onChange={() => this.handleSetTextAlign(HorizontalAlignType.RIGHT)}
                                    >
                                        <FormatAlignRight />
                                    </ToggleButton>
                                </LabeledContainer>
                            </PopupMenuPaddedContainer>
                        </PopupMenu>
                    </ControlBarGroup>
                </AuthoringCanvasControlBar>
            </BoundsBox>
        );
    }
}

class EditEquationDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            equation: props.equation,
            svg: null,
            isLoading: true
        };
    }

    componentDidMount() {
        const { equation } = this.state;

        // Remove all text selection
        window.getSelection().removeAllRanges();

        getMathJax()
            .then(MathJax => {
                this.MathJax = MathJax;
                this.setState({ isLoading: false });

                this.renderEquation(equation);
            })
            .catch(err => logger.error(err, "EditEquationDialog getMathJax() failed"));
    }

    handleSetEquation = event => {
        event.stopPropagation();

        const equation = event.target.value;
        this.setState({
            equation
        });
        this.renderEquation(equation);
    }

    renderEquation = equation => {
        this.MathJax.texReset();
        this.MathJax.tex2svgPromise(equation, {})
            .then(node => {
                const svgNode = node.firstChild;
                svgNode.style.setProperty("max-width", "100%");
                const svgHTML = svgNode.outerHTML;
                this.MathJax.startup.document.clear();
                this.MathJax.startup.document.updateDocument();

                this.setState({
                    svg: svgHTML
                });
            });
    }

    handleSaveEquation = () => {
        const { callback, closeDialog } = this.props;
        const { svg, equation } = this.state;

        callback(equation, svg);
        closeDialog();
    }

    handleShowHelp = () => {
        window.open("https://en.wikipedia.org/wiki/Help:Displaying_a_formula#Formatting_using_TeX", "_blank");
    }

    render() {
        const { closeDialog } = this.props;
        const { equation, svg, isLoading } = this.state;

        return (
            <BeautifulDialog
                closeDialog={closeDialog}
                maxWidth="md"
            >
                <DialogTitle>Edit TeX Equation</DialogTitle>
                <DialogContent>
                    <Loadable isLoading={isLoading}>
                        <FlexBox vertical fillWidth>
                            <div style={{ fontSize: 30 }} dangerouslySetInnerHTML={{ __html: sanitizeSvg(svg) }} />
                            <Gap20 />
                            <div style={{ border: "solid 1px #ccc", padding: 10, width: "100%", height: 140 }}>
                                <textarea
                                    style={{ width: "100%", height: "100%", resize: "none", border: "none", fontSize: 16, outline: "none" }}
                                    value={equation}
                                    onInput={this.handleSetEquation}
                                />
                            </div>
                        </FlexBox>
                    </Loadable>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleShowHelp}>Help with Equations...</Button>
                    <FlexSpacer />
                    <Button onClick={closeDialog}>Cancel</Button>
                    <Button color="primary" onClick={this.handleSaveEquation}>Save Changes</Button>
                </DialogActions>
            </BeautifulDialog>

        );
    }
}

import { defaultDragPositionProps } from "../../../../editor/PresentationEditor/DragElementManager";

import { CollectionItemElementSelection } from "./CollectionItemElementSelection";

export class ConnectorItemLabelSelection extends CollectionItemElementSelection {
    get canDelete() {
        return true;
    }

    get canDrag() {
        return true;
    }

    get dragPositionProps() {
        const { element } = this.props;

        let initialPoint, path;
        return {
            ...defaultDragPositionProps,
            dragOpacity: 1,
            hideFauxElement: true,
            onDragStart: async () => {
                initialPoint = element.calculatedProps.pointOnLine;
                path = element.parentElement.calculatedOptions.path.clone();
            },
            onDrag: async ({ dragOffset }) => {
                const newPoint = initialPoint.offset(dragOffset.x, dragOffset.y);
                const newPercentage = path.findClosestPointAsPercentageOfLength(newPoint);
                const newPointOnPath = path.getPointAt(newPercentage);

                const initialOffset = element.model.offset;
                const distance = newPointOnPath.distance(newPoint);
                if (distance < 10) {
                    element.model.offset = 0;
                } else {
                    const segment = path.getSegmentForPoint(newPointOnPath);
                    if (!segment) {
                        element.model.offset = 0;
                    } else if (segment.start.y === segment.end.y) {
                        if (newPointOnPath.y > newPoint.y) {
                            element.model.offset = -1;
                        } else {
                            element.model.offset = 1;
                        }
                    } else if (segment.start.x === segment.end.x) {
                        if (newPointOnPath.x > newPoint.x) {
                            element.model.offset = -1;
                        } else {
                            element.model.offset = 1;
                        }
                    } else {
                        element.model.offset = 0;
                    }
                }

                element.model.position = newPercentage;

                if (initialOffset === element.model.offset) {
                    element.refreshElement();
                } else {
                    await element.canvas.refreshCanvas();
                }
            }
        };
    }
}

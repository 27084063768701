import * as geom from "js/core/utilities/geom";
import { Ellipse, Polygon } from "js/core/utilities/ellipse.js";
import { DirectionType } from "common/constants";

function calcCircleLayout(props, { size, items, styles, clockwise, shape_is_polygon, ellipse_size, maxItemHeight, topTextAbove = false, bottomTextBelow = false }) {
    let itemCount = items.length;
    let ccw = !clockwise;
    let ccw_sgn = ccw ? 1 : -1;

    // One and two items use a square in polygon mode
    let corners = itemCount < 3 ? 4 : itemCount;
    // All shapes but the square have the first corner at the top; square has it at the top-left
    let firstCorner = ccw_sgn * (corners === 4 ? Math.PI * 3 / 4 : Math.PI / 2);

    // For one or two items, we place the first item on the left; with more items, the first item starts at the top.
    let startAngle = ccw_sgn * (itemCount < 3 ? Math.PI : Math.PI / 2);

    // if (items.length == 4) {
    //     startAngle = Math.PI / items.length;
    // }

    // special case for square, where the first item should coincide with the top-left corner.
    if (shape_is_polygon && itemCount === 4) startAngle = ccw_sgn * Math.PI * 3 / 4;

    // First we make a rough version of the shape, to determine bounds. This version takes up the whole bounds without regard for text.
    let shape = makeShape(size.width / 2, size.height / 2, firstCorner, size.width / 2, size.height / 2, corners, ccw);
    let angles = shape.equalPoints(startAngle, itemCount);

    let itemSizes = calculateItemSizes(props, startAngle, ccw_sgn, styles.itemWidth, maxItemHeight);

    // Now reduce the size of the shape to fit all text
    let outerBounds = angles
        .map((angle, ii) => new geom.Rect(
            items[ii].registrationPoint.delta(shape.point(angle)),
            itemSizes[ii]
        ))
        .reduce((l, r) => l.union(r));

    let corrections = {
        left: Math.max(0, 0 - outerBounds.left),
        right: Math.max(0, outerBounds.right - size.width),
        top: Math.max(0, 0 - outerBounds.top),
        bottom: Math.max(0, outerBounds.bottom - size.height)
    };

    corrections.left = Math.max(corrections.left, corrections.right);
    corrections.right = corrections.left;

    let cx = size.width / 2 - (corrections.right - corrections.left) / 2;
    let cy = size.height / 2 - (corrections.bottom - corrections.top) / 2;

    let rx = size.width / 2 - (corrections.left + corrections.right) / 2;
    let ry = size.height / 2 - (corrections.top + corrections.bottom) / 2;

    ellipse_size = (ellipse_size || 1) - 1;
    rx = Math.min(rx, ry + ellipse_size * (rx - ry));
    ry = Math.min(ry, rx);

    // The final shape, fit to shape all text
    shape = makeShape(cx, cy, firstCorner, rx, ry, corners, ccw);
    angles = shape.equalPoints(startAngle, itemCount);
    let points = angles.map(angle => shape.point(angle));

    if (angles.length === 1) {
        angles.push(angles[0] + Math.PI);
    }

    return {
        shape,
        angles,
        points,
        itemSizes,
        correctedCenter: new geom.Point(cx, cy)
    };

    function determineTextDirection(angle) {
        while (angle < 0) angle += 2 * Math.PI;
        while (angle > 2 * Math.PI) angle -= 2 * Math.PI;
        if (angle < 0.01) return DirectionType.RIGHT;
        if (angle < Math.PI / 2 - 0.01) return DirectionType.RIGHT;
        if (angle < Math.PI / 2 + 0.01 && !bottomTextBelow) return DirectionType.RIGHT;
        if (angle < Math.PI / 2 + 0.01 && bottomTextBelow) return DirectionType.BOTTOM;
        if (angle < Math.PI - 0.01) return DirectionType.LEFT;
        if (angle < Math.PI + 0.01) return DirectionType.LEFT;
        if (angle < 3 * Math.PI / 2 - 0.01) return DirectionType.LEFT;
        if (angle < 3 * Math.PI / 2 + 0.01 && !topTextAbove) return DirectionType.RIGHT;
        if (angle < 3 * Math.PI / 2 + 0.01 && topTextAbove) return DirectionType.TOP;
        if (angle < 2 * Math.PI - 0.01) return DirectionType.RIGHT;
        return DirectionType.RIGHT;
    }

    function calculateItemSizes(props, startAngle, ccw_sgn, itemWidth, itemHeight) {
        return items.map((item, index) => {
            let angle = startAngle + 2 * Math.PI * index / itemCount;
            let itemProps = item.calcProps(new geom.Size(itemWidth, itemHeight || 200),
                { textDirection: determineTextDirection(-1 * ccw_sgn * angle) },
                true);
            return itemProps.size;
        });
    }

    function makeShape(cx, cy, firstCorner, rx, ry, n, ccw) {
        if (shape_is_polygon) {
            return new Polygon(cx, cy, firstCorner, Math.min(rx, ry), n, ccw);
        } else {
            return new Ellipse(cx, cy, rx, ry, ccw);
        }
    }
}

export { calcCircleLayout };

import React from "react";

import { Slider } from "../../../../Components/Slider";
import { MediaPopup } from "../EditorComponents/MediaPopup";
import { ControlBar } from "../ElementControlBars/Components/ControlBar";

export function IconElementControlBar({ element }) {
    return (
        <ControlBar>
            <MediaPopup element={element.parentElement} canAdjust={false} />
            <Slider value={element.calculatedProps.scale}
                min={0.1} max={1.5} step={0.1}
                onChange={value => element.refreshModel({ iconScale: value })}
                onCommit={() => element.saveModel()}
            />
        </ControlBar>
    );
}

import React, { Component } from "react";
import { PropertyPanelContainer, PropertySection } from "../../../../EditorComponents/PropertyPanel";
import { WithLabel } from "../../../../Components/WithLabel";
import { ToggleSwitch } from "../../../../Components/ToggleSwitch";
import { ImageOption, ImageOptionList } from "../../../../Components/ImageOptionList";
import { StaticImage } from "../../../../Components/StaticImage";
import { ControlBar } from "../ElementControlBars/Components/ControlBar";
import { ItemColorPicker } from "../EditorComponents/ColorPickers/ItemColorPicker";
import { CollectionColorPicker } from "../EditorComponents/ColorPickers/CollectionColorPicker";

export class UserTestimonialPropertyPanel extends Component {
    render() {
        const { element } = this.props;
        return (
            <PropertyPanelContainer>
                <PropertySection>
                    <WithLabel label="Style" top left>
                        <ImageOptionList cols={2} value={element.model.frameStyle} onChange={frameStyle => element.updateModel({ frameStyle }, { refreshStyles: true })}>
                            <ImageOption value="none" label="None">
                                <StaticImage src="/images/ui/quote-frame-none.svg" />
                            </ImageOption>
                            <ImageOption value="divider" label="Divider" enabled={element.itemCount != 1}>
                                <StaticImage src="/images/ui/quote-frame-divider.svg" />
                            </ImageOption>
                            <ImageOption value="box" label="Box">
                                <StaticImage src="/images/ui/quote-frame-box.svg" />
                            </ImageOption>
                            <ImageOption value="quote-box" label="Quote Box">
                                <StaticImage src="/images/ui/quote-frame-quote-box.svg" />
                            </ImageOption>
                        </ImageOptionList>
                    </WithLabel>
                    <WithLabel label="Colors">
                        <CollectionColorPicker element={element} />
                    </WithLabel>
                </PropertySection>
                <PropertySection>
                    <WithLabel label="Show Fancy Quotes">
                        <ToggleSwitch value={element.model.showDesignQuotes} onChange={showDesignQuotes => element.updateModel({ showDesignQuotes })} />
                    </WithLabel>
                    <WithLabel label="Match Quote Sizes">
                        <ToggleSwitch value={element.model.matchQuoteSizes} onChange={matchQuoteSizes => element.updateModel({ matchQuoteSizes })} />
                    </WithLabel>
                    <WithLabel label="Flip Orientation">
                        <ToggleSwitch value={element.model.flipOrientation} onChange={flipOrientation => element.updateModel({ flipOrientation })} disabled={element.itemCount === 4 || element.itemCount === 6} />
                    </WithLabel>
                </PropertySection>
                <PropertySection>
                    <WithLabel label="Show Attribution">
                        <ToggleSwitch value={element.getChildValues("showAttribution")} onChange={showAttribution => element.updateChildModels({ showAttribution })} />
                    </WithLabel>

                    {element.getChildValues("showAttribution") !== false && (
                        <>
                            <WithLabel label="Show Attribution Photo">
                                <ToggleSwitch value={element.getChildValues("showImage")} onChange={showImage => element.updateChildModels({ showImage })} />
                            </WithLabel>
                            <WithLabel label="Show Attribution Logo">
                                <ToggleSwitch value={element.getChildValues("showLogo")} onChange={showLogo => element.updateChildModels({ showLogo })} />
                            </WithLabel>
                        </>
                    )}
                </PropertySection>
            </PropertyPanelContainer>
        );
    }
}

export class UserTestimonialItemControlBar extends Component {
    render() {
        const { element } = this.props;
        return (
            <ControlBar>
                <ItemColorPicker element={element} />
            </ControlBar>
        );
    }
}

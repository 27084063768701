import React, { Component } from "react";
import styled from "styled-components";

import { MenuItem } from "@material-ui/core";

import { WithLabel } from "../../../../Components/WithLabel";
import { Dropdown } from "../../../../Components/Dropdown";
import { Popup, PopupContent } from "../../../../Components/Popup";
import { ShowDialog } from "../../../../react/components/Dialogs/BaseDialog";
import EditTextDialog from "../../../../react/components/Dialogs/EditTextDialog";
import { AuthoringBlockType, AuthoringElementType, ForeColorType, NodeType, PaletteColorType, PositionType, TextStyleType } from "../../../../../common/constants";
import { Slider } from "../../../../Components/Slider";
import { _ } from "../../../../vendor";
import * as geom from "../../../../core/utilities/geom";
import { Shape } from "../../../../core/utilities/shapes";

import { PropertyPanelContainer, PropertySection } from "../../../../EditorComponents/PropertyPanel";
import { ControlBar, ControlBarGroup } from "../ElementControlBars/Components/ControlBar";
import { BaseElementSelection, SVGPathSelection } from "../ElementSelections/BaseElementSelection";
import ElementSelectionBox from "../ElementSelections/Components/ElementSelectionBox";
import DeleteElementButton from "../ElementSelections/Components/DeleteElementButton";
import { CollectionColorPicker } from "../EditorComponents/ColorPickers/CollectionColorPicker";
import { ItemColorPicker } from "../EditorComponents/ColorPickers/ItemColorPicker";
import { CollectionElementControlBar } from "../ElementControlBars/CollectionElementControlBar";
import { Key } from "../../../../core/utilities/keys";
import { CollectionItemElementSelection } from "../ElementSelections/CollectionItemElementSelection";
import { ToggleSwitch } from "../../../../Components/ToggleSwitch";

export class TargetPropertyPanel extends Component {
    render() {
        const { element } = this.props;

        return (
            <PropertyPanelContainer>
                <PropertySection>
                    <WithLabel label="Band Colors">
                        <CollectionColorPicker element={element} onChange={color => {
                            for (let band of element.itemElements) {
                                for (let segment of band.segments) {
                                    segment.model.color = null;
                                }
                            }
                            element.updateModel({ collectionColor: color });
                        }} />
                    </WithLabel>
                    {element.model.collectionColor != ForeColorType.COLORFUL && (
                        <WithLabel label="Shade Colors">
                            <ToggleSwitch value={element.model.shadeColors}
                                onChange={value => element.updateModel({ shadeColors: value })}
                            />
                        </WithLabel>
                    )}
                </PropertySection>
                <PropertySection>
                    <WithLabel label="Position">
                        <Dropdown value={element.model.position}
                            onChange={value => element.updateModel({ position: value }, { transition: true })}
                        >
                            <MenuItem value={PositionType.LEFT}>Left</MenuItem>
                            <MenuItem value={PositionType.CENTER}>Center</MenuItem>
                            <MenuItem value={PositionType.RIGHT}>Right</MenuItem>
                        </Dropdown>
                    </WithLabel>
                </PropertySection>
                <PropertySection>
                    <WithLabel label="Inner Radius">
                        <Slider value={element.model.innerRadius}
                            showInput
                            min={0} max={100} step={1}
                            onChange={value => element.refreshModel({ innerRadius: value })}
                            onCommit={() => element.saveModel()}
                        />
                    </WithLabel>
                    <WithLabel label="Outer Radius">
                        <Slider value={element.model.outerRadius}
                            showInput
                            min={50} max={100} step={1}
                            onChange={value => element.refreshModel({ outerRadius: value })}
                            onCommit={() => element.saveModel()}
                        />
                    </WithLabel>
                </PropertySection>
            </PropertyPanelContainer>
        );
    }
}

export class TargetControlBar extends CollectionElementControlBar {
    get addItemLabel() {
        return "Add Band";
    }
}

export class TargetBandControlBar extends Component {
    handleAddLabel = value => {
        const { element } = this.props;

        // let annotationContainer = element.canvas.layouter.canvasElement.primary.annotations;
        let annotationContainer = element.getRootElement().annotations;
        let defaultLabelText = "";

        if (element.model.labelType == "callout" && value == "callout") {
            if (annotationContainer.connectors.itemElements.find(c => c.model.target == element.uniquePath)) {
                return;
            }
        } else if (element.model.labelType == value) {
            return;
        }

        switch (value) {
            case "callout":
                if (element.model.labelType == "inside") {
                    defaultLabelText = element.model.label || "";
                }

                let angle;
                if (element.arcLength < 360) {
                    angle = element.startAngle + element.arcLength / 2;
                } else {
                    angle = element.itemIndex * 90 - 45;
                }

                let calloutPt = geom.Point.PointFromAngle(element.outerRadius + 150, angle, element.centerPoint);
                calloutPt = calloutPt.offset(element.canvasBounds.position);

                if (calloutPt.x < annotationContainer.bounds.width / 2) {
                    calloutPt.x -= 100;
                } else {
                    calloutPt.x += 100;
                }

                let x = Math.clamp(calloutPt.x / annotationContainer.bounds.width, 0, 1);
                let y = Math.clamp(calloutPt.y / annotationContainer.bounds.height, 0, 1);

                if (x < 0.5 && x > 0.4) {
                    x -= 0.2;
                }
                if (x >= 0.5 && x < 0.6) {
                    x += 0.2;
                }

                let annotation = annotationContainer.addItem({
                    type: AuthoringElementType.CALLOUT,
                    calloutType: NodeType.TEXT,
                    x: x * annotationContainer.bounds.width,
                    y: y * annotationContainer.bounds.height,
                    width: 130,
                    color: PaletteColorType.BACKGROUND_DARK,
                    text: {
                        blocks: [{
                            type: AuthoringBlockType.TEXT,
                            textStyle: TextStyleType.TITLE,
                            html: defaultLabelText
                        }]
                    },
                });

                annotationContainer.connectors.addItem({
                    source: annotation.id,
                    target: element.uniquePath,
                    endDecoration: "circle",
                    connectorType: "angle",
                });
                break;
            case "inside":
            case "none":
                // check if there is a callout
                let labelText = "";
                if (element.model.labelType == "callout") {
                    let connector = annotationContainer.connectors.itemElements.find(c => c.model.target == element.uniquePath);
                    if (connector) {
                        let nodeElement = connector.startTarget;
                        // if (nodeElement && nodeElement.text && nodeEl nodeElement.text.itemElements.length > 0 && nodeElement.text.itemElements[0].content && nodeElement.text.itemElements[0].content.textModel) {
                        //     labelText = nodeElement.text.itemElements[0].content.textModel.text || "";
                        // }
                        if (nodeElement?.childElement?.text) {
                            labelText = nodeElement.childElement.text.firstBlock.html;
                        }
                        annotationContainer.deleteItem(connector.model.source);
                        annotationContainer.connectors.deleteItem(connector.id);
                    }
                }

                if (value == "inside") {
                    element.model.label = labelText;
                }

                break;
        }
        element.model.labelType = value;
        element.canvas.updateCanvasModel();
    }

    render() {
        const { element } = this.props;

        const band = element.parentElement;

        return (
            <ControlBar>
                <ControlBarGroup>
                    <WithLabel label="Segments">
                        <Slider value={band.model.segments.length}
                            min={1} max={8} step={1}
                            onChange={value => {
                                let segmentColor = _.last(band.model.segments).color;

                                if (value < band.model.segments.length) {
                                    band.model.segments = band.model.segments.slice(0, value);
                                } else if (value > band.model.segments.length) {
                                    for (let i = band.model.segments.length; i < value; i++) {
                                        band.model.segments.push({ color: segmentColor });
                                    }
                                }

                                band.canvas.refreshCanvas(false);
                            }}
                            onCommit={value => band.saveModel()}
                        />

                    </WithLabel>
                </ControlBarGroup>
                <ControlBarGroup>
                    <ItemColorPicker element={element} />
                    <Popup label="Add Label" showArrow>
                        <PopupContent>
                            <MenuItem value="none" onClick={() => this.handleAddLabel("none")}>None</MenuItem>
                            <MenuItem value="inside" onClick={() => this.handleAddLabel("inside")}>Inside</MenuItem>
                            <MenuItem value="callout" onClick={() => this.handleAddLabel("callout")}>Callout</MenuItem>
                        </PopupContent>
                    </Popup>
                </ControlBarGroup>
            </ControlBar>
        );
    }
}

export class TargetBandSegmentControlBar extends Component {
    render() {
        const { element } = this.props;

        return (
            <ControlBar>
                <ItemColorPicker element={element} />
            </ControlBar>
        );
    }
}

const LabelFrame = styled.div.attrs(({ bounds }) => ({
    style: {
        ...bounds.toObject()
    }
}))`
    position: absolute;
    pointer-events: auto;
    cursor: text;
`;

const Frame = styled.div.attrs(({ bounds }) => ({
    style: {
        ...bounds.toObject()
    }
}))`
    position: absolute;
    pointer-events: auto;
    border: 1px dotted #50bbe6;
    cursor: move;
`;

export class TargetBandSegmentSelection extends CollectionItemElementSelection {
    constructor(props) {
        super(props);

        this.selectionPathRef = React.createRef();

        this.mouseDownAt = 0;

        this.state = {
            labelBounds: null
        };
    }

    get canDrag() {
        return false;
    }

    get hasInnerLabel() {
        const { element } = this.props;
        return element.labelType === "inside";
    }

    get labelBounds() {
        const { element, containerBounds, canvasController } = this.props;

        if (element.labelType !== "inside") {
            return null;
        }

        const containerScreenBounds = containerBounds.offset(canvasController.canvasScreenBounds);
        return new geom.Rect(element.label.ref.current.ref.current.getBoundingClientRect()).offset(-containerScreenBounds.x, -containerScreenBounds.y);
    }

    get canDelete() {
        const { element } = this.props;
        return element.parentElement.parentElement.itemCollection.length > element.parentElement.parentElement.minItemCount;
    }

    get showSelectionBorder() {
        return false;
    }

    componentDidMount() {
        this.setLabelBounds();
    }

    componentDidUpdate(prevProps) {
        this.setLabelBounds();
    }

    handleDeleteElement = async () => {
        const { element, selectionLayerController } = this.props;

        selectionLayerController.setSelectedElements([]);

        if (element.parentElement.segments.length == 1) {
            element.parentElement.parentElement.deleteItem(element.parentElement.id);
        } else {
            element.parentElement.model.segments.splice(element.index, 1);
        }

        element.canvas.markStylesAsDirty();
        element.canvas.updateCanvasModel();
    }

    setLabelBounds = () => {
        const { element, containerBounds, canvasController } = this.props;
        const { labelBounds } = this.state;

        if (element.labelType !== "inside" && labelBounds !== null) {
            this.setState({ labelBounds: null });
        }

        if (!element.label?.textPathRef.current) {
            return;
        }

        const containerScreenBounds = containerBounds.offset(canvasController.canvasScreenBounds);
        const newLabelBounds = new geom.Rect(element.label.textPathRef.current.getBoundingClientRect()).offset(-containerScreenBounds.x, -containerScreenBounds.y);
        if (labelBounds?.toString() !== newLabelBounds.toString()) {
            this.setState({ labelBounds: newLabelBounds });
        }
    }

    handleMouseDown = event => {
        const { element } = this.props;

        const now = Date.now();
        if (now - this.mouseDownAt < 500) {
            const targetPt = new geom.Rect(this.selectionPathRef.current.getBoundingClientRect()).getPoint("center");

            ShowDialog(EditTextDialog, {
                targetPt,
                target: element,
                value: element.model.label,
                callback: value => {
                    element.updateModel({ label: value });
                },
            });
        }

        this.mouseDownAt = now;
    }

    render() {
        const { element, isBeingDragged, isBeingResized } = this.props;
        const { labelBounds } = this.state;

        const canvasScale = element.canvas.getScale();

        const { bounds, outerRadius, innerRadius, startAngle, endAngle, arcLength } = element;

        const center = new geom.Point(bounds.width / 2, bounds.height / 2).multiply(canvasScale);

        const angle = arcLength < 360 ? (startAngle + arcLength / 2) * Math.PI / 180 : -Math.PI / 4;
        const deleteButtonPosition = new geom.Point(
            center.x + outerRadius * canvasScale * Math.cos(angle),
            center.y + outerRadius * canvasScale * Math.sin(angle)
        );

        let selectionPath;
        if (innerRadius > 0 && arcLength < 360) {
            selectionPath = Shape.drawArc2(outerRadius * canvasScale, innerRadius * canvasScale, startAngle, endAngle, center);
        } else {
            selectionPath = Shape.drawCircle(outerRadius * canvasScale, center);
        }

        return (
            <ElementSelectionBox>
                {labelBounds && <LabelFrame bounds={labelBounds} onMouseDown={this.handleMouseDown} />}

                <SVGPathSelection>
                    <path d={selectionPath.toPathData()} ref={this.selectionPathRef} />
                </SVGPathSelection>

                {this.canDelete && !isBeingDragged && !isBeingResized &&
                    <DeleteElementButton
                        onClick={this.handleDeleteElement}
                        left={deleteButtonPosition.x}
                        top={deleteButtonPosition.y}
                    />
                }
            </ElementSelectionBox >
        );
    }
}

import { CollectionItemElementSelection } from "../../ElementSelections/CollectionItemElementSelection";
import { defaultDragResizeProps } from "../../../../../editor/PresentationEditor/DragElementManager";
import { ResizeDirection } from "../../../../../../common/constants";
import React, { Component } from "react";
import { ControlBar } from "../../ElementControlBars/Components/ControlBar";
import { ItemColorPicker } from "../../EditorComponents/ColorPickers/ItemColorPicker";

export class DataHiliteNodeElementSelection extends CollectionItemElementSelection {
    get canResize() {
        return true;
    }

    get canDrag() {
        return false;
    }

    get dragResizeProps() {
        const { element, canvas } = this.props;

        let initialSize;
        let minSize = 40;
        let maxSize = 200;
        return {
            ...defaultDragResizeProps,
            resizeDirections: [ResizeDirection.RIGHT],
            onDragStart: async () => {
                initialSize = element.model.userSize ?? element.bounds.width;
            },
            onDrag: async ({ dragOffset, resizeDirection }) => {
                element.model.userSize = Math.clamp(initialSize + dragOffset.x * (resizeDirection === ResizeDirection.LEFT ? -1 : 1), minSize, maxSize);

                if (element.canRefreshElement) {
                    element.refreshElement();
                } else {
                    await canvas.refreshCanvas();
                }
            }
        };
    }
}

export class DataHiliteNodeElementControlBar extends Component {
    render() {
        const { element } = this.props;

        let autoColor;
        if (element.model.dataSource) {
            let chart = element.canvas.getElementByUniquePath(element.model.dataSource.elementId);
            if (chart) {
                let series = chart.chartConfig?.series.find(s => s.id == element.model.dataSource.seriesId);
                if (series) {
                    autoColor = element.canvas.getTheme().palette.getColor(series.colorName).toRgbString();
                }
            }
        }

        return (
            <ControlBar>
                <ItemColorPicker
                    allowedDecorationStyles={["filled", "outlined"]}
                    showDecorationStyles element={element} autoColorOverride={autoColor} autoLabel="Use Series" allowColorOnColor />
            </ControlBar>
        );
    }
}

import React, { Component } from "react";
import { PropertyPanelContainer, PropertySection } from "../../../../EditorComponents/PropertyPanel";
import { WithLabel } from "../../../../Components/WithLabel";
import { MenuItem } from "../../../../Components/Menu";
import { Dropdown } from "../../../../Components/Dropdown";
import { Slider } from "../../../../Components/Slider";
import { CollectionColorPicker } from "../EditorComponents/ColorPickers/CollectionColorPicker";
import { ControlBar } from "../ElementControlBars/Components/ControlBar";
import { ColorPicker } from "../EditorComponents/ColorPickers/ColorPicker";
import { ItemColorPicker } from "../EditorComponents/ColorPickers/ItemColorPicker";
import { ToggleSwitch } from "../../../../Components/ToggleSwitch";
import { EmphasizeToggle } from "../EditorComponents/EmphasizeToggle";

export class ConnectionDiagramPropertyPanel extends Component {
    render() {
        const { element } = this.props;

        return (
            <PropertyPanelContainer>
                <PropertySection>
                    <WithLabel label="Arrow Color">
                        <CollectionColorPicker element={element} />
                    </WithLabel>
                </PropertySection>
                <PropertySection>
                    <WithLabel label="Style">
                        <Dropdown value={element.model.style}
                            onChange={value => element.updateModel({ style: value })}
                        >
                            <MenuItem value="one-to-many">One to Many</MenuItem>
                            <MenuItem value="many-to-one">Many to One</MenuItem>
                        </Dropdown>
                    </WithLabel>
                </PropertySection>
                <PropertySection>
                    <WithLabel label="Label with">
                        <Dropdown value={element.labelType}
                            onChange={value => element.updateModel({ labelType: value })}
                        >
                            <MenuItem value="none">None</MenuItem>
                            <MenuItem value="number">Numbers</MenuItem>
                            <MenuItem value="letter">Letters</MenuItem>
                            <MenuItem value="icon">Icon</MenuItem>
                        </Dropdown>
                    </WithLabel>
                </PropertySection>

            </PropertyPanelContainer>
        );
    }
}

export class ConnectionDiagramItemControlBar extends Component {
    render() {
        const { element } = this.props;

        return (
            <ControlBar>
                <EmphasizeToggle element={element} />
                <WithLabel label="Color" gap={0}>
                    <ItemColorPicker element={element} />
                </WithLabel>
            </ControlBar>
        );
    }
}


export default class CycleAnimator {
    get duration() {
        return 3000;
    }

    constructor(element, type, playReverse) {
        this.element = element;
        this.type = type;
        this.playReverse = playReverse;

        this.startTime = null;
    }

    handleAnimationFrame(timestamp) {
        if (!this.startTime) {
            this.startTime = timestamp;
        }

        let time = timestamp - this.startTime;
        if (time > this.duration) {
            this.startTime = timestamp;
            time = 0;
        }

        this._handleAnimationFrame(time);
    }

    _handleAnimationFrame(time) {
    }
}

import { MenuItem } from "@material-ui/core";
import React, { Component } from "react";

import { AuthoringElementType, CalloutType, DirectionType, FormatType } from "../../../../../common/constants";
import { Dropdown } from "../../../../Components/Dropdown";
import { NumericStepper } from "../../../../Components/NumericStepper";
import { Slider } from "../../../../Components/Slider";
import { ToggleSwitch } from "../../../../Components/ToggleSwitch";
import { WithLabel } from "../../../../Components/WithLabel";

import { Button } from "../../../../Components/Button";
import { Icon } from "../../../../Components/Icon";
import { PropertyPanelContainer, PropertySection } from "../../../../EditorComponents/PropertyPanel";
import { ColorPicker } from "../EditorComponents/ColorPickers/ColorPicker";
import { FormattingDropdown } from "../EditorComponents/FormattingOptions";
import { ControlBar } from "../ElementControlBars/Components/ControlBar";
import { numeral } from "../../../../vendor";

export class ThermometerPropertyPanel extends Component {
    render() {
        let { element, selectionLayerController } = this.props;

        if (!element.isInstanceOf("Thermometer")) {
            element = element.findClosestOfType("Thermometer");
        }

        return (
            <PropertyPanelContainer>
                <PropertySection>
                    <WithLabel label="Thermometer Color">
                        <ColorPicker value={element.model.color} canvas={element.canvas}
                            onChange={color => element.updateModel({ color }, { refreshStyles: true })}
                            showBackgroundColors showPositiveNegative
                        />
                    </WithLabel>
                </PropertySection>
                <PropertySection>
                    <WithLabel label="Max Value">
                        <NumericStepper value={element.model.targetMax}
                            onChange={targetMax => {
                                let updatedValue = numeral(element.model.value).value() / 100 * element.model.targetMax;

                                if (updatedValue >= 100) {
                                    updatedValue = 100;
                                }

                                element.updateModel({ targetMax, value: Math.min(numeral(updatedValue).value() * 100 / targetMax, 100) });
                            }}
                            min={1} max={99999999} width={100}
                        />
                    </WithLabel>
                    <WithLabel label="Value Format">
                        <FormattingDropdown format={element.format}
                            formatOptions={element.formatOptions}
                            onChangeFormat={format => element.updateModel({ format })}
                            onChangeFormatOptions={formatOptions => element.updateModel({ formatOptions })}
                            allowedFormats={[FormatType.PERCENT, FormatType.NUMBER, FormatType.CURRENCY]}
                        />
                    </WithLabel>
                </PropertySection>
                <PropertySection>
                    <WithLabel label="Number of Ticks">
                        <NumericStepper value={element.model.tickCount}
                            onChange={tickCount => element.updateModel({ tickCount })}
                            min={2}
                            max={15}
                        />
                    </WithLabel>
                </PropertySection>
                <PropertySection>
                    <WithLabel label="Position">
                        <Dropdown value={element.model.position}
                            onChange={position => element.updateModel({ position })}
                        >
                            <MenuItem value="left">Left</MenuItem>
                            <MenuItem value="center">Center</MenuItem>
                            <MenuItem value="right">Right</MenuItem>
                        </Dropdown>
                    </WithLabel>

                    <WithLabel label="Show Value">
                        <ToggleSwitch value={element.model.showValue}
                            onChange={showValue => element.updateModel({ showValue })}
                        />
                    </WithLabel>

                    <WithLabel label="Show Bulb">
                        <ToggleSwitch value={element.model.showBulb}
                            onChange={showBulb => element.updateModel({ showBulb })}
                        />
                    </WithLabel>
                    <WithLabel label="Show Labels">
                        <ToggleSwitch value={element.model.showTickValues}
                            onChange={showTickValues => element.updateModel({ showTickValues })}
                        />
                    </WithLabel>
                </PropertySection>
                {element.canAddCallouts && <PropertySection>
                    <Button
                        disabled={element.annotations.itemCount >= element.annotations.maxItemCount}
                        onClick={() => {
                            const annotations = element.annotations;

                            const { type, calloutType, width } = element.annotations.getDefaultModel(AuthoringElementType.CALLOUT, {
                                calloutType: CalloutType.BULLET_TEXT
                            });

                            const annotation = annotations.addItem({
                                type,
                                calloutType,
                                hideNodeConnectorWidget: true,
                                x: (element.position === "right" ? 0 : 0.75) * annotations.bounds.width,
                                y: Math.random() * annotations.bounds.height,
                                width,
                                textDirection: DirectionType.AUTO
                            });

                            annotations.connectors.addItem({
                                source: annotation.id,
                                target: element.thermometerGauge.uniquePath,
                                endPointIsLocked: true,
                                canChangeConnectorType: false
                            });

                            element.canvas.updateCanvasModel().then(() => {
                                selectionLayerController.setSelectedElements([annotations.getItemElementById(annotation.id)]);
                            });
                        }}
                    >
                        <Icon>add</Icon>
                        Add Callout
                    </Button>
                </PropertySection>}

            </PropertyPanelContainer>
        );
    }
}

export class ThermometerControlBar extends Component {
    render() {
        const { element } = this.props;

        return (
            <ControlBar>
                <WithLabel label="Value" gap={0}>
                    <Slider value={element.model.value}
                        onChange={value => element.refreshModel({ value })}
                        onCommit={() => element.saveModel()}
                        min={0}
                        max={100}
                    />
                </WithLabel>
            </ControlBar>
        );
    }
}

import { isDevelopment } from "js/config";
import { getTemplates, variations, classicTemplates, chartUtils, tableUtils } from "common/slideTemplates/v10"; // eslint-disable-line

const slideTemplates = getTemplates(isDevelopment);

export {
    slideTemplates,
    variations,
    classicTemplates,
    chartUtils,
    tableUtils
};

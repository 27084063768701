import { Button } from "@material-ui/core";
import { Key } from "js/core/utilities/keys";
import React, { FC, useEffect, useRef } from "react";
import styled from "styled-components";
import { FlexBox } from "./LayoutGrid";
import { usePrev } from "../hooks/usePrev";

const ExpandableLabel = styled.label`
& {
    flex-grow: 1;
    display: block;
    border: 1px solid #CCCCCC;
    padding: 0;
    border-radius: 2px;   
}

&:focus-within {
    flex-basis: 100%;
}
`;

const Textbox = styled.textarea`
& {
    resize: none;
    outline: none;
    overflow-x: none;
    overflow-y: hidden;
    border: none;
    font-family: Source Sans Pro;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0px;
    box-sizing: border-box;
    
    width: 100%;
    padding: 6px;
}
`;

type Props = {
    value?: string,
    disabled?: boolean,
    autoFocus?: boolean,
    onSubmit?: (label: string) => void,
    submitText?: string,
    allowSubmitWhenEmpty?: boolean,
    submitWhenBlurred?: boolean,
    clearAfterSubmit?: boolean,
    placeholder?: string,
    maxLength?: number,
    onFocus?: () => void,
    onBlur?: () => void,
    onChange?: (prompt: string) => void,
}

export const AutoHeighteningTextbox: FC<Props> = ({
    value = "",
    disabled = false,
    autoFocus = false,
    onSubmit,
    submitText = null,
    allowSubmitWhenEmpty = false,
    submitWhenBlurred = false,
    clearAfterSubmit = false,
    placeholder = null,
    maxLength = 200,
    onFocus,
    onBlur,
    onChange,
}) => {
    const refLabel = useRef(null);
    const refTextbox = useRef<HTMLTextAreaElement>(null);

    // Override the textbox text if the value has changed
    const prevValue = usePrev(value);
    if (value !== prevValue && refTextbox.current) {
        refTextbox.current.value = value;
    }

    const adjustHeight = () => {
        const elem = refTextbox.current;
        if (elem) {
            // Reset the height to 0 so we can get an accurate scroll height
            elem.style.height = "0";
            elem.style.height = `${elem.scrollHeight}px`;
        }
    };
    // Run once on mount
    useEffect(() => {
        adjustHeight();

        if (autoFocus) {
            setTimeout(() => {
                refTextbox.current.focus();
            }, 0);
        }

        // Override the textbox text if the value has changed
        if (value !== prevValue) {
            refTextbox.current.value = value;
        }
    }, []);

    const submit = () => {
        const elem = refTextbox.current;
        const text = elem.value.trim();
        (!!text || allowSubmitWhenEmpty) && onSubmit && onSubmit(text);

        if (clearAfterSubmit) {
            elem.value = "";
            refLabel.current.style.height = null;
        }
    }

    return (
        <ExpandableLabel ref={refLabel}>
            <Textbox
                ref={refTextbox}
                placeholder={placeholder}
                disabled={disabled}
                maxLength={maxLength}
                onFocus={evt => {
                    onFocus && onFocus();
                }}
                onBlur={evt => {
                    onBlur && onBlur();
                    submitWhenBlurred && submit();
                }}
                onInput={(evt) => {
                    adjustHeight();
                    onChange && onChange(refTextbox.current.value.trim());
                }}
                onKeyDown={evt => {
                    switch (evt.keyCode) {
                        case Key.ENTER:
                            evt.persist();
                            evt.stopPropagation();
                            evt.preventDefault();

                            submit();
                        break;
                    }
                }}
            />
            {
                submitText &&
                <FlexBox
                    right
                    style={{
                        marginTop: -6,
                    }}
                >
                    <Button
                        color="primary"
                        variant="text"
                        disabled={disabled}
                        onClick={() => submit()}
                    >{submitText}</Button>
                </FlexBox>
            }
        </ExpandableLabel>
    );
}
import * as geom from "../../../../../core/utilities/geom";
import { MarkerCallout } from "./MarkerCallout";
import { CalloutType } from "../../../../../../common/constants";

export class TimelineTextCallout extends MarkerCallout {
    get authoringElementType() {
        return CalloutType.TIMELINE_TEXT;
    }

    get markerSize() {
        return 10;
    }

    getMarkerSize() {
        return new geom.Size(10, 10);
    }

    get anchorBounds() {
        return new geom.Rect(0, 0, 10, 10);
    }

    getAnchorPoint(connector, anchor, end) {
        return new geom.Point(0, 50);
    }
}

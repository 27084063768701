import { BlockStructureType, ElementTextBlockPositionType, TextStyleType } from "common/constants";

import { TextElement } from "../base/Text/TextElement";
import { TextTrayPropertyPanel, TextTraySelection } from "../../Editor/ElementPropertyPanels/TextTrayUI";
import { AttributionPropertyPanel, AttributionSelection } from "../../Editor/ElementPropertyPanels/AttributionUI";

export class ElementTextBlock extends TextElement {
    static get schema() {
        return {
            backgroundColor: "background_accent"
        };
    }

    getElementPropertyPanel() {
        return TextTrayPropertyPanel;
    }

    getElementSelection() {
        return TextTraySelection;
    }

    get name() {
        return "Text Tray";
    }

    get textBlockType() {
        return this.canvas.model.layout.elementTextBlockPosition;
    }

    get selectionBounds() {
        return this.canvasBounds.inflate(this.selectionPadding);
    }

    get selectionPadding() {
        if (this.textBlockType == ElementTextBlockPositionType.TRAY) {
            return 0;
        }

        return 10;
    }

    containsPoint(pt) {
        if (this.bounds) {
            return this.selectionBounds.inflate(this.rolloverPadding).contains(pt);
        } else {
            return false;
        }
    }

    get allowStyling() {
        return true;
    }

    get allowAlignment() {
        return true;
    }

    get autoHeight() {
        return true;
    }

    get allowedBlockTypes() {
        return [TextStyleType.HEADING, TextStyleType.TITLE, TextStyleType.BODY, TextStyleType.CAPTION, TextStyleType.LABEL];
    }

    get defaultBlockTextStyle() {
        if (this.textBlockType == ElementTextBlockPositionType.TRAY) {
            return TextStyleType.TITLE;
        } else {
            return TextStyleType.BODY;
        }
    }

    get blockStructure() {
        return BlockStructureType.FREEFORM;
    }

    get blockDefaults() {
        return {
            title: {
                evenBreak: true
            }
        };
    }

    _loadStyles(styles) {
        super._loadStyles(styles);

        if (!this.model.color) {
            this.model.color = this.model.backgroundColor;
            delete this.model.backgroundColor;
        }

        switch (this.textBlockType) {
            case ElementTextBlockPositionType.TRAY:
                styles.applyStyles(styles.tray);

                break;
            case ElementTextBlockPositionType.INLINE:
                styles.applyStyles(styles.below);
                break;
        }

        if (this.canvas.model.layout.showElementAttribution) {
            styles.paddingBottom += 20;
        }
    }

    resetUserColors() {
        return false;
    }

    get disableAnimationsByDefault() {
        return true;
    }

    _exportToSharedModel() {
        return { layoutTextBlocks: this.model };
    }

    _importFromSharedModel(model) {
        return model.layoutTextBlocks;
    }
}

export class ElementAttribution extends TextElement {
    getElementPropertyPanel() {
        return AttributionPropertyPanel;
    }

    getElementSelection() {
        return AttributionSelection;
    }

    get selectionPadding() {
        return 15;
    }

    get selectionBounds() {
        return this.canvasBounds.inflate(this.selectionPadding || 0);
    }

    get autoHeight() {
        return true;
    }

    get blockStructure() {
        return BlockStructureType.FREEFORM;
    }

    get defaultBlockTextStyle() {
        return TextStyleType.BODY;
    }

    get allowedBlockTypes() {
        return [TextStyleType.BODY, TextStyleType.TITLE, TextStyleType.CAPTION];
    }

    _getBackgroundColor(forElement) {
        if (this.canvas.model.layout.elementTextBlockPosition == "tray" && this.canvas.layouter.elements.elementTextBlock) {
            return this.canvas.layouter.elements.elementTextBlock.getBackgroundColor();
        } else {
            return super._getBackgroundColor(forElement);
        }
    }

    get disableAnimationsByDefault() {
        return true;
    }

    getPlaceholderForBlock() {
        return { text: "Type attribution or footnote" };
    }

    _exportToSharedModel() {
        return { layoutAttribution: this.model };
    }

    _importFromSharedModel(model) {
        return model.layoutAttribution;
    }
}

export const elements = {
    ElementTextBlock,
    ElementAttribution,
};

import { BaseElementSelection } from "../ElementSelections/BaseElementSelection";
import { _ } from "../../../../vendor";
import { IconButton } from "../../../../Components/IconButton";
import { AuthoringBlockType, HorizontalAlignType, TextStyleType } from "../../../../../common/constants";
import { ElementUIButton } from "../EditorComponents/ElementUIButton";
import React, { Component } from "react";
import { PropertyPanelContainer, PropertySection, PropertySectionHeader } from "../../../../EditorComponents/PropertyPanel";
import styled from "styled-components";
import { WithLabel } from "../../../../Components/WithLabel";
import { Icon } from "../../../../Components/Icon";
import { Dropdown } from "../../../../Components/Dropdown";
import { MenuItem } from "../../../../Components/Menu";
import { ToggleButton, ToggleButtons } from "../../../../Components/ToggleButtons";
import { PropertyPanelHeader } from "../../../../editor/ElementEditor/PropertyPanelHeader";
import { PropertyPanelButton } from "../../../../EditorComponents/PropertyPanelButton";
import { CollectionElementControlBar } from "../ElementControlBars/CollectionElementControlBar";

const AddButtonContainer = styled.div`
    position: absolute;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    top: calc(50% - 12px);
    right: -44px;
    width: 22px;
    height: 22px;
`;

export class PresentationTitlePresentersPropertyPanel extends Component {
    render() {
        const { element } = this.props;

        const containerElement = element.parentElement;

        return (
            <PropertyPanelContainer>
                <PropertyPanelHeader>Presenter Names</PropertyPanelHeader>
                <PropertySection>

                    <WithLabel label="Alignment" flex>
                        <ToggleButtons value={element.model.horizontalAlign}
                            onChange={value => element.updateModel({ horizontalAlign: value }, { transition: true })}
                        >
                            <ToggleButton size="small" value={HorizontalAlignType.LEFT}>
                                <Icon>format_align_left</Icon>
                            </ToggleButton>
                            <ToggleButton size="small" value={HorizontalAlignType.CENTER}>
                                <Icon>format_align_center</Icon>
                            </ToggleButton>
                            <ToggleButton size="small" value={HorizontalAlignType.RIGHT}>
                                <Icon>format_align_right</Icon>
                            </ToggleButton>
                        </ToggleButtons>
                    </WithLabel>
                    {element.isOverImage &&
                        <WithLabel label="Style" flex>
                            <Dropdown value={element.model.textStyle}
                                onChange={value => element.updateModel({ textStyle: value }, { refreshStyles: true })}
                            >
                                <MenuItem value="white_text">White Text</MenuItem>
                                <MenuItem value="white_text_with_shadow">White Text with Shadow</MenuItem>
                                <MenuItem value="dark_text">Dark Text</MenuItem>
                                <MenuItem value="white_box">White Backdrop</MenuItem>
                                <MenuItem value="transparent_light_box">Transparent Backdrop</MenuItem>
                                <MenuItem value="transparent_dark_box">Dark Backdrop</MenuItem>
                            </Dropdown>
                        </WithLabel>
                    }
                </PropertySection>
                <PropertyPanelButton title="Delete Presenter Names" icon="delete" onClick={() => {
                    containerElement.updateModel({ presenters: null });
                }} />

            </PropertyPanelContainer>
        );
    }
}

export class PresentationTitlePresentersControlBar extends CollectionElementControlBar {
    get addItemLabel() {
        return "Add Presenter";
    }
}

export class PresentationTitlePresentersSelection extends BaseElementSelection {
    // get showSelectionBorder() {
    //     return false;
    // }
    //
    // renderCustomChildren() {
    //     const { element } = this.props;
    //
    //     let x = element.canvasBounds.left + _.last(element.itemElements)?.canvasBounds.right ?? 0;
    //     x *= element.canvas.getScale();
    //     x -= 20;
    //
    //     return (
    //         <AddButtonContainer style={{ right: x }}>
    //             <IconButton fill size={22} icon="add_circle" onClick={() => {
    //                 element.addItem();
    //                 element.saveModel();
    //             }} />
    //         </AddButtonContainer>
    //     );
    // }
}

export class PresentationTitlePresentersItemSelection extends BaseElementSelection {
    get canDelete() {
        return true;
    }

    handleDeleteElement = () => {
        const { element } = this.props;

        element.parentElement.deleteItem(element.id);
        element.parentElement.saveModel();
    }

    handlAddBlock = async () => {
        const { element, selectionLayerController } = this.props;

        const block = element.text.addBlock({
            type: AuthoringBlockType.TEXT,
            textStyle: TextStyleType.BODY
        });

        await element.canvas.refreshCanvas();
        await selectionLayerController.selectTextElementBlock(element.text, block.id);
    }

    renderCustomChildren() {
        const { element } = this.props;

        const bodyBlock = element.text?.getBlockByTextStyle(TextStyleType.BODY);

        return (
            <>
                {super.renderCustomChildren()}

                {element.text && !bodyBlock &&
                    <ElementUIButton
                        style={{ bottom: -30, left: 0 }}
                        onClick={this.handlAddBlock}
                    >
                        Add Title/Role
                    </ElementUIButton>
                }
            </>
        );
    }
}

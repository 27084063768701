import { CalloutType, DecorationStyle } from "../../../../../../common/constants";
import { MarkerCallout } from "./MarkerCallout";
import { SVGCircleElement } from "../../base/SVGElement";

export class BulletCallout extends MarkerCallout {
    get authoringElementType() {
        return CalloutType.BULLET_TEXT;
    }

    get decorationStyle() {
        return DecorationStyle.FILLED;
    }

    buildMarker() {
        return this.addElement("marker", () => SVGCircleElement);
    }
}

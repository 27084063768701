import React, { Component } from "react";
import styled from "styled-components";

const MixedValueLabel = styled.div`
    font-size: 11px;
    color: currentColor;
    border: solid 1px color-mix(in srgb, currentColor 50%, transparent);
    padding: 3px 5px;
`;

export function MixedValue() {
    return (
        <MixedValueLabel>MIXED</MixedValueLabel>
    );
}


import React, { Component } from "react";
import styled from "styled-components";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { IconButton, Icon, Tabs, Tab, Tooltip, Switch, Slider, TextField } from "@material-ui/core";

import { FlexBox } from "../../../../../../react/components/LayoutGrid";
import { $, _ } from "../../../../../../vendor";
import { getStaticUrl } from "../../../../../../config";
import { ShowDialog } from "../../../../../../react/components/Dialogs/BaseDialog";
import { themeColors } from "../../../../../../react/sharedStyles";
import ImportMediaDialog from "../../../../../../react/views/ImportMedia/ImportMediaDialog";
import { dialogTheme } from "../../../../../../react/materialThemeOverrides";
import { PopupMenu, PopupMenuPaddedContainer } from "../../../../../../react/components/PopupMenu";
import { LabeledContainer } from "../../../../../../react/components/LabeledContainer";

import { DeviceFrames } from "../../../../elements/DeviceFrames";

import { AuthoringCanvasControlBar, ControlBarGroup } from "../AuthoringCanvasControlBar";
import { ShadowEditor } from "../../../../../../Components/legacy-components/AuthoringEditorComponents/ShadowEditor";
import { AuthoringBaseEditor } from "./AuthoringBaseEditor";

export class AuthoringVideoEditor extends AuthoringBaseEditor {
    constructor() {
        super();

        this.controlBarRef = React.createRef();
    }

    setSelectionState() {
        super.setSelectionState();
        this.setState({
            frameType: this.getElementValue("frameType"),
        });
    }

    handleChooseAsset = () => {
        const { selection } = this.props;

        const element = selection[0];
        const videoElement = element.childElement.video;

        ShowDialog(ImportMediaDialog, {
            fileTypes: ["video"],
            onSuccess: asset => {
                const {
                    hostedVideoUrl,
                    id,
                } = asset;
                videoElement.model.videoId = hostedVideoUrl;
                videoElement.model.videoAssetId = id;

                videoElement.canvas.updateCanvasModel(false);
            }
        });
    }

    handleChangeFrame = (frameType, frameAspectRatio) => {
        const { selection, refreshCanvasAndSaveChanges } = this.props;

        for (const element of selection) {
            // set the frame model
            element.childElement.model.frameType = frameType;

            if (frameType.equalsAnyOf("none", "light", "dark")) {
                // resize container to image aspect ratio
                const videoElement = element.childElement.video;
                if (videoElement.getOriginalVideoSize) {
                    const aspectRatio = videoElement.getOriginalVideoSize().aspectRatio;
                    if (aspectRatio > 1) {
                        const newWidth = element.model.height * aspectRatio;
                        element.model.x += (element.model.width - newWidth) / 2;
                        element.model.width = newWidth;
                    } else {
                        const newHeight = element.model.width / aspectRatio;
                        element.model.y += (element.model.height - newHeight) / 2;
                        element.model.height = newHeight;
                    }
                    videoElement.model.aoiLeft = videoElement.model.aoiRight = videoElement.model.aoiTop = videoElement.model.aoiBottom = videoElement.model.scale = null;
                }
            } else {
                // resize container to frame aspectRatio instead of image
                const width = element.model.height * frameAspectRatio;
                element.model.x = element.model.x + element.model.width / 2 - width / 2;
                element.model.width = width;
            }
        }

        this.setState({ frameType });

        refreshCanvasAndSaveChanges();
    }

    handleChangeShadow = shadow => {
        const { selection, refreshCanvasAndSaveChanges } = this.props;

        for (const element of selection) {
            element.model.shadow = shadow;
        }
        refreshCanvasAndSaveChanges();
    }

    handleChangeMuted = event => {
        const { selection, refreshCanvasAndSaveChanges } = this.props;

        for (const element of selection) {
            const { video } = element.childElement;
            if (video) {
                video.model.muted = !event.target.checked;
            }
        }
        refreshCanvasAndSaveChanges();
    }

    handleChangeAutoPlay = event => {
        const { selection, refreshCanvasAndSaveChanges } = this.props;

        for (const element of selection) {
            const { video } = element.childElement;
            if (video) {
                video.model.autoPlay = event.target.checked;
            }
        }
        refreshCanvasAndSaveChanges();
    }

    handleChangeStartTime = event => {
        const { selection, refreshCanvasAndSaveChanges } = this.props;

        for (const element of selection) {
            const { video } = element.childElement;
            if (video) {
                video.model.startTime = Math.abs(parseInt(event.target.value));
            }
        }
        refreshCanvasAndSaveChanges();
    }

    render() {
        const { selection, editingElement } = this.props;
        const { frameType, shadow, opacity } = this.state;

        const element = selection[0];
        const { video } = element.childElement;

        let frameLabel;
        if (frameType == "mixed") {
            frameLabel = "mixed";
        } else {
            frameLabel = DeviceFrames.find(item => item.id == (frameType || "none")).name;
        }

        return (
            <AuthoringCanvasControlBar ref={this.controlBarRef}>
                {selection.length == 1 &&
                    <Tooltip title="Replace Video..." arrow>
                        <IconButton onClick={this.handleChooseAsset}>
                            <Icon>video_library</Icon>
                        </IconButton>
                    </Tooltip>
                }
                <PopupMenu icon="settings">
                    <PopupMenuPaddedContainer>
                        <MuiThemeProvider theme={dialogTheme}>
                            <LabeledContainer icon="volume_up" label="Audio">
                                <Switch
                                    name="muted"
                                    color="primary"
                                    checked={!(video.model.muted || false)}
                                    onChange={this.handleChangeMuted}
                                />
                            </LabeledContainer>
                            <LabeledContainer icon="play_circle_filled" label="Auto Play">
                                <Switch
                                    name="autoPlay"
                                    color="primary"
                                    checked={video.model.autoPlay || false}
                                    onChange={this.handleChangeAutoPlay}
                                />
                            </LabeledContainer>
                        </MuiThemeProvider>
                        <LabeledContainer icon="timer" label="Start Time">
                            <TextField
                                type="number"
                                variant="outlined"
                                value={video.model.startTime || 0}
                                onChange={this.handleChangeStartTime}
                                inputProps={{
                                    min: 0,
                                    max: 9999
                                }}
                            />
                        </LabeledContainer>
                        <hr />
                        <LabeledContainer icon="filter_frames" label="Frame">
                            <PopupMenu label={frameLabel} showArrow dialogStyle>
                                <ImageFrameMenu onSelect={this.handleChangeFrame} />
                            </PopupMenu>
                        </LabeledContainer>
                        <LabeledContainer icon="lens_blur" label="Shadow">
                            <ShadowEditor element={element} shadow={shadow} onChange={shadow => this.setContainerValues({ shadow })} />
                        </LabeledContainer>
                        <LabeledContainer icon="opacity" label="Opacity">
                            <Slider
                                value={opacity}
                                onChange={(event, value) => this.setContainerValues({ opacity: value }, false)}
                                onChangeCommitted={(event, value) => this.setContainerValues({ opacity: value })}
                                valueLabelDisplay="auto"
                                min={0}
                                max={100}
                            />
                        </LabeledContainer>
                    </PopupMenuPaddedContainer>
                </PopupMenu>
            </AuthoringCanvasControlBar>
        );
    }
}

const TabBar = styled.div`
  //background: #eee;
  width: 100%;
`;

class ImageFrameMenu extends Component {
    state = {
        value: 0
    }

    handleChangeTab = (event, tab) => {
        this.setState({ value: tab });
    }

    handleSelect = (id, aspectRatio) => {
        this.props.onSelect(id, aspectRatio);
    }

    render() {
        const { value } = this.state;

        return (
            <FlexBox vertical left style={{ width: 1000 }}>
                <TabBar>
                    <Tabs
                        value={value}
                        onChange={this.handleChangeTab}
                    >
                        <Tab id="decorative" label="Decorative" />
                        <Tab id="computers" label="Computers" />
                        <Tab id="displays" label="Displays" />
                        <Tab id="phones" label="Phones" />
                        <Tab id="cisco" label="Cisco Devices" />
                        <Tab id="tablets" label="Other Devices" />
                    </Tabs>
                </TabBar>
                <div style={{ width: "100%", overflowX: "scroll" }}>
                    <ImageFrameTab onSelect={this.handleSelect} value={value} index={0} category="Decorative" />
                    <ImageFrameTab onSelect={this.handleSelect} value={value} index={1} category="Computers" />
                    <ImageFrameTab onSelect={this.handleSelect} value={value} index={2} category="Displays" />
                    <ImageFrameTab onSelect={this.handleSelect} value={value} index={3} category="Phones" />
                    <ImageFrameTab onSelect={this.handleSelect} value={value} index={4} category="Cisco" />
                    <ImageFrameTab onSelect={this.handleSelect} value={value} index={5} category="Other" />
                </div>
            </FlexBox>
        );
    }
}

const ImageFrameTabContents = styled.div`
  width: 100%;
  height: 160px;
  display: grid;
  gap: 10px;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  align-items: center;
  color: #333;
  padding: 0px 20px;
  
  div {
    padding: 10px 20px;
  }
  
  div:hover {
    background: ${themeColors.rollover};
  }
  
  img {
    height: 80px;
    margin-bottom: 20px;
  } 
`;

class ImageFrameTab extends Component {
    handleSelect = device => {
        let img = $(`#${device.id}`)[0];
        let aspectRatio = img.width / img.height;
        this.props.onSelect(device.id, aspectRatio);
    }

    render() {
        const { value, index, category } = this.props;

        if (value == index) {
            return (
                <ImageFrameTabContents key={category}>
                    {DeviceFrames.filter(d => d.category == category).map(device => (
                        <FlexBox key={device.id} vertical onClick={() => this.handleSelect(device)}>
                            <img id={device.id} src={getStaticUrl("/images/frames/thumbnails/" + device.thumbnail)} />
                            <label>{device.name}</label>
                        </FlexBox>
                    ))}
                </ImageFrameTabContents>
            );
        } else {
            return null;
        }
    }
}

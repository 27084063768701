import React from "react";
import styled from "styled-components";
import { v4 as uuid } from "uuid";

import { AuthoringBlockType, BlockStructureType, ConnectorType, DecorationStyle, HorizontalAlignType, NodeType, TextStyleType, VerticalAlignType } from "common/constants";
import { detectGraphData } from "js/core/services/sharedModelManager";
import * as geom from "js/core/utilities/geom";
import { Shape } from "js/core/utilities/shapes";
import { SVGGroup } from "js/core/utilities/svgHelpers";
import { blendColors } from "js/core/utilities/utilities";
import { tinycolor } from "js/vendor";
import { CollectionElement, CollectionItemElement } from "../../base/CollectionElement";
import { TextElement } from "../../base/Text/TextElement";

import { CycleDiagramItemControlBar, CycleDiagramPropertyPanel } from "../../../Editor/ElementPropertyPanels/CycleDiagramUI";

const Config = [
    { angle: 90, positions: [{ col: 0, y: 0 }, { col: 1, y: 0 }] },
    { angle: 150, positions: [{ col: 0, y: 0 }, { col: 1, y: 0 }, { col: 1, y: .5 }] },
    { angle: -180, positions: [{ col: 0, y: 0 }, { col: 0, y: 0.5 }, { col: 1, y: 0 }, { col: 1, y: 0.5 }] },
    { angle: 198, positions: [{ col: 0, y: 0 }, { col: 0, y: 0.5 }, { col: 1, y: 0 }, { col: 1, y: 0.33 }, { col: 1, y: 0.66 }] },
    { angle: -180, positions: [{ col: 0, y: 0 }, { col: 0, y: 0.33 }, { col: 0, y: .66 }, { col: 1, y: 0 }, { col: 1, y: 0.33 }, { col: 1, y: 0.66 }] }
];

class CycleDiagram extends CollectionElement {
    static get schema() {
        return {
            style: "arrow2",
            innerRadius: 0.5,
            showShadowEffect: true,
            labelType: "number",
            columns: "right"
        };
    }

    get name() {
        return "Cycle Diagram";
    }

    getElementPropertyPanel() {
        return CycleDiagramPropertyPanel;
    }

    getChildItemType(itemModel) {
        return CycleDiagramItem;
    }

    get minItemCount() {
        return 2;
    }

    get maxItemCount() {
        return 6;
    }

    get startAngle() {
        return this.model.startAngle ?? Config[this.itemElements.length - 2].angle;
    }

    get animateRotation() {
        return this.model.animateRotation ?? 0;
    }

    get innerRadius() {
        return this.model.innerRadius;
    }

    get showCenterText() {
        return this.model.showCenterText ?? false;
    }

    get columns() {
        return this.model.columns ?? "split";
    }

    get showShadowEffect() {
        return this.model.showShadowEffect ?? false;
    }

    get labelType() {
        return this.model.labelType ?? "number";
    }

    get decorationStyle() {
        return DecorationStyle.FILLED;
    }

    addItem(props, index) {
        this.model.startAngle = null;
        return super.addItem(props, index);
    }

    deleteItem(itemId) {
        this.model.startAngle = null;
        super.deleteItem(itemId);
    }

    _build() {
        super._build();

        if (this.showCenterText) {
            this.centerText = this.addElement("centerText", () => TextElement, {
                scaleTextToFit: true
            });
        }

        this.labels = [];
        if (this.labelType != "none") {
            for (let i = 0; i < this.itemCount; i++) {
                this.labels.push(this.addElement("labelText" + i, () => TextElement, {
                    canEdit: false,
                    html: this.labelType == "number" ? i + 1 : String.fromCharCode(65 + i),
                }));
            }
        }
    }

    _calcProps(props, options) {
        let { size } = props;

        let config = Config[this.itemElements.length - 2];

        let columnGap = this.styles.columnGap ?? 50;
        let rowGap = this.styles.rowGap ?? 30;

        let columnCount = this.columns == "split" ? 2 : 1;

        const MIN_COLUMN_WIDTH = 300;

        let outerR = Math.min(size.width - columnCount * (MIN_COLUMN_WIDTH + columnGap), size.height) / 2;
        let innerR = outerR * (this.model.innerRadius ?? 0.66);

        if (this.model.style == "arrow2") {
            innerR = Math.max(innerR, 50);
        }

        let centerX = size.width / 2;
        let centerY = size.height / 2;

        let startAngle = this.startAngle;
        let arcLength = 360 / this.itemElements.length;

        let paths = [];
        let shadowPaths = [];
        let centerPoints = [];

        let circleWidth = outerR * 2 + columnGap * columnCount;

        let styleAdj = 1;
        switch (this.model.style) {
            case "arrow1":
                styleAdj = .9;
                break;
            case "segments":
                styleAdj = 0.5;
                break;
            case "puzzle":
                styleAdj = 0.6;
                break;
        }

        let y = 0;
        let actualColumnWidth = 0;
        this.itemElements.forEach((item, index) => {
            if (this.columns == "split") {
                let position = config.positions[index];
                let height = size.height / config.positions.filter(p => p.col == position.col).length;
                let columnWidth = (size.width - circleWidth) / 2;
                let itemProps = item.calcProps(new geom.Size(columnWidth, height), {
                    textAlign: position.col == 0 ? HorizontalAlignType.RIGHT : HorizontalAlignType.LEFT,
                    columns: this.columns
                });
                itemProps.bounds = new geom.Rect(
                    position.col == 0 ? 0 : size.width - columnWidth,
                    size.height * position.y,
                    itemProps.size
                );
            } else {
                let itemProps = item.calcProps(new geom.Size(size.width - outerR * 2 - columnGap, size.height), {
                    textAlign: HorizontalAlignType.LEFT,
                });
                let x = this.columns == "left" ? 0 : outerR * 2 + columnGap;
                itemProps.bounds = new geom.Rect(x, y, itemProps.size);
                y += itemProps.size.height + rowGap;

                actualColumnWidth = Math.max(actualColumnWidth, itemProps.size.width);
            }
        });

        if (this.columns == "right") {
            centerX = size.width / 2 - (actualColumnWidth + columnGap + outerR * 2) / 2 + outerR;
            //vertically center the items
            let totalHeight = y - rowGap;
            for (let item of this.itemElements) {
                item.calculatedProps.bounds.left = centerX + outerR + columnGap;
                item.calculatedProps.bounds.top += (size.height - totalHeight) / 2;
            }
        } else if (this.columns == "left") {
            centerX = size.width / 2 - (actualColumnWidth + outerR * 2) / 2 + actualColumnWidth + outerR;
            let totalHeight = y - rowGap;
            for (let item of this.itemElements) {
                item.calculatedProps.bounds.left = centerX + outerR;
                item.calculatedProps.bounds.top += (size.height - totalHeight) / 2;
            }
        }

        this.itemElements.forEach((item, index) => {
            let arrowArcLength = arcLength * (item.animationState?.growProgress ?? 1);
            if (arrowArcLength == 0) {
                return;
            }

            let itemAngleStart = Math.degreesToRadians(startAngle);
            let itemAngleEnd = Math.degreesToRadians(startAngle + arrowArcLength);
            let outerRadius = Math.min(size.width, size.height) / 2 - (outerR - innerR) / 2;

            let rx = outerRadius;
            let ry = outerRadius;

            paths.push(this.getShapePath({
                style: this.model.style,
                centerX, centerY, rx, ry,
                startAngle: itemAngleStart,
                endAngle: itemAngleEnd,
                width: (outerR - innerR),
                index
            }).path);

            shadowPaths.push(this.getShapePath({
                style: this.model.style,
                centerX, centerY, rx, ry,
                startAngle: itemAngleStart + (itemAngleEnd - itemAngleStart) / 2,
                endAngle: itemAngleEnd,
                width: (outerR - innerR),
                index
            }).path);

            let centerPoint = new geom.Point(centerX, centerY);
            let centerAngle = Math.degreesToRadians(startAngle + arcLength * styleAdj);
            let shapeCenter = centerPoint.offsetAngleRadians(centerAngle, rx, ry);
            centerPoints.push(shapeCenter);

            startAngle += arcLength;
        });

        if (this.showCenterText) {
            let textProps = this.centerText.calcProps(new geom.Size(innerR * 2, innerR * 2));
            textProps.bounds = new geom.Rect(centerX - textProps.size.width / 2, centerY - textProps.size.height / 2, textProps.size);
        }

        if (this.labelType != "none") {
            for (let i = 0; i < this.itemElements.length; i++) {
                if (centerPoints[i]) {
                    let label = this.labels[i];
                    let labelProps = label.calcProps(new geom.Size(50, 50));
                    labelProps.bounds = new geom.Rect(centerPoints[i].x - labelProps.size.width / 2, centerPoints[i].y - labelProps.size.height / 2, labelProps.size);
                }
            }
        }

        return { size, paths, shadowPaths, centerPoints };
    }

    _applyColors() {
        super._applyColors();
        if (this.labelType != "none") {
            this.labels.forEach((label, index) => {
                label.colorSet.decorationColor = this.palette.getColor("white");
            });
        }
    }

    getShapePath({ style, centerX, centerY, rx, ry, startAngle, endAngle, width, index }) {
        let textOffset = 0;
        let path;
        switch (style) {
            case "arrow2":
                path = Shape.drawCurvedArrow(centerX, centerY, rx, ry, startAngle, endAngle, width, 100, width * 1.3, index > 0);
                textOffset = 100;
                break;
            case "segments": {
                path = Shape.drawCurvedSegment(centerX, centerY, rx, ry, startAngle, endAngle, width);
                break;
            }
            case "puzzle":
                path = Shape.drawCurvedPuzzlePiece(centerX, centerY, rx, ry, startAngle, endAngle, width, 30, index > 0);
                textOffset = 30;
                break;
            case "bumper": {
                textOffset = 150;
                path = Shape.drawCurvedBumper(centerX, centerY, rx, ry, startAngle, endAngle, width, Math.PI, index > 0);
                break;
            }
            case "arrow1":
            default:
                path = Shape.drawCurvedArrow(centerX, centerY, rx, ry, startAngle, endAngle, width, 50, width, index > 0);
                textOffset = 100;
                break;
        }

        return { path, textOffset };
    }

    renderChildren(transition) {
        let children = [];

        let { paths, shadowPaths } = this.calculatedProps;

        for (let i = 0; i < paths.length; i++) {
            let item = this.itemElements[i];
            let path = paths[i];
            let shadowPath = (i > 0) ? shadowPaths[i - 1] : shadowPaths[paths.length - 1];

            let slideBackgroundColor = this.canvas.getBackgroundColor();

            let color = item.colorSet.decorationColor.toRgbString();
            let strokeColor = slideBackgroundColor.toRgbString();
            let strokeWidth = this.showShadowEffect ? 0 : 5;

            if (slideBackgroundColor.isColor) {
                color = "white";
                color = blendColors(tinycolor("white").setAlpha(1 - (paths.length - i) * .1), slideBackgroundColor).toRgbString();
            }

            const id = uuid();
            children.push(
                <SVGGroup>
                    <mask id={`${id}-shadowmask${i}`}>
                        <path d={path.toPathData()} fill="white" stroke="white" strokeWidth={strokeWidth} />
                    </mask>
                    <g style={{ mask: `url(#${id}-shadowmask${i}` }}>
                        <path d={path.toPathData()} fill={color} stroke={strokeColor} strokeWidth={strokeWidth} />
                        {(this.showShadowEffect && paths.length > 1) &&
                            <path d={shadowPath.toPathData()} fill="black"
                                style={{ filter: "drop-shadow(0px 0px 15px rgba(0,0,0,.4)" }}
                            />
                        }
                    </g>
                </SVGGroup>
            );
        }

        children.push(...super.renderChildren());
        return children;
    }

    getAnimations() {
        const animations = [];

        this.itemElements.forEach(item => {
            animations.push(...item.getAnimations());
        });
        return animations;
    }

    _exportToSharedModel() {
        const centerTextContent = this.showCenterText ? this.centerText._exportToSharedModel().textContent[0] : null;
        const textContent = this.itemElements.map(
            itemElement => itemElement.text._exportToSharedModel().textContent
        ).flat();

        const compareContent = textContent.map(text => ({ text }));

        const nodes = [centerTextContent, ...textContent].filter(Boolean).map(text => ({
            id: uuid(), type: NodeType.TEXT, textContent: text,
        }));
        const connectors = nodes.slice(1).map(node => ({
            id: uuid(), type: ConnectorType.STRAIGHT, source: nodes[0].id, target: node.id
        }));
        const graphData = [{ nodes, connectors, isHubAndSpoke: !!centerTextContent }];

        return { textContent, compareContent, graphData, collectionColor: this.collectionColor };
    }

    _importFromSharedModel(model) {
        const graphData = detectGraphData(model);
        if (!graphData?.nodes?.length) return;

        const items = graphData.nodes.map(({ textContent }) => ({
            text: {
                blocks: [{
                    html: textContent.mainText.text,
                    type: AuthoringBlockType.TEXT,
                    textStyle: TextStyleType.TITLE,
                }, ...textContent.secondaryTexts.map(secondaryText => ({
                    html: secondaryText.text,
                    type: AuthoringBlockType.TEXT,
                    textStyle: TextStyleType.BODY,
                }))]
            },
        })).slice(0, this.maxItemCount);

        const centerText = graphData.isHubAndSpoke ? items.shift().text : null;

        return { items, centerText, showCenterText: !!centerText, collectionColor: model.collectionColor };
    }
}

class CycleDiagramItem extends CollectionItemElement {
    getElementControlBar() {
        return CycleDiagramItemControlBar;
    }

    get selectionPadding() {
        return { left: 20, right: 20, top: 10, bottom: 10 };
    }

    _build() {
        this.text = this.addElement("text", () => TextElement, {
            blockStructure: BlockStructureType.TITLE_AND_BODY,
            scaleTextToFit: true,
            syncFontSizeWithSiblings: true,
            autoHeight: true,
            verticalAlign: VerticalAlignType.MIDDLE
        });

        if (this.parentElement.labelType != "none") {
            this.number = this.addElement("number", () => TextElement, {
                canEdit: false,
                model: {
                    number: this.parentElement.labelType == "number" ? this.itemIndex + 1 : String.fromCharCode(65 + this.itemIndex),
                    color: this.model.color,
                }
            });
        }
    }

    _calcProps(props, options) {
        let { size } = props;

        let numberProps;
        let numberWidth = 0;

        if (this.parentElement.labelType != "none") {
            numberProps = this.number.calcProps(new geom.Size(50, 50));
            numberWidth = numberProps.size.width;
        }

        let textProps = this.text.calcProps(new geom.Size(size.width - numberWidth, size.height), {
            textAlign: HorizontalAlignType.LEFT,
            autoWidth: options.columns != "split"
        });

        if (options.columns == "split") {
            textProps.bounds = new geom.Rect(numberWidth, size.height / 2 - textProps.size.height / 2, textProps.size);
        } else {
            textProps.bounds = new geom.Rect(numberWidth, numberProps ? (numberProps.bounds.centerV - textProps.blockProps[0].middleOfText / 2) : 0, textProps.size);
        }

        let width = textProps.size.width;
        let height = textProps.size.height;
        if (this.parentElement.labelType != "none") {
            if (options.columns == "split") {
                numberProps.bounds = new geom.Rect(0, textProps.bounds.top + textProps.blockProps[0].middleOfText + textProps.blockProps[0].blockMargin.top - numberProps.size.height / 2, numberProps.size);
                height = size.height;
            } else {
                numberProps.bounds = new geom.Rect(0, 0, numberProps.size);
                height = Math.max(textProps.bounds.bottom, numberProps.bounds.bottom) - Math.min(textProps.bounds.top, numberProps.bounds.top);
            }
            width += numberWidth;
        }

        return { size: new geom.Size(width, height) };
    }

    _getAnimations() {
        return [{
            name: "Grow in",
            easing: "easeOutQuad",
            prepare: () => {
                this.animationState.growProgress = 0;
                this.animationState.fadeInProgress = 0;
            },
            onBeforeAnimationFrame: progress => {
                this.animationState.growProgress = progress;
                this.animationState.fadeInProgress = progress;
                return this.parentElement;
            }
        }];
    }
}

export const elements = {
    CycleDiagram
};

import React, { Component } from "react";
import { ControlBar, ControlBarGroup } from "../ElementControlBars/Components/ControlBar";
import { ItemColorPicker } from "../EditorComponents/ColorPickers/ItemColorPicker";
import { PropertyPanelContainer, PropertySection } from "../../../../EditorComponents/PropertyPanel";
import { WithLabel } from "../../../../Components/WithLabel";
import { CollectionColorPicker } from "../EditorComponents/ColorPickers/CollectionColorPicker";
import { BaseElementSelection } from "../ElementSelections/BaseElementSelection";

export class SwotDiagramPropertyPanel extends Component {
    render() {
        const { element } = this.props;

        return (
            <PropertyPanelContainer>
                <PropertySection>
                    <WithLabel label="Colors">
                        <CollectionColorPicker element={element} />
                    </WithLabel>
                </PropertySection>
            </PropertyPanelContainer>
        );
    }
}

export class SwotDiagramItemControlBar extends Component {
    render() {
        const { element } = this.props;
        return (
            <ControlBar>
                <ItemColorPicker element={element} />
            </ControlBar>
        );
    }
}

export class SwotDiagramItemSelection extends BaseElementSelection {
}

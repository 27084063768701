import React from "react";
import styled from "styled-components";

import { DragHandle, defaultDragPositionProps, defaultDragResizeProps, ResizeHandle } from "../../../../editor/PresentationEditor/DragElementManager";
import { WidgetButtonPosition } from "../../../../Components/WidgetButton";

import ElementSelectionBox from "./Components/ElementSelectionBox";
import DeleteElementButton from "./Components/DeleteElementButton";
import { ToolTip } from "../../../../Components/ToolTip";

export const SelectionBorder = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: 1px dotted #50bbe6;
`;

export const SVGPathSelection = styled.svg`
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    stroke: #50bbe6;
    position: absolute;
    overflow: visible;
    stroke-width: 1px;
    stroke-dasharray: 1 1;
    fill: transparent;
`;

export class BaseElementSelection extends React.Component {
    constructor(props) {
        super(props);

        this.containerRef = React.createRef();
    }

    get canDelete() {
        return false;
    }

    get canDrag() {
        return false;
    }

    get canResize() {
        return false;
    }

    get deleteButtonPosition() {
        return WidgetButtonPosition.CORNER;
    }

    get dragButtonPosition() {
        return WidgetButtonPosition.CORNER;
    }

    get dragPositionProps() {
        return {
            ...defaultDragPositionProps,
        };
    }

    get dragResizeProps() {
        return {
            ...defaultDragResizeProps
        };
    }

    get showSelectionBorder() {
        return true;
    }

    get containerRenderBounds() {
        return null;
    }

    handleDeleteElement = () => {
    }

    handleResizeHandleMouseDown = (event, resizeDirection) => {
        const { element, selectionLayerController } = this.props;
        selectionLayerController.registerElementDrag([element], { ...this.dragResizeProps, resizeDirection });
    }

    handleDragHandleMouseDown = event => {
        const { element, selectionLayerController } = this.props;
        selectionLayerController.registerElementDrag([element], this.dragPositionProps);
    };

    renderCustomChildren() {
        return null;
    }

    getResizeHandleOffset(resizeDirection) {
        return null;
    }

    render() {
        const { isBeingDragged, isBeingResized } = this.props;

        return (
            <ElementSelectionBox ref={this.containerRef} bounds={this.containerRenderBounds}>
                {this.showSelectionBorder &&
                    <SelectionBorder />
                }

                {this.renderCustomChildren()}

                {this.canDelete && !isBeingDragged && !isBeingResized &&
                    <DeleteElementButton
                        onClick={this.handleDeleteElement}
                        position={this.deleteButtonPosition}
                    />
                }

                {this.canDrag && !isBeingResized &&
                    <DragHandle
                        simple
                        position={this.dragButtonPosition}
                        onMouseDown={this.handleDragHandleMouseDown}
                    />
                }

                {this.canResize && !isBeingDragged && this.dragResizeProps.resizeDirections.map(resizeDirection => (
                    <ResizeHandle
                        resizeDirection={resizeDirection}
                        key={resizeDirection}
                        onMouseDown={event => this.handleResizeHandleMouseDown(event, resizeDirection)}
                        offset={this.getResizeHandleOffset(resizeDirection)}
                        {...(this.dragResizeProps.handleProps ?? {})}
                    />
                ))}
            </ElementSelectionBox>
        );
    }
}

import React from "react";
import styled from "styled-components";

import { ToggleSwitch } from "../../../../Components/ToggleSwitch";
import { WithLabel } from "../../../../Components/WithLabel";
import * as geom from "../../../../core/utilities/geom";
import { Shape } from "../../../../core/utilities/shapes";

import { IconButton, RoundIconButton } from "../../../../Components/IconButton";
import { Popup, PopupContainer, PopupContent, PopupPreview } from "../../../../Components/Popup";
import { AbsoluteBox } from "../../../../react/components/LayoutGrid";
import ElementSelectionBox from "./Components/ElementSelectionBox";
import { ColorPicker } from "../EditorComponents/ColorPickers/ColorPicker";
import { PropertyPanelContainer, PropertySection } from "../../../../EditorComponents/PropertyPanel";

const WedgeFrame = styled.svg.attrs(({ stroke, strokeWidth }) => ({
    style: {
        stroke,
        strokeWidth: `${strokeWidth}px`
    }
}))`
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    overflow: visible;

    fill: transparent;
`;

export const PieChartWedgeSelection = React.forwardRef(function PieChartWedgeSelection({ element }, ref) {
    const frameInset = 1;
    const scale = element.canvas.getScale();
    const {
        startAngle,
        endAngle,
        innerRadius,
        outerRadius
    } = element.calculatedProps;
    const center = new geom.Point(element.calculatedProps.allowedSize.width / 2 + element.selectionPadding, element.calculatedProps.allowedSize.height / 2 + element.selectionPadding);
    const framePath = Shape.drawWedge(center.x * scale - frameInset, center.y * scale - frameInset, innerRadius * scale, outerRadius * scale, startAngle, endAngle).toPathData();
    const framePathStyle = {
        stroke: element.shape.svgStyles.stroke,
        strokeWidth: element.shape.svgStyles.strokeWidth + frameInset * 2
    };

    const midAngle = (endAngle - startAngle) / 2 + startAngle;
    const radius = outerRadius * scale;
    const buttonPosition = new geom.Point(center.x * scale + Math.cos(midAngle) * radius, center.y * scale + Math.sin(midAngle) * radius);

    const pieChart = element.findClosestOfType("PieChart");

    return (
        <ElementSelectionBox ref={ref}>
            <WedgeFrame {...framePathStyle}>
                <path d={framePath} />
            </WedgeFrame>
            <AbsoluteBox left={buttonPosition.x} top={buttonPosition.y} centerTransform>
                <Popup>
                    <PopupPreview>
                        <RoundIconButton icon="gps_fixed" size={22} />
                    </PopupPreview>
                    <PopupContent>
                        <PropertyPanelContainer>
                            <PropertySection>
                                <WithLabel label="Emphasized">
                                    <ToggleSwitch
                                        value={element.model.emphasized}
                                        onChange={value => element.updateModel({ emphasized: value })}
                                    />
                                </WithLabel>
                                <WithLabel label="Color">
                                    <ColorPicker
                                        value={element.colorSet.decorationColor.toRgbString()}
                                        canvas={element.canvas}
                                        onChange={value => element.updateModel({ color: value }, { refreshStyles: true })}
                                        showColorPicker showThemeColors={false} showChartColors showPositiveNegative defaultColor={pieChart.collectionColor}
                                    />
                                </WithLabel>
                            </PropertySection>
                        </PropertyPanelContainer>
                    </PopupContent>
                </Popup>
            </AbsoluteBox>
        </ElementSelectionBox>
    );
});

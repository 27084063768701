import React, { Component } from "react";
import { ControlBar } from "../ElementControlBars/Components/ControlBar";
import { Popup, PopupContent } from "../../../../Components/Popup";
import AddCalloutPanel from "../../../../editor/PresentationEditor/Components/AddCalloutPanel";
import { CollectionItemElementSelection } from "../ElementSelections/CollectionItemElementSelection";
import { defaultDragPositionProps } from "../../../../editor/PresentationEditor/DragElementManager";
import { AuthoringCanvasSelection } from "../ElementSelections/AuthoringCanvasSelection";
import { PropertyPanelContainer, PropertySection } from "../../../../EditorComponents/PropertyPanel";
import { ConnectorPropertyPanel } from "../EditorComponents/ConnectorPropertyPanel";
import { WithLabel } from "../../../../Components/WithLabel";
import { ToggleSwitch } from "../../../../Components/ToggleSwitch";
import { Button } from "../../../../Components/Button";
import { Icon } from "../../../../Components/Icon";
import { CollectionColorPicker } from "../EditorComponents/ColorPickers/CollectionColorPicker";

export class FlowChartPropertyPanel extends Component {
    render() {
        const { element, canvas } = this.props;

        return (
            <PropertyPanelContainer>
                <PropertySection>
                    <WithLabel label="Node Colors">
                        <CollectionColorPicker element={element}
                            showDecorationStyles
                            onChangeDecorationStyle={decorationStyle => {
                                for (let item of element.itemElements) {
                                    item.model.decorationStyle = decorationStyle;
                                }
                                element.saveModel();
                            }} />
                    </WithLabel>
                </PropertySection>
                <ConnectorPropertyPanel canvas={canvas} elements={element.connectors?.itemElements ?? []} />
                <PropertySection>
                    <WithLabel label="Snap while dragging">
                        <ToggleSwitch value={element.showSnapLines}
                            onChange={value => element.updateModel({ showSnapLines: value })} />
                    </WithLabel>
                    <WithLabel label="Show grid">
                        <ToggleSwitch value={element.showGrid}
                            onChange={value => element.updateModel({ showGrid: value })} />
                    </WithLabel>
                    <WithLabel label="Snap to grid">
                        <ToggleSwitch value={element.snapToGrid}
                            onChange={value => element.updateModel({ snapToGrid: value })} />
                    </WithLabel>
                </PropertySection>
                <PropertySection>
                    <Button small onClick={async () => {
                        element.centerLayout();
                        element.updateModel();
                    }}>
                        <Icon>center_focus_strong</Icon>Center Items
                    </Button>
                </PropertySection>
            </PropertyPanelContainer>
        );
    }
}

export class FlowChartSelection extends AuthoringCanvasSelection {
    get authoringLayerProps() {
        return {
            minimizedControlBar: true
        };
    }
}

export class FlowChartControlBar extends Component {
    render() {
        const { element, position, ...props } = this.props;

        return (
            <ControlBar position={position}>
                <Popup icon="add_box" label="Add Node">
                    <PopupContent>
                        {closePopup => (
                            <AddCalloutPanel
                                {...props}
                                horizontal
                                onClose={() => closePopup()}
                                containerElement={element}
                            />
                        )}
                    </PopupContent>
                </Popup>
            </ControlBar>
        );
    }
}

export class FlowChartItemSelection extends CollectionItemElementSelection {
    get canDrag() {
        return true;
    }

    get dragPositionProps() {
        const { element } = this.props;

        let dragStartProps;
        return {
            ...defaultDragPositionProps,
            dragOpacity: 0,
            dropTargets: [],
            dragAxes: ["x", "y"],
            drop: () => {
            },
            onDragStart: async () => {
                dragStartProps = { x: element.model.x, y: element.model.y, parentSize: element.parentElement.calculatedProps.size.clone() };
            },
            onDrag: async ({ dragOffset }) => {
                const { x, y, parentSize } = dragStartProps;

                const horizontalShift = dragOffset.x / parentSize.width;
                const verticalShift = dragOffset.y / parentSize.height;

                element.model.x = Math.clamp(x + horizontalShift, 0, 1);
                element.model.y = Math.clamp(y + verticalShift, 0, 1);

                element.parentElement.refreshElement();
            }
        };
    }
}

import React from "react";
import moment from "moment";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsMore from "highcharts/highcharts-more";

import {
    BackgroundStyleType,
    CHART_DATE_FORMATS,
    ConnectorType,
    FormatType,
    PaletteColorType,
    PositionType
} from "common/constants";
import getLogger, { LogGroup } from "../../../../../core/logger/index";
import { detectTabularData } from "../../../../../core/services/sharedModelManager";
import { formatter } from "../../../../../core/utilities/formatter";
import * as geom from "../../../../../core/utilities/geom";
import { getValidChartDataFromCsv } from "../../../../../core/utilities/xlsx";
import { $, _, tinycolor } from "../../../../../vendor";

import { ChartSelection, ChartPropertyPanel } from "../../../Editor/ElementPropertyPanels/ChartEditor/ChartUI";
import { EditChartDataPanel } from "../../../Editor/ElementPropertyPanels/ChartEditor/EditChartDataPanel";
import { BaseElement } from "../../base/BaseElement";
import { ChartAnnotations } from "./ChartAnnotations";
import { normalizeMonthAbbreviation } from "js/core/utilities/date";

const logger = getLogger(LogGroup.ELEMENTS);

highchartsMore(Highcharts);

class Chart extends BaseElement {
    getElementPropertyPanel() {
        return ChartPropertyPanel;
    }

    getElementSelection() {
        return ChartSelection;
    }

    get selectionPadding() {
        return 0;
    }

    getElementPanel() {
        return EditChartDataPanel;
    }

    get name() {
        let seriesType = _.uniq(this.model.chartData.series.map(s => s.type));
        if (seriesType.length === 1) {
            return seriesType[0] + " Chart";
        } else {
            return "Mixed Chart";
        }
    }

    get _canSelect() {
        return true;
    }

    get _canRollover() {
        return false;
    }

    get chartModel() {
        return this.model.chartData;
    }

    get series() {
        return this.chartModel.series;
    }

    get isHorizontalChart() {
        return this.series.some(s => s.type === "bar");
    }

    get canRefreshElement() {
        return true;
    }

    get isWaterfall() {
        return this.getChartType() === "waterfall";
    }

    constructor(props) {
        super(props);

        this.chartRef = React.createRef();
    }

    refreshElement(transition) {
        this.canvas.refreshElement(this, transition);
    }

    getAnchorPointType() {
        if (this.model.chartData.series.some(s => s.type == "bar")) {
            return geom.AnchorType.RIGHT;
        } else {
            return geom.AnchorType.TOP;
        }
    }

    getAnchorPoint(connector, anchor, connectorPoint, connectorType, isSource) {
        const snapOptions = isSource ? connector.sourceSnapOptions : connector.targetSnapOptions;
        if (!snapOptions) {
            return super.getAnchorPoint(connector, anchor);
        }

        if (snapOptions.axis === "yAxis") {
            return new geom.Point(this.chartModel.yAxis.opposite ? this.chart.plotBox.width : 0, connectorPoint.y);
        }

        if (snapOptions.axis === "xAxis") {
            return new geom.Point(connectorPoint.x, this.chart.plotBox.height);
        }

        if (snapOptions.pointIndex != null && snapOptions.seriesId) {
            const pointLocation = this.getPointLocation(snapOptions.pointIndex, snapOptions.seriesId);
            return pointLocation.offset(snapOptions.left || 0, snapOptions.top || 0);
        }
    }

    getAnchorBounds(connector, anchor, connectorPoint, isSource) {
        const snapOptions = isSource ? connector.sourceSnapOptions : connector.targetSnapOptions;
        if (!snapOptions) {
            return super.anchorBounds;
        }

        let point;
        if (snapOptions.axis === "yAxis") {
            point = new geom.Point(0, connectorPoint.y);
        }

        if (snapOptions.axis === "xAxis") {
            point = new geom.Point(connectorPoint.x, this.chart.plotBox.height);
        }

        if (snapOptions.pointIndex != null && snapOptions.seriesId) {
            point = this.getPointLocation(snapOptions.pointIndex, snapOptions.seriesId);
        }

        return new geom.Rect(point.x, point.y - 100, 0, 100);
    }

    getPointLocation(pointIndex, seriesId) {
        if (!this.chart) return new geom.Point(0, 0);
        // Looking up a highcharts point
        const series = this.chart.series.find(series => series.userOptions.id === seriesId);
        const point = series.points[pointIndex];
        const pointLocation = new geom.Point(point.plotX, point.plotY);
        // If the chart is a bar chart, then considering the bar's width and shift
        if (series.userOptions.type === "column") {
            pointLocation.x = point.barX + point.pointWidth / 2;
        } else if (series.userOptions.type === "bar") {
            pointLocation.x = this.chart.plotBox.width - point.shapeArgs.y;// - point.shapeArgs.height;
            pointLocation.y = this.chart.plotBox.height - point.shapeArgs.x - point.shapeArgs.width / 2;
        }
        return pointLocation;
    }

    getDynamicValue(dataSource) {
        const { pointIndex, pointAIndex, pointBIndex, seriesId, isDiff, changeType } = dataSource;

        // Arbitrary text, won't return anything
        if (changeType === "text") {
            return null;
        }

        if (!this.chartConfig) {
            // Hasn't rendered yet
            return "--";
        }

        // Looking up a point in the model
        const series = this.model.chartData.series.find(series => series.id === seriesId);
        if (!series) {
            return "--";
        }

        const yAxisConfig = this.chartConfig.yAxis[series.yAxis || 0];

        if (!isDiff) {
            const pointValue = series.data[pointIndex]?.y;
            if (!pointValue && pointValue !== 0) {
                return "--";
            }
            if (!yAxisConfig.format) {
                return pointValue.toString();
            }
            let formattedValue = formatter.scaleValue(pointValue, yAxisConfig.labelFormat);
            formattedValue = formatter.formatValue(formattedValue, yAxisConfig.format, yAxisConfig.formatOptions);
            formattedValue = `${yAxisConfig.prefix || ""}${formattedValue}${yAxisConfig.suffix || ""}`;
            return formattedValue;
        }

        let pointAValue;
        let pointBValue;
        if (pointAIndex < pointBIndex) {
            pointAValue = series.data[pointAIndex].y;
            pointBValue = series.data[pointBIndex].y;
        } else {
            pointAValue = series.data[pointBIndex].y;
            pointBValue = series.data[pointAIndex].y;
        }

        switch (changeType) {
            case "absolute":
                const diff = pointBValue - pointAValue;
                let formattedValue = formatter.formatValue(formatter.scaleValue(diff, yAxisConfig.labelFormat), yAxisConfig.format, yAxisConfig.formatOptions);
                formattedValue = `${yAxisConfig.prefix || ""}${formattedValue}${yAxisConfig.suffix || ""}`;
                return `${diff > 0 ? "+" : ""}${formattedValue}`;
            case "cagr":
                const cagr = Math.round((Math.pow(pointBValue / pointAValue, 1 / Math.abs(pointBIndex - pointAIndex)) - 1) * 100);
                return `CAGR ${cagr}%`;
            case "multiple":
                return parseFloat((pointBValue / pointAValue).toFixed(2)) + "x";
            case "percent":
            default:
                const diffPercents = Math.round(((pointBValue - pointAValue) / pointAValue) * 100);
                return `${diffPercents > 0 ? "+" : ""}${diffPercents}%`;
        }
    }

    getSnapPoint(element) {
        if (!this.chart) {
            return this.bounds.position.offset(this.bounds.center);
        }

        const { pointIndex, seriesId } = element.model.snapOptions;
        let pointLocation = this.getPointLocation(pointIndex, seriesId);
        if (element.model.annotationType === "DataNote") {
            const elementHeight = element.calculatedProps.size.height;
            pointLocation = pointLocation.offset(0, -elementHeight / 2 - 20);
        }
        return pointLocation;
    }

    updateChartData(chartData, initialImport = false) {
        const newSeriesType = this.model.chartData.series[0].type || "line";
        const defaultChartData = this.canvas.chartUtils.getDefaultChartModel(newSeriesType).chartData;
        const elementColors = Object.keys(this.canvas.getTheme().palette.colors).filter(colorName => colorName.startsWith("chart"));
        const chartModel = _.cloneDeep(this.model.chartData);
        if (chartData.categories?.length) {
            chartModel.xAxis.categories = chartData.categories;
        }
        if (chartData.series?.length) {
            chartData.series = chartData.series.filter(({ data }) => data.some(y => y != null));
            const newSeriesLength = chartData.series[0]?.data.length || 0;
            chartModel.series = chartModel.series.map(series => ({
                ...series, data: series.data.length < newSeriesLength
                    ? [...series.data, ...Array(newSeriesLength - series.data.length).fill({ y: 0, pt: true })]
                    : series.data
            }));
            const newSeries = chartData.series.map((newSeries, i) => ({
                ...defaultChartData.series[0],
                id: `series-${i}`,
                colorName: elementColors[Object.keys(elementColors)[i % Object.keys(elementColors).length]],
                ...(chartModel.series?.[i] || {}),
                ...newSeries,
                type: newSeriesType,
                data: (newSeries.data.length < chartModel.xAxis.categories.length
                    ? [...newSeries.data, ...Array(chartModel.xAxis.categories.length - newSeries.data.length).fill(0)]
                    : newSeries.data
                ).map(y => ({ y, pt: true })),
            }));

            if (chartData.appendSeries) {
                chartModel.series.push(...newSeries);
            } else {
                chartModel.series = newSeries;
            }
        }
        if (chartModel.xAxis.categories.length < chartModel.series[0].data.length) {
            chartModel.xAxis.categories = [
                ...chartModel.xAxis.categories,
                ...Array(chartModel.series[0].data.length - chartModel.xAxis.categories.length).fill(defaultChartData)
                    .map((_c, i) => `Category ${i + 1 + chartModel.xAxis.categories.length}`)
            ];
        }

        this.model.chartData = chartModel;
        if (initialImport) {
            if (chartData.yAxisFormat) {
                this.model.chartData.yAxis.format = chartData.yAxisFormat;
                this.model.chartData.yAxis2.format = chartData.yAxisFormat;
            }
            if (chartData.categories?.length) {
                this.model.chartData.xAxis.dateFormatting = "none";
            }
            this.model.chartAnnotations.items = [];
            this.model.chartAnnotations.connections.items = [];
        }

        return this.model;
    }

    _build() {
        if (!this.model.chartData) {
            Object.assign(this.model, this.canvas.chartUtils.getDefaultChartModel("line"), this.model);
        }

        this.model.chartData.series.find(srs => {
            if (!srs.data) {
                const seriesWithData = this.model.chartData.series.find(srs => !!srs.data)?.data || [""];
                srs.data = seriesWithData.map(() => ({
                    y: null
                }));
            }
        });

        // trim series data to not exceed the number of categories
        if (this.model.chartData.xAxis.categories?.length) {
            this.model.chartData.series.forEach(series => {
                series.data = series.data.slice(0, this.model.chartData.xAxis.categories.length);
            });
        } else {
            this.model.chartData.xAxis.categories = [""];
        }

        // migration to reset axis.visible
        if (this.model.chartData.xAxis.visible == false) {
            this.model.chartData.xAxis.visible = true;
            this.model.chartData.xAxis.showGridLines = false;
            this.model.chartData.xAxis.showMajorTicks = false;
            this.model.chartData.xAxis.axisTitle = "none";
            this.model.chartData.xAxis.labels = { enabled: false };
            this.model.chartData.xAxis.showAxisLine = false;
        }
        if (this.model.chartData.yAxis.visible == false) {
            this.model.chartData.yAxis.visible = true;
            this.model.chartData.yAxis.showGridLines = false;
            this.model.chartData.yAxis.showMajorTicks = false;
            this.model.chartData.yAxis.axisTitle = "none";
            this.model.chartData.yAxis.labels = { enabled: false };
            this.model.chartData.yAxis.showAxisLine = false;
        }

        // Migrating to the new model property if the slide has already been migrated to v8
        // by an older version of the app
        if (this.model.annotations && !Array.isArray(this.model.annotations)) {
            logger.warn("[Chart] migrating chart annotations from model.annotations to model.chartAnnotations");
            this.model.chartAnnotations = this.model.annotations;
            delete this.model.annotations;
        }

        if (!this.model.chartAnnotations) {
            this.model.chartAnnotations = { items: [], connections: { items: [] } };
        } else {
            // Ensure items arrays are always present
            _.defaultsDeep(this.model.chartAnnotations, { items: [], connections: { items: [] } });
        }

        // Fixing elementId of annotations and connectors //
        const fixElementId = model => {
            if (model?.elementId && model.elementId !== this.uniquePath) {
                model.elementId = this.uniquePath;
                return true;
            }
            return false;
        };

        // if a point is removed from the chart, we need to remove the annotation that is linked to it
        this.model.chartAnnotations.items = this.model.chartAnnotations.items.filter(item => {
            // if it is not a highlight node annotation, we don't need to check if the point exists
            if (!item.dataSource?.seriesId) {
                return true;
            }

            const dataItem = this.chartModel.series.find(series => series.id === item.dataSource.seriesId).data[item.dataSource.pointIndex];

            if (_.isEmpty(dataItem)) return false;

            if (dataItem.y === null) return false;

            return true;
        });

        this.model.chartAnnotations.items.forEach(item => {
            fixElementId(item.dataSource);
            fixElementId(item.snapOptions);
        });
        this.model.chartAnnotations.connections.items.forEach(item => {
            if (fixElementId(item.sourceSnapOptions)) {
                item.source = this.uniquePath;
            }
            if (fixElementId(item.targetSnapOptions)) {
                item.target = this.uniquePath;
            }
            if (item.labels) {
                item.labels.forEach(label => fixElementId(label.dataSource));
            }
        });
        //////////////////////////////////////////////////////////

        const nodeIds = this.model.chartAnnotations.items.map(({ id }) => id);
        this.model.chartAnnotations.connections.items.forEach(connection => {
            // We are removing in order to recalculate the anchor offsets
            delete connection["startAnchorOffsetY"];
            delete connection["endAnchorOffsetY"];
            delete connection["startAnchorOffsetX"];
            delete connection["endAnchorOffsetX"];
            // We have several types of connectors, but we don't take it into account
            // The STRAIGHT type can be used for both horizontal and vertical charts, but also for adding notes
            // if we have a note on the same slide as the chart, we don't want to offset the anchors
            if (connection.connectorType !== ConnectorType.STRAIGHT || nodeIds.contains(connection.source)) {
                connection.startAnchorOffsetY = 0;
                connection.endAnchorOffsetY = 0;
            } else {
                if (this.isHorizontalChart) {
                    connection.startAnchorOffsetX = 10;
                    connection.endAnchorOffsetX = 10;
                } else {
                    connection.startAnchorOffsetY = -70;
                    connection.endAnchorOffsetY = -70;
                }
            }
        });

        this.annotations = this.addElement("annotations", () => ChartAnnotations, { model: this.model.chartAnnotations });
    }

    get minWidth() {
        return 100;
    }

    get minHeight() {
        return 100;
    }

    _calcProps(props, options) {
        let { size } = props;

        const series = this.getSeriesData(this.chartModel);

        if (size.width < 640 || size.height < 300) {
            this.updateStyles(this.styles.smallChart);
        }

        if (series.some(s => s.type == "bar")) {
            this.updateStyles(this.styles.horizontalChart);
        }

        // this is a special case where we need to do this during calcProps
        // because the styles are used to generate the chartConfig
        this.styles.applyDecorationStyles(this);

        for (const seriesElement of series) {
            if (typeof seriesElement.dataLabels.style.fontSize !== "string") {
                seriesElement.dataLabels.style.fontSize += "px";
            }
        }

        const xAxis = this.getAxisProperties("x", this.chartModel.xAxis, this.styles.xAxis || {}, PositionType.BOTTOM, size.height);
        const yAxis = [
            this.getAxisProperties("y", this.chartModel.yAxis, this.styles.yAxis || {}, this.chartModel.yAxis.opposite ? PositionType.RIGHT : PositionType.LEFT, size.width),
            this.getAxisProperties("y2", this.chartModel.yAxis2, this.styles.yAxis || {}, PositionType.RIGHT, size.width)
        ];

        const legend = this.getLegendProperties();
        if (typeof this.styles.series.dataLabels.style.fontSize !== "string") {
            this.styles.series.dataLabels.style.fontSize += "px";
        }
        if (typeof this.styles.legend.itemStyle.fontSize !== "string") {
            this.styles.legend.itemStyle.fontSize += "px";
        }
        const plotOptions = _.merge(this.chartModel.plotOptions, this.styles.series);

        let spacingTop = 20;
        if (!this.isHorizontalChart && (this.chartModel.yAxis.axisTitle == "top" || this.chartModel.yAxis2.axisTitle == "top")) {
            spacingTop = 50;
        }

        this.chartConfig = {
            chart: {
                style: {
                    position: "static"
                },
                backgroundColor: "none",
                spacingRight: 20,
                spacingTop: spacingTop,
                spacingLeft: 10,
                spacingBottom: 0,
                ignoreHiddenSeries: false,
                animation: false
            },
            legend,
            title: { text: "" },
            credits: { enabled: false },
            tooltip: { enabled: false },
            series,
            xAxis,
            yAxis,
            plotOptions
        };

        if (this.isTryingLayout) {
            return { size };
        }

        const calcAnnotations = () => {
            const plotBox = this.chart.plotBox;
            const annotationsSize = new geom.Size(plotBox.width, plotBox.height);

            const annotationProps = this.annotations.calcProps(annotationsSize);
            annotationProps.bounds = new geom.Rect(plotBox.x, plotBox.y, annotationsSize.width, annotationsSize.height);
        };

        if (
            this.hasStoredPropChanged("chartConfig", this.chartConfig, true) ||
            this.hasStoredPropChanged("size", size.clone(), true) ||
            !this.chart
        ) {
            // This is a trick we have to run in order to make sure the selection layer is only refrehsed
            // after the annotations are calculated
            this.selectionLayerController?.setFreezeSelectionLayer(true);

            // We have to calc the annotations after rendering the chart so it can use the highcharts api
            // to retrieve the actual points' coordinates
            this.canvas.layouter.runPostRender(() => {
                if (!this.chart) {
                    this.selectionLayerController?.setFreezeSelectionLayer(false);
                    return;
                }

                calcAnnotations();

                this.annotations.applyColors();
                this.annotations.refreshRender(false);

                this.selectionLayerController?.setFreezeSelectionLayer(false);
            });
        } else {
            calcAnnotations();
        }

        return { size };
    }

    getBackgroundColor() {
        // have to override because chart applies colors during calcProps which is incompatible with the new implementation
        if (this.parentElement.model.cellColor === "none") {
            return this.canvasElement.background.canvasBackgroundColor;
        } else {
            return this.palette.getColor(this.parentElement.model.cellColor) ?? this.canvasElement.background.canvasBackgroundColor;
        }
    }

    get chart() {
        return this.chartRef && this.chartRef.current ? this.chartRef.current.chart : null;
    }

    renderChildren(transition) {
        let props = this.calculatedProps;
        if (props.innerSize.width !== this.chartConfig.chart.width) {
            this.chartConfig.chart.width = props.innerSize.width;
        }
        if (props.innerSize.height !== this.chartConfig.chart.height) {
            this.chartConfig.chart.height = props.innerSize.height;
        }

        const children = super.renderChildren(transition);
        children.insert(
            <HighchartsReact
                key={"highcharts-react"}
                ref={this.chartRef}
                highcharts={Highcharts}
                options={this.chartConfig}
                allowChartUpdate={!this.isAnimating}
                updateArgs={[true, true, false]}
                containerProps={{
                    className: "highcharts-react-container",
                    style: { left: this.styles.paddingLeft, top: this.styles.paddingTop }
                }}
            />,
            0,
        );

        this.canvas.layouter.runPostRender(() => {
            if (!this.chart) return;

            if (!this.isDeleted) {
                for (const series of this.chart.series) {
                    // special case shadow effect to apply filter attribute to series
                    if (this.styles.series.shadow && this.styles.series.shadow !== "none") {
                        series.group.element.setAttribute("filter", `url(#${this.styles.series.shadow})`);
                    }

                    // shift datalabels above too small waterfall bars
                    if (this.getChartType() === "waterfall") {
                        for (const data of series.data) {
                            if (data.dataLabel) {
                                if (data.shapeArgs.height < 40) {
                                    data.dataLabel.element.setAttribute("transform", `translate(${data.dataLabel.x}, ${data.dataLabel.y - data.shapeArgs.height / 2 - 18})`);
                                    const labelColor = this.palette.getColor("primary", this.getBackgroundColor());
                                    data.dataLabel.text.element.style.fill = labelColor.toRgbString();
                                }
                            }
                        }
                    }
                }
            }
        });

        return children;
    }

    getSeriesData(chartModel) {
        return chartModel.series.map(series => {
            const seriesCopy = _.cloneDeep(series);
            seriesCopy.index = chartModel.series.indexOf(series);
            seriesCopy.zIndex = seriesCopy.index;
            seriesCopy.animation = false;

            if (this.isWaterfall) {
                seriesCopy.pointPadding = 0;
                seriesCopy.data = seriesCopy.data.map(point => {
                    if (_.isString(point.y)) {
                        return {
                            isSum: true
                        };
                    } else {
                        //isSum needs to be set to false so that the highcharts lib can handle the change of a "sum" point to number value
                        point.isSum = false;
                        if (point.y == undefined) {
                            point.y = null;
                        }
                        return point;
                    }
                });
            }

            seriesCopy.pointStart = 0;
            seriesCopy.enableMouseTracking = false;

            seriesCopy.pointPadding = chartModel.columnPadding ?? seriesCopy.pointPadding ?? 0.1;
            seriesCopy.groupPadding = chartModel.groupPadding ?? seriesCopy.groupPadding ?? 0.1;

            // make sure yAxis2 is visible if this series uses it
            if (seriesCopy.yAxis === 1) {
                chartModel.yAxis2.visible = true;
            } else if (!chartModel.series.find(series => series.yAxis === 1)) {
                chartModel.yAxis2.visible = false;
            }

            let seriesProps = this.getSeriesProps(seriesCopy);
            let seriesStyles = this.styles.series[seriesCopy.type] || {};

            if (!this.isWaterfall && seriesCopy.zones && seriesCopy.zones.length > 0) {
                for (var zone of seriesCopy.zones) {
                    switch (zone.style) {
                        case "default":
                            zone.color = this.getSeriesColorFromStyle(series, _.get(seriesStyles, "lineColor", "seriesColor"));
                            zone.fillColor = this.getSeriesColorFromStyle(series, _.get(seriesStyles, "fillColor", "seriesColor"), _.get(seriesStyles, "fillOpacity", 1));
                            zone.dashStyle = _.get(seriesStyles, "dashStyle", "Solid");
                            break;
                        case "emphasized":
                            zone.color = this.getSeriesColorFromStyle(series, _.get(seriesStyles, "emphasis.lineColor", "seriesColor"));
                            zone.fillColor = this.getSeriesColorFromStyle(series, _.get(seriesStyles, "emphasis.fillColor", "seriesColor"), _.get(seriesStyles, "emphasis.fillOpacity", 1));
                            zone.dashStyle = _.get(seriesStyles, "emphasis.dashStyle", "Solid");
                            break;
                        case "projection":
                            zone.color = this.getSeriesColorFromStyle(series, _.get(seriesStyles, "projection.lineColor", "seriesColor"), _.get(seriesStyles, "projection.lineOpacity", 1));
                            zone.fillColor = this.getSeriesColorFromStyle(series, _.get(seriesStyles, "projection.fillColor", "seriesColor"), _.get(seriesStyles, "projection.fillOpacity", 1));
                            zone.dashStyle = _.get(seriesStyles, "projection.dashStyle", "ShortDash");
                            break;
                    }
                }
            }

            // map column colors
            if (seriesCopy.type === "column" || seriesCopy.type === "bar") {
                //If a point is null because a user removed it, convert it to an actual point so that
                //data that succeeds it does not take its place in a graph.
                if (seriesCopy.data.contains(null)) {
                    seriesCopy.data = seriesCopy.data.map(point => {
                        if (point == null) {
                            return { y: null, pt: true };
                        }
                        return point;
                    });
                }

                seriesCopy.data = seriesCopy.data.filter(pt => pt);
                for (let pt of seriesCopy.data) {
                    if (pt.color) {
                        const color = this.canvas.getTheme().palette.getColor(pt.color);
                        let ptZone;
                        if (seriesCopy.zones) {
                            let zoneStart = 0;
                            let ptIndex = seriesCopy.data.indexOf(pt);
                            for (let zone of seriesCopy.zones) {
                                if (ptIndex >= zoneStart && (ptIndex < zone.value || zone.value == undefined)) {
                                    if (zone.style == "projection") {
                                        color.setAlpha(_.get(seriesStyles, "projection.fillOpacity"));
                                    } else {
                                        color.setAlpha(1);
                                    }
                                }
                                zoneStart = zone.value;
                            }
                        }

                        pt.color = color.toRgbString();
                    }
                }
            }

            return Object.assign(seriesCopy, seriesProps);
        });
    }

    parseValue(val) {
        val = parseFloat(val);
        return isNaN(val) ? undefined : val;
    }

    getChartType() {
        const firstSeriesType = this.chartModel.series[0] && this.chartModel.series[0].type;
        switch (firstSeriesType) {
            case "waterfall":
                return "waterfall";
            case "pie":
            case "donut":
                return "pie";
            default:
                return "chart";
        }
    }

    getAxisProperties(axisType, model, styles, position, axisLength) {
        let props = _.merge({}, model, styles);

        let backgroundColor = this.getBackgroundColor();

        props.type = model.axisType || "linear";

        if (axisType != "x") {
            props.min = this.parseValue(model.min);
            props.max = this.parseValue(model.max);
            props.tickAmount = this.parseValue(model.tickAmount);
        }

        props.gridLineWidth = model.showGridLines ? styles.gridLineWidth || 1 : 0;
        props.lineWidth = model.showAxisLine ? styles.lineWidth || 1 : 0;
        props.tickLength = model.showMajorTicks ? styles.tickLength || 10 : 0;

        // set default yaxis formatting
        if ((axisType == "y" || axisType == "y2") && props.format == null) {
            props.format = "number";
            props.formatOptions = formatter.getDefaultFormatOptions();
        }

        if (model.axisTitle) {
            props.title = {};
            props.title.text = model.axisTitle !== "none" ? model.axisTitleText || "Value" : null;

            props.title.style = {
                fontFamily: styles.title.fontId,
                fontSize: styles.title.fontSize,
                fontWeight: styles.title.fontWeight,
                textTransform: styles.title.textTransform,
                color: this.palette.getColor(styles.title.color, backgroundColor).toRgbString(),
            };

            if (!this.isHorizontalChart) {
                if (model.axisTitle === "edge") {
                    props.title.align = "middle";
                    props.title.rotation = -90;
                    props.title.x = props.opposite ? 15 : -10;
                    props.title.y = null;
                    props.title.reserveSpace = true;
                    props.title.offset = null;
                    props.title.textAlign = null;
                } else if (model.axisTitle === "top") {
                    props.title.align = "high";
                    props.title.rotation = 0;
                    props.title.x = null;
                    props.title.y = -30;
                    props.title.reserveSpace = false;
                    props.title.offset = 0;
                    if (position == PositionType.LEFT) {
                        props.title.textAlign = "left";
                    } else {
                        props.title.textAlign = "right";
                    }
                    props.title.style.width = axisLength - 50;
                    props.title.style.textOverflow = "ellipsis";
                }
                if (position == PositionType.BOTTOM) {
                    props.title.y = 10;
                }
            }
        } else {
            props.title = { text: null };
        }

        props.labels = props.labels || {};
        props.labels.enabled = model.labels && model.labels.enabled;
        props.labels.overflow = "allow";

        if (axisType == "x") {
            props.type = "category";

            props.tickmarkPlacement = this.chartModel.series.some(s => s.type == "column") ? "between" : "on";
            if (
                this.chartModel.xAxis.zeroAxisPadding &&
                !this.chartModel.series.some(s => s.type === "column" || s.type === "bar")
            ) {
                props.min = 0.49;
                // Chart rendering breaks if max is a negative value
                props.max = Math.max(props.categories.length - 1.49, 0);
            } else {
                props.min = null;
                props.max = null;
                // Chart rendering breaks if max is a negative value
                props.max = Math.max(props.categories.length - 1.49, 0);
            }
            props.labels.formatter = this.formatCategory;
        } else {
            props.labels.formatter = this.formatLabel;
        }
        props.labels.autoRotation = [-45];
        props.labels.padding = 5;

        if (props.labels.y == "null") {
            props.labels.y = null;
        }
        if (props.labels.x == "null") {
            props.labels.x = null;
        }

        if (props.opposite && this.isHorizontalChart) {
            props.labels.y = -15;
        }

        let color = backgroundColor.isDark() ? "white" : "black";

        props.lineColor = this.palette.getColor(color).setAlpha(0.5).toRgbString();
        props.gridLineColor = this.palette.getColor(color).setAlpha(backgroundColor.isDark() ? .2 : .1).toRgbString();
        props.tickColor = this.palette.getColor(color).setAlpha(backgroundColor.isDark() ? .2 : .1).toRgbString();
        props.dateFormatting = model.dateFormatting || "none";

        if (model.labels && model.labels.enabled) {
            props.labels.style = {
                fontFamily: styles.labels.style.fontId,
                fontSize: typeof styles.labels.style.fontSize !== "string" ? styles.labels.style.fontSize + "px" : styles.labels.style.fontSize,
                color: this.palette.getColor(styles.labels.style.color, backgroundColor).toRgbString(),
                fontWeight: styles.labels.style.fontWeight
            };
            if (props.labels.fontSize) {
                props.labels.style.fontSize = props.labels.fontSize;
            }
        }

        return props;
    }

    get legendPosition() {
        return this.model.legendPosition;
    }

    getLegendProperties() {
        let backgroundColor = this.getBackgroundColor(this);

        if (this.legendPosition && this.legendPosition !== PositionType.OFF) {
            let legend = {};
            let legendStyle = this.styles.legend.itemStyle;

            legend.symbolWidth = legendStyle.symbolSize;
            legend.symbolHeight = legendStyle.symbolSize;

            let legendPosition = this.model.legendPosition || PositionType.BOTTOM;

            if (legendPosition === "proximate" && this.model.chartData.series.some(s => s.type == "bar")) {
                legendPosition = PositionType.RIGHT;
            }

            switch (legendPosition) {
                case PositionType.TOP:
                    legend.align = "center";
                    legend.verticalAlign = "top";
                    legend.layout = "horizontal";
                    legend.margin = this.styles.legend.gap;
                    break;
                case PositionType.BOTTOM:
                    legend.align = "center";
                    legend.verticalAlign = "bottom";
                    legend.layout = "horizontal";
                    legend.margin = this.styles.legend.gap;
                    break;
                case PositionType.LEFT:
                    legend.align = "left";
                    legend.verticalAlign = "middle";
                    legend.layout = "vertical";
                    legend.margin = 10;
                    legend.itemMarginTop = 15;
                    break;
                case PositionType.RIGHT:
                    legend.align = "right";
                    legend.verticalAlign = "middle";
                    legend.layout = "vertical";
                    legend.margin = 15;
                    legend.itemMarginTop = 0;
                    break;
                case "proximate":
                    legend.layout = "proximate";
                    legend.align = "right";
                    legend.margin = 0;
                    legend.symbolWidth = 15;
                    legend.symbolHeight = 15;
                    legend.itemMarginTop = 15;
                    legendStyle = this.styles.legend.proximateItemStyle;
                    break;
            }

            legend.enabled = true;
            legend.symbolPadding = 10;
            legend.itemMarginBottom = 10;

            legend.itemStyle = {
                fontFamily: legendStyle.fontId,
                fontSize: typeof legendStyle.fontSize !== "string" ? legendStyle.fontSize + "px" : legendStyle.fontSize,
                opacity: legendStyle.opacity,
                fontWeight: legendStyle.fontWeight
            };
            const legendColor = this.palette.getColor(legendStyle.color, backgroundColor);
            legend.itemStyle.color = legendColor.toRgbString();
            legend.reversed = this.model.legendReverse || false;

            return legend;
        } else {
            return {
                enabled: false
            };
        }
    }

    // ------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    // Colors
    // ------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    getGradient(seriesColor, style, opacity = 1) {
        let gradient = {};
        if (style.fillGradient.type == "linear") {
            if (style.fillGradient.direction == "vertical") {
                gradient.linearGradient = { x1: 0, y1: 0, x2: 0, y2: 1 };
            } else {
                gradient.linearGradient = { x1: 0, y1: 0, x2: 1, y2: 0 };
            }
            gradient.stops = _.toPairs(_.mapValues(style.fillGradient.stops, colorDef => {
                colorDef = colorDef.replace("pointColor", seriesColor.toRgbString());

                var color;
                if (colorDef.startsWith("darken")) {
                    var adj = colorDef.split(" ")[1];
                    colorDef = colorDef.replace("darken " + adj + " ", "");
                    color = tinycolor(colorDef).darken(adj);
                } else {
                    color = tinycolor(colorDef);
                }

                color.setAlpha(opacity);

                return color.toRgbString();
            }));
        }
        return gradient;
    }

    getSeriesAutoColor(seriesModel) {
        return this.getSlideColor({
            index: _.findIndex(this.chartModel.series, s => s.id === seriesModel.id),
            itemCount: this.chartModel.series.length,
            shading: "dark"
        });
    }

    getSeriesColorFromStyle(seriesModel, style, opacity = 1) {
        let color;
        if (seriesModel.colorName == "auto") {
            let backgroundStyleType = this.canvas.getTheme().palette.getBackgroundColorType(this.getBackgroundColor());
            if (backgroundStyleType == BackgroundStyleType.COLOR || backgroundStyleType == BackgroundStyleType.IMAGE) {
                color = tinycolor("white");
            } else {
                color = this.canvas.getTheme().palette.applyColorMods(this.getSeriesAutoColor(seriesModel), this.canvas.getTheme().palette.getColorMods(style));
            }
        } else {
            style = style.replace("seriesColor", seriesModel.colorName); // this preserves any color modifications from the style sheet
            color = this.palette.getColor(style);
        }

        if (color.setAlpha) {
            color.setAlpha(opacity);
        }

        return color.toRgbString();
    }

    getSeriesProps(seriesModel) {
        let seriesProps = {};

        let seriesStyles = this.styles.series[seriesModel.type] || {};
        let seriesColor = seriesModel.colorName || "theme";

        let gradient;
        if (seriesStyles.fillGradient) {
            gradient = this.getGradient(seriesColor, seriesStyles, 1);
        }

        const backgroundColor = this.getBackgroundColor();

        // style datalabels
        seriesProps.dataLabels = _.merge({}, this.styles.series.dataLabels, seriesStyles.dataLabels);
        seriesProps.dataLabels.enabled = !!seriesModel.showDataLabels;
        if (seriesProps.dataLabels.style && seriesProps.dataLabels.style.color) {
            let textColor;
            switch (seriesModel.type) {
                case "column":
                case "bar":
                    if (seriesModel["stacking"]) {
                        textColor = this.palette.getColor(seriesProps.dataLabels.style.color, tinycolor(this.getSeriesColorFromStyle(seriesModel, seriesStyles.lineColor)));
                    } else {
                        textColor = this.palette.getColor(seriesProps.dataLabels.style.color, backgroundColor);
                    }
                    // no outline for data labels in bar or column
                    seriesProps.dataLabels.style.textOutline = "none";
                    break;
                case "waterfall":
                    textColor = this.palette.getColor(seriesProps.dataLabels.style.color, backgroundColor);
                    break;
                default:
                    textColor = this.palette.getColor(seriesProps.dataLabels.style.color, backgroundColor);
                    // outline datalabels for better visibility when overlapping
                    seriesProps.dataLabels.style.textOutline = "3px " + backgroundColor.toRgbString().replace(/ /g, "");
            }

            seriesProps.dataLabels.style.color = textColor.toRgbString();
            seriesProps.dataLabels.style.fontFamily = seriesProps.dataLabels.style.fontId;
        }
        seriesProps.dataLabels.formatter = this.formatDataLabel;
        seriesProps.dataLabels.allowOverlap = true;
        seriesProps.dataLabels.crop = false;
        seriesProps.dataLabels.overflow = "justify";
        seriesProps.dataLabels.annotations = this.annotations?.itemCollection.filter(annotation => annotation.annotationType == "DataHilite").map(annotation => ({
            series: annotation.dataSource.seriesId,
            point: annotation.dataSource.pointIndex
        }));

        if (this.styles.series.marker) {
            let hasMarker = !!(seriesModel["marker"] && seriesModel.marker != "none");
            seriesProps.marker = {
                enabled: hasMarker,
                symbol: seriesModel.marker,
                // radius: this.styles.series.marker.radius || 10,
                radius: Math.max((seriesModel.lineWidth ?? this.styles.series[seriesModel.type].lineWidth) * 1.25, 5),
                fillColor: this.getSeriesColorFromStyle(seriesModel, _.get(this.styles.series, "marker.fillColor", "seriesColor"))
            };

            if (hasMarker) {
                seriesProps.dataLabels.y = -seriesProps.marker.radius + 3;
            } else if (seriesModel.type == "bar") {
                seriesProps.dataLabels.y = 0;
                seriesProps.dataLabels.x = 4;
            } else {
                seriesProps.dataLabels.y = -4;
            }
        }

        let colors = [];
        switch (seriesModel.type) {
            case "column":
            case "bar":
                seriesProps.color = gradient || this.getSeriesColorFromStyle(seriesModel, seriesStyles.lineColor);
                break;
            case "waterfall":
                // migrate old chart colors
                if (!this.chartModel.positiveBarColor) {
                    this.chartModel.positiveBarColor = PaletteColorType.POSITIVE;
                }
                if (!this.chartModel.negativeBarColor) {
                    this.chartModel.negativeBarColor = PaletteColorType.NEGATIVE;
                }
                if (!this.chartModel.sumBarColor) {
                    this.chartModel.sumBarColor = seriesColor;
                }

                let positiveColor = this.palette.getColor(this.chartModel.positiveBarColor, this.getBackgroundColor());
                let negativeColor = this.palette.getColor(this.chartModel.negativeBarColor, this.getBackgroundColor());
                let sumColor = this.palette.getColor(this.chartModel.sumBarColor, this.getBackgroundColor());

                seriesModel.data.forEach(point => {
                    let val = point.y;
                    if (_.isString(val) || point.isSum) {
                        colors.push(sumColor.toRgbString());
                    } else if (val >= 0) {
                        colors.push(positiveColor.toRgbString());
                    } else if (val < 0) {
                        colors.push(negativeColor.toRgbString());
                    }
                    point.dataLabels = { color: "contrast" };
                });

                seriesProps.colors = colors;
                seriesProps.colorByPoint = true;
                const lineColor = this.palette.getColor("theme");
                seriesProps.lineColor = lineColor.toRgbString();

                seriesProps.dataLabels.enabled = true;
                seriesProps.dataLabels.formatter = this.formatDataLabel;
                break;
            case "area":
            case "areaspline":
                seriesProps.color = this.getSeriesColorFromStyle(seriesModel, seriesStyles.lineColor);
                seriesProps.fillColor = this.getSeriesColorFromStyle(seriesModel, seriesStyles.fillColor, seriesStyles.fillOpacity);
                seriesProps.lineColor = this.getSeriesColorFromStyle(seriesModel, seriesStyles.lineColor);
                break;
            default:
                seriesProps.lineColor = this.getSeriesColorFromStyle(seriesModel, seriesStyles.lineColor);
                seriesProps.color = this.getSeriesColorFromStyle(seriesModel, seriesStyles.lineColor);
        }

        seriesProps.lineWidth = seriesModel.lineWidth || seriesStyles.lineWidth;

        return seriesProps;
    }

    formatCategory() {
        try {
            // If we're trying to label out of bounds, skip it
            if (this.target.pos >= this.chart.axes[0].categories.length) {
                return "";
            }

            if (!this.axis.categories[this.target.pos]) {
                return "";
            }

            if (this.axis.userOptions.dateFormatting === "none") {
                return this.value;
            }

            const date = normalizeMonthAbbreviation(this.value);

            if (moment(date, CHART_DATE_FORMATS, true).isValid()) {
                return formatter.formatValue(moment(date, CHART_DATE_FORMATS, true), FormatType.DATE, { dateFormat: this.axis.userOptions.dateFormatting || "none" });
            }

            return this.value;
        } catch {
            return this.value;
        }
    }

    formatLabel() {
        let value = formatter.scaleValue(this.value, this.axis.userOptions.labelFormat);
        value = formatter.formatValue(value, this.axis.userOptions.format, this.axis.userOptions.formatOptions);

        if (this.axis.userOptions.prefix) {
            value = this.axis.userOptions.prefix + value;
        }
        if (this.axis.userOptions.suffix) {
            value = value + this.axis.userOptions.suffix;
        }

        return value;
    }

    formatDataLabel(options) {
        let yAxis = this.series.yAxis;

        // draw a blank data label if there is a data hilite annotation on this point
        if (options.annotations.find(annotation => (annotation.series == this.series.userOptions.id && annotation.point == this.point.index))) {
            return "";
        }

        let value = this.series.userOptions.stacking === "percent" ? this.percentage : this.y;
        value = formatter.scaleValue(value, yAxis.userOptions.labelFormat);
        value = formatter.formatValue(value, yAxis.userOptions.format || FormatType.NUMBER, yAxis.userOptions.formatOptions || formatter.getDefaultFormatOptions());

        if (yAxis.userOptions.prefix) {
            value = yAxis.userOptions.prefix + value;
        }
        if (yAxis.userOptions.suffix) {
            value = value + yAxis.userOptions.suffix;
        }

        return value;
    }

    // ------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    // Zones
    // ------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    setLineStyle(value, series, type) {
        this.createZone(value, series, type);
    }

    createZone(value, series, style) {
        const END_VALUE = 99999;
        let zone;

        // sort the zones by value
        var zones = _.sortBy(series.zones, zone => zone.value || END_VALUE);

        // make sure the end zone has a value to help with our math
        for (zone of zones) {
            if (zone.value == null) {
                zone.value = END_VALUE;
            }
        }

        // if no zones exist, create one for the entire range using the default series styles
        if (zones.length == 0) {
            zones.push({
                style: "default",
                value: END_VALUE
            });
        }

        // find the zone the new zone will intersect
        let intersectingZone = _.minBy(_.filter(zones, zone => zone.value > value), zone => zone.value);

        let previousZone = _.maxBy(_.filter(zones, zone => zone.value <= value), zone => zone.value);

        // if the intersectingZone begins at the same value as the new zone then just update it
        if (intersectingZone.value == value || previousZone && (previousZone.value == value || previousZone.value == END_VALUE)) {
            intersectingZone.style = style;
        } else {
            let intersectingZoneEnd = intersectingZone.value;

            // trim the intersectingZone to end at the new zone value
            intersectingZone.value = value;

            zones.push({
                style: style,
                value: intersectingZoneEnd
            });
        }

        zones = _.sortBy(zones, zone => zone.value);

        let mergedZones = [];
        // remove any end values
        for (zone of zones) {
            if (zone.value == END_VALUE) {
                zone.value = undefined;
            }

            if (zones.indexOf(zone) > 0) {
                let previousZone = zones[zones.indexOf(zone) - 1];
                if (previousZone.style == zone.style || previousZone.value == zone.value) {
                    previousZone.value = zone.value;
                    mergedZones.push(zone);
                }
            }
        }

        for (var mergedZone of mergedZones) {
            zones.remove(mergedZone);
        }

        series.update({
            zoneAxis: "x",
            zones: zones
        }, true);

        let seriesModel = this.chartModel.series[series.index];
        seriesModel.zoneAxis = "x";
        seriesModel.zones = zones;
        this.canvas.updateCanvasModel(true);
    }

    // ------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    // Animations
    // ------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    get animateChildren() {
        return false;
    }

    _getAnimations() {
        const animations = [];

        const getClipPathId = index => `animation-clip-path-${index ?? "0"}-${this.uniquePath.replaceAll("/", "-")}`;
        const getSeriesAnnoatationsAndConnectors = seriesId => {
            const annotationConnectors = [];
            const annotations = this.annotations.itemElements
                .filter(annotation => {
                    if (annotation.model.annotationType === "DataHilite" && annotation.model.dataSource.seriesId === seriesId) {
                        return true;
                    } else if (annotation.model.annotationType === "DataNote") {
                        const connector = annotation.connectorsFromNode.find(({ model }) => model.target === this.uniquePath);
                        if (connector.targetSnapOptions?.seriesId === seriesId) {
                            annotationConnectors.push(connector);
                            return true;
                        }
                    }
                });

            const connectors = this.annotations.connectors.itemElements
                .filter(({ model: { source, target } }) => source === target && source === this.uniquePath)
                .filter(({ model: { sourceSnapOptions } }) => sourceSnapOptions?.seriesId === seriesId);

            return { annotations, connectors, annotationConnectors };
        };
        const seriesHasAnnotations = seriesId => {
            const { annotations, connectors } = getSeriesAnnoatationsAndConnectors(seriesId);
            return (annotations.length + connectors.length) > 0;
        };
        const prepareAnnoataions = seriesId => {
            const { annotations, connectors } = getSeriesAnnoatationsAndConnectors(seriesId);
            annotations.forEach(annotation => {
                annotation.animationState.fadeInProgress = 0;
                annotation.connectorsFromNode.forEach(connector => {
                    connector.animationState.growProgress = connector.animationState.fadeInProgress = 0;
                });
            });
            connectors.forEach(connector => {
                connector.animationState.growProgress = connector.animationState.fadeInProgress = 0;
            });
        };
        const setAnnotationsAnimationState = (seriesId, pointsCount, progress) => {
            const { annotations, connectors } = getSeriesAnnoatationsAndConnectors(seriesId);

            annotations.forEach(annotation => {
                let pointIndex;
                if (annotation.model.annotationType === "DataHilite" && annotation.model.dataSource.seriesId === seriesId) {
                    pointIndex = annotation.model.dataSource.pointIndex;
                } else if (annotation.model.annotationType === "DataNote") {
                    const connector = annotation.connectorsFromNode.find(({ model }) => model.target === this.uniquePath);
                    if (connector.targetSnapOptions.seriesId === seriesId) {
                        pointIndex = connector.targetSnapOptions.pointIndex;
                    }
                }

                const annotationDuration = 0.2;
                const start = Math.clamp(pointIndex / (pointsCount - 1) - annotationDuration / 2, 0, 1 - annotationDuration);
                const animationProgress = Math.clamp((progress - start) / annotationDuration, 0, 1);
                annotations.forEach(annotation => {
                    annotation.animationState.fadeInProgress = animationProgress;
                    annotation.connectorsFromNode.forEach(connector => {
                        connector.animationState.growProgress = connector.animationState.fadeInProgress = animationProgress;
                    });
                });
                connectors.forEach(connector => {
                    connector.animationState.growProgress = connector.animationState.fadeInProgress = animationProgress;
                });
            });

            connectors.forEach(connector => {
                const startIndex = Math.min(connector.model.sourceSnapOptions.pointIndex, connector.model.targetSnapOptions.pointIndex);
                const endIndex = Math.max(connector.model.sourceSnapOptions.pointIndex, connector.model.targetSnapOptions.pointIndex);

                const start = startIndex / (pointsCount - 1);
                const duration = (endIndex - startIndex) / (pointsCount - 1);
                connector.animationState.growProgress = connector.animationState.fadeInProgress = Math.clamp((progress - start) / duration, 0, 1);
            });
        };

        const axisAnnotations = this.annotations.itemElements
            .filter(annotation => annotation.connectorsFromNode.some(({ model }) => model.target === this.uniquePath && !!model.targetSnapOptions.axis));
        const axisAnnotationsConnectors = (_.flatten(axisAnnotations.map(annotation => annotation.connectorsFromNode)));

        const isStacked = !!this.chartModel.series[0].stacking && this.chartModel.series[0].stacking !== "none";
        if (isStacked) {
            const isColumns = this.chart.series.every(series => series.userOptions.type == "column");

            animations.push({
                name: "Stacked chart",
                animatingElements: [
                    this,
                    ...this.annotations.itemElements.filter(element => !axisAnnotations.includes(element)),
                    ...this.annotations.connectors.itemElements.filter(connector => !axisAnnotationsConnectors.includes(connector))
                ],
                defaultDuration: 2000,
                prepare: () => {
                    const $chart = $(this.chartRef.current.container.current);
                    const $defs = $chart.find("defs");

                    const clipPathId = getClipPathId();
                    const clipPath = document.createElementNS("http://www.w3.org/2000/svg", "clipPath");
                    clipPath.setAttribute("id", `${clipPathId}`);
                    $defs[0].appendChild(clipPath);

                    const rect = document.createElementNS("http://www.w3.org/2000/svg", "rect");
                    rect.setAttribute("x", -50);
                    rect.setAttribute("y", -100);
                    if (isColumns) {
                        rect.setAttribute("width", "120%");
                        rect.setAttribute("style", "transform-origin: bottom; transform: scaleY(0)");
                    } else {
                        rect.setAttribute("width", 0);
                    }
                    rect.setAttribute("height", "120%");
                    clipPath.appendChild(rect);

                    $chart.find(`.highcharts-series-group, .highcharts-data-labels`).attr("clip-path", `url(#${clipPathId})`);

                    this.chart.series.map(series => {
                        const seriesId = series.userOptions.id;
                        prepareAnnoataions(seriesId);
                    });
                },
                onBeforeAnimationFrame: progress => {
                    const $chart = $(this.chartRef.current.container.current);
                    const $defs = $chart.find("defs");

                    const clipPathId = getClipPathId();
                    let annotationsDelay = 0;
                    if (isColumns) {
                        annotationsDelay = 0.5;
                        $defs.find(`#${clipPathId} rect`).css({ transform: `scaleY(${progress})` });
                    } else {
                        const clipWidth = this.chartRef.current.chart.chartWidth;
                        $defs.find(`#${clipPathId} rect`).attr("width", (clipWidth + 70) * progress);
                    }

                    const pointsCount = _.max(this.chart.series.map(series => series.points.length));
                    this.chart.series.map(series => {
                        const seriesId = series.userOptions.id;
                        setAnnotationsAnimationState(seriesId, pointsCount, Math.max((progress - annotationsDelay) / (1 - annotationsDelay), 0));
                    });

                    return this.annotations;
                },
                finalize: () => {
                    $(`#${getClipPathId()}`).remove();
                }
            });
        } else {
            this.chart.series.map((series, index) => {
                const seriesId = series.userOptions.id;
                const hasAnnotations = seriesHasAnnotations(seriesId);
                let animationName = `"${series.name}" series`;
                let duplicatedAnimationsCount = 0;
                while (animations.some(animation => animation.name === animationName)) {
                    duplicatedAnimationsCount++;
                    animationName = `"${series.name} ${duplicatedAnimationsCount}" series`;
                }
                const { annotations, connectors, annotationConnectors } = getSeriesAnnoatationsAndConnectors(seriesId);
                animations.push({
                    name: animationName,
                    animatingElements: [this, ...annotations, ...connectors, ...annotationConnectors],
                    defaultDuration: 2000,
                    prepare: () => {
                        const $chart = $(this.chartRef.current.container.current);

                        if (series.userOptions.type === "column" || series.userOptions.type === "bar") {
                            const clipHeight = this.chartRef.current.chart.clipBox.height;
                            $chart.find(`.highcharts-series.highcharts-series-${index}`).find("rect").css({
                                "transform-origin": `0 ${clipHeight}px`,
                                transform: "scaleY(0)"
                            });
                            $chart.find(`.highcharts-data-labels.highcharts-series-${index}`).css("opacity", 0);
                            $chart.find(`.highcharts-legend-item.highcharts-series-${index}`).css("opacity", 0);
                        } else {
                            const $defs = $chart.find("defs");

                            const clipPathId = getClipPathId(index);
                            const clipPath = document.createElementNS("http://www.w3.org/2000/svg", "clipPath");
                            clipPath.setAttribute("id", clipPathId);
                            $defs[0].appendChild(clipPath);

                            const rect = document.createElementNS("http://www.w3.org/2000/svg", "rect");
                            rect.setAttribute("x", -50);
                            rect.setAttribute("y", 0);
                            rect.setAttribute("width", 0);
                            rect.setAttribute("height", "100%");
                            clipPath.appendChild(rect);

                            $chart.find(`.highcharts-series.highcharts-series-${index}`).attr("clip-path", `url(#${clipPathId})`);

                            $chart.find(`.highcharts-data-labels.highcharts-series-${index}`).attr("clip-path", `url(#${clipPathId})`);
                            $chart.find(`.highcharts-markers.highcharts-series-${index}`).attr("clip-path", `url(#${clipPathId})`);
                            $chart.find(`.highcharts-legend-item.highcharts-series-${index}`).css("opacity", 0);
                        }

                        prepareAnnoataions(seriesId);
                    },
                    onBeforeAnimationFrame: progress => {
                        const $chart = $(this.chartRef.current.container.current);

                        let annotationsDelay = 0;
                        if (series.userOptions.type === "column" || series.userOptions.type === "bar") {
                            if (hasAnnotations) {
                                annotationsDelay = 0.5;
                            }

                            const chartProgress = Math.min(1, progress / (1 - annotationsDelay));
                            $chart.find(`.highcharts-series.highcharts-series-${index}`).find("rect").css("transform", `scaleY(${chartProgress})`);
                            $chart.find(`.highcharts-data-labels.highcharts-series-${index}`).css("opacity", Math.clamp((progress - 0.5) / 0.5, 0, 1));
                            $chart.find(`.highcharts-legend-item.highcharts-series-${index}`).css("opacity", progress);
                        } else {
                            const $defs = $chart.find("defs");

                            const clipWidth = this.chartRef.current.chart.clipBox.width;
                            $defs.find(`#${getClipPathId(index)} rect`).attr("width", (clipWidth + 70) * progress);
                            $chart.find(`.highcharts-legend-item.highcharts-series-${index}`).css("opacity", progress);
                        }

                        setAnnotationsAnimationState(seriesId, series.points.length, Math.max((progress - annotationsDelay) / (1 - annotationsDelay), 0));

                        return this.annotations;
                    },
                    finalize: () => {
                        if (series.userOptions.type === "column" || series.userOptions.type === "bar") {
                            const $chart = $(this.chartRef.current.container.current);
                            $chart.find(`.highcharts-series.highcharts-series-${index}`).find("rect").css({
                                transform: "scaleY(1)"
                            });
                            $chart.find(`.highcharts-data-labels.highcharts-series-${index}`).opacity(1);
                            $chart.find(`.highcharts-legend-item.highcharts-series-${index}`).opacity(1);
                        } else {
                            $(`#${getClipPathId(index)}`).remove();
                        }
                    }
                });
            });
        }

        if (axisAnnotations.length > 0) {
            animations.push({
                name: "Axis annotations",
                animatingElements: [...axisAnnotations, ...axisAnnotationsConnectors],
                prepare: () => axisAnnotations.forEach(annotation => {
                    annotation.animationState.fadeInProgress = 0;
                    annotation.connectorsFromNode.forEach(connector => {
                        connector.animationState.growProgress = connector.animationState.fadeInProgress = 0;
                    });
                }),
                onBeforeAnimationFrame: progress => axisAnnotations.forEach(annotation => {
                    annotation.animationState.fadeInProgress = progress;
                    annotation.connectorsFromNode.forEach(connector => {
                        connector.animationState.growProgress = connector.animationState.fadeInProgress = progress;
                    });
                    return this.annotations;
                })
            });
        }

        return animations;
    }

    _exportToSharedModel() {
        const tabularData = [{
            data: this.getChartType() !== "waterfall" ? [
                ["", ...this.model.chartData.xAxis.categories],
                ...this.model.chartData.series.map(s => ([s.name, ...s.data.map(({ y }) => y)]))
            ] : [
                this.model.chartData.xAxis.categories,
                ...this.model.chartData.series.map(s => s.data.map(({ y }) => y))
            ],
            dataSourceLink: _.omit(this.parentElement.model.dataSourceLink, ["useFirstRowAsCategory", "useFirstColAsLegend", "isDataTransposed"]),
            categories: this.model.chartData.xAxis.categories,
            series: this.model.chartData.series.map(s => ({ name: s.name, values: s.data.map(({ y }) => y) })),
            annotations: { chart: this.model.chartAnnotations }
        }];

        return { tabularData };
    }

    _importFromSharedModel(model) {
        const tabularData = detectTabularData(model);
        if (!tabularData) return;

        tabularData.data = tabularData.data.map((row, index) =>{
            // if first row, replace empty cells with "X"
            if (index === 0) {
                return row.map(cell => (!cell || cell === "undefined" || cell === "") ? "X" : cell);
            }

            return row;
        });

        const chartData = getValidChartDataFromCsv(tabularData.data, false, this.getChartType() === "waterfall");
        chartData.categories = chartData.categories.map(cell => cell === "X" ? "" : cell);
        if (chartData?.error) {
            throw new Error(`Chart data validation failed with error "${chartData.error}"`);
        }

        return {
            ...this.updateChartData(chartData, true),
            dataSourceLink: tabularData.dataSourceLink
        };
    }
}

function drawLegendSymbol(chart, legend, item) {
    var options = chart.options, markerOptions = options.marker, radius, legendSymbol, symbolWidth = legend.symbolWidth,
        symbolHeight = legend.symbolHeight, generalRadius = symbolHeight / 2, renderer = chart.chart.renderer,
        legendItemGroup = chart.legendGroup, verticalCenter = legend.baseline -
            Math.round(legend.fontMetrics.b * 0.3), attr = {};

    verticalCenter = 14;

    if (symbolWidth == 0) return;

    options = legend.options;
    var square = options.squareSymbol;
    symbolWidth = square ? symbolHeight : legend.symbolWidth;
    symbolHeight = legend.symbolHeight;

    let offset;
    if (options.layout == "proximate") {
        offset = 0;
    } else {
        offset = 4;
    }

    item.legendSymbol = chart.chart.renderer.rect(square ? (legend.symbolWidth - symbolHeight) / 2 : 0, legend.baseline - symbolHeight + offset, // #3988
        symbolWidth, symbolHeight, symbolHeight / 2)
        .addClass("highcharts-point")
        .attr({
            zIndex: 3
        }).add(item.legendGroup);
}

Highcharts.seriesTypes.line.prototype.drawLegendSymbol = function(legend, item) {
    drawLegendSymbol(this, legend, item);
};

Highcharts.seriesTypes.area.prototype.drawLegendSymbol = function(legend, item) {
    drawLegendSymbol(this, legend, item);
};

Highcharts.seriesTypes.areaspline.prototype.drawLegendSymbol = function(legend, item) {
    drawLegendSymbol(this, legend, item);
};

Highcharts.seriesTypes.column.prototype.drawLegendSymbol = function(legend, item) {
    drawLegendSymbol(this, legend, item);
};

export { Chart };


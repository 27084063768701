import React, { Component } from "react";
import { MenuItem } from "@material-ui/core";

import { _ } from "../../../../vendor";
import { WithLabel } from "../../../../Components/WithLabel";
import { ImagePopup } from "../../../../Components/ImagePopup";
import { StaticImage } from "../../../../Components/StaticImage";
import { ImageOption } from "../../../../Components/ImageOptionList";
import { FlexBox } from "../../../../react/components/LayoutGrid";
import { Popup, PopupContent } from "../../../../Components/Popup";
import { ForeColorType, NodeType } from "../../../../../common/constants";

import { PropertySection } from "../../../../EditorComponents/PropertyPanel";
import { ItemColorPicker } from "./ColorPickers/ItemColorPicker";
import { ColorPicker } from "./ColorPickers/ColorPicker";
import { Button } from "../../../../Components/Button";
import { Icon } from "../../../../Components/Icon";
import { PropertyPanelButton } from "../../../../EditorComponents/PropertyPanelButton";
import PresentationEditorController from "js/editor/PresentationEditor/PresentationEditorController";

export class ConnectorPropertyPanel extends Component {
    get elements() {
        const { element, elements = [] } = this.props;
        return [element, ...elements].filter(Boolean);
    }

    getValue = propertyName => {
        const values = _.uniq(this.elements.map(element => element.model[propertyName]));
        if (values.length === 1) {
            return values[0];
        }

        return "mixed";
    }

    updateValue = async (props, refreshStyles) => {
        const {
            onClose = () => {
            }
        } = this.props;

        this.elements.map(element => {
            Object.assign(element.model, props);
        });

        await this.elements[0].saveModel(false, refreshStyles);

        onClose();
    }

    addConnectorLabels = async () => {
        const {
            onClose = () => { }
        } = this.props;

        await PresentationEditorController.selectionLayerController.setSelectedElements([]);

        const addedLabels = [];
        this.elements.forEach(element => {
            let position = 0.5;
            if (element.labels.itemElements.length > 0) {
                const minLabelPosition = _.min(element.labels.itemElements.map(label => label.position));
                const maxLabelPosition = _.max(element.labels.itemElements.map(label => label.position));
                const spaceBefore = minLabelPosition;
                const spaceAfter = 1 - maxLabelPosition;
                if (spaceBefore > spaceAfter) {
                    position = minLabelPosition / 2;
                } else {
                    position = (1 - maxLabelPosition) / 2 + maxLabelPosition;
                }
            }
            const label = element.labels.addItem({
                position
            });
            addedLabels.push(label.id);
        });

        await this.elements[0].saveModel();
        const selectLabels = this.elements[0].labels.elements[addedLabels.map(id => id)];
        await PresentationEditorController.selectionLayerController.setSelectedElements([selectLabels]);

        onClose();
    }

    deleteConnectors = async () => {
        this.elements.forEach(element => {
            const container = element.parentElement;
            container.deleteItem(element.id);
        });

        await this.elements[0].canvas.updateCanvasModel(false);
    }

    render() {
        const { element, canChangeConnectorType, canAddLabel, canDelete } = this.props;

        if (this.elements.length === 0) {
            return null;
        }

        const canvas = this.elements[0].canvas;

        const lineWeight = this.getValue("lineWeight");
        const lineWeights = {
            1: "Extra Thin",
            2: "Thin",
            3: "Medium",
            5: "Thick",
            "bold": "Bold",
        };

        const stepEnabled = this.elements.every(element =>
            (element.model.source &&
            element.endTarget?.isInstanceOf("ContentItem") &&
            element.endTarget?.nodeType !== NodeType.BULLET_TEXT &&
            element.endTarget?.nodeType !== NodeType.NUMBERED_TEXT) || element.allowStepConnector
        );

        const angleEnabled = this.elements.every(element =>
            (element.model.source || element.model.target) &&
            element.model.disableAngle !== true
        );

        const connectorTypes = [
            { value: "straight", label: "Straight", image: "/images/ui/connectors/connector-straight.svg" },
            stepEnabled ? { value: "step", label: "Step", image: "/images/ui/connectors/connector-step.svg" } : null,
            angleEnabled ? { value: "angle", label: "Angle", image: "/images/ui/connectors/connector-angle.svg" } : null
        ].filter(Boolean);

        const allowDelete = element && canDelete && (element.model.source || element.model.target);

        return (
            <>
                <PropertySection>
                    <WithLabel label="Connector Styles" above left fillWidth>
                        <FlexBox left bottom fillWidth gap={15}>
                            {canChangeConnectorType && connectorTypes.length > 1 &&
                                <WithLabel label="Type" below small center>
                                    <ImagePopup value={this.getValue("connectorType")}
                                        previewSize={24} showArrow={false} size={30}
                                        onChange={connectorType => this.updateValue({ connectorType })}
                                    >
                                        {connectorTypes.map(({ value, label, image }) => (
                                            <ImageOption key={value} label={label} value={value}>
                                                <StaticImage src={image} />
                                            </ImageOption>
                                        ))}
                                    </ImagePopup>
                                </WithLabel>
                            }
                            <WithLabel label="Style" below small center>
                                <ImagePopup
                                    value={this.getValue("lineStyle")}
                                    previewSize={24} size={30} showArrow={false}
                                    onChange={lineStyle => this.updateValue({ lineStyle })}
                                >
                                    <ImageOption label="Solid" value="solid" background="transparent">
                                        <StaticImage src="/images/ui/connectors/line-style-solid.svg" />
                                    </ImageOption>
                                    <ImageOption label="Dotted" value="dotted" background="transparent">
                                        <StaticImage src="/images/ui/connectors/line-style-dotted.svg" />
                                    </ImageOption>
                                    <ImageOption label="Dashed" value="dashed" background="transparent">
                                        <StaticImage src="/images/ui/connectors/line-style-dashed.svg" />
                                    </ImageOption>
                                    <ImageOption label="Pulse (animated)" value="animate_pulse" background="transparent">
                                        <StaticImage src="/images/ui/connectors/line-style-pulse.svg" />
                                    </ImageOption>
                                    <ImageOption label="Dashed (animated)" value="animate_dash" background="transparent">
                                        <StaticImage src="/images/ui/connectors/line-style-dashed.svg" />
                                    </ImageOption>
                                </ImagePopup>
                            </WithLabel>
                            <WithLabel label="Weight" below small center>
                                <Popup icon="line_weight">
                                    <PopupContent>
                                        {closePopup =>
                                            Object.entries(lineWeights).map(([value, label]) => (
                                                <MenuItem
                                                    key={value}
                                                    selected={lineWeight === value}
                                                    onClick={() => {
                                                        this.updateValue({ lineWeight: value });
                                                        closePopup();
                                                    }}
                                                >
                                                    {label}
                                                </MenuItem>
                                            ))
                                        }
                                    </PopupContent>
                                </Popup>
                            </WithLabel>
                            <WithLabel label="Color" below small center>
                                <ColorPicker value={this.getValue("color") ?? this.elements[0].colorSet.connectorLineColor.name}
                                    canvas={canvas}
                                    defaultColor={element?.getDefaultConnectorColor()} showPrimary showSecondary allowColorOnColor
                                    onChange={color => this.updateValue({ color }, true)}
                                />
                            </WithLabel>
                            <WithLabel label="Decoration" below small center>
                                <FlexBox gap={5}>
                                    <ImagePopup value={this.getValue("startDecoration")}
                                        previewSize={24} size={30} showArrow={false}
                                        onChange={startDecoration => this.updateValue({ startDecoration })}
                                    >
                                        <ImageOption label="None" value="none" background="transparent">
                                            <StaticImage src="/images/ui/connectors/line-start-none.svg" />
                                        </ImageOption>
                                        <ImageOption label="Arrow" value="arrow" background="transparent">
                                            <StaticImage src="/images/ui/connectors/line-start-arrow.svg" />
                                        </ImageOption>
                                        <ImageOption label="Circle" value="circle" background="transparent">
                                            <StaticImage src="/images/ui/connectors/line-start-circle.svg" />
                                        </ImageOption>
                                    </ImagePopup>
                                    <ImagePopup value={this.getValue("endDecoration")}
                                        previewSize={24} size={30} showArrow={false}
                                        onChange={endDecoration => this.updateValue({ endDecoration })}
                                    >
                                        <ImageOption label="None" value="none" background="transparent">
                                            <StaticImage src="/images/ui/connectors/line-end-none.svg" />
                                        </ImageOption>
                                        <ImageOption label="Arrow" value="arrow" background="transparent">
                                            <StaticImage src="/images/ui/connectors/line-end-arrow.svg" />
                                        </ImageOption>
                                        <ImageOption label="Circle" value="circle" background="transparent">
                                            <StaticImage src="/images/ui/connectors/line-end-circle.svg" />
                                        </ImageOption>
                                    </ImagePopup>
                                </FlexBox>
                            </WithLabel>
                        </FlexBox>
                    </WithLabel>
                </PropertySection>

                {canAddLabel &&
                    <PropertyPanelButton icon="text_increase" title="Add Label" description="Add a positionable label to the connector line."
                        onClick={this.addConnectorLabels}
                    />
                }

                {allowDelete &&
                    <PropertySection>
                        <Button onClick={this.deleteConnectors}>
                            <Icon>delete</Icon>
                            Delete Connector
                        </Button>
                    </PropertySection>
                }
            </>
        );
    }
}


import React, { Component } from "react";
import { PropertyPanelContainer, PropertySection, PropertySectionHeader } from "../../../../EditorComponents/PropertyPanel";
import { Button } from "../../../../Components/Button";
import { ToggleSwitch } from "../../../../Components/ToggleSwitch";
import { WithLabel } from "../../../../Components/WithLabel";
import { ShowInputDialog } from "../../../../react/components/Dialogs/BaseDialog";
import { Slider } from "../../../../Components/Slider";
import { ImageFramePopupMenu } from "../EditorComponents/ImageFrameMenu";
import { IconButton } from "../../../../Components/IconButton";
import { FlexBox } from "../../../../react/components/LayoutGrid";
import { ImageOption, ImageOptionList } from "../../../../Components/ImageOptionList";

export class WebViewPropertyPanel extends Component {
    handleSetURL = async () => {
        const { element } = this.props;

        let url = await ShowInputDialog({
            title: (
                <div>Paste Embed Code or URL
                    <div style={{ fontSize: 14, fontWeight: 500, marginTop: 10 }}>
                        <p>Please note that many popular websites prevent embedding their URLs and will not work in a WebView slide. If you or your company controls the webpage you are trying to embed, your webmaster or IT resource may need to allow embedding of the page.</p>
                    </div>
                </div>),
            value: element.model.url
        });
        if (url) {
            if (url.startsWith("<iframe")) {
                let match = /src=("|')(.*?)("|')/.exec(url);
                url = match[2];
            }

            if (!url.startsWith("http")) {
                url = "https://" + url;
            }

            element.updateModel({ url });
        }
    }

    handleEmbedFigma = async () => {
        const { element } = this.props;
        let url = await ShowInputDialog({
            title: "Paste Figma Link",
            value: ""
        });
        if (url) {
            url = `https://www.figma.com/embed?embed_host=beautifulai&url=${url}`;
            element.updateModel({ url });
        }
    }

    handleEmbedLoom = async () => {
        const { element } = this.props;
        let url = await ShowInputDialog({
            title: "Paste Loom Link",
        });
        if (url) {
            url = url.replace("https://www.loom.com/share/", "");
            url = `https://www.loom.com/embed/${url}&hideEmbedTopBar=1`;
            element.updateModel({ url });
        }
    }

    handleEmbedMiro = async () => {
        const { element } = this.props;
        let url = await ShowInputDialog({
            title: "Paste Miro Link",
        });
        if (url) {
            url = url.replace("https://miro.com/app/board/", "");
            url = `https://miro.com/app/live-embed/${url}`;
            element.updateModel({ url });
        }
    }

    handleEmbedTypeForm = async () => {
        const { element } = this.props;
        let url = await ShowInputDialog({
            title: "Paste TypeForm Link",
            value: ""
        });
        if (url) {
            element.updateModel({ url });
        }
    }

    render() {
        const { element } = this.props;

        const { fullBleed, scale, frameType } = element.model;

        return (
            <PropertyPanelContainer>
                <PropertySection>
                    <ImageOptionList size={50}>
                        <ImageOption label="Figma" onClick={this.handleEmbedFigma} url="/images/WebViewIntegrations/figma.webp" />
                        <ImageOption label="Loom" onClick={this.handleEmbedLoom} url="/images/WebViewIntegrations/loom.png" />
                        <ImageOption label="Miro" onClick={this.handleEmbedMiro} url="/images/WebViewIntegrations/miro.png" />
                        <ImageOption label="TypeForm" onClick={this.handleEmbedTypeForm} url="/images/WebViewIntegrations/typeform.jpeg" />
                    </ImageOptionList>
                </PropertySection>
                <PropertySection>
                    <Button blue solid onClick={this.handleSetURL}>Add Embed Code or URL...</Button>
                </PropertySection>
                <PropertySection>
                    <WithLabel label="Full Bleed">
                        <ToggleSwitch value={fullBleed}
                            onChange={value => element.updateModel({ fullBleed: value })}
                        />
                    </WithLabel>
                    <WithLabel label="Scale">
                        <Slider value={scale}
                            min={0.1} max={2} step={0.01}
                            showInput percent
                            onChange={value => element.refreshModel({ scale: value })}
                            onCommit={() => element.saveModel()}

                        />
                    </WithLabel>
                </PropertySection>
                <PropertySection>
                    <PropertySectionHeader label="Frame">
                        <IconButton icon="delete"
                            onClick={() => element.updateModel({ frameType: "none" }, { refreshStyles: true })} />
                    </PropertySectionHeader>
                    <FlexBox left fillWidth>
                        <ImageFramePopupMenu
                            size={60} showPreview
                            frameType={frameType}
                            allowedCategories={["device", "decorative"]}
                            onChange={frameType => element.updateModel({ frameType }, { refreshStyles: true })}
                            showRemoveFrameButton
                        />
                    </FlexBox>
                </PropertySection>
            </PropertyPanelContainer>
        );
    }
}

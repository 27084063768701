import * as geom from "js/core/utilities/geom";
import { AssetType } from "common/constants";

import { CollectionElement, CollectionItemElement } from "../base/CollectionElement";
import { ContentElement } from "../base/ContentElement";
import { LogoGridControlBar, LogoGridPropertyPanel, LogoGridItemControlBar } from "../../Editor/ElementPropertyPanels/LogoGridUI";

class LogoGrid extends CollectionElement {
    get name() {
        return "Logo Grid";
    }

    getElementPropertyPanel() {
        return LogoGridPropertyPanel;
    }

    getElementControlBar() {
        return LogoGridControlBar;
    }

    getChildItemType(itemModel) {
        return LogoGridItem;
    }

    get maxItemCount() {
        return 30;
    }

    _calcProps(props, options) {
        let { size } = props;

        let layouter = this.getLayouter(props, this.itemElements, size).calcBoxGridLayout();

        return { size: layouter.size };
    }

    _exportToSharedModel() {
        const assets = this.itemElements.reduce((assets, itemElement) => ([
            ...assets, ...itemElement.logo._exportToSharedModel().assets
        ]), []);

        return { assets, collectionColor: this.collectionColor };
    }

    _importFromSharedModel(model) {
        const { assets } = model;
        if (!assets?.length) return;

        const items = assets.map((asset, i) => ({
            content_type: asset.type,
            content_value: asset.value,
            assetName: asset.name,
            assetProps: asset.props,
            ...(asset.configProps ?? {})
        }));

        return { items, collectionColor: model.collectionColor };
    }
}

class LogoGridItem extends CollectionItemElement {
    static get schema() {
        return {
            backgroundColor: "white"
        };
    }

    get selectionPadding() {
        return 0;
    }

    getElementControlBar() {
        return LogoGridItemControlBar;
    }

    _build() {
        this.logo = this.addElement("logo", () => ContentElement, {
            defaultAssetType: AssetType.LOGO,
            allowBackdrop: false,
            canSelect: false
        });
    }

    _calcProps(props, options) {
        let { size } = props;

        // let frameColor = "white";
        // if (this.logo.assetElement && this.logo.assetElement.hasAlpha) {
        //     frameColor = this.model.backgroundColor;
        // }

        this.createDecoration({
            type: "frame",
            shape: "rect",
            strokeWidth: this.parentElement.model.showFrame ? 1 : 0
        });

        let logoProps = this.logo.calcProps(size);
        logoProps.bounds = new geom.Rect(0, 0, size);

        return { size };
    }

    _applyColors() {
        this.decoration.colorSet.fillColor = this.palette.getColor("white");
        if (this.parentElement.model.showFrame) {
            this.decoration.colorSet.strokeColor = this.palette.getColor("secondary_dark").setAlpha(0.1);
        }
    }
}

export const elements = {
    LogoGrid
};

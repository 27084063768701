import styled from "styled-components";

const ElementSelectionBox = styled.div.attrs(({ bounds = null, pointerEvents = null }) => ({
    style: {
        ...(bounds ? bounds.toObject() : {}),
        ...(pointerEvents ? { pointerEvents } : {})
    }
}))`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;    
    z-index: 1; // layer selection box over default overlay
`;

export default ElementSelectionBox;

import React, { Component } from "react";
import styled from "styled-components";

import Spinner from "js/react/components/Spinner";
import { ds } from "js/core/models/dataService";
import { Box, FlexBox } from "js/react/components/LayoutGrid";
import { themeColors } from "js/react/sharedStyles";
import { Gap20 } from "js/react/components/Gap";
import { ShowAssetDialog } from "js/react/views/AddAssets/ShowAssetDialog";
import { ShowWarningDialog } from "js/react/components/Dialogs/BaseDialog";
import { AssetType } from "common/constants";
import PresentationEditorController from "js/editor/PresentationEditor/PresentationEditorController";

import { Popup, PopupContent, PopupContainer, PopupPreview } from "./Popup";
import { Button } from "./Button";

const IconGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, 48px);
`;

export class IconPickerPopup extends Component {
    render() {
        const { value, icons, showNone, canChooseIcon, showIconPreview = false, previewIconSize = 40, showArrow = true, onChange, width = 390 } = this.props;

        return (
            <Popup label={showIconPreview ? null : "Icon"} showArrow={showArrow}>
                {showIconPreview && (
                    <PopupPreview>
                        <BeautifulIcon size={previewIconSize}>{value}</BeautifulIcon>
                    </PopupPreview>
                )}
                <PopupContent>
                    {closePopup => (
                        <PopupContainer>
                            <IconPicker icons={icons}
                                value={value}
                                width={width}
                                showNone={showNone}
                                canChooseIcon={canChooseIcon}
                                onChange={iconId => {
                                    onChange(iconId);
                                    closePopup();
                                }} />
                        </PopupContainer>
                    )}
                </PopupContent>
            </Popup>
        );
    }
}

export class IconPicker extends Component {
    render() {
        const { value, icons, showNone, canChooseIcon, onChange, width } = this.props;

        return (
            <Box width={width ?? "100%"}>
                <IconGrid>
                    {showNone &&
                        <IconPreviewContainer className="none" onClick={() => onChange(null)}>NONE</IconPreviewContainer>
                    }
                    {icons.map(iconId => (
                        <BeautifulIcon
                            key={iconId}
                            selected={iconId === value}
                            onSelect={() => {
                                onChange(iconId);
                            }}
                        >
                            {iconId}
                        </BeautifulIcon>)
                    )}
                </IconGrid>
                {canChooseIcon &&
                    <>
                        <Gap20 />
                        <Button small solid onClick={async () => {
                            const asset = await ShowAssetDialog(AssetType.ICON, {
                                supportedAssetTypes: [AssetType.ICON],
                                hideVideos: true,
                                hidePhotos: true,
                                hideLogos: true,
                                hideAISearch: true
                            });

                            onChange(asset.content_value);
                        }}>
                            Choose Icon...
                        </Button>
                    </>
                }
            </Box>
        );
    }
}

const IconPreviewContainer = styled.div`
    position: relative;
    width: ${props => props.size}px;
    height: ${props => props.size}px;
    border: solid 1px ${props => props.selected ? themeColors.selection : "transparent"};
    display: flex;
    align-items: center;
    justify-content: center;
    
    &:hover {
        background: ${themeColors.selection};
    }

    svg {
        width: 100%;
        height: 100%;
    }

    &.none {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }
`;

export class BeautifulIcon extends Component {
    state = {
        svgData: null
    }

    componentDidMount() {
        this.loadIcon();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.children !== this.props.children) {
            this.loadIcon();
        }
    }

    loadIcon() {
        const iconId = this.props.children;

        if (iconId == "mixed") {
            this.setState({ svgData: "MIXED" });
            return;
        }

        ds.assets.getAssetById("icon-" + PresentationEditorController.currentTheme.get("iconStyle") + "-" + iconId, "icon")
            .then(async icon => {
                const url = icon.get("original");
                if (url.startsWith("http")) {
                    const res = await fetch(url);
                    return await res.text();
                } else {
                    return Promise.resolve(url);
                }
            }).then(svgData => {
                this.setState({ svgData });
            });
    }

    render() {
        const { onSelect, selected, size = 48 } = this.props;
        const { svgData } = this.state;

        return (
            <IconPreviewContainer onClick={onSelect} selected={selected} size={size}>
                {svgData && <div dangerouslySetInnerHTML={{ __html: svgData }} />}
                {!svgData && <Spinner />}
            </IconPreviewContainer>
        );
    }
}


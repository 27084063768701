import React from "react";
import styled from "styled-components";
import moment from "moment";

import { _ } from "../../../../../vendor";
import { Gap } from "../../../../../react/components/Gap";
import WidgetButton from "../../../../../Components/WidgetButton";
import { removeFormattingFromHtmlText } from "../../../../../core/utilities/htmlTextHelpers";

import { EditDataPanel } from "./EditDataPanel";
import ReactGridWrapper from "./ReactGridWrapper";
import { reorderArray } from "js/core/utilities/utilities";

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    padding: 20px;
    background: #f5f5f5;
    border-left: solid 5px #333;
`;

const HeaderColumn = styled.div`
    width: 250px;
    display: flex;
    flex-direction: column;

    .MuiButton-label {
        justify-content: flex-start;
    }

    .bai-popup>.MuiButtonBase-root>.MuiButton-label {
        color: rgb(51, 51, 51);
    }
`;

const ClosePanelButton = styled.div`
    position: absolute;
    top: -10px;
    right: 4px;
    padding: 10px;
    cursor: pointer;
    
`;

export class EditPieChartDataPanel extends EditDataPanel {
    constructor() {
        super();
        this.sheetRef = React.createRef();
    }
    getRandomValue() {
        const { element } = this.props;

        const min = Math.min(...element.model.data.map(({ value }) => value));
        const max = Math.max(...element.model.data.map(({ value }) => value));

        return Math.round(Math.random() * (max - min) + min);
    }

    getData = () => {
        const { element } = this.props;

        const categories = element.model.data.map(({ label }) => removeFormattingFromHtmlText(label.blocks?.[0].html ?? label.text));

        const cols = categories.map((category, index) => ({
            columnId: index,
            width: 100,
            reorderable: true
        }));
        cols.push({
            columnId: cols.length,
            width: 100
        });

        const headerRow = {
            rowId: "header",
            height: 30,
            cells: categories.map((category, index) => ({
                type: "header",
                text: String.fromCharCode(65 + index)
            }))
        };
        headerRow.cells.push({
            type: "header", text: "+"
        });

        const categoryRow = {
            rowId: "categories",
            height: 40,
            cells: categories.map((category, index) => ({
                type: "text",
                text: category ?? "---",
                date: moment(category).toDate(),
                format: new Intl.DateTimeFormat("en-US", { month: "short", year: "numeric" })
            }))
        };
        categoryRow.cells.push({
            type: "text", text: ""
        });

        const dataRow = {
            rowId: "data",
            height: 40,
            cells: categories.map((category, index) => ({
                type: "number",
                value: element.model.data[index]?.value ?? null
            }))
        };
        dataRow.cells.push({
            type: "number",
        });

        let rows = [headerRow, categoryRow, dataRow];

        return {
            dataRow, cols, rows
        };
    }

    handleColumnsReordered = async (targetColumnId, columnIds) => {
        const { element } = this.props;

        // Update the element's categories
        element.model.data = reorderArray(element.model.data, columnIds, targetColumnId);

        await element.saveModel();
        this.sheetRef.current.clearSelections();
    }

    handleCellsChanged = changes => {
        const { element } = this.props;

        const data = element.model.data;

        for (const change of changes) {
            if (change.rowId === "data") {
                if (change.columnId >= data.length) {
                    data.push({ value: change.newCell.value, label: { text: "New Value" } });
                } else {
                    // if user deleted the value, revert to the previous value
                    if (!Number.isNaN(change.newCell.value)) {
                        data[change.columnId].value = change.newCell.value;
                    }
                }
                continue;
            }

            if (change.rowId === "categories") {
                if (change.columnId >= data.length) {
                    data.push({ value: this.getRandomValue(), label: { text: change.newCell.text } });
                } else {
                    const labelData = data[change.columnId].label;
                    if (labelData.blocks) {
                        labelData.blocks[0].html = change.newCell.text;
                    } else {
                        labelData.text = change.newCell.text;
                    }
                }
                continue;
            }
        }

        element.saveModel();
    }

    handleContextMenu = (selectedRowIds, selectedColIds, selectionMode, menuOptions) => {
        const { element } = this.props;

        if (selectionMode === "column") {
            return [
                {
                    id: "add-column", label: "Insert Column", handler: () => {
                        for (const colId of selectedColIds.reverse()) {
                            element.model.data.splice(colId, 0, { label: { text: "New Value" }, value: this.getRandomValue() });
                        }
                        element.saveModel();
                    }
                },
                {
                    id: "remove-column", label: "Remove Column", handler: () => {
                        for (const colId of selectedColIds.reverse()) {
                            element.model.data.splice(colId, 1);
                        }
                        element.saveModel();
                    }
                },
                ...menuOptions
            ];
        } else {
            return menuOptions;
        }
    }

    render() {
        const { onClose } = this.props;
        const { cols, rows } = this.getData();

        return (
            <Container>
                <HeaderColumn>
                    {this.DataSourceButton}
                </HeaderColumn>
                <Gap size={3} />
                <ReactGridWrapper
                    ref={this.sheetRef}
                    columns={cols}
                    rows={rows}
                    enableFillHandle
                    enableRangeSelection
                    enableColumnSelection
                    stickyTopRows={1}
                    onColumnsReordered={this.handleColumnsReordered}
                    onCellsChanged={this.handleCellsChanged}
                    onContextMenu={this.handleContextMenu}
                />
                <ClosePanelButton>
                    <WidgetButton icon="close" outlined onClick={onClose} />
                </ClosePanelButton>
            </Container>
        );
    }
}

import React, { Component, useRef } from "react";
import styled from "styled-components";
import { mergeMediaElementModelDefaults } from "../../../../../common/assetUtils";
import { FormatType, PositionType } from "../../../../../common/constants";
import { Button } from "../../../../Components/Button";
import { Dropdown } from "../../../../Components/Dropdown";
import { Icon } from "../../../../Components/Icon";
import { ImageOption, ImageOptionList } from "../../../../Components/ImageOptionList";
import { MenuItem } from "../../../../Components/Menu";
import { Popup, PopupContainer, PopupContent } from "../../../../Components/Popup";
import { Slider } from "../../../../Components/Slider";
import { ToggleSwitch } from "../../../../Components/ToggleSwitch";
import { WithLabel } from "../../../../Components/WithLabel";
import { PropertyPanelContainer, PropertySection } from "../../../../EditorComponents/PropertyPanel";
import CanvasPreview from "../../../../react/components/CanvasPreview";
import { ShowDialog } from "../../../../react/components/Dialogs/BaseDialog";
import { AddAssetsContainer } from "../../../../react/views/AddAssets/index";
import { _ } from "../../../../vendor";
import { ActionNumberAnimationStyles } from "../../elements/elements/ActionText/animations/ActionNumberAnimations";
import { ColorPicker } from "../EditorComponents/ColorPickers/ColorPicker";
import { FormattingDropdown } from "../EditorComponents/FormattingOptions";

export class ActionNumberPropertyPanel extends Component {
    handleAddMedia = () => {
        const { element } = this.props;
        ShowDialog(AddAssetsContainer, {
            callback: model => {
                if (!element.model.image) {
                    element.model.image = {};
                }
                mergeMediaElementModelDefaults(element.model.image, model);
                element.canvas.updateCanvasModel(false);
            },
        });
    }

    render() {
        const { element } = this.props;

        return (
            <PropertyPanelContainer>
                <PropertySection>
                    <WithLabel label="Animation Style">
                        <Popup label={ActionNumberAnimationStyles.findById(element.animationStyle)?.label} contained showArrow>
                            <PopupContent>
                                {closePopup => (
                                    <PopupContainer>
                                        <ImageOptionList value={element.animationStyle} cols={3}
                                            onChange={async value => {
                                                await element.updateModel({ animationStyle: value });
                                                closePopup();
                                                setTimeout(() => element.previewAnimation(), 300);
                                            }}
                                        >
                                            {ActionNumberAnimationStyles.map(animationStyle => (
                                                <ImageOption label={animationStyle.label} value={animationStyle.id} key={animationStyle.id}>
                                                    <AnimationPreview canvas={element.canvas}
                                                        animationStyle={animationStyle.id} />
                                                </ImageOption>
                                            ))}
                                        </ImageOptionList>
                                    </PopupContainer>
                                )}
                            </PopupContent>
                        </Popup>
                    </WithLabel>
                    <Button onClick={() => element.previewAnimation()}>
                        <Icon>play_circle</Icon>
                        Preview Animation
                    </Button>
                    {element.animationStyle != "scroll" && (
                        <WithLabel label="Count From">
                            <Slider min={0} max={1} step={0.1} value={element.startValue}
                                onChange={startValue => element.updateModel({ startValue })}
                                showInput percent
                            />
                        </WithLabel>
                    )}
                </PropertySection>

                <PropertySection>
                    <WithLabel label="Value Color">
                        <ColorPicker value={element.number.colorSet.numberColor.name} canvas={element.canvas}
                            showAuto showPrimary allowColorOnColor
                            onChange={numberColor => element.updateModel({ numberColor }, { refreshStyles: true })}
                        />
                    </WithLabel>
                    <WithLabel label="Value Format">
                        <FormattingDropdown format={element.format}
                            formatOptions={element.formatOptions}
                            onChangeFormat={format => element.updateModel({ format })}
                            onChangeFormatOptions={formatOptions => element.updateModel({ formatOptions })}
                            allowedFormats={[FormatType.PERCENT, FormatType.NUMBER, FormatType.CURRENCY]}
                        />
                    </WithLabel>
                </PropertySection>
                <PropertySection>
                    <WithLabel label="Label Position">
                        <Dropdown value={element.labelPosition}
                            onChange={labelPosition => element.updateModel({ labelPosition })}>
                            <MenuItem value={PositionType.BOTTOM_RIGHT}>Bottom Right</MenuItem>
                            <MenuItem value={PositionType.BOTTOM}>Bottom</MenuItem>
                            <MenuItem value={PositionType.RIGHT}>Right</MenuItem>
                            <MenuItem value={PositionType.TOP}>Top</MenuItem>
                        </Dropdown>
                    </WithLabel>
                    <WithLabel label="Show Divider">
                        <ToggleSwitch value={element.model.showLine}
                            onChange={showLine => element.updateModel({ showLine }, { refreshStyles: true })}
                        />
                    </WithLabel>
                </PropertySection>

                <PropertySection>
                    <WithLabel label="Play Outro Animation" toolTip="Plays an animation when navigating away from this slide">
                        <ToggleSwitch value={element.playOutroAnimation}
                            onChange={value => element.updateModel({ playOutroAnimation: value })}
                        />
                    </WithLabel>
                </PropertySection>

            </PropertyPanelContainer>
        );
    }
}

const AnimationThumbnail = styled.div`
    width: 210px;
    height: ${210 * .5625}px;
    //border: solid 1px #666;
    position: relative;
`;

const CanvasShield = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
`;

function AnimationPreview({ canvas, animationStyle }) {
    let previewRef = useRef(null);

    let handlePreview = onComplete => {
        previewRef.current.slideCanvas.getPrimaryElement().previewAnimation();
    };

    canvas.dataModel.commit();
    let model = _.cloneDeep(canvas.dataModel.attributes);
    model.states[0].primary.animationStyle = animationStyle;

    let scale = 210 / 1280;

    return (
        <AnimationThumbnail>
            <CanvasPreview ref={previewRef}
                slideModel={model}
                scale={scale}
            />
            <CanvasShield onMouseEnter={handlePreview} />
        </AnimationThumbnail>
    );
}


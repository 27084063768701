import React, { useState, useCallback, useEffect } from "react";
import styled, { css } from "styled-components";

import { ds } from "js/core/models/dataService";
import * as geom from "js/core/utilities/geom";
import { FileSource, FileType, validateFileTypeAndSize } from "js/core/utilities/fileSourcesAndTypes";
import { getImageType, isValidMediaType } from "js/core/utilities/imageUtilities";
import { uploadFileAndCreateTask } from "js/core/services/tasks";
import { AssetType, ImageErrorType, TaskState, TaskType } from "common/constants";
import { getVideoAsset } from "js/core/utilities/videoUtilities";
import { trackActivity, trackActivityViaParams } from "js/core/utilities/utilities";
import { _ } from "js/vendor";
import TaskProgressDialog from "js/react/components/Dialogs/TaskProgressDialog";

import getLogger from "../core/logger";
import { ShowErrorDialog } from "../react/components/Dialogs/BaseDialog";

const logger = getLogger();

const DragDropContainer = styled.div`
    width: 100%;
    height: 100%;
    pointer-events: auto;

    ${({ isDragging }) => isDragging ? css`
        background: rgba(80, 187, 230, 0.2);
        border: dashed 2px #999;
    ` : null}
`;

const trackParams = {
    [AssetType.IMAGE]: { category: "Image" },
    [AssetType.LOGO]: { category: "Logo" },
    [AssetType.VIDEO]: { category: "Video" },
    [AssetType.STOCK_VIDEO]: { category: "Video" },
};

const ImageVideoDrop = ({ children, callback, imageAsLogo = false }) => {
    const [isDragging, setIsDragging] = useState(false);
    const [uploadState, setUploadState] = useState(null);
    const [progress, setProgress] = useState(null);
    const [file, setFile] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    // Reset the state if an error occurs
    useEffect(() => {
        if (errorMessage) {
            setProgress(null);
            setFile(null);
            setUploadState(null);
            ShowErrorDialog({
                error: "Sorry, we could not process your request",
                message: errorMessage,
                onClose: () => setErrorMessage(null)
            });
        }
    }, [errorMessage]);

    const handleDragLeave = useCallback(event => {
        event.preventDefault();
        event.stopPropagation();
        setIsDragging(false);
    }, [isDragging]);

    const handleDragOver = useCallback(event => {
        event.preventDefault();
        setIsDragging(true);
    }, [isDragging]);

    const handleOnDrop = useCallback(event => {
        event.preventDefault();
        event.stopPropagation();
        setIsDragging(false);
        handleFileSelect(event);
    }, [isDragging]);

    const onSuccess = asset => {
        trackActivityViaParams({
            ...trackParams[asset.type],
            action: "Added",
        });

        callback(asset);
    };

    const uploadImageOrLogo = file => {
        const fileName = file.name;

        const props = {
            "slide_id": ds.selection.slide?.id,
            "uploaded_from": FileSource.Local,
        };
        if (imageAsLogo) {
            trackActivity("Logo", "Upload", fileName, null, props, { audit: true, skipAmplitude: false });
        } else {
            trackActivity("Image", "Upload", fileName, null, props, { audit: true, skipAmplitude: false });
        }

        let fileType = getImageType(file);
        const imageProperties = {};
        if (imageAsLogo) {
            // force all logos have solid white backgrounds
            imageProperties.hasSolidBackground = true;
            imageProperties.imageBackgroundColor = "#FFFFFF";
        }

        ds.assets
            .getOrCreateImage({
                file,
                name: fileName,
                fileType,
                assetType: imageAsLogo ? AssetType.LOGO : AssetType.IMAGE,
                metadata: {
                    source: "upload"
                },
                ...(imageAsLogo ? {
                    hasSolidBackground: true,
                    imageBackgroundColor: "#FFFFFF"
                } : {}),
            })
            .then(asset => {
                setUploadState(TaskState.FINISHED);
                setProgress(100);

                onSuccess({
                    url: asset.getUrl && asset.getUrl(),
                    ...asset,
                    ...asset.attributes,
                    type: asset.type,
                    id: asset.id,
                    size: new geom.Size(asset.get("w"), asset.get("h")),
                });
            }).catch(err => {
                let label;
                if (err.type === ImageErrorType.SIZE) {
                    label = err.message;
                } else if (err.type === ImageErrorType.BAD_DATA) {
                    label = "We were unable to upload this image. Please try again.";
                } else {
                    label = "We are unable to process and read this image.";
                }
                setErrorMessage(label);
                logger.error(err, "[ImageVideoDrop] uploadFile() failed");
            });
    };

    const getUploadStateText = () => {
        switch (uploadState) {
            case TaskState.PREPARING:
                return "Uploading Media";
            case TaskState.PROCESSING:
                return "Processing Media";
            case TaskState.ERROR:
                return "Error";
            case TaskState.FINISHED:
                return "Your Media Is Ready";
            default:
                return null;
        }
    };

    const uploadVideo = file => {
        uploadFileAndCreateTask(file, TaskType.VIDEO_UPLOAD, async task => {
            if (task.state === TaskState.ERROR) {
                setErrorMessage("We are unable to process and read this video.");
                return;
            }

            if (task.state === TaskState.PREPARING) {
                setProgress(task.stateProgressPercents / 2);
                setUploadState(TaskState.PREPARING);
                return;
            }

            if (task.state === TaskState.PROCESSING) {
                setProgress(task.stateProgressPercents / 2 + 50);
                setUploadState(TaskState.PROCESSING);
                return;
            }

            if (task.state === TaskState.FINISHED) {
                setProgress(100);
                setUploadState(TaskState.FINISHED);

                getVideoAsset(null, task.videoAssetId)
                    .then(({ asset, error }) => {
                        if (error) {
                            setErrorMessage(error);
                        } else {
                            // When we drop an element on the canvas, we want to auto-play it
                            asset.assetProps.autoPlay = true;

                            onSuccess({
                                ...asset,
                                // We destructured asset.attributes from image so we need to do the same for video
                                ...asset.attributes
                            });
                        }
                    });
                return;
            }
        }).catch(err => {
            setErrorMessage("We are unable to process and read this video.");
            logger.error(err, "[ImageVideoDrop] uploadVideo() failed");
        });
    };

    const handleFileSelect = useCallback(event => {
        const files = (event.target?.files || event.dataTransfer?.files || event.clipboardData?.files);

        // Allows only one file to be uploaded
        const selectedFile = files[0];

        const errorMessage = validateFileTypeAndSize(selectedFile, [FileType.Video, FileType.Image]);
        if (errorMessage) {
            logger.error(errorMessage, "[ImageVideoDrop] handleFileSelect() failed");
            setErrorMessage(errorMessage);
        } else {
            handleFilePicked(selectedFile);
        }
    }, [isDragging]);

    const handleFilePicked = file => {
        setUploadState(TaskState.PREPARING);
        setProgress(0);
        setFile(file);

        const { isValidImage, isValidVideo } = isValidMediaType(file);

        if (isValidImage) {
            uploadImageOrLogo(file);
        } else if (isValidVideo) {
            uploadVideo(file);
        } else {
            setErrorMessage("Invalid file type");
            logger.error("[ImageVideoDrop] handleFilePicked() failed");
        }
    };

    if (file && uploadState !== TaskState.FINISHED) {
        return (
            <TaskProgressDialog
                label={file.name}
                titleLabel={getUploadStateText()}
                progress={progress}
                taskState={uploadState}
            />
        );
    }

    return (
        <DragDropContainer
            isDragging={isDragging}
            onDragLeave={handleDragLeave}
            onDragOver={handleDragOver}
            onDrop={handleOnDrop}
        >
            {children}
        </DragDropContainer>
    );
};

export default ImageVideoDrop;

import React from "react";

import {
    ShowDialog,
    ShowWarningDialog,
    ShowConfirmationDialog
} from "../../../../react/components/Dialogs/BaseDialog";
import LinkDataDialog from "../../../../react/views/LinkData/LinkDataDialog";
import { MenuItem } from "../../../../Components/Menu";
import { Icon } from "../../../../Components/Icon";

export function LinkedDataMenu({ element, closeMenu = () => { } }) {
    const hasDataSourceLink = element.hasDataSourceLink();

    const setData = processedData => {
        processedData.initialImport = true;
        element._useUpdatedDataSource(processedData);
        element.canvas.updateCanvasModel(false);
    };

    const unlinkData = () => {
        element.removeDataSource();
        element.model.dataSourceLink = null;
        element.canvas.updateCanvasModel(false);
    };

    const handleRefreshData = async () => {
        closeMenu();

        try {
            const latestData = await element.getDataSourceManager(element.model.dataSourceLink.dataSourceId).refreshData();
            if (latestData?.oauthDisconnected) {
                unlinkData();
            }
        } catch {
            ShowWarningDialog({
                title: "Sorry!",
                message: "We were unable to refresh data for this data source. Please try again.",
            });
        }
    };

    const handleUnlinkData = () => {
        closeMenu();

        ShowConfirmationDialog({
            title: "Are you sure you want to unlink this data?",
            message: "Once unlinked your data will no longer update when your source file is updated.",
            okButtonLabel: "Unlink",
            acceptCallback: () => unlinkData()
        });
    };

    const handleViewData = () => {
        closeMenu();

        ShowDialog(LinkDataDialog, {
            element,
            viewCurrLinkedData: true,
            handleSelectedData: data => setData(data),
            handleDisconnect: () => unlinkData()
        });
    };

    const handleImportNewData = () => {
        closeMenu();

        ShowDialog(LinkDataDialog, {
            element,
            handleSelectedData: data => setData(data),
            handleDisconnect: () => unlinkData()
        });
    };

    if (hasDataSourceLink) {
        return (<>
            <MenuItem onClick={handleRefreshData} divider>
                <Icon>refresh</Icon>
                Refresh Data
            </MenuItem>
            <MenuItem onClick={handleUnlinkData}>
                <Icon>link_off</Icon>
                Unlink Data
            </MenuItem>
            <MenuItem onClick={handleViewData} divider>
                <Icon>visibility</Icon>
                Relink Data...
            </MenuItem>
            <MenuItem onClick={handleImportNewData}>
                <Icon>import_export</Icon>
                Import New...
            </MenuItem>
        </>);
    }

    return (<>
        <MenuItem onClick={handleImportNewData}>
            <Icon>import_export</Icon>
            Import Data
        </MenuItem>
    </>);
}

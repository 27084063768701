import React, { Component } from "react";
import { PropertyPanelContainer } from "../../../../EditorComponents/PropertyPanel";
import { BaseElementSelection } from "../ElementSelections/BaseElementSelection";
import { PropertyPanelHeader } from "../../../../editor/ElementEditor/PropertyPanelHeader";
import { TextElementSelection } from "../ElementSelections/TextElementSelection";

export class AttributionPropertyPanel extends Component {
    render() {
        const { element } = this.props;

        return (
            <PropertyPanelContainer>
                <PropertyPanelHeader>Attribution</PropertyPanelHeader>
            </PropertyPanelContainer>
        );
    }
}

export class AttributionSelection extends TextElementSelection {
    get canDelete() {
        return true;
    }

    handleDeleteElement = () => {
        const { canvas } = this.props;
        canvas.model.layout.showElementAttribution = false;
        canvas.markStylesAsDirty();
        canvas.updateCanvasModelWithErrorDialog(true);
    }
}

import React from "reactn";

import { _ } from "js/vendor";
import { app } from "js/namespaces";
import { getStaticUrl } from "js/config";
import * as geom from "js/core/utilities/geom";
import { getClipPath, SVGGroup } from "js/core/utilities/svgHelpers";
import { ShapeType } from "common/constants";

import { BaseElement } from "./BaseElement";
import { DivDecorationElement } from "./SVGElement";
import { DeviceFrames } from "../DeviceFrames";

class Frame extends BaseElement {
    get _canSelect() {
        return false;
    }

    get _canRollover() {
        return false;
    }

    get clip() {

    }
}

class BoxFrame extends Frame {
    _build() {
        this.frame = this.addElement("frame", () => DivDecorationElement);
    }

    get layer() {
        return -1;
    }

    _calcProps(props, options) {
        const { size, children } = props;

        const frameProps = this.frame.calcProps(size);
        frameProps.bounds = new geom.Rect(0, 0, size);

        switch (this.options.frameStyle) {
            case "light":
                this.frame.updateStyles({
                    strokeColor: "white",
                    shadow: {
                        blur: 14,
                        offsetX: 0,
                        offsetY: 0,
                        opacity: 0.2
                    }
                });
                break;
            case "dark":
            default:
                this.frame.updateStyles({
                    strokeColor: "black"
                });
                break;
        }

        this.frame.updateStyles({ strokeWidth: this.canvas.styleSheet.decorationVariables[this.canvas.styleSheet.BoxFrame.strokeWidth.substr(1)] });
        const clipBounds = new geom.Rect(0, 0, size).deflate(this.frame.styles.strokeWidth);

        return {
            size,
            clipBounds
        };
    }
}

class CircleFrame extends Frame {
    _build() {
        this.frame = this.addElement("frame", () => DivDecorationElement);
    }

    get layer() {
        return -1;
    }

    _calcProps(props, options) {
        const { size } = props;
        const frameProps = this.frame.createProps();
        frameProps.bounds = new geom.Rect(0, 0, size.square()).centerInRect(new geom.Rect(0, 0, size));

        this.frame.styles.borderRadius = "50%";
        this.frame.styles.strokeWidth = this.canvas.styleSheet.decorationVariables[this.canvas.styleSheet.BoxFrame.strokeWidth.substr(1)];

        switch (this.options.frameStyle) {
            case "circle-light":
                this.frame.updateStyles({
                    strokeColor: "white",
                    shadow: {
                        blur: 14,
                        offsetX: 0,
                        offsetY: 0,
                        opacity: 0.2
                    }
                });
                break;
            case "circle-dark":
                this.frame.updateStyles({
                    strokeColor: "black"
                });
                break;
            default:
                this.frame.styles.strokeWidth = 0;
                break;
        }

        const clipBounds = frameProps.bounds;
        const frameClipPath = getClipPath("circle", clipBounds.size, 0, false, this.canvas.getScale());

        return { size, clipBounds, frameClipPath };
    }
}

class ImageFrame extends Frame {
    get hasClipPath() {
        return this.frameDef.clipPath;
    }

    get frameDef() {
        // note: added trim because there were a few device id's with extra spaces that i repaired
        let def = _.find(DeviceFrames, { id: this.model.frame.trim() || "iphone_x" });
        if (!def) {
            def = DeviceFrames.find(device => device.file != "");
        }
        return def;
    }

    get frameURL() {
        return getStaticUrl("/images/frames/" + this.frameDef.file);
    }

    _load() {
        return new Promise(resolve => {
            const image = new Image();
            image.src = this.frameURL;

            image.onload = () => {
                this.imageSize = new geom.Size(image.naturalWidth, image.naturalHeight);
                resolve();
            };
        });
    }

    _calcProps(props, options) {
        const { size } = props;

        const scale = geom.fitImageToRect(this.imageSize, size);
        const scaledImageSize = this.imageSize.scale(scale);
        const scaledCornerRadius = this.frameDef.cornerRadius * scale;

        return {
            size: scaledImageSize,
            imageScale: scale,
            clipBounds: new geom.Rect(this.frameDef.clip).multiply(scale),
            customClip: this.frameDef.clipPath,
            frameClipPath: this.frameDef.cornerRadius ? getClipPath("rect", scaledImageSize, scaledCornerRadius, false, this.canvas.getScale()) : null,
            clipScale: this.frameDef.clipScale
        };
    }

    renderChildren(transition) {
        let props = this.calculatedProps;
        return (<SVGGroup key={this.id}>
            <image href={this.frameURL} style={{ transform: `scale(${props.imageScale})` }} />
        </SVGGroup>);
    }
}

export { BoxFrame, CircleFrame, ImageFrame };

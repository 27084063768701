const obsoleteTemplates = require("./obsoleteTemplates");
const devTemplates = require("./devTemplates");
const classicTemplates = require("./classicTemplates");
const variations = require("./variations");
const chartUtils = require("./chartUtils");
const templates = require("./templates");
const tableUtils = require("./tableUtils");

module.exports = {
    obsoleteTemplates,
    devTemplates,
    classicTemplates,
    variations,
    chartUtils,
    templates,
    tableUtils
};

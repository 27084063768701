import React, { Component } from "react";

import { ToggleSwitch } from "../../../../Components/ToggleSwitch";
import { ListStyleType, ResizeDirection, TextStyleType } from "../../../../../common/constants";
import { ShowErrorDialog } from "../../../../react/components/Dialogs/BaseDialog";
import { ImageOption, ImageOptionList } from "../../../../Components/ImageOptionList";
import { WithLabel } from "../../../../Components/WithLabel";
import { NumericStepper } from "../../../../Components/NumericStepper";
import { Icon } from "../../../../Components/Icon";
import { StaticImage } from "../../../../Components/StaticImage";

import { PropertyPanelContainer, PropertySection } from "../../../../EditorComponents/PropertyPanel";
import { CollectionItemElementSelection } from "../ElementSelections/CollectionItemElementSelection";
import { CollectionElementControlBar } from "../ElementControlBars/CollectionElementControlBar";
import { _ } from "../../../../vendor";
import { defaultDragResizeProps } from "../../../../editor/PresentationEditor/DragElementManager";
import { ColorPicker } from "../EditorComponents/ColorPickers/ColorPicker";
import { ImagePopup } from "../../../../Components/ImagePopup";
import { getValueOrMixed } from "../../../../core/utilities/utilities";
import { IconPickerPopup } from "../../../../Components/IconPicker";
import { DEFAULT_LIST_ICONS } from "../ElementDefaultOverlays/ListDecorationDefaultOverlay";
import { Popup, PopupContent, PopupPreview } from "../../../../Components/Popup";
import { MixedValue } from "../../../../Components/MixedValue";

export class TextListProperties extends Component {
    renderPopupPreview = listStyle => {
        const previewComponents = {
            [ListStyleType.BULLET]: <Icon>format_list_bulleted</Icon>,
            [ListStyleType.NUMBERED]: <Icon>pin</Icon>,
            [ListStyleType.LETTERED]: <Icon>abc</Icon>,
            [ListStyleType.TEXT]: <Icon>notes</Icon>,
            [ListStyleType.CHECKBOX]: <Icon>check</Icon>,
            [ListStyleType.ICON]: <Icon>star</Icon>,
            "mixed": <MixedValue />
        };

        const preview = previewComponents[listStyle] ?? <MixedValue />;

        return (
            <PopupPreview>
                {preview}
            </PopupPreview>
        );
    }

    render() {
        const { element } = this.props;
        const listBlocks = element.model.text.blocks.filter(block => block.textStyle === TextStyleType.BULLET_LIST && block.indent == 0);

        if (!listBlocks || listBlocks.length === 0) {
            return null;
        }

        const listStyle = getValueOrMixed(listBlocks, "listStyle");
        const bulletColor = getValueOrMixed(listBlocks, "bulletColor") ?? "theme";
        const iconValue = listStyle === ListStyleType.ICON ? getValueOrMixed(listBlocks, "listIconId") : "star";

        return (
            <PropertySection>
                <WithLabel label="List Style">
                    <Popup contained showArrow>
                        {this.renderPopupPreview(listStyle)}
                        <PopupContent>
                            {closePopup => (
                                <PropertyPanelContainer>
                                    <PropertySection>
                                        <ImageOptionList value={listStyle}
                                            previewSize={30}
                                            size={40}
                                            onChange={value => {
                                                listBlocks.forEach(block => {
                                                    block.listStyle = value;
                                                });
                                                element.saveModel();
                                                closePopup();
                                            }}
                                            gap={10} cols={6}
                                            icon="format_list_bulleted"
                                        >
                                            <ImageOption value={ListStyleType.BULLET} label="Bullet">
                                                <Icon>format_list_bulleted</Icon>
                                            </ImageOption>
                                            <ImageOption value={ListStyleType.NUMBERED} label="Number">
                                                <Icon>pin</Icon>
                                            </ImageOption>
                                            <ImageOption value={ListStyleType.LETTERED} label="Letter">
                                                <Icon>abc</Icon>
                                            </ImageOption>
                                            <ImageOption value={ListStyleType.TEXT} label="Text">
                                                <Icon>notes</Icon>
                                            </ImageOption>
                                            <ImageOption value={ListStyleType.CHECKBOX} label="Check">
                                                <Icon>check</Icon>
                                            </ImageOption>
                                            <ImageOption value={ListStyleType.ICON} label="Icon">
                                                <IconPickerPopup canChooseIcon icons={DEFAULT_LIST_ICONS} showIconPreview value={iconValue} showArrow={false} width={330}
                                                    onChange={iconId => {
                                                        listBlocks.forEach(block => {
                                                            block.listIconId = iconId;
                                                            block.listStyle = ListStyleType.ICON;
                                                        });
                                                        element.model.listStyle = ListStyleType.ICON;
                                                        element.saveModel();
                                                        closePopup();
                                                    }}
                                                />
                                            </ImageOption>
                                        </ImageOptionList>
                                    </PropertySection>
                                </PropertyPanelContainer>
                            )}
                        </PopupContent>
                    </Popup>
                    {listStyle?.equalsAnyOf(ListStyleType.BULLET, ListStyleType.NUMBERED, ListStyleType.LETTERED, ListStyleType.ICON) &&
                        <ColorPicker canvas={element.canvas}
                            showColorful showBackgroundColors
                            value={bulletColor}
                            onChange={color => {
                                listBlocks.forEach(block => {
                                    block.bulletColor = color;
                                });
                                element.saveModel();
                            }}
                        />
                    }
                </WithLabel>
                {/*{listStyle === ListStyleType.NUMBERED &&*/}
                {/*    <WithLabel label="Start Numbering At" flex>*/}
                {/*        <NumericStepper value={element.model.startNum}*/}
                {/*                        min={1} max={9999} step={1}*/}
                {/*                        onChange={value => element.updateModel({ startNum: value })}*/}
                {/*        />*/}
                {/*    </WithLabel>*/}
                {/*}*/}
            </PropertySection>
        );
    }
}

export class TextListPropertyPanel extends Component {
    handleSetColumnsCount = async value => {
        const { element } = this.props;

        try {
            // Ensure the layout fit set to true to prevent layouter to force render
            element.canvas.layouter.setLastLayoutFit(true);

            element.distributeBullets(value);

            await element.canvas.refreshCanvas();
            await element.canvas.saveCanvasModel();
        } catch (err) {
            ShowErrorDialog({
                title: "Sorry, we couldn't make this change",
                message: "Unable to fit the current blocks into the desired number of columns."
            });
            await element.canvas.revertCanvasModel();
        }
    }

    render() {
        const { element, showColumnControls = true } = this.props;

        let listBlocks = element.allBlockModels.filter(block => block.listStyle && !block.indent);
        let bulletColor = element.collectionColor;
        if (listBlocks.some(block => block.bulletColor && block.bulletColor !== bulletColor)) {
            bulletColor = "mixed";
        }

        let iconValue = element.listStyle == ListStyleType.ICON ? getValueOrMixed(listBlocks, "listIconId") : "star";

        return (
            <PropertyPanelContainer>
                <PropertySection>
                    <WithLabel label="List Style" above left>
                        <ImageOptionList value={element.listStyle}
                            onChange={value => {
                                listBlocks.forEach(block => {
                                    block.listStyle = value;
                                });
                                element.model.listStyle = value;
                                element.saveModel();
                            }}
                            gap={10} cols={5}
                        >
                            <ImageOption value={ListStyleType.BULLET} label="Bullet">
                                <Icon>format_list_bulleted</Icon>
                            </ImageOption>
                            <ImageOption value={ListStyleType.NUMBERED} label="Number">
                                <Icon>pin</Icon>
                            </ImageOption>
                            <ImageOption value={ListStyleType.LETTERED} label="Letter">
                                <Icon>abc</Icon>
                            </ImageOption>
                            <ImageOption value={ListStyleType.TEXT} label="Text">
                                <Icon>notes</Icon>
                            </ImageOption>
                            <ImageOption value={ListStyleType.CHECKBOX} label="Check">
                                <Icon>check</Icon>
                            </ImageOption>
                            <ImageOption value={ListStyleType.ICON} label="Icon">
                                <IconPickerPopup canChooseIcon icons={DEFAULT_LIST_ICONS} showIconPreview value={iconValue} showArrow={false} width={330}
                                    onChange={iconId => {
                                        listBlocks.forEach(block => {
                                            block.listIconId = iconId;
                                            block.listStyle = ListStyleType.ICON;
                                        });
                                        element.model.listStyle = ListStyleType.ICON;
                                        element.saveModel();
                                    }}
                                />
                            </ImageOption>
                        </ImageOptionList>
                    </WithLabel>
                </PropertySection>
                {element.model.listStyle.equalsAnyOf(ListStyleType.BULLET, ListStyleType.LETTERED, ListStyleType.NUMBERED, ListStyleType.ICON) &&
                    <PropertySection>
                        <WithLabel label="Bullet Color">
                            <ColorPicker canvas={element.canvas}
                                showColorful showBackgroundColors
                                value={bulletColor}
                                onChange={color => {
                                    if (element.itemElements) {
                                        for (let item of element.itemElements) {
                                            item.model.color = null;
                                        }
                                    }
                                    element.allBlockModels.forEach(block => {
                                        block.bulletColor = null;
                                    });

                                    element.updateModel({ collectionColor: color });
                                }}
                            />
                        </WithLabel>
                    </PropertySection>
                }
                {showColumnControls && (
                    <PropertySection>
                        <WithLabel label="Columns" flex>
                            <ImageOptionList value={element.textColumnsCount}
                                onChange={this.handleSetColumnsCount}
                                size={34} gap={5}
                            >
                                <ImageOption value={1}><Icon><StaticImage src="/images/ui/cols_1.svg" /></Icon></ImageOption>
                                <ImageOption value={2}><Icon><StaticImage src="/images/ui/cols_2.svg" /></Icon></ImageOption>
                                <ImageOption value={3}><Icon><StaticImage src="/images/ui/cols_3.svg" /></Icon></ImageOption>
                            </ImageOptionList>
                        </WithLabel>
                        <WithLabel label="Show Column Headers" flex>
                            <ToggleSwitch value={element.model.showColumnHeaders}
                                onChange={value => element.updateModel({ showColumnHeaders: value }, { transition: true })} />
                        </WithLabel>
                    </PropertySection>
                )}
                {element.model.listStyle === ListStyleType.NUMBERED && !element.model.showColumnHeaders &&
                    <PropertySection>
                        <WithLabel label="Start Numbering At" flex>
                            <NumericStepper value={element.model.startNum}
                                min={1} max={9999} step={1}
                                onChange={value => element.updateModel({ startNum: value })}
                            />
                        </WithLabel>
                    </PropertySection>
                }

            </PropertyPanelContainer>
        );
    }
}

export class TextListMediaColumnSelection extends CollectionItemElementSelection {
    get canDelete() {
        return false;
    }

    get canResize() {
        return true;
    }

    get dragResizeProps() {
        const { element } = this.props;

        let initialWidth;
        const minSize = 100;
        const maxSize = 1000;

        return {
            ...defaultDragResizeProps,
            resizeDirections: [ResizeDirection.RIGHT],
            onDragStart: async () => {
                initialWidth = element.model.size ?? element.bounds.width;
            },
            onDrag: async ({ dragOffset }) => {
                element.model.size = Math.round(Math.clamp(initialWidth + dragOffset.x, minSize, maxSize));
                element.refreshElement();
            }
        };
    }
}

export class TextListControlBar extends CollectionElementControlBar {
    get canAddNewItem() {
        const { element } = this.props;
        return element.textColumnsCount > 0 || element.maxItemCount > element.itemCollection.length;
    }

    onAddNewItem = async () => {
        const { element, selectionLayerController } = this.props;

        if (!this.canAddNewItem) {
            return;
        }

        await selectionLayerController.setSelectedElements([]);

        const lastColumn = _.last(element.itemElements.filter(element => !element.isMediaColumn));
        if (!lastColumn) {
            element.model.autoArrangeColumnsCount = element.itemCollection.length + 1;
            const { id } = element.addItem();
            await element.canvas.updateCanvasModel(true);
            const firstBlockId = element.elements[id].text.textModel.blocks[0].id;
            await selectionLayerController.selectTextElementBlock(element.elements[id].text, firstBlockId);
            return;
        }

        const lastBlock = _.last(lastColumn.text.textModel.blocks.filter(block => block.listStyle && !block.indent));

        const { id } = lastColumn.text.addBlock({
            listIconId: lastBlock.listIconId,
            textStyle: TextStyleType.BULLET_LIST,
            listStyle: lastBlock.listStyle,
            listDecorationStyle: lastBlock.listDecorationStyle,
            useThemedListDecoration: lastBlock.useThemedListDecoration
        });

        await element.canvas.updateCanvasModel(true);
        await selectionLayerController.selectTextElementBlock(lastColumn.text, id);
    }
}


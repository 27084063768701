export default class Cache {
    constructor(ttlSeconds = 10) {
        this.ttlSeconds = ttlSeconds;
        this.cache = {};
        this.hitCount = 0;
        this.missCount = 0;

        if (this.ttlSeconds) {
            // Running cache clean up in a separate loop in order to make get() and set() as
            // ligthweight as possible
            this.cacheCleanUpInterval = setInterval(() => this.clear(), this.ttlSeconds * 1000);
        }
    }

    destroy() {
        if (this.ttlSeconds) {
            clearInterval(this.cacheCleanUpInterval);
        }
    }

    clear(forceClear = false) {
        Object.entries(this.cache).forEach(([key, cache]) => {
            if (forceClear || (Date.now() - cache.cachedAt >= this.ttlSeconds * 1000)) {
                delete this.cache[key];
            }
        });
    }

    get(key) {
        if (!this.cache[key]) {
            this.missCount++;
            return null;
        }

        this.hitCount++;

        this.cache[key].cachedAt = Date.now();

        return this.cache[key].value;
    }

    set(key, value) {
        this.cache[key] = {
            value,
            cachedAt: Date.now()
        };
    }
}

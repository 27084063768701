import * as geom from "js/core/utilities/geom";
import { _ } from "js/vendor";

import { BaseElement } from "../../base/BaseElement";
import { TextElement } from "../../base/Text/TextElement";
import { SVGRectElement } from "../../base/SVGElement";
import { ConnectorItemLabelSelection } from "../../../Editor/ElementSelections/ConnectorItemLabelSelection";

export default class ConnectorItemLabel extends BaseElement {
    get _canSelect() {
        return true;
    }

    get _canRollover() {
        return false;
    }

    get _passThroughSelection() {
        return false;
    }

    get autoWidth() {
        return true;
    }

    get autoHeight() {
        return true;
    }

    get bindTo() {
        return "text";
    }

    get matchUserScaleWithSiblings() {
        return false;
    }

    get position() {
        return this.model.position != undefined ? this.model.position : 0.5;
    }

    get offset() {
        return this.model.offset != undefined ? this.model.offset : 0;
    }

    get showBackground() {
        return this.offset === 0;
    }

    getElementSelection() {
        return this.options.elementSelection ?? ConnectorItemLabelSelection;
    }

    _build() {
        if (this.showBackground) {
            this.background = this.addElement("background", () => SVGRectElement);
        }

        this.text = this.addElement("text", () => TextElement, {
            autoWidth: true,
            autoHeight: true,
            spellcheck: false,
            canEdit: this.options.canEdit ?? true,
            backgroundElement: this.background,
        });
    }

    _calcProps(props, options) {
        const { size } = props;

        const textProps = this.text.calcProps(size);
        textProps.bounds = new geom.Rect(10, 10, textProps.size);

        if (this.showBackground) {
            const backgroundProps = this.background.calcProps(size);
            backgroundProps.bounds = textProps.bounds.inflate(10);
        }

        return { size: textProps.size.inflate(10) };
    }

    _applyColors() {
        if (this.showBackground) {
            this.background.colorSet.fillColor = this.canvas.getBackgroundColor();
            this.background.colorSet.backgroundColor = this.background.colorSet.fillColor;
            this.background.colorSet.strokeColor = null;
        }
    }
}

import styled from "styled-components";

export const ElementRolloverBox = styled.div.attrs(() => ({}))`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    border: dotted 1px rgba(80, 187, 230, 0.6);
`;

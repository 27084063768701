import { AssetType, AuthoringElementType, AuthoringShapeType, CalloutType } from "common/constants";
import * as geom from "js/core/utilities/geom";
import { _ } from "js/vendor";

import { tinycolor } from "js/vendor";
import { AuthoringElementContainerPropertyPanel } from "../../Editor/ElementPropertyPanels/AuthoringUI";
import { CollectionItemElement } from "../base/CollectionElement";
import { AuthoringContentElement } from "./authoring/AuthoringContent";
import { AuthoringPathElement } from "./authoring/AuthoringPath";
import { AuthoringRadialBar } from "./authoring/AuthoringRadialBar";
import {
    AuthoringShapeElement,
} from "./authoring/AuthoringShape";
import { AuthoringSvgElement } from "./authoring/AuthoringSvg";
import { AuthoringVideo } from "./authoring/AuthoringVideo";
import { AuthoringElementsGroup } from "./AuthoringElementsGroup";
import { BulletCallout } from "./Callouts/BulletCallout";
import { CalloutPropertyPanel } from "./Callouts/CalloutUI";
import { FlexCircleCallout } from "./Callouts/FlexCircleCallout";
import { LetteredCallout } from "./Callouts/LetteredCallout";
import { MediaAndTextCallout } from "./Callouts/MediaAndTextCallout";
import { MediaCallout } from "./Callouts/MediaCallout";
import { NumberedCallout } from "./Callouts/NumberedCallout";
import { ShapeCallout } from "./Callouts/ShapeCallout";
import { TextCallout } from "./Callouts/TextCallout";
import { TimelineTextCallout } from "./Callouts/TimelineTextCallout";
import { Footer } from "./Footer";
import { Header } from "./Header";

export class AuthoringElementContainer extends CollectionItemElement {
    static get schema() {
        return {
            isLocked: false,
            shadow: {},
            opacity: 100
        };
    }

    get canDragWhenChildSelected() {
        return this.childElement.canDragWhenChildSelected ?? false;
    }

    get canChangeTextDirection() {
        return this.childElement.canChangeTextDirection;
    }

    get limitSize() {
        return this.childElement.limitSize ?? false;
    }

    get minWidth() {
        return this.childElement.minWidth;
    }

    get minHeight() {
        return this.childElement.minHeight;
    }

    get maxWidth() {
        return this.childElement.maxWidth ?? Infinity;
    }

    get maxHeight() {
        return this.childElement.maxHeight ?? Infinity;
    }

    setUserWidth(width) {
        this.childElement?.setUserWidth(width);
    }

    get textWidth() {
        return this.childElement.textWidth ?? null;
    }

    get hasText() {
        return !!this.childElement.text;
    }

    get connectorsToNode() {
        return this.childElement.connectorsToNode ?? null;
    }

    get connectorsFromNode() {
        return this.childElement.connectorsFromNode ?? null;
    }

    get canRollover() {
        return this.childElement.canRollover ?? true;
    }

    get isCallout() {
        return this.childElement.isCallout ?? false;
    }

    get isAuthoringElement() {
        return true;
    }

    get referencePoint() {
        return this.childElement.referencePoint ?? null;
    }

    get referencePointAnchor() {
        return this.childElement.referencePointAnchor ?? null;
    }

    get availableAnchorPoints() {
        return this.childElement.availableAnchorPoints;
    }

    get anchorBounds() {
        return this.childElement.anchorBounds.offset(this.bounds.position);
    }

    getAnchorPoint(connector, anchor, end) {
        if (!this.bounds) return new geom.Point(this.model.x, this.model.y);
        return this.childElement.getAnchorPoint(connector, anchor, end).offset(this.bounds.position);
    }

    get widthKey() {
        return this.childElement.widthKey;
    }

    get canSelect() {
        return true;
    }

    get canSelectChildElements() {
        const selectionLayerController = this.canvas.selectionLayerController;
        if (!selectionLayerController) {
            return false;
        }

        const isChildSelected = selectionLayerController.selectedElements.some(element => element.isChildOf(this));
        if (isChildSelected) {
            return true;
        }

        const isSelfSelected = selectionLayerController.selectedElements.length === 1 && selectionLayerController.selectedElements.some(element => element === this);
        if (isSelfSelected) {
            if (this.model.type === AuthoringElementType.COMPONENT && this.model.componentType) {
                return false;
            }

            return true;
        }

        return false;
    }

    get selectionPadding() {
        return this.childElement.selectionPadding;
    }

    get connectionShape() {
        return this.childElement.connectionShape;
    }

    get allowDecorationStyles() {
        return false;
    }

    get lockAspectRatio() {
        return this.childElement.lockAspectRatio ?? false;
    }

    get preserveAspectRatioOnCornerResize() {
        return this.childElement.preserveAspectRatioOnCornerResize ?? false;
    }

    get groupId() {
        if (this.model.groupIds.length === 0) {
            return null;
        }

        return this.model.groupIds[0];
    }

    set groupId(value) {
        if (value) {
            this.model.groupIds.unshift(value);
        } else {
            this.model.groupIds.shift();
        }
    }

    getGroupElements() {
        if (!this.groupId) {
            return [this];
        }

        return Object.values(this.parentElement.elements).filter(el => el.groupId === this.groupId);
    }

    get canChangeDirection() {
        return !!this.childElement.canChangeDirection;
    }

    get canReshape() {
        return this.childElement.canReshape;
    }

    get canDelete() {
        return false;
    }

    get canMultiSelect() {
        return true;
    }

    getElementSelection() {
        // Don't render ElementSelection for AuthoringElements because they are handled in AuthoringLayer
        return null;
    }

    getElementPropertyPanel() {
        if (this.isCallout) {
            return CalloutPropertyPanel;
        } else {
            return AuthoringElementContainerPropertyPanel;
        }
    }

    isChildOf(element) {
        if (element instanceof AuthoringElementsGroup) {
            return this.groupId === element.groupId;
        }

        return super.isChildOf(element);
    }

    onResize(boundsBeforeResize) {
        if (this.childElement.onResize) {
            return this.childElement.onResize(boundsBeforeResize);
        }
    }

    getSelectionElement() {
        return this.childElement;
    }

    get name() {
        if (this.model.name) return this.model.name;

        switch (this.model.type) {
            case AuthoringElementType.SHAPE:
                if (this.childElement.isTextBox) {
                    return "Text Box";
                } else {
                    return this.childElement?.shape?.toTitleCase();
                }
            case AuthoringElementType.CONTENT:
                if (this.childElement.assetType == AssetType.ICON) {
                    return this.childElement.model.content_value?.toTitleCase() || "Icon";
                } else {
                    return this.childElement.assetElement?.model.assetName || "Image";
                }
            case AuthoringElementType.CALLOUT:
                if (this.getRootElement().type == "NodeDiagram") {
                    return "Node";
                } else {
                    return "Callout";
                }
            default:
                return this.model?.type?.toTitleCase();
        }
    }

    getElementFactory() {
        switch (this.model.type) {
            case AuthoringElementType.SHAPE:
                return () => AuthoringShapeElement;
            case AuthoringElementType.PATH:
                return () => AuthoringPathElement;
            case AuthoringElementType.CONTENT:
                return () => AuthoringContentElement;
            case AuthoringElementType.CALLOUT:
                switch (this.model.calloutType) {
                    case CalloutType.CONTENT:
                        return () => MediaCallout;
                    case CalloutType.BULLET_TEXT:
                        return () => BulletCallout;
                    case CalloutType.CONTENT_AND_TEXT:
                        return () => MediaAndTextCallout;
                    case CalloutType.NUMBERED_TEXT:
                        return () => NumberedCallout;
                    case CalloutType.LETTERED_TEXT:
                        return () => LetteredCallout;
                    case CalloutType.TEXT:
                        return () => TextCallout;
                    case CalloutType.FLEX_CIRCLE:
                        return () => FlexCircleCallout;
                    case CalloutType.TIMELINE_TEXT:
                        return () => TimelineTextCallout;
                    default:
                        return () => ShapeCallout;
                }
            case AuthoringElementType.COMPONENT:
                if (this.model.componentType) {
                    return () => this.canvas.elementManager.get(this.model.componentType);
                } else {
                    return () => AuthoringShapeElement;
                }
            case AuthoringElementType.HEADER:
                return () => Header;
            case AuthoringElementType.FOOTER:
                return () => Footer;
            case AuthoringElementType.SVG:
                return () => AuthoringSvgElement;
            case AuthoringElementType.RADIAL_BAR:
                return () => AuthoringRadialBar;
            case AuthoringElementType.VIDEO:
                return () => AuthoringVideo;
        }

        throw new Error(`Unknown element type ${this.model.type}, model: ${JSON.stringify(this.model)}`);
    }

    containsPoint(pt, isSelected) {
        switch (this.model.type) {
            case AuthoringElementType.SHAPE:
            case AuthoringElementType.PATH:
                if (this.model.shape !== AuthoringShapeType.RAW_SVG) {
                    return this.childElement.containsPoint(pt, isSelected);
                }
            default:
                return this.selectionBounds.contains(pt);
        }
    }

    get mediaElement() {
        return this.childElement.mediaElement;
    }

    _build() {
        if (!this.model.groupIds) {
            this.model.groupIds = [];
        }

        this.childElement = this.addElement("childElement", this.getElementFactory(), {
            // NOTE: this.model.element may be undefined, in this case element and container will be sharing model
            model: this.model.element,
            getGroupElements: () => this.getGroupElements(),
            doubleClickToSelect: [AuthoringElementType.PATH, AuthoringElementType.COMPONENT].includes(this.model.type),
            singleCellGridLayout: true,
            canChangeTextDirection: this.options.canChangeTextDirection,
            canChangeColor: this.options.canChangeColor
        });
    }

    getHTMLFilter() {
        switch (this.model.shadow) {
            case "drop":
                return "drop-shadow(3px 5px 6px rgba(0,0,0,.4)";
            case "soft":
                return "drop-shadow(0px 0px 10px rgba(0,0,0,.4)";
            case "block":
                return "drop-shadow(10px 10px 0px rgba(0,0,0,.5";
            default:
                if (this.model.shadow?.hasOwnProperty("color") && this.model.shadow.color != "rgba(0, 0, 0, 0)") {
                    let shadowColor = tinycolor(this.model.shadow.color).setAlpha(this.model.shadow.opacity).toRgbString();
                    return `drop-shadow(${this.model.shadow.x}px ${this.model.shadow.y}px ${this.model.shadow.blur}px ${shadowColor})`;
                }
        }
    }

    _calcProps(props, options) {
        const { size } = props;

        const elementProps = this.childElement.calcProps(size);
        elementProps.bounds = new geom.Rect(0, 0, elementProps.size);

        return {
            size: elementProps.bounds.size,
            opacity: _.defaultTo(this.model.opacity, 100) / 100,
        };
    }

    get animationElementName() {
        if (this.model.name) {
            return this.model.name;
        }
        return `${this.model.type.charAt(0).toUpperCase()}${this.model.type.slice(1)} #${this.itemIndex + 1}`;
    }

    get animateChildren() {
        return this.model.type === AuthoringElementType.PATH;
    }

    _getAnimations() {
        if (this.model.type === AuthoringElementType.PATH) {
            return [];
        }

        return [{
            name: "Fade in",
            prepare: () => this.animationState.fadeInProgress = 0,
            onBeforeAnimationFrame: progress => {
                this.animationState.fadeInProgress = progress;
            }
        }];
    }
}

import React, { Component } from "react";

import { PropertyPanelContainer } from "../../../../../EditorComponents/PropertyPanel";
import { LayoutEngineType } from "../../../elements/elements/LayoutContainers/GridLayoutContainer";
import { GridLayoutPropertyPanel } from "./GridLayoutUI";
import { LayoutContainerItemPropertyPanel } from "./LayoutContainerItemUI";

export class PhotoCollagePropertyPanel extends Component {
    render() {
        return (
            <PropertyPanelContainer>
                <GridLayoutPropertyPanel {...this.props}
                    availableLayoutEngines={[LayoutEngineType.FLEX, LayoutEngineType.PRESERVE_ASPECT, LayoutEngineType.CUSTOM]}
                    showMoreGridOptions
                />
            </PropertyPanelContainer>
        );
    }
}

export class PhotoCollageItemPropertyPanel extends LayoutContainerItemPropertyPanel {
}

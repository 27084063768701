import React, { Component } from "react";
import { ControlBar } from "../../../ElementControlBars/Components/ControlBar";
import { Slider } from "../../../../../../Components/Slider";
import { PropertySection } from "../../../../../../EditorComponents/PropertyPanel";
import { WithLabel } from "../../../../../../Components/WithLabel";
import { FormattingDropdown } from "../../../EditorComponents/FormattingOptions";
import { ControlBarPosition, FormatType } from "../../../../../../../common/constants";
import { ToggleSwitch } from "../../../../../../Components/ToggleSwitch";
import { ColorPicker } from "../../../EditorComponents/ColorPickers/ColorPicker";
import { Dropdown } from "../../../../../../Components/Dropdown";
import { MenuItem } from "../../../../../../Components/Menu";
import { NumericStepper } from "../../../../../../Components/NumericStepper";
import { BaseElementSelection } from "../../../ElementSelections/BaseElementSelection";

export class RadialBarGraphPropertyPanel extends Component {
    render() {
        const { element } = this.props;

        return (
            <>
                <PropertySection>
                    <WithLabel label="Color">
                        <ColorPicker value={element.model.color}
                            canvas={element.canvas}
                            showPositiveNegativeColors showPrimary
                            onChange={color => element.updateModel({ color }, { refreshStyles: true })}
                        />
                    </WithLabel>
                </PropertySection>
                <PropertySection>
                    <WithLabel label="Value">
                        <Slider value={element.model.value}
                            min={0} max={element.model.maxValue ?? 100} step={1} showInput
                            onChange={value => element.refreshModel({ value })}
                            onCommit={() => element.saveModel()}
                        />
                    </WithLabel>
                </PropertySection>
                <PropertySection>
                    <WithLabel label="Show Value">
                        <ToggleSwitch value={element.showValue}
                            onChange={value => element.updateModel({ showValue: value })}
                        />
                    </WithLabel>
                    <WithLabel label="Value Format">
                        <FormattingDropdown
                            format={element.format}
                            formatOptions={element.formatOptions}
                            onChangeFormat={format => element.updateModel({ format })}
                            onChangeFormatOptions={formatOptions => element.updateModel({ formatOptions })}
                            allowedFormats={[FormatType.PERCENT, FormatType.NUMBER, FormatType.CURRENCY]}
                        />
                    </WithLabel>
                    <WithLabel label="Max Value">
                        <NumericStepper value={element.maxValue}
                            min={0} max={9999999} step={1}
                            onChange={maxValue => element.updateModel({ maxValue })}
                        />
                    </WithLabel>
                </PropertySection>
                <PropertySection>
                    <WithLabel label="Show Label">
                        <ToggleSwitch value={element.showLabel}
                            onChange={value => element.updateModel({ showLabel: value })}
                        />
                    </WithLabel>
                    {element.showLabel && (
                        <WithLabel label="Label Position">
                            <Dropdown value={element.model.labelPosition ?? "inside"}
                                onChange={labelPosition => element.updateModel({ labelPosition })}
                            >
                                <MenuItem value="inside">Inside</MenuItem>
                                <MenuItem value="top">Top</MenuItem>
                                <MenuItem value="bottom">Bottom</MenuItem>
                            </Dropdown>
                        </WithLabel>
                    )}
                </PropertySection>
            </>
        );
    }
}

export class RadialBarInfographicControlBar extends Component {
    get suppressPrimaryControlBar() {
        return true;
    }

    render() {
        const { element } = this.props;

        return (
            <ControlBar position={ControlBarPosition.BELOW}>
                <ColorPicker value={element.model.color}
                    canvas={element.canvas}
                    size={20}
                    showPositiveNegativeColors showPrimary
                    onChange={color => element.updateModel({ color }, { refreshStyles: true })}
                />
                <Slider value={element.model.value}
                    min={0} max={element.model.maxValue ?? 100} step={1}
                    onChange={value => element.refreshModel({ value })}
                    onCommit={() => element.saveModel()}
                />
            </ControlBar>
        );
    }
}

export class RadialBarInfographicSelection extends BaseElementSelection {

}


import React, { Component } from "react";
import Spinner from "../react/components/Spinner";

export class ImageLoader extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            urlFunction: props.url
        };
    }

    componentDidMount() {
        this.loadImage();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.url !== this.props.url) {
            this.setState({ loaded: false, url: this.props.url });
            this.loadImage();
        }
    }

    async loadImage() {
        let url = typeof this.state.urlFunction == "function" ? await this.state.urlFunction() : this.state.urlFunction;
        let img = new Image();
        img.onload = () => {
            this.setState({ loaded: true, url });
        };
        img.src = url;
    }

    render() {
        const { loaded, url } = this.state;

        if (!loaded) {
            return <Spinner />;
        } else {
            return <img src={url} {...this.props} />;
        }
    }
}


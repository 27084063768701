import AppController from "js/core/AppController";
import { ShowDialog, ShowWarningDialog } from "js/react/components/Dialogs/BaseDialog";
import { mergeMediaElementModelDefaults } from "common/assetUtils";

import AddAssetsContainer from "./AddAssetsContainer";

export async function ShowAssetDialog(assetType, extraProps = {}) {
    const { supportedAssetTypes, ...props } = extraProps;

    const isCompatibleAssetType = model => !supportedAssetTypes?.length ||
        supportedAssetTypes.includes(model.content_type);

    return new Promise((resolve, reject) => {
        ShowDialog(AddAssetsContainer, {
            workspaceId: AppController.workspaceId,
            assetType,
            ...props,
            callback: model => {
                if (isCompatibleAssetType(model)) {
                    mergeMediaElementModelDefaults(model);
                    resolve(model);
                } else {
                    ShowWarningDialog({
                        title: "Unsupported asset type",
                        message: `Please choose ${supportedAssetTypes.map(t => t.toLowerCase()).join(", ")} from the asset chooser.`,
                    });
                }
            },
        });
    });
}

import React from "react";
import styled from "styled-components";

import { NumericStepper as BaseNumericStepper } from "../../../../../Components/NumericStepper";
import { Icon } from "../../../../../Components/Icon";

const Container = styled.div`
    width: 64px;
    display: flex;
    align-items: center;
    box-sizing: content-box;

    padding: 0 8px;
`;

const StyledNumericInput = styled.input`
    min-width: 20px;
    height: 20px;    
    outline: none;    
    border: none;
    background: transparent;
    text-align: center;
    color: white;
    font-size: 14px;
    font-weight: 600;
    margin: 0 2px;

    &::-webkit-inner-spin-button {
        opacity: 1;
    }
`;

const StepperButton = styled.div`
    width: 25px;
    height: 25px;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
        background: rgba(0,0,0,.1);
    }
`;

export class NumericStepper extends BaseNumericStepper {
    render() {
        const { disabled } = this.props;

        return (
            <Container>
                <StepperButton onClick={event => this.handleStepper(event.shiftKey ? -10 : -1)}><Icon>arrow_drop_down</Icon></StepperButton>
                <StyledNumericInput
                    type="text"
                    ref={this.ref}
                    value={this.getDisplayValue()}
                    onChange={this.handleChange}
                    onKeyDown={this.handleKeyDown}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                    onWheel={this.handleWheel}
                    disabled={disabled}
                />
                <StepperButton onClick={event => this.handleStepper(event.shiftKey ? 10 : 1)}><Icon>arrow_drop_up</Icon></StepperButton>
            </Container>
        );
    }
}

import React, { Component } from "react";
import { ColorPicker } from "./ColorPicker";

export class BackgroundColorPicker extends Component {
    render() {
        const { element, size = 25, colorProperty = "color", decorationProperty = "decorationStyle", onChange, showNone, showThemeColors = true, position, showDecorationStyles = false, imageBackgroundColor } = this.props;

        if (!element) {
            return null;
        }

        const canvas = element.canvas;
        const theme = canvas.getTheme();

        let backgroundColor = element.model[colorProperty];
        if (backgroundColor === "use-image-background") {
            backgroundColor = imageBackgroundColor;
        }

        return (
            <ColorPicker value={backgroundColor}
                canvas={canvas} size={size}
                showBackgroundColors showThemeColors={showThemeColors}
                showNone={showNone}
                showDecorationStyles={showDecorationStyles}
                allowColorOnColor
                position={position}
                onChange={color => {
                    if (onChange) {
                        onChange(color);
                    } else {
                        element.updateModel({ [colorProperty]: color }, { refreshStyles: true });
                    }
                }}
            />
        );
    }
}

import React, { Component } from "react";

import { _ } from "../../../../vendor";
import { ListStyleType, TextStyleType } from "../../../../../common/constants";

import { ControlBar, ControlBarGroup } from "../ElementControlBars/Components/ControlBar";
import { AddItemButton } from "../EditorComponents/AddItemButton";

export class AgendaControlBar extends Component {
    handleAddAgendaItem = () => {
        const { element } = this.props;

        const lastColumn = _.last(element.itemElements.filter(element => !element.isMediaColumn));
        lastColumn.text.addBlock({
            textStyle: TextStyleType.BULLET_LIST,
            listStyle: ListStyleType.NUMBERED,
        });

        element.canvas.updateCanvasModel(true);
    }

    handleAddAgendaSection = () => {
        const { element } = this.props;

        const lastColumn = _.last(element.itemElements.filter(element => !element.isMediaColumn));
        lastColumn.text.addBlock({
            textStyle: TextStyleType.SECTION,
        });

        element.canvas.updateCanvasModel(true);
    }

    render() {
        return (
            <ControlBar>
                <ControlBarGroup>
                    <AddItemButton label="Add Agenda Item" onClick={this.handleAddAgendaItem} />
                </ControlBarGroup>
                <ControlBarGroup>
                    <AddItemButton label="Add Section" onClick={this.handleAddAgendaSection} />
                </ControlBarGroup>
            </ControlBar>
        );
    }
}


import React, { Component } from "react";
import styled from "styled-components";
import { SlideTemplatesByCategoryList } from "../../../../../../../react/views/AddSlide/Panes/SlideTemplatesPane";
import { _ } from "../../../../../../../vendor";

const PickerContainer = styled.div`
  width: 1000px;
  height: calc(100vh - 100px);
  overflow-x: hidden;
  overflow-y: scroll;
`;

export class ElementBlockPicker extends Component {
    render() {
        return (
            <PickerContainer>
                <SlideTemplatesByCategoryList
                    onSelected={this.props.onSelect}
                    hideUseCurrentThemeSwitch
                    showUserTheme={false}
                />
            </PickerContainer>
        );
    }
}

export function GetDocumentElementTypeMenu() {
    return [{
        value: "Dashboard", label: "Chart", image: "/images/template-icons/chart_area.svg"
    }, {
        value: "PhotoCollage", label: "Image", image: "/images/template-icons/image.svg"
    }, {
        value: "TableFrame", label: "Table", image: "/images/template-icons/table.svg"
    }, {
        value: "Timeline", label: "Timeline", image: "/images/template-icons/timeline.svg"
    }, {
        value: "BigNumbers", label: "Dashboard", image: "/images/template-icons/big_numbers.svg"
    }, {
        value: "Headline", label: "Headline", image: "/images/template-icons/headline.svg"
    }, {
        value: "WordCloud", label: "WordCloud", image: "/images/template-icons/word_cloud.svg"
    }, {
        value: "IconTextGrid", label: "Icons & Text", image: "/images/template-icons/textgrid_vertical_icons.svg"
    }];
}

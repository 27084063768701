import React, { Component } from "react";

import { getElementModelUpdatesForAsset } from "common/assetUtils";

import { AssetType } from "../../../../../common/constants";
import ImageVideoDrop from "js/Components/ImageVideoDrop";

export class MediaElementDefaultOverlay extends Component {
    getElementModelUpdatesForAsset = asset => {
        const { element } = this.props;
        const updates = getElementModelUpdatesForAsset(asset);
        element.updateModel(updates, { refreshStyles: true });
    }

    render = () => {
        const { element } = this.props;
        return <ImageVideoDrop imageAsLogo={element.asset?.type === AssetType.ICON} callback={this.getElementModelUpdatesForAsset} />;
    }
}

import { v4 as uuid } from "uuid";

import {
    AuthoringBlockType,
    BlockStructureType,
    HorizontalAlignType,
    ListStyleType,
    TextStyleType
} from "common/constants";
import { detectTextContent } from "js/core/services/sharedModelManager";
import * as geom from "js/core/utilities/geom";
import { htmlToText } from "js/core/utilities/htmlTextHelpers";
import { computeLineBreaks } from "js/core/utilities/linebreak";
import { _ } from "js/vendor";

import { TextListControlBar, TextListMediaColumnSelection, TextListPropertyPanel } from "../../../Editor/ElementPropertyPanels/TextListUI";
import { CollectionElement, CollectionItemElement } from "../../base/CollectionElement";
import { FramedMediaElement } from "../../base/MediaElements/FramedMediaElement";
import { GetBlockModelsFromMigratedHtml, MigrateHtml, TextElement } from "../../base/Text/TextElement";

export class TextList extends CollectionElement {
    static get schema() {
        return {
            autoDistributeBullets: true,
            autoArrangeColumnsCount: 2,
            showColumnHeaders: false,
            startNum: 1,
            listStyle: ListStyleType.BULLET
        };
    }

    get linkToSlideAsLink() {
        return true;
    }

    get name() {
        return "Text List";
    }

    getElementPropertyPanel() {
        return TextListPropertyPanel;
    }

    getElementControlBar() {
        return TextListControlBar;
    }

    get canRefreshElement() {
        return true;
    }

    refreshElement(transition) {
        this.canvas.refreshElement(this, transition);
    }

    get maxItemCount() {
        return this.forceSingleColumn ? 1 : (this.styles.maxCols ?? 3);
    }

    get maxTextColumnsCount() {
        return 3;
    }

    get forceSingleColumn() {
        return this.options.forceSingleColumn ?? this.model.forceSingleColumn;
    }

    get listStyle() {
        return this.options.listStyle ?? this.model.listStyle;
    }

    get mediaColumnsCount() {
        return this.itemCollection.filter(model => model.isMedia).length;
    }

    get textColumnsCount() {
        return this.itemCollection.filter(model => !model.isMedia).length;
    }

    get allowAutoColumns() {
        return true;
    }

    get showAddColumnControl() {
        return true;
    }

    get autoTextWidth() {
        return false;
    }

    get showAddBlockButton() {
        return false;
    }

    get allowedBlockTypes() {
        return [TextStyleType.BULLET_LIST, AuthoringBlockType.DIVIDER];
    }

    getChildItemType(model) {
        return model.isMedia ? TextListMediaColumn : TextListColumn;
    }

    getChildOptions(model, index) {
        if (model.isMedia) {
            return {};
        }

        return {
            textAlign: HorizontalAlignType.LEFT,
            defaultBlockListStyle: this.listStyle === ListStyleType.CHECKBOX ? ListStyleType.ICON : this.listStyle,
            autoTextWidth: this.autoTextWidth,
            linkToSlideAsLink: this.linkToSlideAsLink,
            getNumberedBulletsStartSequenceNum: indent => {
                if (indent) {
                    return 1;
                }

                if (this.showColumnHeaders) {
                    return 1;
                }

                if (index === 0) {
                    return this.model.startNum ?? 1;
                }

                const prevBlocksCount = this.itemCollection
                    .slice(0, index)
                    .filter(item => !item.isMedia)
                    .reduce((prevBlocksCount, item) => prevBlocksCount + item.text.blocks.filter(block => block.listStyle && !block.indent).length, 0);
                return prevBlocksCount + (this.model.startNum ?? 1);
            },
            onAdjacentBlocksListStyleChanged: (indent, listStyle, options) => {
                if (indent === 0) {
                    this.allBlockModels
                        .filter(block => block.listStyle && !block.indent)
                        .forEach(block => {
                            block.listStyle = listStyle;
                            block.listDecorationStyle = options.style ?? block.listDecorationStyle;
                            block.useThemedListDecoration = options.useThemedListDecoration ?? block.useThemedListDecoration;
                        });
                    this.model.listStyle = listStyle;
                }
            }
        };
    }

    get defaultItemData() {
        return {
            isMedia: false,
            text: {
                blocks: [
                    this.getDefaultBlockModel()
                ]
            }
        };
    }

    getDefaultBlockModel() {
        return {
            id: uuid(),
            type: AuthoringBlockType.TEXT,
            textStyle: TextStyleType.BULLET_LIST,
            listStyle: this.listStyle,
            html: ""
        };
    }

    get _showDefaultOverlay() {
        return false;
    }

    get showColumnHeaders() {
        return !!this.model.showColumnHeaders;
    }

    get allBlockModels() {
        return this.itemCollection.reduce((blocks, textListColumnModel) => [...blocks, ...(textListColumnModel.text?.blocks ?? [])], []);
    }

    get bulletsCount() {
        return this.allBlockModels.filter(block => block.listStyle && !block.indent).length;
    }

    get allBlocks() {
        return this.itemElements.reduce((blocks, textListColumnElement) => [...blocks, ...(textListColumnElement.text?.blockContainerRef?.current?.blocks ?? [])], []);
    }

    get canDistributeBullets() {
        return !this.showColumnHeaders;
    }

    get autoDistributeBullets() {
        if (this.showColumnHeaders) {
            return false;
        } else {
            return this.model.autoDistributeBullets;
        }
    }

    getColumnHorizontalAlign(columnIndex) {
        return HorizontalAlignType.CENTER;
    }

    _getCustomBlocksDropTargets(sourceElement, sourceBlocks) {
        if (this.itemElements.length === this.maxItemCount && sourceElement.textModel.blocks.length > sourceBlocks.length) {
            return [];
        }

        if (
            sourceElement.parentElement.itemIndex === this.itemElements.length - 1 &&
            sourceElement.blockContainerRef.current.blocks.length === sourceBlocks.length
        ) {
            return [];
        }

        if (!sourceBlocks[0].model.listStyle || sourceBlocks[0].model.indent) {
            return [];
        }

        return [{
            canvasBounds: this.canvasBounds.inflate({ left: 40 - this.canvasBounds.width, top: 30, right: 0, bottom: 30 }),
            labelText: "Add Column",
            onDrop: async blocks => {
                while (this.itemCollection.some(model => !model.isMedia && !model.text?.blocks?.length) && this.itemCollection.length > 1) {
                    this.itemCollection.splice(this.itemCollection.findIndex(model => !model.isMedia && !model.text?.blocks?.length), 1);
                }

                const { id } = this.addItem({
                    text: {
                        blocks: _.cloneDeep(blocks.map(({ model }) => ({ ...model, id: uuid() })))
                    }
                });

                await this.canvas.selectionLayerController.setSelectedElements([]);
                await this.canvas.updateCanvasModel(true);
                await this.canvas.selectionLayerController.selectTextElementBlock(this.elements[id].text, this.elements[id].text.textModel.blocks[0].id);
            }
        }];
    }

    _build() {
        this.buildItems();

        if (this.showColumnHeaders) {
            this.columnHeaders = [];
            for (let columnIndex = 0; columnIndex < this.itemElements.length; columnIndex++) {
                this.columnHeaders.push(this.addElement("columnHeader" + columnIndex, () => TextListColumnHeader, {
                    columnIndex,
                    bindTo: "columnHeader" + columnIndex,
                    syncFontSizeWithSiblings: true,
                    getSiblings: () => this.columnHeaders
                }));
            }
        }
    }

    _calcProps(props, options) {
        let { size } = props;

        let isReducedWidth = size.width < 800;
        if (isReducedWidth) {
            this.updateStyles(this.styles.reducedWidth);
        } else {
            this.updateStyles(this.styles.fullWidth);
        }
        size = size.deflate({ left: this.styles.columnOuterPadding, right: this.styles.columnOuterPadding });

        const columnsCount = this.itemElements.length;
        const hGap = this.styles.hGap ?? 10;

        let x = this.styles.columnOuterPadding;

        let columnWidths = Array(columnsCount).fill(0);

        if (columnsCount > 1 || isReducedWidth) {
            let remainingWidth = size.width - (columnsCount - 1) * hGap;

            let autoColumns = [];
            for (let columnIndex = 0; columnIndex < columnsCount; columnIndex++) {
                if (this.itemElements[columnIndex].model.size) {
                    columnWidths[columnIndex] = this.itemElements[columnIndex].model.size;
                    remainingWidth -= columnWidths[columnIndex];
                } else {
                    autoColumns.push(columnIndex);
                }
            }
            let columnWidth = remainingWidth / autoColumns.length;
            for (let autoColumn of autoColumns) {
                columnWidths[autoColumn] = columnWidth;
            }

            // columnWidth = (size.width - (columnsCount - 1) * hGap) / columnsCount;
        } else {
            columnWidths[0] = size.width * .75;
            x += (size.width - columnWidths[0]) / 2;
        }

        let maxColumnHeaderHeight = 0;
        if (this.showColumnHeaders) {
            for (let columnIndex = 0; columnIndex < columnsCount; columnIndex++) {
                const colHeaderProps = this.columnHeaders[columnIndex].calcProps(new geom.Size(columnWidths[columnIndex], size.height));
                maxColumnHeaderHeight = Math.max(maxColumnHeaderHeight, colHeaderProps.size.height);
            }
        }

        let maxColumnHeight = 0;
        for (let columnIndex = 0; columnIndex < columnsCount; columnIndex++) {
            const colProps = this.itemElements[columnIndex].calcProps(new geom.Size(columnWidths[columnIndex], size.height - maxColumnHeaderHeight));
            maxColumnHeight = Math.max(maxColumnHeight, colProps.bounds.height);
        }

        const totalHeight = maxColumnHeaderHeight + maxColumnHeight;
        const topOffset = (size.height - totalHeight) / 2;

        for (let columnIndex = 0; columnIndex < columnsCount; columnIndex++) {
            if (this.showColumnHeaders) {
                const colHeaderProps = this.columnHeaders[columnIndex].calculatedProps;
                colHeaderProps.bounds = new geom.Rect(x, topOffset, colHeaderProps.size);
            }

            const colProps = this.itemElements[columnIndex].calculatedProps;

            const colHorizontalAlign = this.getColumnHorizontalAlign(columnIndex);
            let colOffset;
            if (colHorizontalAlign === HorizontalAlignType.LEFT) {
                colOffset = 0;
            } else if (colHorizontalAlign === HorizontalAlignType.CENTER) {
                colOffset = (columnWidths[columnIndex] - colProps.size.width) / 2;
            } else {
                colOffset = columnWidths[columnIndex] - colProps.size.width;
            }

            let columnX = x + colOffset;
            if (this.isRTL) {
                columnX = size.width + this.styles.columnOuterPadding * 2 - columnX - colProps.size.width;
            }

            if (this.itemElements.some(element => element.isMediaColumn)) {
                colProps.bounds = new geom.Rect(columnX, topOffset + maxColumnHeaderHeight + (maxColumnHeight - colProps.size.height) / 2, colProps.size);
            } else {
                colProps.bounds = new geom.Rect(columnX, topOffset + maxColumnHeaderHeight, colProps.size);
            }

            x += columnWidths[columnIndex] + hGap;
        }

        const isFit = totalHeight <= size.height && this.itemElements.filter(element => !element.isMediaColumn).every(element => element.text.calculatedProps.isTextFit);

        return { size, isFit };
    }

    getBlockGroups() {
        const blockGroups = [];

        let currentBlockGroup = [];
        const terminateCurrentBlockGroup = () => {
            if (currentBlockGroup.length === 0) {
                return;
            }

            blockGroups.push({
                blocks: currentBlockGroup,
                bounds: currentBlockGroup.reduce((bounds, block) => bounds ? bounds.union(block.bounds) : block.bounds, null)
            });
            currentBlockGroup = [];
        };

        this.allBlocks.forEach(block => {
            if (!block.model.listStyle || (block.model.listStyle && block.model.indent == 0)) {
                terminateCurrentBlockGroup();
            }
            currentBlockGroup.push(block);
        });
        terminateCurrentBlockGroup();

        return blockGroups;
    }

    distributeBullets(targetTextColumnsCount = 2, keepEmptyBlocks = false, mediaColumnIndex) {
        const mediaColumnModel = this.model.items.find(model => model.isMedia);

        const insertMediaColumn = () => {
            if (mediaColumnModel) {
                if (mediaColumnIndex !== undefined) {
                    this.model.items.insert(mediaColumnModel, mediaColumnIndex);
                } else {
                    if (targetTextColumnsCount == 1) {
                        this.model.items.insert(mediaColumnModel, 0);
                    } else {
                        this.model.items.insert(mediaColumnModel, 1);
                    }
                }
            }
        };

        if (targetTextColumnsCount == 0 && mediaColumnModel) {
            this.model.items = [mediaColumnModel];
            return;
        }

        const blockGroups = this.getBlockGroups()
            .filter(blockGroup => blockGroup.blocks.some(block => keepEmptyBlocks || !!block.model.html));

        if (blockGroups.length == 0) {
            this.model.items = [...new Array(targetTextColumnsCount)].map(() => this.defaultItemData);
            insertMediaColumn();
            return;
        }

        const blockGroupGap = Math.max(...blockGroups.map(blockGroup => blockGroup.blocks[0].props.blockMargin.top));
        const totalHeight = blockGroups.reduce((height, blockGroup) => height + blockGroup.bounds.height, 0) + (blockGroups.length - targetTextColumnsCount) * blockGroupGap;
        const desirableColumnHeight = Math.ceil(totalHeight / targetTextColumnsCount);

        blockGroups.forEach(group => group.id = uuid());

        const lines = computeLineBreaks(
            blockGroups.map(group => ({
                value: group.id,
                width: group.bounds.height
            })),
            // Blocks have scaling enabled, so we can't know at this moment if they'll fit
            // into our total height, so we just distribute them evenly here and
            // then fit will be checked on calc props
            Number.MAX_SAFE_INTEGER,
            {
                goalWidth: desirableColumnHeight,
                maxLines: Math.min(targetTextColumnsCount, blockGroups.length),
                minLines: Math.min(targetTextColumnsCount, blockGroups.length),
                // because it's a column, we want to keep the spacing between the groups
                // in a positive range
                spacing: Math.abs(blockGroupGap)
            }
        );

        if (!lines) {
            // Just in case, we should never end up here
            return;
        }

        const itemModels = lines.lines.map(blockGroupIds => {
            const blocks = [];
            blockGroupIds.forEach(blockGroupId => {
                const blockGroup = blockGroups.find(blockGroup => blockGroup.id === blockGroupId);
                blocks.push(...blockGroup.blocks.map(block => block.model));
            });

            if (blocks.length == 0) {
                blocks.push(this.getDefaultBlockModel());
            }

            return {
                isMedia: false,
                id: uuid(),
                text: {
                    blocks
                }
            };
        });

        if (targetTextColumnsCount > itemModels.length) {
            for (let col = itemModels.length; col < targetTextColumnsCount; col++) {
                itemModels.push(this.defaultItemData);
            }
        }

        this.model.items = itemModels;

        insertMediaColumn();
    }

    _migrate_10() {
        if (!this.model.items) {
            this.model.items = [];
        }

        const columnsCount = this.model.items.reduce((columnsCount, item) => Math.max(columnsCount, (item.col ?? 0) + 1), 0);

        const blockFontScales = {};
        blockFontScales[TextStyleType.BULLET_LIST] = [
            this.model.userFontScale?.["CanvasElement/TextList/TextListItem/TextGroup/TextGroupTitle"] ?? 1,
            this.model.userFontScale?.["CanvasElement/TextList/TextListItem/TextGroup/TextGroupBody"] ?? 1
        ];

        let listStyle = this.listStyle;
        if (listStyle === "icons") {
            listStyle = "icon";
        }

        const columnModels = [];
        let currentColumnBlocks = [];
        for (const columnIndex of [...Array(columnsCount).keys()]) {
            const itemsInColumn = this.model.items.filter(item => (item.col ?? 0) === columnIndex);
            for (const item of itemsInColumn) {
                currentColumnBlocks.push({
                    id: uuid(),
                    type: AuthoringBlockType.TEXT,
                    textStyle: TextStyleType.BULLET_LIST,
                    listStyle,
                    listIconId: item.content_value,
                    checkState: item.checkState,
                    html: MigrateHtml(item.title, this).join("<br>")
                });

                if (item.body && !_.isEmpty(item.body.text)) {
                    let subblocks = GetBlockModelsFromMigratedHtml(item.body, this, TextStyleType.BULLET_LIST, true);
                    for (let subblock of subblocks) {
                        subblock.indent = 1;
                        switch (item.text_format) {
                            case "bullet_list":
                                subblock.listStyle = ListStyleType.BULLET;
                                break;
                            case "numbered_list":
                                subblock.listStyle = ListStyleType.NUMBERED;
                                break;
                            default:
                                subblock.listStyle = ListStyleType.TEXT;
                        }
                    }
                    currentColumnBlocks.push(...subblocks);
                }
            }
            columnModels.push({
                text: {
                    blocks: currentColumnBlocks,
                    blockFontScales
                }
            });
            currentColumnBlocks = [];
        }

        this.model.items = columnModels;

        this.model.autoArrangeColumnsCount = this.model.autoArrange ?? 2;
        delete this.model.autoArrange;
    }

    getAnimations() {
        const animations = this._getAnimations();
        animations.forEach(animation => animation.element = this);

        if (this.animateChildren) {
            const addAnimations = columnElement => {
                if (this.showColumnHeaders) {
                    animations.push(...this.columnHeaders[columnElement.itemIndex].getAnimations());
                }
                animations.push(...columnElement.getAnimations());
            };

            // Media columns go first
            this.itemElements
                .filter(({ model: { isMedia } }) => !!isMedia)
                .forEach(addAnimations);

            // Then text columns
            this.itemElements
                .filter(({ model: { isMedia } }) => !isMedia)
                .forEach(addAnimations);
        }

        if (this.disableAnimationsByDefault) {
            animations.forEach(animation => animation.disabledByDefault = true);
        }

        return animations;
    }

    _exportToSharedModel() {
        const assets = this.itemElements.filter(itemElement => itemElement.isMediaColumn)
            .map(itemElement => itemElement.media._exportToSharedModel()).map(d => d.assets).flat();

        const textContent = this.getBlockGroups().map(blockGroup => ({
            listStyle: this.listStyle,
            mainText: {
                text: blockGroup.blocks[0].textContent,
                textStyle: blockGroup.blocks[0].textStyle
            },
            secondaryTexts: blockGroup.blocks.slice(1).map(b => ({
                text: b.textContent,
                textStyle: b.textStyle
            })).filter(b => !!b.text),
        })).filter(b => !!b.mainText.text);

        return { textContent, assets, collectionColor: this.collectionColor };
    }

    _importFromSharedModel(model) {
        const textContent = detectTextContent(model);
        if (!textContent?.length) return;

        const items = [];
        const bulletCountPerCol = Math.ceil(textContent.length / this.maxItemCount);

        for (let i = 0; i < this.maxItemCount; i++) {
            let currentColumnBlocks = [];

            for (let j = bulletCountPerCol * i; j < bulletCountPerCol * (i + 1); j++) {
                if (!textContent[j]) continue;
                const { mainText, secondaryTexts } = textContent[j];
                currentColumnBlocks.push(...[
                    {
                        id: uuid(),
                        html: mainText.text,
                        textStyle: TextStyleType.BULLET_LIST,
                        listStyle: model.props?.listStyle || ListStyleType.BULLET,
                        type: AuthoringBlockType.TEXT,
                        indent: 0
                    },
                    ...secondaryTexts.map(secondaryText => ({
                        id: uuid(),
                        html: secondaryText.text,
                        textStyle: TextStyleType.BULLET_LIST,
                        listStyle: ListStyleType.TEXT,
                        type: AuthoringBlockType.TEXT,
                        indent: 1
                    }))
                ]);
            }

            items.push(_.cloneDeep({ id: uuid(), text: { blocks: currentColumnBlocks } }));
            currentColumnBlocks = [];
        }

        items.splice(textContent.length);
        return { items, collectionColor: model.collectionColor };
    }
}

class TextListMediaColumn extends CollectionItemElement {
    get isMediaColumn() {
        return true;
    }

    getElementSelection() {
        return TextListMediaColumnSelection;
    }

    _build() {
        this.media = this.addElement("screenshot", () => FramedMediaElement, {
            autoWidth: true,
            autoHeight: true,
            allowUnframedImages: false
        });
    }

    _calcProps(props, options) {
        const { size } = props;

        const mediaProps = this.media.calcProps(size);
        mediaProps.bounds = new geom.Rect(size.width / 2 - mediaProps.size.width / 2, size.height / 2 - mediaProps.size.height / 2, mediaProps.size);

        return { size };
    }

    get animationElementName() {
        return "Image";
    }
}

class TextListColumn extends CollectionItemElement {
    static get schema() {
        return {
            text: {
                blocks: []
            }
        };
    }

    get _canSelect() {
        return false;
    }

    onBlockRemoved() {
        if (this.model.text.blocks.length == 0) {
            this.parentElement.model.items.remove(this.model);
            this.parentElement.model.columns--;
        }
    }

    _build() {
        if (this.model.text.blocks) {
            this.model.text.blocks.forEach(block => delete block.textAlign);
        }

        this.text = this.addElement("text", () => TextElement, {
            blockStructure: BlockStructureType.FREEFORM,
            allowedBlockTypes: this.getRootElement().allowedBlockTypes,
            defaultBlockTextStyle: TextStyleType.BULLET_LIST,
            defaultBlockListStyle: this.options.defaultBlockListStyle,
            getNumberedBulletsStartSequenceNum: this.options.getNumberedBulletsStartSequenceNum,
            onAdjacentBlocksListStyleChanged: this.options.onAdjacentBlocksListStyleChanged,
            textAlign: this.options.textAlign,
            autoWidth: this.options.autoTextWidth,
            forceBlocksTextAlign: true,
            autoHeight: true,
            canRollover: false,
            scaleTextToFit: true,
            syncFontSizeWithSiblings: true,
            canDeleteLastBlock: true,
            animateBullets: true,
            autoDistributeBullets: this.parentElement.autoDistributeBullets,
            createSubBulletOnEnter: true,
            canAddBlocks: this.getRootElement().showAddBlockButton,
            getPlaceholderForBlock: this.options.getPlaceholderForBlock,
            linkToSlideAsLink: this.options.linkToSlideAsLink,
            getSiblings: () => this.parentElement.itemElements.filter(element => element?.isInstanceOf("TextListColumn")).map(element => element.text),
        });
    }

    _calcProps(props, options) {
        const { size } = props;

        const textProps = this.text.calcProps(size);
        textProps.bounds = new geom.Rect(0, 0, textProps.size);

        return { size: textProps.size };
    }

    _applyColors() {
        // color used by block decorations
        this.text.colorSet = {
            decorationColor: this.palette.getColor(this.parentElement.model.collectionColor, this.getBackgroundColor())
        };
    }

    get animateChildren() {
        return true;
    }

    _getAnimations() {
        return [];
    }
}

class TextListColumnHeader extends TextElement {
    get autoHeight() {
        return true;
    }

    get placeholderPrompt() {
        return { text: "Type column header", style: [] };
    }

    get animationElementName() {
        const text = this.textModel.blocks.reduce((text, block) => `${text}${text !== "" ? " " : ""}${htmlToText(block.html ?? "")}`, "").slice(0, 30);
        return `Column Header "${text}"`;
    }
}

export const elements = {
    TextList
};

import React from "react";
import styled from "styled-components";

import { AuthoringBlockType, HorizontalAlignType, PositionType, TextStyleType } from "common/constants";
import { detectListContent } from "js/core/services/sharedModelManager";
import * as geom from "js/core/utilities/geom";
import { ELEMENT_TRANSITION_DURATION } from "js/core/utilities/svgHelpers";
import { _ } from "js/vendor";

import { ImageCarouselControlBar, ImageCarouselGridContainerPropertyPanel, ImageCarouselPropertyPanel } from "../../../Editor/ElementPropertyPanels/ImageCarouselUI";
import { CollectionElement, CollectionItemElement } from "../../base/CollectionElement";
import { InfographicElementTypes } from "../Dashboard/InfographicManager";
import { LayoutEngineType } from "../LayoutContainers/GridLayoutContainer";
import { PhotoCollage } from "../PhotoCollage";

const DisableInteractions = styled.div`
    * {
        pointer-events: none !important;
    }
`;

class ImageCarousel extends CollectionElement {
    getElementPropertyPanel() {
        return ImageCarouselPropertyPanel;
    }

    getElementControlBar() {
        return ImageCarouselControlBar;
    }

    get name() {
        return "Image Carousel";
    }

    get minItemCount() {
        return 1;
    }

    get previousIndex() {
        return this._previousIndex;
    }

    get currentIndex() {
        return this._currentIndex;
    }

    set currentIndex(index) {
        this._previousIndex = this._currentIndex;
        if (index < 0) {
            this._currentIndex = this.itemCollection.length + index % -this.itemCollection.length;
        } else {
            this._currentIndex = index % this.itemCollection.length;
        }
    }

    setupElement() {
        this._currentIndex = 0;
        this._previousIndex = 0;
    }

    getChildItemType() {
        return ImageCarouselItem;
    }

    get defaultItemData() {
        return {
            layoutEngineType: LayoutEngineType.FLEX,
            gridLayout: {
                cols: 12,
                rows: 12,
                cells: [
                    { x: 0, y: 0, width: 12, height: 12 },
                ]
            },
            items: [{}]
        };
    }

    _calcProps(props, options) {
        const { size } = props;

        if (this.currentIndex >= this.itemElements.length) {
            // This can happen in the last item was removed
            this.currentIndex = this.itemElements.length - 1;
        }

        this.itemElements.forEach(element => element.calcProps(size));

        this.buildPlaybackStages();

        return { size };
    }

    addItem(props, index) {
        super.addItem(props, index);
        this.buildPlaybackStages();
    }

    deleteItem(itemId) {
        super.deleteItem(itemId);
        this.currentIndex = Math.max(this.currentIndex - 1, 0);
        this.buildPlaybackStages();
    }

    buildPlaybackStages() {
        this.canvas.clearPlaybackStages();
        for (let index = 0; index < this.itemElements.length; index++) {
            this.canvas.addPlaybackStage({
                type: "carousel",
                index,
                callback: (stage, animate) => this.goToItem(stage.index, animate)
            });
        }
    }

    goNextItem() {
        if (this.canvas.isPlayback && this.loop && this.currentIndex === this.itemElements.length - 1) {
            return this.goToItem(0, true);
        } else {
            return this.goToItem(this.currentIndex + 1, true);
        }
    }

    goPrevItem() {
        return this.goToItem(this.currentIndex - 1, true);
    }

    async goToItem(index, animate) {
        clearTimeout(this.autoPlayTimeout);

        const prevIndex = this.currentIndex;
        this.currentIndex = index;
        if (prevIndex === this.currentIndex) {
            return;
        }

        // No need to recalc here
        this.canvas.refreshRender(animate, false);

        // Prepare or stop the elements
        this.itemElements.forEach(element => {
            element.stopElement();

            if (element.itemIndex === this.currentIndex) {
                element.prepareToShowElement();
            }
        });

        // Resolve only after the animation finishes
        if (animate) {
            await new Promise(resolve => setTimeout(resolve, ELEMENT_TRANSITION_DURATION));
        }

        this._setAutoPlayTimeout();
    }

    get transition() {
        return this.model.transition ?? "slide-left";
    }

    renderChildren(transition) {
        const hasCurrentIndexChanged = this.hasStoredPropChanged("currentIndex", this.currentIndex, true);

        return this.itemElements.map((element, index) => {
            let animationName;

            if (index === this.currentIndex) {
                // Current element changed -> animate slide in/out
                switch (this.transition) {
                    case "slide-left":
                        if (this.canvas.isPlayback) {
                            animationName = "element-slide-in-right";
                        } else {
                            animationName = this.previousIndex < this.currentIndex ? "element-slide-in-right" : "element-slide-in-left";
                        }
                        break;
                    case "slide-up":
                        if (this.canvas.isPlayback) {
                            animationName = "element-slide-in-down";
                        } else {
                            animationName = this.previousIndex < this.currentIndex ? "element-slide-in-down" : "element-slide-in-up";
                        }
                        break;
                    case "fade":
                    default:
                        animationName = "element-fade-in";
                }
                element.calculatedProps.opacity = 1;
            } else if (index === this.previousIndex) {
                switch (this.transition) {
                    case "slide-left":
                        if (this.canvas.isPlayback) {
                            animationName = "element-slide-out-left";
                        } else {
                            animationName = this.previousIndex < this.currentIndex ? "element-slide-out-left" : "element-slide-out-right";
                        }
                        break;
                    case "slide-up":
                        if (this.canvas.isPlayback) {
                            animationName = "element-slide-out-up";
                        } else {
                            animationName = this.previousIndex < this.currentIndex ? "element-slide-out-up" : "element-slide-out-down";
                        }
                        break;
                    case "fade":
                    default:
                        animationName = "element-fade-out";
                }
                element.calculatedProps.opacity = 0;
            } else {
                // Non current elements will still be rendered (but with 0 opacity)
                // in order to get all assets preloaded and cached
                element.calculatedProps.opacity = 0;
            }

            const shouldAnimate = transition && hasCurrentIndexChanged;

            // render the content
            let content = element.renderElement(transition, { animationName: shouldAnimate ? animationName : null });
            // if hidden or disabled, prevent interacting
            // with a slide that's not visible
            if (element.calculatedProps.opacity == 0) {
                content = <DisableInteractions key={`disable-interactions-${index}`}>{content}</DisableInteractions>;
            }

            return content;
        });
    }

    get disableAllAnimationsByDefault() {
        return true;
    }

    get animationElementName() {
        return "Carousel";
    }

    get animateChildren() {
        return false;
    }

    _getAnimations() {
        return [{
            name: "Fade in",
            prepare: () => this.animationState.fadeInProgress = 0,
            onBeforeAnimationFrame: progress => {
                this.animationState.fadeInProgress = progress;
            }
        }];
    }

    get autoPlay() {
        return this.model.autoPlay;
    }

    get loop() {
        return this.model.loop;
    }

    get autoPlayInterval() {
        return this.model.autoPlayInterval || 2000;
    }

    // We're overriding the main method because we want to call prepareToShowElement only on the current item
    prepareToShowElement() {
        this._setAutoPlayTimeout();

        this.itemElements[this.currentIndex].prepareToShowElement();
    }

    _stopElement() {
        clearTimeout(this.autoPlayTimeout);
    }

    _setAutoPlayTimeout() {
        clearTimeout(this.autoPlayTimeout);

        if (!this.canvas.isPlayback || !this.autoPlay) {
            return;
        }

        this.autoPlayTimeout = setTimeout(() => {
            if (this.currentIndex >= this.itemElements.length - 1 && !this.loop) {
                return;
            }

            const nextIndex = (this.currentIndex + 1) % this.itemElements.length;
            this.canvas.goToStage(nextIndex, true);
        }, this.autoPlayInterval);
    }

    _exportToSharedModel() {
        const { assets, textContent } = this.itemElements.reduce(({ assets, textContent }, item) => {
            const textAndImageElements = Object.values(item.elements.grid.elements);
            return {
                assets: [
                    ...assets,
                    ...(textAndImageElements.map(el => el._exportToSharedModel()?.assets || [])).flat()
                ],
                textContent: [
                    ...textContent,
                    ...(textAndImageElements.map(el => el._exportToSharedModel()?.textContent || [])).flat()
                ]
            };
        }, { assets: [], textContent: [] });

        return { assets, textContent, collectionColor: this.collectionColor };
    }

    _importFromSharedModel(model) {
        const listContent = detectListContent(model);
        if (!listContent?.length) return;

        const items = listContent.map(({ text, asset }) => ({
            layoutEngineType: LayoutEngineType.HEADLINE,
            gridLayout: {
                cols: 12, rows: 12,
                cells: [
                    ...(text && asset ? [
                        { x: 0, y: 0, width: 6, height: 12 }, { x: 6, y: 0, width: 6, height: 12 }
                    ] : [
                        { x: 0, y: 0, width: 12, height: 12 }
                    ]),
                ]
            },
            items: [
                ...(asset ? [{
                    componentType: InfographicElementTypes.TEXT_AND_IMAGE,
                    childElement: {
                        content_type: asset.type,
                        content_value: asset.value,
                        assetName: asset.name,
                        assetProps: asset.props,
                        ...(asset.configProps ?? {})
                    }
                }] : []),
                ...(text ? [{
                    componentType: InfographicElementTypes.TEXT_AND_IMAGE,
                    childElement: {
                        text: {
                            blocks: [
                                {
                                    html: text.mainText.text,
                                    textStyle: text.mainText.textStyle || TextStyleType.HEADING,
                                    type: AuthoringBlockType.TEXT,
                                },
                                ...text.secondaryTexts.map(secondaryText => ({
                                    html: secondaryText.text,
                                    textStyle: secondaryText.textStyle || TextStyleType.BODY,
                                    type: AuthoringBlockType.TEXT,
                                }))
                            ]
                        }
                    }
                }] : [])
            ]
        }));

        return { items, collectionColor: model.collectionColor };
    }

    _migrate_10_02() {
        let newItems = [];
        for (let item of this.itemCollection) {
            let imageCell = {
                componentType: InfographicElementTypes.TEXT_AND_IMAGE,
                cellColor: "transparent",
                childElement: {
                    ..._.omit(item, "id", "layout", "text_format", "titleTextStyle", "version", "text"),
                }
            };
            if (imageCell.childElement.frameType == "theme") {
                imageCell.childElement.frameType = this.canvas.getTheme().get("styleShape");
                imageCell.childElement.frameColor = item.color ?? this.canvas.getSlideColor();
            }

            let textCell = {
                componentType: InfographicElementTypes.TEXT_AND_IMAGE,
                cellColor: "transparent",
                childElement: {
                    text: {
                        blockFontScales: { title: 50 / 30, body: 25 / 24 },
                        ...item.text
                    },
                    textAlign: HorizontalAlignType.LEFT,
                    textPosition: PositionType.LEFT,
                    userWidth: 1100 // this will max the width of the text to the cell
                }
            };

            let carouselItem = {
                layoutEngineType: LayoutEngineType.FLEX,
                gridLayout: {
                    cols: 12,
                    rows: 12,
                    cells: [{ x: 0, y: 0, width: 6, height: 12 }, { x: 6, y: 0, width: 6, height: 12 }]
                },
                items: []
            };

            if (item.layout === "right") {
                carouselItem.items.push(textCell);
                carouselItem.items.push(imageCell);
            } else {
                carouselItem.items.push(imageCell);
                carouselItem.items.push(textCell);
            }
            newItems.push(carouselItem);
        }
        this.model.items = newItems;
    }
}

class ImageCarouselItem extends CollectionItemElement {
    getElementControlBar() {
        return null;
    }

    getElementSelection() {
        return null;
    }

    get previousIndex() {
        return this.parentElement.previousIndex;
    }

    get currentIndex() {
        return this.parentElement.currentIndex;
    }

    get canSelectChildElements() {
        return this.itemIndex === this.parentElement.currentIndex;
    }

    get canSelect() {
        return this.itemIndex === this.parentElement.currentIndex;
    }

    get canRollover() {
        return this.itemIndex === this.parentElement.currentIndex;
    }

    get canDoubleClickSelectChildElements() {
        return this.itemIndex === this.parentElement.currentIndex;
    }

    get isCurrent() {
        return this.itemIndex === this.parentElement.currentIndex;
    }

    get flip() {
        switch (this.parentElement.model.flip) {
            case "left":
                return false;
            case "right":
                return true;
            case "alternate":
                return this.itemIndex % 2;
        }
    }

    get layout() {
        return this.model.layout || "left";
    }

    get contentSize() {
        return this.model.contentSize;
    }

    _build() {
        this.grid = this.addElement("grid", () => CarouselGridContainer);
    }

    _calcProps(props, options) {
        const { size } = props;

        const gridProps = this.grid.calcProps(size);
        gridProps.bounds = new geom.Rect(0, 0, size);

        return { size };
    }

    _migrate_10() {
        delete this.model.blocks; // delete any blocks property that was left in this model from switch template
    }
}

class CarouselGridContainer extends PhotoCollage {
    get name() {
        return "Carousel Grid";
    }

    get allowFullScreen() {
        return false;
    }

    getElementPropertyPanel() {
        return ImageCarouselGridContainerPropertyPanel;
    }

    _migrate_10_02() {
        // suppress PhotoCollage migration
    }
}

export const elements = {
    ImageCarousel,
};

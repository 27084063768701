import React, { Component } from "react";
import styled, { css } from "styled-components";

import { _ } from "js/vendor";
import * as geom from "js/core/utilities/geom";
import { Key } from "js/core/utilities/keys";
import { FormatType, ResizeDirection } from "js/../common/constants";
import { formatter } from "js/core/utilities/formatter";
import { stopPropagation } from "js/core/utilities/stopPropagation";
import { DragHandleStyle, ResizeHandle } from "js/editor/PresentationEditor/DragElementManager";
import { Path } from "js/core/utilities/shapes";
import WidgetButton from "js/Components/WidgetButton";
import { IconButton } from "js/Components/IconButton";
import { AbsoluteBox } from "js/react/components/LayoutGrid";
import { ShowDialog, ShowWarningDialog } from "js/react/components/Dialogs/BaseDialog";
import DataLockedDialog from "js/react/components/Dialogs/DataLockedDialog";
import { clipboardWrite, ClipboardType, clipboardRead } from "js/core/utilities/clipboard";
import { parseCSV } from "js/core/utilities/utilities";

import { TableCellControlBar } from "./TableCellControlBar";
import { TableCellEditor } from "./TableCellEditor";
import { Popup, PopupContent, PopupPreview } from "../../../../../Components/Popup";
import { Icon } from "../../../../../Components/Icon";
import { PropertyPanelContainer, PropertySection } from "../../../../../EditorComponents/PropertyPanel";
import { WithLabel } from "../../../../../Components/WithLabel";
import { Dropdown } from "../../../../../Components/Dropdown";
import { MenuItem } from "../../../../../Components/Menu";
import { ToggleSwitch } from "../../../../../Components/ToggleSwitch";
import { themeColors } from "../../../../../react/sharedStyles";
import { SelectionBorder } from "../BaseElementSelection";

const Container = styled.div`
    position: relative;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;

    pointer-events: auto;
`;

const controlOffset = 5;
const controlWidth = 30;

const ControlContainerStyles = css`
    position: absolute;

    background: ${({ isSelected }) => isSelected ? "#50bbe6" : "#d6eff9"};
    border: solid 1px #50bbe6;
    color: ${({ isSelected }) => isSelected ? "#ffffff" : "#23aae0"};
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
`;

const RowControlContainer = styled.div.attrs(({ bounds }) => ({
    style: {
        top: `${bounds.top - 0.5}px`,
        height: `${bounds.height + 1}px`,
        left: `-${controlOffset}px`,
        width: `${controlWidth}px`
    }
}))`
    transform: translate(-100%, 0);
    z-index: 1000;
    ${ControlContainerStyles}
`;

const RowPopupMenuContainer = styled.div`
    position: absolute;
    width: 25px;
    //padding: 5px;
    background: #50BBE6;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ColControlContainer = styled.div.attrs(({ bounds, isEmphasized }) => ({
    style: {
        top: `-${controlOffset - (isEmphasized ? -20 : 0)}px`,
        height: `${controlWidth}px`,
        left: `${bounds.left - 0.5}px`,
        width: `${bounds.width + 1}px`
    }
}))`
    transform: translate(0, -100%);

    ${ControlContainerStyles}
`;

const CellSelectionContainer = styled.div.attrs(({ bounds }) => ({
    style: {
        ...bounds.toObject()
    }
}))`
    position: absolute;
    pointer-events: none;
    z-index: 1000;
`;

const CellSelectionOutline = styled.svg`
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    pointer-events: none;
    overflow: visible;

    stroke: #50bbe6;
    stroke-width: 2px;
    fill: none;
    //stroke-linejoin: round;
`;

const DraggingFrame = styled.div.attrs(({ bounds }) => ({
    style: {
        ...bounds.toObject()
    }
}))`
    position: absolute;
    pointer-events: none;
    background: rgba(80, 187, 230, 0.2);
`;

const DropIndicator = styled.div.attrs(({ bounds }) => ({
    style: {
        ...bounds.toObject()
    }
}))`
    position: absolute;
    pointer-events: none;
    background: #50bbe6;
`;

const RowResizeHandle = styled.div`
    position: absolute;
    left: 0px;
    width: 100%;
    top: -3px;
    height: 6px;

    pointer-events: auto;
    background: transparent;
    cursor: row-resize;
`;

const ColResizeHandle = styled.div`
    position: absolute;
    left: -3px;
    width: 6px;
    top: 0px;
    height: 100%;

    pointer-events: auto;
    background: transparent;
    cursor: col-resize;
`;

const AddColOrRowButton = styled(WidgetButton)`
    width: 20px;
    height: 20px;

    > span {
        font-size: 18px;
    }
`;

export class TableSelection extends Component {
    constructor(props) {
        super(props);

        this.popupMenuRef = React.createRef();

        this.state = {
            selectedCells: [],
            selectedRows: [],
            selectedCols: [],
            cellsSelectionOutlinePaths: null,
            editingCell: null,
            isDragSelectingCells: false,
            isDraggingRows: false,
            isDraggingCols: false,
            isDragSelectingCols: false,
            isDragSelectingRows: false,
            isResizingCols: false,
            isResizingRows: false,
            isResizingTable: false,
            dragFrameBounds: null,
            dropIndicatorBounds: null,
            dropIndex: null,
            resizeIndex: null
        };

        this.firstSelectedCell = null;
        this.itemsBeforeResize = null;
        this.mouseDownPosition = null;
        this.resizeTableDirection = null;
        this.tableSizeBeforeResize = null;
        this.mouseDownOnColIndex = null;
        this.mouseDownOnRowIndex = null;
    }

    get element() {
        const { element } = this.props;
        return element.table;
    }

    get rows() {
        return this.element.calculatedProps.rowBounds.map((bounds, index) => ({
            unscaledBounds: bounds.clone(),
            bounds: bounds.spaceScale(this.canvasScale),
            model: this.element.model.rows[index],
            index,
            displayName: `${index + 1}`
        })).filter(({ model }) => !!model);
    }

    get cols() {
        return this.element.calculatedProps.colBounds.map((bounds, index) => ({
            unscaledBounds: bounds.clone(),
            bounds: bounds.spaceScale(this.canvasScale),
            model: this.element.model.cols[index],
            index,
            displayName: String.fromCharCode(65 + index)
        })).filter(({ model }) => !!model);
    }

    get cells() {
        return this.element.cells
            .filter(({ bounds }) => !!bounds)
            .map(({ model, bounds, styles }) => ({
                unscaledBounds: bounds.clone(),
                bounds: bounds.spaceScale(this.canvasScale),
                model,
                colIndex: model.col,
                rowIndex: model.row,
                top: model.row,
                bottom: model.row + ((model.height ?? 1) - 1),
                left: model.col,
                right: model.col + ((model.width ?? 1) - 1),
                id: `${model.col}:${model.width ?? "1"}:${model.row}:${model.height ?? 1}`,
                styles,
                isMergedCell: model.isHidden === false && model.width !== 1 && model.height !== 1,
                getText: () => {
                    if (model.format === FormatType.ICON) {
                        return model.content_value ?? "";
                    }

                    if (model.format === FormatType.PERCENT && model.cellText && !model.cellText.text.endsWith("%")) {
                        return (parseFloat(model.cellText.text) * 100) + "%";
                    }

                    return model.cellText ? model.cellText.text : "";
                },
                setText: text => {
                    if (!model.cellText) {
                        model.cellText = {};
                    }

                    const value = text.replace(/\n/g, String.fromCodePoint(13));
                    if (!model.cellText.text || model.cellText.text !== value) {
                        const detectedFormat = formatter.detectFormatFromString(value);

                        if (!(detectedFormat.format == FormatType.NUMBER && formatter.getFormatType(model.format) == "numeric") || !model.formatOptions) {
                            // Change the format to the detected format unless the cell is already formatted as a numeric format type and the detected format is number
                            model.format = detectedFormat.format;
                            // get default format options for the detected format and then merge the cell's format options
                            // Merge the detected format options into the cell's format options
                            model.formatOptions = { ...detectedFormat.formatOptions, ...model.formatOptions };
                        }

                        // Turn on decimals if detected in the format
                        if ((detectedFormat.format == FormatType.NUMBER || detectedFormat.format == FormatType.CURRENCY || detectedFormat.format == FormatType.PERCENT) && detectedFormat.formatOptions.decimal > 0) {
                            model.formatOptions.decimal = detectedFormat.formatOptions.decimal;
                        }

                        model.cellText.text = value;

                        // If this cell previously had an icon, we need to delete it's iconCell element
                        if (this.element.elements[model.id]) {
                            this.element.removeElement(this.element.elements[model.id]);
                        }

                        this.element.saveModel();
                    }
                },
                clear: () => {
                    model.cellText = { text: "" };
                    model.content_type = null;
                    model.content_value = null;

                    if (model.format === FormatType.ICON) {
                        model.format = FormatType.TEXT;
                    }
                }
            }));
    }

    get canvasScale() {
        return this.element.canvas.getScale();
    }

    get selectedCellsText() {
        const { selectedCells } = this.state;

        const left = _.minBy(selectedCells, cell => cell.left).left;
        const right = _.maxBy(selectedCells, cell => cell.right).right;
        const top = _.minBy(selectedCells, cell => cell.top).top;
        const bottom = _.maxBy(selectedCells, cell => cell.bottom).bottom;

        const textLines = [];
        for (let row = top; row <= bottom; row++) {
            const cellTexts = [];
            for (let col = left; col <= right; col++) {
                const cell = selectedCells.find(cell => cell.colIndex === col && cell.rowIndex === row);
                if (cell) {
                    cellTexts.push(`"${cell.getText()}"`);
                } else {
                    cellTexts.push(`""`);
                }
            }
            textLines.push(cellTexts.join(","));
        }

        return textLines.join("\n");
    }

    componentDidMount() {
        this.setSelectedElementState();

        document.addEventListener("keydown", this.handleKeyDown);
        document.addEventListener("paste", this.handlePaste);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyDown);
        document.removeEventListener("paste", this.handlePaste);
    }

    componentDidUpdate(prevProps, prevState) {
        const { canvasRenderKey } = this.props;

        if (prevProps.canvasRenderKey !== canvasRenderKey) {
            this.recalcSelectionOutline();
        }
    }

    handlePaste = async event => {
        const { selectedCells } = this.state;

        if (event.target.tagName === "INPUT" || event.target.tagName === "TEXTAREA") {
            return;
        }

        event.stopPropagation();
        event.preventDefault();

        let startCol = 0;
        let startRow = 0;
        if (selectedCells.length > 0) {
            const firstSelectedCell = _.minBy(selectedCells, cell => cell.top * this.cols.length + cell.left);
            startRow = firstSelectedCell.top;
            startCol = firstSelectedCell.left;
        }

        await this.selectCells([]);

        let cellModels = await clipboardRead([ClipboardType.CELLS], event);
        if (cellModels) {
            try {
                // Calculate the boundaries of the selection
                const left = _.minBy(cellModels, cell => cell.col).col;
                const right = _.maxBy(cellModels, cell => cell.col + (cell.width ?? 1)).col;
                const width = right - left + 1;
                const top = _.minBy(cellModels, cell => cell.row).row;
                const bottom = _.maxBy(cellModels, cell => cell.row + (cell.height ?? 1)).row;
                const height = bottom - top + 1;

                // Sort cell models by row and column
                cellModels = _.sortBy(cellModels, cell => cell.row * width + cell.col);

                // Adjust cell models' positions
                cellModels.forEach(model => {
                    model.col -= left;
                    model.row -= top;
                });

                const endRow = startRow + height - 1;
                const endCol = startCol + width - 1;

                for (let col = startCol; col <= endCol; col++) {
                    for (let row = startRow; row <= endRow; row++) {
                        const cell = this.cells.find(cell => cell.colIndex === col && cell.rowIndex === row);
                        if (!cell) {
                            console.warn(`Cell not found at col: ${col}, row: ${row}`);
                            continue;
                        }

                        cell.model.isHidden = false;
                        cell.clear();

                        const cellModelToPaste = cellModels.find(cellModel => cellModel.col === col - startCol && cellModel.row === row - startRow);

                        if (cellModelToPaste) {
                            // clone the cell model to ensure we compare the original model with the pasted model
                            const cellModel = _.cloneDeep(cell.model);
                            // Apply the cell model to the current cell, excluding id, row, and col
                            Object.assign(cell.model, _.omit(cellModelToPaste, ["id", "row", "col"]));

                            // Check for hidden or merged cells and update the model if necessary
                            let shouldUpdateModel = true;

                            if (!(cellModel.height === cellModelToPaste.height && cellModel.width === cellModelToPaste.width)) {
                                for (let c = col; c < col + (cellModelToPaste.width ?? 1); c++) {
                                    for (let r = row; r < row + (cellModelToPaste.height ?? 1); r++) {
                                        if (c === col && r === row) continue; // Skip the main cell
                                        const hiddenCell = this.cells.find(cell => cell.colIndex === c && cell.rowIndex === r);

                                        // if we paste merged cells, where the merged cells are going to go paste the bounds of the table, we should update only one cell
                                        if (c > this.element.totalCols - 1 || r > this.element.totalRows - 1) {
                                            shouldUpdateModel = false;
                                            break;
                                        }

                                        if (hiddenCell && (hiddenCell.model.isHidden || hiddenCell.model.width > 1 || hiddenCell.model.height > 1)) {
                                            shouldUpdateModel = false;
                                            break;
                                        }
                                    }
                                    if (!shouldUpdateModel) break;
                                }

                                // Update the model if any hidden or merged cells were found
                                if (!shouldUpdateModel) {
                                    cell.model.width = 1;
                                    cell.model.height = 1;
                                }
                            }

                            if (shouldUpdateModel) {
                            // If the cell model spans multiple rows or columns, set the corresponding cells as hidden
                                if (cellModelToPaste.height > 1 || cellModelToPaste.width > 1) {
                                    for (let c = col; c < col + (cellModelToPaste.width ?? 1); c++) {
                                        for (let r = row; r < row + (cellModelToPaste.height ?? 1); r++) {
                                            if (c === col && r === row) continue; // Skip the main cell
                                            const hiddenCell = this.cells.find(cell => cell.colIndex === c && cell.rowIndex === r);
                                            if (hiddenCell) {
                                                hiddenCell.model.isHidden = true;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                await this.element.saveModel();

                const newSelectedCells = this.cells.filter(cell => cell.colIndex >= startCol && cell.colIndex <= endCol && cell.rowIndex >= startRow && cell.rowIndex <= endRow && !cell.model.isHidden);
                await this.selectCells(newSelectedCells);
            } catch (err) {
                ShowWarningDialog({
                    title: "An error occurred while pasting"
                });
            }
            return;
        }

        let text = await clipboardRead([ClipboardType.TEXT], event);
        if (text) {
            // Pasting on windows adds an extra return in the clipboard data
            if (window.navigator.platform.indexOf("Win") > -1 && (text.charCodeAt(text.length - 1) === 13 || text.charCodeAt(text.length - 1) === 10)) {
                text = text.substring(0, text.length - 1);
            }

            const processedData = parseCSV(text, String.fromCharCode(9));
            try {
                if (this.element.parentElement.hasDataSourceLink()) {
                    this.element.parentElement.model.dataSourceLink = null;
                    this.element.parentElement.removeDataSource();
                }

                this.element.updateTableData(processedData, false, selectedCells);
            } catch (err) {
                ShowWarningDialog({
                    title: "Unable to parse clipboard",
                    message: "Sorry, we weren't able to parse the data in your clipboard into something we could use to populate a table. Try copying a range of cells from Microsoft Excel, Google Sheets, or similar spreadsheet application.",
                });
            }
            return;
        }
    }

    cellsIntersectVertically(cellA, cellB) {
        // Determine if either cell is a merged cell
        const isMergedCell = cellA.isMergedCell || cellB.isMergedCell;

        if (isMergedCell) {
            // Check if the vertical ranges of cellA and cellB overlap
            return (cellA.row <= cellB.bottom && cellA.bottom >= cellB.row);
        }

        return (cellA.top === cellB.top && cellA.bottom === cellB.bottom) || (cellA.top > cellB.top && cellA.top < cellB.bottom + 1) || (cellA.bottom + 1 > cellB.top && cellA.bottom + 1 < cellB.bottom + 1);
    }

    cellsIntersectHorizontally(cellA, cellB) {
        // Determine if either cell is a merged cell
        const isMergedCell = cellA.isMergedCell || cellB.isMergedCell;

        if (isMergedCell) {
            // Check if the horizontal ranges of cellA and cellB overlap
            return (cellA.col <= cellB.right && cellA.right >= cellB.col);
        }

        return (cellA.left === cellB.left && cellA.right === cellB.right) || (cellA.left > cellB.left && cellA.left < cellB.right + 1) || (cellA.right + 1 > cellB.left && cellA.right + 1 < cellB.right + 1);
    }

    cellsAreAdjacent(cellA, cellB) {
        return ((cellA.left === cellB.right + 1 || cellA.right + 1 === cellB.left) && this.cellsIntersectVertically(cellA, cellB)) || ((cellA.top === cellB.bottom + 1 || cellA.bottom + 1 === cellB.top) && this.cellsIntersectHorizontally(cellA, cellB));
    }

    recalcSelectionOutline() {
        const { selectedCells } = this.state;

        if (selectedCells.length === 0) {
            return this.setState({ cellsSelectionOutlinePaths: null });
        }

        const cells = this.cells
            .filter(cell => selectedCells.some(selectedCell => !selectedCell.model.isHidden && selectedCell.id === cell.id));

        const cellClusters = [];
        const visitedCells = new Set();

        const getAdjacentCells = (cell, cells) => {
            return cells.filter(otherCell => this.cellsAreAdjacent(cell, otherCell));
        };

        const clusterCells = (startCell, cells) => {
            const cluster = [];
            const queue = [startCell];

            while (queue.length > 0) {
                const currentCell = queue.shift();

                if (visitedCells.has(currentCell.id)) continue;
                visitedCells.add(currentCell.id);
                cluster.push(currentCell);

                const adjacentCells = getAdjacentCells(currentCell, cells);
                for (const adjacentCell of adjacentCells) {
                    if (!visitedCells.has(adjacentCell.id)) {
                        queue.push(adjacentCell);
                    }
                }
            }

            return cluster;
        };

        for (const cell of cells) {
            if (!visitedCells.has(cell.id)) {
                const cluster = clusterCells(cell, cells);
                cellClusters.push(cluster);
            }
        }

        const getCellsOutlinePath = cells => {
            const path = new Path();

            for (const cell of cells) {
                const hasCellOnLeft = cells.some(c => this.cellsIntersectVertically(c, cell) && cell.left === c.right + 1);
                const hasCellOnRight = cells.some(c => this.cellsIntersectVertically(c, cell) && cell.right === c.left - 1);
                const hasCellOnTop = cells.some(c => this.cellsIntersectHorizontally(c, cell) && cell.top === c.bottom + 1);
                const hasCellOnBottom = cells.some(c => this.cellsIntersectHorizontally(c, cell) && cell.bottom === c.top - 1);

                if (!hasCellOnLeft) {
                    path.moveTo(cell.bounds.left, cell.bounds.top);
                    path.lineTo(cell.bounds.left, cell.bounds.bottom);
                }
                if (!hasCellOnRight) {
                    path.moveTo(cell.bounds.right, cell.bounds.top);
                    path.lineTo(cell.bounds.right, cell.bounds.bottom);
                }
                if (!hasCellOnTop) {
                    path.moveTo(cell.bounds.left, cell.bounds.top);
                    path.lineTo(cell.bounds.right, cell.bounds.top);
                }
                if (!hasCellOnBottom) {
                    path.moveTo(cell.bounds.left, cell.bounds.bottom);
                    path.lineTo(cell.bounds.right, cell.bounds.bottom);
                }
            }

            path.close();

            return path;
        };

        return this.setState({
            cellsSelectionOutlinePaths: cellClusters.map(c => getCellsOutlinePath(c)),
            selectedCells: cells
        });
    }

    setSelectedElementState() {
        const { element, selectionLayerController } = this.props;
        const { selectedCells, selectedCols, selectedRows } = this.state;

        selectionLayerController.setSelectedElementState(element.uniquePath, {
            selectedCells,
            selectedCols,
            selectedRows,
            addColAtIndex: this.addColAtIndex,
            addRowAtIndex: this.addRowAtIndex,
            deleteColAtIndex: this.deleteColAtIndex,
            deleteRowAtIndex: this.deleteRowAtIndex,
        });
    }

    unionCells(...cellsArrays) {
        return _.unionBy(...cellsArrays, "id").filter(Boolean);
    }

    ensureCellsAreRect(cells, filterOutHiddenCells = true) {
        if (cells.length > 1) {
            const leftTop = [
                _.minBy(cells, cell => cell.left).left,
                _.minBy(cells, cell => cell.top).top
            ];
            const rightBottom = [
                _.maxBy(cells, cell => cell.right).right,
                _.maxBy(cells, cell => cell.bottom).bottom
            ];

            let cellsInRect = this.cells.filter(cell => cell.right >= leftTop[0] && cell.left <= rightBottom[0] && cell.bottom >= leftTop[1] && cell.top <= rightBottom[1]);
            if (filterOutHiddenCells) {
                cellsInRect = cellsInRect.filter(cell => !cell.model.isHidden);
            }
            return cellsInRect;
        }

        return cells;
    }

    getMousePosition(event) {
        const { canvasController, containerBounds } = this.props;
        return new geom.Point(event.pageX, event.pageY)
            .minus(canvasController.canvasScreenBounds.position)
            .minus(containerBounds.position);
    }

    setMouseDownPosition(event) {
        this.mouseDownPosition = this.getMousePosition(event);
    }

    selectCells = async (cells, isDragSelectingCells = false) => {
        if (!this.props.element.model.showTopLeftCell) {
            cells = cells.filter(cell => cell.model.row !== 0 || cell.model.col !== 0);
        }

        await this.setState({
            selectedCells: cells,
            selectedCols: [],
            selectedRows: [],
            editingCell: null,
            isDragSelectingCells
        });

        if (cells.length === 1) {
            this.firstSelectedCell = cells[0];
        }
        this.recalcSelectionOutline();
        this.setSelectedElementState();
    }

    selectCols = async (cols, isDragSelectingCols = false, selectColumnRange = true) => {
        if (cols.length > 1 && selectColumnRange) {
            const minIndex = _.minBy(cols, "index").index;
            const maxIndex = _.maxBy(cols, "index").index;
            const indexes = _.range(minIndex, maxIndex + 1, 1);
            cols = this.cols.filter(col => indexes.includes(col.index));
        }

        await this.setState({
            selectedCols: cols,
            selectedRows: [],
            selectedCells: this.cells.filter(cell => cols.some(col => col.index === cell.colIndex)),
            editingCell: null,
            isDragSelectingCols
        });

        this.firstSelectedCell = null;
        this.recalcSelectionOutline();
        this.setSelectedElementState();
    }

    selectRows = async (rows, isDragSelectingRows = false) => {
        if (rows.length > 1) {
            const minIndex = _.minBy(rows, "index").index;
            const maxIndex = _.maxBy(rows, "index").index;
            const indexes = _.range(minIndex, maxIndex + 1, 1);
            rows = this.rows.filter(row => indexes.includes(row.index));
        }

        await this.setState({
            selectedRows: rows,
            selectedCols: [],
            selectedCells: this.cells.filter(cell => rows.some(row => row.index === cell.rowIndex)),
            editingCell: null,
            isDragSelectingRows
        });

        this.firstSelectedCell = null;
        this.recalcSelectionOutline();
        this.setSelectedElementState();
    }

    removeCellContent(cellModel) {
        if (cellModel.cellText) {
            cellModel.cellText.text = "";
        }
        if (cellModel.format == FormatType.ICON) {
            cellModel.content_type = null;
            cellModel.content_value = null;
            cellModel.format = "text";
        }
    }

    deleteCell(cellModel) {
        this.element.model.cells.remove(cellModel);
        if (this.element.elements[cellModel.id]) {
            delete this.element.elements[cellModel.id];
        }
    }

    deleteCol(colModel) {
        if (this.cols.length == 1) {
            return;
        }

        this.selectCells([]);

        const getCellsIntersectingCol = () => this.cells.filter(cell => cell.left <= colModel.index && cell.right >= colModel.index);

        // First, unmerge cells intersecting the col
        getCellsIntersectingCol().filter(cell => cell.colIndex !== colModel.index).forEach(cell => this.unmergeCell(cell));

        // Then see if there are merged cells in the col and unmerge them too
        getCellsIntersectingCol().filter(cell => cell.colIndex === colModel.index).forEach(cell => this.unmergeCell(cell));

        this.element.model.cols.remove(colModel);

        for (const cell of _.filter(this.element.model.cells, { col: colModel.index })) {
            this.deleteCell(cell);
        }

        for (const col of _.filter(this.element.model.cols, c => c.index > colModel.index)) {
            col.index--;
        }
        for (const cell of _.filter(this.element.model.cells, cell => cell.col > colModel.index)) {
            cell.col--;
        }

        this.element.generateCells();
    }

    deleteRow(rowModel) {
        if (this.rows.length == 1) {
            return;
        }

        this.selectCells([]);

        const getCellsIntersectingRow = () => this.cells.filter(cell => cell.top <= rowModel.index && cell.bottom >= rowModel.index);

        // First, unmerge cells intersecting the row
        getCellsIntersectingRow().filter(cell => cell.rowIndex !== rowModel.index).forEach(cell => this.unmergeCell(cell));

        // Then see if there are merged cells in the row and unmerge them too
        getCellsIntersectingRow().filter(cell => cell.rowIndex === rowModel.index).forEach(cell => this.unmergeCell(cell));

        this.element.model.rows.remove(rowModel);

        for (const cell of _.filter(this.element.model.cells, { row: rowModel.index })) {
            this.deleteCell(cell);
        }

        for (const row of _.filter(this.element.model.rows, r => r.index > rowModel.index)) {
            row.index--;
        }
        for (const cell of _.filter(this.element.model.cells, cell => cell.row > rowModel.index)) {
            cell.row--;
        }

        this.element.generateCells();
    }

    unmergeCell(mergedCell) {
        const startCol = mergedCell.model.col;
        const endCol = mergedCell.model.col + mergedCell.model.width;
        const startRow = mergedCell.model.row;
        const endRow = mergedCell.model.row + mergedCell.model.height;

        const unmergedCells = this.cells.filter(cell => cell.model.col >= startCol && cell.model.col < endCol && cell.model.row >= startRow && cell.model.row < endRow);
        unmergedCells.forEach(cell => {
            cell.model.isHidden = false;
            cell.model.width = cell.model.height = 1;
        });
    }

    deleteColAtIndex = index => {
        const colModel = this.cols[index].model;
        return this.deleteCol(colModel);
    }

    deleteRowAtIndex = index => {
        const rowModel = this.rows[index].model;
        return this.deleteRow(rowModel);
    }

    getNextCell(fromCell, direction) {
        const { left, top, bottom, right } = fromCell;

        const colIndex = ["left", "right"].includes(direction) ? (direction === "left" ? left : right) : fromCell.colIndex;
        const rowIndex = ["up", "down"].includes(direction) ? (direction === "up" ? top : bottom) : fromCell.rowIndex;

        const nextCol = Math.max(0, Math.min(this.cols.length - 1, colIndex + (direction === "left" ? -1 : direction === "right" ? 1 : 0)));
        const nextRow = Math.max(0, Math.min(this.rows.length - 1, rowIndex + (direction === "up" ? -1 : direction === "down" ? 1 : 0)));

        return this.cells
            .filter(cell => !cell.model.isHidden)
            .find(cell => cell.top <= nextRow && cell.bottom >= nextRow && cell.left <= nextCol && cell.right >= nextCol);
    }

    addColAtIndex = async index => {
        const colSize = this.element.model.cols[index - 1]?.size ?? (this.element.MIN_COL_WIDTH * 2);

        for (const column of _.filter(this.element.model.cols, c => c.index >= index)) {
            column.index++;
        }
        for (const cell of _.filter(this.element.model.cells, cell => cell.col >= index)) {
            cell.col++;
        }

        this.element.model.cols.insert({
            index: index,
            break: false,
            style: "defaultCol",
            size: colSize
        }, index);

        for (let row = 0; row < this.element.model.rows.length; row++) {
            if (index > 0) {
                const cellToLeft = this.element.getCell(index - 1, row);
                this.element.model.cells.push({
                    col: index,
                    row: row,
                    format: cellToLeft.format === FormatType.ICON ? FormatType.TEXT : cellToLeft.format,
                    formatOptions: cellToLeft.format === FormatType.ICON ? null : cellToLeft.formatOptions,
                });
            } else {
                this.element.model.cells.push({
                    col: index,
                    row: row,
                    format: FormatType.TEXT
                });
            }
        }

        const tableWidth = this.element.model.tableWidth;
        const actualTableWidth = tableWidth * this.element.calculatedProps.allowedSize.width;
        if (actualTableWidth < this.element.calculatedProps.allowedSize.width) {
            this.element.model.tableWidth = Math.min(1, (actualTableWidth + this.element.MIN_COL_WIDTH) / this.element.calculatedProps.allowedSize.width);
        }

        this.element.generateCells();

        await this.element.saveModel();

        this.selectCols([this.cols[index]], false);
    }

    addRowAtIndex = async index => {
        for (const row of _.filter(this.element.model.rows, r => r.index >= index)) {
            row.index++;
        }
        for (const cell of _.filter(this.element.model.cells, cell => cell.row >= index)) {
            cell.row++;
        }

        this.element.model.rows.insert({
            index: index,
            break: false,
            style: "defaultRow",
            size: this.element.MIN_ROW_HEIGHT
        }, index);

        for (let col = 0; col < this.element.model.cols.length; col++) {
            if (index > 0) {
                const cellAbove = this.element.getCell(col, index - 1);
                this.element.model.cells.push({
                    col: col,
                    row: index,
                    format: cellAbove.format,
                    formatOptions: cellAbove.formatOptions,
                    content_type: cellAbove.format === FormatType.ICON ? cellAbove.model.content_type : null,
                    content_value: cellAbove.format === FormatType.ICON ? cellAbove.model.content_value : null
                });
            } else {
                this.element.model.cells.push({
                    col: col,
                    row: index,
                    format: FormatType.TEXT
                });
            }
        }

        const tableHeight = this.element.model.tableHeight;
        const actualTableHeight = tableHeight * this.element.calculatedProps.allowedSize.height;
        if (actualTableHeight < this.element.calculatedProps.allowedSize.height) {
            this.element.model.tableHeight = Math.min(1, (actualTableHeight + this.element.MIN_ROW_HEIGHT) / this.element.calculatedProps.allowedSize.height);
        }

        this.element.generateCells();

        await this.element.saveModel();

        this.selectRows([this.rows[index]], false);
    }

    startEditingCell = cell => {
        if (!this.element.model.showTopLeftCell && cell.model.row == 0 && cell.model.col == 0) {
            return;
        }

        if (this.element.parentElement.hasDataSourceLink()) {
            ShowDialog(DataLockedDialog, { element: this.element });
            return;
        }

        this.setState({
            editingCell: cell
        });
    }

    handleSelectCells = cells => this.selectCells(cells);

    handleMoveCellSelection = (direction, extend = false, cycle = false) => {
        const { selectedCells } = this.state;
        if (selectedCells.length === 0) {
            return;
        }

        if (extend) {
            const leftCell = _.minBy(selectedCells, cell => cell.colIndex);
            const rightCell = _.maxBy(selectedCells, cell => cell.colIndex);
            const topCell = _.minBy(selectedCells, cell => cell.rowIndex);
            const bottomCell = _.maxBy(selectedCells, cell => cell.rowIndex);

            const rowCount = _.uniqBy(selectedCells, cell => cell.rowIndex).length;
            const colCount = _.uniqBy(selectedCells, cell => cell.colIndex).length;

            const setSelectedCells = (...cells) => this.selectCells(this.ensureCellsAreRect(this.unionCells(...cells)));

            if (direction === "left" || direction === "right") {
                const forwardCell = direction === "left" ? leftCell : rightCell;
                const backwardCell = direction === "left" ? rightCell : leftCell;
                if (this.firstSelectedCell.colIndex === forwardCell.colIndex) {
                    if (colCount === 1) {
                        const nextCell = this.getNextCell(backwardCell, direction);
                        setSelectedCells(selectedCells, [nextCell]);
                        return;
                    }

                    setSelectedCells(selectedCells.filter(cell => cell.colIndex !== backwardCell.colIndex));
                    return;
                }

                const nextCell = this.getNextCell(forwardCell, direction);
                setSelectedCells(selectedCells, [nextCell]);
                return;
            }

            if (direction === "up" || direction === "down") {
                const forwardCell = direction === "up" ? topCell : bottomCell;
                const backwardCell = direction === "up" ? bottomCell : topCell;
                if (this.firstSelectedCell.rowIndex === forwardCell.rowIndex) {
                    if (rowCount === 1) {
                        const nextCell = this.getNextCell(backwardCell, direction);
                        setSelectedCells(selectedCells, [nextCell]);
                        return;
                    }

                    setSelectedCells(selectedCells.filter(cell => cell.rowIndex !== backwardCell.rowIndex));
                    return;
                }

                const nextCell = this.getNextCell(forwardCell, direction);
                setSelectedCells(selectedCells, [nextCell]);
                return;
            }
            return;
        }

        const firstSelectedCell = _.minBy(selectedCells, cell => cell.top * this.cols.length + cell.left);
        const lastSelectedCell = _.maxBy(selectedCells, cell => cell.bottom * this.cols.length + cell.right);

        let nextCell = this.getNextCell(direction === "right" || direction === "down" ? lastSelectedCell : firstSelectedCell, direction);
        if (!nextCell || selectedCells.some(cell => cell.id === nextCell.id)) {
            if (!cycle) {
                return;
            }

            let rowIndex, colIndex;
            if (direction === "right" || direction === "down") {
                const isInLastRow = lastSelectedCell.bottom === this.rows.length - 1;
                const isInLastCol = lastSelectedCell.right === this.cols.length - 1;

                if (direction === "right") {
                    if (isInLastCol) {
                        colIndex = 0;
                        rowIndex = isInLastRow ? 0 : lastSelectedCell.bottom + 1;
                    } else if (isInLastRow) {
                        colIndex = lastSelectedCell.right + 1;
                        rowIndex = 0;
                    }
                } else if (direction === "down") {
                    if (isInLastRow) {
                        rowIndex = 0;
                        colIndex = isInLastCol ? 0 : lastSelectedCell.right + 1;
                    } else if (isInLastCol) {
                        rowIndex = lastSelectedCell.bottom + 1;
                        colIndex = 0;
                    }
                }
            }

            if (direction === "left" || direction === "up") {
                const isInFirstRow = firstSelectedCell.top === 0;
                const isInFirstCol = firstSelectedCell.left === 0;

                if (direction === "left") {
                    if (isInFirstCol) {
                        colIndex = this.cols.length - 1;
                        rowIndex = isInFirstRow ? this.rows.length - 1 : firstSelectedCell.top - 1;
                    } else if (isInFirstRow) {
                        colIndex = firstSelectedCell.left - 1;
                        rowIndex = this.rows.length - 1;
                    }
                } else if (direction === "up") {
                    if (isInFirstRow) {
                        rowIndex = this.rows.length - 1;
                        colIndex = isInFirstCol ? this.cols.length - 1 : firstSelectedCell.left - 1;
                    } else if (isInFirstCol) {
                        rowIndex = firstSelectedCell.top - 1;
                        colIndex = this.cols.length - 1;
                    }
                }
            }

            if (rowIndex !== undefined && colIndex !== undefined) {
                nextCell = this.cells
                    .filter(cell => !cell.model.isHidden)
                    .find(cell => cell.top <= rowIndex && cell.bottom >= rowIndex && cell.left <= colIndex && cell.right >= colIndex);
            }
        }

        if (nextCell && !selectedCells.some(cell => cell.id === nextCell.id)) {
            this.selectCells([nextCell].filter(Boolean));
        }
    }

    handleColControlMouseDown = (event, colIndex) => {
        this.setMouseDownPosition(event);

        const { selectedCols } = this.state;

        if (selectedCols.some(col => col.index === colIndex)) {
            this.mouseDownOnColIndex = colIndex;
        } else {
            let newSelectedCols = [this.cols[colIndex]];
            if (event.shiftKey || event.altKey || event.metaKey) {
                const colIndexes = [...new Set([colIndex, ...selectedCols.map(col => col.index)])];
                newSelectedCols = this.cols.filter(col => colIndexes.includes(col.index));
            }
            this.selectCols(newSelectedCols, true, !event.metaKey);
        }

        window.addEventListener("mousemove", this.handleMouseMove);
        window.addEventListener("mouseup", this.handleMouseUp);
    }

    handleRowControlMouseDown = (event, rowIndex) => {
        this.setMouseDownPosition(event);

        const { selectedRows } = this.state;

        if (selectedRows.some(row => row.index === rowIndex)) {
            this.mouseDownOnRowIndex = rowIndex;
        } else {
            let newSelectedRows = [this.rows[rowIndex]];
            if (event.shiftKey || event.altKey || event.metaKey) {
                const rowIndexes = [...new Set([rowIndex, ...selectedRows.map(row => row.index)])];
                newSelectedRows = this.rows.filter(row => rowIndexes.includes(row.index));
            }
            this.selectRows(newSelectedRows, true);
        }

        window.addEventListener("mousemove", this.handleMouseMove);
        window.addEventListener("mouseup", this.handleMouseUp);
    }

    handleColResizeMouseDown = (event, colIndex) => {
        this.setMouseDownPosition(event);

        this.itemsBeforeResize = this.cols;
        this.itemsBeforeResize.forEach(item => item.originalModelSize = item.model.size);

        this.setState({
            isResizingCols: true,
            resizeIndex: colIndex
        });

        window.addEventListener("mousemove", this.handleMouseMove);
        window.addEventListener("mouseup", this.handleMouseUp);
    }

    handleRowResizeMouseDown = (event, rowIndex) => {
        this.setMouseDownPosition(event);

        this.itemsBeforeResize = this.rows;
        this.itemsBeforeResize.forEach(item => item.originalModelSize = item.model.size);

        this.setState({
            isResizingRows: true,
            resizeIndex: rowIndex
        });

        window.addEventListener("mousemove", this.handleMouseMove);
        window.addEventListener("mouseup", this.handleMouseUp);
    }

    handleCellMouseDown = event => {
        this.setMouseDownPosition(event);

        const { selectedCells: currentSelectedCells } = this.state;

        let selectedCells = this.cells.filter(cell =>
            cell.bounds.contains(this.mouseDownPosition) &&
            !cell.model.isHidden
        );

        if (
            selectedCells.length === 1 &&
            currentSelectedCells.length === 1 &&
            selectedCells[0].id === currentSelectedCells[0].id &&
            selectedCells[0].model.format !== FormatType.ICON
        ) {
            this.startEditingCell(selectedCells[0]);
            return;
        }

        if (event.shiftKey) {
            selectedCells = this.ensureCellsAreRect(this.unionCells(currentSelectedCells, selectedCells));
        } else if (event.metaKey || event.ctrlKey) {
            selectedCells = this.unionCells(currentSelectedCells, selectedCells);
        }

        const isDragSelectingCells = selectedCells.length > 0;

        this.selectCells(selectedCells, isDragSelectingCells);

        if (isDragSelectingCells) {
            window.addEventListener("mousemove", this.handleMouseMove);
            window.addEventListener("mouseup", this.handleMouseUp);
        }
    }

    handleTableResizeMouseDown = (event, direction) => {
        this.setMouseDownPosition(event);

        this.selectCells([]);

        this.resizeTableDirection = direction;
        this.tableSizeBeforeResize = new geom.Size(this.element.model.tableWidth, this.element.model.tableHeight);

        this.setState({
            isResizingTable: true
        });

        window.addEventListener("mousemove", this.handleMouseMove);
        window.addEventListener("mouseup", this.handleMouseUp);
    }

    handleMouseMove = event => {
        let {
            isDragSelectingCells,
            isDragSelectingCols,
            isDragSelectingRows,
            isDraggingCols,
            isDraggingRows,
            isResizingCols,
            isResizingRows,
            isResizingTable,
            resizeIndex,
            selectedCols,
            selectedRows
        } = this.state;

        const mousePosition = this.getMousePosition(event);

        if (this.mouseDownOnColIndex !== null && mousePosition.distance(this.mouseDownPosition) > 5) {
            const dragFrameBounds = selectedCols.reduce((bounds, col) => bounds ? bounds.union(col.bounds) : col.bounds, null);
            this.setState({
                isDraggingCols: true,
                dragFrameBounds
            });
            isDraggingCols = true;
            this.mouseDownOnColIndex = null;
        }

        if (this.mouseDownOnRowIndex !== null && mousePosition.distance(this.mouseDownPosition) > 5) {
            const dragFrameBounds = selectedRows.reduce((bounds, row) => bounds ? bounds.union(row.bounds) : row.bounds, null);
            this.setState({
                isDraggingRows: true,
                dragFrameBounds
            });
            isDraggingRows = true;
            this.mouseDownOnRowIndex = null;
        }

        const selectionBounds = new geom.Rect(
            Math.min(this.mouseDownPosition.x, mousePosition.x),
            Math.min(this.mouseDownPosition.y, mousePosition.y),
            Math.abs(mousePosition.x - this.mouseDownPosition.x),
            Math.abs(mousePosition.y - this.mouseDownPosition.y)
        );
        const dragOffset = mousePosition.minus(this.mouseDownPosition);

        if (isDragSelectingCells && (Math.abs(dragOffset.x) > 10 || Math.abs(dragOffset.y) > 10)) {
            const selectedCells = this.ensureCellsAreRect(this.cells.filter(cell => cell.bounds.intersects(selectionBounds)));
            if (event.shiftKey || event.altKey || event.metaKey) {
                this.selectCells(this.unionCells(selectedCells, this.state.selectedCells), true);
            } else {
                this.selectCells(selectedCells, true);
            }
            return;
        }

        if (isDragSelectingCols) {
            let newSelectedCols = this.cols.filter(col => col.bounds.inflate({ top: 9999, bottom: 9999 }).intersects(selectionBounds));
            if (event.shiftKey || event.altKey || event.metaKey) {
                const colIndexes = [...new Set([...newSelectedCols.map(col => col.index), ...selectedCols.map(col => col.index)])];
                newSelectedCols = this.cols.filter(col => colIndexes.includes(col.index));
            }
            this.selectCols(newSelectedCols, true);
            return;
        }

        if (isDragSelectingRows) {
            let newSelectedRows = this.rows.filter(col => col.bounds.inflate({ left: 9999, right: 9999 }).intersects(selectionBounds));
            if (event.shiftKey || event.altKey || event.metaKey) {
                const rowIndexes = [...new Set([...newSelectedRows.map(row => row.index), ...selectedRows.map(row => row.index)])];
                newSelectedRows = this.rows.filter(row => rowIndexes.includes(row.index));
            }
            this.selectRows(newSelectedRows, true);
            return;
        }

        if (isDraggingCols || isDraggingRows) {
            const { selectedCols, selectedRows } = this.state;

            const selectedItems = isDraggingCols ? selectedCols : selectedRows;
            const selectedItemsBounds = selectedItems.reduce((bounds, col) => bounds ? bounds.union(col.bounds) : col.bounds, null);
            const dragFrameBounds = selectedItemsBounds.offset(isDraggingCols ? dragOffset.x : 0, isDraggingRows ? dragOffset.y : 0);

            let dropIndicatorBounds = null;
            let dropIndex = null;

            const fore = isDraggingCols ? "left" : "top";
            const aft = isDraggingCols ? "right" : "bottom";
            const size = isDraggingCols ? "width" : "height";

            const items = isDraggingCols ? this.cols : this.rows;
            for (const item of items) {
                if (selectedItems.some(({ index }) => index === item.index)) {
                    continue;
                }

                if (item.bounds.intersection(dragFrameBounds)[size] > item.bounds[size] / 2) {
                    if (item.index < selectedItems[0].index) {
                        dropIndicatorBounds = item.bounds.deflate({ [fore]: item.bounds[size] - 2 });
                        dropIndex = item.index + 1;
                    } else {
                        dropIndicatorBounds = item.bounds.deflate({ [aft]: item.bounds[size] - 2 });
                        dropIndex = item.index;
                    }
                    break;
                }
            }

            if (!dropIndicatorBounds) {
                const firstItemBounds = items[0].bounds;
                if (dragFrameBounds[aft] < firstItemBounds[fore] + firstItemBounds[size] / 2) {
                    dropIndicatorBounds = firstItemBounds.deflate({ [aft]: firstItemBounds[size] - 2 });
                    dropIndex = 0;
                }

                const lastItemBounds = items[items.length - 1].bounds;
                if (dragFrameBounds[fore] > lastItemBounds[aft] - lastItemBounds[size] / 2) {
                    dropIndicatorBounds = lastItemBounds.deflate({ [fore]: lastItemBounds[size] - 2 });
                    dropIndex = items.length;
                }
            }

            this.setState({ dragFrameBounds, dropIndicatorBounds, dropIndex });
        }

        if (isResizingCols || isResizingRows) {
            const originalResizeItem = this.itemsBeforeResize[resizeIndex];
            const prevOriginalResizeItem = this.itemsBeforeResize[resizeIndex - 1];

            const minItemSize = isResizingCols ? this.element.MIN_COL_WIDTH : this.element.MIN_ROW_HEIGHT;
            const maxOffsets = [
                -prevOriginalResizeItem.unscaledBounds[isResizingCols ? "width" : "height"] + minItemSize,
                originalResizeItem.unscaledBounds[isResizingCols ? "width" : "height"] - minItemSize
            ];

            const offset =
                Math.clamp(dragOffset[isResizingCols ? "x" : "y"] / this.canvasScale, ...maxOffsets) /
                this.element.calculatedProps[isResizingCols ? "baseColWidth" : "baseRowHeight"];

            prevOriginalResizeItem.model.size = prevOriginalResizeItem.originalModelSize + offset;
            originalResizeItem.model.size = originalResizeItem.originalModelSize - offset;

            this.element.refreshElement();
            this.recalcSelectionOutline();
        }

        if (isResizingTable) {
            const offset = dragOffset.scale(1 / this.canvasScale);
            offset.x = offset.x / this.element.parentElement.bounds.width;
            offset.y = offset.y / this.element.parentElement.bounds.height;

            if (this.resizeTableDirection === ResizeDirection.RIGHT) {
                this.element.model.tableWidth = Math.clamp(this.tableSizeBeforeResize.width + offset.x, 0.1, 1);
            } else {
                this.element.model.tableHeight = Math.clamp(this.tableSizeBeforeResize.height + offset.y, 0.1, 1);
            }

            this.element.refreshElement();
            this.recalcSelectionOutline();
        }
    }

    handleMouseUp = event => {
        window.removeEventListener("mousemove", this.handleMouseMove);
        window.removeEventListener("mouseup", this.handleMouseUp);

        const {
            selectedCols,
            selectedRows,
            isDraggingCols,
            isDraggingRows,
            isResizingCols,
            isResizingRows,
            isResizingTable,
            dropIndex
        } = this.state;

        if (event.button === 2 && this.popupMenuRef.current) {
            this.popupMenuRef.current.showPopup();
        }

        if (this.mouseDownOnColIndex !== null) {
            this.selectCols([this.cols[this.mouseDownOnColIndex]], false);
            this.mouseDownOnColIndex = null;
        }

        if (this.mouseDownOnRowIndex !== null) {
            this.selectRows([this.rows[this.mouseDownOnRowIndex]], false);
            this.mouseDownOnRowIndex = null;
        }

        if ((isDraggingCols || isDraggingRows) && typeof dropIndex === "number") {
            this.selectCells([]);

            const selectedItems = isDraggingCols ? selectedCols : selectedRows;
            const firstItemIndex = _.minBy(selectedItems, "index").index;
            if (firstItemIndex !== dropIndex) {
                // Will be manipulating cols/rows model
                const items = isDraggingCols ? this.element.model.cols : this.element.model.rows;

                // Temp assign each item it's cells
                for (const item of items) {
                    item.cells = isDraggingCols ? this.element.getCellsInColumn(item.index) : this.element.getCellsInRow(item.index);
                }

                // Adjust drop index
                let adjustedDropIndex = dropIndex;
                if (adjustedDropIndex > firstItemIndex) {
                    adjustedDropIndex -= selectedItems.length;
                }
                // Remove the selected items from the items array
                for (const selecteItem of selectedItems) {
                    items.splice(items.indexOf(selecteItem.model), 1);
                }

                // Insert the selected items at the adjusted drop index
                for (const selecteItem of selectedItems) {
                    items.insert(selecteItem.model, adjustedDropIndex);
                    adjustedDropIndex++;
                }

                // Reset the col property on all the items and their cells
                for (let i = 0; i < items.length; i++) {
                    items[i].index = i;
                    for (const cell of items[i].cells) {
                        if (isDraggingCols) {
                            cell.model.col = i;
                        } else {
                            cell.model.row = i;
                        }
                    }
                    items[i].cells = null;
                }

                this.element.saveModel();
            }
        }

        if (isResizingCols || isResizingRows || isResizingTable) {
            this.element.saveModel();
        }

        this.mouseDownPosition = null;
        this.itemsBeforeResize = null;
        this.resizeTableDirection = null;
        this.tableSizeBeforeResize = null;

        this.setState({
            isDragSelectingCells: false,
            isDragSelectingCols: false,
            isDragSelectingRows: false,
            isDraggingCols: false,
            isDraggingRows: false,
            isResizingCols: false,
            isResizingRows: false,
            isResizingTable: false,
            dragFrameBounds: null,
            dropIndicatorBounds: null,
            dropIndex: null,
            resizeIndex: null
        });
    }

    handleCopySelectedCells = async () => {
        const { selectedCells } = this.state;

        await clipboardWrite({
            [ClipboardType.CELLS]: selectedCells.map(cell => _.cloneDeep(cell.model)),
            [ClipboardType.TEXT]: this.selectedCellsText,
        });
    }

    handleCutSelectedCells = async () => {
        const { selectedCells } = this.state;

        await this.handleCopySelectedCells();

        for (const cell of selectedCells) {
            cell.clear();
        }

        await this.element.saveModel();
    }

    handleKeyDown = event => {
        const { selectedCells, editingCell, selectedCols, selectedRows } = this.state;

        if (editingCell) {
            return;
        }

        if (selectedCells.length === 0) {
            return;
        }

        if (event.which === 16 || event.which === 17 || event.which === 91) {
            return;
        }

        if (event.target.tagName === "INPUT" || event.target.tagName === "TEXTAREA") {
            return;
        }

        switch (event.which) {
            case Key.KEY_C:
                if (event.metaKey || event.ctrlKey) {
                    event.stopPropagation();
                    event.preventDefault();
                    this.handleCopySelectedCells();
                    return;
                }
                break;
            case Key.KEY_X:
                if (event.metaKey || event.ctrlKey) {
                    event.stopPropagation();
                    event.preventDefault();
                    this.handleCutSelectedCells();
                    return;
                }
                break;
            case Key.KEY_V:
                if (event.metaKey || event.ctrlKey) {
                    return;
                }
                break;
            case Key.KEY_Z:
                if (event.metaKey || event.ctrlKey) {
                    return;
                }
                break;
            case Key.KEY_A:
                if (event.metaKey || event.ctrlKey) {
                    this.selectCells(this.cells.filter(cell => !cell.model.isHidden));
                    return;
                }
                break;
            case Key.KEY_B:
                if (event.metaKey || event.ctrlKey) {
                    const bold = !selectedCells[0].model.bold;
                    for (const cell of selectedCells) {
                        cell.model.bold = bold;
                    }
                    this.element.saveModel();
                    return;
                }
                break;
            case Key.KEY_I:
                if (event.metaKey || event.ctrlKey) {
                    const italic = !selectedCells[0].model.italic;
                    for (const cell of selectedCells) {
                        cell.model.italic = italic;
                    }
                    this.element.saveModel();
                    return;
                }
                break;
            case Key.LEFT_META:
            case Key.RIGHT_META:
            case Key.SHIFT:
            case Key.CTRL:
                return;
            case Key.LEFT_ARROW:
                this.handleMoveCellSelection("left", event.shiftKey);
                event.stopPropagation();
                return;
            case Key.RIGHT_ARROW:
                this.handleMoveCellSelection("right", event.shiftKey);
                event.stopPropagation();
                return;
            case Key.TAB:
                event.preventDefault();
                if (event.shiftKey) {
                    this.handleMoveCellSelection("left", false, true);
                } else {
                    this.handleMoveCellSelection("right", false, true);
                }
                event.stopPropagation();
                return;
            case Key.UP_ARROW:
                this.handleMoveCellSelection("up", event.shiftKey);
                event.stopPropagation();
                return;
            case Key.DOWN_ARROW:
                this.handleMoveCellSelection("down", event.shiftKey);
                event.stopPropagation();
                return;
            case Key.DELETE:
            case Key.BACKSPACE:
                if (!editingCell) {
                    if (selectedCols.length > 0) {
                        for (const col of selectedCols) {
                            this.deleteCol(col.model);
                        }
                        this.element.saveModel();
                        return;
                    }

                    if (selectedRows.length > 0) {
                        for (const row of selectedRows) {
                            this.deleteRow(row.model);
                        }
                        this.element.saveModel();
                        return;
                    }

                    if (selectedCells.length > 0) {
                        for (const cell of selectedCells) {
                            this.removeCellContent(cell.model);
                        }
                        this.element.saveModel();
                        return;
                    }
                }
                event.stopPropagation();
                break;
        }

        if (selectedCells.length == 1) {
            this.startEditingCell(selectedCells[0]);
        }
    }

    handleTranspose = () => {
        const { element, selectionLayerController } = this.props;

        selectionLayerController.setFreezeSelection(true);

        this.element.transpose();
        this.element.canvas.refreshCanvas().then(() => {
            this.element.calcAutoFit("both");
            this.element.canvas.updateCanvasModel();

            selectionLayerController.setFreezeSelection(false);
        });
    };

    render() {
        const {
            selectedCells,
            cellsSelectionOutlinePaths,
            selectedCols,
            selectedRows,
            editingCell,
            dragFrameBounds,
            dropIndicatorBounds,
            isDraggingCols,
            isDraggingRows,
            isResizingCols,
            isResizingRows,
            isResizingTable
        } = this.state;

        const selectedCellsBounds = selectedCells
            .map(cell => cell.bounds)
            .reduce((bounds, cellBounds) => bounds ? bounds.union(cellBounds) : cellBounds, null);

        const isDraggingOrResizing = isDraggingCols || isDraggingRows || isResizingCols || isResizingRows;

        const cols = this.cols;
        const rows = this.rows;

        const canAddCols = cols.length < this.element.MAX_COLS;
        const canAddRows = rows.length < this.element.MAX_ROWS;

        const canResize = true;// element.canResize;

        return (
            <Container onMouseDown={stopPropagation(this.handleCellMouseDown)}>
                {!isResizingTable && (
                    <AbsoluteBox top={-32} left={-32}>
                        <IconButton icon="open_in_full" onClick={this.handleTranspose} />
                    </AbsoluteBox>
                )}
                {!isResizingTable && cols.map(({ bounds, model, index, displayName }) => (
                    <ColControlContainer
                        key={index}
                        bounds={bounds}
                        isEmphasized={model.style === "emphasizedCol"}
                        onMouseDown={stopPropagation(event => this.handleColControlMouseDown(event, index))}
                        isSelected={selectedCols.some(col => col.index === index)}
                    >
                        {displayName}
                        {!isDraggingOrResizing && (selectedCols.length > 0 && _.last(selectedCols).index === index) &&
                            <AbsoluteBox right={5}>
                                <Popup ref={this.popupMenuRef} onPreviewMouseDown={stopPropagation()} onPopoverMouseDown={stopPropagation()}>
                                    <PopupPreview>
                                        <Icon color="white" fill>expand_circle_down</Icon>
                                    </PopupPreview>
                                    <PopupContent>
                                        {closePopup => (
                                            <PropertyPanelContainer>
                                                <PropertySection color="#f1f1f1">
                                                    <WithLabel label="Column Style">
                                                        <Dropdown
                                                            value={selectedCols[0].model.style ?? "defaultCol"}
                                                            onChange={style => {
                                                                selectedCols.forEach(col => {
                                                                    col.model.style = style;
                                                                });
                                                                this.element.saveModel();
                                                                closePopup();
                                                            }}>
                                                            <MenuItem value="defaultCol">Default</MenuItem>
                                                            <MenuItem value="headerCol">Header</MenuItem>
                                                            <MenuItem value="cleanCol">Clean Header</MenuItem>
                                                            <MenuItem value="summaryCol">Summary</MenuItem>
                                                            <MenuItem value="emphasizedCol">Emphasized</MenuItem>
                                                        </Dropdown>
                                                    </WithLabel>
                                                    <WithLabel label="Insert Break After">
                                                        <ToggleSwitch value={!!selectedCols[0].model.break}
                                                            onChange={value => {
                                                                selectedCols[0].model.break = value;
                                                                this.element.saveModel();
                                                                closePopup();
                                                            }} />
                                                    </WithLabel>
                                                </PropertySection>
                                                <MenuItem onClick={async () => {
                                                    for (const item of selectedCols) {
                                                        this.element.calcAutoFit("columns", item.index);
                                                    }
                                                    await this.element.saveModel();
                                                    this.recalcSelectionOutline();
                                                    closePopup();
                                                }} divider><Icon>fit_width</Icon>Fit Column To Contents</MenuItem>
                                                <MenuItem onClick={() => {
                                                    this.addColAtIndex(_.max(selectedCols.map(item => item.index)) + 1);
                                                    closePopup();
                                                }}><Icon>add_column_right</Icon>Insert Column After</MenuItem>
                                                <MenuItem onClick={() => {
                                                    this.addColAtIndex(_.min(selectedCols.map(item => item.index)));
                                                    closePopup();
                                                }} divider><Icon>add_column_left</Icon>Insert Column Before</MenuItem>
                                                <MenuItem onClick={() => {
                                                    const indices = selectedCols.map(item => item.index);
                                                    indices.sort((a, b) => b - a);
                                                    for (const index of indices) {
                                                        this.deleteColAtIndex(index);
                                                    }
                                                    this.element.saveModel();
                                                    closePopup();
                                                }}><Icon>delete</Icon>Delete {"Column".pluralize(selectedRows.length > 1)}</MenuItem>
                                            </PropertyPanelContainer>
                                        )}
                                    </PopupContent>
                                </Popup>
                            </AbsoluteBox>
                        }
                        {index !== 0 && <ColResizeHandle onMouseDown={stopPropagation(event => this.handleColResizeMouseDown(event, index))} />}
                        {!isDraggingOrResizing && index === cols.length - 1 && canAddCols &&
                            <AddColOrRowButton
                                icon="add"
                                outlined left="calc(100% + 20px)"
                                top="50%"
                                onMouseDown={stopPropagation(() => this.addColAtIndex(cols.length))}
                            />
                        }
                    </ColControlContainer>
                ))}

                {!isResizingTable && rows.map(({ bounds, index, displayName }) => (
                    <RowControlContainer
                        key={index}
                        bounds={bounds}
                        onMouseDown={stopPropagation(event => this.handleRowControlMouseDown(event, index))}
                        isSelected={selectedRows.some(row => row.index === index)}
                    >
                        {displayName}
                        {!isDraggingOrResizing && (selectedRows.length > 0 && _.last(selectedRows).index === index) &&
                            <RowPopupMenuContainer style={{ left: -20, height: bounds.height }}>
                                <Popup ref={this.popupMenuRef} onPreviewMouseDown={stopPropagation()} onPopoverMouseDown={stopPropagation()}>
                                    <PopupPreview>
                                        <Icon color="white" fill>expand_circle_down</Icon>
                                    </PopupPreview>
                                    <PopupContent>
                                        {closePopup => (
                                            <PropertyPanelContainer>
                                                <PropertySection color="#f1f1f1">
                                                    <WithLabel label="Row Style">
                                                        <Dropdown
                                                            value={selectedRows[0].model.style ?? "defaultRow"}
                                                            onChange={style => {
                                                                selectedRows.forEach(row => {
                                                                    row.model.style = style;
                                                                });
                                                                this.element.saveModel();
                                                                closePopup();
                                                            }}>
                                                            <MenuItem value="defaultRow">Default</MenuItem>
                                                            <MenuItem value="headerRow">Header</MenuItem>
                                                            <MenuItem value="cleanRow">Clean Header</MenuItem>
                                                            <MenuItem value="summaryRow">Summary</MenuItem>
                                                        </Dropdown>
                                                    </WithLabel>
                                                    <WithLabel label="Insert Break After">
                                                        <ToggleSwitch value={!!selectedRows[0].model.break}
                                                            onChange={value => {
                                                                selectedRows[0].model.break = value;
                                                                this.element.saveModel();
                                                                closePopup();
                                                            }} />
                                                    </WithLabel>
                                                </PropertySection>
                                                <MenuItem onClick={async () => {
                                                    for (const item of selectedRows) {
                                                        this.element.calcAutoFit("rows", item.index);
                                                    }
                                                    await this.element.saveModel();
                                                    this.recalcSelectionOutline();
                                                    closePopup();
                                                }} divider><Icon rotate={90}>fit_width</Icon>Fit Row To Contents</MenuItem>
                                                <MenuItem onClick={() => {
                                                    this.addRowAtIndex(_.max(selectedRows.map(item => item.index)) + 1);
                                                    closePopup();
                                                }}><Icon>add_row_below</Icon>Insert Row After</MenuItem>
                                                <MenuItem onClick={() => {
                                                    this.addRowAtIndex(_.min(selectedRows.map(item => item.index)));
                                                    closePopup();
                                                }} divider><Icon>add_row_above</Icon>Insert Row Before</MenuItem>
                                                <MenuItem onClick={() => {
                                                    const indices = selectedRows.map(item => item.index);
                                                    indices.sort((a, b) => b - a);
                                                    for (const index of indices) {
                                                        this.deleteRowAtIndex(index);
                                                    }
                                                    this.element.saveModel();
                                                    closePopup();
                                                }}><Icon>delete</Icon>Delete {"Row".pluralize(selectedRows.length > 1)}</MenuItem>
                                            </PropertyPanelContainer>
                                        )}
                                    </PopupContent>
                                </Popup>
                            </RowPopupMenuContainer>
                        }
                        {index !== 0 && <RowResizeHandle onMouseDown={stopPropagation(event => this.handleRowResizeMouseDown(event, index))} />}
                        {!isDraggingOrResizing && index === rows.length - 1 && canAddRows &&
                            <AddColOrRowButton
                                icon="add"
                                outlined
                                left="50%"
                                top="calc(100% + 20px)"
                                onMouseDown={stopPropagation(() => this.addRowAtIndex(rows.length))}
                            />
                        }
                    </RowControlContainer>
                ))}

                {!isResizingTable && !isDraggingOrResizing && selectedCellsBounds &&
                    <CellSelectionContainer bounds={selectedCellsBounds}>
                        <TableCellControlBar
                            element={this.element}
                            selectedCells={selectedCells}
                            rows={rows}
                            cols={cols}
                            selectCells={this.handleSelectCells}
                            stopEditingCell={() => this.setState({ editingCell: null })}
                            cells={this.cells}
                        />
                    </CellSelectionContainer>
                }

                {!isResizingTable && !isDraggingOrResizing && cellsSelectionOutlinePaths &&
                    <CellSelectionOutline>
                        {cellsSelectionOutlinePaths.map((path, index) => (
                            <path key={index} d={path.toPathData()} />
                        ))}
                    </CellSelectionOutline>
                }

                {editingCell &&
                    <TableCellEditor
                        cell={editingCell}
                        element={this.element}
                        moveCellSelection={this.handleMoveCellSelection}
                        onCommit={() => this.setState({ editingCell: null })}
                    />
                }

                {dragFrameBounds && <DraggingFrame bounds={dragFrameBounds} />}
                {dropIndicatorBounds && <DropIndicator bounds={dropIndicatorBounds} />}

                {!isDraggingOrResizing && canResize && (!isResizingTable || this.resizeTableDirection === ResizeDirection.RIGHT) && (
                    <ResizeHandle
                        resizeDirection={ResizeDirection.RIGHT}
                        style={DragHandleStyle.GRABBER}
                        offset={{ x: 20 }}
                        onMouseDown={stopPropagation(event => this.handleTableResizeMouseDown(event, ResizeDirection.RIGHT))}
                    />
                )}
                {!isDraggingOrResizing && canResize && (!isResizingTable || this.resizeTableDirection === ResizeDirection.BOTTOM) && (
                    <ResizeHandle
                        resizeDirection={ResizeDirection.BOTTOM}
                        style={DragHandleStyle.GRABBER}
                        offset={{ y: 20 }}
                        onMouseDown={stopPropagation(event => this.handleTableResizeMouseDown(event, ResizeDirection.BOTTOM))}
                    />
                )}
            </Container>
        );
    }
}

import { BackgroundStyleType, DecorationStyle, PaletteColorType } from "common/constants";
import Logos from "js/core/models/logos";
import * as geom from "js/core/utilities/geom";
import React from "reactn";
import { SVGGroup } from "../../../../core/utilities/svgHelpers";

import { ds } from "../../../../core/models/dataService";
import { Gradient } from "../../../../editor/ThemeEditor/components/Gradient";
import { DecorativeElement } from "./BaseElement";
import { ContentElement } from "./ContentElement";

class CanvasBackground extends DecorativeElement {
    get canvasBackgroundStyle() {
        let backgroundStyle = this.model.backgroundStyle || this.canvas.slideTemplate.defaultBackgroundStyle;

        if (!backgroundStyle) {
            let defaultBackgroundColor = this.canvas.getTheme().get("defaultBackgroundColor");

            const bgGradients = this.canvas.getTheme().get("backgroundGradients");
            if (bgGradients?.some(bg => bg.id === defaultBackgroundColor)) {
                return BackgroundStyleType.GRADIENT;
            }

            // if (this.canvas.slideTemplate.allowImageBackground && this.canvas.getTheme().get("backgroundImages")) {
            if (this.canvas.getTheme().get("backgroundImages")) {
                let bgImage = this.canvas.getTheme().get("backgroundImages").find(bgImage => bgImage.id == defaultBackgroundColor);
                if (bgImage) {
                    return BackgroundStyleType.IMAGE;
                }
            }

            if (defaultBackgroundColor === PaletteColorType.BACKGROUND_LIGHT) {
                backgroundStyle = BackgroundStyleType.LIGHT;
            } else if (defaultBackgroundColor === PaletteColorType.BACKGROUND_DARK) {
                backgroundStyle = BackgroundStyleType.DARK;
            } else if (defaultBackgroundColor === PaletteColorType.BACKGROUND_ACCENT) {
                backgroundStyle = BackgroundStyleType.ACCENT;
            } else {
                backgroundStyle = BackgroundStyleType.COLOR;
            }
        }

        // if (this.canvas.slideTemplate.allowColorBackgrounds == false && backgroundStyle == BackgroundStyleType.COLOR) {
        //     backgroundStyle = BackgroundStyleType.LIGHT;
        // }

        return backgroundStyle;
    }

    get canvasBackgroundColorType() {
        const backgroundColor = this.model.backgroundColor ||
            this.canvas.getTheme().get("defaultBackgroundColor") ||
            BackgroundStyleType.LIGHT;

        switch (this.canvasBackgroundStyle) {
            case BackgroundStyleType.LIGHT:
                return PaletteColorType.BACKGROUND_LIGHT;
            case BackgroundStyleType.DARK:
                return PaletteColorType.BACKGROUND_DARK;
            case BackgroundStyleType.COLOR:
                // if style is gradient and bg color is white
                if (["gradient", "reverseGradient"].includes(this.canvas.getTheme().get("styleBackground")) && (!backgroundColor || backgroundColor === PaletteColorType.BACKGROUND_LIGHT)) {
                    return PaletteColorType.THEME;
                } else {
                    return backgroundColor;
                }
            case BackgroundStyleType.GRADIENT:
                return backgroundColor;
            default:
                return backgroundColor;
        }
    }

    get canvasBackgroundColor() {
        if (this.canvasBackgroundStyle == BackgroundStyleType.IMAGE || this.canvasBackgroundStyle == BackgroundStyleType.GRADIENT) {
            if (this.backgroundImage || this.backgroundGradient) {
                switch ((this.backgroundImage ?? this.backgroundGradient).colorStyle) {
                    case "dark":
                        return this.canvas.getTheme().palette.getColor("black");
                    case "color":
                        return this.canvas.getTheme().palette.getColor("theme");
                    case "light":
                    default:
                        return this.canvas.getTheme().palette.getColor("white");
                }
            } else {
                return this.canvas.getTheme().palette.getColor(PaletteColorType.BACKGROUND_LIGHT);
            }
        } else {
            return this.canvas.getTheme().palette.getColor(this.canvasBackgroundColorType);
        }
    }

    get backgroundEffect() {
        const backgroundStyle = this.canvas.styleSheet.Backgrounds.styles[this.canvasBackgroundStyle];
        if (backgroundStyle) {
            return backgroundStyle.effect;
        } else {
            return null;
        }
    }

    get backgroundOverlay() {
        let overlayClass;
        switch (this.backgroundEffect) {
            case "vignette":
                overlayClass = VignetteOverlayElement;
                break;
            case "greyBorder":
            case "whiteBorder":
            case "colorBorder":
                overlayClass = BorderOverlayElement;
                break;
        }
        return overlayClass;
    }

    _build() {
        let backgroundClass;

        if (this.canvasBackgroundStyle == BackgroundStyleType.IMAGE) {
            if (this.model.backgroundColor == "custom-asset") {
                this.backgroundImage = this.model.customBackgroundImage;
                this.background = this.addElement("background", () => CustomBackgroundImage, {
                    model: this.backgroundImage
                });
                return;
            } else {
                this.backgroundImage = this.canvas.getTheme().get("backgroundImages")?.find(bg => bg.id == this.canvasBackgroundColorType);
                if (this.backgroundImage) {
                    this.background = this.addElement("background", () => ThemeBackgroundImage, {
                        backgroundImage: this.backgroundImage
                    });
                    return;
                }
            }
        }

        if (this.canvasBackgroundStyle == BackgroundStyleType.GRADIENT) {
            this.backgroundGradient = this.canvas.getTheme().get("backgroundGradients")?.find(bg => bg.id == this.canvasBackgroundColorType);
            this.background = this.addElement("background", () => CustomBackgroundGradient, { gradient: this.backgroundGradient });
            return;
        }

        switch (this.backgroundEffect) {
            case "gradient":
                backgroundClass = BackgroundGradient;
                break;
            case "reverseGradient":
                backgroundClass = BackgroundGradientReverse;
                break;
            // case "image":
            //     backgroundClass = BackgroundImage;
            //     break;
            // case "greyBorder":
            // case "whiteBorder":
            // case "colorBorder":
            //     backgroundClass = BackgroundBorder;
            //     break;
            default:
                backgroundClass = BackgroundColor;
        }
        this.background = this.addElement("background", () => backgroundClass, {
            backgroundColor: this.canvasBackgroundColor,
            backgroundStyle: this.canvasBackgroundStyle
        });
    }

    _calcProps(props) {
        let { size } = props;

        let backgroundProps = this.background.calcProps(size);
        backgroundProps.bounds = new geom.Rect(0, 0, size);

        return { size: props.size };
    }

    _exportToSharedModel() {
        return { background: this.model };
    }

    _importFromSharedModel(model) {
        const { background } = model;
        if (!background) return;

        return background;
    }
}

export class BackgroundColor extends DecorativeElement {
    renderChildren(transition) {
        let props = this.calculatedProps;
        return (
            <SVGGroup key={this.id}>
                <rect
                    id={this.id}
                    x={props.bounds.left}
                    y={props.bounds.top}
                    width={props.bounds.width}
                    height={props.bounds.height}
                    style={{ fill: this.options.backgroundColor.toRgbString() }}
                />
            </SVGGroup>
        );
    }
}

class CustomBackgroundGradient extends DecorativeElement {
    renderChildren(transition) {
        return (
            <Gradient gradient={this.options.gradient} />
        );
    }
}

export class BackgroundGradient extends BackgroundColor {
    gradientConfig = {
        gradientId: `rampGradient${this.uniqueId}`,
        type: "linear",
        padding: 150,
        stops: [
            {
                offset: 0.35,
                color: "rgba(0,0,0,0.0)",
            },
            {
                offset: 1.0,
                color: "rgba(0,0,0,0.5)",
            },
        ],
    }

    renderChildren(transition) {
        let props = this.calculatedProps;
        return (
            <SVGGroup key={this.id}>
                <rect
                    id={this.id}
                    x={props.bounds.left}
                    y={props.bounds.top}
                    width={props.bounds.width}
                    height={props.bounds.height}
                    style={{ fill: this.options.backgroundColor.toHexString() }}
                />
                <defs>
                    <linearGradient
                        id={this.gradientConfig.gradientId}
                        x1="0%"
                        y1="0%"
                        x2="0%"
                        y2="100%"
                    >
                        {
                            this.gradientConfig.stops.map(stop => (
                                <stop
                                    key={`stop-${stop.offset}`}
                                    offset={stop.offset}
                                    stopColor={stop.color}
                                />
                            ))
                        }
                    </linearGradient>
                </defs>
                <rect
                    x={-this.gradientConfig.padding}
                    y={-this.gradientConfig.padding}
                    width={props.bounds.width + this.gradientConfig.padding * 2}
                    height={props.bounds.height + this.gradientConfig.padding * 2}
                    fill={`url(#${this.gradientConfig.gradientId})`}
                />
            </SVGGroup>
        );
    }
}

export class BackgroundGradientReverse extends BackgroundGradient {
    gradientConfig = {
        gradientId: `reverseRampGradient${this.uniqueId}`,
        type: "linear",
        padding: 150,
        stops: [
            {
                offset: 0.2,
                color: "rgba(0,0,0,0.7)",
            },
            {
                offset: 1.0,
                color: "rgba(0,0,0,0.0)",
            },
        ],
    }
}

export class CustomBackgroundImage extends DecorativeElement {
    get decorationStyle() {
        return DecorationStyle.FILLED;
    }

    async getBackgroundImageUrl(ignoreCache = false) {
        let { content_type, content_value } = this.options.model;

        const asset = await ds.assets.getAssetById(content_value, content_type);
        this.backgroundImageUrl = await asset.getURL("original", ignoreCache);

        return this.backgroundImageUrl;
    }

    _build() {
        this.content = this.addElement("content", () => ContentElement, {
            canSelect: false,
            canEdit: false
        });
    }

    async _load() {
        await this.getBackgroundImageUrl();
    }

    _applyColors() {
        // set the decoration color to white - this will be used if the image backgound is transparent or blurred
        this.colorSet.decorationColor = this.palette.getColor("white");
    }
}

export class ThemeBackgroundImage extends DecorativeElement {
    async getBackgroundImageUrl(ignoreCache = false) {
        let backgroundImage = this.options.backgroundImage;

        let assetId = backgroundImage.asset;
        if (this.currentAssetId === assetId && !ignoreCache) {
            return this.backgroundImageUrl;
        }

        this.currentAssetId = assetId;
        this.backgroundImageUrl = null;

        if (assetId) {
            this.backgroundImageUrl = await Logos.getSignedUrlAndLoad(assetId, ignoreCache);
        }

        return this.backgroundImageUrl;
    }

    async _load() {
        await this.getBackgroundImageUrl();
    }

    _renderElement(transition) {
        return (
            <SVGGroup key={this.id}>
                <image
                    width="100%"
                    height="100%"
                    viewBox={`0 0 ${this.canvas.CANVAS_WIDTH} ${this.canvas.CANVAS_HEIGHT}`}
                    preserveAspectRatio="xMinYMin slice" href={this.backgroundImageUrl}
                    onLoad={this.getImageOnLoadPromiseResolver(this.backgroundImageUrl)}
                />
            </SVGGroup>
        );
    }
}

export class VignetteOverlayElement extends DecorativeElement {
    gradientConfig = {
        gradientId: `vignette${this.uniqueId}`,
        type: "radial",
        padding: 150,
        stops: [
            {
                offset: 0.6,
                color: "rgba(0,0,0,0.0)",
            },
            {
                offset: 0.7,
                color: "rgba(0,0,0,0.025)",
            },
            {
                offset: 0.8,
                color: "rgba(0,0,0,0.1)",
            },
            {
                offset: 1.0,
                color: "rgba(0,0,0,0.25)",
            },
        ],
    }

    _loadStyles(styles) {
        styles = {
            decoration: {
                type: "frame",
                shape: "rect",
                effect: "vignette",
                fillColor: "none"
            }
        };
    }

    _calcProps(props, options) {
        return { size: props.size };
    }

    renderChildren(transition) {
        let props = this.calculatedProps;
        return (
            <SVGGroup key={this.id}>
                <defs>
                    <radialGradient id={this.gradientConfig.gradientId}>
                        {
                            this.gradientConfig.stops.map(stop => (
                                <stop
                                    key={`stop-${stop.offset}`}
                                    offset={stop.offset}
                                    stopColor={stop.color}
                                />
                            ))
                        }
                    </radialGradient>
                </defs>
                <rect
                    id={this.id}
                    x={-this.gradientConfig.padding}
                    y={-this.gradientConfig.padding}
                    width={props.size.width + this.gradientConfig.padding * 2}
                    height={props.size.height + this.gradientConfig.padding * 2}
                    fill={`url(#${this.gradientConfig.gradientId})`}
                />
            </SVGGroup>
        );
    }
}

export class BorderOverlayElement extends DecorativeElement {
    renderChildren(transition) {
        let props = this.calculatedProps;
        switch (this.parentElement.elements.background.canvasBackgroundStyle) {
            case BackgroundStyleType.LIGHT:
                this.calculatedBorderColor = "rgb(212,212,212)";
                break;
            case BackgroundStyleType.DARK:
                this.calculatedBorderColor = "white";
                break;
            case BackgroundStyleType.COLOR:
                this.calculatedBorderColor = "white";
                break;
            case BackgroundStyleType.IMAGE:
                this.calculatedBorderColor = "white";
                break;
        }

        this.calculatedBorderWidth = this.canvas.styleSheet.$decorationStrokeWidth * 4;

        return (
            <SVGGroup key={this.id}>
                <rect
                    id={this.id}
                    x={props.bounds.left}
                    y={props.bounds.top}
                    width={props.bounds.width}
                    height={props.bounds.height}
                    style={{
                        fill: "none",
                        stroke: this.calculatedBorderColor,
                        strokeWidth: this.calculatedBorderWidth
                    }}
                />
            </SVGGroup>
        );
    }
}

export { CanvasBackground };

import React, { Component } from "react";
import styled from "styled-components";

import { _ } from "../../../../../vendor";
import { themeColors } from "../../../../../react/sharedStyles";
import * as geom from "../../../../../core/utilities/geom";

const foreColor = "white";
const hoverColor = "rgba(255,255,255,0.1)";

const ControlBarFrame = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  top: ${props => props.position === "above" ? (-props.offset - 34) + "px" : null};
  bottom: ${props => props.position === "below" ? -props.offset + "px" : null};
  left: ${props => props.leftOffset !== undefined ? props.leftOffset + "px" : null};
  width: 100%;
  height: 34px;
  z-index: 1000;
  pointer-events: none;
`;

const ControlBarGrid = styled.div`
  display: grid;
  gap: 10px;
  grid-auto-flow: column;
  pointer-events: auto;
`;

const StyledControlBar = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  column-gap: 0px;
  align-items: center;
  justify-content: center;
  padding: 0px 5px;
  background: ${themeColors.ui_blue};
  color: ${foreColor};
  height: 34px;
  border-radius: 2px;
  cursor: default;

  label {
    color: ${foreColor};
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
  }
    
    .bai-icon {
        color: white !important;
    }

  .MuiToggleButton-root {
    border-radius: 0px;
    padding: 6px;
    margin: 0px;
    color: rgba(255,255,255, 0.4);
    &.Mui-selected {
      color: ${foreColor};
      background: none;
    }
  }

  .MuiIcon-root, .MuiSvgIcon-root {
    font-size: 20px;
  }

  .MuiButton-label {
    font-size: 12px;
    color: white;
  }

  .MuiToggleButton-root {
    border: none;
    padding: 0px 5px;
  }

  label {
    color: ${foreColor};
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
  }

  input {
    -webkit-text-fill-color: ${foreColor};
    color: ${foreColor};
  }

  .MuiIconButton-root {
    border-radius: 0;
    padding: 0px 6px;
    height: 100%;
    color: ${foreColor};
    margin: 0px;
    &:hover {
      background: ${hoverColor}
    }
  }
  
  .MuiButtonBase-root.Mui-disabled {
    color: rgba(255,255,255,0.25);
  }
  
  .MuiSlider-root {
    margin: 0px 10px;
    color: ${foreColor};
  }
  
`;

const LightStyledControlBar = styled(StyledControlBar)`
  background: #f1f1f1;
  
  label {
    color: #222;
  }

  .MuiIconButton-root {
    color:#222;
    &:hover {
      background: ${hoverColor}
    }
  }
`;

export const ControlBarDivider = styled.div`
  width: 10px;
  height: 66%;
`;

export class AuthoringCanvasControlBar extends Component {
    constructor(props) {
        super(props);

        this.gridRef = React.createRef();
    }

    get gridBounds() {
        if (this.gridRef.current) {
            return geom.Rect.FromBoundingClientRect(this.gridRef.current.getBoundingClientRect());
        }

        return null;
    }

    render() {
        let { position = "below", offset = 44, id, gap = 5, left = undefined, top = undefined, marginLeft = 0, marginTop = 0 } = this.props;

        let _children = React.Children.toArray(this.props.children);

        if (_children.length > 0) {
            let children;

            if (_children[0].type?.name != "ControlBarGroup") {
                children = <ControlBarGroup>{this.props.children}</ControlBarGroup>;
            } else {
                children = this.props.children;
            }

            const frameStyle = {};
            if (left !== undefined && top !== undefined) {
                frameStyle.left = left;
                frameStyle.top = top;
                frameStyle.transform = "translate(-50%, -50%)";
                offset = undefined;
                position = undefined;
            }
            frameStyle.marginLeft = marginLeft;
            frameStyle.marginTop = marginTop;

            return (
                <ControlBarFrame position={position} offset={offset} style={frameStyle}>
                    <ControlBarGrid ref={this.gridRef} id={id} style={{ gap }}>
                        {children}
                    </ControlBarGrid>
                </ControlBarFrame>
            );
        } else {
            return null;
        }
    }
}

export class ControlBarGroup extends Component {
    render() {
        const { authoring, light, color, children, style } = this.props;
        if (authoring !== undefined) {
            const styling = { ...style, backgroundColor: themeColors.authoringThemeBaseColor };
            return authoring ? (
                <StyledControlBar className="editor-control-bar" style={styling}>
                    {children}
                </StyledControlBar>
            ) : null;
        } else if (light) {
            return (
                <LightStyledControlBar className="editor-control-bar" style={style}>
                    {children}
                </LightStyledControlBar>
            );
        } else {
            const styling = { ...style, backgroundColor: color };
            return (
                <StyledControlBar className="editor-control-bar" style={styling}>
                    {children}
                </StyledControlBar>
            );
        }
    }
}


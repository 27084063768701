import { VerticalAlignType, HorizontalAlignType } from "common/constants";
import * as geom from "js/core/utilities/geom";
import { _ } from "js/vendor";

const layoutHelper = {};

layoutHelper.getHorizontalAlignOffset = function(innerWidth, containerWidth, align = HorizontalAlignType.CENTER) {
    switch (align) {
        case HorizontalAlignType.LEFT:
            return 0;
        case HorizontalAlignType.CENTER:
        case HorizontalAlignType.JUSTIFY:
            return containerWidth / 2 - innerWidth / 2;
        case HorizontalAlignType.RIGHT:
            return containerWidth - innerWidth;
    }
};

layoutHelper.getVerticalAlignOffset = function(innerHeight, containerHeight, align = VerticalAlignType.TOP) {
    switch (align) {
        case VerticalAlignType.TOP:
            return 0;
        case VerticalAlignType.MIDDLE:
            return containerHeight / 2 - innerHeight / 2;
        case VerticalAlignType.BOTTOM:
            return containerHeight - innerHeight;
    }
};

layoutHelper.offsetItemBounds = function(items, offsetX = 0, offsetY = 0) {
    for (let item of items) {
        item.calculatedProps.bounds = item.calculatedProps.bounds.offset(offsetX, offsetY);
    }
};

layoutHelper.getAlignOffset = function(innerSize, containerSize, horizontalAlign = HorizontalAlignType.CENTER, verticalAlign = VerticalAlignType.MIDDLE) {
    return new geom.Point(layoutHelper.getHorizontalAlignOffset(innerSize.width, containerSize.width, horizontalAlign), layoutHelper.getVerticalAlignOffset(innerSize.height, containerSize.height, verticalAlign));
};

layoutHelper.getHorizontalOffsetForItems = function(itemWidth, hGap, itemCount, containerWidth, align = HorizontalAlignType.CENTER) {
    let totalWidth = (itemWidth + hGap) * itemCount - hGap;
    return layoutHelper.getHorizontalAlignOffset(totalWidth, containerWidth, align);
};

layoutHelper.getVerticalOffsetForItems = function(itemHeight, vGap, itemCount, containerHeight, align = VerticalAlignType.MIDDLE) {
    let totalHeight = (itemHeight + vGap) * itemCount - vGap;
    return layoutHelper.getVerticalAlignOffset(totalHeight, containerHeight, align);
};

layoutHelper.getTotalBounds = function(bounds) {
    return bounds.reduce((totalBounds, bounds) => totalBounds.union(bounds), bounds[0]);
};

layoutHelper.getMaxWidthOfItems = function(items) {
    return _.max(items.map(item => item.calculatedProps.size.width));
};

layoutHelper.getMaxHeightOfItems = function(items) {
    return _.max(items.map(item => item.calculatedProps.size.height));
};

layoutHelper.getTotalBoundsOfItems = function(items) {
    let bounds;
    for (let item of items) {
        if (bounds == undefined) {
            bounds = item.calculatedProps.bounds;
        } else {
            bounds = bounds.union(item.calculatedProps.bounds);
        }
    }
    return bounds;
};

layoutHelper.alignItemsInContainer = function(items, containerSize, hAlign, vAlign) {
    let totalBounds = layoutHelper.getTotalBoundsOfItems(items);

    let offsetX = hAlign ? layoutHelper.getHorizontalAlignOffset(totalBounds.width, containerSize.width, hAlign) : 0;
    let offsetY = vAlign ? layoutHelper.getVerticalAlignOffset(totalBounds.height, containerSize.height, vAlign) : 0;
    layoutHelper.offsetItemBounds(items, offsetX, offsetY);
};

layoutHelper.horizontalAlignItems = function(items, containerSize, align = HorizontalAlignType.CENTER) {
    for (let itemProps of items.map(item => item.calculatedProps)) {
        let offsetX;
        switch (align) {
            case HorizontalAlignType.LEFT:
                offsetX = 0;
                break;
            case HorizontalAlignType.CENTER:
                offsetX = containerSize.width / 2 - itemProps.size.width / 2;
                break;
            case HorizontalAlignType.RIGHT:
                offsetX = containerSize.width - itemProps.size.width;
                break;
        }

        itemProps.bounds = itemProps.bounds.offset(offsetX, 0);
    }
};

export { layoutHelper };

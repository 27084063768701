import React from "react";
import styled from "styled-components";
import { Icon } from "@material-ui/core";

import { AssetType } from "common/constants";
import { PositionType } from "common/constants";
import { ds } from "js/core/models/dataService";
import { isRenderer } from "js/config";
import { $, _ } from "js/vendor";
import * as geom from "js/core/utilities/geom";

import { BaseElement } from "../base/BaseElement";
import { PreviewImage } from "../base/MediaElements/VideoElement";
import { VideoOverlayPropertyPanel } from "js/react/views/RecordPanel/VideoOverlayPropertyPanel";
import { VideoOverlayControlBar, VideoOverlaySelection } from "../../Editor/ElementPropertyPanels/VideoOverlayUI";

const VideoFrame = styled.div`
  background: black;
  border-radius: 50%;
  pointer-events: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: opacity 500ms;
  position: relative;
  // When we have PlayerControls to make sure it is above it
  z-index: 20;

  video {
    height: 100%;
  }

  .btn {
    display: none;
    position: absolute;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background: rgba(0, 0, 0, .5);
    color: white;

    .MuiIcon-root {
      font-size: 50px;
    }
    
    &.rewind-icon {
      width: 30px;
      height: 30px;
      left: 75%;
      .MuiIcon-root {
        font-size: 20px;
      }
    }
  }
  
  .no-video {
    position: absolute;
    display: flex;
    align-items:  center;
    justify-content: center;
    width: 100px;
    height: 100px;
    color: #aaa;
    .MuiIcon-root {
      font-size: 80px;
    }
  }
`;

export class VideoOverlay extends BaseElement {
    get _canSelect() {
        return true;
    }

    getElementPropertyPanel() {
        return VideoOverlayPropertyPanel;
    }

    getElementSelection() {
        return VideoOverlaySelection;
    }

    getElementControlBar() {
        return VideoOverlayControlBar;
    }

    setupElement() {
        this.videoFrameRef = React.createRef();
        this.videoRef = React.createRef();
    }

    async _load() {
        if (this.model.videoAssetId && (!this.asset || this.asset.id != this.model.videoAssetId)) {
            await this.loadVideoAsset(this.model.videoAssetId);
        }
    }

    async loadVideoAsset(assetId) {
        this.asset = await ds.assets.getAssetById(assetId);
        if (this.asset) {
            this.videoUrl = await this.asset.getURL();
            const previewAssetId = this.asset.get("previewAssetId");
            if (previewAssetId) {
                const previewAsset = await ds.assets.getAssetById(previewAssetId, AssetType.IMAGE);
                this.previewImageUrl = await previewAsset.getURL();
            }
        }
    }

    get video() {
        return this.videoRef.current;
    }

    _onCanvasAnimationStart() {
        this.video.currentTime = 0;
        if (this.canvas.isPlayback && this.getAutoplay()) {
            this.video.play();
        }
    }

    _prepareToShowElement() {
        if (isRenderer) {
            return;
        }

        if (this.canvas.isCurrentCanvas) {
            this.video.currentTime = 0;
            if (this.canvas.isPlayback && this.getAutoplay()) {
                this.video.play();
            }
            $(this.videoFrameRef.current).css("opacity", 1);
        }
    }

    _stopElement() {
        if (isRenderer) {
            return;
        }

        this.video.pause();
        $(this.videoFrameRef.current).css("opacity", 0);
    }

    get size() {
        return this.model.size ?? 200;
    }

    _calcProps(props, options) {
        return {
            size: new geom.Size(this.size, this.size)
        };
    }

    get isVideoPlaying() {
        let video = this.videoRef.current;
        return !video.paused && !video.ended;
    }

    get rootVideoOverlay() {
        return this.canvas.playerView?.props.parentPresenter?.props.rootPlayerView?.currentCanvas?.layouter.elements.annotations?.videoOverlay;
    }
    get presenterVideoOverlay() {
        return this.canvas.playerView?.activePresenter?.viewRef?.current?.playerRef?.current?.currentCanvas?.layouter.elements.annotations?.videoOverlay;
    }

    handleMouseEnter = () => {
        this.updateTransportControls(true);
    }

    handleMouseLeave = () => {
        this.updateTransportControls(false);
    }

    handleClick = (event, triggeredExternally = false) => {
        if (!triggeredExternally) {
            if (this.rootVideoOverlay) {
                // If the click came from the presenter,
                //   trigger the player
                this.rootVideoOverlay.handleClick(event, true);
            } else if (this.presenterVideoOverlay) {
                // If the click came from the player and we
                //   have a presenter, trigger the presenter
                this.presenterVideoOverlay.handleClick(event, true);
            }
        }
        event.stopPropagation();

        let video = this.videoRef.current;
        if (this.isVideoPlaying) {
            video.pause();
        } else {
            video.play();
        }
        _.defer(() => {
            this.updateTransportControls(!triggeredExternally);
        });
    }

    updateTransportControls = mouseOver => {
        $(this.videoFrameRef.current).find(".btn").hide();

        if (mouseOver) {
            if (this.isVideoPlaying) {
                $(this.videoFrameRef.current).find(".pause-icon").css("display", "flex");
            } else {
                $(this.videoFrameRef.current).find(".play-icon").css("display", "flex");
            }
            $(this.videoFrameRef.current).find(".rewind-icon").css("display", "flex");
        }
    }

    handleRewind = (event, triggeredExternally = false) => {
        if (!triggeredExternally) {
            if (this.rootVideoOverlay) {
                // If the click came from the presenter,
                //   trigger the player
                this.rootVideoOverlay.handleRewind(event, true);
            } else if (this.presenterVideoOverlay) {
                // If the click came from the player and we
                //   have a presenter, trigger the presenter
                this.presenterVideoOverlay.handleRewind(event, true);
            }
        }
        event.stopPropagation();

        let video = this.videoRef.current;
        $(this.videoFrameRef.current).find(".btn").hide();
        video.currentTime = 0;
        // video.pause();
        _.defer(() => {
            this.updateTransportControls(!triggeredExternally);
        });
    }

    get isInteractive() {
        return true;
    }

    get interactiveAction() {
        return {
            type: "video",
        };
    }

    renderChildren(transition) {
        let { size } = this.calculatedProps;
        return [
            <VideoFrame ref={this.videoFrameRef}
                style={{ ...size }}
                onMouseDown={this.handleMouseEnter}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
                key="video-frame"
            >
                {
                    isRenderer &&
                    this.previewImageUrl &&
                    <PreviewImage src={this.previewImageUrl} />
                }
                <video
                    ref={this.videoRef}
                    src={this.videoUrl}
                    autoPlay={false}
                    muted={!!this.rootVideoOverlay}
                    preload="auto"
                />
                {
                    this.videoUrl != null &&
                    <>
                        <div className="btn play-icon" onClick={this.handleClick}><Icon>play_arrow</Icon></div>
                        <div className="btn pause-icon" onClick={this.handleClick}><Icon>pause</Icon></div>
                        <div className="btn rewind-icon" onClick={this.handleRewind}><Icon>replay</Icon></div>
                    </>
                }
                {this.videoUrl == null && <div className="no-video"><Icon>video_camera_front</Icon></div>}
            </VideoFrame>
        ];
    }

    getPosition() {
        const PADDING = 20;
        let size = this.size;
        let width = size / this.parentElement.bounds.width;
        let height = size / this.parentElement.bounds.height;
        let paddingH = PADDING / this.parentElement.bounds.width;
        let paddingV = PADDING / this.parentElement.bounds.height;

        let position;

        if (this.model.x == 1 - width - paddingH && this.model.y == 1 - height - paddingV) {
            position = PositionType.BOTTOM_RIGHT;
        } else if (this.model.x == paddingH && this.model.y == 1 - height - paddingV) {
            position = PositionType.BOTTOM_LEFT;
        } else if (this.model.x == paddingH && this.model.y == paddingV) {
            position = PositionType.TOP_LEFT;
        } else if (this.model.x == 1 - width - paddingH && this.model.y == paddingV) {
            position = PositionType.TOP_RIGHT;
        } else {
            position = "Custom";
        }
        return position;
    }

    setPosition(pos, transition) {
        const PADDING = 20;
        let size = this.size;
        let width = size / this.parentElement.bounds.width;
        let height = size / this.parentElement.bounds.height;
        let paddingH = PADDING / this.parentElement.bounds.width;
        let paddingV = PADDING / this.parentElement.bounds.height;

        switch (pos) {
            case PositionType.BOTTOM_RIGHT:
                this.model.x = 1 - width - paddingH;
                this.model.y = 1 - height - paddingV;
                break;
            case PositionType.BOTTOM_LEFT:
                this.model.x = paddingH;
                this.model.y = 1 - height - paddingV;
                break;
            case PositionType.TOP_LEFT:
                this.model.x = paddingH;
                this.model.y = paddingV;
                break;
            case PositionType.TOP_RIGHT:
                this.model.x = 1 - width - paddingH;
                this.model.y = paddingV;
                break;
        }
        this.canvas.updateCanvasModel(transition);
    }

    getAutoplay() {
        // return true if does not exist
        return this.model.isAutoPlay ?? true;
    }

    setAutoplay(isAutoPlay, transition) {
        this.model.isAutoPlay = isAutoPlay;
        this.canvas.updateCanvasModel(transition);
    }

    _exportToSharedModel() {
        return { videoOverlay: this.model };
    }

    _importFromSharedModel(model) {
        return model.videoOverlay;
    }
}

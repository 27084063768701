import React from "react";
import styled from "styled-components";

import { Icon } from "@material-ui/core";

import { themeColors } from "../../../../../../react/sharedStyles";
import { AuthoringElementType } from "../../../../../../../common/constants";
import { BlueButton } from "../../../../../../react/components/UiComponents";

import { AuthoringContentEditor } from "./AuthoringContentEditor";
import { AuthoringPathEditor } from "./AuthoringPathEditor";
import { AuthoringShapeEditor } from "./AuthoringShapeEditor";
import { AuthoringSvgEditor } from "./AuthoringSvgEditor";
import { AuthoringVideoEditor } from "./AuthoringVideoEditor";
import { AuthoringCalloutEditor } from "./AuthoringCalloutEditor";

const EditElementButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    overflow: visible;
    
    >button {
        pointer-events: auto;
        background: ${themeColors.ui_blue};
        color: white;
        padding: 10px;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 600;
        min-width: 180px;
        width: 180px;
        min-height: 44px;
        height: 44px;
    }
`;

export function getEditorForSelection(props) {
    const { selection, startEditing, editingElement, isLocked = false } = props;

    const selectedElementTypes = [...new Set(selection.map(element => element.model.type))];
    if (selectedElementTypes.length > 1) {
        return;
    }

    const elementType = selectedElementTypes[0];

    switch (elementType) {
        case AuthoringElementType.SHAPE:
            return <AuthoringShapeEditor {...props} />;
        case AuthoringElementType.CONTENT:
            return <AuthoringContentEditor {...props} />;
        case AuthoringElementType.PATH:
            return <AuthoringPathEditor {...props} />;
        case AuthoringElementType.SVG:
            return <AuthoringSvgEditor {...props} />;
        case AuthoringElementType.VIDEO:
            return <AuthoringVideoEditor {...props} />;
        case AuthoringElementType.CALLOUT:
            return <AuthoringCalloutEditor {...props} />;
        case AuthoringElementType.COMPONENT:
        case AuthoringElementType.INFOGRAPHIC:
        default:
            // if (!editingElement && !isLocked) {
            //     return <EditElementButtonContainer><BlueButton onClick={startEditing}><Icon>edit</Icon>Edit Element</BlueButton></EditElementButtonContainer>;
            // }
            return null;
    }
}


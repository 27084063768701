import { tinycolor } from "../../vendor";

const calloutStyles = (color, decorationStyle) => {
    color = tinycolor(color);
    switch (decorationStyle) {
        case "outlined":
            return { fill: "none", stroke: color.setAlpha(1).toRgbString(), strokeWidth: 2, decorationStyle };

        case "filled":
            return { fill: color.setAlpha(1).toRgbString(), stroke: "none", decorationStyle };

        case "muted":
            return { fill: color.setAlpha(0.35).toRgbString(), stroke: "none", decorationStyle };

        case "fillAndStroke":
            return { fill: color.setAlpha(0.35).toRgbString(), stroke: color.setAlpha(1).toRgbString(), strokeWidth: 2, decorationStyle };
    }
};

export { calloutStyles };

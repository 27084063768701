import React, { Component } from "react";

import BadFitDialog from "../../../../react/components/Dialogs/BadFitDialog";
import { ShowDialog } from "../../../../react/components/Dialogs/BaseDialog";
import { Button } from "../../../../Components/Button";
import { Icon } from "../../../../Components/Icon";

import { ControlBar } from "./Components/ControlBar";

export class CollectionElementControlBar extends Component {
    get addItemLabel() {
        const { addItemLabel } = this.props;
        return addItemLabel ?? "Add Item";
    }

    get canAddNewItem() {
        const { element } = this.props;
        return element.addItem && (element.maxItemCount ? (element.itemCount < element.maxItemCount) : true);
    }

    get transitionWhenAddingItem() {
        return false;
    }

    get suppressPrimaryControlBar() {
        return false;
    }

    onAddNewItem = () => {
        const { element, selectionLayerController, selectedElements } = this.props;

        if (!this.canAddNewItem) {
            return;
        }

        const currentSelection = [...selectedElements];

        const newItem = element.addItem();
        this.onAfterAddItem(newItem);
        element.canvas.updateCanvasModel(this.transitionWhenAddingItem)
            .then(() => {
                const newElement = element.elements[newItem.id];
                if (newElement) {
                    selectionLayerController.setSelectedElements([newElement]);
                }
            })
            .catch(() => {
                selectionLayerController.setSelectedElements(currentSelection);

                ShowDialog(BadFitDialog, {
                    title: "Sorry, we aren't able to fit another item to this layout",
                });
            });
    }

    onAfterAddItem = newItem => { };

    render() {
        const { element, position } = this.props;

        return (
            <ControlBar position={position}>
                <Button blue onClick={this.onAddNewItem} disabled={!this.canAddNewItem} disabledTooltip={`This slide only allows ${element.maxItemCount} items.`}>
                    <Icon>add_circle</Icon>
                    {this.addItemLabel}
                </Button>
            </ControlBar>
        );
    }
}


import { $, _ } from "../../../../../../vendor";
import * as geom from "../../../../../../core/utilities/geom";

export function GetValueFromSelection(selection, property) {
    let values = [...new Set(selection.map(container => _.get(container, property)))];
    if (values.length == 1) {
        return values[0];
    } else {
        return "mixed";
    }
}

export function ConvertScreenToSelectionLayer(x, y) {
    return new geom.Point(x - $(".slide_canvas.current_slide").offset().left, y - $(".slide_canvas.current_slide").offset().top);
}

export const stopEventPropagation = {
    onMouseDown: event => event.stopPropagation(),
    onMouseUp: event => event.stopPropagation(),
    onMouseMove: event => event.stopPropagation(),
    onKeyDown: event => event.stopPropagation(),
};


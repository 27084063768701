import anime from "animejs";
import { $ } from "js/vendor";
import SplitType from "split-type";
import * as geom from "js/core/utilities/geom";

export function playActionTextAnimation(el, animationStyle, onComplete) {
    const animation = new ActionTextAnimations(el);

    const animate = animation[`ActionTextAnimationStyle${animationStyle}`];
    if (!animate) {
        return;
    }

    animate();

    animation.timeline.finished.then(() => {
        onComplete && onComplete();
    });
    animation.timeline.play();
    return animation;
}

class ActionTextAnimations {
    constructor(element, timeline) {
        this.element = element;
        this.$element = $(element.DOMNode);

        this.timeline = anime.timeline({
            easing: "easeOutQuad"
        });

        this.$animationContainer = this.$element.addEl($.div("animation-container"));
    }

    prepareText() {
        // for (let el of $(this.element.text.DOMNode).find(".content-editable")) {
        //     let $node = $(el.cloneNode(true));
        //     this.$animationContainer.addEl($node);
        //     $node.css({position: "absolute", top: 335 });
        //     // $node.offset({top: 335});
        // }
        //
        // return this.$animationContainer.find(".content-editable");

        let textContainer = this.element.text.DOMNode.cloneNode(true);
        this.element.text.DOMNode.style.opacity = 0;
        textContainer.style.opacity = 1;

        let $textContainer = this.$animationContainer.addEl($(textContainer));
        // $textContainer.find(".authoring-block-frame").left(0).top(0);
        let $text = $textContainer.find(".content-editable");
        $text.css({ pointerEvents: "none" });

        return $textContainer;
    }

    createSvgPath(id, path, fill = "red", stroke = "black", strokeWidth = 5) {
        let svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");

        let svgPath1 = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "path"
        );
        svgPath1.setAttribute("id", id);
        svgPath1.setAttribute("fill", fill);
        svgPath1.setAttribute("stroke", stroke);
        svgPath1.setAttribute("stroke-width", strokeWidth);
        svgPath1.setAttribute("d", path);
        svg.append(svgPath1);

        let $svg = this.$animationContainer.addEl($(svg));
        $svg.css({
            position: "absolute",
            width: "100%",
            height: "100%",
        });
        return $svg;
    }

    createSvgPolygon(id, points, fill = "red", stroke = "black", strokeWidth = 5) {
        let svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");

        let svgPath1 = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "polygon"
        );
        svgPath1.setAttribute("id", id);
        svgPath1.setAttribute("fill", fill);
        svgPath1.setAttribute("stroke", stroke);
        svgPath1.setAttribute("stroke-width", strokeWidth);
        svgPath1.setAttribute("points", points.map(p => p.join(" ")).join(" "));
        svg.append(svgPath1);

        let $svg = this.$animationContainer.addEl($(svg));
        $svg.css({
            position: "absolute",
            width: "100%",
            height: "100%",
        });
        return $svg;
    }

    createClipPath(id, points) {
        let svgClip = document.createElementNS("http://www.w3.org/2000/svg", "svg");
        let svgClipDefs = document.createElementNS("http://www.w3.org/2000/svg", "defs");
        svgClip.append(svgClipDefs);
        let svgClipPath = document.createElementNS("http://www.w3.org/2000/svg", "clipPath");
        svgClipPath.setAttribute("id", id);
        svgClipDefs.append(svgClipPath);

        let svgPath1 = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "polygon"
        );
        svgPath1.setAttribute("fill", "red");
        svgPath1.setAttribute("points", points.map(p => p.join(" ")).join(" "));
        svgClipPath.append(svgPath1);

        let $svgClip = this.$animationContainer.addEl($(svgClip));
        $svgClip.css({
            position: "absolute",
            width: "100%",
            height: "100%",
        });
        return $svgClip;
    }

    intersectPolygons(subjectPolygon, clipPolygon) {
        var cp1, cp2, s, e;
        var inside = function(p) {
            return (cp2[0] - cp1[0]) * (p[1] - cp1[1]) > (cp2[1] - cp1[1]) * (p[0] - cp1[0]);
        };
        var intersection = function() {
            var dc = [cp1[0] - cp2[0], cp1[1] - cp2[1]],
                dp = [s[0] - e[0], s[1] - e[1]],
                n1 = cp1[0] * cp2[1] - cp1[1] * cp2[0],
                n2 = s[0] * e[1] - s[1] * e[0],
                n3 = 1.0 / (dc[0] * dp[1] - dc[1] * dp[0]);
            return [(n1 * dp[0] - n2 * dc[0]) * n3, (n1 * dp[1] - n2 * dc[1]) * n3];
        };
        var outputList = subjectPolygon;
        cp1 = clipPolygon[clipPolygon.length - 1];
        for (var j in clipPolygon) {
            cp2 = clipPolygon[j];
            var inputList = outputList;
            outputList = [];
            s = inputList[inputList.length - 1]; //last on the input list
            for (var i in inputList) {
                e = inputList[i];
                if (inside(e)) {
                    if (!inside(s)) {
                        outputList.push(intersection());
                    }
                    outputList.push(e);
                } else if (inside(s)) {
                    outputList.push(intersection());
                }
                s = e;
            }
            cp1 = cp2;
        }
        return outputList;
    }

    ActionTextAnimationStyle1() {
        let $text = this.prepareText();

        let text = new SplitType($text, { absolute: false, types: "chars, words, lines" });
        $text.find(".authoring-block-container,.authoring-block-frame").css({ position: "absolute" });

        text.emphasizedWords = [];
        for (let emphasizedWord of $text.find(".emphasized")) {
            let $word = $(emphasizedWord).find(".word").clone();
            $word.css({ position: "absolute", top: 0, left: 0, opacity: 0 });
            text.emphasizedWords.push($word[0]);
            $(emphasizedWord).append($word);
        }

        this.timeline.add({
            targets: text.chars,
            translateX: [100, 0],
            opacity: [0, 1],
            delay: anime.stagger(25)
            // duration: 1000
        });

        this.timeline.add({
            targets: text.emphasizedWords,
            scale: 2,
            keyframes: [{
                opacity: 0, duration: 1
            }, {
                opacity: 1
            }, {
                opacity: 0
            }],
            duration: 1000,
            easing: "easeInQuad",
            delay: anime.stagger(300)
        });

        return this.timeline;
    }

    ActionTextAnimationStyle2() {
        let $text = this.prepareText();
        let $text2 = this.prepareText();
        $text2.hide();

        let text = new SplitType($text, { types: "words, lines" });
        $text.find(".authoring-block-container,.authoring-block-frame").css({ position: "absolute" });

        this.timeline.add({
            targets: $text[0],
            scale: [1.2, 0.8],
            duration: 5000,
            easing: "easeOutQuad",
        });
        this.timeline.add({
            targets: text.words,
            scale: [2, 1],
            opacity: [0, 1],
            duration: 200,
            delay: anime.stagger(100)
        }, 0);

        this.timeline.add({
            targets: $text2[0],
            scale: [0.8, 1.2],
            opacity: [1, 0],
            duration: 300,
            begin: anim => {
                $text2.show();
            }
        }, 1000);

        if (this.element.showImage) {
            this.timeline.add({
                targets: this.element.image.DOMNode,
                scale: [1, 1.3],
                duration: 10000
            }, 0);
        }
    }

    ActionTextAnimationStyle3() {
        let $text = this.prepareText();

        let text = new SplitType($text, { types: "chars, words, lines" });
        $text.find(".authoring-block-container,.authoring-block-frame").css({ position: "absolute" });

        this.timeline.add({
            targets: text.chars,
            translateY: [80, 0],
            opacity: [0, 1],
            duration: 125,
            easing: "easeOutQuad",
            delay: anime.stagger(30)
        });
    }

    ActionTextAnimationStyle4() {
        let $text1 = this.prepareText();
        let $text2 = this.prepareText();

        let $line = this.$animationContainer.addEl($.div());
        $line.css({
            position: "absolute",
            background: this.element.text.styles[this.element.text.blocks[0].textStyle].resolved_fontColor.toHexString(),
            width: 10,
            height: this.element.text.bounds.height + 100,
            left: "50%",
            top: this.element.text.bounds.top - 50,
            transform: "rotate(50deg)",
            // outline: "solid 5px white",
            zIndex: 10000
        });

        let clipPath = [[0, 0], [960, 0], [140, 700], [0, 700]];
        let textBounds = this.element.text.bounds.inflate({ top: 20, bottom: 20 });

        let intersection = this.intersectPolygons(textBounds.toPolyArray(), clipPath);

        let startPt = new geom.Point(intersection[1][0], intersection[1][1]);
        let endPt = new geom.Point(intersection[2][0], intersection[2][1]);

        let $path = this.createSvgPath("path1", `M${startPt.x} ${startPt.y} L${endPt.x} ${endPt.y}`, "none", "black", 10);
        $path.css({ zIndex: 10000, opacity: 0 });

        let $clip2 = this.createClipPath("clip2", [[startPt.x, startPt.y], [textBounds.right, startPt.y], [textBounds.right, endPt.y], [endPt.x, endPt.y]]);

        let $clipContainer2 = this.$animationContainer.addEl($.div("clip-container"));
        $clipContainer2.css({
            position: "absolute",
            clipPath: "url(#clip2)",
            width: "100%",
            height: "100%"
        });
        $text2.appendTo($clipContainer2);
        $text2.find(".content-editable").css({
            color: this.element.canvas.getTheme().palette.getColor(this.element.canvas.getSlideColor()).toRgbString()
        });

        let $clip1 = this.createClipPath("clip1", [[0, startPt.y], [startPt.x, startPt.y], [endPt.x, endPt.y], [0, endPt.y]]);
        let $clipContainer1 = this.$animationContainer.addEl($.div("clip-container"));
        $clipContainer1.css({
            position: "absolute",
            clipPath: "url(#clip1)",
            width: "100%",
            height: "100%"
        });
        $text1.appendTo($clipContainer1);

        const duration = 1000;

        this.timeline.add({
            targets: $line[0],
            scaleY: [0, 1],
            rotate: [-180, 50],
            duration: 500,
        }, 0);

        this.timeline.add({
            targets: $text1[0],
            translateX: [(startPt.x), 0],
            duration: duration,
            easing: "easeOutQuad",
        }, 350);
        this.timeline.add({
            targets: $text2[0],
            translateX: [endPt.x - this.element.text.bounds.width, 0],
            duration: duration,
            easing: "easeOutQuad",
        }, 350);

        let polygon = $clip1.find("polygon")[0];
        let path = $path.find("path")[0];
        this.timeline.add({
            targets: polygon,
            points: [
                {
                    value: [
                        `0 ${startPt.y} ${startPt.x} ${startPt.y} ${endPt.x} ${endPt.y} 0 ${endPt.y}`,
                        `${textBounds.left} ${textBounds.top} ${1290} ${textBounds.top} ${1290} ${textBounds.bottom} ${textBounds.left} ${textBounds.bottom}`
                    ]
                },
            ],
            duration: 500,
            begin: anim => {
                $path.css({ opacity: 1 });
                $line.css({ opacity: 0 });
            },
            update: anim => {
                let points = polygon.getAttribute("points").split(" ");
                let pts = [];
                for (let i = 0; i < points.length - 1; i += 2) {
                    pts.push([points[i], points[i + 1]]);
                }
                let intersection = this.intersectPolygons(textBounds.toPolyArray(), pts);
                path.setAttribute("d", `M${intersection[2][0]} ${intersection[2][1]} L${intersection[3][0]} ${intersection[3][1]}`);
            }
        });

        if (this.element.showImage) {
            this.timeline.add({
                targets: this.element.image.DOMNode,
                scale: [1, 1.3],
                duration: 10000
            }, 0);
        }
        return this.timeline;
    }

    playAnimationOut(onComplete) {
        let timeline = anime.timeline({
            easing: "easeInQuad",
        });

        timeline.add({
            targets: this.animText.lines,
            scale: 1.5,
            opacity: 0,
            duration: 600,
            delay: anime.stagger(200)
        });

        timeline.finished.then(() => {
            onComplete && onComplete();
        });

        timeline.play();
    }
}

import { AuthoringBlockType, CalloutType, ContentBlockType, NodeType, TextStyleType } from "common/constants";
import { detectGraphData } from "../../../../../core/services/sharedModelManager";
import * as geom from "../../../../../core/utilities/geom";
import { XYGraphControlBar, XYGraphPropertyPanel, XYGraphSelection } from "../../../Editor/ElementPropertyPanels/XYGraphUI";
import { BaseElement } from "../../base/BaseElement";
import { SVGPolygonElement, SVGPolylineElement } from "../../base/SVGElement";
import { TextElement } from "../../base/Text/TextElement";
import { GridComponent } from "../../components/Grid";
import { CalloutsCanvas } from "../Callouts/CalloutsCanvas";

class XYGraph extends BaseElement {
    get name() {
        return "XY Graph";
    }

    getElementPropertyPanel() {
        return XYGraphPropertyPanel;
    }

    getElementControlBar() {
        return XYGraphControlBar;
    }

    getCanvasMargins() {
        return {
            left: this.canvas.styleSheet.ElementMargin.marginLeft,
            top: this.canvas.styleSheet.ElementMargin.marginTop,
            right: this.canvas.styleSheet.ElementMargin.marginRight,
            bottom: this.canvas.styleSheet.ElementMargin.marginBottom
        };
    }

    get arrowHeadSize() {
        return new geom.Size(this.styles.xAxis.strokeWidth * 3.5, this.styles.xAxis.strokeWidth * 3.5 * 1.25);
    }

    pasteFromClipboard(data) {
        return this.graphCanvas.pasteFromClipboard(data);
    }

    _build() {
        this.graphCanvas = this.addElement("graphCanvas", () => XYGraphCanvas);

        if (this.model.showGrid) {
            this.grid = this.addElement("grid", () => GridComponent);
        }

        if (this.model.showAxis) {
            this.xAxis = this.addElement("xAxis", () => SVGPolylineElement);
            this.xAxis.layer = -1;
            this.yAxis = this.addElement("yAxis", () => SVGPolylineElement);
            this.yAxis.layer = -1;
            if (this.model.showAxisLabels) {
                this.yAxisLabel = this.addElement("yAxisLabel", () => XYGraphAxisLabel, {
                    autoWidth: true,
                    autoHeight: true,
                    placeholder: "Type label",
                    selectAllOnEdit: true,
                    showFocusedBlock: false,
                    showRolloverBlock: false
                });
                this.xAxisLabel = this.addElement("xAxisLabel", () => XYGraphAxisLabel, {
                    autoWidth: true,
                    autoHeight: true,
                    placeholder: "Type label",
                    selectAllOnEdit: true,
                    showFocusedBlock: false,
                    showRolloverBlock: false
                });

                if (this.model.axisPosition == "center") {
                    this.yAxisLabelEnd = this.addElement("yAxisLabelEnd", () => XYGraphAxisLabel, {
                        autoWidth: true,
                        autoHeight: true,
                        placeholder: "Type label",
                        selectAllOnEdit: true,
                        showFocusedBlock: false,
                        showRolloverBlock: false
                    });

                    this.xAxisLabelEnd = this.addElement("xAxisLabelEnd", () => XYGraphAxisLabel, {
                        autoWidth: true,
                        autoHeight: true,
                        placeholder: "Type label",
                        selectAllOnEdit: true,
                        showFocusedBlock: false,
                        showRolloverBlock: false
                    });
                }
            }

            if (this.model.showArrowHeads) {
                if (this.model.showAxis) {
                    this.xAxisArrowHeadRight = this.addElement("xAxisArrowHeadRight", () => SVGPolygonElement);
                    this.yAxisArrowHeadTop = this.addElement("yAxisArrowHeadTop", () => SVGPolygonElement);
                    if (this.model.axisPosition == "center") {
                        this.xAxisArrowHeadLeft = this.addElement("xAxisArrowHeadLeft", () => SVGPolygonElement);
                        this.yAxisArrowHeadBottom = this.addElement("yAxisArrowHeadBottom", () => SVGPolygonElement);
                    }
                }
            }
        }

        if (this.model.showQuadrants) {
            this.hRule = this.addElement("hRule", () => SVGPolylineElement);
            this.hRule.layer = -1;
            this.vRule = this.addElement("vRule", () => SVGPolylineElement);
            this.vRule.layer = -1;
            if (this.model.showQuadrantLabels) {
                this.label1 = this.addElement("label1", () => TextElement, {
                    autoWidth: true,
                    autoHeight: true,
                    selectAllOnEdit: true,
                });
                this.label2 = this.addElement("label2", () => TextElement, {
                    autoWidth: true,
                    autoHeight: true,
                    selectAllOnEdit: true
                });
                this.label3 = this.addElement("label3", () => TextElement, {
                    autoWidth: true,
                    autoHeight: true,
                    selectAllOnEdit: true
                });
                this.label4 = this.addElement("label4", () => TextElement, {
                    autoWidth: true,
                    autoHeight: true,
                    selectAllOnEdit: true
                });
            }
        }
    }

    _calcProps(props, options) {
        const { size } = props;

        const AXIS_PADDING = 20;
        const isCentered = this.model.axisPosition == "center";

        const bounds = new geom.Rect(0, 0, size.width, size.height);
        let plotBoxBounds = bounds.clone();

        if (this.model.showAxis && this.model.showAxisLabels) {
            if (isCentered) {
                plotBoxBounds = plotBoxBounds.deflate({
                    left: AXIS_PADDING,
                    right: AXIS_PADDING,
                    top: AXIS_PADDING,
                    bottom: AXIS_PADDING
                });
            } else {
                plotBoxBounds = plotBoxBounds.deflate({
                    left: AXIS_PADDING,
                    bottom: AXIS_PADDING
                });
            }
        }

        if (this.model.showGrid) {
            // Migration from old model values
            const gridSize = 300 / this.model.gridSize;
            const majorTickSpan = 4;
            // Adjusting bounds
            const majorTickSize = gridSize * 4;
            const width = Math.floor(plotBoxBounds.width / majorTickSize) * majorTickSize;
            const height = Math.floor(plotBoxBounds.height / majorTickSize) * majorTickSize;
            if (isCentered) {
                plotBoxBounds = plotBoxBounds.deflate({
                    left: (plotBoxBounds.width - width) / 2,
                    right: (plotBoxBounds.width - width) / 2,
                    top: (plotBoxBounds.height - height) / 2,
                    bottom: (plotBoxBounds.height - height) / 2,
                });
            } else {
                plotBoxBounds = plotBoxBounds.deflate({
                    right: plotBoxBounds.width - width,
                    top: plotBoxBounds.height - height,
                });
            }

            const gridProps = this.grid.calcProps(plotBoxBounds.size, {
                showMinorGridLines: false,
                majorTickSpan,
                gridSize
            });
            gridProps.bounds = plotBoxBounds;
            gridProps.layer = -1;
        }

        if (this.model.showAxis) {
            const arrowHeadSize = this.arrowHeadSize;
            const arrowLength = this.model.showArrowHeads ? arrowHeadSize.height : 0;

            const xAxisProps = this.xAxis.createProps({ layer: -1 });
            const yAxisProps = this.yAxis.createProps({ layer: -1 });

            if (isCentered) {
                xAxisProps.path = [[plotBoxBounds.centerH, plotBoxBounds.top + arrowLength], [plotBoxBounds.centerH, plotBoxBounds.bottom - arrowLength]];
                yAxisProps.path = [[plotBoxBounds.left + arrowLength, plotBoxBounds.centerV], [plotBoxBounds.right - arrowLength, plotBoxBounds.centerV]];

                if (this.model.showArrowHeads) {
                    const yAxisArrowHeadTop = this.yAxisArrowHeadTop.createProps({
                        path: [[plotBoxBounds.centerH, plotBoxBounds.top + arrowLength], [plotBoxBounds.centerH - arrowHeadSize.width / 2, plotBoxBounds.top + arrowLength], [plotBoxBounds.centerH, plotBoxBounds.top + arrowLength - arrowHeadSize.height], [plotBoxBounds.centerH + arrowHeadSize.width / 2, plotBoxBounds.top + arrowLength]],
                    });
                    this.yAxisArrowHeadTop.styles.applyStyles(this.styles.yArrow);

                    const xAxisArrowHeadRight = this.xAxisArrowHeadRight.createProps({
                        path: [[plotBoxBounds.right - arrowLength, plotBoxBounds.centerV], [plotBoxBounds.right - arrowLength, plotBoxBounds.centerV - arrowHeadSize.width / 2], [plotBoxBounds.right - arrowLength + arrowHeadSize.height, plotBoxBounds.centerV], [plotBoxBounds.right - arrowLength, plotBoxBounds.centerV + arrowHeadSize.width / 2]],
                    });
                    this.xAxisArrowHeadRight.styles.applyStyles(this.styles.xArrow);

                    const xAxisArrowHeadLeft = this.xAxisArrowHeadLeft.createProps({
                        path: [[plotBoxBounds.left, plotBoxBounds.centerV], [plotBoxBounds.left + arrowHeadSize.height, plotBoxBounds.centerV - arrowHeadSize.width / 2], [plotBoxBounds.left + arrowHeadSize.height, plotBoxBounds.centerV + arrowHeadSize.width / 2]],
                    });
                    this.xAxisArrowHeadLeft.styles.applyStyles(this.styles.xArrow);

                    const yAxisArrowHeadBottom = this.yAxisArrowHeadBottom.createProps({
                        path: [[plotBoxBounds.centerH - arrowHeadSize.width / 2, plotBoxBounds.bottom - arrowLength], [plotBoxBounds.centerH + arrowHeadSize.width / 2, plotBoxBounds.bottom - arrowLength], [plotBoxBounds.centerH, plotBoxBounds.bottom - arrowLength + arrowHeadSize.height]],
                    });
                    this.yAxisArrowHeadBottom.styles.applyStyles(this.styles.yArrow);
                }
            } else {
                xAxisProps.path = [[plotBoxBounds.left, plotBoxBounds.top + arrowLength], [plotBoxBounds.left, plotBoxBounds.bottom]];
                yAxisProps.path = [[plotBoxBounds.left, plotBoxBounds.bottom], [plotBoxBounds.right - arrowLength, plotBoxBounds.bottom]];

                if (this.model.showArrowHeads) {
                    const yAxisArrowHeadTop = this.yAxisArrowHeadTop.createProps({
                        path: [[plotBoxBounds.left, plotBoxBounds.top + arrowLength], [plotBoxBounds.left - arrowHeadSize.width / 2, plotBoxBounds.top + arrowLength], [plotBoxBounds.left, plotBoxBounds.top + arrowLength - arrowHeadSize.height], [plotBoxBounds.left + arrowHeadSize.width / 2, plotBoxBounds.top + arrowLength]],
                    });
                    this.yAxisArrowHeadTop.styles.applyStyles(this.styles.yArrow);

                    const xAxisArrowHeadRight = this.xAxisArrowHeadRight.createProps({
                        path: [[plotBoxBounds.right - arrowLength, plotBoxBounds.bottom], [plotBoxBounds.right - arrowLength, plotBoxBounds.bottom - arrowHeadSize.width / 2], [plotBoxBounds.right - arrowLength + arrowHeadSize.height, plotBoxBounds.bottom], [plotBoxBounds.right - arrowLength, plotBoxBounds.bottom + arrowHeadSize.width / 2]],
                    });
                    this.xAxisArrowHeadRight.styles.applyStyles(this.styles.xArrow);
                }
            }

            if (this.model.showAxisLabels) {
                const yAxisLabelProps = this.yAxisLabel.calcProps(size);
                yAxisLabelProps.bounds = new geom.Rect(-yAxisLabelProps.size.width / 2, plotBoxBounds.centerV - yAxisLabelProps.size.height / 2, yAxisLabelProps.size);
                yAxisLabelProps.rotate = -90;

                const xAxisLabelProps = this.xAxisLabel.calcProps(size);
                xAxisLabelProps.bounds = new geom.Rect(plotBoxBounds.centerH - this.xAxisLabel.textBounds.size.width / 2, plotBoxBounds.bottom, xAxisLabelProps.size);

                if (isCentered) {
                    const yAxisLabelEndProps = this.yAxisLabelEnd.calcProps(size);
                    yAxisLabelEndProps.bounds = new geom.Rect(size.width - yAxisLabelEndProps.size.width / 2, plotBoxBounds.centerV - yAxisLabelEndProps.size.height / 2, yAxisLabelEndProps.size);
                    yAxisLabelEndProps.rotate = 90;

                    const xAxisLabelEndProps = this.xAxisLabelEnd.calcProps(size);
                    xAxisLabelEndProps.bounds = new geom.Rect(plotBoxBounds.centerH - this.xAxisLabelEnd.textBounds.size.width / 2, plotBoxBounds.top - 40, xAxisLabelEndProps.size);
                }
            }
        }

        if (this.model.showQuadrants) {
            const vRule = this.vRule.createProps({});
            vRule.path = [[plotBoxBounds.centerH, plotBoxBounds.top], [plotBoxBounds.centerH, plotBoxBounds.bottom]];
            vRule.layer = -1;
            this.vRule.styles.applyStyles(this.styles.quadrantLine);

            const hRule = this.hRule.createProps({});
            hRule.path = [[plotBoxBounds.left, plotBoxBounds.centerV], [plotBoxBounds.right, plotBoxBounds.centerV]];
            hRule.layer = -1;
            this.hRule.styles.applyStyles(this.styles.quadrantLine);

            if (this.model.showQuadrantLabels) {
                const labelPadding = 10;

                const label1 = this.label1.calcProps(size);
                label1.bounds = new geom.Rect(plotBoxBounds.left + labelPadding, plotBoxBounds.top + labelPadding, plotBoxBounds.width / 2 - labelPadding * 2, label1.size.height);
                label1.layer = -1;

                const label2 = this.label2.calcProps(size);
                label2.bounds = new geom.Rect(plotBoxBounds.width / 2 + plotBoxBounds.left + labelPadding, plotBoxBounds.top + labelPadding, plotBoxBounds.width / 2 - labelPadding * 2, label2.size.height);
                label2.layer = -1;

                const label3 = this.label3.calcProps(size);
                label3.bounds = new geom.Rect(plotBoxBounds.left + labelPadding, plotBoxBounds.bottom - labelPadding - label3.size.height, plotBoxBounds.width / 2 - labelPadding * 2, label3.size.height);
                label3.layer = -1;

                const label4 = this.label4.calcProps(size);
                label4.bounds = new geom.Rect(plotBoxBounds.width / 2 + plotBoxBounds.left + labelPadding, plotBoxBounds.bottom - labelPadding - label4.size.height, plotBoxBounds.width / 2 - labelPadding * 2, label4.size.height);
                label4.layer = -1;
            }
        }

        const graphCanvasProps = this.graphCanvas.calcProps(plotBoxBounds.size, options);
        graphCanvasProps.bounds = plotBoxBounds.clone();

        return { size, plotBoxBounds };
    }

    _applyColors() {
        if (this.model.showAxis) {
            this.xAxis.colorSet.strokeColor = this.palette.getColor("primary", this.getBackgroundColor());
            this.yAxis.colorSet.strokeColor = this.palette.getColor("primary", this.getBackgroundColor());

            if (this.model.showArrowHeads) {
                this.xAxisArrowHeadRight.colorSet.fillColor = this.palette.getColor("primary", this.getBackgroundColor());
                this.yAxisArrowHeadTop.colorSet.fillColor = this.palette.getColor("primary", this.getBackgroundColor());
                if (this.model.axisPosition == "center") {
                    this.xAxisArrowHeadLeft.colorSet.fillColor = this.palette.getColor("primary", this.getBackgroundColor());
                    this.yAxisArrowHeadBottom.colorSet.fillColor = this.palette.getColor("primary", this.getBackgroundColor());
                }
            }
        }
        if (this.model.showQuadrants) {
            this.hRule.colorSet.strokeColor = this.palette.getColor("secondary", this.getBackgroundColor());
            this.vRule.colorSet.strokeColor = this.palette.getColor("secondary", this.getBackgroundColor());
        }
    }

    _migrate_10_02() {
        if (this.model.items) {
            for (let item of this.model.items) {
                if ((item.nodeType ?? NodeType.CIRCLE) === NodeType.CIRCLE) {
                    item.nodeType = NodeType.FLEX_CIRCLE;
                    item.markerSize = item.size;
                    delete item.size;
                }
            }
        }
    }
}

class XYGraphCanvas extends CalloutsCanvas {
    get restrictElementsToBounds() {
        return true;
    }

    get showSnapLines() {
        return true;
    }

    get maxItemCount() {
        return 100;
    }

    get allowDragDropElements() {
        return false;
    }

    get allowSnapToNonAuthoringElements() {
        return false;
    }

    getElementSelection() {
        return XYGraphSelection;
    }

    getChildOptions(model) {
        return {
            syncFontSizeWithSiblings: true,
            allowConnectors: false
        };
    }

    getAllowedCalloutTypes() {
        return [CalloutType.FLEX_CIRCLE, CalloutType.BOX, CalloutType.TEXT, CalloutType.BULLET_TEXT, CalloutType.NUMBERED_TEXT, CalloutType.CONTENT_AND_TEXT, CalloutType.CONTENT];
    }

    get canEditNumberedTextContentItemMarkerValue() {
        return true;
    }

    get defaultBlockType() {
        return ContentBlockType.TITLE;
    }

    refreshElement(transition) {
        this.canvas.refreshElement(this, transition);
    }

    get canRefreshElement() {
        return true;
    }

    _exportToSharedModel() {
        const { graphData, textContent } = this.itemElements.reduce((acc, item) => {
            const model = item._exportToSharedModel();
            return {
                graphData: [{ nodes: [...acc.graphData[0].nodes, ...model.graphData[0].nodes] }],
                textContent: [...acc.textContent, ...model.textContent]
            };
        }, { graphData: [{ nodes: [] }], textContent: [] });

        return { graphData, textContent };
    }

    _importFromSharedModel(model) {
        const { nodes } = detectGraphData(model);
        if (!nodes?.length) return;

        const items = nodes.map(node => ({
            ...(node.props || {}),
            x: node.x || 0.1 + Math.random() * .65,
            y: node.y || 0.1 + Math.random() * .75,
            nodeType: NodeType.CIRCLE,
            text: node.textContent && {
                blocks: [
                    {
                        html: node.textContent.mainText.text,
                        textStyle: node.textContent.mainText.textStyle || TextStyleType.TITLE,
                        type: AuthoringBlockType.TEXT,
                    },
                    ...node.textContent.secondaryTexts.map(({ text, textStyle }) => ({
                        html: text,
                        textStyle: textStyle || TextStyleType.BODY,
                        type: AuthoringBlockType.TEXT,
                    }))
                ]
            }
        }));

        return { items };
    }
}

class XYGraphAxisLabel extends TextElement {
    get selectionBounds() {
        if (Math.abs(this.calculatedProps.rotate) === 90) {
            let selectionBounds = super.selectionBounds;
            return new geom.Rect(selectionBounds.centerH - selectionBounds.height / 2, selectionBounds.centerV - selectionBounds.width / 2, selectionBounds.height, selectionBounds.width);
        } else {
            return super.selectionBounds;
        }
    }

    get autoWidth() {
        return true;
    }

    get autoHeight() {
        return true;
    }

    get placeholderPrompt() {
        return "Type label";
    }

    get textAlign() {
        return "center";
    }

    get allowAlignment() {
        return false;
    }

    get maxLines() {
        return 1;
    }
}

export const elements = {
    XYGraph,
};

import React, { Component } from "react";
import { HorizontalPropertyList, PropertyPanelContainer, PropertySection, PropertySectionHeader } from "../../../../../EditorComponents/PropertyPanel";
import { DialogTitle, DialogActions } from "@material-ui/core";
import { BeautifulDialog, DialogContent, ShowDialog } from "../../../../../react/components/Dialogs/BaseDialog";
import { IconButton } from "../../../../../Components/IconButton";
import styled from "styled-components";
import { WithLabel } from "../../../../../Components/WithLabel";
import { TextField } from "../../../../../Components/TextField";
import { Button } from "../../../../../Components/Button";
import { PropertyPanelButton } from "../../../../../EditorComponents/PropertyPanelButton";
import { FlexSpacer } from "../../../../../react/components/Gap";
import { FormattingDropdown } from "../../../Editor/EditorComponents/FormattingOptions";
import { FormatType } from "../../../../../../common/constants";
import { CollectionColorPicker } from "../../../Editor/EditorComponents/ColorPickers/CollectionColorPicker";
import { CollectionElementControlBar } from "../../../Editor/ElementControlBars/CollectionElementControlBar";
import { FlexBox } from "../../../../../react/components/LayoutGrid";
import { NumericStepper } from "../../../../../Components/NumericStepper";
import { ControlBar } from "../../../Editor/ElementControlBars/Components/ControlBar";
import { Icon } from "../../../../../Components/Icon";
import { Dropdown } from "../../../../../Components/Dropdown";
import { MenuItem } from "../../../../../Components/Menu";

const InputRow = styled.div`
    display: flex;
    align-items: center;
`;

export class ROICalculatorPropertyPanel extends Component {
    handleChangePreset = value => {
        const { element } = this.props;
        switch (value) {
            case "real-estate":
                element.updateModel({
                    inputs: [{
                        name: "loan",
                        label: "Loan Amount",
                        min: 1, max: 5000000, step: 1000, format: FormatType.CURRENCY, value: 100000
                    }, {
                        name: "loanTerm",
                        label: "Loan Term",
                        min: 1, max: 30, step: 1, format: FormatType.NUMBER, value: 30
                    }, {
                        name: "interestRate",
                        label: "Interest Rate",
                        min: 0, max: 10, step: 0.1, format: FormatType.PERCENT, value: 0.05
                    }],

                    calculations: [{
                        name: "monthlyPayment",
                        label: "Monthly Payment",
                        formula: "{(loan * (interestRate / 1200)) / (1 - (1 + interestRate / 1200) ** (-loanTerm * 12))}",
                        format: FormatType.CURRENCY
                    }]
                });
                break;
        }
    }

    render() {
        const { element } = this.props;

        // use regex to extract the variables from the formula '{(loan * (interestRate / 1200)) / (1 - Math.pow(1 + interestRate / 1200, -loanTerm * 12))}'
        // const formula = "{(loan * (interestRate / 1200)) / (1 - Math.pow(1 + interestRate / 1200, -loanTerm * 12))}";
        // const variables = formula.match(/{(.*?)}/g).map(v => v.replace(/[{}]/g, ''));
        // console.log(variables);

        return (
            <PropertyPanelContainer>
                <PropertySection>
                    <WithLabel label="Accent Color">
                        <CollectionColorPicker element={element} />
                    </WithLabel>
                </PropertySection>

                <PropertySection>
                    <WithLabel label="Preset Calculators">
                        <Dropdown onChange={this.handleChangePreset}>
                            <MenuItem value="real-estate">Real Estate ROI</MenuItem>
                            <MenuItem value="stock-market">Stock Market ROI</MenuItem>
                            <MenuItem value="business">Business ROI</MenuItem>
                        </Dropdown>
                    </WithLabel>
                </PropertySection>
                {/*<PropertySection>*/}
                {/*    <PropertySectionHeader label="Inputs" />*/}
                {/*    {element.model.inputs.map((input, index) => {*/}
                {/*        return (*/}
                {/*            <InputRow key={index}>*/}
                {/*                <label>{input.name}</label>*/}
                {/*                <FlexSpacer />*/}
                {/*                <IconButton icon="edit" onClick={() => {*/}
                {/*                    ShowDialog(EditInputDialog, { element, input });*/}
                {/*                }} />*/}
                {/*                <IconButton icon="delete" onClick={() => {*/}
                {/*                    element.model.inputs.splice(index, 1);*/}
                {/*                    element.saveModel();*/}
                {/*                }} />*/}
                {/*            </InputRow>*/}
                {/*        );*/}
                {/*    })}*/}
                {/*</PropertySection>*/}
                {/*<PropertyPanelButton icon="input" title="Add Input" onClick={() => {*/}
                {/*    let input = { name: "input", label: "New Input", value: 1, min: 0, max: 100, step: 1 };*/}
                {/*    ShowDialog(EditInputDialog, { element, input });*/}
                {/*}} />*/}
                {/*<PropertySection>*/}
                {/*    <PropertySectionHeader label="Calculations" />*/}
                {/*    {element.model.calculations.map((calculation, index) => {*/}
                {/*        return (*/}
                {/*            <InputRow key={index}>*/}
                {/*                <label>{calculation.label}</label>*/}
                {/*                <FlexSpacer />*/}
                {/*                <IconButton icon="edit" onClick={() => {*/}
                {/*                    ShowDialog(EditCalculationDialog, { element, calculation });*/}
                {/*                }} />*/}
                {/*                <IconButton icon="delete" onClick={() => {*/}
                {/*                    element.model.calculations.splice(index, 1);*/}
                {/*                    element.saveModel();*/}
                {/*                }} />*/}
                {/*            </InputRow>*/}
                {/*        );*/}
                {/*    })}*/}
                {/*</PropertySection>*/}
                {/*<PropertyPanelButton icon="calculate" title="Add Calculation" onClick={() => {*/}
                {/*    let calculation = { label: "Result", formula: "0" };*/}
                {/*    ShowDialog(EditCalculationDialog, { element, calculation });*/}
                {/*}} />*/}
            </PropertyPanelContainer>
        );
    }
}

export class ROIInputsControlBar extends CollectionElementControlBar {
    get addItemLabel() {
        return "Add Input";
    }

    onAddNewItem = async () => {
        ShowDialog(EditInputDialog, { element: this.props.element, input: { name: "input", label: "New Input", value: 1, min: 0, max: 100, step: 1 } });
    }
}

export class ROICalculationsControlBar extends CollectionElementControlBar {
    get addItemLabel() {
        return "Add Calculation";
    }

    onAddNewItem = async () => {
        ShowDialog(EditCalculationDialog, { element: this.props.element, calculation: { label: "Result", formula: "0" } });
    }
}

export class ROIInputControlBar extends Component {
    render() {
        const { element } = this.props;
        return (
            <ControlBar>
                <Button blue onClick={() => {
                    ShowDialog(EditInputDialog, { element: element.parentElement, input: element.model });
                }}>
                    <Icon>input</Icon>Edit Input...
                </Button>
            </ControlBar>
        );
    }
}

class EditInputDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.input
        };
    }

    handleSave = () => {
        const { element } = this.props;
        document.querySelectorAll("input,textarea").forEach(function(element) {
            if (element === document.activeElement) {
                return element.blur();
            }
        });

        let inputs = element.model.inputs;
        if (inputs.contains(this.props.input)) {
            inputs[inputs.indexOf(this.props.input)] = this.state;
        } else {
            inputs.push(this.state);
        }
        element.updateModel({ inputs });

        this.props.closeDialog();
    }

    render() {
        const { name, value, label, min, max, step, format, formatOptions } = this.state;

        return (
            <BeautifulDialog closeButton closeDialog={this.props.closeDialog}>
                <DialogTitle>Edit Input</DialogTitle>
                <DialogContent>
                    {/*<PropertyPanelContainer>*/}
                    {/*    <PropertySection>*/}
                    <FlexBox left vertical gap={20} fill>
                        <WithLabel label="Name" below fillWidth>
                            <TextField fullWidth value={name} onChange={value => this.setState({ name: value })} />
                        </WithLabel>
                        <WithLabel label="Label" below fillWidth>
                            <TextField fullWidth value={label} onChange={value => this.setState({ label: value })} />
                        </WithLabel>
                        <HorizontalPropertyList>
                            <WithLabel label="Default Value" below center>
                                <NumericStepper value={value} onChange={value => this.setState({ value: parseFloat(value) })} />
                            </WithLabel>
                            <WithLabel label="Min" below center>
                                <NumericStepper value={min} onChange={value => this.setState({ min: parseInt(value) })} />
                            </WithLabel>
                            <WithLabel label="Max" below center>
                                <NumericStepper value={max} onChange={value => this.setState({ max: parseInt(value) })} />
                            </WithLabel>
                            <WithLabel label="Step" below center>
                                <NumericStepper value={step} onChange={value => this.setState({ step: parseFloat(value) })} />
                            </WithLabel>
                        </HorizontalPropertyList>
                        <WithLabel label="Format">
                            <FormattingDropdown format={format}
                                formatOptions={formatOptions}
                                onChangeFormat={format => this.setState({ format: format })}
                                onChangeFormatOptions={formatOptions => this.setState({ formatOptions: formatOptions })}
                                allowedFormats={[FormatType.NUMBER, FormatType.CURRENCY, FormatType.PERCENT]}
                            />
                        </WithLabel>
                    </FlexBox>
                    {/*</PropertySection>*/}
                    {/*</PropertyPanelContainer>*/}
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.closeDialog}>Cancel</Button>
                    <Button onClick={this.handleSave}>Save</Button>
                </DialogActions>
            </BeautifulDialog>
        );
    }
}

class EditCalculationDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.calculation
        };
    }

    handleSave = () => {
        const { element } = this.props;

        document.querySelectorAll("input,textarea").forEach(function(element) {
            if (element === document.activeElement) {
                return element.blur();
            }
        });

        let calculations = element.model.calculations;
        if (calculations.contains(this.props.calculation)) {
            calculations[calculations.indexOf(this.props.calculation)] = this.state;
        } else {
            calculations.push(this.state);
        }
        element.updateModel({ calculations });

        this.props.closeDialog();
    }

    render() {
        const { label, formula, format, formatOptions } = this.state;

        return (
            <BeautifulDialog closeButton closeDialog={this.props.closeDialog}>
                <DialogTitle>Edit Calculation</DialogTitle>
                <DialogContent>
                    <PropertyPanelContainer>
                        <PropertySection>
                            <WithLabel label="Label" below fillWidth>
                                <TextField fullWidth value={label} onChange={value => {
                                    this.setState({ label: value });
                                }} />
                            </WithLabel>
                            <WithLabel label="Formula" below fillWidth>
                                <TextField fullWidth value={formula} onChange={value => this.setState({ formula: value })} />
                            </WithLabel>
                            <WithLabel label="Format">
                                <FormattingDropdown format={format}
                                    formatOptions={formatOptions}
                                    onChangeFormat={format => this.setState({ format })}
                                    onChangeFormatOptions={formatOptions => this.setState({ formatOptions })}
                                    allowedFormats={[FormatType.NUMBER, FormatType.CURRENCY, FormatType.PERCENT]}
                                />
                            </WithLabel>
                        </PropertySection>
                    </PropertyPanelContainer>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.closeDialog}>Cancel</Button>
                    <Button onClick={this.handleSave}>Save</Button>
                </DialogActions>
            </BeautifulDialog>
        );
    }
}

export class ROICalculationControlBar extends Component {
    render() {
        const { element } = this.props;
        return (
            <ControlBar>
                <Button blue onClick={() => {
                    ShowDialog(EditCalculationDialog, { element: element.parentElement, calculation: element.model });
                }}>
                    <Icon>calculate</Icon>Edit Calculation...
                </Button>
            </ControlBar>
        );
    }
}


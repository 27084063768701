import React, { Component } from "react";
import styled from "styled-components";
import { PositionType } from "common/constants";
import { themeColors } from "js/react/sharedStyles";

const Container = styled.div`
    display: grid;
    //width: 90px;
    //height: 40px;
    gap:2px;
    grid-template-columns: repeat(3, 1fr);
    //border-top: solid 1px #ccc;
    //border-left: solid 1px #ccc;
    div {
        background: white;
        border: solid 1px #aaa;
        //border-bottom: solid 1px #ccc;
        //border-right: solid 1px #ccc;
        border-radius: 0%;
        height: 9px;
        width: 9px;
        font-size: 9px;
        display: flex;
        align-items: center;
        justify-content: center;
        
        &:hover {
            border: solid 1px ${themeColors.ui_blue};
        }
        
        &.selected {
            background: ${themeColors.ui_blue};
            color: white;
        }
        
        &.hidden {
            visibility: hidden;
        }
    }
`;

export class PositionPicker extends Component {
    render() {
        const { value = PositionType.CENTER, onChange } = this.props;

        return (
            <Container>
                <div className={value == PositionType.TOP_LEFT ? "selected" : ""} onClick={() => onChange(PositionType.TOP_LEFT)}/>
                <div className={value == PositionType.TOP ? "selected" : ""} onClick={() => onChange(PositionType.TOP)}/>
                <div className={value == PositionType.TOP_RIGHT ? "selected" : ""} onClick={() => onChange(PositionType.TOP_RIGHT)}/>
                <div className={value == PositionType.LEFT ? "selected" : ""} onClick={() => onChange(PositionType.LEFT)}/>
                <div className={value == PositionType.CENTER ? "selected" : ""} onClick={() => onChange(PositionType.CENTER)}/>
                <div className={value == PositionType.RIGHT ? "selected" : ""} onClick={() => onChange(PositionType.RIGHT)}/>
                <div className={value == PositionType.BOTTOM_LEFT ? "selected" : ""} onClick={() => onChange(PositionType.BOTTOM_LEFT)}/>
                <div className={value == PositionType.BOTTOM ? "selected" : ""} onClick={() => onChange(PositionType.BOTTOM)}/>
                <div className={value == PositionType.BOTTOM_RIGHT ? "selected" : ""} onClick={() => onChange(PositionType.BOTTOM_RIGHT)}/>
            </Container>
        );
    }
}

export class TextPositionPicker extends Component {
    render() {
        const { value, onChange } = this.props;
        return (
            <Container>
                <div className="hidden"/>
                <div className={value == PositionType.TOP ? "selected" : ""} onClick={() => onChange(PositionType.TOP)}/>
                <div className="hidden"/>
                <div className={value == PositionType.LEFT ? "selected" : ""} onClick={() => onChange(PositionType.LEFT)}/>
                <div className={(value == "auto" || value == null) ? "selected" : ""} onClick={() => onChange("auto")}>AUTO</div>
                <div className={value == PositionType.RIGHT ? "selected" : ""} onClick={() => onChange(PositionType.RIGHT)}/>
                <div className="hidden"/>
                <div className={value == PositionType.BOTTOM ? "selected" : ""} onClick={() => onChange(PositionType.BOTTOM)}/>
                <div className="hidden"/>
            </Container>
        );
    }
}


import React, { Component } from "react";
import { PropertyPanelContainer, PropertySection, PropertySectionNote } from "../../../../EditorComponents/PropertyPanel";
import { ToggleSwitch } from "../../../../Components/ToggleSwitch";
import { WithLabel } from "../../../../Components/WithLabel";
import { Dropdown } from "../../../../Components/Dropdown";
import { MenuItem } from "../../../../Components/Menu";
import { DisabledStateToolTip } from "../../../../Components/ToolTip";
import { PropertyPanelHeader } from "../../../../editor/ElementEditor/PropertyPanelHeader";
import { ShowFooterType } from "../../../../../common/constants";

export class FooterPropertyPanel extends Component {
    render() {
        const { element, canvas } = this.props;

        return (
            <PropertyPanelContainer>
                <PropertyPanelHeader>Footer</PropertyPanelHeader>
                <PropertySectionNote>
                    <p>
                        Use these settings to override Footer styles on this slide.
                    </p>
                    <p>
                        To change these settings for all slides, edit your Footer settings in the theme.
                    </p>
                </PropertySectionNote>
                <PropertySection>
                    <WithLabel label="Show Footer">
                        <ToggleSwitch value={canvas.getCanvasElement().showFooter}
                            onChange={async value => {
                                canvas.model.layout.showFooter = value ? ShowFooterType.ON : ShowFooterType.OFF;
                                canvas.markStylesAsDirty();
                                await canvas.updateCanvasModelWithErrorDialog(false);
                            }}
                        />
                    </WithLabel>
                </PropertySection>
                <PropertySection>
                    <WithLabel label="Show Slide Number">
                        <ToggleSwitch value={element.showPageNum} onChange={value => element.updateModel({ showPageNumOverride: value }, { refreshStyles: true })} />
                    </WithLabel>
                    <WithLabel label="Show Message">
                        <ToggleSwitch value={element.showMessage} onChange={value => element.updateModel({ showMessageOverride: value }, { refreshStyles: true })} />
                    </WithLabel>
                    <WithLabel label="Show Logo">
                        <DisabledStateToolTip disabled={!element.logoType} disabledTooltip="Logos must be enabled in the theme settings to show on this slide">
                            <ToggleSwitch value={element.showLogo} onChange={value => element.updateModel({ showLogoOverride: value }, { refreshStyles: true })} disabled={!element.logoType} />
                        </DisabledStateToolTip>
                    </WithLabel>
                </PropertySection>
                <PropertySection>
                    <WithLabel label="Footer Text Color">
                        <Dropdown value={element.overrideStyle}
                            onChange={value => element.updateModel({ overrideStyle: value }, { refreshStyles: true })}
                        >
                            <MenuItem value="auto">Auto</MenuItem>
                            <MenuItem value="light">Light</MenuItem>
                            <MenuItem value="dark">Dark</MenuItem>
                        </Dropdown>
                    </WithLabel>
                    {element.showLogo && (
                        <>
                            <WithLabel label="Logo Color">
                                <Dropdown value={element.logoType}
                                    onChange={value => element.updateModel({ logoTypeOverride: value }, { refreshStyles: true })}
                                >
                                    <MenuItem value="auto">Auto</MenuItem>
                                    <MenuItem value="light">Light Background</MenuItem>
                                    <MenuItem value="dark">Dark Background</MenuItem>
                                </Dropdown>
                            </WithLabel>
                            <WithLabel label="Logo Position">
                                <Dropdown value={element.logoPosition}
                                    onChange={value => element.updateModel({ logoPositionOverride: value }, { refreshStyles: true })}
                                >
                                    <MenuItem value="left">Left</MenuItem>
                                    <MenuItem value="right">Right</MenuItem>
                                </Dropdown>
                            </WithLabel>
                        </>
                    )}
                </PropertySection>
            </PropertyPanelContainer>
        );
    }
}


import React, { useEffect, useRef } from "react";

import { numeral } from "../../../../vendor";
import * as geom from "../../../../core/utilities/geom";
import { ShowDialog } from "../../../../react/components/Dialogs/BaseDialog";
import EditTextDialog from "../../../../react/components/Dialogs/EditTextDialog";

export function ThermometerLabelSelection({ element, selectionLayerController }) {
    useEffect(() => {
        const thermometer = element.getParentOfType("Thermometer");

        const selectionBounds = element.selectionBounds;
        const screenBounds = element.getScreenBounds().offset(0, -selectionBounds.height / 2);
        const targetPt = new geom.Point(screenBounds.centerH, screenBounds.centerV);

        const currentValue = thermometer.model.value / 100 * thermometer.model.targetMax;

        ShowDialog(EditTextDialog, {
            targetPt,
            target: element,
            minWidth: 150,
            value: currentValue,
            onClose: () => {
                selectionLayerController.setSelectedElements([thermometer]);
            },
            callback: value => {
                if (!numeral.validateEx(value)) {
                    return;
                }

                if (value.includes("%")) {
                    const indexOfPercent = value.indexOf("%");
                    value = value.substring(0, indexOfPercent);
                }
                const updatedValue = numeral(value).value() * 100 / thermometer.model.targetMax;
                if (updatedValue >= 100) {
                    thermometer.model.value = 100;
                } else {
                    thermometer.model.value = updatedValue;
                }
                thermometer.canvas.updateCanvasModel(false).then(() => {
                    selectionLayerController.setSelectedElements([thermometer]);
                });
            },
        });
    }, []);

    return null;
}

import React from "react";
import * as geom from "js/core/utilities/geom";
import { _ } from "js/vendor";
import { FlexGridItemSelection } from "../../../../Editor/ElementPropertyPanels/GridContainer/FlexGridSelection";
import { ImageOption } from "../../../../../../Components/ImageOptionList";
import { themeColors } from "../../../../../../react/sharedStyles";
import { FlexGridLayout } from "./FlexGridLayout";
import { InfographicElementTypes } from "../../Dashboard/InfographicManager";
import { ShowAssetDialog } from "../../../../../../react/views/AddAssets/ShowAssetDialog";
import { TextStyleType } from "../../../../../../../common/constants";

export class HeadlineLayout extends FlexGridLayout {
    getName() {
        return "Headline";
    }

    getCellSelection() {
        return FlexGridItemSelection;
    }

    getDefaultLayout(itemCount) {
        return {
            cols: 12,
            rows: 12,
            ...this.layouts.find(layout => layout.cells.length === itemCount)
        };
    }

    renderThumbnails(layouts, width = 84, height = 50) {
        return [
            <ImageOption key="none" width={100} url="/images/ui/headline/headline-noimage.svg" onClick={this.setHeadlineNoImage} />,
            <ImageOption key="background" width={100} url="/images/ui/headline/headline-background.svg" onClick={this.setHeadlineBackground} />,
            <ImageOption key="left" url="/images/ui/headline/headline-right.svg" onClick={() => this.handleSplitCells("left")} />,
            <ImageOption key="right" url="/images/ui/headline/headline-left.svg" onClick={() => this.handleSplitCells("right")} />,
            <ImageOption key="top" url="/images/ui/headline/headline-bottom.svg" onClick={() => this.handleSplitCells("top")} />,
            <ImageOption key="bottom" url="/images/ui/headline/headline-top.svg" onClick={() => this.handleSplitCells("bottom")} />
        ];
    }

    setHeadlineNoImage = () => {
        const element = this.element;

        let textCell = element.itemElements.find(item => item.childElement.showText);
        if (!textCell) {
            // create a single cell with text and no image
            element.itemCollection = [{
                componentType: InfographicElementTypes.TEXT_AND_IMAGE,
                childElement: {
                    text: { blocks: [{ type: "text", textStyle: TextStyleType.HEADLINE, text: "" }] }
                }
            }];
        } else {
            // delete any cells with just images and no text
            element.itemElements.filter(item => item.childElement.showImage && !item.childElement.showText).forEach(item => {
                element.itemCollection.remove(item.model);
            });

            // remove image if any from textCell
            delete textCell.childElement.model.content_type;
            delete textCell.childElement.model.content_value;
        }

        // set the single cell layout
        element.setGridLayout(element.getLayoutEngine().getDefaultLayout(1));
        element.saveModel();
    }

    setHeadlineBackground = async () => {
        const element = this.element;

        if (element.itemCount == 2) {
            // find the text and image cells
            let textCell = element.itemElements.find(item => item.childElement.showText && !item.childElement.showImage);
            let imageCell;
            if (!textCell) {
                imageCell = element.itemElements.find(item => item.childElement.showImage);
                if (imageCell) {
                    textCell = element.itemElements.find(item => item != imageCell);
                } else {
                    imageCell = element.itemElements[0];
                    textCell = element.itemElements[1];
                }
            } else {
                imageCell = element.itemElements.find(item => item != textCell);
            }

            let imageCellModel = imageCell.model.childElement;
            let textCellModel = textCell.model.childElement;

            if (!imageCellModel || imageCell.model.componentType !== InfographicElementTypes.TEXT_AND_IMAGE) {
                // if there is no imageCell or it's not TextAndImage, create a new one
                imageCellModel = {};
                imageCell.model.componentType = InfographicElementTypes.TEXT_AND_IMAGE;
                imageCell.model.childElement = imageCellModel;
            }

            // merge text and image
            imageCellModel.text = _.cloneDeep(textCellModel.text);
            imageCellModel.textAlign = textCellModel.textAlign;
            imageCellModel.verticalAlign = textCellModel.verticalAlign;
            imageCellModel.backdropPadding = textCellModel.backdropPadding;
            delete imageCellModel.userPositionX;
            delete imageCellModel.userPositionY;

            // remove the text cell since it's been merged with the image cell
            element.itemCollection.remove(textCell.model);

            // set the single cell layout
            element.setGridLayout(element.getLayoutEngine().getDefaultLayout(1));
        }

        if (!element.itemCollection[0].childElement.content_type || !element.itemCollection[0].childElement.content_value) {
            let model = await ShowAssetDialog();
            element.itemElements[0].childElement.updateModel(model);
        }
        element.saveModel();
    }

    handleSplitCells = async imagePosition => {
        const element = this.element;

        let imageCell, textCell;

        if (element.itemCount == 1) {
            imageCell = element.itemElements[0].model;

            // create a text cell using either the text in the image cell or an empty text block
            textCell = {
                componentType: InfographicElementTypes.TEXT_AND_IMAGE,
                childElement: {
                    text: imageCell.childElement.text ?? { blocks: [{ type: "text", textStyle: "heading", text: "" }] },
                    textAlign: imageCell.childElement.textAlign,
                    verticalAlign: imageCell.childElement.verticalAlign,
                }
            };
            // delete the text from the image cell
            delete imageCell.childElement.text;
            delete imageCell.childElement.textAlign;
            delete imageCell.childElement.verticalAlign;
        } else {
            // if we already have 2 cells just find the text and image cells and let them get layed out below
            imageCell = element.itemElements.find(item => !item.childElement.showText).model;
            textCell = element.itemElements.find(item => item.model != imageCell).model;
        }

        element.itemCollection = [];
        switch (imagePosition) {
            case "left":
                element.setGridLayout(element.getLayoutEngine().layouts[1]);
                element.itemCollection = [imageCell, textCell];
                break;
            case "right":
                element.setGridLayout(element.getLayoutEngine().layouts[1]);
                element.itemCollection = [textCell, imageCell];
                break;
            case "top":
                element.setGridLayout(element.getLayoutEngine().layouts[2]);
                element.itemCollection = [imageCell, textCell];
                break;
            case "bottom":
                element.setGridLayout(element.getLayoutEngine().layouts[2]);
                element.itemCollection = [textCell, imageCell];
                break;
        }

        if (!imageCell.childElement.content_value || !imageCell.childElement.content_type) {
            let model = await ShowAssetDialog();
            Object.assign(imageCell.childElement, model);
        }

        element.saveModel();
    }
}


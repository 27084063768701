import { BaseElementSelection } from "../ElementSelections/BaseElementSelection";
import { IconButton } from "../../../../Components/IconButton";
import { CollectionElementControlBar } from "../ElementControlBars/CollectionElementControlBar";
import React, { Component } from "react";
import { PropertyPanelHeader } from "../../../../editor/ElementEditor/PropertyPanelHeader";
import { PropertyPanelContainer, PropertySection, PropertySectionHeader } from "../../../../EditorComponents/PropertyPanel";
import { WithLabel } from "../../../../Components/WithLabel";
import { Dropdown } from "../../../../Components/Dropdown";
import { MenuItem } from "../../../../Components/Menu";
import { PositionType } from "../../../../../common/constants";
import { Slider } from "../../../../Components/Slider";
import { ToggleSwitch } from "../../../../Components/ToggleSwitch";

export class PresentationTitleLogosPropertyPanel extends Component {
    render() {
        const { element, selectedElements } = this.props;

        const selectedElement = selectedElements[0];

        return (
            <PropertyPanelContainer>
                <PropertyPanelHeader>Logos</PropertyPanelHeader>
                <PropertySection>
                    <WithLabel label="Position" flex>
                        <Dropdown value={element.position}
                            onChange={value => element.updateModel({ position: value }, { transition: true })}
                        >
                            <MenuItem value={PositionType.TOP_LEFT}>Top Left</MenuItem>
                            <MenuItem value={PositionType.CENTER}>With Title</MenuItem>
                            <MenuItem value={PositionType.TOP_RIGHT}>Top Right</MenuItem>
                        </Dropdown>
                    </WithLabel>
                    <WithLabel label="Scale">
                        <Slider value={element.scale}
                            onChange={value => element.refreshModel({ scale: value })}
                            onCommit={value => element.updateModel({ scale: value })}
                            min={0.25} max={1.5} step={0.01}
                            showInput percent
                        />
                    </WithLabel>
                </PropertySection>
                {selectedElement &&
                <PropertySection>
                    <WithLabel label="Show Backdrop" toolTip="Adds a white backdrop to the logos. This can be useful to ensure legibility of logos with transparency (especially over image backgrounds) or when you have a mix of transparent and opaque logos.">
                        <ToggleSwitch value={selectedElement.model.showBackdrop}
                            onChange={value => selectedElement.updateModel({ showBackdrop: value })}
                        />
                    </WithLabel>
                </PropertySection>
                }
            </PropertyPanelContainer>
        );
    }
}

export class PresentationTitleLogosControlBar extends CollectionElementControlBar {
    get addItemLabel() {
        return "Add Logo";
    }
}

import { TextElement } from "../../base/Text/TextElement";
import { ValueLabelSelection } from "../../../Editor/ElementSelections/ValueLabelSelection";

export default class CompareValuesItemLabel extends TextElement {
    getElementSelection() {
        return ValueLabelSelection;
    }

    get _canSelect() {
        return true;
    }
}

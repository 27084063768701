import React, { Component } from "react";

import { ElementTextBlockPositionType } from "../../../../../common/constants";
import { ImageOption, ImageOptionList } from "../../../../Components/ImageOptionList";
import { StaticImage } from "../../../../Components/StaticImage";
import { WithLabel } from "../../../../Components/WithLabel";

import { TextElementSelection } from "../ElementSelections/TextElementSelection";
import { BackgroundColorPicker } from "../EditorComponents/ColorPickers/BackgroundColorPicker";
import { PropertyPanelContainer, PropertySection } from "../../../../EditorComponents/PropertyPanel";

export class TextTrayPropertyPanel extends Component {
    render() {
        const { element } = this.props;

        const canvas = element.canvas;

        return (
            <PropertyPanelContainer>
                <PropertySection color="white" label="Lower Tray">
                    <ImageOptionList value={canvas.model.layout.elementTextBlockPosition}
                        cols={2}
                        onChange={value => {
                            canvas.model.layout.elementTextBlockPosition = value;
                            canvas.updateCanvasModel();
                        }}
                    >
                        <ImageOption label="Tray" value={ElementTextBlockPositionType.TRAY}>
                            <StaticImage src="/images/ui/trays/bottom_tray_text.png" />
                        </ImageOption>
                        <ImageOption label="Text" value={ElementTextBlockPositionType.INLINE}>
                            <StaticImage src="/images/ui/trays/bottom_inline_text.png" />
                        </ImageOption>
                    </ImageOptionList>
                </PropertySection>
                {canvas.model.layout.elementTextBlockPosition == ElementTextBlockPositionType.TRAY && (
                    <PropertySection>
                        <WithLabel label="Background Color">
                            <BackgroundColorPicker element={element} />
                        </WithLabel>
                    </PropertySection>
                )}
            </PropertyPanelContainer>
        );
    }
}

export class TextTraySelection extends TextElementSelection {
    get canDelete() {
        return true;
    }

    get showSelectionBorder() {
        return true;
    }

    handleDeleteElement = () => {
        const { element } = this.props;
        element.parentElement.model.layout.elementTextBlockPosition = ElementTextBlockPositionType.NONE;
        element.canvas.updateCanvasModel(true);
    }
}

export const svgImageWithFilterToUrl = async (imageUrl, filter) => {
    // Skip if we don't have a filter
    if (!filter) {
        return imageUrl;
    }

    return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = "anonymous";
        img.onload = () => {
            const canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext("2d");
            ctx.filter = `url(#${filter})`;

            ctx.drawImage(img, 0, 0);

            let result = canvas.toDataURL();
            resolve(result);
        };
        img.onerror = err => {
            reject(err);
        };
        img.src = imageUrl;
    });
};

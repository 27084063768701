import React, { Fragment } from "react";
import styled from "styled-components";

import { Icon } from "@material-ui/core";

import * as geom from "../../../../../core/utilities/geom";
import { ShowDialog } from "../../../../../react/components/Dialogs/BaseDialog";
import EditTextDialog from "../../../../../react/components/Dialogs/EditTextDialog";

import * as helpers from "./helpers";

const WidgetButtonContainer = styled.div.attrs(({ position }) => ({
    style: {
        left: position.x,
        top: position.y,
    }
}))`
    position: absolute;
    width: 18px;
    height: 18px;
    transform: translate(-50%, -50%) translateX(-1px);
    transition: 50ms all linear;

    pointer-events: auto;
    cursor: pointer;
    background: #50bbe6;
    border: solid 1px #50bbe6;
    color: #fff;
    border-radius: 50%;
    
    display: flex;
    align-items: center;
    justify-content: center;

    >span.MuiIcon-root {
        font-size: 14px;
    }
`;

const AxisWidgetLabel = styled.div.attrs(({ vertical }) => ({
    style: vertical
        ? {
            left: "50%",
            bottom: "calc(100% + 20px)",
            transform: "translateX(-50%)"
        }
        : {
            left: "calc(100% + 20px)",
            top: "50%",
            transform: "translateY(-50%)"
        }
}))`
    position: absolute;    
    background: #50bbe6;
    color: #fff;
    pointer-events: auto;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    border-radius: 6px;
    padding: 5px 14px;
    white-space: nowrap;
`;

const AxisWidgetLabelConnector = styled.div.attrs(({ vertical }) => ({
    style: vertical
        ? {
            width: "1px",
            height: "20px",
            left: "50%",
            bottom: "100%",
            transform: "translateX(-50%)"
        }
        : {
            width: "20px",
            height: "1px",
            left: "100%",
            top: "50%",
            transform: "translateY(-50%)"
        }
}))`
    position: absolute;
    border: dashed 1px #50bbe6;
    opacity: .5;
`;

function AxisWidget({ position, label, vertical, onClick }) {
    return (<WidgetButtonContainer position={position} onClick={onClick}>
        <Icon>add</Icon>
        <AxisWidgetLabelConnector vertical={vertical} />
        <AxisWidgetLabel vertical={vertical}>
            {label}
        </AxisWidgetLabel>
    </WidgetButtonContainer>);
}

const AxisWidgetsContainer = styled.div.attrs(({ bounds }) => ({
    style: {
        ...bounds.toObject()
    }
}))`
    position: absolute;
    overflow: visible;
    pointer-events: auto;
    cursor: pointer;
`;

const AxisTitleFrame = styled.div.attrs(({ bounds }) => ({
    style: {
        ...bounds.inflate(5).toObject()
    }
}))`
    position: absolute;
    cursor: text;
`;

export class AxisWidgets extends React.Component {
    static boundsWidth = 40;

    constructor(props) {
        super(props);

        this.state = {
            widgetPosition: null
        };

        this.animationFrameHandledAt = null;

        this.ref = React.createRef();
    }

    get hasAxisTitle() {
        const { axis } = this.props;
        return axis.axisTitle?.textStr.length > 0;
    }

    getAxisBounds(canvas, axis) {
        const { left, top, width, height, isXAxis, opposite } = axis;

        let bounds = new geom.Rect(left, top, width, height).spaceScale(canvas.getScale());
        if (isXAxis) {
            bounds = bounds.deflate({ top: bounds.height });
        } else if (opposite) {
            bounds = bounds.deflate({ left: bounds.width });
        } else {
            bounds = bounds.deflate({ right: bounds.width });
        }

        return bounds;
    }

    componentDidMount() {
        document.addEventListener("mousemove", this.handleMouseMove);
    }

    componentWillUnmount() {
        document.removeEventListener("mousemove", this.handleMouseMove);
    }

    getContainerAndAxisTitleBounds() {
        const { axis } = this.props;

        if (!this.ref.current) {
            return {
                containerScreenBounds: null,
                axisTitleScreenBounds: null
            };
        }

        return {
            containerScreenBounds: geom.Rect.FromBoundingClientRect(this.ref.current.getBoundingClientRect()),
            axisTitleScreenBounds: this.hasAxisTitle ? geom.Rect.FromBoundingClientRect(axis.axisTitle.element.getBoundingClientRect()) : null
        };
    }

    handleMouseMove = event => {
        window.requestAnimationFrame(timestamp => {
            if (this.animationFrameHandledAt === timestamp) {
                return;
            }
            this.animationFrameHandledAt = timestamp;

            const { axis } = this.props;

            const { containerScreenBounds } = this.getContainerAndAxisTitleBounds();
            if (!containerScreenBounds) {
                this.setWidgetPosition(null);
                return;
            }

            let position = new geom.Point(event.pageX, event.pageY);
            if (!containerScreenBounds.contains(position)) {
                this.setWidgetPosition(null);
                return;
            }

            position = position.offset(-containerScreenBounds.x, -containerScreenBounds.y);
            if (axis.isXAxis) {
                position.y = AxisWidgets.boundsWidth / 2;
            } else {
                position.x = AxisWidgets.boundsWidth / 2;
            }

            this.setWidgetPosition(position);
        });
    }

    setWidgetPosition(position) {
        const { widgetPosition } = this.state;

        if (position?.toString() === widgetPosition?.toString()) {
            return;
        }

        this.setState({ widgetPosition: position });
    }

    handleAddAxisAnnotation = async () => {
        const { canvas, element, selectionLayerController, axis } = this.props;
        const { widgetPosition } = this.state;

        const annotations = helpers.getAnnotations(element);

        const axisBounds = this.getAxisBounds(canvas, axis);

        let x, y;
        if (axis.isXAxis) {
            x = widgetPosition.x / axisBounds.width;
            y = Math.random() * 0.4 + 0.3;
        } else {
            x = Math.random() * 0.4 + 0.3;
            y = widgetPosition.y / axisBounds.height;
        }

        const { annotationModel, connectorModel } = helpers.getAxisAnnotationAndConnectorModels(element.uniquePath, axis.isXAxis ? "xAxis" : "yAxis", x, y);
        const annotation = annotations.addItem(annotationModel);
        annotations.connectors.addItem(connectorModel);

        await element.updateModel();

        const addedAnnotation = annotations.getItemElementById(annotation.id);
        await selectionLayerController.setSelectedElements([addedAnnotation]);
    }

    handleEditAxisTitle = () => {
        const { element, axis, axisIndex } = this.props;
        const { axisTitleScreenBounds } = this.getContainerAndAxisTitleBounds();

        let axisModel = element.chartModel.xAxis;
        if (!axis.isXAxis) {
            if (axisIndex === 1) {
                axisModel = element.chartModel.yAxis;
            } else {
                axisModel = element.chartModel.yAxis2;
            }
        }

        ShowDialog(EditTextDialog, {
            targetPt: axisTitleScreenBounds.center,
            value: axisModel.axisTitleText,
            callback: value => {
                axisModel.axisTitleText = value;
                element.updateModel();
            },
        });
    }

    render() {
        const { canvas, axis } = this.props;
        const { widgetPosition } = this.state;

        const { axisTitleScreenBounds, containerScreenBounds } = this.getContainerAndAxisTitleBounds();

        const axisBounds = this.getAxisBounds(canvas, axis);

        const hoverBounds = axis.isXAxis
            ? axisBounds.inflate({ top: AxisWidgets.boundsWidth / 2, bottom: AxisWidgets.boundsWidth / 2 })
            : axisBounds.inflate({ left: AxisWidgets.boundsWidth / 2, right: AxisWidgets.boundsWidth / 2, bottom: -AxisWidgets.boundsWidth / 2 });

        let axisTitleFrameBounds = null;
        if (containerScreenBounds && axisTitleScreenBounds) {
            axisTitleFrameBounds = axisTitleScreenBounds.offset(-containerScreenBounds.x, -containerScreenBounds.y);
        }

        return (<AxisWidgetsContainer bounds={hoverBounds} ref={this.ref}>
            {widgetPosition && <AxisWidget
                position={widgetPosition}
                label={axis.isXAxis ? "xaxis label" : "yaxis label"}
                vertical={axis.isXAxis}
                onClick={this.handleAddAxisAnnotation}
            />}
            {axisTitleFrameBounds && <AxisTitleFrame
                bounds={axisTitleFrameBounds}
                onClick={this.handleEditAxisTitle}
            />}
        </AxisWidgetsContainer>);
    }
}

import moment from "moment";

import { defaultDragResizeProps } from "../../../../editor/PresentationEditor/DragElementManager";
import { ResizeDirection } from "../../../../../common/constants";

import { CollectionItemElementSelection } from "./CollectionItemElementSelection";

export class GanttChartTaskSelection extends CollectionItemElementSelection {
    get canResize() {
        return true;
    }

    get dragResizeProps() {
        const { element } = this.props;

        const ganttChart = element.parentElement;

        let startDate, endDate, totalDays, dayWidth, taskStartDate, taskEndDate;
        return {
            ...defaultDragResizeProps,
            resizeDirections: [
                ResizeDirection.LEFT,
                ResizeDirection.RIGHT
            ],
            onDragStart: async () => {
                startDate = ganttChart.startDate;
                endDate = ganttChart.endDate;
                totalDays = moment.duration(endDate.diff(startDate)).asDays();
                dayWidth = ganttChart.bounds.width / totalDays;
                taskStartDate = element.taskStartDate.clone();
                taskEndDate = element.taskEndDate.clone();
            },
            onDrag: async ({ dragOffset, resizeDirection }) => {
                const offsetDays = Math.round(dragOffset.x / dayWidth);
                if (resizeDirection === ResizeDirection.LEFT) {
                    element.model.startDate = Math.max(taskStartDate.clone().add(offsetDays, "days").toDate().getTime());
                } else {
                    element.model.endDate = Math.min(taskEndDate.clone().add(offsetDays, "days").toDate().getTime());
                }

                await element.canvas.refreshCanvas();
            }
        };
    }

    get dragPositionProps() {
        const { element } = this.props;

        const ganttChart = element.parentElement;

        let totalDays, dayWidth, taskStartDate, taskEndDate;
        return {
            ...super.dragPositionProps,
            onDragStart: async () => {
                totalDays = moment.duration(ganttChart.endDate.diff(ganttChart.startDate)).asDays();
                dayWidth = ganttChart.bounds.width / totalDays;
                taskStartDate = element.taskStartDate.clone();
                taskEndDate = element.taskEndDate.clone();
            },
            onDrag: async ({ dragOffset }) => {
                const offsetDays = Math.round(dragOffset.x / dayWidth);
                if (Math.abs(offsetDays) < 1) {
                    return;
                }

                element.model.startDate = Math.max(taskStartDate.clone().add(offsetDays, "days").toDate().getTime());
                element.model.endDate = Math.max(taskEndDate.clone().add(offsetDays, "days").toDate().getTime());

                await element.canvas.refreshCanvas();
            }
        };
    }
}

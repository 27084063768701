import React, { Component } from "react";
import { Button as MaterialButton } from "@material-ui/core";
import styled from "styled-components";
import { Button } from "../../../../Components/Button";

const Container = styled.div`
    position: absolute;
    pointer-events: all;
    
    .MuiButton-root {
        background: #c3e9fb;
        border-radius: 0px;
        padding: 3px 10px;
    }
    .MuiButton-label {
        font-size: 9px;
        font-weight: 500;
        color: #333;
        text-transform: uppercase;
    }
`;

const StyledButton = styled(Button)`
    background: #c3e9fb;
    border: none !important;
    border-radius: 0px;
    font-size: 9px;
    font-weight: 500;
    color: #222;
    text-transform: uppercase;
    height: 20px;
    white-space: nowrap;
`;

export class ElementUIButton extends Component {
    render() {
        const { style, onClick } = this.props;

        return (
            <Container style={style}>
                <StyledButton onClick={onClick} >
                    {this.props.children}
                </StyledButton>
            </Container>
        );
    }
}


import React, { FC, useRef } from "react";
import styled from "styled-components";
import { Chip, Tooltip } from "@material-ui/core";
import { Key } from "js/core/utilities/keys";
import { FlexBox } from "js/react/components/LayoutGrid";

const TagChip = styled(Chip)`
&& {
    font-size: 12px;
    font-weight: 600;
    border-radius: 5px;
    background: #F9F9F9;
    color: #666666;
} 
`;

const AddTagLabel = styled.label`
& {
    flex-grow: 1;
    height: 30px;
}

&& > textarea {
    resize: none;
    overflow-x: none;
    overflow-y: hidden;
    border: none;
    font-family: Source Sans Pro;
    font-size: 14px;
    line-height: 21px;
    box-sizing: border-box;

    height: 0;
    width: 0;
    padding: 0;
}

&:focus-within {
    flex-basis: 100%;

    & > ${TagChip} {
        display: none;
    }
}

& > textarea:focus {
    height: 100%;
    width: 100%;
    padding: 5px;
}
`;

export type TagDef = {
    icon: any,
    label: string,
    value?: any,
}

type TagListProps = {
    tags: TagDef[],
    disabled?: boolean,
    onTagClicked?: (tag: TagDef) => void,
    onTagAdded?: (label: string) => void,
    addTagContent?: TagDef,
    addTagPlaceholder?: string,
    onEnteringAdd?: () => void,
    onExitingAdd?: () => void,
    onShowTooltip?: (tag: TagDef) => string | false,
}

export const TagList: FC<TagListProps> = ({
    tags,
    disabled = false,
    onTagClicked,
    onTagAdded,
    addTagContent = {
        label: "+",
        icon: null,
    },
    addTagPlaceholder = "...type a tag to add",
    onEnteringAdd,
    onExitingAdd,
    onShowTooltip = (tag) => false,
}) => {
    const refLabel = useRef(null);

    return (
        <FlexBox left wrap gap={5}>
            {
                tags.map((tag, index) => {
                    let result;
                    const tooltip = onShowTooltip(tag);
                    if (tooltip) {
                        result = (
                            <Tooltip
                                key={`tag-${index}`}
                                title={tooltip}
                                placement="top-start"
                                arrow
                            >
                                <TagChip
                                    icon={tag.icon}
                                    label={tag.label}
                                    disabled={disabled}
                                    onClick={onTagClicked && (() => onTagClicked(tag))}
                                />
                            </Tooltip>
                        )
                    }
                    else {
                        result = (
                            <TagChip
                                key={`tag-${index}`}
                                icon={tag.icon}
                                label={tag.label}
                                disabled={disabled}
                                onClick={onTagClicked && (() => onTagClicked(tag))}
                            />
                        );
                    }
                    return result;
                })
            }
            {
                !!onTagAdded &&
                <AddTagLabel ref={refLabel}>
                    <TagChip
                        icon={addTagContent.icon}
                        label={addTagContent.label}
                        disabled={disabled}
                        onClick={() => onEnteringAdd && onEnteringAdd()}
                    />
                    <textarea
                        placeholder={addTagPlaceholder}
                        disabled={disabled}
                        onBlur={evt => {
                            const elem = evt.target as HTMLTextAreaElement;
                            onExitingAdd && onExitingAdd();
                            const label = elem.value.trim();
                            if (label) {
                                onTagAdded(label);
                            }
                            elem.value = "";
                            refLabel.current.style.height = null;
                        }}
                        onInput={(evt) => {
                            const elem = evt.target as HTMLTextAreaElement;
                            refLabel.current.style.height = `${elem.scrollHeight}px`;
                        }}
                        onKeyDown={evt => {
                            switch (evt.keyCode) {
                                case Key.ENTER:
                                case Key.ESCAPE:
                                    evt.persist();
                                    evt.stopPropagation();
                                    evt.preventDefault();

                                    const elem = evt.target as HTMLTextAreaElement;
                                    elem.blur();
                                break;
                            }
                        }}
                    />
                </AddTagLabel>
            }
        </FlexBox>
    );
}
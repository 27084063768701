import { _ } from "js/vendor";
import { AuthoringBlockType, TextStyleType } from "common/constants";
import { detectListContent } from "js/core/services/sharedModelManager";
import { PhotoCollageItemPropertyPanel, PhotoCollagePropertyPanel } from "../../Editor/ElementPropertyPanels/GridContainer/PhotoCollageUI";
import { FramedMediaElement } from "../base/MediaElements/FramedMediaElement";
import { GridLayoutContainer, LayoutEngineType } from "./LayoutContainers/GridLayoutContainer";
import { LayoutContainerItem } from "./LayoutContainers/LayoutContainerItem";

class PhotoCollage extends GridLayoutContainer {
    getElementPropertyPanel() {
        return PhotoCollagePropertyPanel;
    }

    get name() {
        return "Photo Grid";
    }

    getChildItemType() {
        return PhotoCollageItem;
    }

    get defaultItemData() {
        return {
            componentType: "TextAndImage",
            childElement: {}
        };
    }

    get maxItemCount() {
        return 10;
    }

    get canPasteImage() {
        return true;
    }

    get canEdit() {
        return true;
    }

    _build() {
        this.options.canPasteImage = this.canPasteImage;
        this.options.canEdit = this.canEdit;

        super._build();
    }

    get fullBleed() {
        if (this.aspectRatio == "fit") {
            return false;
        } else {
            return this.options.fullBleed || this.model.fullBleed;
        }
    }

    get showCaption() {
        return this.model.showCaption;
    }

    get aspectRatio() {
        return this.model.aspectRatio || "fill";
    }

    get frame() {
        return this.model.frame || "none";
    }

    get showGutter() {
        if (this.itemElements.length > 1 || (this.fullBleed)) {
            return this.model.showGutter || false;
        } else {
            return false;
        }
    }

    get reserveFooterSpace() {
        if (this.fullBleed && this.showGutter) {
            return true;
        } else {
            return false;
        }
    }

    get gutterSize() {
        return 10;
    }

    get disableAnimationsByDefault() {
        return this.itemCollection.length === 1;
    }

    get allowText() {
        return true;
    }

    get allowSnapToNonAuthoringElements() {
        return true;
    }

    _migrate_10_02() {
        if (this.model.aspectRatio == "fit") {
            this.model.layoutEngineType = LayoutEngineType.PRESERVE_ASPECT;
        } else {
            this.model.layoutEngineType = LayoutEngineType.FLEX;
        }

        this.model[this.collectionPropertyName] = this.itemCollection.map(item => {
            let cellColor = item.cellColor == "auto" ? this.canvas.getSlideColor() : item.cellColor;
            return {
                componentType: "TextAndImage",
                cellColor,
                childElement: _.cloneDeep(item)
            };
        });

        super._migrate_10_02();
    }

    _exportToSharedModel() {
        const assets = this.itemElements.reduce(
            (assets, itemElement) => ([
                ...assets,
                ...(itemElement.elements.element?._exportToSharedModel()?.assets || []),
            ]), []
        );

        const textContent = this.itemElements.reduce(
            (textContent, itemElement) => ([
                ...textContent,
                ...(itemElement.elements.element?._exportToSharedModel()?.textContent || [])
            ]), []
        );

        return { assets, textContent };
    }

    _importFromSharedModel(model) {
        const listContent = detectListContent(model);
        if (!listContent?.length) return;

        const items = listContent.map(({ text, asset }) => ({
            cellColor: this.canvas.getBackgroundColor().name,
            componentType: "TextAndImage",
            childElement: {
                ...(asset ? {
                    content_type: asset.type,
                    content_value: asset.value,
                    assetName: asset.name,
                    assetProps: asset.props,
                    ...(asset.configProps ?? {})
                } : {}),
                ...(text ? {
                    text: {
                        blocks: [
                            {
                                html: text.mainText.text,
                                textStyle: text.mainText.textStyle || TextStyleType.HEADING,
                                type: AuthoringBlockType.TEXT,
                            },
                            ...text.secondaryTexts.map(secondaryText => ({
                                html: secondaryText.text,
                                textStyle: secondaryText.textStyle || TextStyleType.BODY,
                                type: AuthoringBlockType.TEXT,
                            }))
                        ]
                    }
                } : {})
            }
        }));

        return { items };
    }
}

class PhotoCollageItem extends LayoutContainerItem {
    getElementPropertyPanel() {
        return PhotoCollageItemPropertyPanel;
    }

    get allowElementSnap() {
        return true;
    }

    get name() {
        return "Cell";
    }

    get _canSelect() {
        return true;
    }

    get selectionPadding() {
        return 0;
    }

    get passThroughSelection() {
        return true;
    }

    get componentType() {
        return "TextAndImage";
    }
}

class PhotoCollageContent extends FramedMediaElement {
    get defaultOverlayType() {
        return "ContentElementDefaultOverlay";
    }

    get _canSelect() {
        return false;
    }

    get _canRollover() {
        return false;
    }
}

export { PhotoCollage, PhotoCollageContent, PhotoCollageItem };

export const elements = {
    PhotoCollage
};

import styled from "styled-components";

import { themeColors } from "../../../../../react/sharedStyles";

export const ReactGridContainer = styled.div`
    overflow-x: scroll;
    overflow-y: hidden;
    
    >.reactgrid {
        overflow: hidden;
    }
    
    .rg-celleditor-input, .rg-celleditor input {
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        background: transparent;
        font-size: 1em;
        outline: none;
        border: none;
    }

    .rg-celleditor {
        box-sizing: border-box;
        z-index: 5;
        background-color: #ffffff;
        box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.06);
        display: flex;
        border-style: solid;
        border-color: #11a9ef;
        border-width: 2px;
        padding: 0 4px;
    }

    .rg-number-celleditor input {
        text-align: right;
        border: none;
    }

    .reactgrid-content .rg-pane .rg-cell.rg-number-cell,
    .reactgrid-content .rg-pane .rg-cell .rg-time-cell,
    .reactgrid-content .rg-pane .rg-cell .rg-date-cell {
        justify-content: center;
        font-size: 14px;
        background: white;
    }

    .reactgrid-content .rg-pane .rg-text-cell {
        justify-content: center;
        font-weight: bold;
        background: #f9f9f9;
    }

    .reactgrid-content .rg-pane .rg-date-cell {
        justify-content: center;
        font-weight: bold;
        background: #f9f9f9;
    }

    .reactgrid-content .rg-pane .rg-header-cell {
        justify-content: center;
        font-weight: bold;
        background: ${themeColors.selection} !important;
    }

    .reactgrid-content .rg-pane .rg-cell.rg-email-cell.rg-invalid {
        color: rgb(255, 0, 0);
    }

    .reactgrid-content .rg-pane .rg-cell.rg-text-cell.placeholder {
        color: #999;
        font-size: 0.8em;
    }

    .reactgrid-content .rg-pane .rg-cell.rg-checkbox-cell {
        align-items: center;
        justify-content: center;
        padding: 0;
        margin: 0;
        background: transparent;
        pointer-events: auto;
    }


    .reactgrid-content .rg-pane .rg-cell.rg-checkbox-cell input {
        width: 20px;
        height: 20px;
    }

    .reactgrid-content .rg-pane .rg-cell.rg-chevron-cell .chevron {
        pointer-events: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        margin-right: 0.1em;
        cursor: pointer;
        transition: 200ms all ease-in-out;
        height: 1em;
        width: 1em;
    }

    .reactgrid-content .rg-pane .rg-cell.rg-chevron-cell .no-child {
        width: 0.5em;
    }

    .reactgrid-content .rg-pane .rg-cell.rg-chevron-cell.expanded .chevron {
        transform: rotate(90deg);
    }

    .reactgrid-content .rg-pane .rg-cell.rg-chevron-cell.collapsed .chevron {
        transform: rotate(0deg);
    }

    .reactgrid-content .rg-pane .rg-cell.rg-checkbox-cell {
        justify-content: center;
    }

    .reactgrid-content .rg-pane .rg-cell.rg-checkbox-cell label {
        position: relative;
        height: 18px;
        width: 18px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        margin: 0;
    }

    .reactgrid-content .rg-pane .rg-cell.rg-checkbox-cell label input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        margin: 0;
        height: 18px;
        width: 18px;
    }

    .reactgrid-content .rg-pane .rg-cell.rg-checkbox-cell span {
        position: absolute;
        top: 0;
        left: 0;
        height: 18px;
        width: 18px;
        background-color: #eeeeee;
    }

    .reactgrid-content .rg-pane .rg-cell.rg-checkbox-cell label:hover input ~ span {
        background-color: #cccccc;
    }

    .reactgrid-content .rg-pane .rg-cell.rg-checkbox-cell label input:checked ~ span {
        background-color: #3579f8;
    }

    .reactgrid-content .rg-pane .rg-cell.rg-checkbox-cell span:after {
        content: "";
        position: absolute;
        display: none;
    }

    .reactgrid-content .rg-pane .rg-cell.rg-checkbox-cell label input:checked ~ span:after {
        display: block;
    }

    .reactgrid-content .rg-pane .rg-cell.rg-checkbox-cell label span:after {
        left: 7px;
        top: 2px;
        width: 4px;
        height: 12px;
        border: solid #ffffff;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    .reactgrid-content .rg-pane .rg-cell.rg-dropdown-cell {
        padding: 0;
        overflow: visible;
    }

    .reactgrid-content .rg-pane .rg-cell.rg-dropdown-cell .rg-dropdown-menu {
        top: 100%;
        background-color: #ffffff;
        border-radius: 4px;
        box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.5);
        margin-bottom: 8px;
        margin-top: 2px;
        position: absolute;
        width: 100%;
        z-index: 1;
        box-sizing: border-box;
    }

    .reactgrid-content .rg-pane .rg-cell.rg-dropdown-cell .rg-dropdown-option {
        padding: 0.3em 0.5em;
        min-height: 1.5em;
        display: flex;
        align-items: center;
    }

    .reactgrid-content .rg-pane .rg-cell.rg-dropdown-cell .rg-dropdown-option.selected::before {
        content: "✓";
        padding-right: 0.2em;
    }

    .reactgrid-content .rg-pane .rg-cell.rg-dropdown-cell .rg-dropdown-option.focused {
        color: black;
        background-color: #e2ebfe;
    }

    [contenteditable] {
        -webkit-user-select: text;
        user-select: text;
    }

    .reactgrid-content {
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        color: #000000;
    }

    .reactgrid-content .rg-pane.rg-pane-top, .reactgrid-content .rg-pane.rg-pane-bottom, .reactgrid-content .rg-pane.rg-pane-left, .reactgrid-content .rg-pane.rg-pane-right {
        position: sticky;
        position: -webkit-sticky;
        background-color: #ffffff;
    }

    .reactgrid-content .rg-pane.rg-pane-top {
        top: 0;
    }

    .reactgrid-content .rg-pane.rg-pane-bottom {
        bottom: 0;
    }

    .reactgrid-content .rg-pane.rg-pane-left {
        left: 0;
    }

    .reactgrid-content .rg-pane.rg-pane-right {
        right: 0;
    }

    .reactgrid-content .rg-pane .rg-cell {
        font-size: 1em;
        box-sizing: border-box;
        white-space: nowrap;
        position: absolute;
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow: hidden;
        padding: 0 4px;
        outline: none;
        touch-action: auto;
        border-color: #e8e8e8;
        color: #000000;
    }

    .reactgrid-content .rg-pane .rg-cell .rg-touch-resize-handle {
        position: absolute;
        top: 0;
        right: 0;
        width: 11px;
        height: 100%;
        pointer-events: auto;
    }

    .reactgrid-content .rg-pane .rg-cell .rg-touch-resize-handle .rg-resize-handle {
        position: absolute;
        right: 0;
        width: 6px;
        height: 100%;
    }

    .reactgrid-content .rg-pane .rg-cell .rg-touch-resize-handle .rg-resize-handle:hover {
        cursor: col-resize;
        background-color: #3579f8;
    }

    .reactgrid-content .rg-pane .rg-cell .rg-groupId {
        font-size: 0.8em;
        position: absolute;
        right: 4px;
        top: 4px;
    }

    .reactgrid-content .rg-pane .rg-cell-focus,
    .reactgrid-content .rg-pane .rg-cell-highlight {
        position: absolute;
        pointer-events: none;
        box-sizing: border-box;
        border-style: solid;
        border-width: 2px;
        border-color: #11a9e2;
    }

    .reactgrid-content .rg-pane .rg-touch-fill-handle {
        position: absolute;
        width: 40px;
        height: 40px;
        background-color: rgba(255, 255, 255, 0.01);
        touch-action: none;
        pointer-events: auto;
    }

    .reactgrid-content .rg-pane .rg-touch-fill-handle .rg-fill-handle {
        position: absolute;
        cursor: crosshair;
        top: 50%;
        left: 50%;
        transform: translate(calc(-50% - (1px / 2)), calc(-50% - (1px / 2)));
        width: 6.5px;
        height: 6.5px;
        background-color: #11a9e2;
        border-width: 1px;
        border-style: solid;
        border-color: #ffffff;
        background-clip: content-box;
    }

    .reactgrid-content .rg-pane .rg-partial-area {
        position: absolute;
        pointer-events: none;
        box-sizing: border-box;
    }

    .reactgrid-content .rg-pane .rg-partial-area.rg-partial-area-part {
        border-width: 1px;
        border-style: dashed;
        border-color: #000000;
    }

    .reactgrid-content .rg-pane .rg-partial-area.rg-partial-area-selected-range {
        border-width: 1px;
        border-style: solid;
        border-color: #3579f8;
        background-color: rgba(53, 121, 248, 0.35);
    }

    .reactgrid-content .rg-pane-shadow {
        position: sticky;
        display: none; // hide shadow effect
    }

    .reactgrid-content .rg-pane-shadow.shadow-top {
        pointer-events: none;
        top: 0;
        box-shadow: 2px 2px 3px 1px rgba(0, 0, 0, 0.06);
    }

    .reactgrid-content .rg-pane-shadow.shadow-left {
        pointer-events: none;
        left: 0;
        box-shadow: 5px 0 3px -2px rgba(0, 0, 0, 0.06);
    }

    .reactgrid-content .rg-pane-shadow.shadow-bottom {
        pointer-events: none;
        bottom: 0;
        box-shadow: 2px -1px 3px 1px rgba(0, 0, 0, 0.06);
    }

    .reactgrid-content .rg-pane-shadow.shadow-right {
        pointer-events: none;
        right: 0;
        box-shadow: -5px 0 3px -2px rgba(0, 0, 0, 0.06);
    }

    .reactgrid-content .rg-pane-shadow.shadow-top-left-corner {
        box-shadow: 2px 3px 3px 1px rgba(0, 0, 0, 0.06);
    }

    .reactgrid-content .rg-pane-shadow.shadow-top-right-corner {
        box-shadow: -2px 2px 3px 1px rgba(0, 0, 0, 0.06);
    }

    .reactgrid-content .rg-pane-shadow.shadow-bottom-left-corner {
        box-shadow: 2px -2px 3px 1px rgba(0, 0, 0, 0.06);
    }

    .reactgrid-content .rg-pane-shadow.shadow-bottom-right-corner {
        box-shadow: -2px -2px 3px 1px rgba(0, 0, 0, 0.06);
    }

    .reactgrid-content .rg-context-menu {
        position: fixed;
        z-index: 1000;
        background-color: #ffffff;
        font-size: 14px;
        font-weight: 600;
        font-family: Source Sans Pro;
        box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.06);
    }

    .reactgrid-content .rg-context-menu .rg-context-menu-option {
        padding: 10px 20px 10px 15px;
        cursor: pointer;
    }

    .reactgrid-content .rg-context-menu .rg-context-menu-option:hover {
        background-color: #f2f2f2;
    }

    .reactgrid-content .rg-shadow {
        position: absolute;
        background-color: #000000;
        opacity: 0.1;
        z-index: 4;
    }

    .reactgrid-content .rg-column-resize-hint {
        background-color: #74b9ff;
        position: absolute;
        padding: 5px;
        border-radius: 0 5px 5px 0;
    }

    .reactgrid-content .rg-line {
        position: absolute;
        background-color: #74b9ff;
        z-index: 4;
    }

    .reactgrid-content .rg-line-horizontal {
        left: 0;
        height: 2px;
    }

    .reactgrid-content .rg-line-vertical {
        top: 0;
        width: 2px;
    }

    .reactgrid-content .rg-hidden-element {
        border: 0;
        padding: 0;
        margin: 0;
        position: fixed;
        width: 1px;
        height: 1px;
        opacity: 0;
        top: 50%;
        left: 50%;
    }
`;

import { v4 as uuid } from "uuid";

import * as geom from "js/core/utilities/geom";
import { csvDataToChartData, getSelectedSpreadsheetData } from "js/core/utilities/xlsx";
import { _ } from "js/vendor";

import {
    GridPlaceholderDefaultOverlay,
    GridPlaceholderPropertyPanel
} from "../../../Editor/ElementPropertyPanels/GridContainer/Infographics/GridPlaceholderUI";

import { HorizontalAlignType, VerticalAlignType } from "common/constants";
import { addTextToLayoutContainerItem } from "../../../Editor/ElementPropertyPanels/GridContainer/Infographics/TextAndImageUI";
import { LayoutContainerItemNoFitDefaultOverlay, LayoutContainerItemPropertyPanel, LayoutContainerItemSelection, LayoutContainerItemSelectionOverlay } from "../../../Editor/ElementPropertyPanels/GridContainer/LayoutContainerItemUI";
import { BaseElement } from "../../base/BaseElement";
import { CollectionItemElement } from "../../base/CollectionElement";
import { TextElement } from "../../base/Text/TextElement";
import { AuthoringCanvas } from "../AuthoringCanvas";
import { Chart } from "../Dashboard/Chart";
import { InfographicElementTypes } from "../Dashboard/InfographicManager";
import { RadialBarInfographic } from "../Dashboard/RadialBarInfographic";
import { Statistic } from "../Dashboard/Statistic";
import { PieChart } from "../PieChart";
import { RadialBarChart } from "../RadialBarChart";
import { TableFrame } from "../Table/Table";
import { TextAndImage } from "../TextAndImage";

export class LayoutContainerItem extends CollectionItemElement {
    getElementPropertyPanel() {
        return this.options.elementPropertyPanel !== undefined ? this.options.elementPropertyPanel : LayoutContainerItemPropertyPanel;
    }

    getElementSelection() {
        return this.options.elementSelection !== undefined ? this.options.elementSelection : LayoutContainerItemSelection;
    }

    getElementDefaultOverlay() {
        if (this.calculatedProps?.isChildFit === false) {
            return LayoutContainerItemNoFitDefaultOverlay;
        }
        if (this.isChildSelected && this.componentType !== "GridPlaceholder") {
            return LayoutContainerItemSelectionOverlay;
        }
    }

    get _showDefaultOverlay() {
        return true;
    }

    get extendLastItemDropBounds() {
        return false;
    }

    get name() {
        return "Cell";
    }

    get clipboardElement() {
        return this;
    }

    get showTitle() {
        if (this.componentType) {
            return this.model.showTitle ?? false;
        } else {
            return false;
        }
    }

    get _canSelect() {
        return true;
    }

    get selectionPadding() {
        return 0;
    }

    get _canRollover() {
        return true;
    }

    get rolloverPadding() {
        return { left: 0, right: 0, top: 0, bottom: 15 };  // allows extra space for category axis title clicking
    }

    get componentType() {
        return this.model.componentType;
    }

    get hasElementDefined() {
        return this.componentType !== "GridPlaceholder";
    }

    get allowText() {
        return this.options.allowText ?? true;
    }

    get cellColor() {
        return this.model.cellColor ?? this.parentElement.cellColor;
    }

    get allowBackgroundColor() {
        return this.childElement.allowBackgroundColor ?? true;
    }

    _exportToSharedModel() {
        return this.childElement._exportToSharedModel();
    }

    _build() {
        if (this.showTitle) {
            if (!this.model.chartTitle) {
                this.model.chartTitle = {};
            }
            this.chartTitle = this.addElement("chartTitle", () => LayoutContainerTitleCaption, {
                model: this.model.chartTitle,
                autoHeight: true,
                scaleTextToFit: true,
                singleLine: true,
                allowAlignment: true
            });
            this.chartTitle.layer = 2;
        }

        let childElementType;
        let childElementOptions = {};
        let doubleClickToSelect = false;
        switch (this.componentType) {
            case InfographicElementTypes.CHART:
                childElementType = Chart;
                doubleClickToSelect = true;
                break;
            case InfographicElementTypes.PIE_CHART:
                childElementType = PieChart;
                doubleClickToSelect = true;
                break;
            case InfographicElementTypes.TABLE:
                childElementType = TableFrame;
                doubleClickToSelect = true;
                break;
            case InfographicElementTypes.RADIAL_BAR:
                childElementType = RadialBarInfographic;
                doubleClickToSelect = true;
                break;
            case InfographicElementTypes.STATISTIC:
                childElementType = Statistic;
                doubleClickToSelect = false;
                break;
            case InfographicElementTypes.TEXT_AND_IMAGE:
                childElementType = TextAndImage;
                childElementOptions = {
                    hideControlBarWhenTextSelected: this.options.hideControlBarWhenTextSelected,
                };
                doubleClickToSelect = false;
                break;
            case InfographicElementTypes.RADIAL_BAR_CHART:
                childElementType = RadialBarChart;
                doubleClickToSelect = true;
                break;
            case InfographicElementTypes.CANVAS:
                childElementType = AuthoringCanvas;
                doubleClickToSelect = true;
                break;
            default:
                // Force non-empty state of the componentType prop
                this.model.componentType = "GridPlaceholder";

                childElementType = LayoutPlaceholder;
                childElementOptions = {
                    elementDefaultOverlay: this.options.placeholderDefaultOverlay,
                    elementPropertyPanel: this.options.placeholderPropertyPanel,
                    canPasteImage: this.options.canPasteImage,
                    canEdit: this.options.canEdit
                };

                doubleClickToSelect = false;
                break;
        }

        this.childElement = this.addElement("element", () => childElementType, {
            requireParentSelection: false,
            passThroughSelection: false,
            canSelect: false,
            doubleClickToSelect,
            model: this.model.childElement,
            parentCellColor: this.cellColor,
            allowText: this.allowText,
            ...childElementOptions
        });
    }

    calcTitleSize(size) {
        if (this.showTitle) {
            switch (this.model.componentType) {
                case InfographicElementTypes.CHART:
                    if (this.showTitle === "below") {
                        this.chartTitle.styles.marginTop = 35;
                    } else {
                        this.chartTitle.styles.marginBottom = 15;
                    }
                    break;
                case InfographicElementTypes.PIE_CHART:
                    if (this.showTitle === "below") {
                        this.chartTitle.styles.marginTop = 20;
                    } else {
                        this.chartTitle.styles.marginBottom = 20;
                    }
                    break;
            }
            return this.chartTitle.calcProps(size).size;
        } else {
            return new geom.Size(0, 0);
        }
    }

    _loadStyles(styles) {
        let cellColor = this.cellColor;
        if (cellColor === "use-image-background") {
            cellColor = "white";
        }

        styles.decoration = {
            type: "frame",
            shape: "rect",
            strokeWidth: 1,
            cornerRadius: this.parentElement.model.cornerRadius
        };

        if (this.parentElement.model.borderShadow) {
            styles.decoration.shadow = {
                blur: 10,
                offsetX: 0,
                offsetY: 0,
                opacity: 0.2
            };
        }
    }

    _calcProps(props, options) {
        let { size } = props;

        let availableBounds = new geom.Rect(0, 0, size);
        if (this.model.componentType && this.model.componentType != InfographicElementTypes.TEXT_AND_IMAGE && this.parentElement.frameStyle && this.parentElement.frameStyle != "none") {
            // add padding when infographics are in a frame
            availableBounds = availableBounds.deflate(20);
        }

        let titleProps;
        if (this.showTitle) {
            titleProps = this.chartTitle.calcProps(new geom.Size(availableBounds.width, options.titleHeight ?? size.height / 2));
            if (this.showTitle === "below") {
                titleProps.bounds = new geom.Rect(availableBounds.left, availableBounds.bottom - titleProps.size.height, titleProps.size);
                availableBounds = availableBounds.deflate({ bottom: titleProps.size.height });
            } else {
                titleProps.bounds = new geom.Rect(availableBounds.left, availableBounds.top, titleProps.size);
                availableBounds = availableBounds.deflate({ top: titleProps.size.height });
            }
        }

        let padding = { ...this.styles.childPadding[this.model.componentType] };
        if (this.isOnAuthoringCanvas && this.model.useV10Padding) {
            // hack to remove padding of Infographic elements on classic slides migrated from v10
            padding = 0;
        }

        if (padding) {
            if (this.showTitle === "above") {
                padding.paddingTop = 0;
            } else if (this.showTitle === "below") {
                padding.paddingBottom = 0;
            }
            availableBounds = availableBounds.deflate({ left: padding.paddingLeft, right: padding.paddingRight, top: padding.paddingTop, bottom: padding.paddingBottom });
        }

        let isChildFit = availableBounds.width >= this.childElement.minWidth && availableBounds.height >= this.childElement.minHeight;

        let childElementProps = this.childElement.calcProps(availableBounds.size, {
            childPadding: this.styles.childPadding[this.model.componentType],
            titleHeight: this.showTitle ? titleProps.size.height : 0,
            titleProps
        });
        childElementProps.bounds = availableBounds;
        childElementProps.isFit = true; // force the childElement to fit even if it didn't so we don't trigger a layout not fit error

        if (!isChildFit) {
            this.childElement.options.doubleClickToSelect = false;
        }

        return { size, isChildFit };
    }

    renderChildren(transition) {
        if (this.calculatedProps.isChildFit) {
            return super.renderChildren(transition);
        } else {
            return null;
        }
    }

    _applyColors() {
        if (this.decoration) {
            let cellColor = this.model.cellColor ?? this.parentElement.model.cellColor;

            this.decoration.colorSet.fillColor = this.palette.getColor(cellColor, this.getBackgroundColor(), { itemIndex: this.itemIndex, allowColorOnColor: true }) ?? this.palette.getColor("transparent");
            this.decoration.colorSet.strokeColor = this.palette.getColor(this.parentElement.model.borderColor);
            this.decoration.colorSet.backgroundColor = this.decoration.colorSet.fillColor;
        }
    }

    get animateChildren() {
        return true;
    }

    get combineAnimationsWithChildren() {
        return true;
    }

    _useUpdatedDataSource(dataSourceEntry) {
        let chartData = dataSourceEntry.validatedData, initialImport = dataSourceEntry.initialImport;

        if (this.hasDataSourceLink() && dataSourceEntry.id) {
            const spreadsheetData = dataSourceEntry.spreadsheetData;

            const { selectedSheetIndex, selectedCellRange, isDataTransposed, useFirstRowAsCategory, useFirstColAsLegend } = this.model.dataSourceLink;
            const selectedData = getSelectedSpreadsheetData({ sheets: spreadsheetData }, selectedSheetIndex, selectedCellRange);

            if (isDataTransposed) selectedData.csvData = _.unzip(selectedData.csvData);

            const isPieChart = this.childElement.getChartType?.() === "pie";
            const isWaterfall = this.childElement.getChartType?.() === "waterfall";
            chartData = csvDataToChartData(selectedData.csvData, useFirstRowAsCategory, useFirstColAsLegend, isPieChart, isWaterfall);
        }

        this.childElement.updateChartData(chartData, initialImport);
        this.canvas.refreshCanvas();
    }

    _migrate_10_02() {
        if (this.isOnAuthoringCanvas) {
            this.model.useV10Padding = true;
        }
        if (!this.model.componentType && (this.model.text || this.model.content_value)) {
            this.model.componentType = "TextAndImage";

            let verticalAlign = VerticalAlignType.MIDDLE;
            let boxHAlign = HorizontalAlignType.CENTER;
            switch (this.model.textPosition) {
                case "left":
                    verticalAlign = VerticalAlignType.MIDDLE;
                    boxHAlign = HorizontalAlignType.LEFT;
                    break;
                case "top_left":
                    verticalAlign = VerticalAlignType.TOP;
                    boxHAlign = HorizontalAlignType.LEFT;
                    break;
                case "bottom_left":
                    verticalAlign = VerticalAlignType.BOTTOM;
                    boxHAlign = HorizontalAlignType.LEFT;
                    break;
                case "top":
                    verticalAlign = VerticalAlignType.TOP;
                    boxHAlign = HorizontalAlignType.CENTER;
                    break;
                case "center":
                    verticalAlign = VerticalAlignType.MIDDLE;
                    boxHAlign = HorizontalAlignType.CENTER;
                    break;
                case "bottom":
                    verticalAlign = VerticalAlignType.BOTTOM;
                    boxHAlign = HorizontalAlignType.CENTER;
                    break;
                case "top_right":
                    verticalAlign = VerticalAlignType.TOP;
                    boxHAlign = HorizontalAlignType.RIGHT;
                    break;
                case "right":
                    verticalAlign = VerticalAlignType.MIDDLE;
                    boxHAlign = HorizontalAlignType.RIGHT;
                    break;
                case "bottom_right":
                    verticalAlign = VerticalAlignType.BOTTOM;
                    boxHAlign = HorizontalAlignType.RIGHT;
                    break;
            }

            this.model.childElement = {
                boxHAlign,
                textAlign: this.model.textAlign,
                verticalAlign
            };

            if (this.model.text) {
                this.model.childElement.text = this.model.text;
                delete this.model.text;
            }

            if (this.model.content_value) {
                Object.assign(this.model.childElement, _.omit(this.model, "text", "boxHAlign", "verticalAlign", "componentType", "childElement"));
                delete this.model.content_value;
            }
        }
    }
}

class LayoutContainerTitleCaption extends TextElement {
    _getAnimations() {
        return [];
    }
}

export class LayoutPlaceholder extends BaseElement {
    getElementPropertyPanel() {
        return this.options.elementPropertyPanel ?? GridPlaceholderPropertyPanel;
    }

    getElementDefaultOverlay() {
        return this.options.elementDefaultOverlay ?? GridPlaceholderDefaultOverlay;
    }

    get name() {
        return "Empty Cell";
    }

    get _canSelect() {
        return true;
    }

    get canPasteImage() {
        return this.options.canPasteImage ?? false;
    }

    get canEdit() {
        return this.options.canEdit ?? false;
    }

    get _showDefaultOverlay() {
        return true;
    }

    get allowBackgroundColor() {
        return true;
    }

    get minWidth() {
        return 0;
    }

    get minHeight() {
        return 0;
    }

    _getCustomBlocksDropTargets(sourceElement, sourceBlocks) {
        const canvasBounds = this.canvasBounds;
        return [{
            canvasBounds: canvasBounds.deflate({ top: canvasBounds.height / 2 - 18, bottom: canvasBounds.height / 2 - 18, left: 40, right: 40 }),
            labelText: "Add Text",
            onDrop: blocks => {
                let textAlign = HorizontalAlignType.CENTER;
                let verticalAlign = VerticalAlignType.MIDDLE;

                const layoautContainer = sourceElement.findClosestOfType("LayoutContainerItem");
                if (layoautContainer) {
                    textAlign = layoautContainer.childElement?.model.textAlign ?? textAlign;
                    verticalAlign = layoautContainer.childElement?.model.verticalAlign ?? verticalAlign;
                }

                return addTextToLayoutContainerItem({
                    element: this.parentElement,
                    blocks: _.cloneDeep(blocks.map(({ model }) => ({ ...model, id: uuid() }))),
                    textAlign,
                    verticalAlign
                });
            }
        }];
    }
}

export const elements = {
    LayoutContainerItem
};

import React, { Component } from "react";

import { defaultDragResizeProps, DragHandleStyle } from "../../../../editor/PresentationEditor/DragElementManager";
import { ResizeDirection } from "../../../../../common/constants";

import { CollectionItemElementSelection } from "../ElementSelections/CollectionItemElementSelection";
import { ControlBar } from "../ElementControlBars/Components/ControlBar";
import { EmphasizeToggle } from "../EditorComponents/EmphasizeToggle";
import { ItemColorPicker } from "../EditorComponents/ColorPickers/ItemColorPicker";
import { PropertyPanelContainer, PropertySection } from "../../../../EditorComponents/PropertyPanel";
import { CollectionColorPicker } from "../EditorComponents/ColorPickers/CollectionColorPicker";
import { WithLabel } from "../../../../Components/WithLabel";
import { Slider } from "../../../../Components/Slider";
import { ToggleSwitch } from "../../../../Components/ToggleSwitch";

export class SliceChartPropertyPanel extends Component {
    render() {
        const { element } = this.props;

        return (
            <PropertyPanelContainer>
                <PropertySection>
                    <WithLabel label="Colors">
                        <CollectionColorPicker element={element} />
                    </WithLabel>
                </PropertySection>
                <PropertySection>
                    <WithLabel label="Show Description">
                        <ToggleSwitch value={element.model.showSecondaryText}
                            onChange={showSecondaryText => element.updateModel({ showSecondaryText })}
                        />
                    </WithLabel>
                </PropertySection>
                <PropertySection>
                    <WithLabel label="Slice Height" left>
                        <Slider value={element.model.sliceHeight ?? 0.5}
                            min={.1} max={1} step={0.011}
                            showInput percent
                            onChange={sliceHeight => element.updateModel({ sliceHeight })}
                        />
                    </WithLabel>
                </PropertySection>
            </PropertyPanelContainer>
        );
    }
}

export class SliceChartItemSelection extends CollectionItemElementSelection {
    get canResize() {
        return true;
    }

    get dragResizeProps() {
        const { element } = this.props;

        const initialWidths = [];
        let totalSliceWidth = null;
        let availableWidth = null;
        return {
            ...defaultDragResizeProps,
            resizeDirections: [element.isFirstItem ? null : ResizeDirection.LEFT, element.isLastItem ? null : ResizeDirection.RIGHT].filter(Boolean),
            handleProps: {
                style: DragHandleStyle.DIVIDER
            },
            onDragStart: async () => {
                element.parentElement.itemElements.forEach(element => {
                    initialWidths.push(element.model.width);
                });
                totalSliceWidth = element.parentElement.totalSliceWidth;
                availableWidth = element.parentElement.availableWidth;
            },
            onDrag: async ({ dragOffset, resizeDirection }) => {
                const elementIndex = element.itemIndex;

                const minItemWidth = element.styles.minWidth / availableWidth * totalSliceWidth;

                let offset = dragOffset.x / availableWidth * totalSliceWidth;

                if (resizeDirection === ResizeDirection.LEFT) {
                    const maxOffset = Math.max(initialWidths[elementIndex] - minItemWidth, 0);
                    const minOffset = -Math.max(initialWidths[elementIndex - 1] - minItemWidth, 0);
                    offset = Math.clamp(offset, minOffset, maxOffset);

                    const newWidth = initialWidths[elementIndex] - offset;
                    const newNeighborWidth = initialWidths[elementIndex - 1] + offset;

                    element.model.width = newWidth;
                    element.parentElement.itemElements[elementIndex - 1].model.width = newNeighborWidth;
                } else {
                    const maxOffset = Math.max(initialWidths[elementIndex + 1] - minItemWidth, 0);
                    const minOffset = -Math.max(initialWidths[elementIndex] - minItemWidth, 0);
                    offset = Math.clamp(offset, minOffset, maxOffset);

                    const newWidth = initialWidths[elementIndex] + offset;
                    const newNeighborWidth = initialWidths[elementIndex + 1] - offset;

                    element.model.width = newWidth;
                    element.parentElement.itemElements[elementIndex + 1].model.width = newNeighborWidth;
                }

                element.parentElement.refreshElement();
            }
        };
    }

    get containerRenderBounds() {
        const { element, canvas, containerBounds } = this.props;
        return containerBounds.zeroOffset().deflate({ bottom: containerBounds.height - element.shapeContainer.calculatedProps.size.height * canvas.getScale() });
    }
}

export class SliceChartItemControlBar extends Component {
    render() {
        const { element } = this.props;

        return (
            <ControlBar>
                <EmphasizeToggle element={element} showLabel />
                <ItemColorPicker element={element} />
            </ControlBar>
        );
    }
}

import { PaletteColorType, ShapeType } from "common/constants";
import { FramedMediaElement } from "../../base/MediaElements/FramedMediaElement";
import * as geom from "js/core/utilities/geom";

export class AuthoringContentElement extends FramedMediaElement {
    static get schema() {
        return {
            frameType: ShapeType.NONE
        };
    }

    get isAuthoringElement() {
        return true;
    }

    get preserveAspectRatioForUnframed() {
        return false; // when frame is none, we want the FramedMediaElement to clip the content - not preserve its aspect ratio
    }

    // Prevent use override of aspect ratio when resizing using mod key.
    // Only makes sense if preserveAspectRatio = true.
    get lockAspectRatio() {
        if (this.content?.elements?.asset?.lockAspectRatio) {
            return true;
        }

        if (this.frameType.equalsAnyOf(ShapeType.NONE, ShapeType.RECT, ShapeType.ROUNDED_RECT)) {
            return false;
        } else {
            return true;
        }
    }

    get preserveAspectRatioOnCornerResize() {
        return true;
    }

    // Prevent users from selecting intermediate frame element
    get _canSelect() {
        return false;
    }

    // AuthoringCanvas uses this to resize bounds in _calcProps
    get fitToContents() {
        return {
            width: true,
            height: true
        };
    }

    // AuthoringCanvas uses this to position the element after resizing the bounds
    get fitToContentsAnchor() {
        return {
            width: geom.AnchorType.CENTER,
            height: geom.AnchorType.CENTER
        };
    }

    getElementSelection() {
        return null;
    }

    _calcProps(props, options) {
        if (this.hasStoredPropChanged("frameType", this.model.frameType)) {
            // allows frame size to be calculated base on unmodified original size
            props.size = this.previousSize.clone();
        } else if (this.canvas.layouter.isGenerating) {
            // store current size when any other props are changed
            // side effect - changing props on a framed image will reset the original size
            this.previousSize = props.size.clone();
        }
        let autoHeight = true;
        if (this.model.frameType === ShapeType.RECT ||
            this.model.frameType === ShapeType.ROUNDED_RECT) {
            autoHeight = false;
        }
        // signal FramedMediaElement to resize the selection to match the element size
        return super._calcProps(props,
            { ...options,
                autoWidth: true,
                autoHeight: autoHeight });
    }

    addFrame(frameType) {
        this.updateModel({ frameType,
            frameScale: 0.9,
            frameColor: PaletteColorType.BACKGROUND_DARK },
        { refreshStyles: true });
    }

    changeFrame(frameType) {
        this.updateModel({ frameType },
            { refreshStyles: true });
    }

    deleteFrame() {
        this.updateModel({ frameType: "none" },
            { refreshStyles: true });
    }
}

import { BaseElement } from "../base/BaseElement";

/**
 * Fake element class representing a group of authoring elements
 */
export class AuthoringElementsGroup extends BaseElement {
    get groupId() {
        return this.options.groupId;
    }
}

import React, { Component } from "react";

import { ControlBarPosition } from "../../../../../common/constants";
import { Button } from "../../../../Components/Button";
import { Icon } from "../../../../Components/Icon";
import { IconPickerPopup } from "../../../../Components/IconPicker";
import { ImageOption, ImageOptionList } from "../../../../Components/ImageOptionList";
import { StaticImage } from "../../../../Components/StaticImage";
import { ToggleSwitch } from "../../../../Components/ToggleSwitch";
import { WithLabel } from "../../../../Components/WithLabel";
import { PropertyPanelContainer, PropertySection } from "../../../../EditorComponents/PropertyPanel";
import { ShowErrorDialog } from "../../../../react/components/Dialogs/BaseDialog";
import { CollectionColorPicker } from "../EditorComponents/ColorPickers/CollectionColorPicker";
import { ItemColorPicker } from "../EditorComponents/ColorPickers/ItemColorPicker";
import { CollectionElementControlBar } from "../ElementControlBars/CollectionElementControlBar";
import { ControlBar } from "../ElementControlBars/Components/ControlBar";
import { CollectionItemElementSelection } from "../ElementSelections/CollectionItemElementSelection";
import { ColorPicker } from "../EditorComponents/ColorPickers/ColorPicker";

export class VerticalTaskListPropertyPanel extends Component {
    render() {
        const { element } = this.props;

        return (
            <PropertyPanelContainer>
                <PropertySection>
                    <WithLabel label="Task Colors">
                        <ColorPicker canvas={element.canvas}
                            value={element.getChildValues("collectionColor")}
                            backgroundColor={element.itemElements[0].colorSet.backgroundColor}
                            showDecorationStyles showColorful showBackgroundColors
                            onChange={color => {
                                if (element.itemElements) {
                                    for (let column of element.itemElements) {
                                        column.model.collectionColor = color;
                                        for (let item of column.taskList.itemElements) {
                                            item.model.color = null;
                                        }
                                    }
                                }
                                element.updateModel({ collectionColor: color });
                            }}
                            onChangeDecorationStyle={decorationStyle => {
                                if (element.itemElements) {
                                    for (let column of element.itemElements) {
                                        column.model.decorationStyle = decorationStyle;
                                        for (let item of column.taskList.itemElements) {
                                            item.model.decorationStyle = null;
                                        }
                                    }
                                }
                                element.updateModel({ decorationStyle });
                            }}
                        />
                    </WithLabel>
                </PropertySection>
                <PropertySection>
                    <WithLabel label="Show Titles">
                        <ToggleSwitch value={element.model.showTitles}
                            onChange={value => element.updateModel({ showTitles: value }, { transition: true })}
                        />
                    </WithLabel>
                </PropertySection>
                <PropertySection>
                    <WithLabel label="Style" above left>
                        <ImageOptionList cols={3}
                            value={element.model.style}
                            onChange={value => element.updateModel({ style: value })}
                        >
                            <ImageOption value="no-frame" label="None">
                                <StaticImage src="/images/ui/vertical-tasks-style-none.svg" />
                            </ImageOption>
                            <ImageOption value="divider" label="Divider">
                                <StaticImage src="/images/ui/vertical-tasks-style-divider.svg" />
                            </ImageOption>
                            <ImageOption value="box" label="Box">
                                <StaticImage src="/images/ui/vertical-tasks-style-box.svg" />
                            </ImageOption>
                        </ImageOptionList>
                    </WithLabel>
                </PropertySection>
                <PropertySection>
                    <WithLabel label="Layout" above left>
                        <ImageOptionList cols={3}
                            value={element.model.taskLayout}
                            onChange={value => element.updateModel({ taskLayout: value }, { transition: true })}
                        >
                            <ImageOption value="stack" label="Stack">
                                <StaticImage src="/images/ui/vertical-tasks-layout-stack.svg" />
                            </ImageOption>
                            <ImageOption value="fill" label="Fill">
                                <StaticImage src="/images/ui/vertical-tasks-layout-fill.svg" />
                            </ImageOption>
                            <ImageOption value="fit" label="Fit">
                                <StaticImage src="/images/ui/vertical-tasks-layout-fit.svg" />
                            </ImageOption>
                        </ImageOptionList>
                    </WithLabel>
                </PropertySection>
            </PropertyPanelContainer>
        );
    }
}

export class VerticalTaskListControlBar extends CollectionElementControlBar {
    get addItemLabel() {
        return "Add Column";
    }
}

export class VerticalTaskListColumnSelection extends CollectionItemElementSelection {
    get canResize() {
        return false;
    }
}

export class VerticalTaskListColumnControlBar extends Component {
    handleAddNewItem = () => {
        const { element } = this.props;

        element.taskList.addItem();
        element.canvas.updateCanvasModel().catch(() => {
            ShowErrorDialog({ error: "Sorry, we can't fit any more items in this column", message: "If you need to add more items, you can either reduce the text size of the existing items or remove any headers or trays" });
        });
    }

    render() {
        const { element } = this.props;
        let canAddNewItem = true;
        return (
            <ControlBar position={ControlBarPosition.DOCKED}>
                <CollectionColorPicker size={20} element={element.taskList} showNone showDecorationStyles backgroundColor={element.getBackgroundColor()} />
                <Button blue onClick={this.handleAddNewItem} disabled={!canAddNewItem}>
                    <Icon>playlist_add</Icon>
                    Add to List
                </Button>
            </ControlBar>
        );
    }
}

let defaultIcons = ["check-yes", "x", "thumbs-up", "thumbs-down", "star", "star-half", "star-none", "question", "warning", "full", "quarters-three", "contrast", "quarter", "number-one", "number-two", "number-three", "number-four", "number-five", "number-six", "number-seven", "number-eight", "number-nine"];

export class VerticalTaskItemControlBar extends Component {
    render() {
        const { element } = this.props;

        return (
            <ControlBar position={ControlBarPosition.DOCKED}>
                <ItemColorPicker element={element} showNone showDecorationStyles />
                <IconPickerPopup value={element.model.icon} icons={defaultIcons} showNone canChooseIcon onChange={icon => element.updateModel({ icon })} />
            </ControlBar>
        );
    }
}

export class VerticalTaskItemSelection extends CollectionItemElementSelection {
}

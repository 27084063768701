import styled from "styled-components";

import { themeColors } from "../../../../../react/sharedStyles";

export const DefaultOverlayContainer = styled.div`
    width: 100%;
    height: 100%;
    background: ${({ background = true }) => background ? "rgba(17, 169, 226, 0.33)" : "none"};
    border:  ${({ outline = true }) => outline ? `1px solid rgba(17, 169, 226, 0.66)` : "none"};;
    display: flex;
    justify-content: center;
    align-items: center;
    //padding: 20px;
    gap: 10px;
`;

import * as geom from "js/core/utilities/geom";
import { themeColors } from "../../../../../../react/sharedStyles";
import React from "react";
import { ImageOption } from "../../../../../../Components/ImageOptionList";

export class FixedGridLayout {
    getName() {
        return "Fit Images To Grid";
    }

    getContainerSelection() {
        return null;
    }

    getCellSelection() {
        return null;
    }

    calcCellProps(container, props) {
        let { size, gridLayout, outerGutter = 0, innerGutter = 0 } = props;

        let cells = container.itemElements;

        if (cells.length == 0) {
            return null;
        }

        if (!gridLayout && container.type == "TrayPhotoCollage") {
            gridLayout = 1; // this defaults the grid to top/bottom layout when adding the first additional cell to a tray
        }

        let grid = FixedGridLayouts[cells.length - 1];
        let layout = grid[Math.min(grid.length - 1, gridLayout || 0)];

        // overlap a couple of pixels when there is no gutter due to anti-aliasing artifact
        let hOverlap = (innerGutter == 0) ? 0 : (layout.cols === 3 ? 2 : 1);
        let vOverlap = (innerGutter == 0) ? 0 : (layout.rows === 3 ? 2 : 1);

        let colWidth = (size.width - (layout.cols - 1) * innerGutter - outerGutter * 2) / layout.cols;
        let rowHeight = (size.height - (layout.rows - 1) * innerGutter - outerGutter * 2) / layout.rows;

        for (let i = 0; i < cells.length; i++) {
            let cell = layout.cells[i];

            if (cell) {
                let left = 0 + (outerGutter) + cell[0] * (colWidth + innerGutter),
                    top = 0 + (outerGutter) + cell[1] * (rowHeight + innerGutter),
                    width = cell[2] * (colWidth + innerGutter + hOverlap) - innerGutter,
                    height = cell[3] * (rowHeight + innerGutter + vOverlap) - innerGutter;

                let cellProps = cells[i].calcProps(new geom.Size(width, height));
                cellProps.bounds = new geom.Rect(left, top, width, height);
            }
        }
        return { size };
    }

    static renderThumbnails(items) {
        let gridThumbnails = [];
        let gridLayout = FixedGridLayouts[items.length - 1];
        gridLayout = gridLayout.sort((l, r) => {
            return (r.sortOrder || 0) - (l.sortOrder || 0);
        });
        for (let i = 0; i < gridLayout.length; i++) {
            let layout = gridLayout[i];
            let width = 84;
            let height = 50;
            let gap = 4;

            // gridLayout
            let colWidth = (width - (layout.cols - 1) * gap - gap * 2) / layout.cols;
            let rowHeight = (height - (layout.rows - 1) * gap - gap * 2) / layout.rows;

            gridThumbnails.push(
                <ImageOption key={i} value={i}>
                    <svg width="100%" height="100%" viewBox="0 0 84 50">
                        {layout.cells.map((cell, index) => {
                            return (
                                <rect key={index} x={gap + cell[0] * (colWidth + gap)}
                                    y={gap + cell[1] * (rowHeight + gap)} width={cell[2] * (colWidth + gap) - gap}
                                    height={cell[3] * (rowHeight + gap) - gap} fill={themeColors.ui_blue} />
                            );
                        })}
                    </svg>
                </ImageOption>
            );
        }
        return gridThumbnails;
    }
}

export const FixedGridLayouts = [[
// one cell
    {
        rows: 1,
        cols: 1,
        cells: [[0, 0, 1, 1]],
    }
], [
//two cells
    {
        cols: 2,
        rows: 1,
        cells: [[0, 0, 1, 1], [1, 0, 1, 1]],
    }, {
        cols: 1,
        rows: 2,
        cells: [[0, 0, 1, 1], [0, 1, 1, 1]],
    }, {
        cols: 3,
        rows: 1,
        cells: [[0, 0, 2, 1], [2, 0, 1, 1]],
    }, {
        cols: 3,
        rows: 1,
        cells: [[0, 0, 1, 1], [1, 0, 2, 1]],
    }
], [
//three cells
    {
        cols: 2,
        rows: 2,
        cells: [[0, 0, 1, 2], [1, 0, 1, 1], [1, 1, 1, 1]]
    }, {
        cols: 3,
        rows: 1,
        cells: [[0, 0, 1, 1], [1, 0, 1, 1], [2, 0, 1, 1]]
    }, {
        cols: 2,
        rows: 2,
        cells: [[0, 0, 1, 1], [0, 1, 1, 1], [1, 0, 1, 2]]
    }, {
        cols: 2,
        rows: 2,
        cells: [[0, 0, 2, 1], [0, 1, 1, 1], [1, 1, 1, 1]]
    }, {
        cols: 2,
        rows: 2,
        cells: [[0, 0, 1, 1], [1, 0, 1, 1], [0, 1, 2, 1]]
    }, {
        cols: 1,
        rows: 3,
        cells: [[0, 0, 1, 1], [0, 1, 1, 1], [0, 2, 1, 1]]
    }
], [
//four cells
    {
        cols: 2,
        rows: 2,
        cells: [[0, 0, 1, 1], [1, 0, 1, 1], [0, 1, 1, 1], [1, 1, 1, 1]]
    }, {
        cols: 2,
        rows: 3,
        cells: [[0, 0, 1, 3], [1, 0, 1, 1], [1, 1, 1, 1], [1, 2, 1, 1]]
    }, {
        cols: 3,
        rows: 2,
        cells: [[0, 0, 2, 1], [2, 0, 1, 1], [0, 1, 1, 1], [1, 1, 2, 1]]
    }, {
        cols: 3,
        rows: 2,
        cells: [[0, 0, 1, 1], [1, 0, 2, 1], [0, 1, 2, 1], [2, 1, 1, 1]]
    }, {
        cols: 3,
        rows: 2,
        cells: [[0, 0, 2, 1], [2, 0, 1, 1], [0, 1, 2, 1], [2, 1, 1, 1]]
    }, {
        cols: 3,
        rows: 2,
        cells: [[0, 0, 1, 1], [1, 0, 2, 1], [0, 1, 1, 1], [1, 1, 2, 1]]
    }, {
        cols: 3,
        rows: 2,
        cells: [[0, 0, 3, 1], [0, 1, 1, 1], [1, 1, 1, 1], [2, 1, 1, 1]]
    }, {
        cols: 3,
        rows: 2,
        cells: [[0, 0, 1, 1], [1, 0, 1, 1], [2, 0, 1, 1], [0, 1, 3, 1]]
    }, {
        cols: 4,
        rows: 1,
        cells: [[0, 0, 1, 1], [1, 0, 1, 1], [2, 0, 1, 1], [3, 0, 1, 1]]
    }, {
        cols: 1,
        rows: 4,
        cells: [[0, 0, 1, 1], [0, 1, 1, 1], [0, 2, 1, 1], [0, 3, 1, 1]]
    }
], [
//five cells
    {
        cols: 3,
        rows: 2,
        cells: [[0, 0, 2, 1], [2, 0, 1, 1], [0, 1, 1, 1], [1, 1, 1, 1], [2, 1, 1, 1]]
    },
    {
        cols: 3,
        rows: 2,
        cells: [[0, 0, 1, 1], [1, 0, 1, 1], [2, 0, 1, 1], [0, 1, 1, 1], [1, 1, 2, 1]]
    },
    {
        cols: 3,
        rows: 2,
        cells: [[0, 0, 1, 2], [1, 0, 1, 1], [2, 0, 1, 1], [1, 1, 1, 1], [2, 1, 1, 1]]
    },
    {
        cols: 3,
        rows: 2,
        cells: [[0, 0, 1, 1], [0, 1, 1, 1], [1, 0, 1, 2], [2, 0, 1, 1], [2, 1, 1, 1]]
    },
    {
        cols: 3,
        rows: 2,
        cells: [[0, 0, 1, 1], [0, 1, 1, 1], [1, 0, 1, 1], [1, 1, 1, 1], [2, 0, 1, 2]]
    },
    {
        cols: 4,
        rows: 2,
        cells: [[0, 0, 4, 1], [0, 1, 1, 1], [1, 1, 1, 1], [2, 1, 1, 1], [3, 1, 1, 1]]
    },
    {
        cols: 4,
        rows: 2,
        cells: [[0, 0, 1, 1], [1, 0, 1, 1], [2, 0, 1, 1], [3, 0, 1, 1], [0, 1, 4, 1]]
    },
    {
        cols: 4,
        rows: 2,
        cells: [[0, 0, 2, 2], [2, 0, 1, 1], [3, 0, 1, 1], [2, 1, 1, 1], [3, 1, 1, 1]]
    },
    {
        cols: 4,
        rows: 2,
        cells: [[0, 0, 1, 1], [1, 0, 1, 1], [0, 1, 1, 1], [1, 1, 1, 1], [2, 0, 2, 2]]
    },
    {
        cols: 4,
        rows: 2,
        cells: [[0, 0, 1, 1], [0, 1, 1, 1], [1, 0, 2, 2], [3, 0, 1, 1], [3, 1, 1, 1]]
    },
    {
        cols: 6,
        rows: 2,
        cells: [[0, 0, 2, 1], [2, 0, 2, 1], [4, 0, 2, 1], [0, 1, 3, 1], [3, 1, 3, 1]]
    },
    {
        cols: 6,
        rows: 2,
        cells: [[0, 0, 3, 1], [3, 0, 3, 1], [0, 1, 2, 1], [2, 1, 2, 1], [4, 1, 2, 1]]
    }
], [
//six cells
    {
        cols: 3,
        rows: 2,
        cells: [[0, 0, 1, 1], [1, 0, 1, 1], [2, 0, 1, 1], [0, 1, 1, 1], [1, 1, 1, 1], [2, 1, 1, 1]]
    }, {
        cols: 5,
        rows: 3,
        cells: [[0, 0, 5, 2], [0, 2, 1, 1], [1, 2, 1, 1], [2, 2, 1, 1], [3, 2, 1, 1], [4, 2, 1, 1]]
    }, {
        cols: 5,
        rows: 3,
        cells: [[0, 0, 1, 1], [1, 0, 1, 1], [2, 0, 1, 1], [3, 0, 1, 1], [4, 0, 1, 1], [0, 1, 5, 2]]
    }, {
        cols: 5,
        rows: 3,
        cells: [[0, 0, 3, 2], [3, 0, 2, 1], [0, 2, 1, 1], [1, 2, 1, 1], [2, 2, 1, 1], [3, 1, 2, 2]]
    }
], [
//seven cells
    {
        cols: 4,
        rows: 2,
        cells: [[0, 0, 1, 2], [1, 0, 1, 1], [2, 0, 1, 1], [1, 1, 1, 1], [2, 1, 1, 1], [3, 0, 1, 1], [3, 1, 1, 1]]
    }, {
        cols: 4,
        rows: 2,
        cells: [[0, 0, 1, 1], [1, 0, 1, 1], [2, 0, 1, 1], [0, 1, 1, 1], [1, 1, 1, 1], [2, 1, 1, 1], [3, 0, 1, 2]]
    }, {
        cols: 4,
        rows: 2,
        cells: [[0, 0, 2, 1], [2, 0, 1, 1], [3, 0, 1, 1], [0, 1, 1, 1], [1, 1, 1, 1], [2, 1, 1, 1], [3, 1, 1, 1]]
    }, {
        cols: 4,
        rows: 2,
        cells: [[0, 0, 1, 1], [1, 0, 1, 1], [2, 0, 1, 1], [3, 0, 1, 1], [0, 1, 1, 1], [1, 1, 1, 1], [2, 1, 2, 1]]
    }, {
        cols: 4,
        rows: 2,
        cells: [[0, 0, 1, 1], [1, 0, 2, 1], [3, 0, 1, 1], [0, 1, 1, 1], [1, 1, 1, 1], [2, 1, 1, 1], [3, 1, 1, 1]]
    }, {
        cols: 4,
        rows: 2,
        cells: [[0, 0, 1, 1], [1, 0, 1, 1], [2, 0, 1, 1], [3, 0, 1, 1], [0, 1, 1, 1], [1, 1, 2, 1], [3, 1, 1, 1]]
    }
], [
// eight cells
    {
        cols: 4,
        rows: 2,
        cells: [[0, 0, 1, 1], [1, 0, 1, 1], [2, 0, 1, 1], [3, 0, 1, 1], [0, 1, 1, 1], [1, 1, 1, 1], [2, 1, 1, 1], [3, 1, 1, 1]]
    }
], [
// nine cells
    {
        cols: 3,
        rows: 3,
        cells: [[0, 0, 1, 1], [1, 0, 1, 1], [2, 0, 1, 1], [0, 1, 1, 1], [1, 1, 1, 1], [2, 1, 1, 1], [0, 2, 1, 1], [1, 2, 1, 1], [2, 2, 1, 1]]
    }, {
        cols: 4,
        rows: 3,
        cells: [[0, 0, 2, 2], [2, 0, 1, 1], [3, 0, 1, 1], [2, 1, 1, 1], [3, 1, 1, 1], [0, 2, 1, 1], [1, 2, 1, 1], [2, 2, 1, 1], [3, 2, 1, 1]]
    }, {
        cols: 4,
        rows: 3,
        cells: [[0, 0, 1, 1], [1, 0, 1, 1], [2, 0, 2, 2], [0, 1, 1, 1], [1, 1, 1, 1], [0, 2, 1, 1], [1, 2, 1, 1], [2, 2, 1, 1], [3, 2, 1, 1]]
    }, {
        cols: 4,
        rows: 3,
        cells: [[0, 0, 1, 1], [1, 0, 2, 2], [3, 0, 1, 1], [0, 1, 1, 1], [3, 1, 1, 1], [0, 2, 1, 1], [1, 2, 1, 1], [2, 2, 1, 1], [3, 2, 1, 1]]
    }
], [
// ten cells
    {
        cols: 5,
        rows: 2,
        cells: [[0, 0, 1, 1], [1, 0, 1, 1], [2, 0, 1, 1], [3, 0, 1, 1], [4, 0, 1, 1], [0, 1, 1, 1], [1, 1, 1, 1], [2, 1, 1, 1], [3, 1, 1, 1], [4, 1, 1, 1]]
    }, {
        cols: 4,
        rows: 3,
        cells: [[0, 0, 1, 1], [1, 0, 1, 1], [2, 0, 1, 1], [3, 0, 1, 1], [0, 1, 1, 1], [1, 1, 1, 1], [2, 1, 1, 1], [3, 1, 1, 1], [0, 2, 2, 1], [2, 2, 2, 1]]
    }, {
        cols: 4,
        rows: 3,
        cells: [[0, 0, 2, 1], [2, 0, 2, 1], [0, 1, 1, 1], [1, 1, 1, 1], [2, 1, 1, 1], [3, 1, 1, 1], [0, 2, 1, 1], [1, 2, 1, 1], [2, 2, 1, 1], [3, 2, 1, 1]]
    }
], [
// eleven cells
    {
        cols: 4,
        rows: 3,
        cells: [[0, 0, 1, 1], [1, 0, 1, 1], [2, 0, 1, 1], [3, 0, 1, 1], [0, 1, 1, 1], [1, 1, 1, 1], [2, 1, 1, 1], [3, 1, 1, 1], [0, 2, 2, 1], [2, 2, 1, 1], [3, 2, 1, 1]]
    }
], [
// twelve cells
    {
        cols: 4,
        rows: 3,
        cells: [[0, 0, 1, 1], [1, 0, 1, 1], [2, 0, 1, 1], [3, 0, 1, 1], [0, 1, 1, 1], [1, 1, 1, 1], [2, 1, 1, 1], [3, 1, 1, 1], [0, 2, 1, 1], [1, 2, 1, 1], [2, 2, 1, 1], [3, 2, 1, 1]]
    }
]];

import React, { Component } from "react";
import { _ } from "js/vendor";
import { CollectionElementControlBar } from "../ElementControlBars/CollectionElementControlBar";
import { PropertyPanelContainer, PropertySection } from "../../../../EditorComponents/PropertyPanel";
import { WithLabel } from "../../../../Components/WithLabel";
import { ToggleSwitch } from "../../../../Components/ToggleSwitch";
import { ImageOption, ImageOptionList } from "../../../../Components/ImageOptionList";
import { StaticImage } from "../../../../Components/StaticImage";
import { CollectionItemElementSelection } from "../ElementSelections/CollectionItemElementSelection";
import { IconButton } from "../../../../Components/IconButton";
import { AbsoluteBox } from "../../../../react/components/LayoutGrid";
import { ShowErrorDialog } from "../../../../react/components/Dialogs/BaseDialog";
import { CollectionColorPicker } from "../EditorComponents/ColorPickers/CollectionColorPicker";
import { ControlBar } from "../ElementControlBars/Components/ControlBar";
import { ControlBarPosition } from "../../../../../common/constants";
import { ItemColorPicker } from "../EditorComponents/ColorPickers/ItemColorPicker";
import { IconPickerPopup } from "../../../../Components/IconPicker";
import { Button } from "../../../../Components/Button";
import { Icon } from "../../../../Components/Icon";
import { ColorPicker } from "../EditorComponents/ColorPickers/ColorPicker";

export class HorizontalTaskListPropertyPanel extends Component {
    render() {
        const { element } = this.props;

        return (
            <PropertyPanelContainer>
                <PropertySection>
                    <WithLabel label="Task Colors">
                        <ColorPicker canvas={element.canvas}
                            value={element.getChildValues("collectionColor")}
                            showDecorationStyles showColorful showBackgroundColors
                            onChange={color => {
                                if (element.itemElements) {
                                    for (let row of element.itemElements) {
                                        row.model.collectionColor = color;
                                        for (let item of row.taskList.itemElements) {
                                            item.model.color = null;
                                        }
                                    }
                                }
                                element.updateModel({ collectionColor: color });
                            }}
                            onChangeDecorationStyle={decorationStyle => {
                                if (element.itemElements) {
                                    for (let row of element.itemElements) {
                                        row.model.decorationStyle = decorationStyle;
                                        for (let item of row.taskList.itemElements) {
                                            item.model.decorationStyle = null;
                                        }
                                    }
                                }
                                element.updateModel({ decorationStyle });
                            }}
                        />
                    </WithLabel>
                </PropertySection>
                <PropertySection>
                    <WithLabel label="Task Style" above left>
                        <ImageOptionList value={element.model.itemStyle} cols={3}
                            onChange={value => element.updateModel({ itemStyle: value })}
                        >
                            <ImageOption value="task" label="Task" url="/images/ui/horizontal-tasks-style-task.svg" />
                            <ImageOption value="process" label="Process" url="/images/ui/horizontal-tasks-style-process.svg" />
                        </ImageOptionList>
                    </WithLabel>
                </PropertySection>
                <PropertySection>
                    <WithLabel label="Show Titles">
                        <ToggleSwitch value={element.model.showTitles}
                            onChange={value => element.updateModel({ showTitles: value }, { transition: true })}
                        />
                    </WithLabel>
                </PropertySection>
                <PropertySection>
                    <WithLabel label="Layout" above left>
                        <ImageOptionList cols={3}
                            value={element.model.taskLayout}
                            onChange={value => element.updateModel({ taskLayout: value }, { transition: true })}
                        >
                            <ImageOption value="stack" label="Stack">
                                <StaticImage src="/images/ui/horizontal-tasks-layout-stack.svg" />
                            </ImageOption>
                            <ImageOption value="fill" label="Fill">
                                <StaticImage src="/images/ui/horizontal-tasks-layout-fill.svg" />
                            </ImageOption>
                            <ImageOption value="fit" label="Fit">
                                <StaticImage src="/images/ui/horizontal-tasks-layout-fit.svg" />
                            </ImageOption>
                        </ImageOptionList>
                    </WithLabel>
                </PropertySection>
            </PropertyPanelContainer>
        );
    }
}

export class HorizontalTaskListControlBar extends CollectionElementControlBar {
    get addItemLabel() {
        return "Add Row";
    }
}

export class HorizontalTaskListsRowControlBar extends Component {
    handleAddNewItem = () => {
        const { element } = this.props;
        element.taskList.addItem();
        element.canvas.updateCanvasModel().catch(() => {
            ShowErrorDialog({ error: "Sorry, we can't fit any more items in this row", message: "If you need to add more items, you can either reduce the text size of the existing items or remove any headers or trays" });
        });
    }

    get canAddNewItem() {
        const { element } = this.props;
        return element.taskList.addItem && (element.taskList.maxItemCount ? (element.taskList.itemCount < element.taskList.maxItemCount) : true);
    }

    render() {
        const { element } = this.props;

        return (
            <ControlBar position={ControlBarPosition.DOCKED}>
                <CollectionColorPicker size={20} element={element.taskList} showDecorationStyles />
                <Button blue onClick={this.handleAddNewItem} disabled={!this.canAddNewItem} disabledTooltip={`This slide only allows ${element.taskList.maxItemCount} items.`}>
                    <Icon>add_circle</Icon>
                    Add Task
                </Button>
            </ControlBar>
        );
    }
}

let defaultIcons = ["check-yes", "x", "thumbs-up", "thumbs-down", "star", "star-half", "star-none", "question", "warning", "full", "quarters-three", "contrast", "quarter", "number-one", "number-two", "number-three", "number-four", "number-five", "number-six", "number-seven", "number-eight", "number-nine"];

export class HorizontalTaskItemControlBar extends Component {
    render() {
        const { element } = this.props;

        return (
            <ControlBar position={ControlBarPosition.DOCKED}>
                <ItemColorPicker element={element} showNone showDecorationStyles />
                <IconPickerPopup value={element.model.icon} icons={defaultIcons} showNone canChooseIcon onChange={icon => element.updateModel({ icon })} />
            </ControlBar>
        );
    }
}

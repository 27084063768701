import React from "react";
import moment from "moment/moment";
import { _ } from "js/vendor";
import * as geom from "js/core/utilities/geom";
import { ds } from "js/core/models/dataService";
import { SVGGroup } from "js/core/utilities/svgHelpers";
import { detectTextContent } from "js/core/services/sharedModelManager";
import { BlockStructureType, AnimationsArrangementType, AuthoringBlockType, TextStyleType } from "common/constants";

import { BaseElement } from "../base/BaseElement";
import { TextElement } from "../base/Text/TextElement";
import { SVGElement } from "../base/SVGElement";
import { VerticalTaskLists } from "./VerticalTaskList";
import { CalendarCellControlBar, CalendarCellSelection, CalendarPropertyPanel, CalendarTaskTextSelection, WeekCalenderVerticalTaskSelection } from "../../Editor/ElementPropertyPanels/CalendarUI";

export class Calendar extends BaseElement {
    getElementPropertyPanel() {
        return CalendarPropertyPanel;
    }

    get _canSelect() {
        return false;
    }

    get calendarType() {
        return this.model.calendarType || "month";
    }

    get showTitle() {
        return this.model.showTitle || false;
    }

    get defaultAnimationsArrangementType() {
        return this.calendarType === "month" ? AnimationsArrangementType.SIMULTANEOUS : null;
    }

    switchCalendarType(newType) {
        const sharedModel = this._exportToSharedModel();

        this.model.calendarType = newType;
        const modelUpdates = this._importFromSharedModel(sharedModel);

        this.updateModel({ ...this.model, ...modelUpdates });
    }

    _exportToSharedModel() {
        return this.calendar._exportToSharedModel();
    }

    _importFromSharedModel(model) {
        const textContent = detectTextContent(model);
        if (!textContent?.length) return;

        const modelUpdates = {
            date: "",
            days: new Array(31).fill(null).map((_, i) => ({ day: i, text: "", label: {}, items: [] })),
        };

        if (this.calendarType === "week") {
            modelUpdates.date = moment().startOf("week").format("YYYY-MM-DD");

            const daysCount = this.model.showWeekends ? 7 : 5;
            textContent.forEach(({ mainText, secondaryTexts }, i) => {
                if (i > 20) return;
                const day = i % daysCount;
                modelUpdates.days[day].items.push({
                    text: {
                        blocks: [
                            {
                                html: mainText.text,
                                textStyle: TextStyleType.TITLE,
                                type: AuthoringBlockType.TEXT,
                            },
                            ...secondaryTexts.map(({ text }) => ({
                                html: text,
                                textStyle: TextStyleType.BODY,
                                type: AuthoringBlockType.TEXT,
                            }))
                        ]
                    }
                });
            });
        } else if (this.calendarType === "month") {
            modelUpdates.date = moment().startOf("month").format("YYYY-MM-DD");

            textContent.forEach(({ mainText, secondaryTexts }, i) => {
                if (i > 30) return;
                modelUpdates.startOnMonday = false;
                modelUpdates.days[i].task = {
                    text: mainText.text,
                    blocks: [
                        {
                            html: mainText.text,
                            textStyle: TextStyleType.TITLE,
                            type: AuthoringBlockType.TEXT,
                        },
                        ...secondaryTexts.map(({ text }) => ({
                            html: text,
                            textStyle: TextStyleType.BODY,
                            type: AuthoringBlockType.TEXT,
                        }))
                    ]
                };
            });
        }

        return modelUpdates;
    }

    _build() {
        if (!this.model.days) {
            this.model.days = [];
            for (let day = 0; day < 31; day++) {
                this.model.days.push({ day, text: "" });
            }
        }

        if (this.showTitle) {
            let titleText;
            switch (this.calendarType) {
                case "month":
                    titleText = moment(this.model.date).format("MMMM YYYY");
                    break;
                case "week":
                    titleText = "Week of " + moment(this.model.date).startOf("week").format("MMM DD");
                    break;
            }
            this.title = this.addElement("title", () => TextElement, {
                model: {
                    title: titleText
                },
                autoHeight: true,
                canRollover: false,
                canEdit: false,
                canSelect: false,
                isTabbable: false,
            });
        }

        switch (this.calendarType) {
            case "month":
                this.calendar = this.addElement("calendar", () => MonthCalendar);
                break;
            case "week":
                this.calendar = this.addElement("calendar", () => WeekCalendar);
                break;
        }
    }

    _calcProps(props, options) {
        let { size } = props;
        let y = 0;

        if (this.showTitle) {
            let titleProps = this.title.calcProps(size);
            titleProps.bounds = new geom.Rect(0, 0, titleProps.size);
            y += titleProps.size.height;
        }

        let calendarProps = this.calendar.calcProps(new geom.Size(size.width, size.height - y), options);
        calendarProps.bounds = new geom.Rect(0, y, calendarProps.size);

        return { size };
    }
}

class BaseCalendar extends BaseElement {
    get _canSelect() {
        return false;
    }

    get startDayOfWeek() {
        return this.startDate.day();
    }

    get daysInMonth() {
        return this.startDate.daysInMonth();
    }

    get showHeader() {
        return true; //this.model.showHeader;
    }

    get showWeekends() {
        return this.model.showWeekends;
    }

    get startOnMonday() {
        return this.model.startOnMonday;
    }

    dayOfWeek(day) {
        if (this.startOnMonday) {
            return ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"][day];
        } else {
            return ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][day];
        }
    }

    _build() {
        if (!this.model.date) {
            this.model.date = new Date().toDateString();
        }
    }
}

class WeekCalendar extends BaseCalendar {
    getElementControlBar() {
        return false;
    }

    get startDate() {
        return moment(this.model.date).startOf("week");
    }

    get showTimes() {
        return this.model.showTimes;
    }

    _exportToSharedModel() {
        return this.board._exportToSharedModel();
    }

    _build() {
        super._build();

        let totalDays = this.showWeekends ? 7 : 5;
        let startDay = this.showWeekends ? 0 : 1;

        let model = {
            taskLayout: this.model.taskLayout,
            style: this.model.style,
            items: _.slice(this.model.days, startDay, startDay + totalDays),
        };

        for (let day = 0; day < totalDays; day++) {
            model.items[day].label = this.startDate.add(day + startDay, "days").format("ddd MMM DD");
        }

        this.board = this.addElement("board", () => WeekCalenderVerticalTaskLists, {
            model,
            lockColumns: true,
        });
    }

    _calcProps(props, options) {
        let { size } = props;

        this.board.calcProps(size);

        return { size };
    }
}

class WeekCalenderVerticalTaskLists extends VerticalTaskLists {
    getElementPropertyPanel() {
        return false;  //override the default property panel
    }

    getElementControlBar() {
        return false;  //override the default control bar
    }

    getChildOptions(model, index) {
        return {
            elementSelection: WeekCalenderVerticalTaskSelection
        };
    }
}

class MonthCalendar extends BaseCalendar {
    get startDate() {
        return moment(this.model.date).startOf("month");
    }

    _exportToSharedModel() {
        return {
            textContent: this.calendarCells.filter(cell => !!cell.task)
                .map(cell => cell.task._exportToSharedModel().textContent).flat()
        };
    }

    _build() {
        super._build();

        this.headerCells = [];
        this.calendarCells = [];

        if (this.showHeader) {
            for (let dayOfWeek = 0; dayOfWeek < 7; dayOfWeek++) {
                if (this.showWeekends || (dayOfWeek > 0 && dayOfWeek < 6)) {
                    this.headerCells.push(this.addElement("header" + dayOfWeek, () => CalendarHeader, {
                        model: {
                            label: this.dayOfWeek(dayOfWeek),
                        }
                    }));
                }
            }
        }

        for (let day = 0; day < this.daysInMonth; day++) {
            let date = this.startDate.add(day, "days");

            if (this.showWeekends || (date.day() > 0 && date.day() < 6)) {
                this.calendarCells.push(this.addElement("calendarCell" + day, () => CalendarCell, {
                    model: this.model.days[day],
                    showDay: true
                }));
            }
        }
    }

    _calcProps(props, options) {
        let { size } = props;
        let totalCols = this.showWeekends ? 7 : 5;
        let totalRows = Math.ceil((this.startDate.day() + this.startDate.daysInMonth()) / 7);

        let cellWidth = Math.round(size.width / totalCols);

        let headerHeight = 0;

        if (this.showHeader) {
            for (let dayOfWeek = 0; dayOfWeek < totalCols; dayOfWeek++) {
                let headerProps = this.headerCells[dayOfWeek].calcProps(new geom.Size(cellWidth, size.height));
                headerHeight = headerProps.size.height;
                headerProps.bounds = new geom.Rect(dayOfWeek * cellWidth, 0, cellWidth, headerProps.size.height);
            }
        }

        let cellHeight = Math.round((size.height - headerHeight) / totalRows);

        let col = this.startDayOfWeek;

        if (this.showWeekends == false) {
            if (this.startDayOfWeek == 0 || this.startDayOfWeek == 6) {
                col = 0;
            } else {
                col = this.startDayOfWeek - 1;
            }
        }

        if (this.startOnMonday) {
            col--;
        }

        let row = 0;

        for (let day = 0; day < this.calendarCells.length; day++) {
            let cellProps = this.calendarCells[day].calcProps(new geom.Size(cellWidth + 1, cellHeight + 1));
            cellProps.bounds = new geom.Rect(col * cellWidth, headerHeight + row * cellHeight, cellWidth + 1, cellHeight + 1);
            col++;
            if (col > totalCols - 1) {
                col = 0;
                row++;
            }
        }

        return { size };
    }

    getAnimations() {
        const animations = [];

        if (this.showHeader) {
            animations.push({
                name: "Fade in",
                elementName: "Weekdays",
                prepare: () => {
                    this.headerCells.forEach(element => element.animationState.fadeInProgress = 0);
                },
                onBeforeAnimationFrame: progress => {
                    this.headerCells.forEach(element => element.animationState.fadeInProgress = progress);
                },
                element: this
            });
        }

        this.calendarCells.forEach(element => {
            animations.push(...element.getAnimations());
        });

        if (this.disableAnimationsByDefault) {
            animations.forEach(animation => animation.disabledByDefault = true);
        }

        return animations;
    }
}

class CalendarHeader extends BaseElement {
    _build() {
        this.label = this.addElement("label", () => TextElement, {
            canEdit: false,
            canSelect: false,
            canRollover: false,
            autoHeight: true,
        });
    }

    _calcProps(props, options) {
        let { size } = props;

        let labelProps = this.label.calcProps(size);
        labelProps.bounds = new geom.Rect(0, 0, labelProps.size);

        return { size: new geom.Size(size.width, labelProps.size.height) };
    }
}

class CalendarCell extends BaseElement {
    get minItemCount() {
        return 0;
    }

    getElementControlBar() {
        return CalendarCellControlBar;
    }

    getElementSelection() {
        return CalendarCellSelection;
    }

    get _canSelect() {
        return true;
    }

    get selectionPadding() {
        return 0;
    }

    get showTask() {
        if (this.task && this.canvas.selectionLayerController?.selectedElements.includes(this.task)) return true;
        // if (this.showTaskPlaceholder) return true;
        // if (!_.isEmpty(this.model.task?.text) || (this.model.task?.blocks && !_.isEmpty(this.model.task.blocks[0]?.html))) return true;
        if (!_.isEmpty(this.model.task?.text) || (this.model.task?.blocks?.length)) return true;
        if (this.options.showTask) return true;
        return false;
    }

    get showTaskPlaceholder() {
        return this._showTaskPlaceholder == undefined ? false : this._showTaskPlaceholder;
    }

    set showTaskPlaceholder(value) {
        this._showTaskPlaceholder = value;
    }

    get date() {
        return this.parentElement.startDate.add(this.model.day, "days");
    }

    get isWeekend() {
        return this.date.day() == 0 || this.date.day() == 6;
    }

    containsPoint(pt) {
        if (this.bounds) {
            return this.selectionBounds.inflate(this.rolloverPadding).contains(pt);
        } else {
            return false;
        }
    }

    get cellColor() {
        if (this.isWeekend && (this.model.cellColor == null || this.model.cellColor == "none" || this.model.cellColor == "rgb(254,254,254)")) {
            return "rgb(230,230,230)";
        } else {
            return this.model.cellColor || "rgb(254,254,254)";
        }
    }

    _build() {
        if (this.showTask) {
            this.task = this.addElement("task", () => CalendarTaskText, {
                autoHeight: true,
                scaleTextToFit: true,
                allowAlignment: true,
                blockStructure: BlockStructureType.SINGLE_BLOCK,
                syncFontSizeWithSiblings: true,
                getSiblings: () => this.parentElement.calendarCells.map(cell => cell.elements.task),
                allowNewLines: true,
                backgroundElement: this
            });
        }

        if (this.options.showDay) {
            this.dayLabel = this.addElement("dayLabel", () => CalendarCellDayLabel);
        }
    }

    _loadStyles(styles) {
        if (this.isWeekend) {
            styles.applyStyles(styles.weekend);
        }
    }

    _calcProps(props, options) {
        let { size } = props;

        // this.createDecoration({
        //     type: "frame",
        //     shape: "rect",
        //     // fillColor: this.palette.getColor( this.cellColor).toRgbString(),
        //     // strokeColor: "rgb(178,178,178)",
        //     strokeWidth: 1
        // });

        let availableTextSize = size.clone();
        if (this.showTask) {
            let taskProps = this.task.calcProps(availableTextSize, options);
            taskProps.bounds = new geom.Rect(0, 0, taskProps.size).centerInRect(new geom.Rect(0, 0, availableTextSize));
        }

        if (this.options.showDay) {
            const dayLabelProps = this.dayLabel.calcProps(size, { day: this.model.day });
            dayLabelProps.bounds = new geom.Rect(this.styles.paddingLeft, this.styles.paddingRight, size);
        }

        return { size };
    }

    _applyColors() {
        this.colorSet.backgroundColor = this.palette.getColor(this.cellColor);
        this.decoration.colorSet.fillColor = this.palette.getColor(this.cellColor);
        this.decoration.colorSet.strokeColor = this.palette.getColor("rgb(178,178,178)");
    }
}

class CalendarCellDayLabel extends SVGElement {
    _applyColors() {
        this.styles.fontColor = this.palette.getColor("primary", this.getBackgroundColor());
    }

    renderSVG(props, svgStyles) {
        const { bounds, options: { day } } = props;

        return (
            <SVGGroup ref={this.ref} key={this.id}>
                <text
                    fontFamily={this.styles.fontId}
                    fontWeight={this.styles.fontWeight}
                    fontSize={this.styles.fontSize}
                    fill={this.styles.fontColor}
                    fillOpacity={0.8}
                    x={bounds.left + bounds.width}
                    y={bounds.top + this.styles.fontSize}
                    textAnchor="end"
                >
                    {day + 1}
                </text>
            </SVGGroup>
        );
    }
}

class CalendarTaskText extends TextElement {
    // inherited for CalendarTaskTextSelection editor
    getElementSelection() {
        return CalendarTaskTextSelection;
    }
}

export const elements = {
    Calendar: Calendar
};

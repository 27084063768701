import { AuthoringBlockType, BlockStructureType, CalloutType, DecorationStyle } from "../../../../../../common/constants";

import { TextElement, USE_STYLESHEET_FOR_TEXT_STYLES } from "../../base/Text/TextElement";
import { MarkerCallout } from "./MarkerCallout";

export class NumberedCallout extends MarkerCallout {
    get authoringElementType() {
        return CalloutType.MARKERANDTEXT;
    }

    buildMarker() {
        if (this.model.value == null) {
            this.model.value = {
                blocks: [{
                    type: AuthoringBlockType.TEXT,
                    textStyle: USE_STYLESHEET_FOR_TEXT_STYLES,
                    html: (this.itemIndex + 1).toString()
                }]
            };
        }

        this.model.frameType = null; // protects against the frameType being set to "none" in the model via changing the callout type

        return this.addElement("value", () => TextElement, {
            blockStructure: BlockStructureType.SINGLE_BLOCK,
            canEdit: true,
            backgroundElement: this.decoration
        });
    }

    get decorationStyle() {
        return DecorationStyle.FILLED;
    }
}

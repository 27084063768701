import React, { Component } from "react";
import styled from "styled-components";

import { Slider } from "@material-ui/core";

import { Gap10, Gap20 } from "../../../react/components/Gap";

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
`;

const InputContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

const StyledInput = styled.input`
    border: solid 1px #ccc;
    background: white;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    outline: none;
    position: relative;
    top: -1px;
  
     /* Chrome, Safari, Edge, Opera */
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    
    /* Firefox */
    -moz-appearance: textfield;
`;

export class MultiInputSlider extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: props.value
        };

        this.onChangeHandledAt = null;
    }

    onChange = () => {
        window.requestAnimationFrame(timestamp => {
            if (this.onChangeHandledAt === timestamp) {
                return;
            }
            this.onChangeHandledAt = timestamp;

            const { value } = this.state;
            const { onChange, multiValue } = this.props;

            let doChange = false;
            let result;
            if (multiValue) {
                result = value.map(x => parseFloat(x));
                doChange = result.every(x => !isNaN(x));
            } else {
                result = parseFloat(value);
                doChange = !isNaN(result);
            }

            if (doChange) {
                onChange(result);
            }
        });
    }

    onChangeCommitted = () => {
        const { value } = this.state;
        const { onChangeCommitted, multiValue, onEndDrag } = this.props;

        this.setState({ isDragging: false });
        onEndDrag && onEndDrag();

        let doChange = false;
        let result;
        if (multiValue) {
            result = value.map(x => parseFloat(x));
            doChange = result.every(x => !isNaN(x));
        } else {
            result = parseFloat(value);
            doChange = !isNaN(result);
        }

        if (doChange) {
            onChangeCommitted(result);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.value != this.props.value) {
            this.setState({ value: this.props.value });
        }
    }

    handleCommitValue = () => {
        this.onChangeCommitted();
    }

    handleChangeInput = value => {
        let doChange = false;
        if (this.props.multiValue) {
            // Order the values if we have valid values
            doChange = value.every(x => x !== "");
            if (doChange) {
                value = value.sort((a, b) => a < b ? -1 : 1);
            }
        }

        this.setState({ value }, () => {
            if (doChange) {
                this.onChange();
            }
        });
    }

    handleChangeSlider = (event, value) => {
        if (!this.state.isDragging) {
            this.setState({ isDragging: true });
            this.props.onStartDrag && this.props.onStartDrag();
        }

        this.setState({ value }, () => this.onChange());
    }

    handleFocus = event => {
        event.target.select();
    }

    handleKeyDown = event => {
        event.stopPropagation();
    }

    handleKeyPress = event => {
        if (event.which == 13) {
            this.handleCommitValue();
        }
    }

    render() {
        const { className, styles, showBorder = true, inputFormat = "number", inputMin = 0, inputMax = 99, sliderMin = 0, sliderMax = 99, step = 1 } = this.props;
        const { value } = this.state;

        let formattedValue = value;

        if (inputFormat == "percentage") {
            formattedValue = Math.round(formattedValue * 100);
        }

        return (<>
            {
                !value.length &&
                <Container
                    className={className}
                    style={{
                        flexDirection: "row",
                        height: 34,
                        ...styles
                    }}
                    showBorder={showBorder}
                >
                    <StyledInput
                        type="number"
                        min={inputMin}
                        max={inputMax}
                        value={formattedValue}
                        style={{
                            // width: 60,
                            height: 25,
                        }}
                        onFocus={this.handleFocus}
                        onBlur={this.handleCommitValue}
                        onChange={evt => this.handleChangeInput(evt.target.value)}
                    // onKeyDown={this.handleKeyDown}
                    // onKeyPress={this.handleKeyPress}
                    />
                    {inputFormat == "percentage" && <div style={{ marginLeft: 5 }}>%</div>}
                    <Gap10 />
                    <Slider
                        min={sliderMin}
                        max={sliderMax}
                        step={step}
                        value={value}
                        onChange={this.handleChangeSlider}
                        onChangeCommitted={this.handleCommitValue}
                        style={{
                            width: 150
                        }}
                    />
                </Container>
            }
            {
                value.length &&
                <Container
                    className={className}
                    style={{
                        flexDirection: "column",
                        height: 50,
                        ...styles
                    }}
                    showBorder={showBorder}
                >
                    <InputContainer>
                        <StyledInput
                            type="number"
                            min={inputMin}
                            max={inputMax}
                            value={value[0]}
                            style={{
                                width: 50,
                                height: 24,
                            }}
                            onFocus={this.handleFocus}
                            onBlur={this.handleCommitValue}
                            onChange={evt => this.handleChangeInput([evt.target.value, value[1]])}
                            onKeyDown={this.handleKeyDown}
                            onKeyPress={this.handleKeyPress}
                        />
                        <StyledInput
                            type="number"
                            min={inputMin}
                            max={inputMax}
                            value={value[1]}
                            style={{
                                width: 50,
                                height: 24,
                            }}
                            onFocus={this.handleFocus}
                            onBlur={this.handleCommitValue}
                            onChange={evt => this.handleChangeInput([value[0], evt.target.value])}
                            onKeyDown={this.handleKeyDown}
                            onKeyPress={this.handleKeyPress}
                        />
                    </InputContainer>
                    <Slider
                        min={sliderMin}
                        max={sliderMax}
                        step={step}
                        value={value}
                        onChange={this.handleChangeSlider}
                        onChangeCommitted={this.handleCommitValue}
                        style={{
                            width: "100%",
                            padding: "10px 0",
                        }}
                    />
                </Container>
            }
        </>);
    }
}

import * as geom from "js/core/utilities/geom";
import { Shape } from "js/core/utilities/shapes";

import { BaseElement } from "../../base/BaseElement";
import { SVGPathElement } from "../../base/SVGElement";
import {
    RadialBarGraphPropertyPanel, RadialBarInfographicControlBar, RadialBarInfographicSelection
} from "../../../Editor/ElementPropertyPanels/GridContainer/Infographics/RadialBarGraphUI";
import { TextElement } from "../../base/Text/TextElement";
import { FormatType, PositionType } from "../../../../../../common/constants";
import { formatter } from "../../../../../core/utilities/formatter";
import { ValueLabelSelection } from "../../../Editor/ElementSelections/ValueLabelSelection";

export class RadialBarInfographic extends BaseElement {
    static get schema() {
        return {
            value: 50,
            showValue: true,
            format: FormatType.PERCENT,
            color: "theme"
        };
    }

    getElementPropertyPanel() {
        return RadialBarGraphPropertyPanel;
    }

    getElementControlBar() {
        return RadialBarInfographicControlBar;
    }

    getElementSelection() {
        return RadialBarInfographicSelection;
    }

    get selectionPadding() {
        return 30;
    }

    get name() {
        return "Radial Bar";
    }

    get _canSelect() {
        return true;
    }

    get doubleClickToSelect() {
        return true;
    }

    get currentValue() {
        if (this.isAnimating) {
            return this.model.value * this.animationState.value;
        }

        return this.model.value;
    }

    get maxValue() {
        return this.model.maxValue ?? 100;
    }

    get format() {
        return this.model.format ?? FormatType.PERCENT;
    }

    get _formatOptions() {
        return this.model.formatOptions ?? formatter.getDefaultFormatOptions();
    }

    get showValue() {
        return this.model.showValue ?? true;
    }

    get showLabel() {
        return this.model.showLabel ?? true;
    }

    get labelPosition() {
        if (this.showLabel) {
            return this.model.labelPosition ?? "inside";
        } else {
            return null;
        }
    }

    formatValue(value) {
        let displayValue = value;
        if (this.format == FormatType.PERCENT) {
            displayValue = value / 100;
        } else {
            displayValue = value;
        }

        return formatter.formatValue(displayValue, this.format, this.formatOptions);
    }

    get valueText() {
        return this.formatValue(this.currentValue);
    }

    _build() {
        this.circle = this.addElement("radialCircle", () => SVGPathElement);
        this.path = this.addElement("radialPath", () => SVGPathElement);

        if (this.showValue) {
            this.value = this.addElement("value", () => RadialBarInfographicValue, {
                model: {
                    value: this.valueText
                },
                constrainWidth: true,
                scaleTextToFit: true,
                autoHeight: true,
                minTextScale: 0,
                canEdit: false
            });
        }

        if (this.showLabel) {
            this.label = this.addElement("label", () => TextElement, {
                scaleTextToFit: true,
                minTextScale: 0.05,
                autoHeight: true,
            });
        }
    }

    get minWidth() {
        return 75;
    }

    get minHeight() {
        return 75;
    }

    _calcProps(props, options) {
        const { size } = props;

        const BASE_SIZE = 150;

        const bounds = new geom.Rect(0, 0, size);

        let value = this.valueText;
        let labelProps, valueProps;
        let circleBounds, outerCircleBounds, innerCircleBounds, strokeWidth, scale;

        if (this.showValue) {
            if (this.isAnimating) {
                this.value.updateText(this.valueText);
            }
        }

        let calcCircleProps = circleBounds => {
            let scale = Math.min(size.width, size.height) / BASE_SIZE;
            let strokeWidth = 15 * scale;
            innerCircleBounds = circleBounds.deflate(strokeWidth).deflate(circleBounds.width * 0.15);
            return { scale, strokeWidth, innerCircleBounds };
        };

        if (this.showLabel) {
            switch (this.labelPosition) {
                case PositionType.BOTTOM: {
                    this.label.updateStyles({ marginTop: 20 });
                    labelProps = this.label.calcProps(size);

                    let circleSize = Math.min(bounds.width, bounds.height - labelProps.size.height);

                    circleBounds = new geom.Rect(bounds.centerH - circleSize / 2, bounds.centerV - (circleSize + labelProps.size.height) / 2, circleSize, circleSize);
                    ({ scale, strokeWidth, innerCircleBounds, outerCircleBounds } = calcCircleProps(circleBounds));

                    if (this.showValue) {
                        valueProps = this.value.calcProps(new geom.Size(innerCircleBounds.width, innerCircleBounds.height / 2));
                        valueProps.bounds = new geom.Rect(innerCircleBounds.left, innerCircleBounds.centerV - valueProps.size.height / 2, valueProps.size);
                    }
                    labelProps.bounds = new geom.Rect(bounds.centerH - labelProps.size.width / 2, circleBounds.bottom, labelProps.size);
                    break;
                }
                case PositionType.TOP: {
                    this.label.updateStyles({ marginBottom: 20 });
                    labelProps = this.label.calcProps(size);

                    let circleSize = Math.min(bounds.width, bounds.height - labelProps.size.height);

                    circleBounds = new geom.Rect(bounds.centerH - circleSize / 2, bounds.centerV - (circleSize + labelProps.size.height) / 2 + labelProps.size.height, circleSize, circleSize);
                    ({ scale, strokeWidth, innerCircleBounds } = calcCircleProps(circleBounds));

                    if (this.showValue) {
                        valueProps = this.value.calcProps(new geom.Size(innerCircleBounds.width, innerCircleBounds.height / 2));
                        valueProps.bounds = new geom.Rect(innerCircleBounds.left, innerCircleBounds.centerV - valueProps.size.height / 2, valueProps.size);
                    }
                    labelProps.bounds = new geom.Rect(bounds.centerH - labelProps.size.width / 2, circleBounds.top - labelProps.size.height, labelProps.size);
                    break;
                }
                case "inside":
                default:
                    circleBounds = new geom.Rect(0, 0, size.square()).centerInRect(bounds);
                    ({ scale, strokeWidth, innerCircleBounds } = calcCircleProps(circleBounds));

                    // this.label.updateStyles({ fontSize: this.label.styles.fontSize * scale });
                    if (this.showValue) {
                        // this.value.updateStyles({ fontSize: this.value.styles.fontSize * scale });
                        this.label.updateStyles({ marginTop: 10 });
                    }

                    labelProps = this.label.calcProps(new geom.Size(innerCircleBounds.width, innerCircleBounds.height / 2));
                    if (this.showValue) {
                        valueProps = this.value.calcProps(new geom.Size(innerCircleBounds.width, innerCircleBounds.height / 2));
                        valueProps.bounds = new geom.Rect(innerCircleBounds.left, innerCircleBounds.centerV - (valueProps.size.height + labelProps.size.height) / 2, valueProps.size);
                        labelProps.bounds = new geom.Rect(innerCircleBounds.left, innerCircleBounds.centerV + (valueProps.size.height - labelProps.size.height) / 2, labelProps.size);
                    } else {
                        labelProps.bounds = new geom.Rect(innerCircleBounds.left, innerCircleBounds.centerV - labelProps.size.height / 2, labelProps.size);
                    }

                    break;
            }
        } else {
            circleBounds = new geom.Rect(0, 0, size.square()).centerInRect(bounds);
            ({ scale, strokeWidth, innerCircleBounds } = calcCircleProps(circleBounds));

            if (this.showValue) {
                valueProps = this.value.calcProps(new geom.Size(innerCircleBounds.width, innerCircleBounds.height / 2));
                valueProps.bounds = new geom.Rect(innerCircleBounds.left, innerCircleBounds.centerV - valueProps.size.height / 2, valueProps.size);
            }
        }

        let percent = Math.clamp(this.currentValue / this.maxValue, 0, 1);

        let unstrokedCircleBounds = circleBounds.deflate(strokeWidth / 2);

        const startAngle = 0 - 90 / 180 * Math.PI;
        const angle = Math.min(359.99, 360 * percent);
        const endAngle = (angle - 90) / 180 * Math.PI;
        const r = unstrokedCircleBounds.width / 2;

        this.circle.createProps({
            path: Shape.drawCircle(r, unstrokedCircleBounds.center).toPathData(),
        });
        this.circle.styles.strokeWidth = strokeWidth;

        this.path.createProps({
            path: Shape.drawArc(unstrokedCircleBounds.centerH, unstrokedCircleBounds.centerV, r, startAngle, endAngle).toPathData(),
        });
        this.path.styles.strokeWidth = strokeWidth;

        return { size };
    }

    _applyColors() {
        this.circle.colorSet.strokeColor = this.palette.getColor(this.model.color ?? this.getDecorationColor().toRgbString(), this.getBackgroundColor());
        this.circle.colorSet.fillColor = null;
        this.path.colorSet.strokeColor = this.palette.getColor(this.model.color ?? this.getDecorationColor().toRgbString(), this.getBackgroundColor());
        this.path.colorSet.fillColor = null;
    }

    get animationElementName() {
        return `Radial bar #${this.itemIndex + 1}`;
    }

    get animateChildren() {
        return false;
    }

    _getAnimations() {
        return [{
            name: "Grow in",
            prepare: () => {
                this.animationState.value = 0;
                this.animationState.fadeInProgress = 0;
            },
            onBeforeAnimationFrame: progress => {
                this.animationState.value = progress;
                this.animationState.fadeInProgress = Math.min(1, progress * 3);
                return this;
            }
        }];
    }
}

class RadialBarInfographicValue extends TextElement {
    getElementSelection() {
        return ValueLabelSelection;
    }

    get _canSelect() {
        return true;
    }
}

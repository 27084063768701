import React, { Component } from "react";

import { NodeType } from "../../../../../common/constants";
import { WithLabel } from "../../../../Components/WithLabel";
import { ToggleSwitch } from "../../../../Components/ToggleSwitch";
import { ColorPicker } from "../../../../react/components/ColorPicker";
import { ImagePopup } from "../../../../Components/ImagePopup";
import { Slider } from "../../../../Components/Slider";

import { ConnectorPropertyPanel } from "../EditorComponents/ConnectorPropertyPanel";
import { getContentItemImageOptions } from "../EditorComponents/getContentItemImageOptions";
import { PropertyPanelContainer, PropertySection } from "../../../../EditorComponents/PropertyPanel";
import { CollectionColorPicker } from "../EditorComponents/ColorPickers/CollectionColorPicker";
import { ContentItemSelection } from "../ElementSelections/ContentItemSelection";
import { Dropdown } from "../../../../Components/Dropdown";
import { MenuItem } from "../../../../Components/Menu";

export class IconCirclePropertyPanel extends Component {
    render() {
        const { element, canvas } = this.props;
        return (
            <PropertyPanelContainer>

                <PropertySection>
                    <WithLabel label="Item Style">
                        <ImagePopup value={element.itemElements[0].model.nodeType}
                            size={70} previewSize={50} border={false}
                            onChange={value => {
                                for (let node of element.itemElements) {
                                    node.model.nodeType = value;
                                }
                                element.saveModel(false);
                            }}
                        >
                            {getContentItemImageOptions([NodeType.CONTENT_AND_TEXT, NodeType.BULLET_TEXT, NodeType.NUMBERED_TEXT, NodeType.LETTERED_TEXT])}
                        </ImagePopup>
                    </WithLabel>
                    <WithLabel label="Item Color">
                        <CollectionColorPicker element={element} showDecorationStyles />
                    </WithLabel>
                </PropertySection>

                <PropertySection>
                    {element.model.shape_is_polygon == false &&
                        <WithLabel label="Stretch">
                            <Slider value={element.model.ellipse_size}
                                min={1} max={2} step={0.01}
                                percent
                                showInput
                                onChange={(value => element.refreshModel({ ellipse_size: value }))}
                                onCommit={() => element.saveModel()}
                            />
                        </WithLabel>
                    }
                    <WithLabel label="Use Straight Lines">
                        <ToggleSwitch value={element.model.shape_is_polygon}
                            onChange={value => element.updateModel({ shape_is_polygon: value })}
                        />
                    </WithLabel>
                    <WithLabel label="Direction">
                        <Dropdown value={element.model.clockwise}
                            onChange={value => element.updateModel({ clockwise: value })}
                        >
                            <MenuItem value={true}>Clockwise</MenuItem>
                            <MenuItem value={false}>Counter-Clockwise</MenuItem>
                        </Dropdown>
                    </WithLabel>
                </PropertySection>

                <ConnectorPropertyPanel canvas={canvas} elements={element.connectors?.itemElements ?? []} />

            </PropertyPanelContainer>
        );
    }
}

export function IconCircleItemPropertyPanel({ element }) {
    const connector = element.connectorsFromNode[0];
    return (
        <>
            <PropertySection>
                <WithLabel label="Item Color">
                    <ColorPicker value={element.model.color}
                        onChange={color => element.updateModel({ color }, { refreshStyles: true })}
                    />
                </WithLabel>
            </PropertySection>
            {connector && <ConnectorPropertyPanel element={connector} canAddLabel />}
        </>
    );
}

export class IconCircleItemSelection extends ContentItemSelection {
    get dragAsCollectionItem() {
        return true;
    }
}

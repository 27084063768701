import React, { Component } from "react";
import styled from "styled-components";
import moment from "moment";

import { themeColors } from "js/react/sharedStyles";
import { _ } from "js/vendor";
import { FlexSpacer, Gap, Gap10 } from "js/react/components/Gap";
import { ColorPicker } from "js/Components/ColorPicker";
import { Popup, PopupContainer, PopupContent } from "js/Components/Popup";
import { ImagePopup } from "js/Components/ImagePopup";
import { ImageOption } from "js/Components/ImageOptionList";
import { Icon } from "js/Components/Icon";
import { StaticImage } from "js/Components/StaticImage";
import { WithLabel } from "js/Components/WithLabel";
import { NumericStepper } from "js/Components/NumericStepper";
import { ToggleSwitch } from "js/Components/ToggleSwitch";
import { Divider } from "js/Components/Divider";
import { Button } from "js/Components/Button";
import { TextField } from "js/Components/TextField";
import { FlexBox } from "js/react/components/LayoutGrid";
import WidgetButton from "js/Components/WidgetButton";

import PresentationEditorController from "js/editor/PresentationEditor/PresentationEditorController";

import { EditDataPanel } from "./EditDataPanel";
import ReactGridWrapper from "./ReactGridWrapper";
import { Key } from "js/core/utilities/keys";
import { reorderArray } from "js/core/utilities/utilities";

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    padding: 20px 20px 20px 5px;
    background: #f5f5f5;
    border-left: solid 5px #333;
`;

const TableContainer = styled.div`
    display: flex;
    flex-flow: row;
    align-items: flex-start;
    height: 100%;
    overflow-y: scroll;
    padding-left: 20px;
`;

const HeaderRowContainer = styled.div`
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    > div:first-child {
        display: flex;
        align-items: center;
        gap: 20px;
    }
`;

const SeriesColumn = styled.div`
    width: 250px;
    display: flex;
    flex-direction: column;

    .MuiButton-label {
        justify-content: flex-start;
    }

    .bai-popup > .MuiButtonBase-root > .MuiButton-label {
        color: rgb(51, 51, 51);
    }
`;

const ClosePanelButton = styled.div`
    position: absolute;
    top: -10px;
    right: 4px;
    padding: 10px;
    cursor: pointer;
`;

const InfoBlock = styled.div`
    color: #666;
    font-style: italic;
    display: flex;
    flex-flow: row;
    align-items: center;
    gap: 5px;

    > div {
        background: white;
        border-radius: 50%;
    }
`;

export class EditChartDataPanel extends EditDataPanel {
    constructor() {
        super();

        this.sheetRef = React.createRef();
    }

    componentDidMount() {
        // Add event listeners for keydown and keyup
        document.addEventListener("keydown", this.handleKeyDown);
    }

    componentWillUnmount() {
        // Remove event listeners when the component is unmounted
        document.removeEventListener("keydown", this.handleKeyDown);
    }

    getData = () => {
        const { element } = this.props;

        const categories = element.model.chartData.xAxis.categories;
        const cols = categories.map((category, index) => ({
            columnId: index,
            width: 100,
            reorderable: true
        }));
        cols.push({
            columnId: cols.length,
            width: 100
        });

        const headerRow = {
            rowId: "header",
            height: 30,
            cells: categories.map((category, index) => ({
                type: "header",
                text: String.fromCharCode(65 + index)
            }))
        };
        headerRow.cells.push({
            type: "header",
            text: "+"
        });

        const categoryRow = {
            rowId: "categories",
            height: 40,
            cells: categories.map((category, index) => ({
                type: "text",
                text: category ?? "---",
                date: moment(category).toDate(),
                format: new Intl.DateTimeFormat("en-US", { month: "short", year: "numeric" })
            }))
        };
        categoryRow.cells.push({
            type: "text",
            text: ""
        });

        const series = element.model.chartData.series;

        const seriesRows = series.map((row, index) => ({
            rowId: row.id,
            height: 40,
            cells: categories.map((category, index) => ({
                type: "number",
                value: row.data[index]?.y ?? null
            }))
        }));
        for (const row of seriesRows) {
            row.cells.push({
                type: "number"
            });
        }

        return {
            series,
            cols,
            rows: [headerRow, categoryRow, ...seriesRows]
        };
    }

    handleColumnsReordered = async (targetColumnId, columnIds) => {
        const { element } = this.props;

        // Update the element's categories
        element.model.chartData.xAxis.categories = reorderArray(element.model.chartData.xAxis.categories, columnIds, targetColumnId);

        element.model.chartData.series.forEach(series => {
            series.data = reorderArray(series.data, columnIds, targetColumnId);
        });

        await element.saveModel();
        this.sheetRef.current.clearSelections();
    }

    handleCellsChanged = changes => {
        const { element } = this.props;

        for (const change of changes) {
            if (change.rowId === "categories") {
                const categories = element.model.chartData.xAxis.categories;
                if (change.columnId >= categories.length) {
                    // add new category
                    categories.push(change.newCell.text);
                } else {
                    // update existing category
                    categories[change.columnId] = change.newCell.text;
                }
                element.model.chartData.xAxis.categories = categories;
                element.model.chartData.xAxis.autoType = "linear";
            } else {
                let value = change.newCell.value;

                if (Number.isNaN(value)) {
                    if (element.isWaterfall) {
                        value = "sum";
                    } else {
                        value = null;
                    }
                }

                const series = element.model.chartData.series.findById(change.rowId);
                if (change.columnId >= series.data.length) {
                    // add new data point
                    series.data[change.columnId] = { y: value };
                    if (change.columnId >= element.model.chartData.xAxis.categories.length) {
                        // add new category
                        element.model.chartData.xAxis.categories.push("Col " + (change.columnId + 1));
                    }
                } else {
                    // update existing data point
                    if (series.data[change.columnId]) {
                        series.data[change.columnId].y = value;
                    } else {
                        series.data[change.columnId] = { y: value };
                    }
                }
            }
        }

        element.saveModel();
    }

    handleContextMenu = (selectedRowIds, selectedColIds, selectionMode, menuOptions) => {
        const { element } = this.props;

        if (selectedColIds.length === 0) {
            return [];
        }

        if (selectionMode === "column") {
            const categories = element.model.chartData.xAxis.categories;

            return [
                {
                    id: "add-column-before", label: "Insert Column Before", handler: async () => {
                        const colId = selectedColIds[0];
                        categories.splice(colId, 0, "Col " + (colId));

                        for (const series of element.model.chartData.series) {
                            series.data.splice(colId, 0, { y: element.isWaterfall ? "sum" : 0 });
                        }
                        await element.saveModel();
                        this.selectColumn(colId);
                    }
                },
                {
                    id: "add-column-after", label: "Insert Column After", handler: async () => {
                        const colId = selectedColIds[0];
                        categories.splice(colId + 1, 0, "Col " + (colId + 1));

                        for (const series of element.model.chartData.series) {
                            series.data.splice(colId + 1, 0, { y: element.isWaterfall ? "sum" : 0 });
                        }
                        await element.saveModel();
                        this.selectColumn(colId + 1);
                    }
                },
                {
                    id: "remove-column", label: "Remove Column".pluralize(selectedColIds.length > 1), handler: async () => {
                        for (const colId of selectedColIds.reverse()) {
                            categories.splice(colId, 1);

                            for (const series of element.model.chartData.series) {
                                series.data.splice(colId, 1);

                                if (element.model.chartAnnotations) {
                                    if (element.model.chartAnnotations.items) {
                                        for (const annotation of element.model.chartAnnotations.items) {
                                            if (annotation.dataSource?.seriesId === series.id && annotation.dataSource?.pointIndex === colId) {
                                                element.model.chartAnnotations.items.remove(annotation);
                                            }
                                            if (annotation.dataSource?.pointIndex > colId) {
                                                annotation.dataSource.pointIndex--;
                                            }
                                            if (annotation.snapOptions?.pointIndex > colId) {
                                                annotation.snapOptions.pointIndex--;
                                            }
                                        }
                                    }
                                    if (element.model.chartAnnotations.connections?.items) {
                                        for (const connector of element.model.chartAnnotations.connections.items) {
                                            if (connector.sourceSnapOptions?.seriesId === series.id && connector.sourceSnapOptions?.pointIndex === colId) {
                                                element.model.chartAnnotations.connections.items.remove(connector);
                                            }
                                            if (connector.targetSnapOptions?.seriesId === series.id && connector.targetSnapOptions?.pointIndex === colId) {
                                                element.model.chartAnnotations.connections.items.remove(connector);

                                                const annotation = element.model.chartAnnotations.items.findById(connector.source);
                                                if (annotation) {
                                                    element.model.chartAnnotations.items.remove(annotation);
                                                }
                                            }
                                            if (connector.sourceSnapOptions?.pointIndex > colId) {
                                                connector.sourceSnapOptions.pointIndex--;
                                            }
                                            if (connector.targetSnapOptions?.pointIndex > colId) {
                                                connector.targetSnapOptions.pointIndex--;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        await element.saveModel();
                        this.sheetRef.current.clearSelections();
                    }
                },
                ...menuOptions.filter(m => m.id != "cut")
            ];
        } else {
            return menuOptions.filter(m => m.id != "cut");
        }
    }

    handleAddSeries = () => {
        const { element } = this.props;

        const lastSeries = _.last(element.chartModel.series);

        let seriesColor;
        if (lastSeries.colorName?.startsWith("chart")) {
            seriesColor = "chart" + (parseInt(lastSeries.colorName.substr(5)) % Object.keys(element.canvas.getTheme().palette.getChartColors()).length + 1);
        } else {
            seriesColor = "chart1";
        }

        const generateRandomSeriesData = function(totalLength, categoryLength, max) {
            const data = [];
            for (let i = 0; i < totalLength; i++) {
                if (i < categoryLength) {
                    data.push({ y: parseInt(Math.random() * max), pt: true });
                } else {
                    data.push({ pt: true });
                }
            }
            return data;
        };

        const newSeries = {
            id: "series" + new Date().getTime(), name: "Untitled", type: lastSeries ? lastSeries.type : "line", data: generateRandomSeriesData(lastSeries.data.length, _.dropRightWhile(element.chartModel.xAxis.categories, c => c === "").length, _.maxBy(lastSeries.data, pt => pt.y).y), colorName: seriesColor, marker: "none", lineWidth: lastSeries.lineWidth, stacking: element.chartModel.series[0]?.stacking, zones: [{
                style: "default",
            }],
        };

        element.chartModel.series.push(newSeries);
        element.saveModel();
    }

    handleReorderSeries = async (direction, series) => {
        const { element } = this.props;
        const seriesIndex = element.chartModel.series.indexOf(series);
        const newIndex = seriesIndex + direction;
        if (newIndex >= 0 && newIndex < element.chartModel.series.length) {
            element.chartModel.series.move(seriesIndex, newIndex);
            await element.saveModel();

            this.forceUpdate();
        }
    }

    handleDeleteSeries = series => {
        const { element } = this.props;

        element.chartModel.series.remove(series);

        const annotations = element.annotations;
        if (annotations) {
            if (annotations.model.connections?.items?.length) {
                const connectors = annotations.model.connections.items.filter(connector => {
                    return connector.sourceSnapOptions?.seriesId === series.id || connector.targetSnapOptions?.seriesId === series.id;
                });
                for (let connector of connectors) {
                    annotations.model.connections.items.remove(connector);
                }
            }

            annotations.itemCollection.forEach(item => {
                if (item.dataSource?.seriesId === series.id) {
                    annotations.deleteItem(item.id);
                }
            });
        }

        element.saveModel();
    }

    selectColumn(colId) {
        this.sheetRef.current.setState({ selectionMode: "column", selectedIds: [colId], selectedIndexes: [0] });
    }

    handleKeyDown = event => {
        if (event.which === Key.KEY_Z && (event.metaKey || event.ctrlKey)) {
            event.preventDefault();
            event.stopPropagation();
            if (event.shiftKey) {
                PresentationEditorController.redo();
            } else {
                PresentationEditorController.undo();
            }
        }
    }

    render() {
        const { element, onClose } = this.props;
        if (!element.model.chartData) {
            return null;
        }

        const { cols, rows, series } = this.getData();

        const isWaterfall = element.isWaterfall;

        const hasDataSourceLink = element.parentElement.hasDataSourceLink();

        const getBarColorLabel = (propertyName, label) => (
            <WithLabel label={label}>
                <ColorPicker
                    value={element.model.chartData[propertyName]}
                    onChange={color => {
                        element.model.chartData[propertyName] = color;
                        element.saveModel();
                    }}
                    showChartColors
                    showColorPicker
                    disableAlpha
                />
            </WithLabel>
        );

        return (
            <Container onKeyDown={this.handleKeyDown} onClick={() => this.sheetRef.current.clearSelections() }>
                {isWaterfall && <HeaderRowContainer>
                    <div>
                        {this.DataSourceButton}
                        {getBarColorLabel("positiveBarColor", "Positive Bar Color")}
                        {getBarColorLabel("negativeBarColor", "Negative Bar Color")}
                        {getBarColorLabel("sumBarColor", "Sum Bar Color")}
                    </div>
                    <InfoBlock>
                        <Icon>info</Icon>
                        Delete value to convert a cell to a "sum" cell
                    </InfoBlock>
                </HeaderRowContainer>}

                <TableContainer style={{ pointerEvents: hasDataSourceLink ? "none" : "auto" }}>
                    {!isWaterfall && <>
                        <SeriesColumn>
                            {this.DataSourceButton}

                            <FlexBox height={29}>
                            </FlexBox>
                            {series.map((row, index) => (<ChartSeriesCell key={index} element={element} series={row} onReorder={this.handleReorderSeries} onDelete={this.handleDeleteSeries} />))}
                            <Divider />
                            <Gap10 />
                            <Button unframed onClick={this.handleAddSeries}><Icon>add</Icon>Add Series</Button>
                        </SeriesColumn>
                        <Gap size={3} />
                    </>}

                    <ReactGridWrapper
                        ref={this.sheetRef}
                        columns={cols}
                        rows={rows}
                        enableFillHandle
                        enableRangeSelection
                        enableColumnSelection
                        stickyTopRows={1}
                        onColumnsReordered={this.handleColumnsReordered}
                        onCellsChanged={this.handleCellsChanged}
                        onContextMenu={this.handleContextMenu}
                    />
                </TableContainer>

                <ClosePanelButton>
                    <WidgetButton icon="close" outlined onClick={onClose} />
                </ClosePanelButton>
            </Container>
        );
    }
}

const SeriesContainer = styled.section`
    height: 40px;
    width: 100%;
    border: solid 1px #ccc;
    border-bottom: none;
    box-sizing: border-box;
    background: white;


    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    gap: 10px;

    .MuiInput-root {
        font-size: 14px;
    }
`;

const SeriesReorderButtons = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0px;
    opacity: 0;
    //position: absolute;
    //left: -18px;
    gap: 5px;

    .bai-icon {
        font-size: 18px;
    }
`;

const SeriesReorderButton = styled.div`
    cursor: pointer;

    &:hover {
        .bai-icon {
            color: ${themeColors.ui_blue};
        }
    }
`;

const SeriesOuterContainer = styled.div`
    display: flex;
    position: relative;
    pointer-events: auto;
    margin-left: -20px;
    height: 40px;

    &:hover {
        ${SeriesReorderButtons} {
            opacity: 1;
        }
    }
`;

class ChartSeriesCell extends Component {
    constructor(props) {
        super(props);

        this.state = {
            seriesName: props.series.name
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.series.name !== this.props.series.name) {
            this.setState({ seriesName: this.props.series.name });
        }
    }

    render() {
        const { element, series, onReorder, onDelete } = this.props;
        const { seriesName } = this.state;

        const seriesColorProps = element.getSeriesProps(series);

        let seriesType = series.type;
        if (seriesType === "bar") {
            seriesType = "column";
        }
        return (
            <SeriesOuterContainer>
                <SeriesReorderButtons>
                    <SeriesReorderButton onClick={() => onReorder(-1, series)}>
                        <Icon>keyboard_arrow_up</Icon>
                    </SeriesReorderButton>
                    <SeriesReorderButton onClick={() => onReorder(1, series)}>
                        <Icon>keyboard_arrow_down</Icon>
                    </SeriesReorderButton>
                </SeriesReorderButtons>
                <SeriesContainer className="series-container">
                    <TextField
                        value={seriesName}
                        onBlur={value => {
                            series.name = value;
                            element.saveModel();
                        }}
                        InputProps={{ disableUnderline: true }}
                    />
                    <FlexSpacer />

                    <ImagePopup value={seriesType}
                        onChange={type => {
                            series.type = type;
                            element.saveModel();
                        }}
                        previewSize={30} size={40} border={false} showArrow={false}
                    >
                        < ImageOption value="line" label="Line">
                            <StaticImage src="/images/ui/charts/line.svg" />
                        </ImageOption>
                        <ImageOption value="column" label="Column">
                            <StaticImage src="/images/ui/charts/column.svg" />
                        </ImageOption>
                        <ImageOption value="area" label="Area">
                            <StaticImage src="/images/ui/charts/area.svg" />
                        </ImageOption>
                        <ImageOption value="spline" label="Spline">
                            <StaticImage src="/images/ui/charts/spline.svg" />
                        </ImageOption>
                        <ImageOption value="areaspline" label="Area Spline">
                            <StaticImage src="/images/ui/charts/areaspline.svg" />
                        </ImageOption>
                    </ImagePopup>
                    <ColorPicker
                        value={seriesColorProps.color || seriesColorProps.lineColor}
                        onChange={color => {
                            series.colorName = color;
                            element.saveModel();
                        }}
                        showChartColors
                        showColorPicker
                        disableAlpha
                    />
                    <Popup icon="more_vert">
                        <PopupContent>
                            {closePopup => (
                                <PopupContainer>
                                    {!["column", "bar"].includes(series.type) &&
                                        <WithLabel label="Line Width">
                                            <NumericStepper value={series.lineWidth || element.styles.series[series.type].lineWidth}
                                                onChange={lineWidth => {
                                                    series.lineWidth = lineWidth;
                                                    element.saveModel();
                                                }}
                                                min={1} max={25} />
                                        </WithLabel>
                                    }
                                    <WithLabel label="Data Labels">
                                        <ToggleSwitch value={series.showDataLabels}
                                            onChange={value => {
                                                series.showDataLabels = value;
                                                element.saveModel();
                                            }}
                                        />
                                    </WithLabel>
                                    {!["bar"].includes(series.type) && <WithLabel label="Data Markers">
                                        <ImagePopup value={series.marker} border={true} previewSize={30} size={30} gap={30}
                                            onChange={marker => {
                                                series.marker = marker;
                                                element.saveModel();
                                            }}
                                        >
                                            <ImageOption value="none" label="None">
                                                <StaticImage src="/images/ui/icons/chart_markers/marker-none.svg" />
                                            </ImageOption>
                                            <ImageOption value="circle" label="Circle">
                                                <StaticImage src="/images/ui/icons/chart_markers/marker-circle.svg" />
                                            </ImageOption>
                                            <ImageOption value="square" label="Square">
                                                <StaticImage src="/images/ui/icons/chart_markers/marker-square.svg" />
                                            </ImageOption>
                                            <ImageOption value="triangle" label="Up">
                                                <StaticImage src="/images/ui/icons/chart_markers/marker-up.svg" />
                                            </ImageOption>
                                            <ImageOption value="triangle-down" label="Down">
                                                <StaticImage src="/images/ui/icons/chart_markers/marker-down.svg" />
                                            </ImageOption>
                                            <ImageOption value="diamond" label="Diamond">
                                                <StaticImage src="/images/ui/icons/chart_markers/marker-diamond.svg" />
                                            </ImageOption>
                                        </ImagePopup>
                                    </WithLabel>}
                                    <Divider />
                                    <WithLabel label="Plot on Right Axis">
                                        <ToggleSwitch value={series.yAxis === 1}
                                            onChange={value => {
                                                series.yAxis = value ? 1 : 0;
                                                element.saveModel();
                                            }}
                                        />
                                    </WithLabel>
                                    {
                                        element.series.length > 1 && (
                                            <>
                                                <Divider />
                                                <Button warning onClick={() => {
                                                    onDelete(series);
                                                    closePopup();
                                                }}>Delete Series</Button>
                                            </>
                                        )
                                    }
                                </PopupContainer>
                            )}
                        </PopupContent>
                    </Popup>
                </SeriesContainer>
            </SeriesOuterContainer>
        );
    }
}


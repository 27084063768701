import { _ } from "js/vendor";

const validWeights = [1, 2, 3, 4, 5];

const emptyWordGroups = { 0: [], 1: [], 2: [], 3: [], 4: [], 5: [] };

export function elementToWordGroups(element) {
    return element?.wordList ? _.groupBy(element?.wordList, item=> item.weight) : emptyWordGroups;
}

function wrapQuotedWords(str) {
    return str.includes(" ") ? `"${str}"` : str;
}

export function wordGroupsToStrings(wordGroups) {
    return validWeights.map(weight => {
        return wordGroups[weight]?.map(({ word })=> wrapQuotedWords(word)).reverse().join(" ");
    });
}

function sliceToString(words, start = null, end = null) {
    if (!start) {
        return words.slice(start, end).reverse().join(" ");
    } else if (start && end) {
        return words.slice(start, end).reverse().join(" ");
    } else if (!end) {
        return words.slice(start).reverse().join(" ");
    }
}

export function aiCalcBreakpoints(wordCount) {
    if (wordCount === 0) {
        return [0, 0, 0, 0];
    } else if (wordCount <= 10) {
        return [1, 2, 3, 6];
    } else if (wordCount <= 20) {
        return [1, 3, 7, 12];
    } else if (wordCount <= 30) {
        return [1, 4, 9, 18];
    } else if (wordCount <= 40) {
        return [2, 5, 12, 22];
    } else if (wordCount <= 50) {
        return [2, 6, 12, 25];
    } else if (wordCount <= 60) {
        return [2, 6, 13, 25];
    } else if (wordCount <= 70) {
        return [2, 6, 13, 30];
    } else {
        return [2, 6, 13, 35];
    }
}

export function aiResultsToWordStrings(words, breakpoints) {
    return [
        ...[],

        sliceToString(words, null, breakpoints[0]),
        sliceToString(words, breakpoints[0], breakpoints[1]),
        sliceToString(words, breakpoints[1], breakpoints[2]),
        sliceToString(words, breakpoints[2], breakpoints[3]),
        sliceToString(words, breakpoints[3])
    ].reverse();
}

export function wordStringsToWordList(wordStrings) {
    let newWordList = [];

    for (let i = 0; i < validWeights.length; i++) {
        if (wordStrings[i]?.length > 0) {
            // Extract both words and multi-word phases surrounded by quotes
            let matches = wordStrings[i].match(/"[^"]+"|\b\w+\b/g).map(e => e.replace(/^"|"$/g, ""));
            matches.forEach(word=>{
                let weight = (i + 1).toString();
                newWordList.push({ word: word, weight: weight, layout: {} });
            });
        }
    }
    return newWordList.reverse();
}

import React, { Component } from "react";

import { ImageOption, ImageOptionList } from "../../../../../../Components/ImageOptionList";
import { WithLabel } from "../../../../../../Components/WithLabel";
import PresentationEditorController from "../../../../../../editor/PresentationEditor/PresentationEditorController";
import { PropertySection } from "../../../../../../EditorComponents/PropertyPanel";
import { ShowAssetDialog } from "../../../../../../react/views/AddAssets/ShowAssetDialog";
import { InfographicElements, InfographicElementTypes } from "../../../../elements/elements/Dashboard/InfographicManager";
import { ChangeChartType } from "../../ChartEditor/ChartUI";

import { ChangeTableType } from "../../TableUI";

export class InfographicElementPicker extends Component {
    handleAddMedia = async containerElement => {
        const { onChange } = this.props;
        onChange && onChange();

        const model = await ShowAssetDialog();
        await containerElement.updateModel({
            componentType: InfographicElementTypes.TEXT_AND_IMAGE,
            showTitle: false,
            childElement: {
                ...model
            }
        });
        PresentationEditorController.selectionLayerController.setSelectedElements([containerElement]);
    }

    handleSetElement = async (elementType, model) => {
        const { containerElement, onChange, selectionLayerController } = this.props;
        onChange && onChange();

        await selectionLayerController.setFreezeSelectionLayer(true);

        try {
            switch (model.componentType) {
                case InfographicElementTypes.CHART:
                case InfographicElementTypes.PIE_CHART:
                    switch (containerElement.componentType) {
                        case InfographicElementTypes.CHART:
                        case InfographicElementTypes.PIE_CHART:
                        case InfographicElementTypes.TABLE:
                            await ChangeChartType(containerElement.childElement, elementType);
                            return;
                    }
                    break;
                case InfographicElementTypes.TABLE:
                    switch (containerElement.childElement.type) {
                        case InfographicElementTypes.CHART:
                        case InfographicElementTypes.PIE_CHART:
                            ChangeTableType(containerElement.childElement, model);
                    }
                    break;
            }

            await containerElement.updateModel(model, { refreshStyles: true });
            await containerElement.canvas.selectionLayerController.setSelectedElements([containerElement]);
        } finally {
            await selectionLayerController.setFreezeSelectionLayer(false);
        }
    }

    render() {
        const { containerElement, onChange, cols, size } = this.props;

        return (
            <>
                <PropertySection>
                    <WithLabel label="Charts" above left>
                        <ImageOptionList cols={cols} size={size} onChange={value => {
                            this.handleSetElement(value, InfographicElements.findById("chart").getDefaultModel(containerElement, value));
                        }}>
                            <ImageOption value="line" label="Line" url="/images/infographics/chart-line.svg" />
                            <ImageOption value="column" label="Column" url="/images/infographics/chart-column.svg" />
                            <ImageOption value="bar" label="Bar" url="/images/infographics/chart-bar.svg" />
                            <ImageOption value="area" label="Area" url="/images/infographics/chart-area.svg" />
                            <ImageOption value="spline" label="Spline" url="/images/infographics/chart-spline.svg" />
                            <ImageOption value="areaspline" label="Area Spline" url="/images/infographics/chart-area-spline.svg" />
                            <ImageOption value="pie" label="Pie" url="/images/infographics/chart-pie.svg" />
                            <ImageOption value="donut" label="Donut" url="/images/infographics/chart-donut.svg" />
                            <ImageOption value="waterfall" label="Waterfall" url="/images/infographics/chart-waterfall.svg" />
                        </ImageOptionList>
                    </WithLabel>
                </PropertySection>
                <PropertySection>
                    <WithLabel label="Infographics" above left>
                        <ImageOptionList cols={cols} size={size} onChange={value => {
                            this.handleSetElement(value, InfographicElements.findById(value).getDefaultModel(containerElement));
                        }}>
                            <ImageOption value="statistic" label="Statistic" url="/images/infographics/statistic.svg" />
                            <ImageOption value="table" label="Table" url="/images/infographics/table.svg" />
                            <ImageOption value="radial_bar" label="Radial Bar" url="/images/infographics/radial.svg" />
                            <ImageOption value="radial_bar_chart" label="Radial Chart" url="/images/infographics/chart-radial.svg" />
                        </ImageOptionList>
                    </WithLabel>
                </PropertySection>
                <PropertySection>
                    <WithLabel label="Other" above left>
                        <ImageOptionList cols={cols} size={size} onChange={value => {
                            if (value == "media") {
                                this.handleAddMedia(containerElement);
                            } else {
                                this.handleSetElement(value, InfographicElements.findById(value).getDefaultModel(containerElement));
                            }
                        }}>
                            <ImageOption value="text" label="Text" url="/images/infographics/text.svg" />
                            <ImageOption value="media" label="Media" url="/images/infographics/media.svg" />
                            {/*<ImageOption value="canvas" label="Canvas" url="/images/infographics/canvas.svg" />*/}
                        </ImageOptionList>
                    </WithLabel>
                </PropertySection>
            </>
        );
    }
}


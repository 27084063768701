import { HorizontalAlignType, ResizeDirection } from "../../../../../common/constants";
import { defaultDragPositionProps, defaultDragResizeProps } from "../../../../editor/PresentationEditor/DragElementManager";

import { CollectionItemElementSelection } from "./CollectionItemElementSelection";
import { TextElementSelection } from "./TextElementSelection";

export class ContentItemSelection extends CollectionItemElementSelection {
    get dragAsCollectionItem() {
        return false;
    }

    get canDrag() {
        if (this.dragAsCollectionItem) {
            return super.canDrag;
        }

        const { element } = this.props;
        return element.model.canDrag !== false;
    }

    get dragPositionProps() {
        if (this.dragAsCollectionItem) {
            return super.dragPositionProps;
        }

        const { element, canvas } = this.props;

        let dragStartProps;
        return {
            ...defaultDragPositionProps,
            dragOpacity: 1,
            hideFauxElement: true,
            dropTargets: [],
            dragAxes: ["x", "y"],
            drop: () => {
            },
            onDragStart: async () => {
                const parentSize = element.parentElement.calculatedProps.size.clone();

                let x = element.model.x;
                let y = element.model.y;

                if (element.model.snapOptions) {
                    const sourceElement = element.canvas.getElementByUniquePath(element.model.snapOptions.elementId);
                    const snapPoint = sourceElement.getSnapPoint(element);
                    x = snapPoint.x / parentSize.width;
                    y = snapPoint.y / parentSize.height;
                }

                dragStartProps = {
                    x,
                    y,
                    parentSize
                };
            },
            onDrag: async ({ dragOffset }) => {
                const { x, y, parentSize } = dragStartProps;

                const horizontalShift = dragOffset.x / parentSize.width;
                const verticalShift = dragOffset.y / parentSize.height;

                element.model.x = Math.clamp(x + horizontalShift, 0, 1);
                element.model.y = Math.clamp(y + verticalShift, 0, 1);
                element.model.hasBeenDragged = true;

                // If the element has snap options - remove them after it's been moved
                if (element.model.snapOptions) {
                    delete element.model.snapOptions;
                }

                if (element.parentElement.canRefreshElement) {
                    element.parentElement.refreshElement();
                } else {
                    await canvas.refreshCanvas();
                }
            },
            onDragEnd: async () => {
                canvas.markStylesAsDirty();
            }
        };
    }

    get canResize() {
        return true;
    }

    get dragResizeProps() {
        const { element, canvas } = this.props;

        let initialSize;
        return {
            ...defaultDragResizeProps,
            resizeDirections: element.calculatedProps?.textDirection == HorizontalAlignType.LEFT ? [ResizeDirection.LEFT] : [ResizeDirection.RIGHT],
            onDragStart: async () => {
                initialSize = element.userWidth ?? element.bounds.width;
            },
            onDrag: async ({ dragOffset, resizeDirection }) => {
                element.userWidth = initialSize + dragOffset.x * (resizeDirection === ResizeDirection.LEFT ? -1 : 1);

                if (element.canRefreshElement) {
                    element.refreshElement();
                } else {
                    await canvas.refreshCanvas();
                }
            }
        };
    }
}

export class ContentItemTextSelection extends TextElementSelection {
    get canResize() {
        return false;
    }

    get dragResizeProps() {
        const { element, canvas } = this.props;

        let initialSize;
        let contentItem = element.parentElement;
        return {
            ...defaultDragResizeProps,
            resizeDirections: [ResizeDirection.RIGHT],
            onDragStart: async () => {
                initialSize = contentItem.userWidth ?? contentItem.bounds.width;
            },
            onDrag: async ({ dragOffset, resizeDirection }) => {
                contentItem.userWidth = initialSize + dragOffset.x * (resizeDirection === ResizeDirection.LEFT ? -1 : 1);

                if (element.parentElement.canRefreshElement) {
                    element.parentElement.refreshElement();
                } else {
                    await canvas.refreshCanvas();
                }
            }
        };
    }
}


import React, { Component } from "react";
import { MenuItem } from "@material-ui/core";
import { v4 as uuid } from "uuid";

import { PopupContent, Popup, PopupContainer } from "js/Components/Popup";
import ImageVideoDrop from "js/Components/ImageVideoDrop";
import { _ } from "js/vendor";
import { getElementModelUpdatesForAsset } from "common/assetUtils";

import { IconButton, RoundIconButton } from "../../../../Components/IconButton";
import { WithLabel } from "../../../../Components/WithLabel";
import { AbsoluteBox, Box, FlexBox } from "../../../../react/components/LayoutGrid";
import { ToggleIcon, ToggleSwitch } from "../../../../Components/ToggleSwitch";
import { AssetType, AuthoringBlockType, ControlBarPosition, DecorationStyle, TextStyleType } from "../../../../../common/constants";
import { Dropdown } from "../../../../Components/Dropdown";
import { ShowAssetDialog } from "../../../../react/views/AddAssets/ShowAssetDialog";
import { Slider } from "../../../../Components/Slider";

import { DefaultOverlayContainer } from "../ElementDefaultOverlays/Components/DefaultOverlayContainer";
import { PropertySection, PropertySectionHeader } from "../../../../EditorComponents/PropertyPanel";
import { ControlBar } from "../ElementControlBars/Components/ControlBar";
import { MediaPopup } from "../EditorComponents/MediaPopup";
import { ItemColorPicker } from "../EditorComponents/ColorPickers/ItemColorPicker";
import { ImagePopup } from "../../../../Components/ImagePopup";
import { ImageOption } from "../../../../Components/ImageOptionList";
import { MultiInputSlider } from "../../../../Components/legacy-components/AuthoringEditorComponents/MultiInputSlider";
import { BaseElementSelection } from "../ElementSelections/BaseElementSelection";
import { Key } from "js/core/utilities/keys";
import presentationEditorController from "../../../../editor/PresentationEditor/PresentationEditorController";
import { ColorPicker } from "../EditorComponents/ColorPickers/ColorPicker";
import { over } from "lodash";

export class MediaPropertyPanel extends Component {
    handleChangeAttribution = async value => {
        const { element } = this.props;
        const textAndImageElement = element.parentElement;
        if (value) {
            const blockId = uuid();
            textAndImageElement.model.attribution = {
                blocks: [{
                    id: blockId, type: AuthoringBlockType.TEXT, html: element.asset.get("attribution"), textStyle: TextStyleType.BODY
                }]
            };
            await textAndImageElement.canvas.updateCanvasModel();
        } else {
            textAndImageElement.model.attribution = null;
            textAndImageElement.canvas.updateCanvasModel(false);
        }
    }

    handleChangeCaption = async value => {
        const { element } = this.props;

        if (value) {
            const blockId = uuid();
            element.model.caption = {
                blocks: [{
                    id: blockId, type: AuthoringBlockType.TEXT, html: "", textStyle: TextStyleType.CAPTION
                }]
            };
            await element.canvas.updateCanvasModel();
            element.canvas.selectionLayerController.selectTextElementBlock(element.parentElement.caption, blockId);
        } else {
            element.model.caption = null;
            await element.canvas.updateCanvasModel(false);
            element.canvas.selectionLayerController.setSelectedElements([element.parentElement]);
        }
    }

    handleChangeVideoTime = (value, save = true) => {
        const { element: videoElement } = this.props;

        const [
            startTime,
            endTime,
        ] = value;

        if (videoElement) {
            videoElement.updateModel({ assetProps: { ...videoElement.model.assetProps, startTime, endTime } });
        }

        if (save) {
            videoElement.assetElement.togglePlayback({
                value: startTime < endTime,
                refresh: false,
                reset: true,
            });
        }
    };

    handleAdjustImage = () => {
        const { element } = this.props;
        element.canvas.selectionLayerController.setSelectedElements([element.assetElement]);
    }

    render() {
        const { element, showCaption = false, showAttribution = false, onDelete, children } = this.props;

        switch (element.assetType) {
            case AssetType.IMAGE:
            case AssetType.LOGO:
                return (
                    <PropertySection>
                        <PropertySectionHeader label="Image">
                            <FlexBox>
                                <IconButton
                                    icon="add_a_photo"
                                    tooltip="Replace image"
                                    onClick={async () => {
                                        let asset = await ShowAssetDialog();
                                        element.updateModel(asset);
                                    }} />
                                <IconButton
                                    icon="crop"
                                    tooltip="Edit image"
                                    onClick={this.handleAdjustImage}
                                />
                                {/*<IconButton*/}
                                {/*    icon="edit"*/}
                                {/*    tooltip="Edit and filter image"*/}
                                {/*    onClick={() => {*/}
                                {/*        ShowDialog(FilterDialog, { element: element.assetElement });*/}
                                {/*    }} />*/}
                                <IconButton
                                    icon="delete"
                                    tooltip="Remove image"
                                    onClick={() => {
                                        if (onDelete) {
                                            onDelete();
                                        } else {
                                            element.deleteAsset();
                                            delete element.model.caption;
                                            delete element.model.attribution;
                                            delete element.model.kenBurnsEffect;

                                            element.saveModel(false, true);
                                            element.canvas.selectionLayerController.setSelectedElements([element.parentElement]);
                                        }
                                    }}
                                />
                            </FlexBox>
                        </PropertySectionHeader>
                        <WithLabel label="Animation Effect">
                            <ImagePopup value={element.model.kenBurnsEffect ?? "none"} previewSize={30} size={40} border={false}
                                onChange={value => {
                                    element.updateModel({ kenBurnsEffect: value });
                                }}
                            >
                                <ImageOption value="none" label="None" url="/images/ui/kenburns/none.svg" />
                                <ImageOption value="zoom_in" label="Zoom In" url="/images/ui/kenburns/zoom_in.svg" />
                                <ImageOption value="zoom_out" label="Zoom Out" url="/images/ui/kenburns/zoom_out.svg" />
                                <ImageOption value="pan_left" label="Pan Left" url="/images/ui/kenburns/pan_left.svg" />
                                <ImageOption value="pan_right" label="Pan Right" url="/images/ui/kenburns/pan_right.svg" />
                            </ImagePopup>
                        </WithLabel>
                        {showAttribution && (
                            <WithLabel label="Show Attribution">
                                <ToggleSwitch icon="attribution"
                                    value={element.parentElement.showAttribution}
                                    onChange={this.handleChangeAttribution}
                                />
                            </WithLabel>
                        )}
                        {children}
                    </PropertySection>
                );
            case AssetType.VIDEO:
            case AssetType.STOCK_VIDEO:
                return (
                    <>
                        <PropertySection>
                            <PropertySectionHeader label="Video">
                                <FlexBox gap={10}>
                                    <IconButton icon="add_a_photo"
                                        onClick={async () => {
                                            let asset = await ShowAssetDialog();
                                            //TODO figure out what this does
                                            // asset.position = element.image.model.position;
                                            element.updateModel(asset);
                                        }} />
                                    <IconButton icon="crop"
                                        onClick={() => {
                                            element.canvas.selectionLayerController.setSelectedElements([element.assetElement]);
                                        }} />
                                    <IconButton icon="delete"
                                        onClick={() => {
                                            element.deleteAsset();
                                            element.saveModel(false, true);
                                        }}
                                    />
                                </FlexBox>
                            </PropertySectionHeader>

                            {this.props.children}
                            <FlexBox left gap={10}>
                                <WithLabel label="Loop" below center small>
                                    <ToggleIcon icon="repeat" value={element.model.assetProps.loop}
                                        onChange={async value => {
                                            await element.updateModel({ assetProps: { ...element.model.assetProps, loop: value } });
                                            element.assetElement?.playFromStart();
                                        }}
                                    />
                                </WithLabel>
                                <WithLabel label="Audio" below center small>
                                    <ToggleIcon icon="volume_up" iconOff="volume_off" value={!element.model.assetProps.muted}
                                        onChange={value => element.updateModel({ assetProps: { ...element.model.assetProps, muted: !value } })}
                                    />
                                </WithLabel>
                                <WithLabel label="Speed" below center small>
                                    <Dropdown
                                        overrideStyledSelect={{
                                            height: "32px",
                                        }}
                                        value={element.model.assetProps.speed}
                                        onChange={value => element.updateModel({ assetProps: { ...element.model.assetProps, speed: value } })}
                                    >
                                        <MenuItem value={0.25}>0.25x</MenuItem>
                                        <MenuItem value={0.5}>0.5x</MenuItem>
                                        <MenuItem value={1}>1x</MenuItem>
                                        <MenuItem value={1.5}>1.5x</MenuItem>
                                        <MenuItem value={2}>2x</MenuItem>
                                    </Dropdown>
                                </WithLabel>
                                <WithLabel label="Trim Video" below center small>
                                    <Popup icon="av_timer" value={true} overrideContainerStyles={{
                                        width: "32px",
                                        height: "32px",
                                        borderRadius: "2px",
                                        background: "#ccc",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center"
                                    }}>
                                        <PopupContent>
                                            {closePopup => (
                                                <PopupContainer width="180">
                                                    <MultiInputSlider
                                                        styles={{
                                                            height: "65px",
                                                            overflow: "hidden"
                                                        }}
                                                        value={[
                                                            element.model.assetProps?.startTime || 0,
                                                            element.model.assetProps?.endTime || element.model.assetProps?.duration,
                                                        ]}
                                                        multiValue
                                                        onChange={values => this.handleChangeVideoTime(values, false)}
                                                        onChangeCommitted={values => this.handleChangeVideoTime(values)}
                                                        sliderMin={0}
                                                        sliderMax={element.model.assetProps?.duration}
                                                        inputMin={0}
                                                        inputMax={element.model.assetProps?.duration}
                                                        step={1}
                                                    />
                                                </PopupContainer>
                                            )}
                                        </PopupContent>
                                    </Popup>
                                </WithLabel>
                            </FlexBox>
                            {showAttribution && (
                                <WithLabel label="Show Attribution" left>
                                    <ToggleSwitch value={element.parentElement.showAttribution}
                                        onChange={this.handleChangeAttribution}
                                    />
                                </WithLabel>
                            )}
                        </PropertySection>
                    </>
                );
            case AssetType.ICON:
                let iconColor = element.model.iconColor ||
                    (element.isOnAuthoringCanvas ? "primary" : "theme");

                return (
                    <PropertySection>
                        <PropertySectionHeader label="Icon">
                            <FlexBox gap={10}>
                                <IconButton icon="add_a_photo"
                                    onClick={async () => {
                                        let asset = await ShowAssetDialog(AssetType.ICON);
                                        element.updateModel(asset);
                                    }} />
                                <IconButton icon="delete"
                                    onClick={() => {
                                        element.deleteAsset();
                                        element.saveModel(true);
                                    }}
                                />
                            </FlexBox>
                        </PropertySectionHeader>
                        <WithLabel label="Icon Scale">
                            <Slider value={element.model.iconScale ?? 1}
                                showInput percent
                                min={0.1} max={2} step={0.05}
                                onChange={value => element.refreshModel({ iconScale: value })}
                                onCommit={() => element.saveModel()}
                            />
                        </WithLabel>
                        <WithLabel label="Icon Color">
                            <ColorPicker value={iconColor}
                                backgroundColor={element.getBackgroundColor()}
                                onChange={value => element.updateModel({ iconColor: value })}
                                canvas={element.canvas}
                                showBackgroundColors
                                showColorPicker={element.isOnAuthoringCanvas}
                                allowColorOnColor={element.isOnAuthoringCanvas}
                                showPrimary={element.isOnAuthoringCanvas}
                            />
                        </WithLabel>
                        {this.props.children}
                    </PropertySection>
                );
        }
    }
}

export class ContentElementDefaultOverlay extends Component {
    state = {
        scale: 1
    }

    handleAddMedia = async () => {
        const { element } = this.props;
        let asset = await ShowAssetDialog(element.defaultAssetType);
        element.updateModel(asset);
        presentationEditorController.selectionLayerController.setSelectedElements([element.assetElement]);
    }

    constructor() {
        super();
    }

    calcScale() {
        if (this.props.containerBounds.width < 50 || this.props.containerBounds.height < 50) {
            return Math.min(1, Math.min(this.props.containerBounds.width, this.props.containerBounds.height) / 50) * .85;
        } else {
            return 1;
        }
    }

    getElementModelUpdatesForAsset = async asset => {
        const { element } = this.props;
        const updates = getElementModelUpdatesForAsset(asset);
        element.assetElement.updateModel({ ...element.assetElement.model, ...updates });
    }

    render() {
        const { element } = this.props;

        let styles = {};
        let frame = element.decoration?.styles?.shape;
        if (frame == "theme") {
            frame = element.canvas.getTheme().get("styleShape");
        }

        let backShape;
        if (frame == "circle" || frame === "octagon") {
            styles = {
                borderRadius: "50%",
                background: "none"
            };
            backShape = (
                <AbsoluteBox style={{ width: "100%", height: "100%", zIndex: -1 }}>
                    <svg width="100%" height="100%" viewBox="0 0 100 100">
                        <circle cx="50%" cy="50%" r="50" fill="none" />
                    </svg>
                </AbsoluteBox>
            );
        } else if (frame == "rounded_rect") {
            styles = {
                borderRadius: "10px"
            };
        }

        return (
            <ImageVideoDrop callback={this.getElementModelUpdatesForAsset} imageAsLogo={element.assetElement.type === AssetType.ICON}>
                <DefaultOverlayContainer outline={!!element.assetElement.asset} style={styles} background={element.shapeStyle === "none"}>
                    {backShape}
                    <Box scale={this.calcScale()}>
                        <RoundIconButton icon="add_a_photo" onClick={this.handleAddMedia} />
                    </Box>
                </DefaultOverlayContainer>
            </ImageVideoDrop>
        );
    }
}

export class ContentElementControlBar extends Component {
    render() {
        const { element } = this.props;

        if ([AssetType.IMAGE, AssetType.ICON, AssetType.VIDEO, AssetType.STOCK_VIDEO].includes(element.assetType)) {
            const elementHeight = element.calculatedProps?.bounds?.height ?? Number.POSITIVE_INFINITY;
            const controlBarPosition = elementHeight < 150 ? ControlBarPosition.BELOW : ControlBarPosition.INNER_BOTTOM;

            return (
                <ControlBar position={controlBarPosition}>
                    <MediaPopup element={element} />
                </ControlBar>
            );
        }

        return null;
    }
}

export class ContentElementSelection extends BaseElementSelection {
    get deleteButtonPosition() {
        return "corner";
    }

    handleDeleteElement = () => {
        const { element, selectionLayerController } = this.props;
        const collectionElement = element.findClosestOfType("CollectionElement");

        selectionLayerController.setSelectedElements([]);

        collectionElement.deleteItem(element.findClosestOfType("CollectionItemElement").id);
        collectionElement.updateModel({});
    }

    handleKeyboardShortcut = async event => {
        if ([Key.DELETE, Key.BACKSPACE].includes(event.which)) {
            this.handleDeleteElement();
        }
    }
}

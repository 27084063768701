import React, { Component } from "react";

import { ImageOption, ImageOptionList } from "../../../../Components/ImageOptionList";
import { StaticImage } from "../../../../Components/StaticImage";
import { ToggleSwitch } from "../../../../Components/ToggleSwitch";
import { WithLabel } from "../../../../Components/WithLabel";
import BadFitDialog from "../../../../react/components/Dialogs/BadFitDialog";
import { ShowDialog } from "../../../../react/components/Dialogs/BaseDialog";

import { Popup, PopupContainer, PopupContent } from "../../../../Components/Popup";
import { CollectionElementControlBar } from "../ElementControlBars/CollectionElementControlBar";
import { ControlBar } from "../ElementControlBars/Components/ControlBar";
import { ItemColorPicker } from "../EditorComponents/ColorPickers/ItemColorPicker";
import { PropertyPanelContainer, PropertySection } from "../../../../EditorComponents/PropertyPanel";
import { CollectionColorPicker } from "../EditorComponents/ColorPickers/CollectionColorPicker";

export class BigNumberPropertyPanel extends Component {
    render() {
        const { element } = this.props;

        return (
            <PropertyPanelContainer>
                <PropertySection>
                    <WithLabel label="Color">
                        <CollectionColorPicker element={element} />
                    </WithLabel>
                </PropertySection>
                <PropertySection>
                    <WithLabel label="Layout" above left>
                        <ImageOptionList
                            cols={3}
                            value={element.model.layout}
                            onChange={value => element.updateModel({ layout: value }, { transition: true })}
                        >
                            <ImageOption value="fill" label="Fill">
                                <StaticImage src="/images/ui/big-numbers-align-justify.svg" />
                            </ImageOption>
                            <ImageOption value="fit" label="Fit">
                                <StaticImage src="/images/ui/big-numbers-align-center.svg" />
                            </ImageOption>
                        </ImageOptionList>
                    </WithLabel>
                </PropertySection>
                <PropertySection>
                    <WithLabel label="Show Labels">
                        <ToggleSwitch
                            value={element.getChildValues("showLabel")}
                            onChange={value => element.updateChildModels({ showLabel: value })}
                        />
                    </WithLabel>
                    <WithLabel label="Show Change in Value">
                        <ToggleSwitch
                            value={element.getChildValues("showChangeInValue")}
                            onChange={value => element.updateChildModels({ showChangeInValue: value })}
                        />
                    </WithLabel>
                    <WithLabel label="Show Descriptions">
                        <ToggleSwitch
                            value={element.getChildValues("showDescription")}
                            onChange={value => element.updateChildModels({ showDescription: value })}
                        />
                    </WithLabel>
                </PropertySection>
            </PropertyPanelContainer>
        );
    }
}

export class BigNumberItemControlBar extends Component {
    render() {
        const { element } = this.props;

        return (
            <ControlBar>
                <ItemColorPicker element={element} />
                <Popup icon="settings">
                    <PopupContent>
                        <PopupContainer>
                            <WithLabel label="Show Label" tight={false}>
                                <ToggleSwitch
                                    value={element.model.showLabel}
                                    onChange={value => element.updateModel({ showLabel: value })}
                                />
                            </WithLabel>
                            <WithLabel label="Show Change in Value" tight={false}>
                                <ToggleSwitch
                                    value={element.model.showChangeInValue}
                                    onChange={value => element.updateModel({ showChangeInValue: value })}
                                />
                            </WithLabel>
                            <WithLabel label="Show Description" tight={false}>
                                <ToggleSwitch
                                    value={element.model.showDescription}
                                    onChange={value => element.updateModel({ showDescription: value })}
                                />
                            </WithLabel>
                        </PopupContainer>
                    </PopupContent>
                </Popup>
            </ControlBar>
        );
    }
}

export class BigNumbersControlBar extends CollectionElementControlBar {
    onAddNewItem = async () => {
        const { element, selectionLayerController, selectedElements } = this.props;

        if (!this.canAddNewItem) {
            return;
        }

        const currentSelection = [...selectedElements];
        selectionLayerController.setSelectedElements([]);

        const selectNewElement = async id => {
            const newElement = element.elements[id];
            if (newElement) {
                await selectionLayerController.setSelectedElements([newElement]);
            }
        };

        try {
            const { id } = element.addItem();
            await element.canvas.updateCanvasModel(false);
            await selectNewElement(id);
        } catch (err) {
            try {
                const elementModel = element.addItem();
                elementModel.row++;
                await element.canvas.updateCanvasModel(false);
                await selectNewElement(elementModel.id);
            } catch (err) {
                selectionLayerController.setSelectedElements(currentSelection);

                ShowDialog(BadFitDialog, {
                    title: "Sorry, we aren't able to fit another item to this layout",
                });
            }
        }
    }
}

const lodash = require("lodash");

const { HeaderPositionType, TrayElementType, TrayType } = require("../../constants");

const DEFAULT_DATA = {
    layout: {
        headerPosition: HeaderPositionType.TOP,
        showFooter: "theme",
    },
    header: {},
    primary: {},
};

function mergeDefaultData(defaultData, overrides) {
    if (defaultData && overrides) {
        return Object.assign(lodash.cloneDeep(defaultData), lodash.cloneDeep(overrides));
    } else if (overrides === null) { // override being set to null means to remove the default.
        return null;
    } else if (overrides) {
        return lodash.cloneDeep(overrides);
    } else if (defaultData) {
        return lodash.cloneDeep(defaultData);
    } else {
        return null;
    }
}

class BaseSlideTemplate {
    constructor() {
        this.version = 1;
        this.migrate = null;

        // layout permissions
        this.allowLayouts = true;
        this.allowHeader = true;
        this.allowFooter = true;
        this.preventDefaultFooter = false;

        this.allowTheming = true;

        this.allowAnimations = true;

        this.canSwitchTemplate = true;

        this.allowCallouts = true;
        this.allowAuthoring = false;
        this.allowElementTextInline = true;
        this.allowElementTextTray = true;
        this.allowElementAttribution = true;
        this.availableHeaderPositions = [HeaderPositionType.TOP, HeaderPositionType.LEFT];

        // allow colored background
        this.allowColorBackgrounds = true;
        this.allowColorful = true;

        this.allowBackgroundImage = true;

        this.availableTrayLayouts = [TrayType.LEFT_TRAY, TrayType.LEFT_INLINE, TrayType.RIGHT_TRAY, TrayType.RIGHT_INLINE];

        this.availableTrayElements = [TrayElementType.IMAGE, TrayElementType.HEADLINE, TrayElementType.TEXT, TrayElementType.BULLET_LIST, TrayElementType.ICON_LIST, TrayElementType.NUMBERED_LIST];

        this.defaultTrayLayout = null;
        this.defaultTrayElement = null;
        this.defaultTrayBackgroundColor = "primary";

        this.defaultImageTrayType = TrayType.RIGHT_INLINE;

        this.canConvertToAuthoring = true;

        this.defaultData = {};
    }

    generateSlideData() {
        const layout = mergeDefaultData(DEFAULT_DATA.layout, this.defaultData.layout);

        const header = mergeDefaultData(DEFAULT_DATA.header, this.defaultData.header);
        let primary = mergeDefaultData(DEFAULT_DATA.primary, this.defaultData.primary);
        const tray = mergeDefaultData(DEFAULT_DATA.tray, this.defaultData.tray);

        const state = {};
        if (header) {
            state.header = header;
        }
        if (primary) {
            state.primary = primary;
        }
        if (tray) {
            state.tray = tray;
        }

        return {
            template_version: this.version,
            layout: layout || {},
            states: [state],
            version: 10,
            migrationVersion: 10.02
        };
    }
}

module.exports = BaseSlideTemplate;

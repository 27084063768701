export const HeadlineLayouts = [
    {
        // 1-chart
        cells: [{
            x: 0, y: 0, width: 12, height: 12
        }],
        favorite: true
    },
    {
        // 2-charts-horizontal
        cells: [{
            x: 0, y: 0, width: 6, height: 12
        }, {
            x: 6, y: 0, width: 6, height: 12
        }],
        favorite: true
    },
    {
        // 2-charts-vertical
        cells: [{
            x: 0, y: 0, width: 12, height: 6
        }, {
            x: 0, y: 6, width: 12, height: 6
        }],
        favorite: true
    }
];

export const DashboardLayouts = [
    {
        // 1-chart
        cells: [{
            x: 0, y: 0, width: 12, height: 12
        }],
        favorite: true
    },
    {
        // 2-charts-horizontal
        cells: [{
            x: 0, y: 0, width: 6, height: 12
        }, {
            x: 6, y: 0, width: 6, height: 12
        }],
        favorite: true
    },
    {
        // 2-charts-vertical
        cells: [{
            x: 0, y: 0, width: 12, height: 6
        }, {
            x: 0, y: 6, width: 12, height: 6
        }],
        favorite: true
    },
    {
        // 3-charts-left
        cells: [{
            x: 0, y: 0, width: 6, height: 12
        }, {
            x: 6, y: 0, width: 6, height: 6
        }, {
            x: 6, y: 6, width: 6, height: 6
        }],
        favorite: true
    },
    {
        // 3-charts-right
        cells: [{
            x: 0, y: 0, width: 6, height: 6
        }, {
            x: 0, y: 6, width: 6, height: 6
        }, {
            x: 6, y: 0, width: 6, height: 12
        }],
    },
    {
        // 6-charts-top
        cells: [{
            x: 0, y: 0, width: 12, height: 6
        }, {
            x: 0, y: 6, width: 6, height: 6
        }, {
            x: 6, y: 6, width: 6, height: 6
        }],
        favorite: true
    },
    {
        // 3-charts-horizontal
        cells: [{
            x: 0, y: 0, width: 4, height: 12
        }, {
            x: 4, y: 0, width: 4, height: 12
        }, {
            x: 8, y: 0, width: 4, height: 12
        }],
    },
    {
        // 4-charts
        cells: [{
            x: 0, y: 0, width: 6, height: 6
        }, {
            x: 0, y: 6, width: 6, height: 6
        }, {
            x: 6, y: 0, width: 6, height: 6
        }, {
            x: 6, y: 6, width: 6, height: 6
        }],
        favorite: true
    },
    {
        cells: [{
            x: 0, y: 0, width: 4, height: 4
        }, {
            x: 0, y: 4, width: 4, height: 4
        }, {
            x: 0, y: 8, width: 4, height: 4
        }, {
            x: 4, y: 0, width: 8, height: 12
        }]
    },
    {
        cells: [{
            x: 0, y: 0, width: 8, height: 12
        }, {
            x: 8, y: 0, width: 4, height: 4
        }, {
            x: 8, y: 4, width: 4, height: 4
        }, {
            x: 8, y: 8, width: 4, height: 4
        }],
        favorite: true
    },
    {
        cells: [{
            x: 0, y: 0, width: 4, height: 4
        }, {
            x: 4, y: 0, width: 4, height: 4
        }, {
            x: 8, y: 0, width: 4, height: 4
        }, {
            x: 0, y: 4, width: 12, height: 8
        }],
        favorite: true
    },
    {
        cells: [{
            x: 0, y: 0, width: 12, height: 8
        }, {
            x: 0, y: 8, width: 3, height: 4
        }, {
            x: 3, y: 8, width: 3, height: 4
        }, {
            x: 6, y: 8, width: 3, height: 4
        }, {
            x: 9, y: 8, width: 3, height: 4
        }]
    },
    {
        // six cells
        cells: [{
            x: 0, y: 0, width: 4, height: 6
        }, {
            x: 4, y: 0, width: 4, height: 6
        }, {
            x: 8, y: 0, width: 4, height: 6
        }, {
            x: 0, y: 6, width: 4, height: 6
        }, {
            x: 4, y: 6, width: 4, height: 6
        }, {
            x: 8, y: 6, width: 4, height: 6
        }]
    },
    {
        // seven cells
        cells: [{
            x: 0, y: 0, width: 6, height: 12
        }, {
            x: 6, y: 0, width: 2, height: 6
        }, {
            x: 8, y: 0, width: 2, height: 6
        }, {
            x: 10, y: 0, width: 2, height: 6
        }, {
            x: 6, y: 6, width: 2, height: 6
        }, {
            x: 8, y: 6, width: 2, height: 6
        }, {
            x: 10, y: 6, width: 2, height: 6
        }]
    },
    {
        // eight cells
        cells: [{
            x: 0, y: 0, width: 3, height: 6
        }, {
            x: 3, y: 0, width: 3, height: 6
        }, {
            x: 6, y: 0, width: 3, height: 6
        }, {
            x: 9, y: 0, width: 3, height: 6
        }, {
            x: 0, y: 6, width: 3, height: 6
        }, {
            x: 3, y: 6, width: 3, height: 6
        }, {
            x: 6, y: 6, width: 3, height: 6
        }, {
            x: 9, y: 6, width: 3, height: 6
        }]
    }
];

export const PhotoCollageLayouts = [
    // one cell
    {
        cells: [{ x: 0, y: 0, width: 12, height: 12 }],
        favorite: true
    },
    // two cells
    {
        cells: [
            { x: 0, y: 0, width: 6, height: 12 },
            { x: 6, y: 0, width: 6, height: 12 }
        ],
        favorite: true
    },
    {
        cells: [
            { x: 0, y: 0, width: 12, height: 6 },
            { x: 0, y: 6, width: 12, height: 6 }
        ],
        favorite: true
    },
    {
        cells: [
            { x: 0, y: 0, width: 8, height: 12 },
            { x: 8, y: 0, width: 4, height: 12 }
        ],
        favorite: false
    },
    {
        cells: [
            { x: 0, y: 0, width: 4, height: 12 },
            { x: 4, y: 0, width: 8, height: 12 }
        ],
        favorite: false
    },
    // three cells
    {
        cells: [
            { x: 0, y: 0, width: 6, height: 12 },
            { x: 6, y: 0, width: 6, height: 6 },
            { x: 6, y: 6, width: 6, height: 6 }
        ],
        favorite: true
    },
    {
        cells: [
            { x: 0, y: 0, width: 4, height: 12 },
            { x: 4, y: 0, width: 4, height: 12 },
            { x: 8, y: 0, width: 4, height: 12 }
        ],
        favorite: true
    },
    {
        cells: [
            { x: 0, y: 0, width: 6, height: 6 },
            { x: 0, y: 6, width: 6, height: 6 },
            { x: 6, y: 0, width: 6, height: 12 }
        ],
        favorite: true
    },
    {
        cells: [
            { x: 0, y: 0, width: 12, height: 6 },
            { x: 0, y: 6, width: 6, height: 6 },
            { x: 6, y: 6, width: 6, height: 6 }
        ],
        favorite: true
    },
    {
        cells: [
            { x: 0, y: 0, width: 6, height: 6 },
            { x: 6, y: 0, width: 6, height: 6 },
            { x: 0, y: 6, width: 12, height: 6 }
        ],
        favorite: false
    },
    {
        cells: [
            { x: 0, y: 0, width: 12, height: 4 },
            { x: 0, y: 4, width: 12, height: 4 },
            { x: 0, y: 8, width: 12, height: 4 }
        ],
        favorite: false
    },
    // four cells
    {
        cells: [
            { x: 0, y: 0, width: 6, height: 6 },
            { x: 6, y: 0, width: 6, height: 6 },
            { x: 0, y: 6, width: 6, height: 6 },
            { x: 6, y: 6, width: 6, height: 6 }
        ],
        favorite: true
    },
    {
        cells: [
            { x: 0, y: 0, width: 6, height: 12 },
            { x: 6, y: 0, width: 6, height: 4 },
            { x: 6, y: 4, width: 6, height: 4 },
            { x: 6, y: 8, width: 6, height: 4 }
        ],
        favorite: false
    },
    {
        cells: [
            { x: 0, y: 0, width: 8, height: 6 },
            { x: 8, y: 0, width: 4, height: 6 },
            { x: 0, y: 6, width: 4, height: 6 },
            { x: 4, y: 6, width: 8, height: 6 }
        ],
        favorite: true
    },
    {
        cells: [
            { x: 0, y: 0, width: 4, height: 6 },
            { x: 4, y: 0, width: 8, height: 6 },
            { x: 0, y: 6, width: 8, height: 6 },
            { x: 8, y: 6, width: 4, height: 6 }
        ],
        favorite: false
    },
    {
        cells: [
            { x: 0, y: 0, width: 8, height: 6 },
            { x: 8, y: 0, width: 4, height: 6 },
            { x: 0, y: 6, width: 8, height: 6 },
            { x: 8, y: 6, width: 4, height: 6 }
        ]
    },
    {
        cells: [
            { x: 0, y: 0, width: 4, height: 6 },
            { x: 4, y: 0, width: 8, height: 6 },
            { x: 0, y: 6, width: 4, height: 6 },
            { x: 4, y: 6, width: 8, height: 6 }
        ],
        favorite: false
    },
    {
        cells: [
            { x: 0, y: 0, width: 12, height: 6 },
            { x: 0, y: 6, width: 4, height: 6 },
            { x: 4, y: 6, width: 4, height: 6 },
            { x: 8, y: 6, width: 4, height: 6 }
        ],
        favorite: true
    },
    {
        cells: [
            { x: 0, y: 0, width: 4, height: 6 },
            { x: 4, y: 0, width: 4, height: 6 },
            { x: 8, y: 0, width: 4, height: 6 },
            { x: 0, y: 6, width: 12, height: 6 }
        ]
    },
    {
        cells: [
            { x: 0, y: 0, width: 3, height: 12 },
            { x: 3, y: 0, width: 3, height: 12 },
            { x: 6, y: 0, width: 3, height: 12 },
            { x: 9, y: 0, width: 3, height: 12 },
        ]
    },
    {
        cells: [
            { x: 0, y: 0, width: 12, height: 3 },
            { x: 0, y: 3, width: 12, height: 3 },
            { x: 0, y: 6, width: 12, height: 3 },
            { x: 0, y: 9, width: 12, height: 3 }
        ]
    },
    // five cells
    {
        cells: [
            { x: 0, y: 0, width: 8, height: 6 },
            { x: 8, y: 0, width: 4, height: 6 },
            { x: 0, y: 6, width: 4, height: 6 },
            { x: 4, y: 6, width: 4, height: 6 },
            { x: 8, y: 6, width: 4, height: 6 }
        ]
    },
    {
        cells: [
            { x: 0, y: 0, width: 4, height: 6 },
            { x: 4, y: 0, width: 4, height: 6 },
            { x: 8, y: 0, width: 4, height: 6 },
            { x: 0, y: 6, width: 4, height: 6 },
            { x: 4, y: 6, width: 8, height: 6 }
        ]
    },
    {
        cells: [
            { x: 0, y: 0, width: 4, height: 12 },
            { x: 4, y: 0, width: 4, height: 6 },
            { x: 8, y: 0, width: 4, height: 6 },
            { x: 4, y: 6, width: 4, height: 6 },
            { x: 8, y: 6, width: 4, height: 6 }
        ],
        favorite: true
    },
    {
        cells: [
            { x: 0, y: 0, width: 4, height: 6 },
            { x: 0, y: 6, width: 4, height: 6 },
            { x: 4, y: 0, width: 4, height: 12 },
            { x: 8, y: 0, width: 4, height: 6 },
            { x: 8, y: 6, width: 4, height: 6 }
        ]
    },
    {
        cells: [
            { x: 0, y: 0, width: 4, height: 6 },
            { x: 0, y: 6, width: 4, height: 6 },
            { x: 4, y: 0, width: 4, height: 6 },
            { x: 4, y: 6, width: 4, height: 6 },
            { x: 8, y: 0, width: 4, height: 12 }
        ],
        favorite: false
    },
    {
        cells: [
            { x: 0, y: 0, width: 12, height: 6 },
            { x: 0, y: 6, width: 3, height: 6 },
            { x: 3, y: 6, width: 3, height: 6 },
            { x: 6, y: 6, width: 3, height: 6 },
            { x: 9, y: 6, width: 3, height: 6 }
        ]
    },
    {
        cells: [
            { x: 0, y: 0, width: 3, height: 6 },
            { x: 3, y: 0, width: 3, height: 6 },
            { x: 6, y: 0, width: 3, height: 6 },
            { x: 9, y: 0, width: 3, height: 6 },
            { x: 0, y: 6, width: 12, height: 6 }
        ]
    },
    {
        cells: [
            { x: 0, y: 0, width: 6, height: 12 },
            { x: 6, y: 0, width: 3, height: 6 },
            { x: 9, y: 0, width: 3, height: 6 },
            { x: 6, y: 6, width: 3, height: 6 },
            { x: 9, y: 6, width: 3, height: 6 }
        ]
    },
    {
        cells: [
            { x: 0, y: 0, width: 3, height: 6 },
            { x: 3, y: 0, width: 3, height: 6 },
            { x: 0, y: 6, width: 3, height: 6 },
            { x: 3, y: 6, width: 3, height: 6 },
            { x: 6, y: 0, width: 6, height: 12 },
        ]
    },
    {
        cells: [
            { x: 0, y: 0, width: 3, height: 6 },
            { x: 0, y: 6, width: 3, height: 6 },
            { x: 3, y: 0, width: 6, height: 12 },
            { x: 9, y: 0, width: 3, height: 6 },
            { x: 9, y: 6, width: 3, height: 6 }
        ]
    },
    {
        cells: [
            { x: 0, y: 0, width: 4, height: 6 },
            { x: 4, y: 0, width: 4, height: 6 },
            { x: 8, y: 0, width: 4, height: 6 },
            { x: 0, y: 6, width: 6, height: 6 },
            { x: 6, y: 6, width: 6, height: 6 }
        ]
    },
    {
        cells: [
            { x: 0, y: 0, width: 6, height: 6 },
            { x: 6, y: 0, width: 6, height: 6 },
            { x: 0, y: 6, width: 4, height: 6 },
            { x: 4, y: 6, width: 4, height: 6 },
            { x: 8, y: 6, width: 4, height: 6 },
        ]
    },
    //six cells
    {
        cells: [
            { x: 0, y: 0, width: 4, height: 6 },
            { x: 4, y: 0, width: 4, height: 6 },
            { x: 8, y: 0, width: 4, height: 6 },
            { x: 0, y: 6, width: 4, height: 6 },
            { x: 4, y: 6, width: 4, height: 6 },
            { x: 8, y: 6, width: 4, height: 6 }
        ],
        favorite: true
    },
    {
        cells: [
            { x: 0, y: 0, width: 12, height: 8 },
            { x: 0, y: 8, width: 3, height: 4 },
            { x: 3, y: 8, width: 2, height: 4 },
            { x: 5, y: 8, width: 2, height: 4 },
            { x: 7, y: 8, width: 2, height: 4 },
            { x: 9, y: 8, width: 3, height: 4 }
        ]
    },
    {
        cells: [
            { x: 0, y: 0, width: 3, height: 4 },
            { x: 3, y: 0, width: 2, height: 4 },
            { x: 5, y: 0, width: 2, height: 4 },
            { x: 7, y: 0, width: 2, height: 4 },
            { x: 9, y: 0, width: 3, height: 4 },
            { x: 0, y: 4, width: 12, height: 8 },
        ]
    },
    {
        cells: [
            { x: 0, y: 0, width: 9, height: 8 },
            { x: 0, y: 8, width: 3, height: 4 },
            { x: 3, y: 8, width: 3, height: 4 },
            { x: 6, y: 8, width: 3, height: 4 },
            { x: 9, y: 0, width: 3, height: 4 },
            { x: 9, y: 4, width: 3, height: 8 },
        ]
    },
    //seven cells
    {
        cells: [
            { x: 0, y: 0, width: 3, height: 12 },
            { x: 3, y: 0, width: 3, height: 6 },
            { x: 6, y: 0, width: 3, height: 6 },
            { x: 3, y: 6, width: 3, height: 6 },
            { x: 6, y: 6, width: 3, height: 6 },
            { x: 9, y: 0, width: 3, height: 6 },
            { x: 9, y: 6, width: 3, height: 6 }
        ]
    },
    {
        cells: [
            { x: 0, y: 0, width: 3, height: 6 },
            { x: 3, y: 0, width: 3, height: 6 },
            { x: 6, y: 0, width: 3, height: 6 },
            { x: 0, y: 6, width: 3, height: 6 },
            { x: 3, y: 6, width: 3, height: 6 },
            { x: 6, y: 6, width: 3, height: 6 },
            { x: 9, y: 0, width: 3, height: 12 }
        ]
    },
    {
        cells: [
            { x: 0, y: 0, width: 6, height: 6 },
            { x: 6, y: 0, width: 3, height: 6 },
            { x: 9, y: 0, width: 3, height: 6 },
            { x: 0, y: 6, width: 3, height: 6 },
            { x: 3, y: 6, width: 3, height: 6 },
            { x: 6, y: 6, width: 3, height: 6 },
            { x: 9, y: 6, width: 3, height: 6 }
        ]
    },
    {
        cells: [
            { x: 0, y: 0, width: 3, height: 6 },
            { x: 3, y: 0, width: 3, height: 6 },
            { x: 6, y: 0, width: 3, height: 6 },
            { x: 9, y: 0, width: 3, height: 6 },
            { x: 0, y: 6, width: 3, height: 6 },
            { x: 3, y: 6, width: 3, height: 6 },
            { x: 6, y: 6, width: 6, height: 6 }
        ]
    },
    {
        cells: [
            { x: 0, y: 0, width: 3, height: 6 },
            { x: 3, y: 0, width: 6, height: 6 },
            { x: 9, y: 0, width: 3, height: 6 },
            { x: 0, y: 6, width: 3, height: 6 },
            { x: 3, y: 6, width: 3, height: 6 },
            { x: 6, y: 6, width: 3, height: 6 },
            { x: 9, y: 6, width: 3, height: 6 }
        ]
    },
    {
        cells: [
            { x: 0, y: 0, width: 3, height: 6 },
            { x: 3, y: 0, width: 3, height: 6 },
            { x: 6, y: 0, width: 3, height: 6 },
            { x: 9, y: 0, width: 3, height: 6 },
            { x: 0, y: 6, width: 3, height: 6 },
            { x: 3, y: 6, width: 6, height: 6 },
            { x: 9, y: 6, width: 3, height: 6 }
        ]
    },
    // eight cells
    {
        cells: [
            { x: 0, y: 0, width: 3, height: 6 },
            { x: 3, y: 0, width: 3, height: 6 },
            { x: 6, y: 0, width: 3, height: 6 },
            { x: 9, y: 0, width: 3, height: 6 },
            { x: 0, y: 6, width: 3, height: 6 },
            { x: 3, y: 6, width: 3, height: 6 },
            { x: 6, y: 6, width: 3, height: 6 },
            { x: 9, y: 6, width: 3, height: 6 }
        ]
    },
    // nine cells
    {
        cells: [
            { x: 0, y: 0, width: 4, height: 4 },
            { x: 4, y: 0, width: 4, height: 4 },
            { x: 8, y: 0, width: 4, height: 4 },
            { x: 0, y: 4, width: 4, height: 4 },
            { x: 4, y: 4, width: 4, height: 4 },
            { x: 8, y: 4, width: 4, height: 4 },
            { x: 0, y: 8, width: 4, height: 4 },
            { x: 4, y: 8, width: 4, height: 4 },
            { x: 8, y: 8, width: 4, height: 4 }
        ]
    },
    {
        cells: [
            { x: 0, y: 0, width: 6, height: 8 },
            { x: 6, y: 0, width: 3, height: 4 },
            { x: 9, y: 0, width: 3, height: 4 },
            { x: 6, y: 4, width: 3, height: 4 },
            { x: 9, y: 4, width: 3, height: 4 },
            { x: 0, y: 8, width: 3, height: 4 },
            { x: 3, y: 8, width: 3, height: 4 },
            { x: 6, y: 8, width: 3, height: 4 },
            { x: 9, y: 8, width: 3, height: 4 }
        ]
    },
    {
        cells: [
            { x: 0, y: 0, width: 3, height: 4 },
            { x: 3, y: 0, width: 3, height: 4 },
            { x: 0, y: 4, width: 3, height: 4 },
            { x: 3, y: 4, width: 3, height: 4 },
            { x: 6, y: 0, width: 6, height: 8 },
            { x: 0, y: 8, width: 3, height: 4 },
            { x: 3, y: 8, width: 3, height: 4 },
            { x: 6, y: 8, width: 3, height: 4 },
            { x: 9, y: 8, width: 3, height: 4 }
        ]
    },
    {
        cells: [
            { x: 0, y: 0, width: 3, height: 4 },
            { x: 0, y: 4, width: 3, height: 4 },
            { x: 3, y: 0, width: 6, height: 8 },
            { x: 9, y: 0, width: 3, height: 4 },
            { x: 9, y: 4, width: 3, height: 4 },
            { x: 0, y: 8, width: 3, height: 4 },
            { x: 3, y: 8, width: 3, height: 4 },
            { x: 6, y: 8, width: 3, height: 4 },
            { x: 9, y: 8, width: 3, height: 4 }
        ]
    },
    // ten cells
    // {
    //     cells: [
    //         { x: 0, y: 0, width: 3, height: 6 },
    //         { x: 3, y: 0, width: 3, height: 6 },
    //         { x: 6, y: 0, width: 3, height: 6 },
    //         { x: 9, y: 0, width: 3, height: 6 },
    //         { x: 0, y: 6, width: 3, height: 6 },
    //         { x: 3, y: 6, width: 3, height: 6 },
    //         { x: 6, y: 6, width: 3, height: 6 },
    //         { x: 9, y: 6, width: 3, height: 6 },
    //         { x: 0, y: 12, width: 3, height: 6 },
    //         { x: 3, y: 12, width: 3, height: 6 }
    //     ]
    // },
    // {
    //     cells: [
    //         { x: 0, y: 0, width: 3, height: 6 },
    //         { x: 3, y: 0, width: 3, height: 6 },
    //         { x: 6, y: 0, width: 3, height: 6 },
    //         { x: 9, y: 0, width: 3, height: 6 },
    //         { x: 0, y: 6, width: 3, height: 6 },
    //         { x: 3, y: 6, width: 3, height: 6 },
    //         { x: 6, y: 6, width: 3, height: 6 },
    //         { x: 9, y: 6, width: 3, height: 6 },
    //         { x: 0, y: 12, width: 6, height: 6 },
    //         { x: 6, y: 12, width: 6, height: 6 }
    //     ]
    // },
    // {
    //     cells: [
    //         { x: 0, y: 0, width: 6, height: 6 },
    //         { x: 6, y: 0, width: 6, height: 6 },
    //         { x: 0, y: 6, width: 3, height: 6 },
    //         { x: 3, y: 6, width: 3, height: 6 },
    //         { x: 6, y: 6, width: 3, height: 6 },
    //         { x: 9, y: 6, width: 3, height: 6 },
    //         { x: 0, y: 12, width: 3, height: 6 },
    //         { x: 3, y: 12, width: 3, height: 6 },
    //         { x: 6, y: 12, width: 3, height: 6 },
    //         { x: 9, y: 12, width: 3, height: 6 }
    //     ]
    // }
];

export const StackDiagramLayouts = [{
    cols: 12, rows: 12,
    cells: [{
        x: 0, y: 0, width: 12, height: 4
    }, {
        x: 0, y: 4, width: 6, height: 4
    }, {
        x: 6, y: 4, width: 6, height: 4
    }, {
        x: 0, y: 8, width: 4, height: 4
    }, {
        x: 4, y: 8, width: 4, height: 4
    }, {
        x: 8, y: 8, width: 4, height: 4
    }],
    favorite: true
}, {
    cols: 12, rows: 12,
    cells: [{
        x: 0, y: 0, width: 12, height: 3
    }, {
        x: 0, y: 3, width: 3, height: 6
    }, {
        x: 3, y: 3, width: 3, height: 6
    }, {
        x: 6, y: 3, width: 3, height: 6
    }, {
        x: 9, y: 3, width: 3, height: 6
    }, {
        x: 0, y: 9, width: 12, height: 3
    }],
    favorite: true
}, {
    cols: 12, rows: 12,
    cells: [{
        x: 0, y: 0, height: 6, width: 3
    }, {
        x: 0, y: 6, height: 3, width: 6
    }, {
        x: 3, y: 0, width: 3, height: 6,
    }, {
        x: 6, y: 0, width: 3, height: 6,
    }, {
        x: 0, y: 9, width: 6, height: 3,
    }, {
        x: 6, y: 6, width: 6, height: 3,
    }, {
        x: 9, y: 0, width: 3, height: 6,
    }, {
        x: 6, y: 9, width: 6, height: 3,
    }],
    favorite: true
}, {
    cells: [{
        x: 0, y: 0, width: 6, height: 6
    }, {
        x: 0, y: 6, width: 6, height: 6
    }, {
        x: 6, y: 0, width: 6, height: 6
    }, {
        x: 6, y: 6, width: 6, height: 6
    }],
    favorite: true
}];

export const SideBarLayouts = [{
    cols: 6,
    rows: 60,
    cells: [{ x: 0, y: 0, width: 6, height: 60 }],
    favorite: true
}, {
    cols: 6,
    rows: 60,
    cells: [{ x: 0, y: 0, width: 6, height: 30 }, { x: 0, y: 30, width: 6, height: 30 }],
    favorite: true
}, {
    cols: 6,
    rows: 60,
    cells: [{ x: 0, y: 0, width: 6, height: 20 }, { x: 0, y: 20, width: 6, height: 20 }, { x: 0, y: 40, width: 6, height: 20 }],
    favorite: true
}, {
    cols: 6,
    rows: 60,
    cells: [{ x: 0, y: 0, width: 6, height: 15 }, { x: 0, y: 15, width: 6, height: 15 }, { x: 0, y: 30, width: 6, height: 15 }, { x: 0, y: 45, width: 6, height: 15 }],
    favorite: true
}, {
    cols: 6,
    rows: 60,
    cells: [{ x: 0, y: 0, width: 3, height: 60 }, { x: 3, y: 0, width: 3, height: 60 }],
    favorite: true
}, {
    cols: 6,
    rows: 60,
    cells: [{ x: 0, y: 0, width: 3, height: 30 }, { x: 3, y: 0, width: 3, height: 30 }, { x: 0, y: 30, width: 3, height: 30 }, { x: 3, y: 30, width: 3, height: 30 }],
    favorite: true
}];

import React from "react";
import styled from "styled-components";

import { ResizeDirection, TrayType } from "../../../../../../common/constants";
import { Button } from "../../../../../Components/Button";
import * as geom from "../../../../../core/utilities/geom";
import { defaultDragResizeProps, DragHandleStyle } from "../../../../../editor/PresentationEditor/DragElementManager";
import { ShowWarningDialog } from "../../../../../react/components/Dialogs/BaseDialog";
import { FlexBox } from "../../../../../react/components/LayoutGrid";
import { themeColors } from "../../../../../react/sharedStyles";
import { InfographicElementTypes } from "../../../elements/elements/Dashboard/InfographicManager";
import { LayoutEngineType } from "../../../elements/elements/LayoutContainers/GridLayoutContainer";
import { FlexGridLayout } from "../../../elements/elements/LayoutContainers/GridLayoutEngines/FlexGridLayout";
import { CollectionItemElementSelection } from "../../ElementSelections/CollectionItemElementSelection";
import { Icon } from "../../../../../Components/Icon";

const Container = styled.div.attrs(({ isResizing, bounds = null }) => ({
    style: {
        background: isResizing ? "rgba(17, 169, 226, 0.5)" : "transparent",
        border: isResizing ? `border: solid 1px ${themeColors.ui_blue}` : "1px dotted #50bbe6",
        ...(bounds ? bounds.toObject() : {})
    }
}))`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
`;

const AddCellHandle = styled.div`
    width: 16px;
    height: 16px;
    background: ${themeColors.ui_blue};
    background: white;
    border-radius: 50%;
    border: 1px solid ${themeColors.ui_blue};
    display: flex;
    justify-content: center;
    align-items: center;

    .bai-icon {
        font-size: 12px;
    }
`;

export class FlexGridItemSelection extends CollectionItemElementSelection {
    get canResize() {
        return true;
    }

    get dragResizeProps() {
        const { element } = this.props;
        const container = element.parentElement;

        let resizeDirections = [ResizeDirection.TOP, ResizeDirection.BOTTOM, ResizeDirection.LEFT, ResizeDirection.RIGHT];

        if (container.type == "BottomTray") {
            resizeDirections = [ResizeDirection.BOTTOM, ResizeDirection.LEFT, ResizeDirection.RIGHT];
        } else if (container.type == "SideBarContainer") {
            if (container.canvas.model.layout.trayLayout == TrayType.RIGHT_TRAY || container.canvas.model.layout.trayLayout == TrayType.RIGHT_INLINE) {
                resizeDirections = [ResizeDirection.TOP, ResizeDirection.BOTTOM, ResizeDirection.RIGHT];
            } else {
                resizeDirections = [ResizeDirection.TOP, ResizeDirection.BOTTOM, ResizeDirection.LEFT];
            }
        }

        return {
            ...defaultDragResizeProps,
            resizeDirections,
            handleProps: {
                style: direction => {
                    let cellBounds = new geom.Rect(container.gridLayout.cells[element.itemIndex]);

                    switch (direction) {
                        case ResizeDirection.LEFT:
                            return cellBounds.left == 0 ? <AddCellHandle onClick={() => this.handleClickAddCellHandle(direction)}><Icon blue fill>add</Icon></AddCellHandle> : DragHandleStyle.SIMPLE;
                        case ResizeDirection.TOP:
                            return cellBounds.top == 0 ? <AddCellHandle onClick={() => this.handleClickAddCellHandle(direction)}><Icon blue fill>add</Icon></AddCellHandle> : DragHandleStyle.SIMPLE;
                        case ResizeDirection.RIGHT:
                            return cellBounds.right == container.gridLayout.cols ? <AddCellHandle onClick={() => this.handleClickAddCellHandle(direction)}><Icon blue fill>add</Icon></AddCellHandle> : DragHandleStyle.SIMPLE;
                        case ResizeDirection.BOTTOM:
                            return cellBounds.bottom == container.gridLayout.rows ? <AddCellHandle onClick={() => this.handleClickAddCellHandle(direction)}><Icon blue fill>add</Icon></AddCellHandle> : DragHandleStyle.SIMPLE;
                    }
                }
            }
        };
    }

    handleClickAddCellHandle = direction => {
        const { element } = this.props;
        const container = element.parentElement;

        let cell = container.gridLayout.cells[element.itemIndex];
        let cellBounds = new geom.Rect(container.gridLayout.cells[element.itemIndex]);

        switch (direction) {
            case ResizeDirection.LEFT:
                cell.x = cell.x + Math.floor(cell.width / 2);
                cell.width = Math.ceil(cell.width / 2);
                break;
            case ResizeDirection.TOP:
                cell.y = cell.y + Math.floor(cell.height / 2);
                cell.height = Math.ceil(cell.height / 2);
                break;
            case ResizeDirection.RIGHT:
                cell.width = Math.ceil(cell.width / 2);
                break;
            case ResizeDirection.BOTTOM:
                cell.height = Math.ceil(cell.height / 2);
                break;
        }

        FlexGridLayout.fillEmptySlots(container);
        container.saveModel();
    }

    get swapOnDragDrop() {
        return true;
    }

    constructor(props) {
        super(props);

        this.state = {
            isResizing: false,
            resizeBounds: null
        };

        this.resizeProps = null;

        this.containerRef = React.createRef();
    }

    handleResizeHandleMouseDown = (event, resizeDirection) => {
        const { element, canvas } = this.props;
        const container = element.parentElement;

        this.resizeProps = {
            resizeDirection,
            containerScreenBounds: geom.Rect.FromBoundingClientRect(this.containerRef.current.getBoundingClientRect()),
            hGap: container.calculatedProps.hGap * canvas.getScale(),
            vGap: container.calculatedProps.vGap * canvas.getScale(),
            cols: container.calculatedProps.cols,
            rows: container.calculatedProps.rows,
            colWidth: container.calculatedProps.colWidth * canvas.getScale(),
            rowHeight: container.calculatedProps.rowHeight * canvas.getScale(),
        };

        window.addEventListener("mouseup", this.handleResizeMouseUp);
        window.addEventListener("mousemove", this.handleResizeMouseMove);
    }

    handleResizeMouseMove = event => {
        const { element } = this.props;
        const { isResizing } = this.state;

        const container = element.parentElement;

        if (!isResizing) {
            this.setState({ isResizing: true });
        }

        const { resizeDirection, containerScreenBounds, rowHeight, vGap, colWidth, hGap, cols, rows } = this.resizeProps;

        const gridBounds = new geom.Rect(container.gridLayout.cells[element.itemIndex]);
        const offsetLimits = {
            top: {
                min: -gridBounds.height + 1,
                max: gridBounds.top
            },
            bottom: {
                min: -gridBounds.height + 1,
                max: rows - gridBounds.bottom
            },
            left: {
                min: -gridBounds.width + 1,
                max: gridBounds.left
            },
            right: {
                min: -gridBounds.width + 1,
                max: cols - gridBounds.right
            }
        };

        let resizeGridBounds = gridBounds.clone();
        let resizeBounds = containerScreenBounds.zeroOffset();
        if (resizeDirection === ResizeDirection.TOP) {
            const offsetInGridUnits = Math.clamp(
                Math.round(
                    (containerScreenBounds.top - event.pageY) / (rowHeight + vGap)
                ),
                offsetLimits.top.min,
                offsetLimits.top.max
            );
            resizeGridBounds = resizeGridBounds.inflate({ top: offsetInGridUnits });
            resizeBounds = resizeBounds.inflate({ top: offsetInGridUnits * (rowHeight + vGap) });
        } else if (resizeDirection === ResizeDirection.BOTTOM) {
            const offsetInGridUnits = Math.clamp(
                Math.round(
                    (event.pageY - containerScreenBounds.bottom) / (rowHeight + vGap)
                ),
                offsetLimits.bottom.min,
                offsetLimits.bottom.max
            );
            resizeGridBounds = resizeGridBounds.inflate({ bottom: offsetInGridUnits });
            resizeBounds = resizeBounds.inflate({ bottom: offsetInGridUnits * (rowHeight + vGap) });
        } else if (resizeDirection === ResizeDirection.LEFT) {
            const offsetInGridUnits = Math.clamp(
                Math.round(
                    (containerScreenBounds.left - event.pageX) / (colWidth + hGap)
                ),
                offsetLimits.left.min,
                offsetLimits.left.max
            );
            resizeGridBounds = resizeGridBounds.inflate({ left: offsetInGridUnits });
            resizeBounds = resizeBounds.inflate({ left: offsetInGridUnits * (colWidth + hGap) });
        } else if (resizeDirection === ResizeDirection.RIGHT) {
            const offsetInGridUnits = Math.clamp(
                Math.round(
                    (event.pageX - containerScreenBounds.right) / (colWidth + hGap)
                ),
                offsetLimits.right.min,
                offsetLimits.right.max
            );
            resizeGridBounds = resizeGridBounds.inflate({ right: offsetInGridUnits });
            resizeBounds = resizeBounds.inflate({ right: offsetInGridUnits * (colWidth + hGap) });
        }

        this.resizeProps.resizeGridBounds = resizeGridBounds;

        this.setState({ resizeBounds });
    }

    handleResizeMouseUp = () => {
        const { element } = this.props;
        const container = element.parentElement;

        const currentCellIndex = element.itemIndex;
        const currentCell = container.gridLayout.cells[currentCellIndex];
        const currentGridBounds = new geom.Rect(currentCell);

        window.removeEventListener("mouseup", this.handleResizeMouseUp);
        window.removeEventListener("mousemove", this.handleResizeMouseMove);

        const { resizeGridBounds, resizeDirection } = this.resizeProps;

        if (resizeGridBounds && resizeGridBounds.toString() !== currentGridBounds.toString()) {
            Object.assign(currentCell, {
                x: resizeGridBounds.left,
                y: resizeGridBounds.top,
                width: resizeGridBounds.width,
                height: resizeGridBounds.height
            });

            const edgeCells = container.gridLayout.cells.filter((edgeCell, index) => {
                if (currentCell !== edgeCell) {
                    const itemGridBounds = new geom.Rect(edgeCell);
                    switch (resizeDirection) {
                        case ResizeDirection.RIGHT:
                            return currentGridBounds.right === itemGridBounds.left && itemGridBounds.top >= resizeGridBounds.top && itemGridBounds.bottom <= resizeGridBounds.bottom;
                        case ResizeDirection.LEFT:
                            return currentGridBounds.left === itemGridBounds.right && itemGridBounds.top >= resizeGridBounds.top && itemGridBounds.bottom <= resizeGridBounds.bottom;
                        case ResizeDirection.TOP:
                            return currentGridBounds.top === itemGridBounds.bottom && itemGridBounds.left >= resizeGridBounds.left && itemGridBounds.right <= resizeGridBounds.right;
                        case ResizeDirection.BOTTOM:
                            return currentGridBounds.bottom === itemGridBounds.top && itemGridBounds.left >= resizeGridBounds.left && itemGridBounds.right <= resizeGridBounds.right;
                    }
                }
            });
            edgeCells.forEach(edgeCell => {
                const cellBounds = new geom.Rect(edgeCell);

                switch (resizeDirection) {
                    case ResizeDirection.RIGHT:
                        Object.assign(edgeCell, cellBounds.inflate({ left: currentGridBounds.right - resizeGridBounds.right }).toXYObject());
                        break;
                    case ResizeDirection.LEFT:
                        Object.assign(edgeCell, cellBounds.inflate({ right: resizeGridBounds.left - currentGridBounds.left }).toXYObject());
                        break;
                    case ResizeDirection.TOP:
                        Object.assign(edgeCell, cellBounds.inflate({ bottom: resizeGridBounds.top - currentGridBounds.top }).toXYObject());
                        break;
                    case ResizeDirection.BOTTOM:
                        Object.assign(edgeCell, cellBounds.inflate({ top: currentGridBounds.bottom - resizeGridBounds.bottom }).toXYObject());
                        break;
                }

                if (edgeCell.width <= 0 || edgeCell.height <= 0) {
                    this.deleteCell(edgeCell);
                }
            });

            const intersectingCells = container.gridLayout.cells.filter(cell =>
                cell !== currentCell && resizeGridBounds.intersects(new geom.Rect(cell), true)
            );
            intersectingCells.forEach(intersectingCell => {
                const cellBounds = new geom.Rect(intersectingCell);
                if (resizeGridBounds.overlaps(cellBounds)) {
                    this.deleteCell(intersectingCell);
                } else {
                    const bounds = this.splitItem(intersectingCell, resizeGridBounds);
                    Object.assign(intersectingCell, {
                        x: bounds.left,
                        y: bounds.top,
                        width: bounds.width,
                        height: bounds.height
                    });
                }
            });

            this.fillEmptySlots();

            element.saveModel();
        }

        this.setState({ isResizing: false, resizeBounds: null });

        this.resizeProps = null;
    }

    deleteCell(cell) {
        const { element } = this.props;
        const container = element.parentElement;

        const cellIndex = container.gridLayout.cells.indexOf(cell);
        container.deleteItem(container.itemElements[cellIndex].id);
        container.gridLayout.cells.remove(cell);
    }

    fillEmptySlots() {
        const { element } = this.props;
        const container = element.parentElement;
        FlexGridLayout.fillEmptySlots(container);

        // special case for Headline layouts to automatically convert them to Flex layouts when more than 2 cells
        if (container.layoutEngineType === LayoutEngineType.HEADLINE && container.itemCount > 2) {
            container.model.layoutEngineType = LayoutEngineType.FLEX;
        }
    }

    splitItem(item, bounds) {
        let boxes = [];

        let itemBounds = new geom.Rect(item);

        // break the item area into boxes without the overlapped area
        for (let row = itemBounds.top; row < itemBounds.bottom; row++) {
            for (let col = itemBounds.left; col < itemBounds.right; col++) {
                let box = new geom.Rect(col, row, 1, 1);
                if (!bounds.overlaps(box)) {
                    boxes.push(box);
                }
            }
        }

        return FlexGridLayout.combineCells(boxes);
    }

    handleDeleteElement = () => {
        const { element } = this.props;
        const container = element.parentElement;

        const deletedCell = container.gridLayout.cells[element.itemIndex];

        const deleteCell = () => {
            container.gridLayout.cells.remove(deletedCell);
            container.deleteItem(element.id);

            if (container.gridLayout.cells.length === 0) {
                container.gridLayout.cells = null;
            }

            container.saveModel();
        };

        if (container.gridLayout.cells.length === 1) {
            deleteCell();
            return;
        }

        // find multiple cells aligned to the right
        let cells = container.gridLayout.cells.filter(cell => {
            return cell.y >= deletedCell.y && (cell.y + cell.height) <= (deletedCell.y + deletedCell.height) && cell.x == deletedCell.x + deletedCell.width;
        });
        if (cells.length && cells.some(cell => cell.y == deletedCell.y) && cells.some(cell => cell.y + cell.height == deletedCell.y + deletedCell.height)) {
            for (const cell of cells) {
                cell.x = deletedCell.x;
                cell.width += deletedCell.width;
            }
            deleteCell();
            return;
        }

        // find multiple cells aligned to the left
        cells = container.gridLayout.cells.filter(cell => {
            return cell.y >= deletedCell.y && (cell.y + cell.height) <= (deletedCell.y + deletedCell.height) && cell.x + cell.width == deletedCell.x;
        });
        if (cells.length && cells.some(cell => cell.y == deletedCell.y) && cells.some(cell => cell.y + cell.height == deletedCell.y + deletedCell.height)) {
            for (let cell of cells) {
                cell.width += deletedCell.width;
            }
            deleteCell();
            return;
        }

        // find multiple cells aligned to the bottom
        cells = container.gridLayout.cells.filter(cell => {
            return cell.x >= deletedCell.x && (cell.x + cell.width) <= (deletedCell.x + deletedCell.width) && cell.y + cell.height == deletedCell.y;
        });
        if (cells.length && cells.some(cell => cell.x == deletedCell.x) && cells.some(cell => cell.x + cell.width == deletedCell.x + deletedCell.width)) {
            for (const cell of cells) {
                cell.height += deletedCell.height;
            }
            deleteCell();
            return;
        }

        // find multiple cells aligned to the top
        cells = container.gridLayout.cells.filter(cell => {
            return cell.x >= deletedCell.x && (cell.x + cell.width) <= (deletedCell.x + deletedCell.width) && cell.y == deletedCell.y + deletedCell.height;
        });
        if (cells.length && cells.some(cell => cell.x == deletedCell.x) && cells.some(cell => cell.x + cell.width == deletedCell.x + deletedCell.width)) {
            for (const cell of cells) {
                cell.y = deletedCell.y;
                cell.height += deletedCell.height;
            }
            deleteCell();
            return;
        }

        ShowWarningDialog({ title: "Delete Cell", message: "This cell cannot be deleted because it is not aligned with other cells. Please resize the cell to zero width or height to remove it." });
    }

    renderCustomChildren() {
        const { selectedElements, canvasController } = this.props;
        const { isResizing, resizeBounds } = this.state;

        const isChildFit = selectedElements[0].calculatedProps?.isChildFit !== false;

        let EditButton = null;
        switch (selectedElements[0].componentType) {
            case InfographicElementTypes.CHART:
            case InfographicElementTypes.PIE_CHART:
            case InfographicElementTypes.RADIAL_BAR_CHART:
                EditButton = (
                    <FlexBox fill center middle>
                        <Button onClick={() => canvasController.selectionLayerController.setSelectedElements([selectedElements[0].childElement])}>Edit Chart...</Button>
                    </FlexBox>
                );
                break;
            case InfographicElementTypes.TABLE:
                EditButton = (
                    <FlexBox fill center middle>
                        <Button onClick={() => canvasController.selectionLayerController.setSelectedElements([selectedElements[0].childElement])}>Edit Table...</Button>
                    </FlexBox>
                );
                break;
        }

        return (
            <Container ref={this.containerRef} isResizing={isResizing} bounds={resizeBounds}>
                {isChildFit && EditButton}
            </Container>
        );
    }
}

import React, { Component } from "react";
import styled from "styled-components";

import { ShowDialog } from "js/react/components/Dialogs/BaseDialog";
import ConvertPPTSlideToAuthoringDialog from "js/react/components/Dialogs/ConvertPPTSlideToAuthoringDialog";

import { Button } from "../../../../Components/Button";
import { Icon } from "../../../../Components/Icon";
import { PropertyPanelContainer, PropertySection } from "../../../../EditorComponents/PropertyPanel";

const ClickListener = styled.div`
    width: 100%;
    height: 100%;
    background: transparent;
    pointer-events: auto;
`;

export class PPTSlidePropertyPanel extends Component {
    handleConvert = () => {
        const { element } = this.props;

        ShowDialog(ConvertPPTSlideToAuthoringDialog, {
            canvas: element.canvas
        });
    }

    render() {
        return (
            <PropertyPanelContainer>
                <PropertySection>
                    <Button onClick={this.handleConvert}>
                        <Icon>edit</Icon>Convert to Editable...
                    </Button>
                </PropertySection>
            </PropertyPanelContainer>
        );
    }
}

export class PPTSlideDefaultOverlay extends Component {
    handleMouseDown = () => {
        const { element } = this.props;

        ShowDialog(ConvertPPTSlideToAuthoringDialog, {
            canvas: element.canvas
        });
    }

    render() {
        return <ClickListener onMouseDown={this.handleMouseDown} />;
    }
}

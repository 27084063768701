import CompareValuesCollection from "./CompareValuesCollection";
import CompareVerticalPictographItem from "./CompareVerticalPictographItem";
import { CompareVerticalPictographPropertyPanel } from "../../../Editor/ElementPropertyPanels/CompareItemsUI";

export default class CompareVerticalPictograph extends CompareValuesCollection {
    getElementPropertyPanel() {
        return CompareVerticalPictographPropertyPanel;
    }

    get name() {
        return "Compare Pictographs";
    }

    getChildItemType() {
        return CompareVerticalPictographItem;
    }

    get maxItemCount() {
        return 4;
    }

    _calcProps(props, options) {
        let { size } = props;
        let layouter = this.getLayouter(props, this.itemElements, size);
        layouter.calcHorizontalLayout({ verticallyAlignText: true });
        layouter.alignHorizontally();
        props.isFit = layouter.isFit && this.itemCount <= (this.styles.maxItemCount || 4);
        return { size: layouter.size };
    }
}

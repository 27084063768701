import React, { Component } from "react";
import { FeatureType } from "common/features";
import { PropertyPanelContainer, PropertySection } from "../../../../EditorComponents/PropertyPanel";
import { WithLabel } from "../../../../Components/WithLabel";
import { Dropdown } from "../../../../Components/Dropdown";
import { ToggleSwitch } from "../../../../Components/ToggleSwitch";
import { tinycolor } from "../../../../vendor";
import { themeColors } from "../../../../react/sharedStyles";
import { Button } from "../../../../Components/Button";
import { Icon } from "../../../../Components/Icon";
import { MenuItem } from "../../../../Components/Menu";
import DesignerBotIcon from "js/react/components/DesignerBotIcon";
import { CollectionColorPicker } from "../EditorComponents/ColorPickers/CollectionColorPicker";
import { ForeColorType } from "../../../../../common/constants";
import { ShowDialog } from "js/react/components/Dialogs/BaseDialog";
import { EditWordCloudDialog } from "js/react/components/Dialogs/WordCloud/EditWordCloudDialog";
import { GenerateWordCloudDialog } from "js/react/components/Dialogs/WordCloud/GenerateWordCloudDialog";
import { wordStringsToWordList } from "js/react/components/Dialogs/WordCloud/common";
import AppController from "js/core/AppController";

function updateWordCloud(element) {
    // Setting shouldRegen and letting the element to recalc itself
    element.shouldRegen = true;
    element.canvas.refreshCanvas();
}

export class WordCloudPropertyPanel extends Component {
    render() {
        const { element } = this.props;
        const designerBotDisabled = AppController.user.features.isFeatureEnabled(FeatureType.PROHIBIT_GENERATIVE_AI, AppController.workspaceId);
        const designerBotAccessible = AppController.user.features.isFeatureEnabled(FeatureType.DESIGNER_BOT, AppController.workspaceId);

        return (
            <PropertyPanelContainer>
                <PropertySection>
                    <Button onClick={() => {
                        ShowDialog(EditWordCloudDialog, { element });
                    }}
                    >
                        <Icon>pin</Icon>Set Words...
                    </Button>

                    {!designerBotDisabled && designerBotAccessible &&
                        <Button
                            color={themeColors.lightGray}
                            backgroundColor={themeColors.aiColor}
                            hoverColor={ tinycolor(themeColors.aiColor).lighten(5).toString()}
                            onClick={() => {
                                ShowDialog(GenerateWordCloudDialog,
                                    {
                                        element: element,
                                        onSuccess: aiGeneratedWords => {
                                            element.wordList = wordStringsToWordList(aiGeneratedWords);
                                            updateWordCloud(element);
                                        }
                                    });
                            }}
                        >
                            <Icon><DesignerBotIcon/>
                            </Icon>
                            Generate
                        </Button>
                    }
                </PropertySection>
                <PropertySection>
                    <WithLabel label="Word Color">
                        <CollectionColorPicker element={element} showBackgroundAccent={false} propertyName="color"/>
                    </WithLabel>
                </PropertySection>

                <PropertySection>
                    <WithLabel label="Layout">
                        <Dropdown value={element.model.style}
                            onChange={value => {
                                element.model.style = value;
                                updateWordCloud(element);
                            }}
                        >
                            <MenuItem value="straight">Straight</MenuItem>
                            <MenuItem value="right_angles">Right Angles</MenuItem>
                            <MenuItem value="rotated">Rotated</MenuItem>
                        </Dropdown>
                    </WithLabel>
                    <WithLabel label="Sort">
                        <Dropdown value={element.model.sort}
                            onChange={value => {
                                element.model.sort = value;
                                updateWordCloud(element);
                            }}
                        >
                            <MenuItem value="size">Size</MenuItem>
                            <MenuItem value="random">Random</MenuItem>
                        </Dropdown>
                    </WithLabel>
                    <WithLabel label="Font">
                        <Dropdown value={element.fontType}
                            onChange={value => {
                                element.model.font = value;
                                updateWordCloud(element);
                            }}
                        >
                            <MenuItem value="block">Block Font</MenuItem>
                            <MenuItem value="theme">Use Theme Font</MenuItem>
                        </Dropdown>
                    </WithLabel>
                    <WithLabel label="WordCloud Size">
                        <Dropdown value={element.scale}
                            onChange={value => {
                                element.model.scale = value;
                                updateWordCloud(element);
                            }}
                        >
                            <MenuItem value={10}>Tiny</MenuItem>
                            <MenuItem value={15}>Small</MenuItem>
                            <MenuItem value={20}>Medium</MenuItem>
                            <MenuItem value={25}>Large</MenuItem>
                        </Dropdown>
                    </WithLabel>
                    <WithLabel label="Spacing">
                        <Dropdown value={element.spacing}
                            onChange={value => {
                                element.model.spacing = value;
                                updateWordCloud(element);
                            }}
                        >
                            <MenuItem value={1}>Tight</MenuItem>
                            <MenuItem value={5}>Medium</MenuItem>
                            <MenuItem value={8}>Wide</MenuItem>
                        </Dropdown>
                    </WithLabel>
                    <WithLabel label="Invert Colors">
                        <ToggleSwitch value={element.model.invertColors}
                            onChange={value => {
                                element.model.invertColors = value;
                                element.canvas.refreshCanvas();
                            }}
                            disabled={element.canvas.getBackgroundColor().isColor || element.model.color === ForeColorType.NEUTRAL}
                        />
                    </WithLabel>
                </PropertySection>
                <PropertySection>
                    <Button onClick={() => {
                        updateWordCloud(element);
                    }}
                    >
                        <Icon>loop</Icon>Randomize Word Cloud
                    </Button>
                </PropertySection>
            </PropertyPanelContainer>
        );
    }
}

import * as geom from "js/core/utilities/geom";
import { DecorationStyle, ShapeType, ForeColorType } from "common/constants";

import { BaseElement } from "../BaseElement";
import { ImageFrame } from "../Frame";
import { ContentElement } from "../ContentElement";

export class FramedMediaElement extends BaseElement {
    static get schema() {
        return {
            frameType: "theme",
        };
    }

    get allowUnframedImages() {
        return this.options.allowUnframedImages ?? true;
    }

    get hasValidAsset() {
        return this.content.hasValidAsset;
    }

    deleteAsset() {
        this.content.deleteAsset();
    }

    get clipboardElement() {
        return this.content;
    }

    get maxMarkerSize() {
        return this.options.maxMarkerSize;
    }

    get minMarkerSize() {
        return this.options.minMarkerSize;
    }

    get frameType() {
        let frameType;
        if (this.model.frameType == "theme" || this.model.frameType == null) {
            if (this.options.frameType) {
                frameType = this.options.frameType;
                // } else if (this.styles.content.decoration.shape == ShapeType.RECT && this.styles.content.decoration.cornerRadius > 0) {
                //     frameType = ShapeType.ROUNDED_RECT;
            } else {
                frameType = this.canvas.getTheme().get("styleShape");
            }
        } else {
            frameType = this.model.frameType ?? this.options.frameType ?? "none";
        }

        if (frameType == "none" && !this.allowUnframedImages && this.content && this.content.hasValidAsset) {
            frameType = "unframedRect";  // special-case where we want the asset constrained to a rect but with no frame
        }

        return frameType;
    }

    get hasFrame() {
        return this.frameType != "none" && this.frameType != "unframedRect";
    }

    get frameColor() {
        if (this.frameType == "unframedRect") {
            return null;
        }
        return this.options.frameColor ?? this.model.frameColor;
    }

    get framePadding() {
        return this.model.framePadding ?? 0;
    }

    get assetType() {
        return this.content.assetType;
    }

    get assetElement() {
        return this.content.assetElement;
    }

    get decorationStyle() {
        if (this.allowDecorationStyles) {
            return super.decorationStyle;
        } else {
            return DecorationStyle.FILLED;
        }
    }

    get allowDecorationStyles() {
        return this.hasShapeFrame;
    }

    get hasShapeFrame() {
        return this.frameType.equalsAnyOf(ShapeType.RECT, ShapeType.ROUNDED_RECT, ShapeType.CIRCLE, ShapeType.OCTAGON, "square", "light", "dark", "circle", "circle-light", "circle-dark");
    }

    get hasDeviceFrame() {
        return !this.frameType.equalsAnyOf(ShapeType.RECT, ShapeType.ROUNDED_RECT, ShapeType.CIRCLE, ShapeType.OCTAGON, ShapeType.NONE, "unframedRect");
    }

    get preserveAspectRatioForUnframed() {
        return this.options.preserveAspectRatioForUnframed ?? true;
    }

    get canPasteImage() {
        return true;
    }

    get canEdit() {
        return true;
    }

    get _canSelect() {
        return true;
    }

    _build() {
        let forceIconColor = false;
        if (this.hasShapeFrame && (this.decorationStyle == "muted" || this.decorationStyle == "fillAndStroke")) {
            // this enables the icon to use the model color even when the bg is color color for muted backgrounds
            forceIconColor = true;
        } else if (!this.hasFrame) {
            // this enables the icon to use the model color even when the bg is color color for none frames
            forceIconColor = true;
        }

        this.content = this.addElement("content", () => ContentElement, {
            defaultAssetType: this.options.defaultAssetType,
            canSelect: false,
            canDoubleClickToEdit: this.options.canDoubleClickToEdit ?? true,
            iconScale: this.hasFrame ? this.styles.content.iconScale : 1,  // when frame is none, scale the icon to 1 otherwise use the style value
            forceIconColor, // this enables the icon to use color on color for muted backgrounds
            allowBackdrop: false,
            showFrameButton: this.options.showFrameButton,
            elementControlBar: this.options.elementControlBar,
            hasShapeFrame: this.hasShapeFrame,
            allowColorOnColor: this.options.allowColorOnColor ?? (this.hasFrame && !this.hasShapeFrame)
        });

        let decorationShape;
        let cornerRadius = 0;

        this.content.removeDecoration();

        switch (this.frameType) {
            case "light":  // legacy
            case "dark":   // legacy
            case "square": // legacy
            case ShapeType.RECT:
            case "unframedRect":
                decorationShape = ShapeType.RECT;
                break;
            case "circle":// legacy
            case "circle-light":// legacy
            case "circle-dark":// legacy
            case ShapeType.CIRCLE:
                decorationShape = ShapeType.CIRCLE;
                break;
            case ShapeType.ROUNDED_RECT:
                decorationShape = ShapeType.RECT;
                cornerRadius = 10;
                break;
            case ShapeType.OCTAGON:
                decorationShape = ShapeType.OCTAGON;
                break;
            case "none":
                this.frame = null;
                break;
            default:
                this.frame = this.addElement("frame", () => ImageFrame, {
                    model: {
                        frame: this.frameType,
                    }
                });
                this.content.createDecoration({
                    type: "frame",
                    shape: "rect",
                    fillColor: this.frameColor,
                    strokeWidth: 0,
                    cornerRadius: 0,
                });
        }

        if (this.hasShapeFrame) {
            this.content.createDecoration({
                type: "frame",
                shape: decorationShape,
                cornerRadius: cornerRadius,
                fillColor: "$iconFrameFillColor",
                strokeColor: "$iconFrameStrokeColor",
                strokeWidth: "$iconFrameStrokeWidth",
                allowColorOnColor: this.allowColorOnColor
            });
            this.content.decoration.loadedStyles.applyDecorationStyles(this, this.decorationStyle);
        }
    }

    _calcProps(props, options) {
        let { size } = props;

        if (this.hasShapeFrame || this.frameType == "unframedRect") {
            if (this.hasShapeFrame && options.autoHeight && (size.height > size.width)) {
                size = size.square();
            }
            if (this.hasShapeFrame && options.autoWidth && (size.width > size.height)) {
                size = size.square();
            }

            const contentProps = this.content.calcProps(size);
            if (options.autoWidth) {
                size.width = contentProps.size.width;
            } else if (options.autoHeight) {
                size.height = contentProps.size.height;
            }
            contentProps.bounds = new geom.Rect(size.width / 2 - contentProps.size.width / 2, size.height / 2 - contentProps.size.height / 2, contentProps.size);
        } else if (this.frameType == ShapeType.NONE) {
            if (this.preserveAspectRatioForUnframed) {
                let aspectRatio = this.content.assetElement.mediaSize ? this.content.assetElement.mediaSize.aspectRatio : 1;
                if (options.fitToHeight) {
                    if (size.height * aspectRatio > size.width) {
                        size = new geom.Size(size.width, size.width / aspectRatio);
                    } else {
                        size = new geom.Size(size.height * aspectRatio, size.height);
                    }
                } else {
                    size = new geom.Size(size.width, size.width / aspectRatio);
                }
            }

            const contentProps = this.content.calcProps(size);
            contentProps.bounds = new geom.Rect(size.width / 2 - contentProps.size.width / 2, size.height / 2 - contentProps.size.height / 2, contentProps.size);
        } else {
            // has an image frame
            const frameProps = this.frame.calcProps(size);

            if (options.autoWidth) {
                size.width = frameProps.size.width;
            }
            if (options.autoHeight) {
                size.height = frameProps.size.height;
            }

            frameProps.bounds = new geom.Rect(size.width / 2 - frameProps.size.width / 2, size.height / 2 - frameProps.size.height / 2, frameProps.size);
            frameProps.layer = 1;

            const framePadding = this.content.isVideo && this.frameType.startsWith("circle") ? 0.5 : 0;
            const contentProps = this.content.calcProps(
                frameProps.clipBounds.size,
                {
                    // backgroundColor: this.frameColor,
                    // Accounting for anti-aliasing artifacts when video in circle
                    framePadding
                });
            contentProps.bounds = new geom.Rect(0, 0, contentProps.size).offset(frameProps.bounds.left, frameProps.bounds.top).offset(frameProps.clipBounds.left, frameProps.clipBounds.top);

            if (frameProps.frameClipPath) {
                contentProps.clipPath = frameProps.frameClipPath;
            }
            if (frameProps.customClip) {
                contentProps.customClip = frameProps.customClip;
            }
        }

        return { size };
    }

    _applyColors() {
        if (this.isOnAuthoringCanvas || this.hasDeviceFrame) {
            const color = this.frameColor ?? this.model.color ?? this.findClosestOfType("CollectionElement")?.collectionColor ?? "white";
            this.colorSet.decorationColor = this.palette.getColor(color, color === ForeColorType.PRIMARY ? this.getBackgroundColor() : null, { itemIndex: this.itemIndex });
        } else {
            this.colorSet.decorationColor = this.palette.getColor(this.frameColor ?? this.getDecorationColor(), this.getBackgroundColor());
        }

        if (this.decorationStyle == DecorationStyle.FILLED && this.frameType != "unframedRect" && this.frameType != "none") {
            // set the background color for the frame (if it's FILLED and not none or unframed rect)
            // allow other decorationStyles will use the parent backgroundColor
            this.colorSet.backgroundColor = this.colorSet.decorationColor?.clone();
        }
    }

    _exportToSharedModel() {
        return this.content._exportToSharedModel();
    }

    _importFromSharedModel(model) {
        return this.content._importFromSharedModel(model);
    }
}

export const elements = {
    FramedMediaElement
};

import React, { Component } from "react";
import styled from "styled-components";
import { Slider } from "@material-ui/core";

import { Gap10 } from "../../../react/components/Gap";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;

const StyledInput = styled.input`
  border: solid 1px #ccc;
  background: white;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  outline: none;
  position: relative;
  top: -1px;

  /* Chrome, Safari, Edge, Opera */

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  -moz-appearance: textfield;
`;

export class InputSlider extends Component {
    constructor(props) {
        super(props);

        this.isDraggingSlider = false;
        this.hasUncommittedChange = false;

        this.state = {
            inputValue: null,
            isEditingInput: false
        };
    }

    componentWillUnmount() {
        const { value } = this.props;

        if (this.hasUncommittedChange) {
            this.handleCommitChange(value);
        }
    }

    handleChange = (value, source) => {
        const { onChange, inputMin = 0, inputMax = 99, sliderMin = 0, sliderMax = 99 } = this.props;

        let result = parseFloat(value);
        if (!isNaN(result)) {
            if (source === "slider") {
                result = Math.min(sliderMax, Math.max(sliderMin, result));
            } else {
                result = Math.min(inputMax, Math.max(inputMin, result));
            }
            onChange(result);
            this.hasUncommittedChange = true;
        }
    }

    handleCommitChange = (value, source) => {
        const { onChangeCommitted, inputMin = 0, inputMax = 99, sliderMin = 0, sliderMax = 99 } = this.props;

        let result = parseFloat(value);
        if (!isNaN(result)) {
            if (source === "slider") {
                result = Math.min(sliderMax, Math.max(sliderMin, result));
            } else {
                result = Math.min(inputMax, Math.max(inputMin, result));
            }
            onChangeCommitted(result);
            this.hasUncommittedChange = false;
        }
    }

    handleChangeSlider = (event, value) => {
        const { onStartDrag, inputFormat = "number" } = this.props;

        if (!this.isDraggingSlider && onStartDrag) {
            onStartDrag();
        }
        this.isDraggingSlider = true;

        this.handleChange(value, "slider");
    }

    handleChangeCommitSlider = (event, value) => {
        const { onEndDrag, inputFormat = "number" } = this.props;

        if (this.isDraggingSlider && onEndDrag) {
            onEndDrag();
        }
        this.isDraggingSlider = false;

        this.handleCommitChange(value, "slider");
    }

    handleInputKeyDown = event => {
        event.stopPropagation();
    }

    handleInputKeyPress = event => {
        const { inputValue } = this.state;
        if (event.which == 13) {
            this.handleCommitChange(inputValue, "input");
        }
    }

    handleInputFocus = event => {
        const { value } = this.props;

        event.target.select();

        this.setState({
            isEditingInput: true,
            inputValue: value
        });
    }

    handleInputBlur = () => {
        const { inputValue } = this.state;

        this.setState({
            isEditingInput: false
        });

        this.handleCommitChange(inputValue, "input");
    }

    handleInputChange = event => {
        const { inputFormat = "number" } = this.props;

        const value = parseInt(event.target.value);
        if (!isNaN(value)) {
            this.setState({
                inputValue: inputFormat == "percentage" ? value / 100 : value
            });
        }
    }

    render() {
        const { className, styles, showBorder = true, inputFormat = "number", inputMin = 0, inputMax = 99, sliderMin = 0, sliderMax = 99, step = 1, disabled, value } = this.props;
        const { isEditingInput, inputValue } = this.state;

        const currentValue = isEditingInput ? inputValue : value;

        return (
            <Container
                className={className}
                style={{
                    flexDirection: "row",
                    height: 34,
                    ...styles
                }}
                showBorder={showBorder}
            >
                <StyledInput
                    type="number"
                    min={inputMin}
                    max={inputMax}
                    value={inputFormat == "percentage" ? Math.round(currentValue * 100) : currentValue}
                    style={{
                        width: 60,
                        height: 25,
                    }}
                    onFocus={this.handleInputFocus}
                    onBlur={this.handleInputBlur}
                    onChange={this.handleInputChange}
                    onKeyDown={this.handleInputKeyDown}
                    onKeyPress={this.handleInputKeyPress}
                    disabled={disabled}
                />
                {inputFormat === "percentage" && <div style={{ marginLeft: 5 }}>%</div>}
                <Gap10 />
                <Slider
                    min={sliderMin}
                    max={sliderMax}
                    step={step}
                    value={currentValue}
                    onChange={this.handleChangeSlider}
                    onChangeCommitted={this.handleChangeCommitSlider}
                    disabled={disabled}
                    style={{ width: 150 }}
                />
            </Container>
        );
    }
}

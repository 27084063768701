import React, { Component } from "react";

import { app } from "js/namespaces";
import AppController from "js/core/AppController";
import { FeatureType } from "common/features";
import { tinycolor } from "js/vendor";
import { PropertyPanelContainer, PropertySection } from "js/EditorComponents/PropertyPanel";
import { Button } from "js/Components/Button";
import { Icon } from "js/Components/Icon";
import { PropertyPanelHeader } from "js/editor/ElementEditor/PropertyPanelHeader";
import { ShowDialog } from "js/react/components/Dialogs/BaseDialog";
import DesignerBotIcon from "js/react/components/DesignerBotIcon";
import { themeColors } from "js/react/sharedStyles";
import { AddSlideContainer } from "js/react/views/AddSlide/index";

export class SlidePlaceholderPropertyPanel extends Component {
    handleChooseTemplate = () => {
        const { currentSlide } = this.props;

        ShowDialog(AddSlideContainer, {
            replaceSlide: currentSlide
        });
    }

    handleGenerateSlide = () => {
        this.props.element.finishWithAi();
    }

    render() {
        const designerBotDisabled = app.user.features.isFeatureEnabled(FeatureType.PROHIBIT_GENERATIVE_AI, AppController.workspaceId);
        const designerBotAccessible = app.user.features.isFeatureEnabled(FeatureType.DESIGNER_BOT, AppController.workspaceId);

        return (
            <PropertyPanelContainer>
                <PropertySection>
                    <Button onClick={this.handleChooseTemplate}>Choose Template...</Button>
                    {!designerBotDisabled && designerBotAccessible && (
                        <Button
                            color={themeColors.lightGray}
                            backgroundColor={themeColors.aiColor}
                            hoverColor={tinycolor(themeColors.aiColor).lighten(5).toString()}
                            onClick={this.handleGenerateSlide}
                        >
                            <Icon><DesignerBotIcon /></Icon>
                            Generate Slide
                        </Button>
                    )}
                </PropertySection>
            </PropertyPanelContainer>
        );
    }
}

/**
 * component to create a svg-based embed using <foreignObject>
 */
import * as geom from "js/core/utilities/geom";
import { SVG as SVGjs } from "js/vendor";

import { BaseElement } from "../base/BaseElement";
import { SVGElement } from "../base/SVGElement";

/**
 * produces a svg <foreignObject> element
 */
class SVGForeignObjectElement extends SVGElement {
    _create() {
        const foreignObject = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "foreignObject"
        );
        foreignObject.id = "embed";
        foreignObject.setAttribute("width", "100%");
        foreignObject.setAttribute("height", "100%");
        foreignObject.setAttribute("style", "{ overflow: visible !important; }");
        this.parentSVG.node.appendChild(foreignObject);
        // the class we're inheriting from has various lifecycle/utility methods (???) that rely
        //  on this naming convention – this must be assigned to `this.svg`, and be
        //  an instance of an svg.js element
        this.svg = SVGjs.adopt(foreignObject);
    }

    _render(transition) {
        this.svg.setBounds(this.bounds, transition);
    }
}

/**
 * creates the following structure:
 *   <foreignObject width="100%" height="100%">
 *     <iframe src="<user-defined src>" width="100%" height="100%"></iframe>
 *   </foreignObject>
 */
export class Embed extends BaseElement {
    getCanvasMargins() {
        return this.model.fullBleed
            ? { left: 0, top: 0, bottom: 0, right: 0 }
            : { left: 50, top: 50, bottom: 50, right: 50 };
    }

    get _showDefaultOverlay() {
        return this.model.sourceUrl ? false : true;
    }

    _cleanup() {
        // remove `this.iframe`, a native DOM element
        if (this.iframe) {
            this.iframe.remove();
        }
    }

    _build() {
        // we'll rebuild the DOM, so remove the iframe if it already exists.
        // WARNING even though the BaseElement render lifecycle method removes (from the DOM)
        //  elements with the previous render's generationKey, elements not created with
        //  BaseElement.addElement are "unregistered" and need to be cleaned up manually.
        //  this is a potential source of memory leaks and unpredictable behavior if component
        //  extensions of BaseElement don't clean themselves up.
        this._cleanup();

        // create a svg foreignObject to contain the iframe (an HTMLElement)
        this.foreignObject = this.addElement("embed", () => SVGForeignObjectElement);

        this.iframe = document.createElement("iframe");
        this.iframe.setAttribute("width", "100%");
        this.iframe.setAttribute("height", "100%");
        this.iframe.style["box-sizing"] = "border-box";
        // for debugging:
        this.iframe.style.border = "4px dashed red";

        // add iframe to the svg foreignObject
        this.foreignObject.svg.node.appendChild(this.iframe);

        // // set the initial embed URL
        // this.updateEmbedUrl()
    }

    _render() {
        // set the initial embed URL
        this.updateEmbedUrl();
    }

    _calcProps(props, options) {
        let { size } = props;
        this.foreignObject.bounds = new geom.Rect(0, 0, size);
        // this.rect.applyStyles({
        //     fill: "slide"
        // });
        return { size };
    }

    updateEmbedUrl() {
        if (!this.iframe || !this.model.sourceUrl) {
            return;
        }
        this.iframe.src = this.model.sourceUrl;
    }
}

export const elements = { Embed };

import React, { Component } from "react";
import styled from "styled-components";
import { DialogTitle, DialogContent } from "@material-ui/core";
import moment from "moment";

import Api from "js/core/api";
import { getStaticUrl } from "js/config";
import Spinner from "js/react/components/Spinner";
import { BeautifulDialog } from "js/react/components/Dialogs/BaseDialog";

const DialogContentWrapper = styled(DialogContent)`
    margin-bottom: 20px;
    
    & .file-list {
        display: flex;
        flex-direction: column;
        width: 100%;
        overflow-y: auto;
        padding: 10px 10px;
        min-height: 100px;
        gap: 10px;

        & .file-list-item {
            display: flex;
            height: 50px;
            gap: 20px;
            border-radius: 1px;
            box-shadow: 0px 2px 10px 0.001px #999;
            cursor: pointer;
            :hover {
                background-color: #f5f5f5;
            }
            :active {
                background-color: #e0e0e0;
            }

            & .file-list-item-left {
                width: 10%;
                display: flex;
                justify-content: flex-end;
                align-items: center;
            }
            & .file-list-item-right {
                width: 90%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                color: #333;
                font-size: 16px;
                
                & .last-modified-text {
                    font-size: 14px;
                }
            }
        }
    }

    & .empty-state-text {
        font-weight: 400;
        font-size: 18px;
        color: #666;
        padding: 0px 0px 20px 12px;
    }
`;

interface AirtableBase {
    id: string,
    name: string,
    tables: [{
        id: string,
        name: string,
        primaryFieldId: string,
        views: {
            id: string,
            name: string,
            type: string
        },
        fields: [{
            id: string,
            name: string,
            type: string,
        }]
    }],
    lastModifiedAt?: number
}

interface Props {
    token: string,
    handleSelectedFile: Function,
    closeDialog: Function
}

interface State {
    isPickerReady: boolean,
    bases: AirtableBase[]
}

export default class AirtableFilePickerDialog extends Component<Props, State> {
    state = {
        isPickerReady: false,
        bases: []
    }

    async componentDidMount() {
        const { token } = this.props;

        if (token) {
            const bases: AirtableBase[] = await Api.airtableSpreadsheets.get();
            this.setState({ isPickerReady: true, bases });
        }
    }

    handleSelectedFile = (base: AirtableBase) => {
        this.props.handleSelectedFile(base);
        this.props.closeDialog();
    }

    render() {
        const { closeDialog } = this.props;
        const { isPickerReady, bases } = this.state;

        const showEmptyState = !bases || !bases.length;
        const getLastModiedDate = (base: AirtableBase) => {
            if (base.lastModifiedAt) {
                return moment(base.lastModifiedAt).format("MM/DD/YYYY");
            }
        };

        return (
            <BeautifulDialog closeButton closeDialog={closeDialog}>
                <DialogTitle>Select a file</DialogTitle>
                <DialogContentWrapper>
                    {!isPickerReady && <Spinner />}
                    {isPickerReady && showEmptyState && (
                        <div className="empty-state-text">No files found</div>
                    )}
                    {isPickerReady && !showEmptyState && (
                        <div className="file-list">{
                            bases.map(base => (
                                <div className="file-list-item" onClick={() => this.handleSelectedFile(base)} key={base.id}>
                                    <div className="file-list-item-left">
                                        <img src={getStaticUrl("/images/data-linking/airtable.svg")} />
                                    </div>
                                    <div className="file-list-item-right">
                                        <span>{base.name}</span>
                                        <span className="last-modified-text">{getLastModiedDate(base)}</span>
                                    </div>
                                </div>
                            ))
                        }</div>
                    )}
                </DialogContentWrapper>
            </BeautifulDialog>
        );
    }
}

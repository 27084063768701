import React, { Component } from "react";
import { v4 as uuid } from "uuid";

import { WithLabel } from "../../../../Components/WithLabel";
import { ImagePopup } from "../../../../Components/ImagePopup";
import { NodeType, ResizeDirection } from "../../../../../common/constants";
import { Button } from "../../../../Components/Button";
import { ToggleSwitch } from "../../../../Components/ToggleSwitch";
import { ImageOption, ImageOptionList } from "../../../../Components/ImageOptionList";
import { StaticImage } from "../../../../Components/StaticImage";
import { ShowDialog, ShowDialogAsync } from "../../../../react/components/Dialogs/BaseDialog";
import BadFitDialog from "../../../../react/components/Dialogs/BadFitDialog";
import { Icon } from "../../../../Components/Icon";
import { defaultDragResizeProps } from "../../../../editor/PresentationEditor/DragElementManager";

import { getContentItemImageOptions } from "../EditorComponents/getContentItemImageOptions";
import { PropertyPanelContainer, PropertySection } from "../../../../EditorComponents/PropertyPanel";
import { ControlBar, ControlBarGroup } from "../ElementControlBars/Components/ControlBar";
import { ItemColorPicker } from "../EditorComponents/ColorPickers/ItemColorPicker";
import { CollectionColorPicker } from "../EditorComponents/ColorPickers/CollectionColorPicker";
import { CollectionItemElementSelection } from "../ElementSelections/CollectionItemElementSelection";
import { NumericStepper } from "../../../../Components/NumericStepper";

export class TimelinePropertyPanel extends Component {
    render() {
        const { element } = this.props;

        const { nodeType } = element.model.annotations?.items?.[0] ?? element.getDefaultAnnotationModel();
        let frameType = element.model.annotations.items[0]?.frameType;
        if (!frameType || frameType == "theme") {
            frameType = element.canvas.getTheme().get("styleShape");
        }

        return (
            <PropertyPanelContainer>
                <PropertySection>
                    <WithLabel label="Timeline Style" above left>
                        <ImageOptionList value={element.spanStyle}
                            onChange={value => {
                                if (value != "line" && element.model.spans.length === 0) {
                                    for (let i = 0; i < 3; i++) {
                                        element.model.spans.push({
                                            id: uuid()
                                        });
                                    }
                                }
                                element.updateModel({ spanStyle: value }, { refreshStyles: true });
                            }}
                            cols={3}
                        >
                            <ImageOption value="line" label="Line">
                                <StaticImage src="/images/ui/timeline/timeline-line.svg" />
                            </ImageOption>

                            <ImageOption value="chevron" label="Arrows">
                                <StaticImage src="/images/ui/timeline/timeline-arrow.svg" />
                            </ImageOption>
                            <ImageOption value="slice" label="Spans">
                                <StaticImage src="/images/ui/timeline/timeline-span.svg" />
                            </ImageOption>
                        </ImageOptionList>
                    </WithLabel>
                    {element.spanStyle == "line" && (
                        <>
                            <WithLabel label="Line Color">
                                <ItemColorPicker element={element} size={25} />
                            </WithLabel>
                            <WithLabel label="Line Width">
                                <NumericStepper value={element.timelineWidth}
                                    onChange={value => element.updateModel({ lineWidth: value })}
                                    min={1}
                                    max={15}
                                />
                            </WithLabel>
                        </>
                    )}
                    {element.spanStyle !== "line" && (
                        <WithLabel label="Span Colors">
                            <CollectionColorPicker element={element.timeline} showDecorationStyles />
                        </WithLabel>
                    )}
                </PropertySection>

                <PropertySection>
                    <WithLabel label="Show Start Marker">
                        <ToggleSwitch value={element.model.showStartMarker}
                            onChange={value => element.updateModel({ showStartMarker: value })}
                        />
                    </WithLabel>
                    <WithLabel label="Show End Marker">
                        <ToggleSwitch value={element.model.showEndMarker}
                            onChange={value => element.updateModel({ showEndMarker: value })}
                        />
                    </WithLabel>
                </PropertySection>

                <PropertySection>

                    <WithLabel label="Milestone Style">
                        <ImagePopup value={nodeType}
                            onChange={value => {
                                element.model.annotations.items.forEach(itemModel => {
                                    itemModel.nodeType = value;
                                    itemModel.frameType = null;
                                });
                                element.canvas.updateCanvasModel();
                            }}
                            size={70} previewSize={50}
                        >
                            {getContentItemImageOptions([NodeType.TEXT, NodeType.BULLET_TEXT, NodeType.NUMBERED_TEXT, NodeType.LETTERED_TEXT, NodeType.CONTENT_AND_TEXT])}
                        </ImagePopup>
                    </WithLabel>
                    <WithLabel label="Milestone Colors">
                        <CollectionColorPicker element={element.annotations} showDecorationStyles={nodeType != NodeType.TEXT && nodeType != NodeType.BULLET_TEXT} />
                    </WithLabel>

                    {nodeType === NodeType.CONTENT_AND_TEXT &&
                        <WithLabel label="Frame Style">
                            <ImagePopup value={frameType}
                                onChange={value => {
                                    element.model.annotations.items.forEach(itemModel => itemModel.frameType = value);
                                    element.canvas.updateCanvasModel();
                                }}
                                size={50} previewSize={30} gap={30}
                            >
                                <ImageOption value="none" label="None">
                                    <StaticImage src="/images/frames/thumbnails/frame_none.png" />
                                </ImageOption>
                                <ImageOption value="rect" label="Square">
                                    <StaticImage src="/images/frames/thumbnails/Square.svg" />
                                </ImageOption>
                                <ImageOption value="circle" label="Circle">
                                    <StaticImage src="/images/frames/thumbnails/Circle.svg" />
                                </ImageOption>
                                <ImageOption value="rounded_rect" label="Rounded">
                                    <StaticImage src="/images/frames/thumbnails/Rounded.svg" />
                                </ImageOption>
                                <ImageOption value="octagon" label="Octagon">
                                    <StaticImage src="/images/frames/thumbnails/Octagon.svg" />
                                </ImageOption>

                            </ImagePopup>
                        </WithLabel>
                    }

                </PropertySection>

                <PropertySection>
                    <Button small onClick={async () => {
                        await element.distributeMilestones();
                        await element.canvas.updateCanvasModel(true);
                    }}><Icon>stacks</Icon>Auto Arrange Milestones</Button>
                </PropertySection>
            </PropertyPanelContainer>
        );
    }
}

export class TimelineControlBar extends Component {
    onAddNewItem = async () => {
        const { element, selectionLayerController, selectedElements, position } = this.props;

        const currentSelection = [...selectedElements];
        selectionLayerController.setSelectedElements([]);

        try {
            const annotations = element.annotations;

            const annotationModel = element.getDefaultAnnotationModel();
            annotationModel.x = annotations.model.items.reduce((x, itemModel) => Math.max(x, itemModel.x), 0);
            annotationModel.x = (1 - annotationModel.x) / 2 + annotationModel.x;

            const annotation = annotations.addItem(annotationModel);

            await element.canvas.updateCanvasModel();

            selectionLayerController.setSelectedElements([annotations.getItemElementById(annotation.id)]);
        } catch (err) {
            selectionLayerController.setSelectedElements(currentSelection);

            ShowDialog(BadFitDialog, {
                title: "Sorry, we aren't able to fit another item to this layout",
            });
        }
    }

    onAddSpan = async () => {
        const { element } = this.props;

        if (element.spanStyle === "line") {
            element.model.spanStyle = "chevron";
        }
        element.model.spans.push({
            id: uuid()
        });
        // We're removing the decoration from the start/end of the timeline when adding a span
        // we want to be sure the color is set to the default color
        // of the slide and not the background it had before
        element.markStylesAsDirty();
        return element.canvas.updateCanvasModel().catch(err => {
            ShowDialogAsync(BadFitDialog, {
                title: "Sorry, we aren't able to fit another task on this chart",
            });
        });
    }

    render() {
        const { element, position } = this.props;

        const canAddNewItem = element.addItem && (element.maxItemCount ? (element.itemCount < element.maxItemCount) : true);

        return (
            <ControlBar position={position}>
                <ControlBarGroup>
                    <Button onClick={this.onAddNewItem} disabled={!canAddNewItem} blue>
                        <Icon>add_circle</Icon>
                        Add Milestone
                    </Button>
                </ControlBarGroup>
                {element.spanStyle !== "line" && (
                    <ControlBarGroup>
                        <Button onClick={this.onAddSpan} disabled={!canAddNewItem} blue>
                            <Icon>add_circle</Icon>
                            Add Span
                        </Button>
                    </ControlBarGroup>
                )}
            </ControlBar>
        );
    }
}

export class TimelineSpanControlBar extends Component {
    render() {
        const { element } = this.props;

        return (
            <ControlBar>
                <ItemColorPicker element={element} showDecorationStyles />
            </ControlBar>
        );
    }
}

export class TimelineSpanSelection extends CollectionItemElementSelection {
    get canResize() {
        return true;
    }

    get dragResizeProps() {
        const { element } = this.props;

        const initialWidths = [];
        let totalSliceWidth = null;
        let availableWidth = null;

        return {
            ...defaultDragResizeProps,
            resizeDirections: [element.isFirstItem ? null : ResizeDirection.LEFT, element.isLastItem ? null : ResizeDirection.RIGHT].filter(Boolean),
            onDragStart: async () => {
                element.parentElement.itemElements.forEach(element => {
                    initialWidths.push(element.model.width);
                });
                totalSliceWidth = element.parentElement.totalSpansWidth;
                availableWidth = element.parentElement.availableWidth;
            },
            onDrag: async ({ dragOffset, resizeDirection }) => {
                const elementIndex = element.itemIndex;

                const minItemWidth = element.styles.minWidth / availableWidth * totalSliceWidth;

                let offset = dragOffset.x / availableWidth * totalSliceWidth;

                if (resizeDirection === ResizeDirection.LEFT) {
                    const maxOffset = Math.max(initialWidths[elementIndex] - minItemWidth, 0);
                    const minOffset = -Math.max(initialWidths[elementIndex - 1] - minItemWidth, 0);
                    offset = Math.clamp(offset, minOffset, maxOffset);

                    const newWidth = initialWidths[elementIndex] - offset;
                    const newNeighborWidth = initialWidths[elementIndex - 1] + offset;

                    element.model.width = newWidth;
                    element.parentElement.itemElements[elementIndex - 1].model.width = newNeighborWidth;
                } else {
                    const maxOffset = Math.max(initialWidths[elementIndex + 1] - minItemWidth, 0);
                    const minOffset = -Math.max(initialWidths[elementIndex] - minItemWidth, 0);
                    offset = Math.clamp(offset, minOffset, maxOffset);

                    const newWidth = initialWidths[elementIndex] + offset;
                    const newNeighborWidth = initialWidths[elementIndex + 1] - offset;

                    element.model.width = newWidth;
                    element.parentElement.itemElements[elementIndex + 1].model.width = newNeighborWidth;
                }

                element.parentElement.refreshElement();
            }
        };
    }
}


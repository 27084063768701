import { AuthoringElementType, BlockStructureType, ForeColorType, HorizontalAlignType, TextStyleType } from "../../../../../../common/constants";
import * as geom from "../../../../../core/utilities/geom";

import { TextElement } from "../../base/Text/TextElement";
import { CalloutElement } from "./CalloutElement";

export class TextCallout extends CalloutElement {
    get authoringElementType() {
        return AuthoringElementType.TEXT;
    }

    get textAlign() {
        return this.model.textAlign ?? "center";
    }

    get fitToContents() {
        return {
            width: false,
            height: true
        };
    }

    get resizeDirections() {
        return {
            left: true,
            right: true,
            top: false,
            bottom: false
        };
    }

    get referencePoint() {
        return this.bounds.center;
    }

    get referencePointAnchor() {
        return geom.AnchorType.CENTER;
    }

    get connectionShape() {
        return {
            bounds: this.bounds,
            type: this.model.shape
        };
    }

    get canChangeColor() {
        return this.options.canChangeColor ?? false;
    }

    getDefaultConnectorColor() {
        if (this.canChangeColor) {
            return this.model.color ?? this.findClosestOfType("CollectionElement").collectionColor;
        }

        return ForeColorType.SECONDARY;
    }

    _build() {
        this.text = this.addElement("text", () => TextElement, {
            blockStructure: BlockStructureType.HEADER,
            defaultBlockTextStyle: TextStyleType.TITLE,
            allowedBlockTypes: this.options.allowedTextBlockTypes ?? null,
            autoHeight: true,
            isTextFit: true,
            scaleTextToFit: true,
            allowAlignment: true,
            canAddBlocks: this.canAddBlocks,
            syncFontSizeWithSiblings: this.syncFontSizeWithSiblings,
            textAlign: this.textAlign,
            canSelect: false,
            doubleClickToSelect: true,
            showTextBackdrop: true
        });
    }

    _calcProps(props) {
        const { size } = props;
        const textProps = this.text.calcProps(new geom.Size(size.width - this.styles.marginRight - this.styles.marginLeft, this.canvas.CANVAS_HEIGHT), {
            textAlign: HorizontalAlignType.CENTER,
        });

        const sizeHeight = textProps.size.height + this.styles.marginTop + this.styles.marginBottom;

        const middleLine = size.width / 2;
        textProps.bounds = new geom.Rect(middleLine - textProps.size.width / 2, sizeHeight / 2 - textProps.size.height / 2, textProps.size);
        return { size: new geom.Size(size.width, sizeHeight) };
    }
}

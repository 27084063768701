import { _ } from "js/vendor";
import * as geom from "js/core/utilities/geom";
import { HorizontalAlignType, TrayType, ContentBlockType, BlockStructureType } from "common/constants";

import { TextElement } from "../../../base/Text/TextElement";
import { SVGCircleElement } from "../../../base/SVGElement";
import { CollectionElement, CollectionItemElement } from "../../../base/CollectionElement";

class TextGrid extends CollectionElement {
    get defaultItemData() {
        return {
            title: { text: "" },
            body: { text: "" }
        };
    }
}

class TextGridItem extends CollectionItemElement {
    get name() {
        return "Item";
    }
}

class BulletPoints extends TextGrid {
    get forceSingleColumn() {
        return this.options.forceSingleColumn || this.model.forceSingleColumn;
    }

    get listStyle() {
        return this.options.listStyle || this.model.listStyle;
    }

    getChildItemType() {
        switch (this.model.listStyle) {
            case "numbered":
                return NumberedListItem;
            case "no_bullets":
                return TextListItem;
            case "bullets":
            default:
                return BulletPointItem;
        }
    }

    _loadStyles(styles) {
        //special case styling when there is a large tray
        if (this.canvas.model.layout.trayLayout && this.canvas.model.layout.trayLayout.equalsAnyOf(TrayType.RIGHT_INLINE, TrayType.RIGHT_TRAY, TrayType.LEFT_INLINE, TrayType.LEFT_TRAY) && this.canvas.model.elements.tray && this.canvas.model.elements.tray.trayWidth > 300) {
            styles.applyStyles(styles.withLargeTray);
        }
    }

    _calcProps(props, options) {
        let { size } = props;

        let layouter = this.getLayouter(props, this.itemElements, size);

        const calcLayout = scale => {
            layouter.calcColumnLayout({
                forceSingleColumn: this.forceSingleColumn,
                listStyle: this.listStyle
            });

            // Layouter is fit, exiting
            if (layouter.isFit) {
                return true;
            }

            // Reducing the scale by 10%
            const reducedScale = scale - 0.1;

            // We can't dowscale anymore
            if (reducedScale < 0.6) {
                return false;
            }

            // Downscaling the styles
            this.scaleStyleValues(reducedScale);

            // Trying again
            return calcLayout(reducedScale);
        };

        this.scaleStyleValues(1);

        props.isFit = calcLayout(1);

        layouter.alignHorizontally(this.styles.hGridAlign);
        layouter.alignVertically(this.styles.vGridAlign);

        return { size: layouter.size };
    }
}

class TextListItem extends TextGridItem {
    get minHeight() {
        return this.text.minHeight;
    }

    get _passThroughSelection() {
        return false;
    }

    _build() {
        this.text = this.addElement("text", () => TextElement, {
            blockStructure: BlockStructureType.TITLE_AND_BODY,
            allowedBlockTypes: [ContentBlockType.HEADING, ContentBlockType.TITLE, ContentBlockType.BODY, ContentBlockType.BULLET_LIST, ContentBlockType.NUMBERED_LIST],
            autoHeight: true,
        });
    }

    _calcProps(props, options) {
        let { size } = props;

        let textProps = this.text.calcProps(size);
        props.isFit = true;

        return { size: textProps.size };
    }
}

class BulletPointItem extends TextListItem {
    getElementTreeType() {
        return "TextListItem";
    }

    _build() {
        this.bullet = this.addElement("bullet", () => SVGCircleElement);
        this.text = this.addElement("text", () => TextElement, {
            blockStructure: BlockStructureType.TITLE_AND_BODY,
            autoHeight: true,
        });
    }

    _calcProps(props, options) {
        let { size } = props;

        // if (options.scale) {
        //     styles.scaleTextStyles(options.scale);
        // }

        if (options.bulletAlign == "right") {
            this.text.styles.textAlign = HorizontalAlignType.RIGHT;
            this.text.styles.marginRight = this.text.styles.marginLeft;
            this.text.styles.marginLeft = 0;
        }

        let layouter = this.getLayouter(props, [this.bullet, this.text], size);
        layouter.calcHorizontalBlockLayout({
            contentSize: new geom.Size(this.styles.bullet.width, this.styles.bullet.height),
            verticalAlign: layouter.VerticalBlockAlignType.MIDDLE_TITLE,
            reverse: options.bulletAlign == "right"
        });

        props.isFit = this.text.calculatedProps.isTextFit;

        return { size: layouter.size };
    }
}

class NumberedListItem extends TextListItem {
    getElementTreeType() {
        return "TextListItem";
    }

    _build() {
        this.index = this.addElement("index", () => TextElement, {
            model: {
                index: this.itemIndex + 1
            },
            canEdit: false,
            isTabbable: false
        });
        this.text = this.addElement("text", () => TextElement, {
            blockStructure: BlockStructureType.TITLE_AND_BODY,
            autoHeight: true,
        });
    }

    _calcProps(props, options) {
        let { size } = props;

        // if (options.scale) {
        //     this.scaleStyleValues(options.scale);
        // }

        if (options.bulletAlign == "right") {
            this.text.styles.textAlign = HorizontalAlignType.RIGHT;
            this.text.styles.marginRight = this.text.styles.marginLeft;
            this.text.styles.marginLeft = 0;
        }

        let layouter = this.getLayouter(props, [this.index, this.text], size);
        layouter.calcHorizontalBlockLayout({
            verticalAlign: layouter.VerticalBlockAlignType.MIDDLE_TITLE,
            reverse: options.bulletAlign == "right"
        });

        return { size: layouter.size };
    }
}

export {
    TextGrid,
    BulletPoints,
    BulletPointItem,
    NumberedListItem,
    TextListItem
};

export const elements = {
    BulletPoints
};

import * as geom from "js/core/utilities/geom";

import { TextElement } from "../../base/Text/TextElement";
import { OrgChartNode } from "./OrgChartNode";
import { BlockStructureType, TextStyleType, HorizontalAlignType } from "common/constants";

export class MinimalOrgChartNode extends OrgChartNode {
    _build() {
        this.text = this.addElement("text", () => TextElement, {
            blockStructure: BlockStructureType.TITLE_AND_BODY,
            allowedBlockTypes: [TextStyleType.TITLE, TextStyleType.BODY],
            autoWidth: false,
            autoHeight: true,
            requireParentSelection: true,
            passThroughSelection: false,
            allowAlignment: true,
            syncFontSizeWithSiblings: true,
            getSiblings: () => {
                return this.getRootElement().findChildElements("TextElement");
            },
            title: {
                placeholder: "Type name"
            },
            body: {
                placeholder: "Type title"
            }
        });
    }

    _calcProps(props, options) {
        const { size } = props;
        if (this.parentElement.isRowSmall(this.rowIndex) && this.styles.small) {
            this.updateStyles(this.styles.small, true);
        }

        if (this.model.isAssistant) {
            options.textAlign = options.assistantNodeSide == 1 ? HorizontalAlignType.LEFT : HorizontalAlignType.RIGHT;
        }
        if (options.isStacked) {
            options.textAlign = HorizontalAlignType.LEFT;
        }

        const textProps = this.text.calcProps(new geom.Size(size.width, size.height), options);
        textProps.bounds = new geom.Rect(0, 0, textProps.size);

        return { size: textProps.size };
    }
}

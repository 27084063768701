import { SlideCanvas } from "./canvas/canvas";
import * as slideTemplates from "./slideTemplates/slideTemplates";
import { SlideMenuBar } from "../../editor/ElementEditor/SlideMenuBar";
import { SelectedElementPropertyPanel } from "./Editor/EditorComponents/RootPanes/SelectedElementPropertyPanel";

export { SlideCanvas, slideTemplates, SlideMenuBar, SelectedElementPropertyPanel };

// DEV-START
if (import.meta.webpackHot) {
    import.meta.webpackHot.accept(err => {
        // eslint-disable-next-line no-console
        console.error(err);
    });
}
// DEV-END

import React from "react";
import { ElementBlockEditor } from "./ElementBlockEditor";
import { BoundsBox } from "../../SelectionBox";
import { themeColors } from "../../../../../../../react/sharedStyles";
import { BlockTypePopupMenu } from "./TextFormatBar";
import { PopupMenu } from "../../../../../../../react/components/PopupMenu";
import { MenuItem } from "@material-ui/core";

import { AuthoringCanvasControlBar, ControlBarGroup } from "../../AuthoringCanvasControlBar";

export class StartRegionBlockEditor extends ElementBlockEditor {
    handleChangeStyle = type => {
        const { block, refreshCanvasAndSaveChanges } = this.props;
        block.model.regionType = type;
        refreshCanvasAndSaveChanges();
    }

    render() {
        const { bounds, block, editorConfig, refreshCanvasAndSaveChanges } = this.props;
        const { height } = this.state;

        return (
            <BoundsBox bounds={bounds}>
                <AuthoringCanvasControlBar position="above" offset={10}>
                    <ControlBarGroup color={themeColors.darkBlue}>
                        <BlockTypePopupMenu
                            selectedBlocks={[block]}
                            allowedBlockTypes={editorConfig.allowedBlockTypes}
                            onChange={refreshCanvasAndSaveChanges}
                        />
                    </ControlBarGroup>
                    <ControlBarGroup>
                        <PopupMenu label="Style" showArrow onChange={this.handleChangeStyle} childrenAreMenuItems>
                            <MenuItem value="inset">Inset</MenuItem>
                            <MenuItem value="color">Color</MenuItem>
                        </PopupMenu>
                    </ControlBarGroup>
                </AuthoringCanvasControlBar>
            </BoundsBox>
        );
    }
}

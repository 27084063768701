import * as geom from "js/core/utilities/geom";
import { _ } from "js/vendor";
import React from "react";
import { ImageOption } from "../../../../../../Components/ImageOptionList";
import { themeColors } from "../../../../../../react/sharedStyles";
import { FlexGridItemSelection } from "../../../../Editor/ElementPropertyPanels/GridContainer/FlexGridSelection";

export class FlexGridLayout {
    constructor(element, props) {
        this.element = element;
        this.layouts = props.layouts;
    }

    getName() {
        return "Flex Grid";
    }

    getContainerSelection() {
        return null;
    }

    getCellSelection() {
        return FlexGridItemSelection;
    }

    getDefaultLayout(itemCount) {
        return {
            cols: 12,
            rows: 12,
            ...this.layouts.find(layout => layout.cells.length === itemCount)
        };
    }

    calcCellProps(container, props) {
        let { size, gridLayout, outerGutter = 0, innerGutter = 0 } = props;

        size = size.deflate(outerGutter);

        let hGap = innerGutter;
        let vGap = innerGutter;

        let cols = gridLayout.cols ?? 12;
        let rows = gridLayout.rows ?? 12;

        let colWidth = (size.width - (cols - 1) * innerGutter) / cols;
        let rowHeight = (size.height - (rows - 1) * innerGutter) / rows;

        let cells = gridLayout.cells;

        let outOfBounds = [];

        for (let i = 0; i < cells.length; i++) {
            let gridCell = gridLayout.cells[i];
            let item = container.itemElements[i];
            if (!item) {
                continue;
            }
            let itemBounds = new geom.Rect(gridCell.x, gridCell.y, Math.max(gridCell.width, 1), Math.max(gridCell.height, 1));

            if (itemBounds.left > cols - 1 || itemBounds.top > rows - 1) {
                outOfBounds.push(item);
                continue;
            }
            if (itemBounds.left + itemBounds.width > cols) {
                itemBounds.width = cols - itemBounds.left;
                // item.model.width = itemBounds.width;
            }
            if (itemBounds.top + itemBounds.height > rows) {
                itemBounds.height = rows - itemBounds.top;
                // item.model.height = itemBounds.height;
            }

            let x = outerGutter + itemBounds.left * (colWidth + hGap);
            let y = outerGutter + itemBounds.top * (rowHeight + vGap);
            let width = itemBounds.width * (colWidth + hGap) - hGap;
            let height = itemBounds.height * (rowHeight + vGap) - vGap;

            let itemProps = item.calcProps(new geom.Size(width, height));
            itemProps.bounds = new geom.Rect(x, y, width, height);
            itemProps.gridBounds = itemBounds;
        }

        return { size, colWidth, rowHeight, cols, rows, hGap, vGap, gridLayout };
    }

    renderThumbnails(layouts, width = 84, height = 50) {
        let gridThumbnails = [];

        for (let i = 0; i < layouts.length; i++) {
            let layout = _.clone(layouts[i]);

            layout.cols = layout.cols ?? 12;
            layout.rows = layout.rows ?? 12;

            let gap = 4;

            // gridLayout
            let colWidth = (width - (layout.cols - 1) * gap - gap * 2) / layout.cols;
            let rowHeight = (height - (layout.rows - 1) * gap - gap * 2) / layout.rows;

            gridThumbnails.push(
                <ImageOption key={i} value={layout}>
                    <svg width="100%" height="100%" viewBox={`0 0 ${width} ${height}`}>
                        {layout.cells?.map((cell, index) => {
                            let fill = themeColors.ui_blue;
                            if (cell.width <= 4 && cell.height <= 4) {
                                fill = themeColors.ui_blue;
                            }
                            return (
                                <rect key={index} x={gap + cell.x * (colWidth + gap)}
                                    y={gap + cell.y * (rowHeight + gap)} width={cell.width * (colWidth + gap) - gap}
                                    height={cell.height * (rowHeight + gap) - gap} fill={fill} />
                            );
                        })}
                    </svg>
                </ImageOption>
            );
        }
        return gridThumbnails;
    }

    static fillEmptySlots(container) {
        let emptyCells = [];

        for (let row = 0; row < container.gridLayout.rows; row++) {
            for (let col = 0; col < container.gridLayout.cols; col++) {
                let found = _.find(container.gridLayout.cells, cell => {
                    return (
                        col >= cell.x &&
                        col < cell.x + cell.width &&
                        row >= cell.y &&
                        row < cell.y + cell.height
                    );
                });
                if (!found) {
                    emptyCells.push(new geom.Rect(col, row, 1, 1));
                }
            }
        }

        if (emptyCells.length > 0) {
            let newCell = FlexGridLayout.combineCells(emptyCells);
            container.gridLayout.cells.push(newCell.toXYObject());
            container.addItem({
                cellColor: _.last(container.itemCollection).cellColor
            });
        }
    }

    static combineCells(availableCells) {
        let canGrowRight = function(box) {
            let canGrow = true;
            for (let y = box.top; y < box.bottom; y++) {
                if (!_.find(availableCells, function(target) {
                    return target.top === y && target.left === box.right;
                })) {
                    canGrow = false;
                }
            }
            return canGrow;
        };
        let canGrowDown = function(box) {
            let canGrow = true;
            for (let x = box.left; x < box.right; x++) {
                if (!_.find(availableCells, function(target) {
                    return target.left === x && target.top === box.bottom;
                })) {
                    canGrow = false;
                }
            }
            return canGrow;
        };

        let areas = [];
        //combine the adjacent boxes to form bigger boxes
        for (let box of availableCells) {
            // first check right - down
            let area = box.clone();
            while (canGrowRight(area)) {
                area.width += 1;
            }
            while (canGrowDown(area)) {
                area.height += 1;
            }
            areas.push(area);

            // now check down - right
            area = box.clone();
            while (canGrowDown(area)) {
                area.height += 1;
            }
            while (canGrowRight(area)) {
                area.width += 1;
            }
            areas.push(area);
        }

        //find the biggest combined area
        return _.maxBy(areas, function(area) {
            return area.width * area.height;
        });
    }
}


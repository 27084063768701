import React, { Component } from "react";

import styled from "styled-components";
import { DefaultOverlayContainer } from "../../../ElementDefaultOverlays/Components/DefaultOverlayContainer";
import { PropertyPanelContainer, PropertySection } from "../../../../../../EditorComponents/PropertyPanel";
import { WithLabel } from "../../../../../../Components/WithLabel";
import { ToggleSwitch } from "../../../../../../Components/ToggleSwitch";
import { ControlBar } from "../../../ElementControlBars/Components/ControlBar";
import { ItemColorPicker } from "../../../EditorComponents/ColorPickers/ItemColorPicker";
import { Popup, PopupContainer, PopupContent } from "../../../../../../Components/Popup";
import { ToggleButton, ToggleButtons } from "../../../../../../Components/ToggleButtons";
import { HorizontalAlignType } from "../../../../../../../common/constants";
import { Icon } from "../../../../../../Components/Icon";

export class StatisticPropertyPanel extends Component {
    render() {
        const { element } = this.props;

        return (
            <PropertyPanelContainer>
                <PropertySection>
                    <WithLabel label="Show Change in Value">
                        <ToggleSwitch
                            value={element.model.showChangeInValue}
                            onChange={value => element.updateModel({ showChangeInValue: value })}
                        />
                    </WithLabel>
                    <WithLabel label="Show Note">
                        <ToggleSwitch
                            value={element.model.showNote}
                            onChange={value => element.updateModel({ showNote: value })}
                        />
                    </WithLabel>
                </PropertySection>
                <PropertySection>
                    <WithLabel label="Align">
                        <ToggleButtons value={element.horizontalAlign}
                            onChange={value => {
                                if (element.parentElement.showTitle) {
                                    element.parentElement.chartTitle.model.textAlign = value;
                                }
                                element.updateModel({ horizontalAlign: value });
                            }}
                        >
                            <ToggleButton size="small" value={HorizontalAlignType.LEFT}>
                                <Icon>format_align_left</Icon>
                            </ToggleButton>
                            <ToggleButton size="small" value={HorizontalAlignType.CENTER}>
                                <Icon>format_align_center</Icon>
                            </ToggleButton>
                            <ToggleButton size="small" value={HorizontalAlignType.RIGHT}>
                                <Icon>format_align_right</Icon>
                            </ToggleButton>
                        </ToggleButtons>
                    </WithLabel>
                </PropertySection>

            </PropertyPanelContainer>
        );
    }
}

const Container = styled(DefaultOverlayContainer)`
    cursor: pointer;
    pointer-events: auto;
    border: none;
`;

export class StatisticChangeInValueIconDefaultOverlay extends Component {
    handleMouseUp = () => {
        const { element } = this.props;
        return element.updateModel({ positive: !element.model.positive }, { refreshStyles: true });
    };

    render() {
        return (
            <Container
                background={false}
                outline={false}
                onMouseUp={this.handleMouseUp}
            />
        );
    }
}

export class StatisticControlBar extends Component {
    render() {
        const { element } = this.props;

        return (
            <ControlBar>
                <ItemColorPicker element={element} />
                <Popup icon="settings">
                    <PopupContent>
                        <PopupContainer>
                            <WithLabel label="Show Label" tight={false}>
                                <ToggleSwitch
                                    value={element.model.showLabel}
                                    onChange={value => element.updateModel({ showLabel: value })}
                                />
                            </WithLabel>
                            <WithLabel label="Show Change in Value" tight={false}>
                                <ToggleSwitch
                                    value={element.model.showChangeInValue}
                                    onChange={value => element.updateModel({ showChangeInValue: value })}
                                />
                            </WithLabel>
                            {/*<WithLabel label="Show Description" tight={false}>*/}
                            {/*    <ToggleSwitch*/}
                            {/*        value={element.model.showDescription}*/}
                            {/*        onChange={value => element.updateModel({ showDescription: value })}*/}
                            {/*    />*/}
                            {/*</WithLabel>*/}
                        </PopupContainer>
                    </PopupContent>
                </Popup>
            </ControlBar>
        );
    }
}


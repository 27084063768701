import React from "react";

import { Slider } from "js/Components/Slider";

import { ControlBar, ControlBarButton } from "./Components/ControlBar";
import { PictureElementControlBar } from "./PictureElementControlBar";

import { MediaPopup } from "../EditorComponents/MediaPopup";
import { Icon } from "../../../../Components/Icon";

export class VideoElementControlBar extends PictureElementControlBar {
    handleResetImageTransform = () => {
        const { element } = this.props;

        element.resetMediaTransform();
        element.canvas.updateCanvasModel();
    }

    render() {
        const { element } = this.props;

        const hasMedia = element.hasMedia;
        if (!hasMedia) {
            return null;
        }

        this.elementSize = element.calculatedProps?.size ?? this.elementSize;

        const scale = element.getScale(this.elementSize);
        const minScale = element.minScale(this.elementSize);
        const maxScale = element.maxScale(this.elementSize);

        const adjustedScaleValue = Math.round((scale - minScale) / (maxScale - minScale) * 100);

        const isSmall = element.calculatedProps.size.width < 200 || element.calculatedProps.size.height < 200;

        return (
            <ControlBar top={isSmall ? undefined : "calc(100% - 32px)"}>
                <MediaPopup element={element.parentElement} canAdjust={false} />

                <Slider
                    min={0}
                    max={100}
                    step={1}
                    value={adjustedScaleValue}
                    onSlider={scale => this.handleSetElementScale(scale, minScale, maxScale, false)}
                    onChange={scale => this.handleSetElementScale(scale, minScale, maxScale, true)}
                />
                <ControlBarButton onClick={this.handleResetImageTransform} tooltip="Reset crop and zoom">
                    <Icon>settings_overscan</Icon>
                </ControlBarButton>
            </ControlBar>
        );
    }
}

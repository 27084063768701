import React, { Component } from "react";
import styled from "styled-components";

import { IconPicker } from "../../../../Components/IconPicker";
import { Popup, PopupContent, PopupPreview, PopupContainer } from "../../../../Components/Popup";

import { DefaultOverlayContainer } from "./Components/DefaultOverlayContainer";

export const DEFAULT_LIST_ICONS = ["star", "star-half", "star-none", "thumbs-up", "thumbs-down", "question", "warning", "full", "quarters-three", "contrast", "quarter"];

const ListDecorationDefaultOverlayContainer = styled(DefaultOverlayContainer)`
    cursor: pointer;
    pointer-events: auto;
`;

export class CheckListDecorationDefaultOverlay extends Component {
    handleMouseUp = () => {
        const { element } = this.props;

        const blockId = element.options.blockId;
        const blockModel = element.parentElement.getBlockById(blockId);
        if (!blockModel) {
            return null;
        }

        switch (blockModel.checkState) {
            case "unchecked":
                blockModel.checkState = "checked";
                break;
            case "crossed-out":
                blockModel.checkState = "unchecked";
                break;
            case "checked":
            default:
                blockModel.checkState = "crossed-out";
                break;
        }

        element.saveModel(false, true);
    };

    render() {
        return (
            <ListDecorationDefaultOverlayContainer
                background={false}
                outline={false}
                onMouseUp={this.handleMouseUp}
            />
        );
    }
}

export class IconListDecorationDefaultOverlay extends Component {
    render() {
        const { element } = this.props;

        const blockId = element.options.blockId;
        const blockModel = element.parentElement.getBlockById(blockId);
        if (!blockModel) {
            return null;
        }

        const currentIconId = blockModel.listIconId ?? "star";

        return (
            // <ListDecorationDefaultOverlayContainer>
            <Popup>
                <PopupPreview>
                    <ListDecorationDefaultOverlayContainer
                        background={false}
                        outline={false}
                    />
                </PopupPreview>
                <PopupContent>
                    {closePopup => (
                        <PopupContainer>
                            <IconPicker
                                icons={[...new Set([currentIconId, ...DEFAULT_LIST_ICONS])]}
                                value={currentIconId}
                                width={330}
                                canChooseIcon
                                onChange={iconId => {
                                    blockModel.listIconId = iconId;
                                    element.saveModel(false, true);
                                    closePopup();
                                }} />
                        </PopupContainer>
                    )}
                </PopupContent>
            </Popup>
            // </ListDecorationDefaultOverlayContainer>
        );
    }
}

import { DecorationType } from "common/constants";
import { sanitizeSvg } from "js/core/utilities/dompurify";
import * as geom from "js/core/utilities/geom";
import { Shape } from "js/core/utilities/shapes";
import { getHTMLProps, getSVGStyleProps, SVGGroup } from "js/core/utilities/svgHelpers";
import { _, tinycolor } from "js/vendor";
import React from "reactn";

import { BaseElement } from "./BaseElement";

export class DecorationElement extends BaseElement {
    constructor(props) {
        super(props);

        this.backgroundRef = React.createRef();
        this.foregroundRef = React.createRef();
    }

    get _canSelect() {
        return false;
    }

    get _canRollover() {
        return false;
    }

    get isDecoration() {
        return true;
    }

    get DOMNode() {
        return null;
    }

    get decorationStyle() {
        // ensure that we let the parent decide which decoration style to use
        return this.parentElement.decorationStyle;
    }

    _calcProps(props, options) {
        let { size } = props;
        return { size };
    }

    renderElement(transition) {
        throw new Error("Decoration element must be rendered with renderDecorationElement()");
    }

    hasStyleValueDefined(styleValue) {
        if (!styleValue) {
            return false;
        }

        if (styleValue === "none") {
            return false;
        }

        return true;
    }

    _applyColors() {
        if (this.styles.shape == "none") {
            // don't set any colors if shape is none
            return;
        }

        // if (this.styles.fillColor && this.styles.fillColor != "slide") {
        //     this.colorSet.fillColor = this.palette.getColor(this.styles.fillColor);
        //     if (this.styles.fillOpacity) {
        //         this.colorSet.fillColor.setAlpha(this.styles.fillOpacity);
        //     }
        // }
        // if (this.styles.strokeColor && this.styles.strokeColor != "slide") {
        //     this.colorSet.strokeColor = this.palette.getColor(this.styles.strokeColor);
        //     if (this.styles.strokeOpacity) {
        //         this.colorSet.strokeColor.setAlpha(this.styles.strokeOpacity);
        //     }
        // }

        let decorationFillColor, decorationStrokeColor, backgroundColor;

        if (this.colorSet.fillColor || this.colorSet.strokeColor) {
            // if fill or stroke is set manually, use it
            decorationFillColor = this.colorSet.fillColor;
            decorationStrokeColor = this.colorSet.strokeColor;
            backgroundColor = decorationFillColor;
        } else {
            ({ decorationFillColor, decorationStrokeColor, backgroundColor } = this.palette.getDecorationColors(this.getDecorationColor(), this.decorationStyle, this.getHiliteType()));
        }
        if (this.styles.type === "svg") {
            this.colorSet.decorationColor = this.palette.getColor(this.getDecorationColor(), this.getBackgroundColor());
        } else {
            this.colorSet.backgroundColor = backgroundColor;
        }
        this.colorSet.fillColor = decorationFillColor;
        this.colorSet.strokeColor = decorationStrokeColor;

        this.styles.fillColor = decorationFillColor?.toRgbString() ?? "none";
        this.styles.strokeColor = decorationStrokeColor?.toRgbString() ?? "none";
        if (this.styles.strokeColor && !this.styles.strokeWidth) {
            this.styles.strokeWidth = this.canvas.styleSheet.$shapeStrokeWidth;
        }

        // Saved colors if previously converted to classic slide
        if (this.parentElement?.blockModel?.convertedColorSet) {
            if (this.parentElement.blockModel.convertedColorSet.strokeColor) {
                let strokeColor = tinycolor(this.parentElement.blockModel.convertedColorSet.strokeColor);
                this.colorSet.strokeColor = strokeColor; // Used when elements are being moved
                this.styles.strokeColor = strokeColor; // Used when elements are not being moved
                this.colorSet.backgroundColor = strokeColor; // Used for numbered list colors (not sure about other decorations)
            }
            if (this.parentElement.blockModel.convertedColorSet.fillColor) {
                let fillColor = tinycolor(this.parentElement.blockModel.convertedColorSet.fillColor);
                this.colorSet.fillColor = fillColor; // Used when elements are being moved
                this.styles.fillColor = fillColor; // Used when elements are not being moved
            }
        }
    }

    /**
     * Renders the decoration, returns an array where the first element is a background decoration
     * which should be placed behind the parent element, and the second element is a foreground that
     * should be placed in front of the parent element.
     * WARNING: background and/or foreground can be null!
     * @returns [background, foreground]
     */
    renderDecorationElement(props, transition) {
        this.hasForeground = this.hasBackground = false;

        if (this.styles.type === "svg") {
            // Always putting svg decorations on the background
            this.hasBackground = true;
            return [this.renderSVG(props, "background", this.backgroundRef, transition), null];
        }

        let background, foreground = null;

        if (this.hasStyleValueDefined(this.styles.fillColor) || this.hasStyleValueDefined(this.styles.fill)) {
            background = this.renderDecoration(props, this.styles, "background", this.backgroundRef, transition);
            // Used for ppt export
            this.hasBackground = true;
        }

        if (this.hasStyleValueDefined(this.styles.strokeColor) || this.hasStyleValueDefined(this.styles.stroke)) {
            const foregroundStyles = _.cloneDeep(this.styles);
            delete foregroundStyles.fillColor;
            delete foregroundStyles.resolved_fillColor;
            if (background) {
                delete foregroundStyles.filter; // Don't double render filter shadow if already applied to fill
                delete foregroundStyles.shadow;
            }
            foreground = this.renderDecoration(props, foregroundStyles, "foreground", this.foregroundRef, transition);
            // Used for ppt export
            this.hasForeground = true;
        }

        this.isRendered = true;

        return [background, foreground];
    }

    renderDecoration(props, styles, key, ref, transition) {
        switch (styles.shape) {
            case DecorationType.RECT:
                return this.renderRect(props, styles, key, ref, transition);
            case DecorationType.ROUNDED_RECT:
                styles.cornerRadius = 20;
                return this.renderRect(props, styles, key, ref, transition);
            case DecorationType.CIRCLE:
                return this.renderCircle(props, styles, key, ref, transition);
            case DecorationType.OCTAGON:
                return this.renderOctagon(props, styles, key, ref, transition);
        }
    }

    renderRect(props, styles, key, ref, transition) {
        return <div key={key} ref={ref} {...getHTMLProps(props, styles, transition)} />;
    }

    renderCircle(props, styles, key, ref, transition) {
        const bounds = props.bounds.square().centerInRect(props.bounds);
        const htmlProps = getHTMLProps({ ...props, bounds }, styles, transition);
        htmlProps.style.borderRadius = "50%";

        return <div key={key} ref={ref} {...htmlProps} />;
    }

    renderOctagon(props, styles, key, ref, transition) {
        const size = props.bounds.size.square();
        const polygon = Shape.drawOctagon(size).offset(new geom.Rect(0, 0, size).centerInRect(props.bounds).position).toPolygonData();

        return (
            <SVGGroup key={key}>
                <polygon
                    ref={ref}
                    id={this.id}
                    points={polygon}
                    style={getSVGStyleProps(styles)}
                />
            </SVGGroup>
        );
    }

    renderSVG(props, key, ref, transition) {
        let { bounds } = props;

        let svgDef = this.styles.def;
        svgDef = svgDef.replace(/(\$.*?)(?=[ }'"])/gm, match => {
            return this.canvas.styleSheet.variables[match.substr(1)];
        });

        // replace palette colors in svg definition
        svgDef = svgDef.replace(/fill='(.*?)'|stroke='(.*?)'/g, (str, fillColor, strokeColor) => {
            if (fillColor && !fillColor.startsWith("url")) {
                if (fillColor == "decoration") {
                    str = str.replace(fillColor, this.getDecorationColor().toRgbString());
                } else if (fillColor == "primary" || fillColor == "secondary") {
                    str = str.replace(fillColor, this.palette.getColor(fillColor, this.getBackgroundColor()).toRgbString());
                } else {
                    str = str.replace(fillColor, this.styles.fillColor);
                }
            }
            if (strokeColor) {
                if (strokeColor == "decoration") {
                    str = str.replace(strokeColor, this.getDecorationColor().toRgbString());
                } else if (strokeColor == "primary" || strokeColor == "secondary") {
                    str = str.replace(strokeColor, this.palette.getColor(strokeColor, this.getBackgroundColor()).toRgbString());
                } else {
                    str = str.replace(strokeColor, this.styles.strokeColor);
                }
            }
            return str;
        });

        let decorationSVG;
        try {
            decorationSVG = {
                __html: sanitizeSvg(this.parseSVGForDecoration(svgDef, props, this.styles, this.parentElement))
            };
        } catch {
            return null;
        }

        return (
            <SVGGroup key={key}>
                <g ref={ref} dangerouslySetInnerHTML={decorationSVG} style={{ transition: "fill 300ms" }}></g>
            </SVGGroup>
        );
    }

    parseSVGForDecoration(def, props, styles, parentElement) {
        let parentProps = (parentElement.isInstanceOf("TextElement") ? parentElement : parentElement.findChildElements("TextElement")[0]).calculatedProps;
        let paddedBounds = props.bounds.deflate(styles.margins || 0);

        const svg = def.replace(/#{(.*?)}/g, (match, value) => {
            let expression = "";
            for (let component of value.split(" ")) {
                if (component.startsWith("getBlock")) {
                    let textStyle = component.match(/\((.*?)\)/)[1];
                    let matchedBlocks = parentProps.blockProps.filter(b => b.textStyle == textStyle);

                    if (matchedBlocks.length) {
                        let startIndex = matchedBlocks[0].index;
                        let bounds;
                        for (let block of matchedBlocks) {
                            if (matchedBlocks.indexOf(block) == 0 || block.index == startIndex + matchedBlocks.indexOf(block)) {
                                bounds = bounds ? bounds.union(block.textBounds) : block.textBounds;

                                //special-case for block decoration in header
                                if (styles.forceBackgroundColor) {
                                    // block.forceBackgroundColor = styles.forceBackgroundColor;
                                    block.forceBackgroundColor = this.canvas.getTheme().palette.getForeColor(styles.forceBackgroundColor, this.getSlideColor(), this.getBackgroundColor());
                                }
                            } else {
                                break;
                            }
                        }
                        expression += bounds.offset(parentElement.loadedStyles.paddingLeft, parentElement.loadedStyles.paddingTop)[component.split(".")[1]];
                    } else {
                        throw new Error();
                    }
                } else {
                    switch (component) {
                        case "tw":
                            expression += parentProps.textBounds.width;
                            break;
                        case "th":
                            // expression += parentProps.textBounds.height;
                            expression += parentProps.blockProps[0].fontHeight;
                            break;
                        case "tl":
                            expression += parentProps.bounds.left + parentProps.textBounds.left;
                            break;
                        case "tr":
                            expression += parentProps.bounds.left + parentProps.textBounds.right;
                            break;
                        case "tc":
                            expression += parentProps.bounds.left + parentProps.bounds.width / 2;
                            break;
                        case "tm":
                            expression += parentProps.blockProps[0].textBounds.centerV;
                            break;
                        case "tt":
                            expression += parentProps.blockProps[0].textBounds.top - parentProps.blockProps[0].topSpace;
                            break;
                        case "tb":
                            expression += parentProps.blockProps[0].textBounds.bottom;
                            break;
                        case "ct":
                            expression += parentProps.bounds.width / 2 - parentProps.textBounds.width / 2;
                            break;
                        case "first_char_width":
                            // expression += parentProps.textLayout.lines[0].words[0].glyphWidths[0] * 2;
                            break;
                        case "first_char_left":
                            // expression += parentProps.textLayout.lines[0].words[0].x;
                            break;
                        case "first_char_top":
                            // expression += parentProps.textLayout.lines[0].words[0].y;
                            break;
                        case "last_char_right":
                            // expression += _.last(_.last(parentProps.textLayout.lines).words).x + _.last(_.last(parentProps.textLayout.lines).words).width;
                            break;
                        case "last_char_top":
                            // expression += _.last(_.last(parentProps.textLayout.lines).words).y;
                            break;
                        case "marginBottom":
                            expression += parentProps.styles.marginBottom || 0;
                            break;
                        case "canvasLeft":
                            expression = -parentElement.canvasBounds.left;
                            break;
                        case "canvasTop":
                            expression = -parentElement.canvasBounds.top;
                            break;
                        case "canvasWidth":
                            expression = parentElement.canvas.CANVAS_WIDTH;
                            break;
                        case "canvasHeight":
                            expression = parentElement.canvas.CANVAS_HEIGHT;
                            break;
                        default:
                            if (component.indexOf("w") > -1) {
                                expression += paddedBounds.width * (parseInt(component) / 100);
                            } else if (component.indexOf("h") > -1) {
                                expression += paddedBounds.height * (parseInt(component) / 100);
                            } else {
                                expression += component;
                            }
                    }
                }
                expression += " ";
            }

            return eval(expression);
        });
        if (svg == "") {
            return "<g/>";
        } else {
            return svg;
        }
    }
}

export const elements = {
    DecorationElement
};

import React from "react";
import styled from "styled-components";
import { v4 as uuid } from "uuid";
import { _ } from "js/vendor";
import * as geom from "js/core/utilities/geom";
import { CollectionElement, CollectionItemElement } from "../../base/CollectionElement";
import { Path, Shape } from "js/core/utilities/shapes";
import { TextElement } from "../../base/Text/TextElement";
import { AuthoringBlockType, BlockStructureType, HorizontalAlignType, TextStyleType, VerticalAlignType, FormatType, NodeType, ConnectorType, DecorationStyle, PositionType, AssetType, HiliteType } from "common/constants";
import { SVGGroup } from "js/core/utilities/svgHelpers";
import { detectGraphData } from "js/core/services/sharedModelManager";
import { CycleDiagramItemControlBar, CycleDiagramPropertyPanel } from "../../../Editor/ElementPropertyPanels/CycleDiagramUI";
import { blendColors } from "../../../../../core/utilities/utilities";
import { tinycolor } from "js/vendor";
import { ConnectionDiagramItemControlBar, ConnectionDiagramPropertyPanel } from "../../../Editor/ElementPropertyPanels/ConnectionDiagramUI";
import { AnchorType } from "js/core/utilities/geom";
import { ContentElement } from "../../base/ContentElement";

class ConnectionDiagram extends CollectionElement {
    static get schema() {
        return {
            style: "one-to-many",
            labelType: "number",
            columns: "right"
        };
    }

    get name() {
        return "Connection Diagram";
    }

    getElementPropertyPanel() {
        return ConnectionDiagramPropertyPanel;
    }

    getChildItemType(itemModel) {
        return ConnectionDiagramItem;
    }

    get style() {
        return this.model.style ?? "one-to-many";
    }

    get labelType() {
        return this.model.labelType;
    }

    get minItemCount() {
        return 2;
    }

    get maxItemCount() {
        return 6;
    }

    get columns() {
        return this.model.columns ?? "split";
    }

    get decorationStyle() {
        return DecorationStyle.FILLED;
    }

    get shadeColors() {
        return true;
    }

    _build() {
        super._build();

        this.source = this.addElement("source", () => TextElement, {
            blockStructure: BlockStructureType.TITLE_AND_BODY,
            scaleTextToFit: true,
            autoHeight: false,
            verticalAlign: VerticalAlignType.MIDDLE
        });
    }

    _calcProps(props, options) {
        let { size } = props;

        let columnGap = this.styles.columnGap ?? 50;
        let rowGap = this.styles.rowGap ?? 40;

        let columnCount = 1;

        const SOURCE_WIDTH = size.width / 4;
        const MAX_COLUMN_WIDTH = size.width * .4;
        let pathWidth;

        let sourceX;
        let columnX;

        if (this.style == "one-to-many") {
            columnX = size.width - MAX_COLUMN_WIDTH + columnGap;
            sourceX = 0;
            pathWidth = 30;
        } else if (this.style == "many-to-one") {
            columnX = 0;
            sourceX = size.width - size.width / 3;
            pathWidth = 50;
        }

        let sourceProps = this.source.calcProps(new geom.Size(SOURCE_WIDTH, size.height), {
            textAlign: this.style == "one-to-many" ? HorizontalAlignType.RIGHT : HorizontalAlignType.LEFT,
        });
        sourceProps.bounds = new geom.Rect(sourceX, size.height / 2 - sourceProps.size.height / 2, sourceProps.size);

        let y = 0;
        let actualColumnWidth = 0;
        // let rowGap = (size.height - this.itemCount * 50) / (this.itemCount - 1);
        // rowGap = 10;
        for (let item of this.itemElements) {
            let itemProps = item.calcProps(new geom.Size(MAX_COLUMN_WIDTH, size.height), {
                textAlign: this.style == "one-to-many" ? HorizontalAlignType.LEFT : HorizontalAlignType.RIGHT,
            });
            itemProps.bounds = new geom.Rect(columnX, y, itemProps.size);
            y += itemProps.size.height + rowGap;

            actualColumnWidth = Math.max(actualColumnWidth, itemProps.size.width);
        }

        //vertically center the items
        let totalHeight = y - rowGap;
        for (let item of this.itemElements) {
            item.calculatedProps.bounds.top += (size.height - totalHeight) / 2;
        }

        // draw arrows
        let paths = [];

        let offsetX = 0;
        let arrowWidth = size.width - SOURCE_WIDTH - MAX_COLUMN_WIDTH;
        let sourceY = this.source.calculatedProps.bounds.centerV - (this.itemElements.length / 2 * pathWidth) + pathWidth / 2;

        for (let item of this.itemElements) {
            let sourcePoint, targetPoint;

            let path = new Path();
            if (this.style == "one-to-many") {
                sourcePoint = this.source.calculatedProps.bounds.getPoint(AnchorType.RIGHT);
                targetPoint = item.marker.calculatedProps.bounds.getPoint(AnchorType.CENTER).offset(item.calculatedProps.bounds.position);

                path.moveTo(sourcePoint.x, sourceY);
                path.lineTo(sourcePoint.x + arrowWidth / 3, sourceY);
                path.lineTo(sourcePoint.x + arrowWidth / 3 * 2.5, targetPoint.y);
                path.lineTo(targetPoint.x, targetPoint.y);
                // path.curveTo(sourcePoint.x + arrowWidth / 2 + 200, sourcePoint.y, targetPoint.x - arrowWidth , targetPoint.y, targetPoint.x, targetPoint.y)
            } else {
                sourcePoint = this.source.calculatedProps.bounds.getPoint(AnchorType.LEFT);
                targetPoint = item.marker.calculatedProps.bounds.getPoint(AnchorType.CENTER).offset(item.calculatedProps.bounds.position);

                path.moveTo(sourcePoint.x, sourceY);
                path.lineTo(sourcePoint.x - arrowWidth / 3, sourceY);
                path.lineTo(sourcePoint.x - arrowWidth / 3 * 2.5, targetPoint.y);
                path.lineTo(targetPoint.x, targetPoint.y);
            }

            paths.push(path);

            sourceY += pathWidth;

            offsetX += 5;
        }

        return { size, paths, pathWidth };
    }

    _applyColors() {
        super._applyColors();
    }

    getShapePath({ style, centerX, centerY, rx, ry, startAngle, endAngle, width, index }) {
        let textOffset = 0;
        let path;
        switch (style) {
            case "arrow2":
                path = Shape.drawCurvedArrow(centerX, centerY, rx, ry, startAngle, endAngle, width, 100, width * 1.3, index > 0);
                textOffset = 100;
                break;
            case "segments": {
                path = Shape.drawCurvedSegment(centerX, centerY, rx, ry, startAngle, endAngle, width);
                break;
            }
            case "puzzle":
                path = Shape.drawCurvedPuzzlePiece(centerX, centerY, rx, ry, startAngle, endAngle, width, 30, index > 0);
                textOffset = 30;
                break;
            case "bumper": {
                textOffset = 150;
                path = Shape.drawCurvedBumper(centerX, centerY, rx, ry, startAngle, endAngle, width, Math.PI, index > 0);
                break;
            }
            case "arrow1":
            default:
                path = Shape.drawCurvedArrow(centerX, centerY, rx, ry, startAngle, endAngle, width, 50, width, index > 0);
                textOffset = 100;
                break;
        }

        return { path, textOffset };
    }

    renderChildren(transition) {
        let children = [];

        let { paths, pathWidth } = this.calculatedProps;
        let offsetX = 0;
        for (let i = 0; i < paths.length; i++) {
            let item = this.itemElements[i];
            let path = paths[i];

            let color = item.colorSet.decorationColor.toRgbString();

            let itemColor = item.colorSet.decorationColor;

            if (item.getHiliteType() == HiliteType.DEEMPHASIZED) {
                itemColor = itemColor.desaturate(50);
                itemColor = this.palette.getColor("primary", this.getBackgroundColor()).setAlpha(0.15);
            } else {
                itemColor = itemColor.setAlpha(.66);
            }

            // let strokeColor = blendColors(itemColor.setAlpha(.66), this.getBackgroundColor()).toRgbString();
            let strokeColor = itemColor.toRgbString();

            // if (slideBackgroundColor.isColor) {
            //     color = "white";
            //     color = blendColors(tinycolor("white").setAlpha(1 - (paths.length - i) * .1), slideBackgroundColor).toRgbString();
            // }

            children.push(
                <SVGGroup>
                    <path d={path.toPathData()} fill="transparent" stroke={strokeColor} strokeWidth={pathWidth} />
                </SVGGroup>
            );
        }

        // if (this.labelType != "none") {
        //     let { fontHeaderFontId, fontHeaderWeight } = this.canvas.getTheme().attributes;
        //     for (let i = 0; i < paths.length; i++) {
        //         let opacity = this.itemElements[i].animationState?.fadeInProgress ?? 1;
        //         children.push(
        //             <ArrowNumber x={centerPoints[i].x} y={centerPoints[i].y} font={fontHeaderFontId} fontWeight={fontHeaderWeight} opacity={opacity}>
        //                 {this.labelType == "number" ? i + 1 : String.fromCharCode(65 + i)}
        //             </ArrowNumber>
        //         );
        //     }
        // }

        children.push(...super.renderChildren());
        return children;
    }

    getAnimations() {
        const animations = [];

        this.itemElements.forEach(item => {
            animations.push(...item.getAnimations());
        });
        return animations;
    }
}

class ConnectionDiagramItem extends CollectionItemElement {
    getElementControlBar() {
        return ConnectionDiagramItemControlBar;
    }

    get selectionPadding() {
        return { left: 20, right: 20, top: 10, bottom: 10 };
    }

    _build() {
        this.text = this.addElement("text", () => TextElement, {
            blockStructure: BlockStructureType.TITLE_AND_BODY,
            scaleTextToFit: true,
            syncFontSizeWithSiblings: true,
            autoHeight: true,
            verticalAlign: VerticalAlignType.MIDDLE
        });

        if (this.parentElement.labelType == "letter" || this.parentElement.labelType == "number") {
            this.marker = this.addElement("marker", () => TextElement, {
                canEdit: false,
                model: {
                    marker: this.parentElement.labelType == "number" ? this.itemIndex + 1 : String.fromCharCode(65 + this.itemIndex),
                    color: this.model.color,
                }
            });
        }
        if (this.parentElement.labelType == "icon") {
            this.marker = this.addElement("marker", () => ContentElement, {
                defaultAssetType: AssetType.ICON,
                canEdit: true
            });
        }
    }

    _calcProps(props, options) {
        let { size } = props;

        let markerProps;
        let markerWidth = 0;

        if (this.parentElement.labelType != "none") {
            let markerSize = this.parentElement.labelType == "icon" ? 75 : 50;
            markerProps = this.marker.calcProps(new geom.Size(markerSize, markerSize));
            markerWidth = markerProps.size.width;
        }

        let textProps = this.text.calcProps(new geom.Size(size.width - markerWidth, size.height), {
            textAlign: HorizontalAlignType.LEFT,
            autoWidth: true
        });
        textProps.bounds = new geom.Rect(markerWidth, markerProps ? (markerProps.bounds.centerV - textProps.blockProps[0].bounds.height / 2) : 0, textProps.size);

        let width = textProps.size.width;
        let height = textProps.size.height;
        if (this.parentElement.labelType != "none") {
            markerProps.bounds = new geom.Rect(0, 0, markerProps.size);
            height = Math.max(textProps.bounds.bottom, markerProps.bounds.bottom) - Math.min(textProps.bounds.top, markerProps.bounds.top);
            width += markerWidth;
        }

        return { size: new geom.Size(width, height) };
    }

    _applyColors() {
        if (this.getHiliteType() == HiliteType.DEEMPHASIZED) {
            this.marker.decoration.colorSet.fillColor = this.palette.getColor("primary", this.getBackgroundColor()).mix(this.getBackgroundColor(), 50);
            this.colorSet.decorationColor = this.palette.getColor("primary", this.getBackgroundColor()).mix(this.getBackgroundColor(), 50);
        } else {
            this.marker.decoration.colorSet.fillColor = this.colorSet.decorationColor;
        }
    }

    _getAnimations() {
        return [{
            name: "Grow in",
            easing: "easeOutQuad",
            prepare: () => {
                this.animationState.growProgress = 0;
                this.animationState.fadeInProgress = 0;
            },
            onBeforeAnimationFrame: progress => {
                this.animationState.growProgress = progress;
                this.animationState.fadeInProgress = progress;
                return this.parentElement;
            }
        }];
    }
}

export const elements = {
    ConnectionDiagram
};

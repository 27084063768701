import React, { Component } from "react";
import AuthoringElementHeader from "../EditorComponents/AuthoringElementHeader";
import { WithLabel } from "../../../../Components/WithLabel";
import { ToggleSwitch } from "../../../../Components/ToggleSwitch";
import { PropertyPanelContainer, PropertySection } from "../../../../EditorComponents/PropertyPanel";
import { Button } from "../../../../Components/Button";
import { ImageOption, ImageOptionList } from "../../../../Components/ImageOptionList";
import { TextListProperties } from "./TextListUI";

export class TextSlidePropertyPanel extends Component {
    render() {
        const { element } = this.props;

        return (
            <PropertyPanelContainer>
                <TextListProperties element={element} />
            </PropertyPanelContainer>
        );
    }
}


import { SVGPolylineElement } from "../../base/SVGElement";
import CompareValuesCollection from "./CompareValuesCollection";
import CompareVerticalBarItem from "./CompareVerticalBarItem";
import { CompareVerticalBarPropertyPanel } from "../../../Editor/ElementPropertyPanels/CompareItemsUI";

export default class CompareVerticalBar extends CompareValuesCollection {
    getElementPropertyPanel() {
        return CompareVerticalBarPropertyPanel;
    }

    get name() {
        return "Compare Vertical Bars";
    }

    getChildItemType() {
        return CompareVerticalBarItem;
    }

    get maxItemCount() {
        return 7;
    }

    _build() {
        super._build();

        if (this.model.showBaseline) {
            this.baseline = this.addElement("baseline", () => SVGPolylineElement);
        }
    }

    _calcProps(props, options) {
        const { size } = props;

        const layouter = this.getLayouter(props, this.itemElements, size);
        layouter.calcHorizontalLayout({ verticallyAlignText: true });
        layouter.alignHorizontally();

        return { size: layouter.size };
    }
}

import * as geom from "js/core/utilities/geom";

import { BaseElement } from "../../base/BaseElement";
import { TextElement } from "../../base/Text/TextElement";
import { BlockStructureType } from "common/constants";

export class OrgChartRowLabel extends BaseElement {
    static get LABEL_WIDTH() {
        return 100;
    }

    _build() {
        this.text = this.addElement("text", () => TextElement, {
            blockStructure: BlockStructureType.TITLE_AND_BODY
        });
    }

    _calcProps(props, options) {
        const { size } = props;
        const textSize = this.text.calcSize(new geom.Size(OrgChartRowLabel.LABEL_WIDTH, size.height));
        this.text.bounds = new geom.Rect(0, size.height / 2 - textSize.height / 2, textSize);
        return { size };
    }
}

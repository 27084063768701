import React, { Component } from "react";

import { formatter } from "js/core/utilities/formatter";
import { AssetType, FormatType } from "common/constants";

import { ImageOption, ImageOptionList } from "../../../../Components/ImageOptionList";
import { Popup, PopupContent, PopupPreview } from "../../../../Components/Popup";
import { ToggleButton, ToggleButtons } from "../../../../Components/ToggleButtons";
import { WithLabel } from "../../../../Components/WithLabel";
import { ShowDialog } from "../../../../react/components/Dialogs/BaseDialog";
import LinkDataDialog from "../../../../react/views/LinkData/LinkDataDialog";

import { BackgroundColorPicker } from "../EditorComponents/ColorPickers/BackgroundColorPicker";
import { LinkedDataMenu } from "../EditorComponents/LinkedDataMenu";

import { Button } from "../../../../Components/Button";
import { Icon } from "../../../../Components/Icon";
import {
    PropertyPanelContainer,
    PropertySection
} from "../../../../EditorComponents/PropertyPanel";
import { ColorPicker } from "../EditorComponents/ColorPickers/ColorPicker";
import { detectTabularData } from "js/core/services/sharedModelManager";

export async function ChangeTableType(element, model) {
    const sharedModel = element.exportToSharedModel();
    const tableData = detectTabularData(sharedModel).data;

    const rowCount = tableData.length;
    const colCount = tableData[0].length;
    model.childElement = element.canvas.tableUtils.getDefaultTableData();
    model.childElement.color = element.model.chartData?.series[0].colorName ?? element.model.collectionColor;
    replaceEntireTable(model.childElement, tableData, true, colCount, rowCount);
}

export function replaceEntireTable(model, tableData, initialImport = false, colCount, rowCount) {
    model.cols = [...new Array(colCount).keys()].map(index => ({
        index,
        style: "defaultCol",
        break: false,
        ...(model.cols[index ?? {}])
    }));
    model.cols = model.cols.slice(0, colCount);

    model.rows = [...new Array(rowCount).keys()].map(index => ({
        index,
        style: "defaultRow",
        break: false,
        ...(model.rows[index ?? {}])
    }));
    model.rows = model.rows.slice(0, rowCount);

    const currCells = [...model.cells];
    model.cells = [];

    for (const colModel of model.cols) {
        const colIndex = colModel.index;
        for (const rowModel of model.rows) {
            const rowIndex = rowModel.index;

            const cell = { row: rowIndex, col: colIndex, id: `${rowIndex}:${colIndex}` };
            const currCell = currCells.find(c => c.row === rowIndex && c.col === colIndex) || {};

            let cellText = tableData[rowIndex][colIndex];
            cellText = String(cellText).replace(/\n/g, String.fromCharCode(13));

            const format = setCellFormat(currCell, cellText);

            if (!initialImport) {
                if (currCell.format) cell.format = currCell.format;
                cell.formatOptions = { ...format.formatOptions, ...currCell.formatOptions };
            }

            model.cells.push({ ...currCell, ...cell });
        }
    }
}

export function setCellFormat(cell, cellText) {
    const format = formatter.detectFormatFromString(cellText);

    switch (cellText) {
        case "✓":
        case "✔":
        case "✅":
        case "☑":
            cell.format = FormatType.ICON;
            cell.content_type = AssetType.ICON;
            cell.content_value = "check-yes";
            break;
        case "☒":
        case "✗":
        case "✘":
        case "x":
            cell.format = FormatType.ICON;
            cell.content_type = AssetType.ICON;
            cell.content_value = "x";
            break;
        default:
            cell.cellText = { text: cellText };
            cell.format = format.format;
            if (!cell.formatOptions) cell.formatOptions = format.formatOptions;
    }

    return format;
}

function TableProperties({ table, currentCanvasController }) {
    const tableFrame = table.parentElement;
    const hasDataSourceLink = tableFrame.hasDataSourceLink();

    const handleCalcAutofit = value => {
        table.calcAutoFit(value);

        if (table.isOnAuthoringCanvas) {
            // Adjusting the container
            const authoringElementContainer = table.parentElement.parentElement;
            if (value == "columns") {
                authoringElementContainer.model.width *= table.parentElement.tableWidth;
            } else {
                authoringElementContainer.model.height *= table.parentElement.tableHeight;
            }
        }

        table.saveModel();
    };

    const handleSelectedData = processedData => {
        processedData.initialImport = true;
        tableFrame._useUpdatedDataSource(processedData);
        tableFrame.canvas.updateCanvasModel(false);
    };

    const handleUnlinkData = () => {
        tableFrame.removeDataSource();
        tableFrame.model.dataSourceLink = null;
        tableFrame.canvas.updateCanvasModel(false);
    };

    const handleImportData = () => {
        currentCanvasController.selectionLayerController.setFreezeSelection(true);

        ShowDialog(LinkDataDialog, {
            element: tableFrame,
            handleSelectedData: data => handleSelectedData(data),
            handleDisconnect: () => handleUnlinkData(),
            onClose: () => currentCanvasController.selectionLayerController.setFreezeSelection(false)
        });
    };

    return (
        <>
            <PropertySection>
                {!hasDataSourceLink &&
                    <Button blue onClick={handleImportData}>
                        <Icon>table</Icon>Import Data...
                    </Button>
                }
                {hasDataSourceLink && <Popup>
                    <PopupPreview>
                        <Button blue>
                            <Icon>table</Icon>Linked data settings...
                        </Button>
                    </PopupPreview>
                    <PopupContent>
                        {closePopup =>
                            <LinkedDataMenu element={tableFrame} closeMenu={closePopup} />
                        }
                    </PopupContent>
                </Popup>}
            </PropertySection>
            <PropertySection>
                <WithLabel above lefet label="Table Styles">
                    <ImageOptionList cols={3}>
                        <ImageOption label="Header Row"
                            url="/images/ui/tables/table_headerrow.svg"
                            selected={table.getRowStyle(0) == "headerRow"}
                            onClick={() => {
                                if (table.getRowStyle(0) == "headerRow") {
                                    table.getRow(0).style = "defaultRow";
                                } else {
                                    table.getRow(0).style = "headerRow";
                                }
                                table.saveModel();
                            }}
                        />
                        <ImageOption label="Header Col"
                            url="/images/ui/tables/table_headercol.svg"
                            selected={table.getColumnStyle(0) == "headerCol"}
                            onClick={() => {
                                if (table.getColumnStyle(0) == "headerCol") {
                                    table.getColumn(0).style = "defaultCol";
                                } else {
                                    table.getColumn(0).style = "headerCol";
                                }
                                table.saveModel();
                            }}
                        />
                        <ImageOption label="Top Left Cell"
                            url="/images/ui/tables/table_a1cell.svg"
                            selected={table.model.showTopLeftCell}
                            onClick={() => {
                                table.model.showTopLeftCell = !table.model.showTopLeftCell;
                                table.saveModel();
                            }}
                        />
                        <ImageOption label="Border"
                            url="/images/ui/tables/table_border.svg"
                            selected={table.model.showBorder}
                            onClick={() => {
                                table.model.showBorder = !table.model.showBorder;
                                table.saveModel();
                            }}
                        />
                        <ImageOption label="Column Lines"
                            url="/images/ui/tables/table_column.svg"
                            selected={table.model.showColGridLines}
                            onClick={() => {
                                table.model.showColGridLines = !table.model.showColGridLines;
                                table.saveModel();
                            }}
                        />
                        <ImageOption label="Row Lines"
                            url="/images/ui/tables/table_row.svg"
                            selected={table.model.showRowGridLines}
                            onClick={() => {
                                table.model.showRowGridLines = !table.model.showRowGridLines;
                                table.saveModel();
                            }}
                        />

                        <ImageOption label="Banded Rows"
                            url="/images/ui/tables/table_banded.svg"
                            selected={table.model.alternateRows}
                            onClick={() => {
                                table.model.alternateRows = !table.model.alternateRows;
                                table.saveModel();
                            }}
                        />
                    </ImageOptionList>
                </WithLabel>
            </PropertySection>

            <PropertySection>
                <WithLabel label="Accent Color">
                    <ColorPicker value={table.model.color} canvas={table.canvas}
                        showBackgroundColors allowColorOnColor
                        onChange={color => table.updateModel({ color }, { refreshStyles: true }) }
                    />
                </WithLabel>
                <WithLabel label="Table Background Color">
                    <BackgroundColorPicker element={table} colorProperty="tableBackgroundColor" />
                </WithLabel>
            </PropertySection>

            <PropertySection>
                <WithLabel left above label="Fit Table Size To">
                    <ToggleButtons
                        value={null}
                        onChange={handleCalcAutofit}
                    >
                        <ToggleButton value="columns"><Icon>view_week</Icon>Columns</ToggleButton>
                        <ToggleButton value="rows"><Icon>table_rows</Icon>Rows</ToggleButton>
                    </ToggleButtons>
                </WithLabel>
            </PropertySection>
        </>);
}

export class TablePropertyPanel extends Component {
    render() {
        const { element } = this.props;

        const table = element.isInstanceOf("Table") ? element : element.table;

        return (
            <PropertyPanelContainer>
                <TableProperties {...this.props} table={table} />
            </PropertyPanelContainer>
        );
    }
}


import { _ } from "js/vendor";
import * as geom from "js/core/utilities/geom";

export function calcBoxGridLayout() {
    let styles = _.defaults({}, this.styles, {
        hGap: 30,
        vGap: 20
    });

    if (this.styles.maxItemCount) {
        this.isFit = this.items.length <= this.styles.maxItemCount;
        this.items = this.items.slice(0, this.styles.maxItemCount);
    }

    // The following is a simplified recreation of d3's grid functionality as previously
    // implemented. It should be noted that, like before, hGap and vGap represent the total
    // amount of padding applied to all items in a row or column, and are not individual
    // values that control padding between each item. It seems strange, but this is how d3
    // handles padding in the context of 'bands', which was our previous implementation.
    const n = this.items.length;
    this.cols = Math.ceil(Math.sqrt(n));
    this.rows = Math.ceil(n / this.cols);
    const itemPadding = {
        x: Math.ceil(styles.hGap / this.cols),
        y: Math.ceil(styles.vGap / this.rows),
    };

    const nodeSize = {
        width: Math.ceil(this.containerSize.width / this.cols) - (styles.hGap / (this.cols + 1)),
        height: Math.ceil(this.containerSize.height / this.rows) - (styles.vGap / (this.rows + 1)),
    };

    this.items.forEach((item, idx) => {
        const colNum = (idx % this.cols);
        const rowNum = Math.floor(idx / this.cols);
        const itemX = (nodeSize.width * colNum) + itemPadding.x * colNum;
        const itemY = (nodeSize.height * rowNum) + itemPadding.y * rowNum;

        let itemProps = item.calcProps(new geom.Size(nodeSize.width, nodeSize.height));
        itemProps.bounds = new geom.Rect(itemX, itemY, nodeSize.width, nodeSize.height);
    });

    this.size = this.containerSize;
    return this;
}

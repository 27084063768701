import React, { Component } from "react";
import { HorizontalPropertyList, PropertyPanelContainer, PropertySection } from "../../../EditorComponents/PropertyPanel";
import { ImageOption, ImageOptionList } from "../../ImageOptionList";
import { NumericStepper } from "../../NumericStepper";
import { WithLabel } from "../../WithLabel";
import { _ } from "../../../vendor";

export function getShadowStyle(shadow) {
    if (shadow == null || _.isEmpty(shadow)) {
        return "none";
    } else if (_.isEqual(shadow, { x: 3, y: 5, blur: 6, color: "black", opacity: 0.4 })) {
        return "drop";
    } else if (_.isEqual(shadow, { x: 0, y: 0, blur: 10, color: "black", opacity: 0.4 })) {
        return "soft";
    } else if (_.isEqual(shadow, { x: 10, y: 10, blur: 0, color: "black", opacity: 0.5 })) {
        return "block";
    } else {
        return "custom";
    }
}

export class ShadowEditor extends Component {
    render() {
        let { shadow, onChange, closePopup } = this.props;

        let shadowStyle = getShadowStyle(shadow);

        return (
            <PropertyPanelContainer>
                <PropertySection>
                    <ImageOptionList value={shadowStyle ?? "none"} previewSize={40} size={40} border={false} showArrow={false}
                        onChange={shadowStyle => {
                            let shadow;
                            switch (shadowStyle) {
                                case "none":
                                    shadow = null;
                                    break;
                                case "drop":
                                    shadow = { x: 3, y: 5, blur: 6, color: "black", opacity: 0.4 };
                                    break;
                                case "soft":
                                    shadow = { x: 0, y: 0, blur: 10, color: "black", opacity: 0.4 };
                                    break;
                                case "block":
                                    shadow = { x: 10, y: 10, blur: 0, color: "black", opacity: 0.5 };
                                    break;
                                case "custom":
                                    shadow = shadow || { x: 5, y: 5, blur: 10, color: "black", opacity: 0.4 };
                                    break;
                            }

                            onChange(shadow);
                            if (shadowStyle != "custom") {
                                closePopup && closePopup();
                            }
                        }}
                    >
                        <ImageOption value="none" label="None" url="/images/ui/shadows/shadow-none.svg" />
                        <ImageOption value="drop" label="Drop" url="/images/ui/shadows/shadow-drop.svg" />
                        <ImageOption value="soft" label="Soft" url="/images/ui/shadows/shadow-soft.svg" />
                        <ImageOption value="block" label="Block" url="/images/ui/shadows/shadow-block.svg" />
                        <ImageOption value="custom" label="Custom" url="/images/ui/shadows/shadow-custom.svg" />
                    </ImageOptionList>
                </PropertySection>
                {shadowStyle == "custom" &&
                    <PropertySection>
                        <HorizontalPropertyList>
                            <WithLabel label="Offset X" below small center>
                                <NumericStepper value={shadow?.x ?? 0}
                                    onChange={value => onChange({ ...shadow, x: value })}
                                    min={-50} max={50}
                                />
                            </WithLabel>
                            <WithLabel label="Offset Y" below small center>
                                <NumericStepper value={shadow?.y ?? 0}
                                    onChange={value => onChange({ ...shadow, y: value })}
                                    min={-50} max={50}
                                />
                            </WithLabel>

                            <WithLabel label="Blur" below small center>
                                <NumericStepper value={shadow?.blur ?? 0}
                                    onChange={value => onChange({ ...shadow, blur: value })}
                                    min={0} max={50}
                                />
                            </WithLabel>

                            <WithLabel label="Opacity" below small center>
                                <NumericStepper value={shadow?.opacity ?? 0}
                                    onChange={value => onChange({ ...shadow, opacity: value })}
                                    min={0} max={1} step={0.01} format="percent"
                                />
                            </WithLabel>
                        </HorizontalPropertyList>
                    </PropertySection>
                }
            </PropertyPanelContainer>
        );
    }
}


import { AssetType, AuthoringBlockType, BlockStructureType, ContentBlockType, DecorationStyle, DirectionType, ForeColorType, HorizontalAlignType, NodeType, PositionType, TextStyleType } from "common/constants";
import { ClipboardType } from "js/core/utilities/clipboard";
import * as geom from "js/core/utilities/geom";
import { AnchorType, getCenteredRect } from "js/core/utilities/geom";
import { Shape } from "js/core/utilities/shapes";
import { app } from "js/namespaces";
import { _ } from "js/vendor";

import { ContentItemControlBar } from "../../Editor/ElementPropertyPanels/ContentItemUI";
import { ContentItemSelection, ContentItemTextSelection } from "../../Editor/ElementSelections/ContentItemSelection";
import { CollectionItemElement } from "./CollectionElement";
import { FramedMediaElement } from "./MediaElements/FramedMediaElement";
import { SVGCircleElement, SVGPathElement } from "./SVGElement";
import { TextElement, USE_STYLESHEET_FOR_TEXT_STYLES } from "./Text/TextElement";

export function getContentItemFromType(type) {
    switch (type) {
        case NodeType.FLEX_CIRCLE:
            return FlexCircleContentItem;
        case NodeType.BULLET_TEXT:
            return BulletTextContentItem;
        case NodeType.TEXT:
            return TextContentItem;
        case NodeType.CONTENT_AND_TEXT:
            return MediaAndTextContentItem;
        case NodeType.NUMBERED_TEXT:
            return NumberedTextContentItem;
        case NodeType.LETTERED_TEXT:
            return LetteredTextContentItem;
        case NodeType.CONTENT:
            return MediaContentItem;
        case NodeType.BOX:
        case NodeType.CAPSULE:
        case NodeType.DIAMOND:
        case NodeType.CIRCLE:
            return TextInShapeContentItem;
        default:
            return MediaAndTextContentItem;
    }
}

export class ContentItem extends CollectionItemElement {
    static get schema() {
        return {
            textWidth: null
        };
    }

    get migrationProps() {
        return this.bounds.offset(this.registrationPoint.x, this.registrationPoint.y).toXYObject();
    }

    get canEditText() {
        return this.options.canEditText ?? true;
    }

    getElementControlBar() {
        return this.options.elementControlBar ?? ContentItemControlBar;
    }

    getElementSelection() {
        return this.options.elementSelection ?? ContentItemSelection;
    }

    get allowDecorationStyles() {
        return true;
    }

    get _canSelect() {
        return true;
    }

    getClipboardData() {
        return {
            [ClipboardType.EDITOR_ELEMENT]: {
                dataType: "element",
                elementType: this.type,
                parentElementType: this.parentElement.type,
                slideId: this.canvas.dataModel.id,
                model: _.cloneDeep(this.model)
            }
        };
    }

    get selectionPadding() {
        return 10;
    }

    get showResizeHandle() {
        return false;
    }

    get showResizeSlider() {
        return this.options.showResizeSlider ?? false;
    }

    get minTextWidth() {
        return 50;
    }

    get maxTextWidth() {
        return 1280;
    }

    get userWidth() {
        return this.model.textWidth;
    }

    set userWidth(width) {
        this.model.textWidth = Math.clamp(width, this.minTextWidth || 100, this.maxTextWidth || 1280);
    }

    get minMarkerSize() {
        return 50;
    }

    get maxMarkerSize() {
        return 400;
    }

    getMarkerSize() {
        return Math.clamp(this.model.size ?? this.styles.markerSize ?? 10, this.minMarkerSize, this.maxMarkerSize);
    }

    getInnerText(trimLength = null) {
        const text = this.model.title?.text;
        if (!text) {
            return null;
        }

        if (!trimLength) {
            return text;
        }

        return `${text.slice(0, 5)}${text.length > trimLength ? "..." : ""}`;
    }

    // get canRefreshElement() {
    //     return true;
    // }
    //
    // refreshElement(transition) {
    //     this.canvas.refreshElement(this, transition);
    // }

    get defaultBlockType() {
        return ContentBlockType.TITLE;
    }

    get autoWidth() {
        return this.model.textWidth == null;
    }

    get canChangeTextDirection() {
        return this.options.canChangeTextDirection ?? true;
    }

    get canChangeColors() {
        if (this.options.canChangeColors != undefined) {
            return this.options.canChangeColors;
        }

        return true;
    }

    get textPosition() {
        return this.model.textPosition || PositionType.CENTER;
    }

    get horizontalScaleOrigin() {
        if (this.options.horizontalScaleOrigin) {
            return this.options.horizontalScaleOrigin;
        }

        switch (this.calculatedProps.textDirection || this.textDirection) {
            case DirectionType.LEFT:
                return HorizontalAlignType.RIGHT;
            case DirectionType.RIGHT:
                return HorizontalAlignType.LEFT;
            default:
                return HorizontalAlignType.CENTER;
        }
    }

    get textDirection() {
        return this.model.textDirection || this.options.textDirection || this.defaultDirectionType;
    }

    get defaultDirectionType() {
        return DirectionType.RIGHT;
    }

    getAutoTextDirection(size) {
        if (app.isDraggingItem) {
            return this.calculatedTextDirection || DirectionType.RIGHT;
        }

        if (this.connectorsFromNode.length > 0 || this.connectorsToNode.length > 0) {
            const nodePoint = new geom.Point(size.width * this.model.x, size.height * this.model.y);

            let anchorPoint;
            if (this.connectorsFromNode.length > 0) {
                const connector = this.connectorsFromNode[0];
                if (connector.endTarget) {
                    if (connector.endTarget instanceof ContentItem) {
                        // if the connection is to another node element, it may not have been calc'd yet so use the model to determine the anchorPoint
                        anchorPoint = new geom.Point(size.width * connector.endTarget.model.x, size.height * connector.endTarget.model.y);
                    } else if (connector.endTarget.getAnchorPoint) {
                        anchorPoint = connector.endTarget.getAnchorPoint(connector, connector.startAnchor, nodePoint, connector.type, false);
                    } else if (connector.endTarget.anchorBounds) {
                        anchorPoint = connector.endTarget.anchorBounds.center;
                    } else {
                        anchorPoint = new geom.Rect(0, 0, connector.endTarget.size).center;
                    }
                } else {
                    anchorPoint = new geom.Point(connector.model.targetPoint);
                }
            } else {
                const connector = this.connectorsToNode[0];
                if (connector.startTarget) {
                    if (connector.startTarget instanceof ContentItem) {
                        // if the connection is to another node element, it may not have been calc'd yet so use the model to determine the anchorPoint
                        anchorPoint = new geom.Point(size.width * connector.startTarget.model.x, size.height * connector.startTarget.model.y);
                    } else if (connector.startTarget.getAnchorPoint) {
                        anchorPoint = connector.startTarget.getAnchorPoint(connector, connector.endAnchor, nodePoint, connector.type, true);
                    } else if (connector.startTarget.anchorBounds) {
                        anchorPoint = connector.startTarget.anchorBounds.center;
                    } else {
                        anchorPoint = new geom.Rect(0, 0, connector.startTarget.size).center;
                    }
                } else {
                    anchorPoint = new geom.Point(connector.model.startPoint);
                }
            }

            let calculatedTextDirection;
            const nodeAngle = anchorPoint.angleToPoint(nodePoint);
            if (nodeAngle > 300 || nodeAngle < 60) {
                calculatedTextDirection = DirectionType.RIGHT;
            } else if (nodeAngle > 240 && nodeAngle <= 300) {
                calculatedTextDirection = DirectionType.TOP;
            } else if (nodeAngle > 115 && nodeAngle <= 240) {
                calculatedTextDirection = DirectionType.LEFT;
            } else {
                calculatedTextDirection = DirectionType.BOTTOM;
            }

            // If not dragging, then just return the calculated direction
            if (!this.isDragging) {
                return calculatedTextDirection;
            }

            // Direction hasn't changed
            if (this.calculatedTextDirection === calculatedTextDirection) {
                return calculatedTextDirection;
            }

            if (!this.dragWidgetPosition) {
                return PositionType.RIGHT;
            }

            const dragWidgetAngle = anchorPoint.angleToPoint(this.dragWidgetPosition);
            // Won't be changing direction if the angle to the drag widget changed less than
            // by 30 degrees
            if (this.textDirectionChangedAtAngle && Math.abs(this.textDirectionChangedAtAngle - dragWidgetAngle) < 30) {
                return this.calculatedProps?.textDirection;
            }

            // Changing direction
            this.textDirectionChangedAtAngle = dragWidgetAngle;
            return calculatedTextDirection;
        } else {
            return DirectionType.RIGHT;
        }
    }

    get textWidth() {
        return this.model.textWidth ?? 200;
    }

    get contentSize() {
        return this.model.contentSize ?? 100;
    }

    get minWidth() {
        return this.options.minWidth ?? 40;
    }

    get maxWidth() {
        return this.options.maxWidth ?? 1180;
    }

    get minHeight() {
        return this.options.minHeight ?? 20;
    }

    get maxHeight() {
        return this.options.maxHeight ?? 600;
    }

    get nodeType() {
        return this.model.nodeType || NodeType.BOX;
    }

    get bounds() {
        // IMPORTANT: returning a modiifed copy of bounds means that we can't directly modify bounds property for this
        // element!
        return super.bounds.offset(-this.registrationPoint.x, -this.registrationPoint.y);
    }

    set bounds(value) {
        this._bounds = value;
    }

    get connectionShape() {
        return {
            bounds: this.shape.bounds.inflate(this.anchorPadding),
            type: this.options.connectionShape ?? this.nodeType
        };
    }

    get anchorPadding() {
        return this.options.anchorPadding || this.styles.anchorPadding || 0;
    }

    getAnchorPoint(connector, anchor) {
        if (this.options.getAnchorPoint) {
            return this.options.getAnchorPoint(this, connector, anchor);
        }

        let anchorBounds = this.anchorBounds.inflate(this.anchorPadding);
        switch (anchor) {
            case AnchorType.FREE:
            case AnchorType.CENTER:
                return anchorBounds.center;
            case AnchorType.LEFT:
                return anchorBounds.getPoint("middle-left");
            case AnchorType.RIGHT:
                return anchorBounds.getPoint("middle-right");
            case AnchorType.TOP:
                return anchorBounds.getPoint("top-center");
            case AnchorType.BOTTOM:
                return anchorBounds.getPoint("bottom-center");
        }
    }

    get availableAnchorPoints() {
        return [AnchorType.FREE, AnchorType.LEFT, AnchorType.RIGHT, AnchorType.TOP, AnchorType.BOTTOM];
    }

    // tree hierarchy helpers

    get connectorsFromNode() {
        if (this.parentElement.connectors) {
            return this.parentElement.connectors.getConnectorsForItem(this.id, "source");
        } else {
            return [];
        }
    }

    get connectorsToNode() {
        if (this.parentElement.connectors) {
            return this.parentElement.connectors.getConnectorsForItem(this.id, "target");
        } else {
            return [];
        }
    }

    get childNodes() {
        return _.map(this.connectorsFromNode, c => this.parentElement.getChild(c.model.target));
    }

    get parentNodes() {
        return _.map(this.connectorsToNode, c => this.parentElement.getChild(c.model.source));
    }

    get isCyclicalNode() {
        let inConnectorNodes = _.map(this.connectorsToNode, c => c.model.source);

        let checkChildNodes = (currentNode, targetNode, checkedNodes) => {
            for (let childNode of currentNode.childNodes) {
                if (checkedNodes.contains(childNode)) continue;
                checkedNodes.push(childNode);

                if (targetNode.id == childNode.id) {
                    return true;
                }
                if (checkChildNodes(childNode, targetNode, checkedNodes)) {
                    return true;
                }
            }
        };

        return checkChildNodes(this, this, []) === true;
    }

    get animationElementName() {
        const text = this.model.title?.text;
        return `Node ${text ? `"${text}"` : `#${this.itemIndex + 1}`}`;
    }

    get animateChildren() {
        return false;
    }

    _getAnimations() {
        return [{
            name: "Fade in",
            prepare: () => this.animationState.fadeInProgress = 0,
            onBeforeAnimationFrame: progress => {
                this.animationState.fadeInProgress = progress;
            }
        }];
    }

    _migrate_10() {
        if (this.model.userSize) {
            this.model.textWidth = this.model.userSize;
        }
        if (this.model.blocks && this.model.title && typeof this.model.title === "object" && this.model.title.text) {
            delete this.model.blocks; // remove any old blocks property from the model if there is already a title property because the old blocks will get migrated by TextElement
        }
        if (this.model.label) {
            this.model.value = {
                blocks: [{
                    type: AuthoringBlockType.TEXT,
                    textStyle: USE_STYLESHEET_FOR_TEXT_STYLES,
                    html: this.model.label.text?.toString() || ""
                }]
            };
            delete this.model.label;
        }
    }

    _exportToSharedModel() {
        const assets = [
            ...(this.content?._exportToSharedModel()?.assets || []),
            ...(this.media?._exportToSharedModel()?.assets || []),
            ...(this.marker?._exportToSharedModel()?.assets || [])
        ];
        const textContent = this.text?._exportToSharedModel()?.textContent || [];

        const graphData = [{
            nodes: [{
                id: this.model.id,
                type: this.nodeType,
                x: this.model.x,
                y: this.model.y,
                asset: assets[0],
                textContent: textContent[0],
                props: _.omit(this.model, ["id", "x", "y", "nodeType"])
            }]
        }];

        return { assets, textContent, graphData };
    }
}

export class FlexCircleContentItem extends ContentItem {
    get selectionPadding() {
        return 15;
    }

    get anchorBounds() {
        return this.shape.calculatedProps.bounds.offset(this.bounds.position);
    }

    get registrationPoint() {
        if (this.options.getRegistrationPoint) {
            return this.options.getRegistrationPoint(this);
        }

        return this.shape.calculatedProps.bounds.center;
    }

    get textDirection() {
        return this.model.textDirection || this.options.textDirection || DirectionType.AUTO;
    }

    get nodeType() {
        return NodeType.FLEX_CIRCLE;
    }

    get minMarkerSize() {
        return 10;
    }

    get showResizeHandle() {
        return this.calculatedProps.textInCircle == false;
    }

    get showResizeSlider() {
        return this.options.showResizeSlider ?? true;
    }

    get decorationStyle() {
        if (this.model.decorationStyle) {
            return this.model.decorationStyle;
        } else {
            if (this.model.hilited || this.canvas.getTheme().get("styleElementStyle") == "filled") {
                return "muted";
            } else {
                return this.canvas.getTheme().get("styleElementStyle");
            }
        }
    }

    get horizontalScaleOrigin() {
        if (this.options.horizontalScaleOrigin) {
            return this.options.horizontalScaleOrigin;
        }

        if (this.textDirection === DirectionType.AUTO && !this.textInCircle) {
            return HorizontalAlignType.LEFT;
        }

        return super.horizontalScaleOrigin;
    }

    _build() {
        this.shape = this.addElement("shape", () => SVGCircleElement);

        this.text = this.addElement("text", () => TextElement, {
            blockStructure: BlockStructureType.HEADER,
            defaultBlockTextStyle: TextStyleType.TITLE,
            allowAlignment: this.options.allowTextAlignment ?? true,
            textAlign: this.options.textAlign ?? null,
            autoWidth: this.autoWidth,
            autoHeight: true,
            syncFontSizeWithSiblings: this.options.syncFontSizeWithSiblings ?? true,
            allowedBlockTypes: this.options.allowedTextBlockTypes ?? null,
            canEdit: this.canEditText
        });
    }

    _calcProps(props, options) {
        const { size } = props;

        // size.width = this.textWidth || size.width;

        let textProps;
        let calculatedSize;

        props.textInCircle = false;

        if (this.decorationStyle == "muted") {
            // this matches the pre-elements styling on xygraph nodes
            this.shape.styles.fillColor = "slide 0.7";
        }

        let markerSize = this.getMarkerSize();
        const markerProps = this.shape.calcProps(new geom.Size(markerSize, markerSize));
        markerProps.bounds = new geom.Rect(0, 0, markerSize, markerSize);
        markerProps.layer = -1;

        if (this.textDirection === DirectionType.AUTO) {
            this.text.loadedStyles.applyStyles(this.styles.text);
            const textInCircleSize = geom.fitRectInCircle(markerSize, 1, 1);
            textProps = this.text.calcProps(textInCircleSize, { scaleToFit: false, textAlign: HorizontalAlignType.CENTER });
            if (textProps.isTextFit) {
                props.textInCircle = true;
                // this.shapeBounds = new geom.Rect(0, 0, markerSize, markerSize);
                textProps.bounds = getCenteredRect(textProps.size, markerProps.bounds);
                calculatedSize = markerProps.size;
            }
        }

        if (this.hasStoredPropChanged("textInCircle", props.textInCircle)) {
            this.markStylesAsDirty();
        }

        if (!props.textInCircle) {
            let textDirection = this.textDirection;
            if (textDirection === DirectionType.AUTO) {
                textDirection = DirectionType.RIGHT;
            }

            this.text.loadedStyles.applyStyles(this.styles.text.textDirection[textDirection]);

            // let textProps;
            let middleLine;
            switch (textDirection) {
                case DirectionType.LEFT:
                    textProps = this.text.calcProps(new geom.Size(this.textWidth, size.height), {
                        textAlign: HorizontalAlignType.RIGHT
                    });
                    if (markerSize > textProps.size.height) {
                        textProps.bounds = new geom.Rect(0, Math.max(0, markerSize / 2 - textProps.size.height / 2), textProps.size);
                        markerProps.bounds = new geom.Rect(textProps.size.width, Math.max(0, textProps.size.height / 2 - markerSize / 2), markerSize, markerSize);
                    } else {
                        let middleLine = Math.max(markerSize, textProps.blockProps[0].fontHeight) / 2;
                        markerProps.bounds = new geom.Rect(textProps.size.width, middleLine - markerSize / 2, markerSize, markerSize);
                        textProps.bounds = new geom.Rect(0, middleLine - textProps.blockProps[0].fontHeight / 2, textProps.size);
                    }
                    break;
                case DirectionType.RIGHT:
                    textProps = this.text.calcProps(new geom.Size(this.textWidth, size.height), {
                        textAlign: HorizontalAlignType.LEFT
                    });
                    if (markerSize > textProps.size.height) {
                        textProps.bounds = new geom.Rect(markerSize, Math.max(0, markerSize / 2 - textProps.size.height / 2), textProps.size);
                        markerProps.bounds = new geom.Rect(0, Math.max(0, textProps.size.height / 2 - markerSize / 2), markerSize, markerSize);
                    } else {
                        let middleLine = Math.max(markerSize, textProps.blockProps[0].fontHeight) / 2;
                        markerProps.bounds = new geom.Rect(0, middleLine - markerSize / 2, markerSize, markerSize);
                        textProps.bounds = new geom.Rect(markerSize, middleLine - textProps.blockProps[0].fontHeight / 2, textProps.size);
                    }

                    break;
                case DirectionType.TOP:
                    textProps = this.text.calcProps(new geom.Size(this.textWidth, size.height), {
                        textAlign: HorizontalAlignType.CENTER
                    });
                    middleLine = Math.max(markerSize, textProps.size.width) / 2;
                    textProps.bounds = new geom.Rect(middleLine - textProps.size.width / 2, 0, textProps.size);
                    markerProps.bounds = new geom.Rect(middleLine - markerSize / 2, textProps.size.height, markerSize, markerSize);
                    break;

                case DirectionType.BOTTOM:
                default:
                    textProps = this.text.calcProps(new geom.Size(this.textWidth, size.height), {
                        textAlign: HorizontalAlignType.CENTER
                    });
                    middleLine = Math.max(markerSize, textProps.size.width) / 2;
                    textProps.bounds = new geom.Rect(middleLine - textProps.size.width / 2, markerSize, textProps.size);
                    markerProps.bounds = new geom.Rect(middleLine - markerSize / 2, 0, markerSize, markerSize);
                    break;
            }

            calculatedSize = markerProps.bounds.union(textProps.bounds).size;
        }

        // if (this.isDragResizing) {
        //     return { size: new geom.Size(size.width, calculatedSize.height) };
        // } else {
        return { size: calculatedSize };
        // }
    }
}

export class TextInShapeContentItem extends ContentItem {
    get selectionPadding() {
        return 0;
    }

    get anchorBounds() {
        return this.shape.bounds.offset(this.bounds.position);
    }

    get registrationPoint() {
        if (this.options.getRegistrationPoint) {
            return this.options.getRegistrationPoint(this);
        }

        return this.shape.bounds.center;
    }

    get textDirection() {
        return null;
    }

    get showResizeHandle() {
        return true;
    }

    get maxWidth() {
        if (this.nodeType == NodeType.CIRCLE || this.nodeType == NodeType.DIAMOND) {
            return Math.min(500, super.maxWidth);
        } else {
            return Math.min(1180, super.maxWidth);
        }
    }

    get canChangeTextDirection() {
        return false;
    }

    _build() {
        this.shape = this.addElement("shape", () => SVGPathElement);

        this.text = this.addElement("text", () => TextElement, {
            blockStructure: BlockStructureType.HEADER,
            defaultBlockTextStyle: TextStyleType.TITLE,
            autoHeight: true,
            allowAlignment: this.options.allowTextAlignment ?? true,
            textAlign: this.options.textAlign ?? null,
            scaleTextToFit: true,
            selectionPadding: 0,
            allowedBlockTypes: this.options.allowedTextBlockTypes ?? null,
            syncFontSizeWithSiblings: this.options.syncFontSizeWithSiblings ?? false,
            textFormatBarOffset: this.options.textFormatBarOffset ?? 35,
            canEdit: this.canEditText,
            doubleClickToSelect: this.options.doubleClickToSelect,
            backgroundElement: this.shape
        });
    }

    _loadStyles(styles) {
        if (this.model.title) {
            // scale padding based on userFontScale to look better optically
            styles.text.paddingLeft = (this.model.title.userFontScale || 1) * styles.text.paddingLeft;
            styles.text.paddingRight = (this.model.title.userFontScale || 1) * styles.text.paddingRight;
            styles.text.paddingTop = (this.model.title.userFontScale || 1) * styles.text.paddingTop;
            styles.text.paddingBottom = (this.model.title.userFontScale || 1) * styles.text.paddingBottom;
        }
    }

    _calcProps(props, options) {
        let { size } = props;

        if (typeof this.model.size == "object") {
            size.width = Math.clamp(this.model.size.width ?? 200, this.minWidth, this.maxWidth);
            size.height = Math.clamp(this.model.size.height ?? 200, this.minHeight, this.maxHeight);
        } else {
            size.width = Math.clamp(this.textWidth || size.width, this.minWidth, this.maxWidth);
            size.height = Math.min(size.width, size.height);
        }

        if (this.canvas.layouter.isGenerating) {
            if (this.styles.shape.fillColor == "none") {
                this.shape.styles.fillColor = "backgroundColor";
            }
            if (this.model.showShadow) {
                this.shape.styles.filter = "annotationShadow";
            }
        }

        let shapeProps = this.shape.calcProps(size, {
            layer: -1
        });
        let textProps = this.text.calcProps(size, {
            autoWidth: this.autoWidth
        });

        if (this.autoWidth) {
            size.width = Math.clamp(textProps.size.width, this.minWidth, this.maxWidth);
        }

        let shapeBounds;
        if (typeof this.model.size == "object") {
            shapeBounds = new geom.Rect(0, 0, size).inflate(this.styles.textInset || 0).zeroOffset();
        } else {
            shapeBounds = new geom.Rect(0, 0, size.width, textProps.size.height).inflate(this.styles.textInset || 0).zeroOffset();
        }

        if (options.snapToGrid) {
            shapeBounds.width = Math.round(shapeBounds.width / options.snapToGrid) * options.snapToGrid;
            shapeBounds.height = Math.round(shapeBounds.height / options.snapToGrid) * options.snapToGrid;
        }

        switch (this.nodeType) {
            case NodeType.BOX:
                shapeProps.bounds = shapeBounds;
                shapeProps.path = Shape.drawRect(shapeBounds, this.styles.shape.cornerRadius / 2);
                break;
            case NodeType.CIRCLE:
                shapeProps.bounds = shapeBounds.square(true);
                shapeProps.path = Shape.drawCircle(shapeProps.bounds.width / 2, shapeProps.bounds.center).toPathData();
                break;
            case NodeType.DIAMOND:
                shapeProps.bounds = new geom.Rect(0, 0, shapeBounds.width + 20, (shapeBounds.width + 20) * .666);
                shapeProps.path = Shape.drawDiamond(shapeProps.bounds).toPathData();
                break;
            case NodeType.CAPSULE:
                shapeProps.bounds = shapeBounds;
                shapeProps.path = Shape.drawCapsule(shapeProps.bounds, 0).toPathData();
                break;
            case NodeType.TEXT:
                shapeProps.bounds = new geom.Rect(0, 0, textProps.size).inflate(15).zeroOffset();
                break;
        }
        textProps.bounds = getCenteredRect(textProps.size, shapeProps.bounds);

        return { size: shapeProps.bounds.size };
    }

    _applyColors() {
        this.colorSet.decorationColor = this.palette.getColor(this.model.color ?? "theme", this.getBackgroundColor(), this.itemIndex, this.decorationStyle);
    }
}

export class TextContentItem extends ContentItem {
    get anchorBounds() {
        return this.text.calculatedProps.textBounds.inflate(this.anchorPadding).offset(this.bounds.position);
    }

    get connectionShape() {
        return {
            bounds: this.text.calculatedProps.textBounds.inflate(this.anchorPadding),
            type: "rect"
        };
    }

    get migrationProps() {
        const bounds = this.bounds.offset(this.registrationPoint.x, this.registrationPoint.y);
        return new geom.Rect(bounds.x, bounds.y, new geom.Size(bounds.width, bounds.height).inflate(this.anchorPadding)).toXYObject();
    }

    get selectionPadding() {
        return 20;
    }

    get rolloverPadding() {
        return 20; // extra padding to bounds used when detecting if mouse is rolled over this element
    }

    get registrationPoint() {
        if (this.options.getRegistrationPoint) {
            return this.options.getRegistrationPoint(this);
        }

        return new geom.Point(this.calculatedProps.bounds.width / 2, this.calculatedProps.bounds.height / 2);
    }

    get textDirection() {
        return null;
    }

    get showResizeHandle() {
        return true;
    }

    get allowDecorationStyles() {
        return false;
    }

    get canChangeTextDirection() {
        return false;
    }

    _loadStyles(styles) {
        if (this.getRootElement().type === "NodeDiagram") {
            styles.applyStyles(this.getRootElement().styles);
        }
    }

    _build() {
        this.text = this.addElement("text", () => TextElement, {
            blockStructure: BlockStructureType.HEADER,
            defaultBlockTextStyle: TextStyleType.TITLE,
            autoWidth: this.autoWidth,
            scaleTextToFit: true,
            autoHeight: true,
            allowAlignment: this.options.allowTextAlignment ?? true,
            textAlign: this.options.textAlign ?? null,
            forceRefreshOnKeyPress: true, // necessary because we are anchoring the connectors to the actual text bounds - not the element bounds
            allowedBlockTypes: this.options.allowedTextBlockTypes ?? null,
            syncFontSizeWithSiblings: this.options.syncFontSizeWithSiblings ?? false,
            textFormatBarOffset: this.options.textFormatBarOffset ?? 35,
            canEdit: this.canEditText,
            doubleClickToSelect: this.options.doubleClickToSelect,
            showTextBackdrop: this.options.showTextBackdrop,
        });
    }

    _calcProps(props, options) {
        const { size } = props;

        size.width = Math.clamp(this.textWidth || size.width, this.minWidth, this.maxWidth);

        const textProps = this.text.calcProps(size, { autoWidth: this.autoWidth });

        size.width = Math.clamp(textProps.size.width, this.minWidth, this.maxWidth);

        textProps.bounds = new geom.Rect(0, 0, textProps.size);

        if (this.isDragResizing) {
            return { size: new geom.Size(size.width, textProps.size.height) };
        } else {
            return { size: new geom.Size(textProps.size.width, textProps.size.height) };
        }
    }
}

export class MediaContentItem extends ContentItem {
    get anchorBounds() {
        return this.content.bounds.offset(this.bounds.position);
    }

    get registrationPoint() {
        if (this.options.getRegistrationPoint) {
            return this.options.getRegistrationPoint(this);
        }

        return this.content.bounds.center;
    }

    get connectionShape() {
        let frameType = this.content.frameType;
        // We want the connector to end closer to icons when they don't have a decoration
        if (frameType === "none" && this.content.assetType === AssetType.ICON) {
            frameType = "circle";
        }

        return {
            bounds: this.content.bounds.inflate(this.anchorPadding),
            type: frameType
        };
    }

    get horizontalScaleOrigin() {
        if (this.options.horizontalScaleOrigin) {
            return this.options.horizontalScaleOrigin;
        }

        return PositionType.CENTER;
    }

    get minMarkerSize() {
        return 20;
    }

    get maxMarkerSize() {
        return 400;
    }

    get showResizeHandle() {
        return true;
    }

    setUserWidth(width) {
        this.model.size = Math.clamp(width, this.minMarkerSize, this.maxMarkerSize);
    }

    get canChangeTextDirection() {
        return false;
    }

    get mediaElement() {
        return this.content;
    }

    _build() {
        this.content = this.addElement("content", () => FramedMediaElement, {
            defaultAssetType: AssetType.ICON,
            canSelect: false,
            autoHeight: true,
            frameType: this.options.frameType,
            allowUnframedImages: true,
            frameColor: this.model.color,
        });
    }

    _calcProps(props, options) {
        let { size } = props;

        size.width = size.height = this.getMarkerSize();
        if (options.maxSize) {
            size.width = size.height = Math.min(options.maxSize, size.width);
        }

        let contentProps = this.content.calcProps(size);
        contentProps.bounds = new geom.Rect(0, 0, contentProps.size);

        return { size: contentProps.size };
    }
}

class MarkerAndTextContentItem extends ContentItem {
    get anchorBounds() {
        return this.marker.bounds.offset(this.bounds.position).inflate(0);
    }

    get connectionShape() {
        return {
            bounds: this.marker.bounds.inflate(this.anchorPadding),
            type: this.options.connectionShape ?? "circle"
        };
    }

    get selectionPadding() {
        return 15;
    }

    get rolloverPadding() {
        return 15; // extra padding to bounds used when detecting if mouse is rolled over this element
    }

    get registrationPoint() {
        if (this.options.getRegistrationPoint) {
            return this.options.getRegistrationPoint(this);
        }

        return this.marker.bounds.center;
    }

    get defaultDirectionType() {
        return DirectionType.AUTO;
    }

    get showResizeHandle() {
        return true; // this is for the text
    }

    get minMarkerSize() {
        return 50;
    }

    get maxMarkerSize() {
        return 400;
    }

    getMarkerSize() {
        return Math.clamp(this.model.size || this.styles.markerSize || 10, this.minMarkerSize, this.maxMarkerSize);
    }

    buildMarker() {
        return this.addElement("marker", () => SVGCircleElement);
    }

    getDefaultConnectorColor() {
        return ForeColorType.SECONDARY;
    }

    _build() {
        this.marker = this.buildMarker();

        this.text = this.addElement("text", () => TextElement, {
            blockStructure: BlockStructureType.HEADER,
            defaultBlockTextStyle: TextStyleType.TITLE,
            allowAlignment: false,
            autoWidth: this.autoWidth,
            autoHeight: true,
            scaleTextToFit: true,
            allowedBlockTypes: this.options.allowedTextBlockTypes ?? null,
            syncFontSizeWithSiblings: this.options.syncFontSizeWithSiblings ?? false,
            showBackdrop: true,
            textFormatBarOffset: 45,
            canEdit: this.canEditText,
            doubleClickToSelect: this.options.doubleClickToSelect,
            elementSelection: ContentItemTextSelection,
        });
    }

    _calcProps(props, options) {
        let { size } = props;

        let textDirection = options.textDirection || this.textDirection;
        if (textDirection === DirectionType.AUTO) {
            textDirection = this.getAutoTextDirection(size);
        }
        this.calculatedTextDirection = textDirection;

        let markerSize = this.getMarkerSize();
        let markerProps = this.marker.calcProps(new geom.Size(markerSize, markerSize));

        this.text.styles.applyStyles(this.styles.text);  // resets styles to default
        this.text.styles.applyStyles(this.styles.text.textDirection[textDirection]);

        let textProps;
        let middleLine;
        switch (textDirection) {
            case DirectionType.LEFT:
                textProps = this.text.calcProps(new geom.Size(this.textWidth, size.height), {
                    textAlign: HorizontalAlignType.RIGHT,
                });

                if (markerProps.size.height > textProps.size.height) {
                    textProps.bounds = new geom.Rect(0, Math.max(0, markerProps.size.height / 2 - textProps.size.height / 2), textProps.size);
                    markerProps.bounds = new geom.Rect(textProps.size.width, Math.max(0, textProps.size.height / 2 - markerProps.size.height / 2), markerProps.size);
                } else {
                    let lineHeight = textProps.blockProps[0].fontHeight;
                    let middleLine = Math.max(markerProps.size.height, lineHeight) / 2;
                    markerProps.bounds = new geom.Rect(textProps.size.width, middleLine - markerProps.size.height / 2, markerProps.size);
                    textProps.bounds = new geom.Rect(0, middleLine - lineHeight / 2, textProps.size);
                }
                break;
            case DirectionType.RIGHT:
                textProps = this.text.calcProps(new geom.Size(this.textWidth, size.height), {
                    textAlign: HorizontalAlignType.LEFT,
                });

                if (markerProps.size.height > textProps.size.height) {
                    textProps.bounds = new geom.Rect(markerSize, Math.max(0, markerProps.size.height / 2 - textProps.size.height / 2), textProps.size);
                    markerProps.bounds = new geom.Rect(0, Math.max(0, textProps.size.height / 2 - markerProps.size.height / 2), markerProps.size);
                } else {
                    let lineHeight = textProps.blockProps[0].fontHeight;
                    let middleLine = Math.max(markerProps.size.height, lineHeight) / 2;
                    markerProps.bounds = new geom.Rect(0, middleLine - markerProps.size.height / 2, markerProps.size);
                    textProps.bounds = new geom.Rect(markerProps.size.width, middleLine - lineHeight / 2, textProps.size);
                }

                break;
            case DirectionType.TOP:
                textProps = this.text.calcProps(new geom.Size(this.textWidth, size.height), {
                    textAlign: HorizontalAlignType.CENTER
                });
                middleLine = Math.max(markerProps.size.width, textProps.size.width) / 2;
                textProps.bounds = new geom.Rect(middleLine - textProps.size.width / 2, 0, textProps.size);
                markerProps.bounds = new geom.Rect(middleLine - markerProps.size.width / 2, textProps.size.height, markerProps.size);
                break;

            case DirectionType.BOTTOM:
            default:
                textProps = this.text.calcProps(new geom.Size(this.textWidth, size.height), {
                    textAlign: HorizontalAlignType.CENTER
                });
                middleLine = Math.max(markerProps.size.width, textProps.size.width) / 2;
                textProps.bounds = new geom.Rect(middleLine - textProps.size.width / 2, markerProps.size.height, textProps.size);
                markerProps.bounds = new geom.Rect(middleLine - markerProps.size.width / 2, 0, markerProps.size);
                break;
        }

        let calculatedSize = markerProps.bounds.union(textProps.bounds).size;

        return { size: calculatedSize, textDirection };
    }

    // _applyColors() {
    //     // to match existing behavior, we need to set the marker color to the pure decoration color so it doesn't use decorationStyles from the theme
    //     this.marker.colorSet = {
    //         decorationFillColor: this.colorSet.decorationColor,
    //         backgroundColor: this.colorSet.decorationColor
    //     };
    // }
}

export class MediaAndTextContentItem extends MarkerAndTextContentItem {
    get showResizeSlider() {
        return this.options.showResizeSlider ?? true; // this is for the content
    }

    get mediaElement() {
        return this.marker;
    }

    buildMarker() {
        return this.addElement("media", () => FramedMediaElement, {
            defaultAssetType: AssetType.ICON,
            canSelect: false,
            autoHeight: true,
            frameType: this.options.frameType,
            allowUnframedImages: true
        });
    }
}

export class BulletTextContentItem extends MarkerAndTextContentItem {
    get migrationProps() {
        return this.bounds.inflate(10).toXYObject();
    }

    get allowDecorationStyles() {
        return false;
    }

    buildMarker() {
        return this.addElement("marker", () => SVGCircleElement);
    }

    getMarkerSize() {
        return this.marker.styles.width;
    }

    get decorationStyle() {
        return DecorationStyle.FILLED;
    }
}

export class NumberedTextContentItem extends MarkerAndTextContentItem {
    buildMarker() {
        let options = {
            blockStructure: BlockStructureType.SINGLE_BLOCK,
            backgroundElement: this.decoration
        };

        if (this.parentElement.canEditNumberedTextContentItemMarkerValue) {
            options.canEdit = true;
            if (this.model.value == null) {
                this.model.value = {
                    blocks: [{
                        type: AuthoringBlockType.TEXT,
                        textStyle: USE_STYLESHEET_FOR_TEXT_STYLES,
                        html: (this.itemIndex + 1).toString()
                    }]
                };
            }
        } else {
            options.canEdit = false;
            options.html = (this.itemIndex + 1).toString();
        }

        return this.addElement("value", () => TextElement, options);
    }

    getMarkerSize() {
        return this.marker.styles.width;
    }

    _migrate_10() {
        super._migrate_10();
        if (this.model.label) {
            this.model.value = this.model.label;
            delete this.model.label; // this feature to edit the label value of a numbered contentitem is deprecated and we need to remove the model or it will get migrated into the main text blocks
        }
    }

    _migrate_10_02() {
        this.model.decorationStyle = DecorationStyle.FILLED;
    }
}

export class LetteredTextContentItem extends MarkerAndTextContentItem {
    buildMarker() {
        let options = {
            blockStructure: BlockStructureType.SINGLE_BLOCK,
        };

        if (this.parentElement.canEditNumberedTextContentItemMarkerValue) {
            options.canEdit = true;
            if (this.model.value == null) {
                this.model.value = {
                    blocks: [{
                        type: AuthoringBlockType.TEXT,
                        textStyle: USE_STYLESHEET_FOR_TEXT_STYLES,
                        html: String.fromCharCode(65 + this.itemIndex)
                    }]
                };
            }
        } else {
            options.canEdit = false;
            options.html = String.fromCharCode(65 + this.itemIndex);
        }

        return this.addElement("value", () => TextElement, options);
    }

    getMarkerSize() {
        return this.marker.styles.width;
    }

    _migrate_10_02() {
        this.model.decorationStyle = DecorationStyle.FILLED;
    }
}

import React, { Component } from "react";
import * as geom from "js/core/utilities/geom";
import { HorizontalPropertyList, PropertyPanelContainer, PropertySection } from "../../../../EditorComponents/PropertyPanel";
import { WithLabel } from "../../../../Components/WithLabel";
import { ImageOption, ImageOptionList } from "../../../../Components/ImageOptionList";
import { TextFrameBoxPropertyPanel } from "./TextFrameBoxUI";
import { ToggleIcon } from "../../../../Components/ToggleSwitch";
import { BaseElementSelection } from "../ElementSelections/BaseElementSelection";
import { ResizeDirection } from "../../../../../common/constants";
import { defaultDragResizeProps, DragHandleStyle } from "../../../../editor/PresentationEditor/DragElementManager";
import { HeadlinePositionType } from "../../elements/elements/Headline";
import { ShowAssetDialog } from "../../../../react/views/AddAssets/ShowAssetDialog";
import { InfographicElementTypes } from "../../elements/elements/Dashboard/InfographicManager";
import { PropertyPanelButton } from "../../../../EditorComponents/PropertyPanelButton";

async function addMediaToHeadline(element, position) {
    let asset = await ShowAssetDialog();
    element.updateModel({
        headlinePosition: position,
        items: [{
            componentType: InfographicElementTypes.TEXT_AND_IMAGE,
            childElement: asset
        }],
        gridLayout: {
            cols: 12,
            rows: 12,
            cells: [{ x: 0, y: 0, width: 12, height: 12 }]
        }
    });
}

export class HeadlinePropertyPanel extends Component {
    render() {
        const { element } = this.props;

        return (
            <PropertyPanelContainer>
                <PropertySection>
                    <WithLabel label="Layout" above left>
                        <ImageOptionList cols={3} value={element.headlinePosition}
                            onChange={async headlinePosition => {
                                let items = element.model.items;
                                if (headlinePosition == "none") {
                                    headlinePosition = HeadlinePositionType.CENTER;
                                    items = null;
                                } else if (!element.showImage) {
                                    return addMediaToHeadline(element, headlinePosition);
                                }
                                element.updateModel({ headlinePosition, items });
                            }}>
                            <ImageOption key="none" value="none" url="/images/ui/headline/headline-noimage.svg" />
                            <ImageOption key="background" value={HeadlinePositionType.CENTER} url="/images/ui/headline/headline-background.svg" />
                            <ImageOption key="left" value={HeadlinePositionType.RIGHT} url="/images/ui/headline/headline-right.svg" />
                            <ImageOption key="right" value={HeadlinePositionType.LEFT} url="/images/ui/headline/headline-left.svg" />
                            <ImageOption key="top" value={HeadlinePositionType.BOTTOM} url="/images/ui/headline/headline-bottom.svg" />
                            <ImageOption key="bottom" value={HeadlinePositionType.TOP} url="/images/ui/headline/headline-top.svg" />
                        </ImageOptionList>
                    </WithLabel>
                </PropertySection>
                {element.showImage && (
                    <PropertySection>
                        <HorizontalPropertyList>
                            <WithLabel label="Gutter" below center small>
                                <ToggleIcon icon="grid_view" iconOff="window"
                                    value={element.images.showGutter}
                                    onChange={value => element.images.updateModel({ showGutter: value })} />
                            </WithLabel>
                            <WithLabel label="Full Bleed" below center small>
                                <ToggleIcon icon="aspect_ratio"
                                    value={element.fullBleed}
                                    onChange={value => element.updateModel({ fullBleed: value })} />
                            </WithLabel>
                        </HorizontalPropertyList>
                    </PropertySection>
                )}

                {!element.showImage &&
                    <PropertyPanelButton icon="add_a_photo"
                        title="Add Media to Cell..."
                        description="Add an image, icon or video to fill the cell."
                        onClick={() => addMediaToHeadline(element, HeadlinePositionType.CENTER)}
                    />
                }

                <TextFrameBoxPropertyPanel element={element.textFrame.textFrameBox} />
            </PropertyPanelContainer>
        );
    }
}

export class HeadlineSelection extends BaseElementSelection {
    constructor(props) {
        super(props);

        this.state = {
            isMouseOver: false
        };

        window.addEventListener("mousemove", this.handleMouseMove);
    }

    componentWillUnmount() {
        window.removeEventListener("mousemove", this.handleMouseMove);
    }

    handleMouseMove = event => {
        let bounds = geom.Rect.FromBoundingClientRect(this.containerRef.current.getBoundingClientRect());
        this.setState({ isMouseOver: bounds.contains(event.clientX, event.clientY) });
    }

    get showSelectionBorder() {
        return false;
    }

    get canResize() {
        const { element } = this.props;
        const { isMouseOver } = this.state;

        if (isMouseOver) {
            return element.headlinePosition !== HeadlinePositionType.CENTER;
        } else {
            return false;
        }
    }

    get canDelete() {
        return false;
    }

    get dragResizeProps() {
        const { element } = this.props;

        // if (!element.bounds) return;

        let maxSize, minSize;
        if (element.headlinePosition === HeadlinePositionType.LEFT || element.headlinePosition === HeadlinePositionType.RIGHT) {
            maxSize = element.dividerMax * element.bounds.width;
            minSize = element.dividerMin * element.bounds.width;
        } else {
            maxSize = element.dividerMax * element.bounds.height;
            minSize = element.dividerMin * element.bounds.height;
        }

        let size;

        let resizeDirections, offsetX, offsetY;
        switch (element.headlinePosition) {
            case HeadlinePositionType.LEFT:
                resizeDirections = [ResizeDirection.RIGHT];
                offsetX = (-element.bounds.width + element.dividerSize * element.bounds.width - 20) * element.canvas.getScale();
                break;
            case HeadlinePositionType.RIGHT:
                resizeDirections = [ResizeDirection.LEFT];
                offsetX = (element.bounds.width - element.dividerSize * element.bounds.width + 20) * element.canvas.getScale();
                break;
            case HeadlinePositionType.TOP:
                resizeDirections = [ResizeDirection.BOTTOM];
                offsetY = (-element.bounds.height + element.dividerSize * element.bounds.height - 20) * element.canvas.getScale();
                break;
            case HeadlinePositionType.BOTTOM:
                resizeDirections = [ResizeDirection.TOP];
                offsetY = (element.bounds.height - element.dividerSize * element.bounds.height + 20) * element.canvas.getScale();
                break;
        }

        return {
            ...defaultDragResizeProps,
            handleProps: {
                style: DragHandleStyle.DIVIDER,
                offset: {
                    x: offsetX,
                    y: offsetY
                }
            },
            resizeDirections,
            onDragStart: async () => {
                if (element.headlinePosition === HeadlinePositionType.LEFT || element.headlinePosition === HeadlinePositionType.RIGHT) {
                    size = element.dividerSize * element.bounds.width;
                } else {
                    size = element.dividerSize * element.bounds.height;
                }
            },
            onDrag: async ({ dragOffset, resizeDirection }) => {
                switch (element.headlinePosition) {
                    case HeadlinePositionType.LEFT:
                        element.model.dividerSize = Math.clamp(size + dragOffset.x, minSize, maxSize) / element.bounds.width;
                        break;
                    case HeadlinePositionType.RIGHT:
                        element.model.dividerSize = Math.clamp(size - dragOffset.x, minSize, maxSize) / element.bounds.width;
                        break;
                    case HeadlinePositionType.TOP:
                        element.model.dividerSize = Math.clamp(size + dragOffset.y, minSize, maxSize) / element.bounds.height;
                        break;
                    case HeadlinePositionType.BOTTOM:
                        element.model.dividerSize = Math.clamp(size - dragOffset.y, minSize, maxSize) / element.bounds.height;
                        break;
                }

                await element.canvas.refreshCanvas();
            }
        };
    }
}

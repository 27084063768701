import { RewriteTextTask } from "common/aiConstants";
import { TextStyleType } from "common/constants";
import aiGenerationService from "js/core/services/aiGeneration";
import { sanitizeHtmlText } from "js/core/utilities/htmlTextHelpers";
import { trackActivity } from "js/core/utilities/utilities";
import { ShowErrorDialog } from "js/react/components/Dialogs/BaseDialog";
import { _ } from "js/vendor";
import getLogger, { LogGroup } from "../logger";

const logger = getLogger(LogGroup.AI);

type GenTextTrackProps = {
    task: RewriteTextTask,
    isCustomPrompt: boolean,
    isRecentlyUsed: boolean,
    prompt: string,
    selectedText: string,
    variationCount: number,
    results?: string[],
    result?: string,
}

export enum GenTextApplyAction {
    APPEND = "append",
    REPLACE = "replace",
}

type GenTextResult = {
    text: string,
    applyAction: GenTextApplyAction,
    trackProps: GenTextTrackProps,
}

type GenTextState = Partial<{
    results: GenTextResult[],
    isGenerating: boolean,
    error: any,
}>

type GenTextProps = {
    task: RewriteTextTask,
    prompt?: string,
    selectedText?: string,
    textboxText?: string,
    allText?: string,
    textStyle?: string,
    variationCount?: number,
    isCustomPrompt?: boolean,
    isRecentlyUsed?: boolean,
    shorten?: boolean,
    lengthen?: boolean,
    onReportState?: (state: GenTextState) => void,
}

export async function generateText({
    task,
    prompt = "",
    selectedText = "",
    textboxText = "",
    allText = "",
    textStyle = "",
    variationCount = 3,
    isCustomPrompt = false,
    isRecentlyUsed = false,
    shorten = false,
    lengthen = false,
    onReportState = state => { },
}: GenTextProps) {
    const userPrompt = sanitizeHtmlText(prompt.trim().toLowerCase());

    if (userPrompt.length > 200) {
        ShowErrorDialog({ title: "Sorry, custom prompts are limited to 200 characters" });
        return;
    }

    let actualPrompt = userPrompt;
    const text = selectedText || textboxText;

    const words = text.split(/\s/).filter(x => !!x);
    if (shorten) {
        const wordLength = Math.floor(words.length * 0.5);
        if (actualPrompt) {
            actualPrompt = `text that is ${actualPrompt} and is shorter and more concise`;
        } else {
            actualPrompt = `text that is shorter and more concise, ideally around ${wordLength} words.`;
        }
    } else if (lengthen) {
        const wordLength = Math.floor(words.length * 1.3);
        const maxWordLength = Math.ceil(words.length * 3.5);
        if (actualPrompt) {
            actualPrompt = `text that is elaborated on and is ${actualPrompt} and has at least ${wordLength} words`;
        } else {
            actualPrompt = `text that is elaborated on and has at least ${wordLength} words but less than ${maxWordLength} words.`;
        }
    } else if (task === RewriteTextTask.GENERATE) {
        let size = "";
        switch (textStyle) {
            case TextStyleType.BODY:
                size = "15-40";
                break;
            case TextStyleType.HEADING:
            case TextStyleType.HEADLINE:
                size = "3-10";
                break;
            case TextStyleType.LABEL:
                size = "less than 5";
                break;
            default:
                size = "5-15";
                break;
        }

        if (actualPrompt) {
            actualPrompt = `${textStyle} text that is ${actualPrompt} in ${size} words`;
        } else {
            actualPrompt = `${textStyle} text in ${size} words`;
        }
    } else if (task === RewriteTextTask.REWRITE) {
        actualPrompt = `${actualPrompt}\nKeep the length of the text as similar as possible, around ${words.length} words.`;
    }

    onReportState({
        results: [],
        isGenerating: true,
        error: null,
    });

    let textResults: string[] = [];
    try {
        for (let retryCount = 0; retryCount < 3; ++retryCount) {
            const { results } = await aiGenerationService.rewriteText({
                text,
                allText: (task === RewriteTextTask.GENERATE ? allText : textboxText) || "attention",
                task,
                customPrompt: actualPrompt,
                variationCount: variationCount - textResults.length, // Only attempt to generate as many results as we need
            });

            // Add to any exiting results
            textResults.push(...results);

            const originalText = selectedText || textboxText;

            // Don't remove the parroting of input text for spellcheck results
            if (task === RewriteTextTask.SPELLCHECK) {
                // Remove parroting of context text if it's different than the original text
                if (allText !== originalText) {
                    textResults = textResults.filter(x =>
                        x !== allText
                    );
                }
                // Remove parroting of textbox text if it's different than the original text
                if (textboxText !== originalText) {
                    textResults = textResults.filter(x =>
                        x !== textboxText
                    );
                }
                // Remove parroting of prompt text if it's different than the original text
                if (actualPrompt !== originalText) {
                    textResults = textResults.filter(x =>
                        x !== actualPrompt
                    );
                }
                // Otherwise, keep textbox text if we don't
            } else {
                // Remove parroting of both textbox text and selected text
                textResults = textResults.filter(x =>
                    x !== allText &&
                    x !== textboxText &&
                    x !== selectedText &&
                    x !== actualPrompt
                );
            }

            // Eliminate duplicate results
            textResults = _.uniq(textResults);

            textResults = textResults
                // Eliminate blank results
                .filter(x => !!x)
                .map(x => {
                    // Remove periods from results if the existing text does not already include any periods
                    if (!textboxText.includes(".")) {
                        // Clear period at end
                        x = x.trim().replace(/\.$/, "");
                    }
                    // Clear bullet
                    x = x.replace("•", "").trim();
                    return x;
                });
            // Limit results to the variation count
            if (textResults.length > variationCount) {
                textResults.splice(variationCount, Number.POSITIVE_INFINITY);
            }

            // If we have variation count valid results, stop retrying
            if (textResults.length === variationCount) {
                break;
            }
        }

        // Throw an error if we have no valid results
        if (!textResults.length) {
            throw new Error("[generateText] Unable to generate different results after 3 tries.");
        }

        // Convert the results into an object
        let applyAction = GenTextApplyAction.REPLACE;
        if (task === RewriteTextTask.GENERATE) {
            applyAction = GenTextApplyAction.APPEND;
        }

        const trackProps: GenTextTrackProps = {
            task,
            isCustomPrompt,
            isRecentlyUsed,
            prompt: userPrompt,
            selectedText,
            variationCount,
            results: textResults,
        };

        trackActivity("TextRewrite", "Generate", null, null, trackProps);

        const results = textResults.map(text => (<GenTextResult>{
            text,
            applyAction,
            trackProps,
        }));

        onReportState({
            results,
            isGenerating: false,
        });
    } catch (error) {
        logger.error(error, "[GenerateText]", { textResults });

        onReportState({
            isGenerating: false,
            error,
        });
    }
}

import React, { Component } from "react";
import styled from "styled-components";

import { DefaultOverlayContainer } from "./Components/DefaultOverlayContainer";

const Container = styled(DefaultOverlayContainer)`
    cursor: pointer;
    pointer-events: auto;
`;

export class BigNumberChangeInValueIconDefaultOverlay extends Component {
    handleMouseUp = () => {
        const { element } = this.props;
        return element.updateModel({ positive: !element.model.positive }, { refreshStyles: true });
    };

    render() {
        return (<Container
            background={false}
            outline={false}
            onMouseUp={this.handleMouseUp}
        />);
    }
}

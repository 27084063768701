import { ElementTextBlockPositionType, HeaderPositionType, ShowFooterType, TrayType } from "common/constants";
import { withMaxConcurrency } from "common/utils/withMaxConcurrency";
import getLogger, { LogGroup } from "js/core/logger";
import { findElementsWithImages, generateImagesForElement } from "js/core/services/slideModelBuilder";
import * as geom from "js/core/utilities/geom";
import { _ } from "js/vendor";
import React from "react";

import { CalloutsCanvas } from "../elements/Callouts/CalloutsCanvas";
import { ElementAttribution, ElementTextBlock } from "../elements/CanvasTextBoxes";
import { Footer } from "../elements/Footer";
import { Header } from "../elements/Header";
import { BottomTray } from "../elements/LayoutContainers/BottomTray";
import { VideoOverlay } from "../VideoOverlay/VideoOverlay";
import { BaseElement } from "./BaseElement";
import { CanvasBackground } from "./CanvasBackground";
import { SideBarContainer } from "./SideBarContainer";

const logger = getLogger(LogGroup.ELEMENTS);

export class CanvasElement extends BaseElement {
    constructor(props) {
        super(props);

        this.refFilters = React.createRef();
        this.generateImagesPromise = null;
    }

    get isSelected() {
        return true;
    }

    getSelectableParent() {
        return this;
    }

    get alwaysRefreshStyles() {
        return false;
    }

    get headerPosition() {
        if (this.template.allowHeader) {
            return this.model.layout.headerPosition || HeaderPositionType.TOP;
        }

        return HeaderPositionType.NONE;
    }

    get headerWidth() {
        return this.model.layout.headerWidth || 400;
    }

    get canShowFooter() {
        return this.template.allowFooter && this.trayLayout != TrayType.BOTTOM_TRAY && this.model.layout.elementTextBlockPosition != ElementTextBlockPositionType.TRAY;
    }

    get fullBleedPrimaryElement() {
        return this.template.fullBleedPrimaryElement === true;
    }

    get showFooter() {
        if (!this.canShowFooter) return false;

        // if the layout showFooter was set, always show the footer
        if (this.model.layout.showFooter == ShowFooterType.ON) {
            return true;
        } else if (this.model.layout.showFooter == ShowFooterType.OFF) {
            return false;
        }

        // otherwise use the theme's showFooterByDefault property UNLESS the template is set to preventDefaultFooter
        return this.canvas.getTheme().get("showFooterByDefault") === true && this.template.preventDefaultFooter === false;
    }

    get elementTextBlockPosition() {
        return this.model.layout.elementTextBlockPosition || ElementTextBlockPositionType.NONE;
    }

    get showAttribution() {
        return Boolean(this.model.layout.showElementAttribution);
    }

    get trayLayout() {
        try {
            if (!this.model.layout) {
                logger.warn("[CanvasElement] this.model.layout missing", { slideId: this.canvas.dataModel?.id });
                throw new Error("Cannot open presentation due to an error. Please refresh your browser.");
            }
            const trayLayout = this.model.layout.trayLayout || this.template.defaultTrayLayout || TrayType.NONE;
            if (trayLayout == TrayType.NONE || this.template.availableTrayLayouts.contains(trayLayout)) {
                return trayLayout;
            } else {
                logger.warn("[CanvasElement] Invalid tray layout defined for this slide - " + trayLayout + " is not permitted for a " + this.template.constructor.id + " template.", { slideId: this.canvas.dataModel?.id });
                this.model.layout.trayLayout = TrayType.NONE;
                return TrayType.NONE;
            }
        } catch (err) {
            logger.error(err, "trayLayout() failed", { slideId: this.canvas.dataModel?.id });
            return TrayType.NONE;
        }
    }

    get maxTrayWidth() {
        if (this.elements.primary) {
            return this.canvas.CANVAS_WIDTH - this.elements.primary.minWidth;
        } else {
            return this.canvas.CANVAS_WIDTH / 2;
        }
    }

    get minTrayWidth() {
        return 200;
    }

    get maxTrayHeight() {
        if (this.elements.primary) {
            return this.canvas.CANVAS_HEIGHT - this.elements.primary.minHeight;
        } else {
            return this.canvas.CANVAS_HEIGHT / 2;
        }
    }

    get minTrayHeight() {
        return 200;
    }

    refreshElement(transition) {
        // We don't want to reach the top of the tree for this function, in this case you should
        // call canvas.refreshCanvas() instead.
        throw new Error("Can not refresh the whole canvas, use canvas.refreshCanvas() insted");
    }

    get canRefreshElement() {
        return false;
    }

    get childrenLayers() {
        return {
            background: 0,
            header: this.overlay ? 9 : 1,
            tray: 2,
            primary: 3,
            footer: 4,
            elementTextBlock: 5,
            elementAttribution: 6,
            annotations: 7,
            overlay: 8,
            callouts: 9,
            videoOverlay: 10
        };
    }

    get showCallouts() {
        return this.canvas.slideTemplate.allowCallouts !== false;
    }

    exportToSharedModel() {
        const sharedModel = Object.values(this.elements)
            .reduce((model, el) => _.mergeWith(model, el.exportToSharedModel(), (a, b) => {
                if (_.isArray(a)) return a.concat(b);
            }), {});

        // remove empty keys from model updates
        Object.keys(sharedModel).forEach(key => {
            if (_.isEmpty(sharedModel[key])) delete sharedModel[key];
        });

        return sharedModel;
    }

    importFromSharedModel(model) {
        const elementModels = Object.values(this.elements)
            .map(el => {
                let elModel;
                try {
                    elModel = el.importFromSharedModel(model);
                } catch (err) {
                    logger.info("Error importing element from shared model", { err });
                }
                if (!elModel) {
                    elModel = _.cloneDeep(el.canvas.slideTemplate.defaultData?.[el.id]);
                }
                return elModel;
            });

        const postProcessing = elementModels.filter(el => !!el.postProcessingFunction);
        if (postProcessing.length) {
            return canvas => postProcessing.forEach(el => el.postProcessingFunction(canvas));
        }
    }

    async _load() {
        if (!this.canvas.generateImages) {
            return;
        }

        if (this.generateImagesPromise) {
            // Already generating
            return;
        }

        const imagesToGenerate = findElementsWithImages(this.model);
        if (imagesToGenerate.length === 0) {
            // Nothing to generate
            return;
        }

        // NOTE: we're not awaiting the result of this promise, as we don't want to block the loading of the slide
        this.generateImagesPromise = (async () => {
            try {
                await withMaxConcurrency(
                    imagesToGenerate.map(image => () =>
                        generateImagesForElement(image, this.slide.presentation?.get("metadata"))
                            .then(() => {
                                if (this.canvas.layouter) {
                                    return this.canvas.refreshCanvas();
                                }
                            })
                    ),
                    3
                );

                await this.canvas.saveCanvasModel();
            } catch (err) {
                logger.error(err, "Error generating images for slide", { slideId: this.canvas.slide.id });
            }
        })();
    }

    applyColors() {
        this.clearColors(); // make sure all colorSets are cleared before applying new ones

        this.colorSet = {
            backgroundColor: this.background.canvasBackgroundColor,
        };
        for (const element of Object.values(this.elements)) {
            element.applyColors();
        }
    }

    build(model, generationKey) {
        // Handling old slides that had old values for migrationVersion
        if (model.migrationVersion && model.version && model.migrationVersion < model.version) {
            model.migrationVersion = model.version;
        } else if (model.migrationVersion && !model.version) {
            model.migrationVersion = null;
        }

        const lowerBoundInclusiveVersion = model.migrationVersion ?? model.version ?? 4;
        const upperBoundInclusiveVersion = this.canvas.migrationVersion;
        super.build(model, generationKey, lowerBoundInclusiveVersion, upperBoundInclusiveVersion);

        this.model.version = this.canvas.bundleVersion;
        this.model.migrationVersion = upperBoundInclusiveVersion;
    }

    _build() {
        this.background = this.addElement("background", () => CanvasBackground, {
            model: {
                backgroundColor: this.model.layout.backgroundColor,
                backgroundStyle: this.model.layout.backgroundStyle,
                customBackgroundImage: this.model.layout.customBackgroundImage,
            }
        });

        if (this.headerPosition != HeaderPositionType.NONE) {
            if (!this.model.elements.header) {
                this.model.elements.header = {};
            }
            this.header = this.addElement("header", () => Header, { model: this.model.elements.header });
        }

        if (!this.model.elements.primary) {
            this.model.elements.primary = {};
        }

        this.primary = this.addElement("primary", () => this.canvas.elementManager.get(this.template.elementType), { model: this.model.elements.primary });

        if (this.showFooter) {
            if (!this.model.elements.footer) {
                this.model.elements.footer = {};
            }
            this.footer = this.addElement("footer", () => Footer, { model: this.model.elements.footer });
        }

        if (this.elementTextBlockPosition !== ElementTextBlockPositionType.NONE) {
            if (!this.model.elements.elementTextBlock) {
                this.model.elements.elementTextBlock = {};
            }
            if (this.elementTextBlockPosition == ElementTextBlockPositionType.TRAY) {
                this.elementTextBlock = this.addElement("elementTextBlock", () => BottomTray, { model: this.model.elements.elementTextBlock });
            } else {
                this.elementTextBlock = this.addElement("elementTextBlock", () => ElementTextBlock, { model: this.model.elements.elementTextBlock });
            }
        }

        if (this.trayLayout !== TrayType.NONE) {
            if (!this.model.elements.tray) {
                this.model.elements.tray = {};
            }
            this.tray = this.addElement("tray", () => SideBarContainer, { model: this.model.elements.tray });
        }

        if (this.showAttribution) {
            if (!this.model.elements.elementAttribution) {
                this.model.elements.elementAttribution = {};
            }
            this.elementAttribution = this.addElement("elementAttribution", () => ElementAttribution, { model: this.model.elements.elementAttribution });
        }

        if (this.background.backgroundOverlay) {
            this.overlay = this.addElement("overlay", () => this.background.backgroundOverlay);
        }

        if (this.showCallouts) {
            if (!this.model.elements.callouts) {
                this.model.elements.callouts = _.cloneDeep(this.model.elements.annotations ?? { elements: [] });
            }
            this.callouts = this.addElement("callouts", () => CalloutsCanvas, {
                model: this.model.elements.callouts,
                canSelect: true,
                selectAllDisabled: true,
                allowSelectionContextMenu: false,
                disableAllAnimationsByDefault: false,
                allowContextMenu: false
            });
        }

        if (this.model.elements.videoOverlay && (!this.canvas.isPlayback || this.model.elements.videoOverlay.videoAssetId)) {
            this.videoOverlay = this.addElement("videoOverlay", () => VideoOverlay, {
                model: this.model.elements.videoOverlay
            });
        } else {
            this.videoOverlay = null;
        }
    }

    _calcProps(props, options) {
        const { size } = props;

        const canvasBounds = new geom.Rect(0, 0, size);
        let contentBounds = new geom.Rect(0, 0, size);

        const footerHeight = this.canvas.styleSheet.Footer.height;

        this.background.calcProps(canvasBounds.size);

        // let trayProps;
        if (this.trayLayout != TrayType.NONE) {
            contentBounds = this.calcTray(props, contentBounds, canvasBounds);
        }
        // layout.children.tray = trayProps;
        let bodyBounds = contentBounds.clone();

        let canvasMargin = this.primary.getCanvasMargins();

        // // remove element minimum heights from availableSecondaryHeight to see if they'll fit
        let availableSecondaryHeight = bodyBounds.height - this.elements.primary.minHeight;
        if (this.showAttribution && this.elementTextBlockPosition != ElementTextBlockPositionType.TRAY) {
            canvasMargin.bottom = 20;
            availableSecondaryHeight -= this.elements.elementAttribution.minHeight;
        }
        if (this.elementTextBlockPosition !== ElementTextBlockPositionType.NONE) {
            availableSecondaryHeight -= this.elements.elementTextBlock.minHeight;
        }

        if (this.elementTextBlockPosition == ElementTextBlockPositionType.TRAY) {
            bodyBounds = this.calcTextBlock(props, contentBounds, bodyBounds, availableSecondaryHeight);
        }

        bodyBounds = this.calcHeader(props, contentBounds, bodyBounds, canvasMargin, canvasBounds);

        if (this.showFooter) {
            bodyBounds = this.calcFooter(props, bodyBounds, footerHeight, canvasBounds);
        }

        let primaryBounds = bodyBounds.deflate(canvasMargin);

        if (availableSecondaryHeight < 0) {
            logger.warn("[CanvasElement] not enough space for secondary items", { availableSecondaryHeight, slideId: this.canvas.slide.id });
        }

        // if everything will fit - reset availableSecondaryHeight
        availableSecondaryHeight = primaryBounds.height - this.elements.primary.minHeight;

        // layout attribution
        if (this.showAttribution) {
            ({
                primaryBounds,
                availableSecondaryHeight
            } = this.calcAttribution(props, primaryBounds, canvasBounds, availableSecondaryHeight));
        }

        if (this.elementTextBlockPosition == ElementTextBlockPositionType.INLINE) {
            primaryBounds = this.calcTextBlock(props, contentBounds, primaryBounds, availableSecondaryHeight);
        }

        if (this.trayLayout != TrayType.NONE) {
            primaryBounds = this.calcInlineTray(props, primaryBounds, contentBounds);
        }

        primaryBounds.top = Math.round(primaryBounds.top);
        primaryBounds.height = Math.round(primaryBounds.height);

        if (this.fullBleedPrimaryElement) {
            primaryBounds = canvasBounds.clone();
        }

        let primaryProps = this.primary.calcProps(primaryBounds.size);
        primaryProps.bounds = primaryBounds;

        // let annotationsProps = this.annotations.calcProps(canvasBounds.size);
        // annotationsProps.bounds = canvasBounds;

        if (this.overlay) {
            let overlayProps = this.overlay.calcProps(canvasBounds.size);
            overlayProps.bounds = canvasBounds;
        }

        if (this.showCallouts) {
            let calloutsProps = this.callouts.calcProps(size);
            calloutsProps.bounds = new geom.Rect(0, 0, size);
        }

        if (this.videoOverlay) {
            const videoOverlayProps = this.videoOverlay.calcProps(size);
            videoOverlayProps.bounds = new geom.Rect((this.model.elements.videoOverlay.x ?? 0) * size.width, (this.model.elements.videoOverlay.y ?? 0) * size.height, videoOverlayProps.size);
        }

        // Assigning correct layers to the children
        Object.entries(this.elements).forEach(([id, element]) => {
            element.calculatedProps.layer = this.childrenLayers[id];
        });

        return { size };
    }

    calcHeader(props, contentBounds, bodyBounds, canvasMargin, canvasBounds) {
        let headerProps;
        switch (this.headerPosition) {
            case HeaderPositionType.TOP:
                // calculate how much space the header is allowed to take based on certain layout parameters:
                let maxHeaderHeight = canvasBounds.height - this.elements.primary.minHeight;
                if (this.showFooter) {
                    maxHeaderHeight -= this.canvas.styleSheet.Footer.spacing;
                }
                if (this.elementTextBlockPosition !== ElementTextBlockPositionType.NONE) {
                    maxHeaderHeight -= this.elements.elementTextBlock.minHeight;
                }
                if (this.headerPosition === HeaderPositionType.TOP) {
                    canvasMargin.top = 0;
                }
                maxHeaderHeight -= canvasMargin.top + canvasMargin.bottom;

                headerProps = this.header.calcProps(new geom.Size(contentBounds.width, maxHeaderHeight), {});
                headerProps.bounds = new geom.Rect(contentBounds.left, contentBounds.top, contentBounds.width, headerProps.size.height);

                bodyBounds = bodyBounds.deflate({ top: headerProps.size.height });
                break;
            case HeaderPositionType.LEFT:
                let headerHeight = contentBounds.height;
                if (this.elementTextBlockPosition === ElementTextBlockPositionType.TRAY) {
                    // headerHeight -= this.elements.elementTextBlock.minHeight;
                    headerHeight -= this.elements.elementTextBlock.bounds.height;
                }

                headerProps = this.header.calcProps(new geom.Size(this.headerWidth, headerHeight), {});
                headerProps.bounds = new geom.Rect(contentBounds.left, headerHeight / 2 - headerProps.size.height / 2, headerProps.size);

                bodyBounds = bodyBounds.deflate({ left: headerProps.size.width });
                break;
        }
        return bodyBounds;
    }

    calcFooter(props, bodyBounds, footerHeight, canvasBounds) {
        const footerBounds = new geom.Rect(0, canvasBounds.height - footerHeight, canvasBounds.width, footerHeight);

        const footerProps = this.footer.calcProps(footerBounds.size);
        footerProps.bounds = footerBounds;

        if (this.elements.primary.reserveFooterSpace) {
            bodyBounds = bodyBounds.deflate({ bottom: this.canvas.styleSheet.Footer.spacing });
        } else {
            //TODO
            // // this is a bit hacky to make sure the footer is over the primary element here
            // // we need to set the layer for export support but because we aren't sorting root elements by layer
            // // we also explicitly move the footer svg to front...
            // this.elements.footer.layer = 100;
            // this.elements.footer.svg.front();
        }

        return bodyBounds;
    }

    calcTray(props, contentBounds, canvasBounds) {
        let border = 0;
        if (this.canvas.getTheme().get("styleBackground") == "border") {
            border = this.canvas.styleSheet.$decorationStrokeWidth * 2;
        }

        let trayProps;
        switch (this.trayLayout) {
            case TrayType.NONE:
                break;
            case TrayType.LEFT_TRAY:
                trayProps = this.tray.calcProps(contentBounds.deflate(border).size);
                trayProps.bounds = new geom.Rect(border, border, trayProps.size);
                contentBounds = new geom.Rect(trayProps.size.width + this.canvas.styleSheet.TrayContainer.sideTrayEdgeMargin, 0, canvasBounds.width - trayProps.size.width - this.canvas.styleSheet.TrayContainer.sideTrayEdgeMargin, canvasBounds.height);
                break;
            case TrayType.BACKGROUND:
                trayProps = this.tray.calcProps(contentBounds.deflate(border).size);
                trayProps.bounds = new geom.Rect(border, border, trayProps.size);
                break;
            case TrayType.RIGHT_TRAY:
                // default:
                trayProps = this.tray.calcProps(contentBounds.deflate(border).size);
                trayProps.bounds = new geom.Rect(canvasBounds.width - trayProps.size.width - border, border, trayProps.size);
                contentBounds = new geom.Rect(0, 0, canvasBounds.width - trayProps.size.width - this.canvas.styleSheet.TrayContainer.sideTrayEdgeMargin, canvasBounds.height);
                break;
        }
        return contentBounds;
    }

    calcTextBlock(props, contentBounds, primaryBounds, availableSecondaryHeight) {
        let elementTextBlockProps;
        switch (this.elementTextBlockPosition) {
            case ElementTextBlockPositionType.TRAY:
                elementTextBlockProps = this.elementTextBlock.calcProps(new geom.Size(contentBounds.width, Math.max(availableSecondaryHeight, this.elements.elementTextBlock.minHeight)));
                elementTextBlockProps.bounds = new geom.Rect(contentBounds.left, contentBounds.bottom - elementTextBlockProps.size.height, contentBounds.width, elementTextBlockProps.size.height);
                primaryBounds = primaryBounds.deflate({ bottom: elementTextBlockProps.size.height });
                break;
            case ElementTextBlockPositionType.INLINE:
                if (this.headerPosition == HeaderPositionType.LEFT && this.header.hasBackground) {
                    this.elementTextBlock.styles.marginLeft = this.elementTextBlock.styles.marginRight = 0;
                    elementTextBlockProps = this.elementTextBlock.calcProps(new geom.Size(primaryBounds.width, Math.max(availableSecondaryHeight, this.elements.elementTextBlock.minHeight)));
                    elementTextBlockProps.bounds = new geom.Rect(primaryBounds.left, primaryBounds.bottom - elementTextBlockProps.size.height, primaryBounds.width, elementTextBlockProps.size.height);
                } else {
                    elementTextBlockProps = this.elementTextBlock.calcProps(new geom.Size(contentBounds.width, Math.max(availableSecondaryHeight, this.elements.elementTextBlock.minHeight)));
                    elementTextBlockProps.bounds = new geom.Rect(contentBounds.left, primaryBounds.bottom - elementTextBlockProps.size.height, contentBounds.width, elementTextBlockProps.size.height);
                }
                primaryBounds = primaryBounds.deflate({ bottom: elementTextBlockProps.size.height });
                break;
        }

        return primaryBounds;
    }

    calcAttribution(props, primaryBounds, canvasBounds, availableSecondaryHeight) {
        if (this.showAttribution) {
            // Ensure that the side margin is always 50.
            const canvasMargin = this.primary.getCanvasMargins();

            let attributionBounds;

            if (this.elementTextBlockPosition == ElementTextBlockPositionType.TRAY) {
                attributionBounds = canvasBounds.deflate(20);
            } else {
                attributionBounds = primaryBounds.deflate({
                    left: Math.max(50 - canvasMargin.left, 0),
                    right: Math.max(50 - canvasMargin.right, 0),
                });
            }

            let attributionWidth = attributionBounds.width;
            if (this.canvas.showBrandingWatermark()) {
                attributionWidth -= 140;
            }

            let attributionProps = this.elementAttribution.calcProps(new geom.Size(attributionWidth, Math.max(availableSecondaryHeight, this.elements.elementAttribution.minHeight)));
            attributionProps.bounds = new geom.Rect(attributionBounds.left, attributionBounds.bottom - attributionProps.size.height, attributionWidth, attributionProps.size.height);

            if (this.elementTextBlockPosition != ElementTextBlockPositionType.TRAY) {
                primaryBounds = primaryBounds.deflate({ bottom: attributionProps.size.height });
                availableSecondaryHeight -= attributionProps.size.height;
            }
        }

        return {
            primaryBounds,
            availableSecondaryHeight,
        };
    }

    calcInlineTray(props, primaryBounds, contentBounds) {
        let trayProps;

        switch (this.trayLayout) {
            case TrayType.LEFT_INLINE:
                trayProps = this.tray.calcProps(new geom.Size(contentBounds.width, primaryBounds.height));
                trayProps.bounds = new geom.Rect(primaryBounds.left, primaryBounds.top, trayProps.size.width, primaryBounds.height);
                primaryBounds = primaryBounds.deflate({ left: trayProps.size.width + this.canvas.styleSheet.TrayContainer.inlineTrayEdgeMargin });
                break;
            case TrayType.RIGHT_INLINE:
                trayProps = this.tray.calcProps(new geom.Size(contentBounds.width, primaryBounds.height));
                trayProps.bounds = new geom.Rect(primaryBounds.right - trayProps.size.width, primaryBounds.top, trayProps.size.width, primaryBounds.height);
                primaryBounds = primaryBounds.deflate({ right: trayProps.size.width + this.canvas.styleSheet.TrayContainer.inlineTrayEdgeMargin });
                break;
        }
        return primaryBounds;
    }

    _migrate_10_01() {
        if (this.template.elementType === "AuthoringCanvas") {
            this.model.layout.headerPosition = "none";
            this.model.layout.showFooter = "off";
        }
    }

    _migrate_10_02() {
        if (this.template.elementType === "AuthoringCanvas") {
            if (!this.model.layout.headerPosition) {
                this.model.layout.headerPosition = "none";
            }
            if (!this.model.layout.showFooter) {
                this.model.layout.showFooter = "off";
            }
        }
    }
}

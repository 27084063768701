import { TrayType } from "common/constants";
import * as geom from "js/core/utilities/geom";
import { SideBarControlBar, SideBarPropertyPanel, SideBarSelection } from "../../Editor/ElementPropertyPanels/GridContainer/SideBarUI";
import { GridLayoutContainer, LayoutEngineType } from "../elements/LayoutContainers/GridLayoutContainer";

class SideBarContainer extends GridLayoutContainer {
    static get schema() {
        return {
            layoutEngineType: LayoutEngineType.SIDEBAR,
            trayWidth: 440,
            items: [{}]
        };
    }

    getElementPropertyPanel() {
        return SideBarPropertyPanel;
    }

    getElementControlBar() {
        return SideBarControlBar;
    }

    getElementSelection() {
        return SideBarSelection;
    }

    get name() {
        return "SideBar";
    }

    get minItemCount() {
        return 0;
    }

    get _canSelect() {
        return true;
    }

    get _canRollover() {
        return true;
    }

    get trayLayout() {
        let trayLayout = this.getCanvasElement().trayLayout;
        if (trayLayout === TrayType.BOTTOM_TRAY || trayLayout === TrayType.TOP_TRAY) {
            trayLayout = TrayType.RIGHT_TRAY;
            // TODO?
            // this.canvas.model.layout.trayLayout = TrayType.RIGHT_TRAY;
        }
        return trayLayout;
    }

    get trayWidth() {
        return this.model.trayWidth ?? 440;
    }

    get minTrayWidth() {
        return 50;
    }

    get maxTrayWidth() {
        return 640;
    }

    get fullBleed() {
        return this.isTray;
    }

    get isTray() {
        return this.trayLayout.equalsAnyOf(TrayType.LEFT_TRAY, TrayType.RIGHT_TRAY);
    }

    get isInline() {
        return this.trayLayout.equalsAnyOf(TrayType.LEFT_INLINE, TrayType.RIGHT_INLINE);
    }

    get trayPosition() {
        return this.trayLayout.equalsAnyOf(TrayType.LEFT_TRAY, TrayType.LEFT_INLINE) ? "left" : "right";
    }

    get selectionPadding() {
        return 0;
    }

    get defaultItemData() {
        return {
            componentType: "TextAndImage",
            childElement: {}
        };
    }

    get layoutEngineType() {
        return this.model.layoutEngineType ?? LayoutEngineType.SIDEBAR;
    }

    deleteItem(itemId) {
        super.deleteItem(itemId);
        if (this.itemCount == 0) {
            this.canvas.model.layout.trayLayout = TrayType.NONE;
            this.canvas.model.elements.tray = null;
        }
    }

    _calcProps(props, options) {
        let { size } = props;

        let availableSize = new geom.Size(this.trayWidth, size.height);

        let calculatedProps = super._calcProps({ size: availableSize }, options);

        return { ...calculatedProps, size: availableSize };
    }

    get disableAnimationsByDefault() {
        return true;
    }

    _exportToSharedModel() {
        return { sidebar: this.model };
    }

    _importFromSharedModel(model) {
        return model.sidebar;
    }
}

export { SideBarContainer };

export const elements = {
    TrayContainer: SideBarContainer
};

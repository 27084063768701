import React from "react";

import { Slider } from "../../../../Components/Slider";
import { ShowDialog, ShowInputDialog } from "../../../../react/components/Dialogs/BaseDialog";

import { Icon } from "../../../../Components/Icon";
import { MenuItem } from "../../../../Components/Menu";
import { Popup, PopupContent } from "../../../../Components/Popup";
import { FilterDialog } from "../../../../react/components/Dialogs/MediaDialog";
import { MediaPopup } from "../EditorComponents/MediaPopup";
import { ControlBar, ControlBarButton } from "./Components/ControlBar";

export class PictureElementControlBar extends React.Component {
    get suppressPrimaryControlBar() {
        return true;
    }

    handleSetElementScale = (adjustedScale, minScale, maxScale, save) => {
        const { element } = this.props;

        if (this.isHandlingAction) {
            return;
        }
        this.isHandlingAction = true;

        const scale = minScale + (maxScale - minScale) * adjustedScale / 100;
        element._setModel({ scale }, this.elementSize);

        element.canvas.refreshCanvas()
            .then(() => this.isHandlingAction = false)
            .then(() => {
                if (save) {
                    return element.canvas.saveCanvasModel();
                }
            });
    }

    handleResetImageTransform = () => {
        const { element } = this.props;

        element.resetMediaTransform();
        element.canvas.updateCanvasModel();
    }

    handleSetAltText = async () => {
        const { element } = this.props;
        let altText = await ShowInputDialog({
            title: "Set the alt text for this image",
            description: "This text will be read by screen readers and exported to PPT.",
            value: element.getAltText(),
        });
        if (altText) {
            element.updateModel({ altText: altText });
        }
    }

    render() {
        const { element } = this.props;

        const hasMedia = element.hasMedia;
        if (!hasMedia) {
            return null;
        }

        this.elementSize = element.calculatedProps?.size ?? this.elementSize;

        const scale = element.getScale(this.elementSize);
        const minScale = element.minScale(this.elementSize);
        const maxScale = element.maxScale(this.elementSize);

        const adjustedScaleValue = Math.round((scale - minScale) / (maxScale - minScale) * 100);

        const isSmall = element.calculatedProps.size.width < 200 || element.calculatedProps.size.height < 200;

        return (
            <ControlBar top={isSmall ? undefined : "calc(100% - 32px)"}>
                <MediaPopup element={element.parentElement} canAdjust={false} />
                {element.options.allowImageScaling && (
                    <>
                        <Slider
                            min={0}
                            max={100}
                            step={1}
                            value={adjustedScaleValue}
                            onChange={scale => this.handleSetElementScale(scale, minScale, maxScale, false)}
                            onCommit={scale => this.handleSetElementScale(scale, minScale, maxScale, true)}
                        />
                        <ControlBarButton onClick={this.handleResetImageTransform} tooltip="Reset crop and zoom">
                            <Icon>settings_overscan</Icon>
                        </ControlBarButton>
                    </>
                )}
                <ControlBarButton
                    tooltip="Edit and filter image"
                    onClick={() => {
                        ShowDialog(FilterDialog, { element });
                    }}>
                    <Icon>instant_mix</Icon>
                </ControlBarButton>

                <Popup icon="more_vert">
                    <PopupContent>
                        {closePopup => (
                            <MenuItem onClick={() => {
                                this.handleSetAltText();
                                closePopup();
                            }}>Alt text...</MenuItem>
                        )}
                    </PopupContent>
                </Popup>
            </ControlBar>
        );
    }
}

function getDefaultTableData() {
    return {
        tableWidth: 1,
        tableHeight: 1,
        showTopLeftCell: true,
        showColGridLines: true,
        showRowGridLines: true,
        showHeaderRow: true,
        showHeaderCol: true,
        cols: [
            {
                index: 0,
                style: "headerCol",
                break: false,
                size: 50
            },
            {
                index: 1,
                style: "defaultCol",
                break: false,
                size: 60
            },
            {
                index: 2,
                style: "defaultCol",
                break: false,
                size: 60
            },
            {
                index: 3,
                style: "defaultCol",
                break: false,
                size: 60
            },
            {
                index: 4,
                style: "defaultCol",
                break: false,
                size: 60
            }
        ],
        rows: [
            {
                index: 0,
                style: "headerRow",
                break: false,
                size: 40
            },
            {
                index: 1,
                style: "defaultRow",
                break: false,
                size: 30
            },
            {
                index: 2,
                style: "defaultRow",
                break: false,
                size: 30
            },
            {
                index: 3,
                style: "defaultRow",
                break: false,
                size: 30
            }
        ],
        cells: [
            {
                "row": 0,
                "col": 0,
            },
            {
                "row": 0,
                "col": 1,
            },
            {
                "row": 0,
                "col": 2,
            },
            {
                "row": 0,
                "col": 3,
            },
            {
                "row": 0,
                "col": 4,
            },
            {
                "row": 1,
                "col": 0,
            },
            {
                "row": 1,
                "col": 1,
            },
            {
                "row": 1,
                "col": 2,
            },
            {
                "row": 1,
                "col": 3,
            },
            {
                "row": 1,
                "col": 4,
            },
            {
                "row": 2,
                "col": 0,
            },
            {
                "row": 2,
                "col": 1,
            },
            {
                "row": 2,
                "col": 2,
            },
            {
                "row": 2,
                "col": 3,
            },
            {
                "row": 2,
                "col": 4,
            },
            {
                "row": 3,
                "col": 0,
            },
            {
                "row": 3,
                "col": 1,
            },
            {
                "row": 3,
                "col": 2,
            },
            {
                "row": 3,
                "col": 3,
            },
            {
                "row": 3,
                "col": 4,
            }
        ]
    };
}

module.exports = {
    getDefaultTableData
};

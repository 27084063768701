import React, { Component } from "react";

import { WithLabel } from "../../../../Components/WithLabel";
import { Slider } from "../../../../Components/Slider";

import { PropertyPanelContainer, PropertySection } from "../../../../EditorComponents/PropertyPanel";
import { ImageFramePopupMenu } from "../EditorComponents/ImageFrameMenu";
import { CollectionColorPicker } from "../EditorComponents/ColorPickers/CollectionColorPicker";
import { ToggleButtons, ToggleButton } from "../../../../Components/ToggleButtons";
import { ItemColorPicker } from "../EditorComponents/ColorPickers/ItemColorPicker";
import { ControlBar } from "../ElementControlBars/Components/ControlBar";
import { MediaPopup } from "../EditorComponents/MediaPopup";
import { DecorationStylePicker } from "../EditorComponents/ColorPickers/DecorationStylePicker";
import { DecorationStyle } from "../../../../../common/constants";

export class IconsListPropertyPanel extends Component {
    render() {
        const { element } = this.props;

        let frameType = element.itemElements[0].model.frameType;
        if (!frameType || frameType == "theme") {
            frameType = element.canvas.getTheme().get("styleShape");
        }

        // let hasShapeFrame = element.itemElements.some(el => el.media?.hasShapeFrame);
        // let mediaBgColor;
        // let mediaBackgroundColors = element.itemElements.map(el => el.media?.getBackgroundColor());
        // if (!hasShapeFrame || mediaBackgroundColors.some(color => element.palette.doesBackgroundColorAllowColorOnTop(color))) {
        //     mediaBgColor = element.palette.getColor("white")
        // }

        let iconScale = element.model.iconScale ?? 1;

        return (
            <PropertyPanelContainer>
                <PropertySection>
                    <WithLabel label="Item Colors">
                        <CollectionColorPicker element={element} showDecorationStyles />
                    </WithLabel>
                </PropertySection>
                <PropertySection>
                    <WithLabel label="Item Frames">
                        <ImageFramePopupMenu frameType={frameType}
                            showPreview
                            allowedCategories={["shape", "device"]}
                            onChange={frameType => element.updateChildModels({ frameType }, { refreshStyles: true })}
                        />
                    </WithLabel>
                </PropertySection>
                <PropertySection>
                    <WithLabel label="Scale">
                        <Slider value={element.model.iconScale ?? 1}
                            onChange={async value => {
                                try {
                                    await element.refreshModel({ iconScale: value });
                                    iconScale = value;
                                } catch (e) {
                                    element.refreshModel({ iconScale });
                                }
                            }}
                            onCommit={() => element.saveModel()}
                            min={0.5} max={2} step={0.01}
                            marks={[{ value: 1 }]}
                            showInput percent
                        />
                    </WithLabel>
                </PropertySection>

                <PropertySection>
                    <WithLabel left above label="Orientation">
                        <ToggleButtons
                            value={element.model.orientation ?? "auto"}
                            onChange={orientation => element.updateModel({ orientation })}
                        >
                            <ToggleButton value="auto">Auto</ToggleButton>
                            <ToggleButton value="vertical">Vertical</ToggleButton>
                            <ToggleButton value="horizontal">Horizontal</ToggleButton>
                        </ToggleButtons>
                    </WithLabel>
                </PropertySection>

            </PropertyPanelContainer>
        );
    }
}

export class IconsListItemControlBar extends Component {
    render() {
        const { element } = this.props;

        return (
            <ControlBar>
                <MediaPopup element={element.media.content} />
                <ItemColorPicker element={element} showDecorationStyles />
            </ControlBar>
        );
    }
}

import { _ } from "js/vendor";
import { NodeType } from "common/constants";
import * as geom from "js/core/utilities/geom";

import { CollectionElement } from "../base/CollectionElement";
import { getContentItemFromType } from "../base/ContentItem";
import ConnectorGroup from "./connectors/ConnectorGroup";
import { VideoOverlay } from "../VideoOverlay/VideoOverlay";
import { isRenderer } from "js/config";
import { ContentItemSelection } from "../../Editor/ElementSelections/ContentItemSelection";

export class AnnotationLayer extends CollectionElement {
    get minItemCount() {
        return 0;
    }

    get allowDragDropElements() {
        return false;
    }

    get _canSelect() {
        return false;
    }

    getElementControlBar() {
        return null;
    }

    getElementSelection() {
        return null;
    }

    getChildItemType(model) {
        return getContentItemFromType(model.nodeType || NodeType.BOX);
    }

    getAllowedNodeTypes() {
        return [
            NodeType.TEXT,
            NodeType.BOX,
            NodeType.CAPSULE,
            NodeType.CIRCLE,
            NodeType.BULLET_TEXT,
            NodeType.CONTENT_AND_TEXT,
            NodeType.NUMBERED_TEXT,
            NodeType.CONTENT,
            NodeType.LINE
        ];
    }

    get canEditNumberedTextContentItemMarkerValue() {
        return true;
    }

    getChildOptions(model) {
        return {
            allowConnection: false,
            syncFontSizeWithSiblings: true,
            elementSelection: ContentItemSelection,
            allowColorOnColor: true,
            textFormatBarOffset: model.textFormatBarOffset
        };
    }

    refreshElement(transition) {
        this.canvas.refreshElement(this, transition, true);
    }

    get constrainNodesToBounds() {
        return false;
    }

    get canRefreshElement() {
        return true;
    }

    getConnectorGroupOptions() {
        return {};
    }

    resetUserColors() {
        return; // don't reset user colors on annnotations
    }

    _build() {
        super._build();

        if (!this.model.connections) {
            this.model.connections = {
                items: []
            };
        }

        this.connectors = this.addElement("connectors", () => ConnectorGroup, {
            model: this.model.connections,
            containerElement: this,
            startPointsAreLocked: true,
            ...this.getConnectorGroupOptions()
        });
        this.connectors.layer = -1;

        if (this.model.videoOverlay && (!this.canvas.isPlayback || this.model.videoOverlay.videoAssetId)) {
            this.videoOverlay = this.addElement("videoOverlay", () => VideoOverlay, {
                model: this.model.videoOverlay
            });
        } else {
            this.videoOverlay = null;
        }
    }

    _calcProps(props, options) {
        const { size } = props;

        let shouldUpdateModel = false;
        const containerBounds = new geom.Rect(0, 0, size);
        for (const item of this.itemElements) {
            const itemProps = item.calcProps(size);
            if (item.model.snapOptions) {
                const sourceElement = this.canvas.getElementByUniquePath(item.model.snapOptions.elementId);
                const snapPoint = sourceElement.getSnapPoint(item);
                itemProps.bounds = new geom.Rect(snapPoint.x, snapPoint.y, itemProps.size);
            } else {
                itemProps.bounds = new geom.Rect(size.width * item.model.x, size.height * item.model.y, itemProps.size);
            }

            // adjustedItemBounds will respect the node's registration point
            const adjustedItemBounds = item.bounds;
            // Fitting the node
            if (this.constrainNodesToBounds && !containerBounds.contains(adjustedItemBounds)) {
                const offset = adjustedItemBounds.fitInRect(containerBounds).position.minus(adjustedItemBounds.position);
                itemProps.bounds = itemProps.bounds.offset(offset);
                item.model.x += offset.x / size.width;
                item.model.y += offset.y / size.height;
                // If the item is being dragged, then its model will be updated
                // when dragging is finished
                if (!item.isDragging && !item.isDragResizing) {
                    shouldUpdateModel = true;
                }
            }
        }

        if (shouldUpdateModel) {
            this.canvas.layouter.runPostRender(() => this.canvas.saveCanvasModel());
        }

        const connectorProps = this.connectors.calcProps(size);
        connectorProps.bounds = new geom.Rect(0, 0, size);

        if (this.videoOverlay) {
            const videoOverlayProps = this.videoOverlay.calcProps(size);
            videoOverlayProps.bounds = new geom.Rect((this.model.videoOverlay.x ?? 0) * size.width, (this.model.videoOverlay.y ?? 0) * size.height, videoOverlayProps.size);
        }

        return { size };
    }

    _postCalcProps() {
        // if (this.model.runPostCalcProps) {
        //     this.model.shouldMigrateToCallouts = true;
        //     delete this.model.runPostCalcProps;
        //     this.canvas.layouter.runPostRender(() => this.canvas.updateCanvasModel());
        // }
    }

    deleteItem(itemId) {
        // delete any connectors that referenced the deleted item
        _.remove(this.model.connections.items, connector => connector.source == itemId);
        super.deleteItem(itemId);
    }

    async pasteFromClipboard({ elementType, model }) {
        const modelToPaste = _.cloneDeep(model);
        delete modelToPaste.id;

        let newItem;
        if (elementType === "ConnectorItem") {
            // Offset in a perpendicular direction to ensure
            //   the new item does not overlap the old item
            let offset = new geom.Point(
                modelToPaste.targetPoint.x,
                modelToPaste.targetPoint.y,
            )
                .minus(
                    modelToPaste.sourcePoint.x,
                    modelToPaste.sourcePoint.y,
                )
                .normalize() // direction vector from source to target
                .rotate90CW()
                .scale(10); // offset 10 units

            modelToPaste.sourcePoint.x += offset.x;
            modelToPaste.sourcePoint.y += offset.y;
            modelToPaste.targetPoint.x += offset.x;
            modelToPaste.targetPoint.y += offset.y;

            newItem = this.connectors.addItem(modelToPaste);
        } else {
            while (this.itemElements.filter(item => item.model.x === modelToPaste.x && item.model.y === modelToPaste.y).length > 0) {
                modelToPaste.x += 0.05;
                modelToPaste.y += 0.05;
            }

            newItem = this.addItem(modelToPaste);
        }

        await this.canvas.updateCanvasModel(false);
        return this.getChild(newItem.id);
    }

    getAnimations() {
        const animations = [];

        const connectorsWithNode = [];
        const sortedItemIds = this.itemElements.map(({ id, itemIndex }) => ({ id, itemIndex })).sort((a, b) => a.itemIndex - b.itemIndex).map(({ id }) => id);
        sortedItemIds.map(id => this.elements[id]).forEach(node => {
            const nodeAnimations = node.getAnimations();
            animations.push(...nodeAnimations);

            const nodeName = node.animationElementName;
            node.connectorsFromNode.forEach(connector => {
                connectorsWithNode.push(connector);

                const connectorName = `← ${nodeName}`;
                const connectorAnimations = connector.getAnimations();
                connectorAnimations.forEach(animation => animation.elementName = connectorName);
                animations.push(...connectorAnimations);
            });
        });

        this.connectors.itemElements
            .filter(connector => !connectorsWithNode.includes(connector))
            .forEach((connector, idx) => {
                const connectorAnimations = connector.getAnimations();
                connectorAnimations.forEach(animation => animation.elementName = `Line #${idx + 1}`);
                animations.push(...connectorAnimations);
            });

        return animations;
    }

    _exportToSharedModel() {
        return { annotations: this.model };
    }

    _importFromSharedModel(model) {
        return model.annotations;
    }

    _migrate_10_01() {
        if (isRenderer || this.canvas.isPlayback) {
            return;
        }

        this.model.shouldMigrateToCallouts = false;
        this.model.runPostCalcProps = true;
    }
}

export const elements = {
    AnnotationLayer
};

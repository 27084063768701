import { Button, DialogContent } from "@material-ui/core";
import { RewriteTextTask } from "common/aiConstants";
import { generateText } from "js/core/utilities/generateText";
import { delay } from "js/core/utilities/promiseHelper";
import { AutoHeighteningTextbox } from "js/react/components/AutoHeighteningTextbox";
import BeautifulDialog from "js/react/components/Dialogs/BeautifulDialog";
import { FlexSpacer, Gap20 } from "js/react/components/Gap";
import { FlexBox } from "js/react/components/LayoutGrid";
import Spinner from "js/react/components/Spinner";
import { TagList } from "js/react/components/TagList2";
import React, { Component } from "react";
import styled from "styled-components";

const CustomDialog = styled(BeautifulDialog)`
&&& {
    cursor: default;
}
`;

const Title = styled.div`
& {
    font-size: 23px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0.5px;
    color: #222222;
}
`;

const Subtitle = styled.div`
& {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.5px;
    color: #666666;
}
`;

const Content = styled(DialogContent)`
&&& {
    padding: 24px;
}
`;

const Suggestion = styled.div`
& {
    width: 100%;
    border-radius: 3px;
    margin-bottom: 10px;

    background: #F7FAFC;

    cursor: pointer;

    &:last-of-type {
        margin-bottom: 0;
    }
}
`;

const SuggestionText = styled.div`
& {
    font-family: Source Sans Pro;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0px;
    padding: 10px;
    padding-bottom: 0;
}
`;

const ResultHeader = styled.div`
& {
    font-family: Source Sans Pro;
    font-size: 10px;
    font-weight: 600;
    line-height: 15px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #999;
}
`;

const ErrorMessage = styled(FlexBox)`
& {
    font-family: Source Sans Pro;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0px;
    color: #FF4500;
    text-align: center;

    padding: 10px;
    border-radius: 3px;
    background: #F7FAFC;

    &>img {
        height: 80px;
    }
}
`;

const SpinnerContainer = styled.div`
& {
    position: relative;
    height: 200px;
}
`;

const IconEmoji = styled.span`
& {
    font-size: 16px;
}
`;

const tags = [
    {
        icon: <IconEmoji>👔</IconEmoji>,
        label: "Professional",
        value: "professional",
    },
    {
        icon: <IconEmoji>🎩</IconEmoji>,
        label: "Formal",
        value: "formal",
    },
    {
        icon: <IconEmoji>😃</IconEmoji>,
        label: "Friendly",
        value: "friendly",
    },
    {
        icon: <IconEmoji>😎</IconEmoji>,
        label: "Casual",
        value: "casual",
    },
    {
        icon: <IconEmoji>🙃</IconEmoji>,
        label: "Playful",
        value: "playful",
    },
    {
        icon: <IconEmoji>🍿</IconEmoji>,
        label: "Engaging",
        value: "engaging",
    },
    {
        icon: <IconEmoji>🎉</IconEmoji>,
        label: "Enthusiastic",
        value: "enthusiastic",
    },
    {
        icon: <IconEmoji>☝️</IconEmoji>,
        label: "Assertive",
        value: "assertive",
    },
    {
        icon: <IconEmoji>🎨</IconEmoji>,
        label: "Vivid",
        value: "vivid",
    },
    {
        icon: <IconEmoji>🍞</IconEmoji>,
        label: "Simple",
        value: "simple",
    },
];

export class GenerateTextDialog extends Component {
    state = {
        results: [],
        isGenerating: false,
        error: null,
        fullPrompt: "",
    };

    componentDidMount = async () => {
        const {
            initialPrompt,
            isCustomPrompt,
            isRecentlyUsed,
            shorten,
            lengthen,
        } = this.props;

        if (
            initialPrompt ||
            shorten ||
            lengthen
        ) {
            await delay(0);
            this.setState({ fullPrompt: initialPrompt });
            this.generateText({
                prompt: initialPrompt,
                isCustomPrompt,
                isRecentlyUsed,
            });
        }
    }

    async generateText({
        prompt = "",
        isCustomPrompt = false,
        isRecentlyUsed = false,
    }) {
        const {
            task,
            selectedText,
            textboxText,
            allText,
            textStyle,
            variationCount = 3,
            shorten = false,
            lengthen = false,
        } = this.props;

        await generateText({
            task,
            prompt,
            selectedText,
            textboxText,
            allText,
            textStyle,
            variationCount,
            isCustomPrompt,
            isRecentlyUsed,
            shorten,
            lengthen,
            onReportState: state => this.setState(state),
        });
    }

    applyResult = result => {
        const {
            onApply,
            closeDialog,
        } = this.props;
        closeDialog();
        onApply(result);
    }

    togglePrompt = ({ prompt }) => {
        let {
            fullPrompt,
        } = this.state;

        // Remove the prompt if it's already present
        const regexPrompt = new RegExp(`\\b(${prompt})\\b`);
        if (fullPrompt.match(regexPrompt)) {
            fullPrompt = fullPrompt.replace(regexPrompt, "");
        // Otherwise, add it
        } else {
            fullPrompt += `, ${prompt}`;
        }

        fullPrompt = fullPrompt
            .trim()
            .replace(/^,/, "") // Clear out comma start of string
            .replace(/,$/, "") // Clear out comma at end of string
            .replaceAll(", ,", ",") // Clear spaced double comma
            .replaceAll(",,", ",") // Clear double comma
            .replaceAll("  ", " ") // Clear double space
            .trim();

        this.setState({ fullPrompt });
    }

    render() {
        const {
            results,
            isGenerating,
            error,
            fullPrompt,
        } = this.state;

        const {
            task,
            selectedText,
            textboxText,
            closeDialog,
        } = this.props;

        const titleText = (
            (task === RewriteTextTask.REWRITE && "Rewrite text...") ||
            (task === RewriteTextTask.GENERATE && `Generate text...`)
        );

        const subtitleText = (
            (task === RewriteTextTask.REWRITE && "Let AI suggest new ways to rephrase your text.") ||
            (task === RewriteTextTask.GENERATE && "Let AI give you a strong starting point.")
        );

        const originalText = selectedText || textboxText;

        return (
            <CustomDialog
                closeDialog={closeDialog}
                preventClose={false}
                closeButton
            >
                <Content>
                    <Title>{titleText}</Title>
                    <Subtitle>{subtitleText}</Subtitle>
                    {
                        task === RewriteTextTask.REWRITE &&
                        <>
                            <Gap20/>
                            <TagList
                                tags={tags}
                                disabled={isGenerating}
                                onTagClicked={({ value }) => this.togglePrompt({
                                    prompt: value,
                                })}
                            />
                        </>
                    }
                    <Gap20/>
                    <AutoHeighteningTextbox
                        value={fullPrompt}
                        allowSubmitWhenEmpty
                        disabled={isGenerating}
                        autoFocus
                        placeholder={
                            task === RewriteTextTask.REWRITE
                                ? "Examples: ...in Spanish, ...to include a compelling CTA, ...in the style of Shakespeare"
                                : "Examples: ...a title for this slide, ...summarizing the benefits of collaboration, ...about Shakespeare"
                        }
                        onSubmit={prompt => this.generateText({
                            prompt,
                            isCustomPrompt: true,
                        })}
                        onChange={fullPrompt => this.setState({ fullPrompt })}
                    />
                    <FlexBox>
                        <FlexSpacer />
                        <Button
                            color="primary"
                            variant="text"
                            disabled={isGenerating}
                            onClick={() => this.generateText({
                                prompt: fullPrompt,
                                isCustomPrompt: true,
                            })}
                        >{!results.length || isGenerating ? "Generate" : "Try Again"}</Button>
                    </FlexBox>

                    {
                        !!error &&
                        <ErrorMessage column>
                            <div>Oops! Something went wrong when generating your text.<br/>Please check your internet connection and try again.</div>
                        </ErrorMessage>
                    }
                    {
                        !error &&
                        isGenerating &&
                        <SpinnerContainer>
                            <Spinner />
                        </SpinnerContainer>
                    }
                    {
                        !error &&
                        !isGenerating &&
                        !!results.length &&
                        <>
                            <Gap20/>
                            {
                                results.map((result, index) => (
                                    <Suggestion
                                        key={`suggestion-${index}`}
                                        onClick={() => this.applyResult(result)}
                                    >
                                        <SuggestionText>{result.text}</SuggestionText>
                                        <FlexBox right>
                                            <Button
                                                color="primary"
                                                variant="text"
                                            >{task === RewriteTextTask.REWRITE ? "Apply" : "Add text"}</Button>
                                        </FlexBox>
                                    </Suggestion>
                                ))
                            }
                            {
                                task === RewriteTextTask.REWRITE &&
                                !!originalText &&
                                <>
                                    <ResultHeader>Original text</ResultHeader>
                                    <Suggestion
                                        onClick={() => closeDialog()}
                                    >
                                        <SuggestionText>{originalText}</SuggestionText>
                                        <FlexBox right>
                                            <Button
                                                color="primary"
                                                variant="text"
                                            >Keep my text</Button>
                                        </FlexBox>
                                    </Suggestion>
                                </>
                            }
                        </>
                    }
                </Content>
            </CustomDialog>
        );
    }
}

import { AuthoringBlockType, ListStyleType, TextStyleType } from "common/constants";

import { TextList } from "./Lists/TextList";
import { v4 as uuid } from "uuid";
import { ProductScreenshotUI } from "../../Editor/ElementPropertyPanels/ProductScreenshotUI";
import { _ } from "js/vendor";

class ProductScreenshot extends TextList {
    get name() {
        return "Product Screenshot";
    }

    getElementPropertyPanel() {
        return ProductScreenshotUI;
    }

    getElementControlBar() {
        return null;
    }

    get allowedBlockTypes() {
        return [TextStyleType.HEADING, TextStyleType.TITLE, TextStyleType.BODY, TextStyleType.BULLET_LIST, AuthoringBlockType.DIVIDER];
    }

    get autoTextWidth() {
        return false;
    }

    get showAddBlockButton() {
        return true;
    }

    getDefaultBlockModel() {
        return {
            id: uuid(),
            type: AuthoringBlockType.TEXT,
            textStyle: TextStyleType.TITLE,
            html: ""
        };
    }

    _getCustomBlocksDropTargets(sourceElement, sourceBlocks) {
        return [];
    }

    _migrate_10() {
        super._migrate_10();

        const mediaItemModel = ["content_type", "content_value", "showCallout", "frame", "aoiLeft", "aoiRight", "aoiTop", "aoiBottom", "scale", "version", "frameType", "size"]
            .reduce(
                (mediaItemModel, modelKey) => {
                    delete this.model.modelKey;
                    return { ...mediaItemModel, [modelKey]: this.model[modelKey] };
                },
                { isMedia: true }
            );

        if (!mediaItemModel.size) {
            switch (this.model.items.length) {
                case 0:
                    mediaItemModel.size = 700;
                    break;
                case 1:
                    mediaItemModel.size = 700;
                    break;
                case 2:
                    mediaItemModel.size = 500;
                    break;
            }
        }

        if (this.model.items.length > 1) {
            this.model.items.splice(1, 0, mediaItemModel);
        } else {
            this.model.items.splice(0, 0, mediaItemModel);
        }
    }

    _exportToSharedModel() {
        const model = super._exportToSharedModel();
        model.textContent = this.itemElements.filter(item => !item.model.isMedia).reduce((acc, item) => ([
            ...acc, ...item.text._exportToSharedModel(true).textContent
        ]), []);
        return model;
    }

    _importFromSharedModel(model) {
        const { textContent, assets } = model;
        if (!textContent?.length && !assets?.length) return;

        const elModel = _.cloneDeep(this.canvas.slideTemplate.defaultData.primary);
        if (assets?.length) {
            elModel.items[0] = {
                ...elModel.items[0],
                assetName: assets[0].name,
                content_type: assets[0].type,
                content_value: assets[0].value,
                isMedia: true,
            };
        }
        if (textContent?.length) {
            elModel.items[1] = {
                ...elModel.items[1],
                text: {
                    blocks: textContent.map(t => ([
                        {
                            html: t.mainText.text,
                            textStyle: t.mainText.textStyle || TextStyleType.BULLET_LIST,
                            listStyle: ListStyleType.BULLET,
                            type: AuthoringBlockType.TEXT,
                        },
                        ...t.secondaryTexts.map(secondaryText => ({
                            html: secondaryText.text,
                            textStyle: secondaryText.textStyle || TextStyleType.BULLET_LIST,
                            type: AuthoringBlockType.TEXT,
                            indent: 1
                        }))
                    ])).flat()
                }
            };
        }

        return elModel;
    }
}

export const elements = {
    ProductScreenshot
};

import React, { Component } from "react";
import styled from "styled-components";
import { themeColors } from "js/react/sharedStyles";
import BeautifulDialog from "../../../components/Dialogs/BeautifulDialog.tsx";
import DialogContent from "../../../components/Dialogs/DialogContent.js";
import { Gap } from "../../../components/Gap";
import { Button, TextField } from "@material-ui/core";
import { TextFields as TextFieldsIcon } from "@material-ui/icons";
import { BlueButton } from "../../../components/UiComponents";
import { GenerateWordCloudDialog } from "./GenerateWordCloudDialog";
import { withStyles } from "@material-ui/core/styles";
import DesignerBotIcon from "../../../components/DesignerBotIcon";
import { ShowDialog } from "js/react/components/Dialogs/BaseDialog";
import { elementToWordGroups, wordGroupsToStrings, wordStringsToWordList } from "./common";

const StyledDialogContent = styled(DialogContent)`
    padding: 40px 50px;
    max-height: 600px;
`;

const StyledHeader = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const StyledHeaderTitle = styled.div`
    font-family: Source Sans Pro, sans-serif;
    font-weight: 600;
    font-size: 23px;
    line-height: 27.6px;
    letter-spacing: 0.5px;
    color: #333;
`;

const StyledHeaderInstructions = styled.div`
    font-family: Source Sans Pro, sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.5px;
    color: #666;
`;

const StyledHeaderGenerateButton = styled.div`
    text-align: right;
    background: none;
    color: #333;
    border: none;
    margin: 0;
    font-size: 15px;
`;

const StyledBody = styled.div`
    display: flex;
    flex-direction: row;
    max-height: 300px;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background: #999;
    }
`;

const StyledBodyContent = styled.div`
    display: grid;
    grid-template-columns: 32px 1fr;
    flex-direction: row;
    width: 100%;
    gap: 10px;
`;

const StyledBodyLeftColumn = styled.div`
    width: 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`;

const StyledBodyRightColumn = styled.div`
    display: flex;
    flex-direction: column; 
    flex: 1;
    height: 100%;
    gap: 10px;
    padding-right: 10px;
`;

const StyledVerticalLine = styled.div`
    width: 1px;
    background-color: #eee;
    margin:auto;
    flex: 1;
    height: 100%;
`;

const StyledSmallTextImage = withStyles({
    root: { fontSize: "18px",
        width: "100%",
        height: "18px" }
})(TextFieldsIcon);

const StyledLargeTextImage = withStyles({
    root: { fontSize: "32px",
        width: "100%",
        height: "32px" }
})(TextFieldsIcon);

const StyledTextField = styled(TextField)`
&&& {
    width: 100%;

    & .MuiOutlinedInput-multiline {
        padding: 10px;
    }

    input::placeholder {
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-weight: bolder;
    }
}
`;

const StyledFooter = styled.div`
    display: flex;
    flex-direction: row;
    min-height: 38px;
    justify-content: right;
    gap: 20px;
`;

export class EditWordCloudDialog extends Component {
    state = { wordStrings: (wordGroupsToStrings(elementToWordGroups(this.props.element))),
        results: [],
        isGenerating: false,
        error: null };

    handleCancel = ()=>{
        this.props.closeDialog();
    }

    handleSave = ()=>{
        this.props.element.wordList = wordStringsToWordList(this.state.wordStrings);
        this.props.element.shouldRegen = true;
        this.props.element.canvas.refreshCanvas();
        this.props.closeDialog();
    }

    updateWordStrings = (index, value) =>{
        this.setState(prevState => {
            return {
                wordStrings: [...prevState.wordStrings.slice(0, index), value, ...prevState.wordStrings.slice(index + 1)]
            };
        });
    }

    handleGeneratedResults = generatedWordStrings => {
        this.setState({ wordStrings: generatedWordStrings });
    }

    onGenerate = ()=>{
        ShowDialog(GenerateWordCloudDialog,
            { onSuccess: this.handleGeneratedResults });
    }

    render() {
        return (
            <>
                <BeautifulDialog
                    data-testid="editWordCloudDialog"
                    closeDialog={this.handleCancel}
                    maxWidth = { "md" }>
                    <StyledDialogContent >
                        <StyledHeader>
                            <StyledHeaderTitle>
                                Set Words
                            </StyledHeaderTitle>
                            <StyledHeaderInstructions>
                                Add words and arrange them by size, from small to large. Group words together with quotes.
                            </StyledHeaderInstructions>
                            <StyledHeaderGenerateButton>
                                <Button onClick={ this.onGenerate }>
                                    <DesignerBotIcon style={{ fill: themeColors.aiColor }} />
                                    Generate with AI</Button>
                            </StyledHeaderGenerateButton>
                        </StyledHeader>
                        <Gap size={20} />
                        <StyledBody>
                            <StyledBodyContent>
                                <StyledBodyLeftColumn>
                                    <StyledSmallTextImage fontSize={"small"}/>
                                    <StyledVerticalLine/>
                                    <StyledLargeTextImage />
                                </StyledBodyLeftColumn>
                                <StyledBodyRightColumn>
                                    {this.state.wordStrings.map((words, index) =>
                                        (<StyledTextField variant={"outlined"}
                                            value={words}
                                            key={index}
                                            onChange={event=> this.updateWordStrings(index, event.target.value)}
                                            multiline
                                        />))}
                                </StyledBodyRightColumn>
                            </StyledBodyContent>

                        </StyledBody>

                        <Gap size={10} />
                        <StyledFooter>
                            <Button onClick={ this.handleCancel }>CANCEL
                            </Button>
                            <BlueButton onClick={ this.handleSave }
                                variant={"contained"}
                            >SAVE</BlueButton>

                        </StyledFooter>

                    </StyledDialogContent>

                </BeautifulDialog>

            </>
        );
    }
}

import styled from "styled-components";
import { Button, Icon, Popover } from "@material-ui/core";
import { Component, Fragment } from "react";
import { ArrowDropDown } from "@material-ui/icons";
import React from "reactn";
import { ds } from "../../../core/models/dataService";
import * as geom from "../../../core/utilities/geom";
import { ThemeProvider } from "@material-ui/styles";
import { getStaticUrl } from "../../../config";
import { dialogTheme } from "../../../react/materialThemeOverrides";
import { app } from "js/namespaces";
import { $ } from "js/vendor";

const $font = "Source Sans Pro";
const $newBlue = "#11a9e2";

const WIDGET_BAR_HEIGHT = 34;

export const WidgetBarContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
`;

const WidgetBarInner = styled.div`
    position: absolute;
    background: ${$newBlue};
    padding: 3px 6px;
    display: grid;
    grid-auto-flow: column;
    grid-gap: 4px;
    align-items: center;
    pointer-events: auto;
    height: ${WIDGET_BAR_HEIGHT}px;
    //color: white;
`;

export const UIButton = styled(Button)`
  color: white; 
  & .MuiButton-text {
    padding: 0px 14px; 
  }
  
  & .MuiButton-label {
    font-family: ${$font};
    font-size: 12px;
    font-weight: 600;
    white-space: nowrap;
    text-transform: uppercase;
    color: white;
    user-select: none;
    letter-spacing: 0.75px
  }
  & .MuiSvgIcon-root {
    margin-right: 7px;
    font-size: 18px;
  }
`;

export const UIDivider = styled.div`
    height: 100%;
    width: 1px;
    background: rgba(255,255,255,0.4);
    margin: 0px 0px;
`;

export const SelectionBox = styled.div`
    position: absolute;
    border: dotted 1px ${$newBlue};
    left: ${props => props.bounds.left}px;
    top: ${props => props.bounds.top}px;
    width: ${props => props.bounds.width}px;
    height: ${props => props.bounds.height}px;
`;

export let WidgetBarPosition = {
    BELOW: "below",
    CANVAS: "canvas",
    ABOVE: "above",
};

export const StyleBarInner = styled.div`
    position: absolute;
    padding: 8px 16px;
    background: white;
    display: grid;
    grid-gap: 8px;
    box-shadow: 2px 2px 8px rgba(0,0,0,.2); 
    grid-auto-flow: column;
    grid-gap: 4px;
    align-items: center;
    pointer-events: auto;
    border: solid 1px #ddd;
    color: #333;
`;

export class WidgetBar extends Component {
    constructor() {
        super();
        this.ref = React.createRef();
    }

    render() {
        let { element, selectionBounds, position = WidgetBarPosition.BELOW, offset = 0 } = this.props;

        let bounds = selectionBounds.multiply(app.currentCanvas.canvasScale);

        let widgetContainerStyles;

        switch (position) {
            case WidgetBarPosition.CANVAS:
                widgetContainerStyles = {
                    left: 0,
                    top: "calc(100% + 17px)",
                    width: "100%",
                    height: WIDGET_BAR_HEIGHT
                };
                break;
            case WidgetBarPosition.BELOW:
                widgetContainerStyles = new geom.Rect(bounds.left, bounds.bottom + offset, bounds.width, WIDGET_BAR_HEIGHT).toObject();
                break;
            case WidgetBarPosition.ABOVE:
                widgetContainerStyles = new geom.Rect(bounds.left, bounds.top - WIDGET_BAR_HEIGHT - offset, bounds.width, WIDGET_BAR_HEIGHT).toObject();
                break;
        }

        return (
            <WidgetBarContainer ref={this.ref} className={`ui-components widget-bar ${element.id}`}
                style={widgetContainerStyles} onMouseDown={event => {
                    event.preventDefault();
                    event.stopPropagation();
                }}>
                <WidgetBarInner>
                    {this.props.children}
                </WidgetBarInner>
            </WidgetBarContainer>
        );
    }
}

export class StyleBar extends Component {
    constructor() {
        super();
        this.ref = React.createRef();
    }

    render() {
        let { element, selectionBounds, position = WidgetBarPosition.BELOW, offset = 0 } = this.props;

        let widgetContainerStyles;

        switch (position) {
            case WidgetBarPosition.CANVAS:
                widgetContainerStyles = {
                    left: 0,
                    top: "calc(100% + 17px)",
                    width: "100%",
                    height: WIDGET_BAR_HEIGHT
                };
                break;
            case WidgetBarPosition.BELOW:
                widgetContainerStyles = new geom.Rect(selectionBounds.left, selectionBounds.bottom + offset, selectionBounds.width, WIDGET_BAR_HEIGHT).toObject();
                break;
            case WidgetBarPosition.ABOVE:
                widgetContainerStyles = new geom.Rect(selectionBounds.left, selectionBounds.top - WIDGET_BAR_HEIGHT - offset, selectionBounds.width, WIDGET_BAR_HEIGHT).toObject();
                break;
        }

        return (
            <ThemeProvider theme={dialogTheme}>
                <WidgetBarContainer ref={this.ref} className={`ui-components widget-bar ${element.id}`}
                    style={widgetContainerStyles} onMouseDown={event => {
                        event.preventDefault();
                        event.stopPropagation();
                    }}>
                    <StyleBarInner>
                        {this.props.children}
                    </StyleBarInner>
                </WidgetBarContainer>
            </ThemeProvider>
        );
    }
}

const UIPopupMenuLabel = styled.div`
    color: white;
    display: flex;
    align-items: center;
    label {
      margin-left: 10px;
      margin-right: 10px;
    }
    cursor: pointer;
`;

export class UIPopupMenu extends Component {
    state = {
        anchorEl: null
    }

    handleMouseDown = event => {
        event.preventDefault();
        event.stopPropagation();
    }

    handleShowPopup = event => {
        event.preventDefault();
        this.setState({
            anchorEl: event.currentTarget
        });
    }

    handleClose = () => {
        this.setState({
            anchorEl: null
        });
    }

    render() {
        let { anchorEl } = this.state;
        let { label, icon, showDropDownArrow = true, popoverDirection = "below" } = this.props;

        return (
            <Fragment>
                <UIPopupMenuLabel onMouseDown={this.handleMouseDown} onClick={this.handleShowPopup}>
                    {label && <label>{label}</label>}
                    {icon && <Icon>{icon}</Icon>}
                    {/*{icon && React.createElement(icon)}*/}
                    {showDropDownArrow && <ArrowDropDown />}
                </UIPopupMenuLabel>
                <Popover
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={this.handleClose}
                    anchorOrigin={{
                        vertical: popoverDirection == "above" ? -10 : 30,
                        horizontal: "center",
                    }}
                    transformOrigin={{
                        vertical: popoverDirection == "above" ? "bottom" : "top",
                        horizontal: "center",
                    }}
                    disableAutoFocus={true}
                    disableEnforceFocus={true}
                >
                    <div className="popover-contents ui-components">
                        {React.Children.map(this.props.children, child =>
                            React.cloneElement(child, { closePopup: this.handleClose })
                        )}
                    </div>
                </Popover>
            </Fragment>
        );
    }
}

export class BeautifulIconLoader extends Component {
    state = { loading: true }

    componentDidMount() {
        let { iconId } = this.props;
        ds.assets.getAssetById(iconId, "icon").then(icon => {
            const url = icon.get("original");
            if (url.startsWith("http")) {
                return fetch(url).then(res => {
                    return res.text();
                });
            } else {
                return Promise.resolve(url);
            }
        }).then(svgData => {
            let r = / d="(.*?)"/m;
            let pathString = r.exec(svgData)[1];
            this.setState({ path: pathString });
        });
    }

    render() {
        let { iconId, onClick } = this.props;
        let { loading, path } = this.state;

        return (
            <div className="icon" onClick={() => onClick(iconId)}>
                <svg viewBox="0 0 128 128">
                    <path d={path} />
                </svg>
            </div>
        );
    }
}

export const StyledUIMenuPopupPane = styled.div`
    padding: 0px;
`;

export class UIMenuPopupPane extends Component {
    render() {
        return (
            <ThemeProvider theme={dialogTheme}>
                <StyledUIMenuPopupPane>
                    {this.props.children}
                </StyledUIMenuPopupPane>
            </ThemeProvider>
        );
    }
}

export const InnerUIMenuThumbnailGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(${props => (props.columns || 3)}, ${props => props.size}px);
    grid-column-gap: 00px;
    grid-row-gap: 0px;
    color: #333;
    padding: 10px;
`;

const UIMenuThumbnailContainer = styled.div`
    cursor: pointer;
    //width: 100px;
    padding: 10px;
    text-align: center;
    position: relative;
    img {
        width: 100%;
        border: none;
        min-height: 25px;
    }
    label {
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
    }
`;

export class UIMenuThumbnailGrid extends Component {
    render() {
        let { closePopup, size = 100 } = this.props;
        return (
            <InnerUIMenuThumbnailGrid columns={this.props.columns} size={size}>
                {React.Children.map(this.props.children, child =>
                    React.cloneElement(child, { closePopup })
                )}
            </InnerUIMenuThumbnailGrid>
        );
    }
}

export class UIMenuThumbnail extends Component {
    render() {
        let { value, label, path, onClick, closePopup } = this.props;

        return (
            <UIMenuThumbnailContainer
                key={value}
                onClick={() => {
                    onClick && onClick(value);
                    closePopup && closePopup();
                }}
            >
                <img src={getStaticUrl(path)} />
                <label>{label}</label>
            </UIMenuThumbnailContainer>
        );
    }
}

export const LabeledGridBox = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-gap: 4px;
    align-items: center;
    pointer-events: auto;
    padding-top: 20px;
    position: relative;
    &:before {
        content: "${props => props.label}";
        position: absolute;
        top: 0px;
        left: 0px;
        font-size: 10px;
        color: #777;
        text-transform: uppercase;
    }
`;

export class UIDraggable extends Component {
    constructor(props) {
        super(props);

        this.dragRef = React.createRef();

        this.state = {
            isDragging: false
        };
    }

    handleMouseDown = event => {
        let { dragThreshold = 5 } = this.props;

        let $dragSource = $(this.dragRef.current);

        let $dragHelper = $("body").addEl($dragSource.clone());
        $dragHelper.css({
            position: "absolute",
            opacity: 0
        });

        let mouseStart = new geom.Point(event.pageX, event.pageY);
        let isDragging = false;

        $(document).on("mousemove.drag", event => {
            if (!isDragging && new geom.Point(event.pageX, event.pageY).distance(mouseStart) < dragThreshold) {
                return;
            }
            if (!isDragging) {
                this.props.onDragStart && this.props.onDragStart(event);
                isDragging = true;
            }

            $dragHelper.opacity(1);
            $dragHelper.top(event.pageY - $dragHelper.height() / 2).left(event.pageX - $dragHelper.width() / 2);
        });

        $(document).on("mouseup.drag", event => {
            $(document).off(".drag");
            $dragHelper.remove();
            if (isDragging) {
                this.props.onDragComplete && this.props.onDragComplete(event);
            }
        });
    }

    render() {
        return (
            <div onMouseDown={this.handleMouseDown}>
                <div ref={this.dragRef}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

import React, { Component } from "react";
import { PropertyPanelContainer, PropertySection } from "../../../../EditorComponents/PropertyPanel";
import { ItemColorPicker } from "../EditorComponents/ColorPickers/ItemColorPicker";
import { ControlBar } from "../ElementControlBars/Components/ControlBar";
import { CollectionColorPicker } from "../EditorComponents/ColorPickers/CollectionColorPicker";
import { WithLabel } from "../../../../Components/WithLabel";
import { ToggleSwitch } from "../../../../Components/ToggleSwitch";
import { ImageOption, ImageOptionList } from "../../../../Components/ImageOptionList";
import { StaticImage } from "../../../../Components/StaticImage";
import { Icon } from "../../../../Components/Icon";
import { ColorPicker } from "../EditorComponents/ColorPickers/ColorPicker";

export class JourneyPropertyPanel extends Component {
    render() {
        const { element } = this.props;

        return (
            <PropertyPanelContainer>
                <PropertySection>
                    <WithLabel label="Milestone Style">
                        <ImageOptionList cols={3} size={40} value={element.model.milestoneStyle ?? "no-icons"}
                            onChange={value => element.updateModel({ milestoneStyle: value })}>
                            <ImageOption value="no-icons" label="Pin">
                                <Icon>location_on</Icon>
                            </ImageOption>
                            <ImageOption value="with-icons" label="Icon">
                                <Icon>grade</Icon>
                            </ImageOption>
                            <ImageOption value="dots" label="Dot">
                                <Icon>fiber_manual_record</Icon>
                            </ImageOption>
                        </ImageOptionList>
                    </WithLabel>
                    <WithLabel label="Milestone Colors">
                        <CollectionColorPicker element={element} />
                    </WithLabel>
                </PropertySection>
                <PropertySection>
                    <WithLabel label="Show Start Marker">
                        <ToggleSwitch value={element.model.showStartMarker} onChange={value => element.updateModel({ showStartMarker: value })} />
                    </WithLabel>
                    <WithLabel label="Show End Marker">
                        <ToggleSwitch value={element.model.showEndMarker} onChange={value => element.updateModel({ showEndMarker: value })} />
                    </WithLabel>
                    {element.model.showEndMarker && (
                        <WithLabel label="End Marker Color">
                            <ColorPicker canvas={element.canvas}
                                value={element.model.endMarker.color ?? element.collectionColor ?? "theme"}
                                onChange={value => {
                                    element.updateModel({ endMarker: { ...element.model.endMarker, color: value } });
                                }}
                                showPrimary
                            />
                        </WithLabel>
                    )}
                </PropertySection>
            </PropertyPanelContainer>
        );
    }
}

export class JourneyItemControlBar extends Component {
    render() {
        const { element } = this.props;

        return (
            <ControlBar>
                <ItemColorPicker element={element} />
            </ControlBar>
        );
    }
}


import React, { Component } from "react";

import { ConnectorType } from "../../../../../../common/constants";
import { Dropdown } from "../../../../../Components/Dropdown";
import { MenuItem } from "../../../../../Components/Menu";
import { WithLabel } from "../../../../../Components/WithLabel";
import { PropertyPanelContainer, PropertySection } from "../../../../../EditorComponents/PropertyPanel";
import { PropertyPanelHeader } from "../../../../../editor/ElementEditor/PropertyPanelHeader";
import { ConnectorPropertyPanel } from "../../EditorComponents/ConnectorPropertyPanel";
import { ConnectorItemSelection } from "../../ElementPropertyPanels/ConnectorItemUI";
import { ConnectorItemLabelSelection } from "../../ElementSelections/ConnectorItemLabelSelection";
import * as geom from "../../../../../core/utilities/geom";
import * as helpers from "../../ElementSelections/ChartSelection/helpers";

export class ChangeInValuePropertyPanel extends Component {
    render() {
        const { element, canvas } = this.props;

        let dataSource;
        if (element.model.labels.length) {
            dataSource = element.model.labels[0].dataSource;
        }
        return (
            <PropertyPanelContainer>
                <PropertyPanelHeader>Change in Value</PropertyPanelHeader>
                <PropertySection>
                    <WithLabel label="Type">
                        <Dropdown value={element.model.connectorType == ConnectorType.STEP ? "change" : "growth"}
                            onChange={value => {
                                switch (value) {
                                    case "change":
                                        element.model.connectorType = ConnectorType.STEP;
                                        element.model.startDecoration = "circle";
                                        element.model.endDecoration = "circle";
                                        element.model.startAnchorOffsetY = 0;
                                        element.model.endAnchorOffsetY = 0;
                                        element.model.lineStyle = "dotted";
                                        break;
                                    case "growth":
                                        element.model.connectorType = ConnectorType.STRAIGHT;
                                        element.model.startDecoration = "none";
                                        element.model.endDecoration = "arrow";
                                        element.model.startAnchorOffsetY = -70;
                                        element.model.endAnchorOffsetY = -70;
                                        element.model.lineStyle = "solid";
                                        element.model.labels[0].offset = 0;
                                        break;
                                }

                                element.saveModel();
                            }}>
                            <MenuItem value="change">Change in Value</MenuItem>
                            <MenuItem value="growth">Growth/Trend Line</MenuItem>
                        </Dropdown>
                    </WithLabel>
                    {dataSource &&
                        <WithLabel label="Value Format">
                            <Dropdown value={dataSource.changeType ?? "percent"}
                                onChange={value => {
                                    dataSource.changeType = value;
                                    element.saveModel();
                                }}>
                                <MenuItem value="percent">Percentage</MenuItem>
                                <MenuItem value="absolute">Absolute Change</MenuItem>
                                <MenuItem divider value="multiple">Multiple</MenuItem>
                                <MenuItem divider value="cagr">CAGR</MenuItem>
                                <MenuItem value="text">Custom Text</MenuItem>
                            </Dropdown>
                        </WithLabel>
                    }
                </PropertySection>
                <ConnectorPropertyPanel canvas={canvas} element={element} canDelete />
            </PropertyPanelContainer>
        );
    }
}

export class ChangeInValueLabelSelection extends ConnectorItemLabelSelection {
    get canDelete() {
        return true;
    }

    handleDeleteElement = async () => {
        const { element } = this.props;
        let chart = element.findClosestOfType("Chart");
        chart.canvas.canvasController.selectionLayerController.setSelectedElements([chart]);
        let connector = element.findClosestOfType("ConnectorItem");
        let connectors = element.findClosestOfType("ConnectorGroup");
        connectors.deleteItem(connector.id);
        await connectors.saveModel();
    }
}

export class ChangeInValueConnectorItemSelection extends ConnectorItemSelection {
    get chart() {
        const { element } = this.props;
        return element.getParentOfType("Chart");
    }

    get plotBoxBounds() {
        const { canvas } = this.props;

        const { x, y, width, height } = this.chart.chart.plotBox;
        return new geom.Rect(x, y, width, height).spaceScale(canvas.getScale());
    }

    get plotBoxScreenBounds() {
        const { canvas } = this.props;

        const { x, y, width, height } = this.chart.chart.plotBox;
        const chartScreenBounds = this.chart.getScreenBounds();
        return new geom.Rect(x, y, width, height).spaceScale(canvas.getScale()).offset(chartScreenBounds.position);
    }

    handleConnectorEndDragStart = isEnd => {
        const { element } = this.props;

        let seriesId, pointIndex;
        if (isEnd) {
            seriesId = element.model.targetSnapOptions.seriesId;
            pointIndex = element.model.targetSnapOptions.pointIndex;
        } else {
            seriesId = element.model.sourceSnapOptions.seriesId;
            pointIndex = element.model.sourceSnapOptions.pointIndex;
        }
        const series = this.chart.chart.series.find(series => series.userOptions?.id === seriesId);
        const point = series.points[pointIndex];
        const annotations = helpers.getAnnotations(this.chart);

        this.setState({
            isDragging: true
        });

        helpers.handleDragChangeInValueAnchor({
            plotBoxScreenBounds: this.plotBoxScreenBounds,
            point,
            annotations,
            connectorModel: element.model,
            chartElement: this.chart,
            isStart: !isEnd,
            adjustVertically: false,
            onDragEnd: () => {
                this.setState({
                    isDragging: false
                });
            }
        });
    }
}

import React, { Component, useRef } from "react";
import styled from "styled-components";
import { mergeMediaElementModelDefaults } from "../../../../../common/assetUtils";
import { Button } from "../../../../Components/Button";
import { Icon } from "../../../../Components/Icon";
import { ImageOption, ImageOptionList } from "../../../../Components/ImageOptionList";
import { Popup, PopupContainer, PopupContent } from "../../../../Components/Popup";
import { ToggleSwitch } from "../../../../Components/ToggleSwitch";
import { WithLabel } from "../../../../Components/WithLabel";
import { PropertyPanelContainer, PropertySection } from "../../../../EditorComponents/PropertyPanel";
import CanvasPreview from "../../../../react/components/CanvasPreview";
import { ShowDialog } from "../../../../react/components/Dialogs/BaseDialog";
import { AddAssetsContainer } from "../../../../react/views/AddAssets/index";
import { _ } from "../../../../vendor";
import { MediaPropertyPanel } from "./MediaUI";

export class ActionTextPropertyPanel extends Component {
    handleAddMedia = () => {
        const { element } = this.props;
        ShowDialog(AddAssetsContainer, {
            callback: model => {
                if (!element.model.image) {
                    element.model.image = {};
                }
                mergeMediaElementModelDefaults(element.model.image, model);
                element.canvas.updateCanvasModel(false);
            },
        });
    }

    render() {
        const { element } = this.props;

        return (
            <PropertyPanelContainer>
                <PropertySection>
                    <Button onClick={() => element.previewAnimation()}>
                        <Icon>play_circle</Icon>
                        Preview Animation
                    </Button>
                </PropertySection>

                <PropertySection>
                    <Popup label="Select Animation Style" showArrow>
                        <PopupContent>
                            {closePopup => (
                                <PopupContainer>
                                    <ImageOptionList value={element.model.animationStyle}
                                        onChange={async value => {
                                            await element.updateModel({ animationStyle: value });
                                            closePopup();
                                            setTimeout(() => element.previewAnimation(), 300);
                                        }}
                                    >
                                        {[1, 2, 3, 4].map(animationStyle => (
                                            <ImageOption value={animationStyle} key={animationStyle}>
                                                <AnimationPreview canvas={element.canvas}
                                                    animationStyle={animationStyle} />
                                            </ImageOption>
                                        ))}
                                    </ImageOptionList>
                                </PopupContainer>
                            )}
                        </PopupContent>
                    </Popup>
                </PropertySection>

                {!element.showImage &&
                    <PropertySection>
                        <Button onClick={this.handleAddMedia}>
                            <Icon>add_a_photo</Icon>
                            Add Background Media...
                        </Button>
                    </PropertySection>
                }
                {element.showImage && element.image &&
                    <MediaPropertyPanel element={element.image} />
                }

                <PropertySection>
                    <WithLabel label="Play Outro Animation">
                        <ToggleSwitch value={element.model.playOutroAnimation}
                            onChange={value => element.updateModel({ playOutroAnimation: value })}
                        />
                    </WithLabel>
                </PropertySection>

            </PropertyPanelContainer>
        );
    }
}

const AnimationThumbnail = styled.div`
    width: 210px;
    height: ${210 * .5625}px;
    //border: solid 1px #666;
    position: relative;
`;

const CanvasShield = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
`;

function AnimationPreview({ canvas, animationStyle }) {
    let previewRef = useRef(null);

    let handlePreview = onComplete => {
        previewRef.current.slideCanvas.getPrimaryElement().previewAnimation();
    };

    let model = _.cloneDeep(canvas.dataModel.attributes);
    model.states[0].primary.animationStyle = animationStyle;

    let scale = 210 / 1280;

    return (
        <AnimationThumbnail>
            <CanvasPreview ref={previewRef}
                slideModel={model}
                scale={scale}
            />
            <CanvasShield onMouseEnter={handlePreview}
            />
        </AnimationThumbnail>
    );
}


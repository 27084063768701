import React, { Component } from "react";
import { WithLabel } from "../../../../Components/WithLabel";
import { PropertyPanelContainer, PropertySection } from "../../../../EditorComponents/PropertyPanel";
import { ToggleSwitch } from "../../../../Components/ToggleSwitch";
import { CollectionColorPicker } from "../EditorComponents/ColorPickers/CollectionColorPicker";
import { ControlBar } from "../ElementControlBars/Components/ControlBar";
import { ItemColorPicker } from "../EditorComponents/ColorPickers/ItemColorPicker";
import { EmphasizeToggle } from "../EditorComponents/EmphasizeToggle";

export class TextBoxGridPropertyPanel extends Component {
    render() {
        const { element } = this.props;

        return (
            <PropertyPanelContainer>
                <PropertySection>
                    <WithLabel label="Box Color">
                        <CollectionColorPicker element={element} showDecorationStyles />
                    </WithLabel>
                </PropertySection>
                <PropertySection>
                    {element.itemElements.length < 4 && (
                        <WithLabel label="Fit Boxes to Text">
                            <ToggleSwitch value={element.model.fitToText}
                                onChange={value => element.updateModel({ fitToText: value }, { transition: true })}
                            />
                        </WithLabel>
                    )}
                    {!(element.itemElements.length == 1 || element.itemElements.length == 4 || element.itemElements.length == 6) && (
                        <WithLabel label="Flip Orientation" flex>
                            <ToggleSwitch value={element.model.flipOrientation}
                                onChange={value => element.updateModel({ flipOrientation: value }, { transition: true })}
                            />
                        </WithLabel>
                    )}
                </PropertySection>

            </PropertyPanelContainer>
        );
    }
}

export class TextBoxGridItemControlBar extends Component {
    render() {
        const { element } = this.props;
        return (
            <ControlBar>
                <EmphasizeToggle element={element} />
                <ItemColorPicker element={element} showDecorationStyles />
            </ControlBar>
        );
    }
}

import { v4 as uuid } from "uuid";
import { _ } from "js/vendor";
import * as geom from "js/core/utilities/geom";
import { VerticalAlignType, HorizontalAlignType, AuthoringBlockType, TextStyleType } from "common/constants";
import { detectGraphData } from "js/core/services/sharedModelManager";

import { CollectionElement } from "../../base/CollectionElement";
import { OrgChartRowLabel } from "./OrgChartRowLabel";
import { HiddenOrgChartRootNode } from "./HiddenOrgChartRootNode";
import { BoxOrgChartNode } from "./BoxOrgChartNode";
import { MinimalOrgChartNode } from "./MinimalOrgChartNode";
import { PictureOrgChartNode } from "./PictureOrgChartNode";
import { LabelOrgChartNode } from "./LabelOrgChartNode";
import { OrgChartConnectors } from "./OrgChartConnectors";

// Layouters
import "./layouters/calcHorizontalTreeLayout";
import "./layouters/calcVerticalTreeLayout";
import { OrgChartPropertyPanel } from "../../../Editor/ElementPropertyPanels/OrgChartUI";

export class OrgChart extends CollectionElement {
    get name() {
        return "Org Chart";
    }

    getElementPropertyPanel() {
        return OrgChartPropertyPanel;
    }

    get minItemCount() {
        return 2;
    }

    getElementControlBar() {
        return null;
    }

    get connectorColor() {
        if (this.model.connectorColor) {
            return this.model.connectorColor;
        } else if (this.collectionColor != "colorful") {
            return this.collectionColor;
        } else {
            return "secondary";
        }
    }

    getChildItemType(itemModel) {
        if (this.layout == "table") {
            return BoxOrgChartNode;
        }

        if (itemModel.parent == null && this.hideRootNode) {
            return HiddenOrgChartRootNode;
        }

        switch (itemModel.nodeType ?? "node") {
            case "person":
            case "assistant":
            case "emphasized":
            case "node":
            case "picture-node":
                switch (this.model.defaultNodeStyle ?? "box") {
                    case "box":
                    case "circle":
                        return BoxOrgChartNode;
                    case "minimal":
                        return MinimalOrgChartNode;
                    case "photo1":
                    case "photo2":
                    case "photo3":
                        return PictureOrgChartNode;
                }

                break;
            case "placeholder":
                return BoxOrgChartNode;
            case "label":
                return LabelOrgChartNode;
        }
    }

    get maxItemCount() {
        return 40;
    }

    get supportsAddHotKey() {
        return false;
    }

    get defaultItemData() {
        return {
            title: { text: "" },
            body: { text: "" },
            style: "text-box"
        };
    }

    get nodeStyle() {
        return this.model.shape || "box";
    }

    get showConnectors() {
        return this.layout != "table";
    }

    get hideRootNode() {
        if (this.layoutDirection == "vertical" && this.layout != "stacked" && this.layout != "table") {
            return this.model.hideRootNode;
        } else {
            return false;
        }
    }

    get layout() {
        return this.model.layout || "compressed";
    }

    get layoutDirection() {
        return this.model.layoutDirection || "vertical";
    }

    get horizontalSpacing() {
        return this.model.horizontalSpacing ?? this.styles.hGap;
    }

    get verticalSpacing() {
        return this.model.verticalSpacing ?? this.styles.vGap;
    }

    get connectorStyle() {
        return "step";
    }

    get rowCount() {
        return _.maxBy(this.itemElements, node => node.rowIndex).rowIndex + 1;
    }

    get rowLabels() {
        return this.model.rowLabels || {};
    }

    get rootNode() {
        return _.find(this.itemElements, node => node.isRootNode);
    }

    _build() {
        // Removing items w/o parent
        this.itemCollection.forEach(nodeModel => {
            if (nodeModel.parent && !this.itemCollection.some(parent => parent.id === nodeModel.parent)) {
                this.itemCollection.remove(nodeModel);
            }
        });

        // Creating row labels
        this.rowLabelElements = [];
        Object.keys(this.rowLabels).forEach(key => {
            if (this.rowLabels[key]) {
                const rowLabelElement = this.addElement(`rowLabel${key}`, () => OrgChartRowLabel, {
                    model: this.rowLabels[key]
                });
                this.rowLabelElements.push(rowLabelElement);
            }
        });

        if (this.showConnectors) {
            this.connectors = this.addElement("connectors", () => OrgChartConnectors);
        }

        super.buildItems();
    }

    get dirtyProperites() {
        return ["primary.smallRows"];
    }

    _loadStyles(styles) {
        let styleNode;
        if (this.layout == "table") {
            styleNode = styles.layout.table;
        } else {
            styleNode = styles.layout.tree;
        }

        if (this.layoutDirection === "vertical") {
            styles.applyStyles(styleNode.vertical);
        } else if (this.layoutDirection === "horizontal") {
            styles.applyStyles(styleNode.horizontal);
        }
    }

    _calcProps(props, options) {
        const { size } = props;

        let availableSize = size.clone();
        if (this.rowLabelElements.length) {
            availableSize = availableSize.deflate({ left: OrgChartRowLabel.LABEL_WIDTH });
        }

        const layouter = this.getLayouter(props, this.itemElements, availableSize);

        const layoutOptions = {
            hideRootNode: this.hideRootNode,
            stackLeafNodes: false,
            compressed: false,
            isTable: false,
            direction: this.layoutDirection
        };

        switch (this.layout) {
            case "table":
                layoutOptions.isTable = true;
                break;
            case "expanded":
                break;
            case "stacked":
                layoutOptions.stackLeafNodes = true;
                break;
            case "compressed":
            default:
                layoutOptions.compressed = true;
                break;
        }

        if (this.layoutDirection == "vertical") {
            layouter.calcVerticalTreeLayout(layoutOptions);
        } else {
            layouter.calcHorizontalTreeLayout(layoutOptions);
        }

        layouter.alignHorizontally(HorizontalAlignType.CENTER);
        layouter.alignVertically(VerticalAlignType.MIDDLE);

        this.rows = layouter.rows;

        if (this.showConnectors) {
            const connectorsProps = this.connectors.calcProps(layouter.size, {
                vGap: layouter.vGap,
                hGap: layouter.hGap,
                connectorStyle: this.connectorStyle,
                hideRootNode: this.hideRootNode,
                layout: this.layout,
                layoutDirection: this.layoutDirection
            });
            connectorsProps.bounds = new geom.Rect(0, 0, layouter.size);
            connectorsProps.layer = -1;
        }

        return { size: layouter.size };
    }

    isRowSmall(rowIndex) {
        if (this.options.forceSmallNodes) {
            return true;
        }

        if (this.model.smallRows) {
            return this.model.smallRows.contains(rowIndex);
        } else {
            return false;
        }
    }

    deleteItem(itemId) {
        const deletedNode = this.getChild(itemId);

        const parent = deletedNode.parentNode;

        let moveIndex = deletedNode.nodeIndex;
        for (const childNode of deletedNode.childNodes) {
            childNode.model.parent = parent.model.id;
            childNode.model.index = moveIndex++;
        }

        super.deleteItem(itemId);

        deletedNode.siblingNodes.forEach((node, idx) => node.model.index = idx);
    }

    get disableAllAnimationsByDefault() {
        return true;
    }

    get animationElementName() {
        return "Org chart";
    }

    get animateChildren() {
        return false;
    }

    _getAnimations() {
        return [{
            name: "Fade in",
            prepare: () => this.animationState.fadeInProgress = 0,
            onBeforeAnimationFrame: progress => {
                this.animationState.fadeInProgress = progress;
            }
        }];
    }

    _migrate_10() {
        if (this.model.defaultNodeStyle == "circle") {
            this.model.defaultNodeStyle = "box";
        }
    }

    _migrate_10_02() {
        super._migrate_10_02();
        this.model.connectorColor = this.canvas.getSlideColor();
        if (this.model.connectorColor == "colorful") {
            this.model.connectorColor = "theme";
        }
    }

    _exportToSharedModel() {
        const textContent = []; const assets = [];
        const graphData = [{ nodes: [], connectors: [] }];

        for (const itemElement of this.itemElements) {
            const text = itemElement.text._exportToSharedModel().textContent[0];
            const asset = itemElement.content?._exportToSharedModel().assets[0];

            textContent.push(text);
            if (asset && asset.value) assets.push(asset);

            graphData[0].nodes.push({
                id: itemElement.model.id,
                type: itemElement.model.nodeType,
                ...(textContent?.length ? { textContent: text } : {}),
                ...(assets?.length ? { asset } : {}),
            });
            if (itemElement.model.parent) {
                graphData[0].connectors.push({
                    source: itemElement.model.parent,
                    target: itemElement.model.id,
                    type: this.connectorStyle
                });
            }
        }

        return { textContent, assets, graphData, collectionColor: this.collectionColor };
    }

    _importFromSharedModel(model) {
        const { nodes, connectors } = detectGraphData(model);
        if (!nodes?.length) return;

        const items = nodes.map(node => ({
            id: node.id || uuid(),
            ...(node.textContent ? {
                text: {
                    blocks: [
                        {
                            html: node.textContent.mainText.text,
                            textStyle: TextStyleType.TITLE,
                            type: AuthoringBlockType.TEXT,
                        },
                        ...node.textContent.secondaryTexts.map(secondaryText => ({
                            html: secondaryText.text,
                            textStyle: TextStyleType.BODY,
                            type: AuthoringBlockType.TEXT,
                        })),
                    ]
                }
            } : {}),
            ...(node.asset ? {
                content_type: node.asset.type,
                content_value: node.asset.value,
                assetProps: node.asset.props,
                assetName: node.asset.name,
            } : {})
        }));

        connectors.forEach(connector => {
            const source = _.find(items, { id: connector.source });
            const target = _.find(items, { id: connector.target });
            if (source && target) {
                target.parent = source.id;
            }
        });

        return {
            items,
            defaultNodeStyle: items.some(item => item.content_type) ? "photo1" : "box",
            collectionColor: model.collectionColor
        };
    }
}

import React from "react";
import { DialogTitle } from "@material-ui/core";
import { BeautifulDialog, DialogContent } from "js/react/components/Dialogs/BaseDialog";
import { Gap5 } from "js/react/components/Gap";
import { FlexBox } from "js/react/components/LayoutGrid";
import { LoadingBot } from "js/react/components/LoadingBot";

export class LoadingBotDialog extends React.Component {
    render() {
        const { title, subTitle, labels = [], closeDialog } = this.props;

        return (
            <BeautifulDialog
                preventClose
                closeDialog={closeDialog}
                closeButton={false}
            >
                {(title || subTitle) && (
                    <DialogTitle> {/* @ts-ignore() */}
                        <FlexBox column>
                            {title && <>
                                <span>{title}</span>
                                <Gap5 />
                            </>}
                            {subTitle && <>
                                <span style={{ fontSize: "18px", lineHeight: "18px", fontWeight: 400 }}>
                                    {subTitle}
                                </span>
                            </>}
                        </FlexBox>
                    </DialogTitle>
                )}
                <DialogContent>
                    <LoadingBot pad={50} labels={labels} />
                </DialogContent>
            </BeautifulDialog>
        );
    }
}

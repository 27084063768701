import React from "react";

import { tinycolor } from "js/vendor";
import { SVGGroup } from "js/core/utilities/svgHelpers";
import { sanitizeSvg } from "js/core/utilities/dompurify";

import { SVGElement } from "../../base/SVGElement";

export class AuthoringSvgElement extends SVGElement {
    static get schema() {
        return {
            fill: "red",
            stroke: "black",
            strokeWidth: 2
        };
    }

    get isAuthoringElement() {
        return true;
    }

    get allowDecorationStyles() {
        return false;
    }

    _calcProps(props, options) {
        const { size } = props;
        return { size };
    }

    renderSVG(props, styles, transition) {
        const { bounds } = props;
        const { fill, stroke, strokeWidth } = this.model;

        let fillColor, strokeColor;
        if (fill && fill != "none") {
            fillColor = this.canvas.getTheme().palette.getColor(fill);
        } else {
            fillColor = tinycolor("transparent");
        }

        if (stroke && stroke != "none" && strokeWidth != 0) {
            strokeColor = this.canvas.getTheme().palette.getColor(stroke);
        } else {
            strokeColor = tinycolor("transparent");
        }

        let svg = this.model.svg;
        svg = svg.replace(/style=".*?"/, `style="fill: ${fillColor.toRgbString()}; stroke: ${strokeColor.toRgbString()}; stroke-width: ${strokeWidth};"`);

        return (
            <SVGGroup key={this.id} ref={this.ref}>
                <svg
                    width={bounds.width}
                    height={bounds.height}
                    style={{
                        overflow: "visible",
                        position: "absolute",
                        left: "0",
                        top: "0"
                    }}
                    dangerouslySetInnerHTML={{ __html: sanitizeSvg(svg) }}
                />
            </SVGGroup>
        );
    }
}

import React, { Component } from "react";
import { ImageOption } from "../../../../Components/ImageOptionList";
import { StaticImage } from "../../../../Components/StaticImage";
import { NodeType } from "../../../../../common/constants";

export function getContentItemImageOptions(allowedNodeTypes) {
    let options = [];
    for (let nodeType of allowedNodeTypes) {
        switch (nodeType) {
            case NodeType.FLEX_CIRCLE:
                options.push(
                    <ImageOption label="Circle" value={NodeType.FLEX_CIRCLE} key={NodeType.FLEX_CIRCLE}>
                        <StaticImage src="/images/ui/node_types/node_circle.svg" />
                    </ImageOption>
                );
                break;
            case NodeType.TEXT:
                options.push(
                    <ImageOption label="Text" value={NodeType.TEXT} key={NodeType.TEXT}>
                        <StaticImage src="/images/ui/node_types/node_text.svg" />
                    </ImageOption>
                );
                break;
            case NodeType.BOX:
                options.push(
                    <ImageOption label="Box" value={NodeType.BOX} key={NodeType.BOX}>
                        <StaticImage src="/images/ui/node_types/node_box.svg" />
                    </ImageOption>
                );
                break;
            case NodeType.CIRCLE:
                options.push(
                    <ImageOption label="Circle" value={NodeType.CIRCLE} key={NodeType.CIRCLE}>
                        <StaticImage src="/images/ui/node_types/node_circle.svg" />
                    </ImageOption>
                );
                break;
            case NodeType.DIAMOND:
                options.push(
                    <ImageOption label="Diamond" value={NodeType.DIAMOND} key={NodeType.DIAMOND}>
                        <StaticImage src="/images/ui/node_types/node_diamond.svg" />
                    </ImageOption>
                );
                break;
            case NodeType.CAPSULE:
                options.push(
                    <ImageOption label="Capsule" value={NodeType.CAPSULE} key={NodeType.CAPSULE}>
                        <StaticImage src="/images/ui/node_types/node_capsule.svg" />
                    </ImageOption>
                );
                break;
            case NodeType.BULLET_TEXT:
                options.push(
                    <ImageOption label="Marker" value={NodeType.BULLET_TEXT} key={NodeType.BULLET_TEXT}>
                        <StaticImage src="/images/ui/node_types/node_bullet_text.svg" />
                    </ImageOption>
                );
                break;
            case NodeType.CONTENT_AND_TEXT:
                options.push(
                    <ImageOption label="Media & Text" value={NodeType.CONTENT_AND_TEXT} key={NodeType.CONTENT_AND_TEXT}>
                        <StaticImage src="/images/ui/node_types/node_content_and_text.svg" />
                    </ImageOption>
                );
                break;
            case NodeType.NUMBERED_TEXT:
                options.push(
                    <ImageOption label="Numbered" value={NodeType.NUMBERED_TEXT} key={NodeType.NUMBERED_TEXT}>
                        <StaticImage src="/images/ui/node_types/node_numbered_text.svg" />
                    </ImageOption>
                );
                break;
            case NodeType.CONTENT:
                options.push(
                    <ImageOption label="Just Media" value={NodeType.CONTENT} key={NodeType.CONTENT}>
                        <StaticImage src="/images/ui/node_types/node_content.svg" />
                    </ImageOption>
                );
                break;
            case NodeType.LETTERED_TEXT:
                options.push(
                    <ImageOption label="Lettered" value={NodeType.LETTERED_TEXT} key={NodeType.LETTERED_TEXT}>
                        <StaticImage src="/images/ui/node_types/node_lettered_text.svg" />
                    </ImageOption>
                );
                break;
        }
    }
    return options;
}


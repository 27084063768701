import { v4 as uuid } from "uuid";

import { AnchorType } from "../../../../../core/utilities/geom";
import { $ } from "../../../../../vendor";
import { NodeType, ConnectorType } from "../../../../../../common/constants";
import setCursor from "../../../../../core/utilities/cursor";

export const getSeriesId = point => point.series.userOptions.id;

export const getAnnotations = chartElement => chartElement.elements.annotations;

export const getPointDataSource = (elementId, seriesId, pointIndex) => ({
    elementId,
    seriesId,
    pointIndex
});

export const getPointSnapOptions = (elementId, seriesId, pointIndex) => ({
    elementId,
    seriesId,
    pointIndex
});

export const getPointsDiffDataSource = (elementId, seriesId, pointAIndex, pointBIndex) => ({
    elementId,
    seriesId,
    pointAIndex,
    pointBIndex,
    isDiff: true
});

export const getDataHiliteAnnotationModel = (chartElementId, seriesId, pointIndex) => (
    {
        nodeType: NodeType.CIRCLE,
        snapOptions: getPointSnapOptions(chartElementId, seriesId, pointIndex),
        canDrag: false,
        hideNodeConnectorWidget: true,
        annotationType: "DataHilite",
        dataSource: getPointDataSource(chartElementId, seriesId, pointIndex)
    });

export const getShowChangeInValueConnectorModel = (chartElementId, seriesId, pointAIndex, pointBIndex) => (
    {
        connectorType: ConnectorType.STEP,
        source: chartElementId,
        target: chartElementId,
        startDecoration: "circle",
        endDecoration: "circle",
        endPointIsLocked: false,
        canChangeConnectorType: false,
        lineStyle: "dotted",
        lineWeight: 2,
        color: "secondary",
        labels: [
            {
                dataSource: getPointsDiffDataSource(chartElementId, seriesId, pointAIndex, pointBIndex),
                canEdit: false,
                offset: 0,
                userFontScale: 1.7
            }
        ],
        sourceSnapOptions: getPointSnapOptions(chartElementId, seriesId, pointAIndex),
        targetSnapOptions: getPointSnapOptions(chartElementId, seriesId, pointBIndex),
        adjustments: {
            a1: 75
        }
    });

export const getDataNoteAnnotationAndConnectorModels = (chartElementId, seriesId, pointIndex) => {
    const annotationId = uuid();

    const annotationModel = {
        id: annotationId,
        nodeType: NodeType.BOX,
        hideNodeConnectorWidget: true,
        annotationType: "DataNote",
        snapOptions: getPointSnapOptions(chartElementId, seriesId, pointIndex),
        canDrag: true,
        textFormatBarOffset: 0,
    };

    const connectorModel = {
        source: annotationId,
        target: chartElementId,
        endDecoration: "circle",
        endPointIsLocked: true,
        targetSnapOptions: getPointSnapOptions(chartElementId, seriesId, pointIndex)
    };

    return { annotationModel, connectorModel };
};

export const getAxisAnnotationAndConnectorModels = (chartElementId, axis, x, y) => {
    const annotationId = uuid();

    const annotationModel = {
        id: annotationId,
        nodeType: NodeType.TEXT,
        x,
        y,
        hideNodeConnectorWidget: true,
        textFormatBarOffset: 0,
    };

    const connectorModel = {
        source: annotationId,
        target: chartElementId,
        endPointIsLocked: true,
        canChangeConnectorType: false,
        targetSnapOptions: {
            axis
        }
    };

    return { annotationModel, connectorModel };
};

export const getAnnotationsElementsLinkedToPoint = (chartElement, point) =>
    Object.values(getAnnotations(chartElement).elements)
        .filter(element => {
            // Element uses the chart as a data source for its value
            if (element.model.dataSource) {
                // The data source uses the point
                if (element.model.dataSource.pointIndex === point.index && element.model.dataSource.seriesId === getSeriesId(point)) {
                    return true;
                }
            }

            // Element has connectors which may be snapped to a point of the chart
            if (element.connectorsFromNode) {
                const connector = element.connectorsFromNode.find(({ model }) => model.target === chartElement.uniquePath);
                // A connector is snapped to the point
                if (connector && connector.targetSnapOptions &&
                    connector.targetSnapOptions.pointIndex === point.index &&
                    connector.targetSnapOptions.seriesId === getSeriesId(point)) {
                    return true;
                }
            }

            return false;
        });

export function handleDragChangeInValueAnchor({
    plotBoxScreenBounds,
    point,
    annotations,
    connectorModel,
    chartElement,
    isStart,
    adjustVertically = true,
    onDragEnd = () => {}
}) {
    const canvasScale = chartElement.canvas.getScale();
    const series = point.series;
    const seriesId = getSeriesId(point);

    const isBarSeries = series.type == "bar";

    if (isBarSeries) {
        connectorModel.startAnchor = AnchorType.RIGHT;
        connectorModel.endAnchor = AnchorType.RIGHT;
    }

    let axisSnapPoints = [];
    for (let dataIndex = 0; dataIndex < series.data.length; dataIndex++) {
        const pointPos = (isBarSeries ? plotBoxScreenBounds.top : plotBoxScreenBounds.left) + series.data[dataIndex].plotX * canvasScale;
        axisSnapPoints.push(pointPos);
    }

    if (isBarSeries) {
        axisSnapPoints = axisSnapPoints.reverse();
    }

    setCursor("-webkit-grabbing");

    const $body = $("body");

    $body.on("mousemove.drag", event => {
        event.stopPropagation();

        let animationFrameHandledAt = 0;
        window.requestAnimationFrame(timestamp => {
            if (animationFrameHandledAt === timestamp) {
                return;
            }
            animationFrameHandledAt = timestamp;

            const mouseX = event.pageX;
            const mouseY = event.pageY;

            // Find closest xAxisSnapPoint
            const endPos = axisSnapPoints.reduce((prev, curr) => {
                if (isBarSeries) {
                    return Math.abs(curr - mouseY) < Math.abs(prev - mouseY) ? curr : prev;
                } else {
                    return Math.abs(curr - mouseX) < Math.abs(prev - mouseX) ? curr : prev;
                }
            });

            let endPointIndex;
            let startPointIndex;

            if (isStart) {
                endPointIndex = connectorModel.targetSnapOptions.pointIndex;
                startPointIndex = axisSnapPoints.indexOf(endPos);
            } else {
                endPointIndex = axisSnapPoints.indexOf(endPos);
                startPointIndex = connectorModel.sourceSnapOptions.pointIndex;
            }

            if (startPointIndex === endPointIndex) {
                if (startPointIndex < axisSnapPoints.length - 1) {
                    endPointIndex = startPointIndex + 1;
                } else {
                    endPointIndex = startPointIndex - 1;
                }
            }

            if (isStart && endPointIndex < startPointIndex) {
                const temp = endPointIndex;
                endPointIndex = startPointIndex;
                startPointIndex = temp;

                isStart = false;
            } else if (!isStart && endPointIndex < startPointIndex) {
                const temp = endPointIndex;
                endPointIndex = startPointIndex;
                startPointIndex = temp;

                isStart = true;
            }

            connectorModel.sourceSnapOptions.pointIndex = startPointIndex;
            connectorModel.targetSnapOptions.pointIndex = endPointIndex;

            if (adjustVertically) {
                if (isBarSeries) {
                    connectorModel.adjustments = {
                        a1: (mouseX - plotBoxScreenBounds.left) / canvasScale
                    };
                } else {
                    connectorModel.adjustments = {
                        a1: (mouseY - plotBoxScreenBounds.top) / canvasScale
                    };
                }
            }

            connectorModel.labels[0].dataSource = getPointsDiffDataSource(chartElement.uniquePath, seriesId, startPointIndex, endPointIndex);

            annotations.refreshElement();
        });
    });

    const handleDragEnd = (event, deleteConnector = false) => {
        event.stopPropagation();

        $body.off("mousemove.drag");
        $body.off("keyup.showChange");

        if (deleteConnector) {
            annotations.connectors.deleteItem(connectorModel.id);
        }

        setCursor("default");
        onDragEnd();

        chartElement.updateModel();
    };

    $body.one("mouseup.drag", event => {
        handleDragEnd(event);
    });

    $body.one("keyup.showChange", event => {
        if (event.which === 27) {
            handleDragEnd(event, true);
        }
    });
}

import * as geom from "js/core/utilities/geom";
import { AssetType, BlockStructureType, HorizontalAlignType, AuthoringBlockType, TextStyleType } from "common/constants";

import { CollectionElement, CollectionItemElement } from "../base/CollectionElement";
import { ContentElement } from "../base/ContentElement";
import { TextElement } from "../base/Text/TextElement";
import { detectListContent } from "js/core/services/sharedModelManager";
import { TeamItemControlBar, TeamPropertyPanel } from "../../Editor/ElementPropertyPanels/TeamUI";
import { FramedMediaElement } from "../base/MediaElements/FramedMediaElement";

class Team extends CollectionElement {
    getElementPropertyPanel() {
        return TeamPropertyPanel;
    }

    getChildItemType() {
        return TeamItem;
    }

    get defaultItemData() {
        return {
            title: { text: "" },
            body: { text: "" }
        };
    }

    get maxItemCount() {
        return 8;
    }

    _calcProps(props, options) {
        const { size } = props;

        const layouter = this.getLayouter(props, this.itemElements, size);

        if (this.model.orientation == "vertical") {
            this.updateStyles(this.styles.vertical, true);
            layouter.calcRowLayout({ itemOptions: { orientation: "vertical" } });
        } else if (this.model.orientation == "horizontal") {
            this.updateStyles(this.styles.horizontal, true);
            layouter.calcRowLayout({ itemOptions: { orientation: "horizontal" } });
        } else {
            this.updateStyles(this.styles.vertical, true);

            layouter.calcRowLayout({ itemOptions: { orientation: "vertical" } });

            if (!layouter.isFit || !layouter.isTextFit) {
                this.itemElements.forEach(element => element.resetCalculatedProps(true));
                this.updateStyles(this.styles.horizontal, true);
                this.matchedTextElements = null;
                layouter.calcRowLayout({ itemOptions: { orientation: "horizontal" } });
            }

            if (!layouter.isFit) {
                layouter.calcRowLayout({
                    minItemHeight: 100,
                    itemOptions: { orientation: "horizontal" }
                });
            }

            if (!layouter.isFit) {
                this.itemElements.forEach(element => element.resetCalculatedProps(true));
                this.updateStyles(this.styles.small, true);
                const layouterOptions = {
                    minItemHeight: 50,
                    itemOptions: { orientation: "auto" }
                };
                if (this.itemElements.length > 8) {
                    layouter.calcColumnLayout(layouterOptions);
                } else {
                    layouter.calcRowLayout(layouterOptions);
                }
            }
        }

        layouter.alignVertically();

        props.isFit = layouter.isFit;

        return { size: layouter.size };
    }

    _exportToSharedModel() {
        const listContent = this.itemElements.map(itemElement => ({
            text: itemElement.text._exportToSharedModel().textContent[0],
            asset: itemElement.content._exportToSharedModel().assets[0]
        }));

        const textContent = this.itemElements.reduce(
            (textContent, itemElement) => ([
                ...textContent, ...itemElement.text._exportToSharedModel().textContent
            ]), []
        );

        const assets = this.itemElements.reduce(
            (assets, itemElement) => ([
                ...assets, ...itemElement.content._exportToSharedModel().assets
            ]), []
        );

        return { listContent, textContent, assets, collectionColor: this.collectionColor };
    }

    _importFromSharedModel(model) {
        const listContent = detectListContent(model);
        if (!listContent?.length) return;

        const items = listContent.map(({ text, asset }) => ({
            ...(asset ? {
                content_type: asset.type,
                content_value: asset.value,
                assetProps: asset.props,
                assetName: asset.name,
                ...(asset.configProps ?? {})
            } : {}),
            ...(text ? {
                text: {
                    blocks: [
                        {
                            html: text.mainText.text,
                            textStyle: TextStyleType.TITLE,
                            type: AuthoringBlockType.TEXT,
                        },
                        ...text.secondaryTexts.map(secondaryText => ({
                            html: secondaryText.text,
                            textStyle: TextStyleType.BODY,
                            type: AuthoringBlockType.TEXT,
                        })),
                    ]
                }
            } : {})
        }));

        items.splice(this.maxItemCount);
        return { items, collectionColor: model.collectionColor };
    }
}

class TeamItem extends CollectionItemElement {
    get name() {
        return "Team Member";
    }

    getElementControlBar() {
        return TeamItemControlBar;
    }

    get mediaElement() {
        return this.content;
    }

    _build() {
        this.content = this.addElement("content", () => FramedMediaElement, {
            defaultAssetType: AssetType.IMAGE,
            canSelect: false,
            allowUnframedImages: false
        });
        this.text = this.addElement("text", () => TextElement, {
            blockStructure: BlockStructureType.TITLE_AND_BODY,
            autoHeight: true,
            syncFontSizeWithSiblings: true
        });
    }

    _calcProps(props, options) {
        const { size } = props;
        const layouter = this.getLayouter(props, [this.content, this.text], size);

        if (options.orientation == "vertical") {
            layouter.calcVerticalBlockLayout({
                contentSize: new geom.Size(this.styles.content.width, this.styles.content.height),
                horizontalAlign: layouter.HorizontalAlignType.CENTER
            });
        } else {
            layouter.calcHorizontalBlockLayout({
                verticalAlign: layouter.VerticalBlockAlignType.MIDDLE_OR_TOP
            });
        }
        layouter.alignHorizontally(HorizontalAlignType.CENTER);

        props.isFit = layouter.isFit;
        props.isTextFit = this.text.calculatedProps.isTextFit;

        return { size: layouter.size };
    }
}

export const elements = {
    Team,
};

import React, { Component } from "react";

import { ImageOption, ImageOptionList } from "../../../../Components/ImageOptionList";
import { AuthoringBlockType, HeaderPositionType, ResizeDirection, TextStyleType, TrayType } from "../../../../../common/constants";
import { WithLabel } from "../../../../Components/WithLabel";
import { defaultDragResizeProps, DragHandleStyle } from "../../../../editor/PresentationEditor/DragElementManager";
import { Slider } from "../../../../Components/Slider";
import { BackgroundColorPicker } from "../EditorComponents/ColorPickers/BackgroundColorPicker";
import { BaseElementSelection } from "../ElementSelections/BaseElementSelection";
import { ElementUIButton } from "../EditorComponents/ElementUIButton";
import { PropertyPanelContainer, PropertySection, PropertySectionNote } from "../../../../EditorComponents/PropertyPanel";
import { PropertyPanelHeader } from "../../../../editor/ElementEditor/PropertyPanelHeader";
import { ColorPicker } from "../EditorComponents/ColorPickers/ColorPicker";

export class HeaderPropertyPanel extends Component {
    constructor(props) {
        super(props);

        this.lastFitWidth = null;
    }

    handleSetHeaderPosition = async headerPosition => {
        const { currentCanvasController } = this.props;
        const canvas = currentCanvasController.canvas;

        canvas.model.layout.headerPosition = headerPosition;
        canvas.markStylesAsDirty();
        await canvas.updateCanvasModelWithErrorDialog(false);
        if (headerPosition !== HeaderPositionType.NONE) {
            currentCanvasController.selectionLayerController.setSelectedElements([canvas.getHeaderElement()]);
        }
    }

    render() {
        const { element, canvas } = this.props;

        let backgroundColor = element.model.backgroundColor ? element.canvas.getTheme().palette.getColor(element.model.backgroundColor) : element.getBackgroundColor();

        if (canvas.getTheme().get("styleDecoration").contains("block") && !element.model.backgroundColor) {
            // special case for block decoration where we want to ensure that we don't allow color on color for the accent color
            backgroundColor = canvas.getBackgroundColor();
        }

        const allowHeader = canvas.slideTemplate.allowHeader;
        const allowTopHeader = canvas.slideTemplate.availableHeaderPositions.contains("top");
        const allowLeftHeader = canvas.slideTemplate.availableHeaderPositions.contains("left") && (
            canvas.model.layout.trayLayout == TrayType.NONE ||
            canvas.model.layout.trayLayout == null
        );
        let leftHeaderWarning;
        if (!allowLeftHeader) {
            if (!canvas.slideTemplate.availableHeaderPositions.contains(TrayType.LEFT_TRAY)) {
                leftHeaderWarning = "This smart slide does not support left headers.";
            } else {
                leftHeaderWarning = "You can't have a left header when there is a side tray.";
            }
        }
        if (!allowHeader) {
            return (
                <PropertySectionNote>
                    This smart slide does not support headers.
                </PropertySectionNote>
            );
        }

        return (
            <PropertyPanelContainer>
                <PropertyPanelHeader>Header</PropertyPanelHeader>
                <PropertySection>
                    <ImageOptionList value={canvas.model.layout.headerPosition ?? HeaderPositionType.NONE}
                        showLabel cols={3}
                        onChange={this.handleSetHeaderPosition}>
                        <ImageOption label="No Header" value={HeaderPositionType.NONE}
                            url="/images/ui/trays/header_none.svg" />
                        <ImageOption label="Top Header" value={HeaderPositionType.TOP}
                            disabled={!allowTopHeader}
                            url="/images/ui/trays/header_top.svg" />
                        <ImageOption label="Left Header" value={HeaderPositionType.LEFT}
                            disabled={!allowLeftHeader}
                            disabledTooltip={leftHeaderWarning}
                            url="/images/ui/trays/header_left.svg" />
                    </ImageOptionList>
                </PropertySection>

                <PropertySection>
                    <WithLabel label="Header Background Color">
                        <ColorPicker value={element.model.backgroundColor} canvas={element.canvas}
                            showNone showBackgroundColors allowColorOnColor
                            onChange={color => {
                                if (color == "transparent") {
                                    color = null; // header is expecting a null for no background
                                }
                                element.updateModel({ backgroundColor: color });
                            }}
                        />
                    </WithLabel>
                    <WithLabel label="Header Accent Color" toolTip={
                        <>
                            <p>The <strong>header accent color</strong> is used for:</p>
                            <ul>
                                <li>Any header decorations defined by the theme.</li>
                                <li>The header label if present.</li>
                            </ul>
                            <p><strong>Note:</strong> In some cases, the accent color may not be visible when none of those conditions are present.</p>
                        </>
                    }>
                        <ColorPicker value={element.model.accentColor ?? "theme"} canvas={element.canvas}
                            onChange={color => element.updateModel({ accentColor: color })}
                            backgroundColor={backgroundColor}
                        />
                    </WithLabel>
                </PropertySection>
                {canvas.model.layout.headerPosition == HeaderPositionType.LEFT &&
                    <PropertySection>
                        <WithLabel above label="Header Width">
                            <Slider
                                fillWidth
                                value={canvas.getCanvasElement().headerWidth}
                                showInput
                                min={50} max={1280 / 2} step={1}
                                onChange={value => {
                                    canvas.model.layout.headerWidth = value;
                                    canvas.refreshCanvas()
                                        .then(() => {
                                            this.lastFitWidth = value;
                                        })
                                        .catch(err => {
                                            if (err.name === "LayoutNotFitError" && this.lastFitWidth) {
                                                canvas.model.layout.headerWidth = this.lastFitWidth;
                                                return canvas.refreshCanvas();
                                            }
                                        });
                                }}
                                onCommit={value => {
                                    canvas.model.layout.headerWidth = value;
                                    canvas.updateCanvasModel();
                                }}
                            />
                        </WithLabel>
                    </PropertySection>
                }
            </PropertyPanelContainer>
        );
    }
}

export class HeaderSelection extends BaseElementSelection {
    get canDelete() {
        const { element } = this.props;
        return !element.isOnAuthoringCanvas;
    }

    get canResize() {
        const { element } = this.props;
        return element.canvas.model.layout.headerPosition == HeaderPositionType.LEFT;
    }

    get dragResizeProps() {
        const { element } = this.props;

        const minSize = 200;
        const maxSize = 1280 / 2;
        let initialWidth;
        let lastFitWidth;
        return {
            ...defaultDragResizeProps,
            resizeDirections: [ResizeDirection.RIGHT],
            onDragStart: async () => {
                initialWidth = element.getCanvasElement().headerWidth;
            },
            onDrag: async ({ dragOffset }) => {
                element.canvas.model.layout.headerWidth = Math.round(Math.clamp(initialWidth + dragOffset.x, minSize, maxSize));
                await element.canvas.refreshCanvas()
                    .catch(err => {
                        if (err.name === "LayoutNotFitError" && lastFitWidth) {
                            element.canvas.model.layout.headerWidth = lastFitWidth;
                            return element.canvas.refreshCanvas();
                        }
                        throw err;
                    });
                lastFitWidth = element.canvas.model.layout.headerWidth;
            },
            handleProps: {
                style: DragHandleStyle.GRABBER,
            }
        };
    }

    handleDeleteElement = () => {
        const { element } = this.props;
        element.canvas.model.layout.headerPosition = HeaderPositionType.NONE;
        element.canvas.updateCanvasModel(true);
    }

    renderCustomChildren() {
        const { element, selectionLayerController } = this.props;

        const labelBlock = element.text.getBlockByTextStyle(TextStyleType.LABEL);
        const descriptionBlock = element.text.getBlockByTextStyle(TextStyleType.BODY);

        return (
            <>
                {!labelBlock &&
                    <ElementUIButton style={{ top: -10, left: 20 }}
                        onClick={() => {
                            const block = element.text.addBlock({
                                type: AuthoringBlockType.TEXT,
                                index: 0,
                                textStyle: TextStyleType.LABEL
                            });

                            element.canvas.refreshCanvas().then(() =>
                                selectionLayerController.selectTextElementBlock(element.text, block.id)
                            );
                        }}
                    >Add Label</ElementUIButton>
                }
                {!descriptionBlock &&
                    <ElementUIButton style={{ bottom: -10, left: 20 }}
                        onClick={() => {
                            const block = element.text.addBlock({
                                type: AuthoringBlockType.TEXT,
                                textStyle: TextStyleType.BODY
                            });

                            element.canvas.refreshCanvas().then(() =>
                                selectionLayerController.selectTextElementBlock(element.text, block.id)
                            );
                        }}
                    >Add Sub Heading</ElementUIButton>
                }
            </>
        );
    }
}


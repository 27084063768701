import { AnnotationLayer } from "../AnnotationLayer";
import { NodeType } from "../../../../../../common/constants";
import ConnectorGroup from "../connectors/ConnectorGroup";
import { BaseElement } from "../../base/BaseElement";
import { Shape } from "../../../../../core/utilities/shapes";
import { getCenteredRect } from "../../../../../core/utilities/geom";
import { TextElement } from "../../base/Text/TextElement";
import * as geom from "../../../../../core/utilities/geom";
import { SVGPathElement } from "../../base/SVGElement";
import { ChangeInValueLabelSelection, ChangeInValuePropertyPanel, ChangeInValueConnectorItemSelection } from "../../../Editor/ElementPropertyPanels/ChartEditor/ChartChangeInValueUI";
import { DataHiliteNodeElementControlBar, DataHiliteNodeElementSelection } from "../../../Editor/ElementPropertyPanels/ChartEditor/DataHiliteAnnotationUI";

export class ChartAnnotations extends AnnotationLayer {
    get canSelectChildElements() {
        return this.parentElement.isSelected || this.allChildElements.some(element => element?.isSelected);
    }

    getAllowedNodeTypes(nodeElement) {
        if (nodeElement.model.annotationType === "DataHilite") {
            return [NodeType.CIRCLE];
        }

        return super.getAllowedNodeTypes(nodeElement);
    }

    getChildItemType(model) {
        if (model.annotationType == "DataHilite") {
            return DataHiliteNodeElement;
        } else {
            return super.getChildItemType(model);
        }
    }

    getChildOptions(model) {
        const options = {
            ...super.getChildOptions(model),
            isSingleText: model.annotationType === "DataHilite",
            showResizeSlider: model.annotationType === "DataHilite",
            canEditText: model.annotationType !== "DataHilite",
            doubleClickToSelect: true
        };

        if (model.annotationType === "DataNote") {
            options.maxWidth = 250;
            options.textFormatBarOffset = model.textFormatBarOffset;
        }

        if (model.annotationType === "ChangeInValue") {
            options.elementPropertyPanel = ChangeInValuePropertyPanel;
        }

        return options;
    }

    _build() {
        this.buildItems();

        if (!this.model.connections) {
            this.model.connections = {
                items: []
            };
        }

        this.connectors = this.addElement("connectors", () => ConnectorGroup, {
            model: this.model.connections,
            containerElement: this,
            canDeleteConnectors: false,
            canAddLabels: false,
            getConnectorOptions: connectorItemModel => {
                const labelModel = (connectorItemModel.labels ?? []).find(labelModel => labelModel.dataSource?.isDiff);
                if (!labelModel) {
                    return {};
                }

                return {
                    elementPropertyPanel: ChangeInValuePropertyPanel,
                    elementSelection: ChangeInValueConnectorItemSelection,
                    avoidExtraSteps: true,
                    getLabelOptions: () => ({
                        elementSelection: ChangeInValueLabelSelection,
                        canEdit: labelModel.dataSource.changeType === "text"
                    })
                };
            }
        });
        this.connectors.layer = -1;
    }

    getAnimations() {
        const animations = [];

        this.itemElements
            .forEach(element => {
                animations.push(...element.getAnimations());

                this.connectors.itemElements.filter(connector => connector.model.source == element.id)
                    .forEach(connector => {
                        animations.push(...connector.getAnimations());
                    });
            });

        // Building animations for connectors that don't belong to the annotation nodes
        // i.e. "change in value" connectors
        this.connectors.itemElements
            .filter(connector => !this.itemElements.some(element => connector.model.source === element.id))
            .forEach(connector => {
                animations.push(...connector.getAnimations());
            });

        return animations;
    }

    _migrate_10_02() {
        this.model.connections.items.forEach(connector => {
            if (connector.targetSnapOptions.axis === "xAxis") {
                connector.target = this.parentElement.uniquePath;
            }
        });
    }
}

export class DataHiliteNodeElement extends BaseElement {
    static get schema() {
        return {
            userSize: null,
            decorationStyle: "outlined"
        };
    }

    getElementSelection() {
        return DataHiliteNodeElementSelection;
    }

    getElementControlBar() {
        return DataHiliteNodeElementControlBar;
    }

    get _canSelect() {
        return this.canvas.selectionLayerController.selectedElements.contains(this.findClosestOfType("Chart"));
    }

    get requireParentSelection() {
        return true;
    }

    get passThroughSelection() {
        return true;
    }

    // this is left-over from when this element inherited from NodeElement and some of the chart code is dependent on it
    get connectorsFromNode() {
        return [];
    }

    get bounds() {
        // IMPORTANT: returning a modiifed copy of bounds means that we can't directly modify bounds property for this
        // element!
        return super.bounds.offset(-this.registrationPoint.x, -this.registrationPoint.y);
    }

    get registrationPoint() {
        return this.shape.calculatedProps.bounds.center;
    }

    _build() {
        this.shape = this.addElement("shape", () => SVGPathElement);

        this.text = this.addElement("text", () => TextElement, {
            autoHeight: true,
            autoWidth: true,
            canEdit: false,
            canSelect: false,
            canRollover: false,
            allowAlignment: false,
            scaleTextToFit: true,
            selectionPadding: 0,
            allowTextStyles: false,
            minTextScale: 0.01,
            backgroundElement: this.shape
        });
    }

    get size() {
        return this.model.userSize;
    }

    _calcProps(props, options) {
        let { size } = props;

        const series = this.canvas.layouter.canvasElement ? this.canvas.getElementByUniquePath(this.model.dataSource.elementId).model.chartData.series.find(s => s.id == this.model.dataSource.seriesId) : null;
        if (series) {
            if (!this.model.color || this.model.color == "auto") {
                if (this.model.decorationStyle == "filled") {
                    this.shape.styles.resolved_fillColor = this.canvas.getTheme().palette.getColor(series.colorName);
                } else {
                    this.shape.styles.resolved_strokeColor = this.canvas.getTheme().palette.getColor(series.colorName);
                }
            }
            if (this.model.decorationStyle == "filled") {
                this.shape.styles.strokeWidth = 0;
            } else {
                this.shape.styles.strokeWidth = Math.min(series.lineWidth ?? this.canvas.styleSheet.variables.chartLineWidth, 10);
            }
        }

        if (this.size) {
            this.text.styles.fontSize = 100;
            size.width = size.height = this.size;
        } else {
            this.text.styles.fontSize = 20;
            let textProps = this.text.calcProps(size);
            size.width = size.height = textProps.size.width;
        }

        this.text.styles.paddingLeft = this.text.styles.paddingRight = this.text.styles.paddingTop = this.text.styles.paddingBottom = size.width / 10 * 2;

        let shapeProps = this.shape.calcProps(size);
        let textProps = this.text.calcProps(size);

        shapeProps.bounds = new geom.Rect(0, 0, size);
        shapeProps.path = Shape.drawCircle(shapeProps.bounds.width / 2, shapeProps.bounds.center).toPathData();
        textProps.bounds = getCenteredRect(textProps.size, shapeProps.bounds);

        return { size };
    }

    _applyColors() {
        const series = this.canvas.layouter.canvasElement ? this.canvas.getElementByUniquePath(this.model.dataSource.elementId).model.chartData.series.find(s => s.id == this.model.dataSource.seriesId) : null;

        if (this.model.decorationStyle == "filled") {
            this.shape.colorSet.fillColor = this.palette.getColor(this.model.color ?? this.canvas.getBackgroundColor());
        } else {
            this.shape.colorSet.fillColor = this.palette.getColor(this.canvas.getBackgroundColor());
        }
        this.shape.colorSet.backgroundColor = this.shape.colorSet.fillColor;
        this.shape.colorSet.strokeColor = this.palette.getColor(this.model.color ?? series.colorName ?? "theme", this.getBackgroundColor());
    }
}

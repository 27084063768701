import React, { Component } from "react";
import { PropertyPanelContainer, PropertySection } from "../../../../EditorComponents/PropertyPanel";
import { ToggleSwitch } from "../../../../Components/ToggleSwitch";
import { WithLabel } from "../../../../Components/WithLabel";
import { CollectionColorPicker } from "../EditorComponents/ColorPickers/CollectionColorPicker";
import { ControlBar } from "../ElementControlBars/Components/ControlBar";
import { Slider } from "../../../../Components/Slider";
import { EmphasizeToggle } from "../EditorComponents/EmphasizeToggle";
import { ItemColorPicker } from "../EditorComponents/ColorPickers/ItemColorPicker";
import { ImageOption, ImageOptionList } from "../../../../Components/ImageOptionList";
import { StaticImage } from "../../../../Components/StaticImage";
import { CollectionItemElementSelection } from "../ElementSelections/CollectionItemElementSelection";
import { ForeColorType } from "../../../../../common/constants";

export class ArrowBarsPropertyPanel extends Component {
    render() {
        const { element } = this.props;
        return (
            <PropertyPanelContainer>
                <PropertySection>
                    <WithLabel label="Arrow Colors">
                        <CollectionColorPicker element={element} showDecorationStyles />
                    </WithLabel>
                    {element.model.collectionColor != ForeColorType.COLORFUL && (
                        <WithLabel label="Shade Colors">
                            <ToggleSwitch value={element.model.shadeColors}
                                onChange={value => element.updateModel({ shadeColors: value }, { refreshStyles: true })}
                            />
                        </WithLabel>
                    )}
                </PropertySection>
                <PropertySection>
                    <WithLabel label="Arrow Style" top left>
                        <ImageOptionList cols={4} value={element.model.arrowHeadStyle ?? "arrow"}
                            onChange={value => {
                                for (let item of element.itemElements) {
                                    // this removes a legeacy property that is no longer used
                                    delete item.model.showArrowHead;
                                }
                                element.updateModel({ arrowHeadStyle: value });
                            }}>
                            <ImageOption value="arrow" label="Arrow">
                                <StaticImage src="/images/arrowBars/arrow_arrow.svg" />
                            </ImageOption>
                            <ImageOption value="triangle" label="Triangle">
                                <StaticImage src="/images/arrowBars/arrow_triangle.svg" />
                            </ImageOption>
                            <ImageOption value="rounded" label="Rounded">
                                <StaticImage src="/images/arrowBars/arrow_round.svg" />
                            </ImageOption>
                            <ImageOption value="square" label="Square">
                                <StaticImage src="/images/arrowBars/arrow_square.svg" />
                            </ImageOption>
                        </ImageOptionList>
                    </WithLabel>
                </PropertySection>
                <PropertySection>
                    <WithLabel label="Show 3D Effect">
                        <ToggleSwitch value={element.model.showFoldEffect}
                            onChange={value => element.updateModel({ showFoldEffect: value }, { transition: false })} />
                    </WithLabel>
                    <WithLabel label="Show Number">
                        <ToggleSwitch value={element.model.showIndex}
                            onChange={value => element.updateModel({ showIndex: value })} />
                    </WithLabel>
                </PropertySection>
            </PropertyPanelContainer>
        );
    }
}

export class ArrowBarItemControlBar extends Component {
    render() {
        const { element } = this.props;
        return (
            <ControlBar>
                <EmphasizeToggle element={element} />
                <Slider min={15} max={100}
                    value={element.model.value}
                    onChange={value => element.refreshModel({ value })}
                    onCommit={() => element.saveModel()}
                />
                <ItemColorPicker element={element} />
            </ControlBar>
        );
    }
}

export class ArrowBarItemSelection extends CollectionItemElementSelection {
    get dragPositionProps() {
        return {
            ...super.dragPositionProps,
            dragAxes: ["y"],
        };
    }
}


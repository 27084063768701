import React, { Component } from "react";
import { v4 as uuid } from "uuid";

import { MenuItem } from "@material-ui/core";

import { AuthoringBlockType, TextStyleType } from "../../../../../common/constants";
import { Dropdown } from "../../../../Components/Dropdown";
import { WithLabel } from "../../../../Components/WithLabel";

import { Icon } from "../../../../Components/Icon";
import { Slider } from "../../../../Components/Slider";
import { PropertyPanelContainer, PropertySection } from "../../../../EditorComponents/PropertyPanel";
import { CollectionColorPicker } from "../EditorComponents/ColorPickers/CollectionColorPicker";
import { ItemColorPicker } from "../EditorComponents/ColorPickers/ItemColorPicker";
import { EmphasizeToggle } from "../EditorComponents/EmphasizeToggle";
import { CollectionElementControlBar } from "../ElementControlBars/CollectionElementControlBar";
import { ControlBar, ControlBarButton } from "../ElementControlBars/Components/ControlBar";
import { BaseElementSelection } from "../ElementSelections/BaseElementSelection";

export class ProcessDiagramPropertyPanel extends Component {
    render() {
        const { element } = this.props;

        const singleRow = element.calculatedProps.rows === 1;

        let textLayout = element.textLayout;
        if (!singleRow && textLayout !== "none") {
            textLayout = "alternate";
        }

        return (
            <PropertyPanelContainer>
                <PropertySection>
                    <WithLabel label="Item Color">
                        <CollectionColorPicker element={element} showDecorationStyles />
                    </WithLabel>
                </PropertySection>
                <PropertySection>
                    <WithLabel label="Height">
                        <Slider value={element.itemHeight}
                            min={.5} max={1.5} step={.01} percent showInput
                            onChange={value => element.refreshModel({ itemHeight: value })}
                            onCommit={value => element.updateModel({ itemHeight: value })}
                        />
                    </WithLabel>
                </PropertySection>
                <PropertySection>
                    <WithLabel label="Additional Item Text">
                        <Dropdown value={textLayout}
                            onChange={value => {
                                if (value === "none") {
                                    element.itemElements.forEach(item => {
                                        item.model.body = null;
                                    });
                                } else if (element.textLayout === "none") {
                                    element.itemElements.forEach(item => {
                                        item.model.body = {
                                            blocks: [{
                                                type: AuthoringBlockType.TEXT,
                                                textStyle: TextStyleType.BODY
                                            }]
                                        };
                                    });
                                }
                                element.updateModel({ textLayout: value }, { refreshStyles: true });
                            }}
                        >
                            <MenuItem value="none">None</MenuItem>
                            {singleRow && <MenuItem value="above">Above</MenuItem>}
                            {singleRow && <MenuItem value="below">Below</MenuItem>}
                            <MenuItem value="alternate">Alternating</MenuItem>
                        </Dropdown>
                    </WithLabel>
                </PropertySection>

            </PropertyPanelContainer>
        );
    }
}

export class ProcessDiagramBoxesPropertyPanel extends Component {
    render() {
        const { element } = this.props;

        return (
            <PropertyPanelContainer>
                <PropertySection>
                    <WithLabel label="Item Color">
                        <CollectionColorPicker element={element} />
                    </WithLabel>
                </PropertySection>
            </PropertyPanelContainer>
        );
    }
}

export class ProcessDiagramControlBar extends CollectionElementControlBar {
    get addItemLabel() {
        return "Add Step";
    }
}

export class ProcessDiagramChevronControlBar extends Component {
    render() {
        const { element } = this.props;
        return (
            <ControlBar>
                <EmphasizeToggle element={element} />
                <ItemColorPicker element={element} />
                {!element.showBody && (
                    <ControlBarButton
                        onClick={() => {
                            const block = {
                                id: uuid(),
                                type: AuthoringBlockType.TEXT,
                                textStyle: TextStyleType.BODY
                            };

                            element.model.body = {
                                blocks: [block]
                            };

                            element.canvas.refreshCanvas();
                        }}
                    ><Icon>text_increase</Icon></ControlBarButton>
                )}
            </ControlBar>
        );
    }
}

export class ProcessDiagramDescriptionSelection extends BaseElementSelection {
    get canDelete() {
        return true;
    }

    handleDeleteElement = () => {
        const { element } = this.props;
        element.updateModel({ body: null });
    }
}

export class ProcessDiagramBoxItemControlBar extends Component {
    render() {
        const { element } = this.props;
        return (
            <ControlBar>
                <EmphasizeToggle element={element} />
                <ItemColorPicker element={element} />
            </ControlBar>
        );
    }
}

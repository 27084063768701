import React, { Component } from "react";

import { AuthoringLayer } from "./Authoring/AuthoringLayer";
import ElementSelectionBox from "./Components/ElementSelectionBox";

export class AuthoringCanvasSelection extends Component {
    constructor(props) {
        super(props);

        this.authoringLayerRef = React.createRef();
    }

    get authoringLayer() {
        return this.authoringLayerRef.current;
    }

    get authoringLayerProps() {
        const { element } = this.props;

        return {
            minimizedControlBar: !!element.isCallouts
        };
    }

    get allowAreaSelect() {
        return true;
    }

    render() {
        const { element, ...props } = this.props;

        return (
            <ElementSelectionBox>
                <AuthoringLayer
                    {...props}
                    {...this.authoringLayerProps}
                    allowAreaSelect={this.allowAreaSelect}
                    ref={this.authoringLayerRef}
                    containerElement={element}
                    isTemplate={element.canvas.slide.get("isTeamSlideTemplate")}
                />
            </ElementSelectionBox>
        );
    }
}

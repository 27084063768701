import React from "react";
import { ReactGrid } from "@silevis/reactgrid";
import _ from "lodash";
import { ReactGridContainer } from "./ReactGridContainer"; // Ensure the path is correct

import { isShift } from "../../../../../core/utilities/keys";

class ReactGridWrapper extends React.Component {
    // Flag to check if the shift key is pressed but not state
    // We need this in order to handle the shift keyup event and not using the state
    // as it has a side effect of the caret moving to the end of the input
    isShiftPressed = false;
    constructor(props) {
        super(props);

        this.state = {
            shiftRangeSelection: null,
            selectedColumns: []
        };
    }

    componentDidMount() {
        // Add event listeners for keydown and keyup
        document.addEventListener("keydown", this.handleKeyDown);
        document.addEventListener("keyup", this.handleKeyUp);
    }

    componentWillUnmount() {
        // Remove event listeners when the component is unmounted
        document.removeEventListener("keydown", this.handleKeyDown);
        document.removeEventListener("keyup", this.handleKeyUp);
        this.isShiftPressed = false;
    }

    handleKeyDown = event => {
        if (isShift(event.which)) {
            this.isShiftPressed = true;
        }
    }

    handleKeyUp = event => {
        if (isShift(event.which)) {
            this.isShiftPressed = false;
        }
    }

    handleSelectionChanged = event => {
        const { innerRef } = this.props;
        const selectedColumns = event[0].columns.map(cols => cols.columnId);

        if (event[0].first.row.rowId === "header") {
            _.defer(() => {
                // ReactGrid doesn't have a way to select a column without selecting the rows
                if (this.isShiftPressed) {
                    this.selectColumnOnShift(selectedColumns);
                } else {
                    this.setState({ selectedColumns });
                    innerRef.current.setState({ focusedLocation: undefined });
                }
            });
        } else {
            if (selectedColumns.length > 1) {
                const columns = innerRef.current.props.columns;
                const lastColumnId = columns[columns.length - 1].columnId;

                if (selectedColumns.includes(lastColumnId)) {
                    // Filter out the column with columnId equal to the last column's columnId
                    const filteredColumns = event[0].columns.filter(column => column.columnId !== lastColumnId);

                    // Update the last column reference
                    event[0].last.column = filteredColumns[filteredColumns.length - 1];

                    // Update the columns array
                    event[0].columns = filteredColumns;

                    // Update the state with the new selected ranges
                    innerRef.current.setState({ selectedRanges: [event[0]] });
                }
            }
        }
    }

    selectColumnOnShift = selectedColumns => {
        const { innerRef } = this.props;

        this.clearSelection();
        const { selectedColumns: prevSelectedColumns } = this.state;
        // Merge and remove duplicates
        const mergedColumns = Array.from(new Set([...prevSelectedColumns, ...selectedColumns]));
        const arrayRange = (start, stop, step = 1) => {
            const length = Math.floor((stop - start) / step) + 1;
            return Array.from({ length }, (_, index) => start + index * step);
        };
        // Calculate the range
        const min = Math.min(...mergedColumns);
        const max = Math.max(...mergedColumns);
        const range = arrayRange(min, max, 1);
        // Update the state
        this.setState({ selectedColumns: range });
        innerRef.current.setState({ selectionMode: "column", selectedIds: range, selectedIndexes: [0] });
    }

    clearSelection() {
        const { innerRef } = this.props;
        innerRef.current.setState({ focusedLocation: undefined, selectedIds: [], selectedIndexes: [], selectedRanges: [] });
    }

    render() {
        const { innerRef, ...rest } = this.props;

        return (
            <ReactGridContainer onClick={event => event.stopPropagation()}>
                <ReactGrid
                    ref={innerRef}
                    onSelectionChanged={this.handleSelectionChanged}
                    {...rest}
                />
            </ReactGridContainer>
        );
    }
}

export default React.forwardRef((props, ref) => <ReactGridWrapper {...props} innerRef={ref} />);
